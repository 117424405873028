import DatePickerKo from "../../../com/dateInputs/datePickerKo";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";
import { getToken } from "../../../../service/authService";

export const UserBetweenUserSearch = ({ codeList, rgns, search, setSearch, getUserDataList }) => {
  // 날짜 Default 정의
  let today = new Date();
  let yesterday = new Date();
  let lastWeek = new Date();
  let lastMonth = new Date();
  let lastYear = new Date();
  yesterday.setDate(today.getDate() - 1);
  lastWeek.setDate(yesterday.getDate() - 7);
  lastMonth.setDate(yesterday.getDate() - 30);
  lastYear.setDate(yesterday.getDate() - 365);

  // 에너지원 리스트
  const ensoList = codeList.filter((c) => c.grpCodeNo === 15 && c.codeNo !== 15007);
  // 날짜 검색 데이터 리스트
  const dateList = codeList.filter((c) => c.grpCodeNo === 23 && c.codeNo !== 23001);

  // 드롭다운
  const onChangeEvt = (e) => {
    if (e.target.name === "ensoTypeCodeNo") {
      search.ensoTypeCode = e.target.value.codeNo;
    } else if (e.target.name === "rgnCode") {
      search.rgnCode = e.target.value.rgnCode;
    }
    // 날짜 검색
    else if (e.target.name === "date") {
      let start = yesterday;
      if (e.target.value.codeNo === 23002) {
        start = yesterday;
      } else if (e.target.value.codeNo === 23003) {
        start = lastWeek;
      } else if (e.target.value.codeNo === 23004) {
        start = lastMonth;
      } else if (e.target.value.codeNo === 23005) {
        start = lastYear;
      } else if (e.target.value.codeNo === 23006) {
        start = yesterday;
      }
      setSearch((search) => {
        const item = { ...search };
        item.dateStatus = e.target.value;
        item.startGathDtm = start;
        return item;
      });
    }
  };

  // 조회 버튼
  const setSearchClickEvt = () => {
    // 유저 ID 초기화
    search.userId = "";
    search.makrSeriNo = "";
    getUserDataList();
  };

  return (
    <>
      <span className="searchSubTitleA">에너지원</span>
      <DropDownList
        data={ensoList}
        defaultValue={{ codeVal: "태양광", codeNo: 15001 }}
        name="ensoTypeCodeNo"
        textField="codeVal"
        dataItemKey="codeNo"
        onChange={onChangeEvt}
        className="searchArea"
      ></DropDownList>
      <span className="searchSubTitleB">지역검색</span>
      <DropDownList
        data={rgns}
        defaultValue={{ rgnTermSimple: "전체", rgnCode: "" }}
        defaultItem={{ rgnTermSimple: "전체", rgnCode: "" }}
        name="rgnCode"
        textField="rgnTermSimple"
        dataItemKey="rgnCode"
        onChange={onChangeEvt}
        className="searchArea"
      ></DropDownList>
      <span className="searchSubTitleB">날짜검색</span>
      <DropDownList
        data={dateList}
        name="date"
        className="searchArea"
        dataItemKey="codeNo"
        textField="codeVal"
        defaultValue={{ codeNo: 23006, codeVal: "직접조회" }}
        onChange={onChangeEvt}
        value={search.dateStatus}
      ></DropDownList>
      <DatePickerKo
        id="start"
        name="start"
        className="datePicker"
        defaultValue={search.yesterday}
        format="yyyy-MM-dd "
        value={search.startGathDtm}
        readonly={true}
        max={search.startMax}
        setDtm={(v) => {
          setSearch((search) => {
            const item = { ...search };
            item.endMin = v;
            item.startGathDtm = v;
            item.dateStatus = { codeNo: 23006, codeVal: "직접조회" };
            return item;
          });
        }}
      />
      <span className="textColorGr">~</span>
      <DatePickerKo
        id="end"
        name="end"
        className="datePicker"
        defaultValue={search.yesterday}
        format="yyyy-MM-dd"
        min={search.endMin}
        max={search.yesterday}
        readonly={true}
        setDtm={(v) => {
          setSearch((search) => {
            const item = { ...search };
            item.startMax = v;
            item.endGathDtm = v;
            item.dateStatus = { codeNo: 23006, codeVal: "직접조회" };
            return item;
          });
        }}
      />
      <span className="searchSubTitleC">
        <Button className="inqBtn" onClick={setSearchClickEvt} icon="search"></Button>
      </span>
    </>
  );
};

export default UserBetweenUserSearch;
