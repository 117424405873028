import log from "../../com/log";

import { parseDate } from "../../com/dateUtil";
import kendo from "@progress/kendo-ui";
import { getMessage } from "../../com/messageUtil";

const loraUrl = "/iot/sk";

export const getLora = async (conn, state) => {
  const { take, skip, sort, filter } = state;
  const config = {
    params: {
      page: (take + skip) / take,
      size: take,
      sort,
    },
  };

  if (filter !== undefined) {
    if (filter.loraDevid !== undefined) config.params.loraDevid = filter.loraDevid;
    if (filter.rtuEnteCode !== undefined) config.params.rtuEnteCode = filter.rtuEnteCode;
    if (filter.loraStatus !== undefined) config.params.loraStatus = filter.loraStatus;
  }

  const res = await conn.get(loraUrl + "/list", config);

  res.results.forEach((item) => {
    if (item.loraStatus === 201) item.loraStatusTerm = "등록완료";
    else if (item.loraStatus === 409) item.loraStatusTerm = "기등록";
    else if (item.loraStatus === 404) item.loraStatusTerm = "미개통 또는 조인 미실시";
    else item.loraStatusTerm = "실패";
  });

  return res;
};

export const setLoraData = async (conn, data) => {
  const config = {
    headers: { "Content-Type": "application/json" },
  };
  const res = await conn.post(loraUrl, JSON.stringify(data), config);

  return res;
};

export const getLoraLog = async (conn, setLoading, state, data) => {
  try {
    setLoading(true);
    const { take, skip, sort } = state;
    const config = {
      params: {
        page: (take + skip) / take,
        size: take,
        sort,
      },
    };

    if (data !== "전체") config.params.regiId = data;
    else config.params.regiId = null;

    const result = await conn.get(loraUrl + "/log/list", config);
    for (let item of result.results) {
      item.regiDtm = parseDate("yyyy-mm-dd hh:mm:ss", item.regiDtm);
    }

    return result;
  } catch (e) {
    setLoading(false);
  }
};

export const getLogRegiIdList = async (conn) => {
  const res = await conn.get(loraUrl + "/log/regiId");

  return res;
};
