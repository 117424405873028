import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";

import Settings from "@mui/icons-material/Settings"
import EnergySavingsLeaf from "@mui/icons-material/EnergySavingsLeafTwoTone"
import Monitor from "@mui/icons-material/Monitor"
import DesktopAccessDisabled from "@mui/icons-material/DesktopAccessDisabled"
import LocationCityIcon from '@mui/icons-material/LocationCity';
import BusinessIcon from '@mui/icons-material/Business';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EditIcon from '@mui/icons-material/Edit';
import CallIcon from '@mui/icons-material/Call';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import EngineeringIcon from '@mui/icons-material/Engineering';
import ConstructionIcon from '@mui/icons-material/Construction';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import AddToQueueIcon from '@mui/icons-material/AddToQueue';
import NumbersIcon from '@mui/icons-material/Numbers';
import { pink, orange, indigo, blue, red } from '@mui/material/colors';

import { useEffect } from "react";

import { roundNum } from "../../../com/dataUtil";
import GridColumnInputFilter from "../../com/gridFilter/gridColumnInputFilter";

const MonitorASGrid = ({ search, setSearch, paging, getMonitorASData, modalItem, setModalItem }) => {

    // 페이징 이벤트
    const onPageChange = (e) => {
        let { take, skip } = e.page;
        paging.take = take;
        paging.skip = skip;
        getMonitorASData(search, paging);
    };

    // 필터링
    const filterChange = (event) => {
        setSearch({
            ...search,
            installerTerm: event.filter.filters[0].value
        });
    };

    const twoInOne = (e) => {
        const { dataItem } = e;
        let tempSplitString;
        if (e.field == "editInfo") {
            tempSplitString = dataItem.editInfo.toString().split('/');
            return <td>
                <EditIcon />관리이력 수정 여부 : {tempSplitString[0]}<br />
                {tempSplitString[1] === 'Y'
                    ? <bluetext style={{ color: blue[500] }}><AddToQueueIcon />추가계약 연장 여부 : {tempSplitString[1]}</bluetext>
                    : <redtext style={{ color: red[500] }}><AddToQueueIcon />추가계약 연장 여부 : {tempSplitString[1]}</redtext>}
            </td>;
        }
    };

    const threeInOne = (e) => {
        const { dataItem } = e;
        let tempSplitString;
        if (e.field == "bizInfo") {
            tempSplitString = dataItem.bizInfo.toString().split('/');
            return <td>
                <LocationCityIcon style={{ color: indigo[600] }} /> 지역구분 : {tempSplitString[0]}<br />
                <BusinessIcon style={{ color: indigo[600] }} /> 사업구분 : {tempSplitString[1]}<br />
                <CalendarMonthIcon style={{ color: indigo[600] }} /> 사업연도 : {tempSplitString[2]}
            </td>;
        }
        else if (e.field == "dayInfo") {
            tempSplitString = dataItem.dayInfo.toString().split('/');
            const dateTemp = new Date(tempSplitString[2]);
            const now = new Date();
            const diffDate = Math.ceil((dateTemp.getTime() - now.getTime()) / (24 * 60 * 60 * 1000));
            return <td>
                <b><TimelapseIcon style={{ color: pink[500] }} /> 만료 : D - {diffDate}</b><br />
                <ConstructionIcon /> 설비설치 : {tempSplitString[0]}<br />
                <Monitor /> 모니터링 : {tempSplitString[1]}<br />
                <DesktopAccessDisabled /> A/S 만료 : {tempSplitString[2]}
            </td>;
        }
        else if (e.field == "rtuInfo") {
            tempSplitString = dataItem.rtuInfo.toString().split('/');
            return <td>
                <EngineeringIcon style={{ color: orange[600] }} /> 시공업체 : {tempSplitString[0]}<br />
                <CallIcon /> 연락처 : {tempSplitString[1]}<br />
                <PhoneForwardedIcon /> 통신방식 : {tempSplitString[2]}
            </td>;
        }
        else if (e.field == "instInfo") {
            tempSplitString = dataItem.instInfo.toString().split('/');
            return <td>
                <EnergySavingsLeaf color="success" /> 에너지원 : {tempSplitString[0]}<br />
                <NumbersIcon color="success" /> 설비 개수 : {tempSplitString[1]}<br />
                <ViewModuleIcon color="success" /> 설비 총 용량 : {roundNum(tempSplitString[2], 4)} {dataItem.ensoTypeCode === 15002 || dataItem.ensoTypeCode === 15003 ? "㎡" : "kW"}
            </td>;
        }
    };

    useEffect(() => {
        getMonitorASData(search, paging);
    }, [search.installerTerm])

    return (
        <div className="uk-grid">
            <div className="uk-width-medium-1-1">
                <LocalizationProvider language="ko-KR">
                    <IntlProvider locale="ko">
                        <Grid
                            id="monitorASGrid"
                            className="monitorASGrid"
                            data={paging.list}
                            pageable={{ buttonCount: 5, pageSizes: true }}
                            total={paging.count}
                            skip={paging.skip}
                            take={paging.take}
                            filterable
                            onPageChange={onPageChange}
                            onFilterChange={filterChange}
                        >
                            <GridNoRecords>{"  "}</GridNoRecords>
                            <GridColumn title="수용가명" field="installerTerm" filterCell={GridColumnInputFilter}></GridColumn>
                            <GridColumn title="구분" field="bizInfo" filterable={false} cell={threeInOne}></GridColumn>
                            <GridColumn title="일시" field="dayInfo" filterable={false} cell={threeInOne}></GridColumn>
                            <GridColumn title="RTU 정보" field="rtuInfo" filterable={false} cell={threeInOne}></GridColumn>
                            <GridColumn title="설비정보" field="instInfo" filterable={false} cell={threeInOne}></GridColumn>
                            <GridColumn title="여부" field="editInfo" filterable={false} cell={twoInOne}></GridColumn>
                            <GridColumn
                                title="관리"
                                filterable={false}
                                sortable={false}
                                width={60}
                                cell={(props) => (
                                    <td>
                                        <a
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setModalItem({
                                                    ...modalItem,
                                                    visible: !modalItem.visible,
                                                    title: props.dataItem.installerTerm + " 관리이력",
                                                    createUpdateYn: false,
                                                    item: props.dataItem,
                                                });
                                            }}
                                        >
                                            <Settings />
                                        </a>
                                    </td>
                                )}
                            />
                        </Grid>
                    </IntlProvider>
                </LocalizationProvider>
            </div>
        </div>
    );
};

export default MonitorASGrid;
