// React
import React from "react";

// Kendo
import { Dialog } from "@progress/kendo-react-dialogs";

import Meain from "../meain/meain";

function MeainInfoModal({ title, cancelEdit, conn, codeList, rgnList, dongList }) {
    return (
        <Dialog title={title} onClose={cancelEdit} width={1800}>
            <Meain
                conn={conn}
                codeList={codeList}
                rgnList={rgnList}
                dongList={dongList}
            />
        </Dialog>
    );
}

export default MeainInfoModal;
