// 모니터링 사후관리 페이지 데이터 조회 함수
export const getMonitorASDataList = async (conn, callback, reject, param, paging, setLoading) => {
    let url = "meain/monitorAS/page";
    if (param.bizYy === "사업연도") param.bizYy = null;
    await conn
        .get(url, {
            params: {
                installerTerm: param.installerTerm,
                ensoType: param.ensoType.codeNo,
                bizSct: param.bizSct.codeNo,
                bizYy: param.bizYy,
                rgnCode: param.rgn.rgnCode,
                row: paging.skip,
                size: paging.take,
            },
        })
        .then((response) => {
            setLoading(false);
            callback(response);
        })
        .catch((error) => {
            setLoading(false);
            reject(error);
        });
};

// 관리 버튼을 눌렀을 때 나오는 관리이력 모달창에서 설비 상세정보를 눌렀을 경우 해당 사용자에 대한 정보를 전달하기 위한 함수
export const openMeainDetail = (history, meainInfo) => {
    let sysMode = meainInfo.sysMode ? meainInfo.sysMode : '';

    // 설비 등록 이동
    history.push(
        {
            // pathname: "/meain",   // 관리 - 설비등록으로 이동
            meainTransData: {
                installerTerm: meainInfo.installerTerm,  // 수용가명
                ensoTypeCode: meainInfo.ensoTypeCode,   // 에너지원 코드 설정
                sysMode: sysMode,               // 태양열 설치모드 설정 : 강제, 자연
                monitorYn: 'Y'
            }
        }
    );
}

// 관리이력 모달창에서 추가계약 여부를 변경하기 위해 사용하는 함수
export const setAddlAgreements = async (conn, param) => {
    let url = "meain/monitorAS/addlAgreements";
    const config = {
        headers: { "Content-Type": "application/json" },
    };
    const result = await conn.post(
        url,
        JSON.stringify(new Array({
            installerId: param.installerId,
            bizSct: param.bizSctCode,
            addlAgreeYN: param.editInfo.charAt(param.editInfo.length - 1)
        })),
        config
    );
    return result;
}

// 관리이력 모달창에서 관리이력 목록을 불러오기 위해 사용하는 함수
export const getMeainMngtLogListData = async (conn, callback, reject, param, setLoading) => {
    let url = "meain/monitorAS/meainMngtLog";
    await conn
        .get(url, {
            params: {
                installerId: param.installerId,
                bizSct: param.bizSctCode,
            },
        })
        .then((response) => {
            setLoading(false);
            callback(response);
        })
        .catch((error) => {
            setLoading(false);
            reject(error);
        });
};

// 관리이력 모달창에서 추가기록을 저장하기 위해 사용하는 함수
export const setAddlHistory = async (conn, param) => {
    let url = "meain/monitorAS/addlHistory";
    const config = {
        headers: { "Content-Type": "application/json" },
    };
    const result = await conn.post(
        url,
        JSON.stringify(new Array({
            installerId: param.installerId,
            bizSct: param.bizSctCode,
            addlHistory: param.addlHistory
        })),
        config
    );
    return result;
}

export const delAddlHistory = async (conn, id) => {
    let url = "meain/monitorAS/addlHistory";
    const config = {
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify(id)
    };
    const result = await conn.delete(
        url,
        config
    );
    return result;
}