import TimePowerChart from "./time_power_chart";
import TimePowerGrid from "./time_power_grid";
import React, { useState, useEffect, useCallback } from "react";
import { getTimeDataList } from "../../../../service/stats/type/ensoDataService";
import { Button } from "@progress/kendo-react-buttons";

const TimePower = ({ conn, search, setLoading, LocalizationProvider, IntlProvider }) => {
  // 설치 유형 데이터 소스
  const [dataSource, setDataSource] = useState({
    ds: [],
    chart: [],
    excelData: null,
  });

  // 엑셀 데이터 설정
  const setExcelData = (excel) => {
    setDataSource((dataSource) => {
      const item = { ...dataSource };
      item.excelData = excel;
      return item;
    });
  };

  // 엑셀 저장
  const downloadExcel = () => {
    setLoading(true);
    dataSource.excelData.save();
  };

  // 데이터 소스 가져오기
  const getResult = useCallback(
    (getData, chart) => {
      setLoading(false);
      setDataSource((dataSource) => {
        const item = { ...dataSource };
        item.ds = getData;
        item.chart = chart;
        return item;
      });
    },
    [search],
  );

  // reject 함수 진행
  const getRejection = (error) => {
    setLoading(false);
  };

  const completeExcel = () => {
    setLoading(false);
  };

  useEffect(() => {
    getTimeDataList(conn, getResult, getRejection, search);
  }, [getResult]);

  return (
    <div className="uk-grid">
      <div className="uk-width-medium-1-2">
        <div className="md-card">
          <div className="md-card-content">
            <div className="md-card-toolbar">
              {search.unit === "HH" && <h3 className="md-card-toolbar-heading-text">시간대별 그래프</h3>}
              {search.unit === "DD" && <h3 className="md-card-toolbar-heading-text">일별 그래프</h3>}
              {search.unit === "MM" && <h3 className="md-card-toolbar-heading-text">월별 그래프</h3>}
            </div>
            <TimePowerChart dataSource={dataSource.chart} ensoTypeCodeNo={search.ensoTypeCodeNo}></TimePowerChart>
          </div>
        </div>
      </div>
      <div className="uk-width-medium-1-2">
        <div className="md-card">
          <div className="md-card-content">
            <div className="md-card-toolbar">
              <h3 className="md-card-toolbar-heading-text">
                {search.unit === "HH" && <>시간대별 상세정보</>}
                {search.unit === "DD" && <>일별 상세정보</>}
                {search.unit === "MM" && <>월별 상세정보</>}
                <Button icon="excel" className="ensoCmpExcelDownBtn" onClick={downloadExcel}>
                  엑셀저장
                </Button>
              </h3>
            </div>
            <label for="timePowerGrid">
              <TimePowerGrid
                dataSource={dataSource.ds}
                ensoTypeCodeNo={search.ensoTypeCodeNo}
                unit={search.unit}
                setExcelData={setExcelData}
                completeExcel={completeExcel}
                LocalizationProvider={LocalizationProvider}
                IntlProvider={IntlProvider}
              ></TimePowerGrid>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimePower;
