// import kendo from "@progress/kendo-ui";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input, RadioButton } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import DatePickerKo from "../../../com/dateInputs/datePickerKo";
import styles from "./cmpUserByUser.module.css";
import { getToken } from "../../../../service/authService";
import { useState } from "react";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";

// 검색
const CmpUserByUserSearch = ({ search, setSearch, codeList, getMeainListData, kendo }) => {
  const isMobile = localStorage.getItem("isMobile");
  const [selected, setSelected] = useState(0);
  const handleSelect = (e) => {
    setSelected(e.selected);
  };
  // 날짜 Default 정의
  let today = new Date();
  let yesterday = new Date();
  let lastWeek = new Date();
  let lastMonth = new Date();
  let lastYear = new Date();
  yesterday.setDate(today.getDate() - 1);
  lastWeek.setDate(yesterday.getDate() - 7);
  lastMonth.setDate(yesterday.getDate() - 30);
  lastYear.setDate(yesterday.getDate() - 365);

  // 검색 객체의 라디오 상태 값 (검색 시간별 상태)
  const selectedValue = search.dateType;

  // 날짜 조회 드롭다운
  const dateList = codeList.filter((e) => e.grpCodeNo === 23);

  // 라디오 상태 useState
  const [radioStatus, setRadioStatus] = useState("HH");

  // 라디오 이벤트
  const radioChange = (e) => {
    search.dateType = e.value;
    setRadioStatus(e.value);
  };

  // 드롭다운 이벤트
  const onchangeEvt = (e) => {
    // 날짜 검색
    if (e.target.name === "date") {
      let start = yesterday;
      if (e.target.value.codeNo === 23002) {
        start = yesterday;
      } else if (e.target.value.codeNo === 23003) {
        start = lastWeek;
      } else if (e.target.value.codeNo === 23004) {
        start = lastMonth;
      } else if (e.target.value.codeNo === 23005) {
        start = lastYear;
      } else if (e.target.value.codeNo === 23006) {
        start = yesterday;
      }
      // 검색 상태 설정
      setSearch((search) => {
        const item = { ...search };
        item.dateStatus = e.target.value;
        item.startGathDtm = start;
        return item;
      });
    }
  };

  // 유저 ID 검색
  const searchUserId = (e) => {
    setSearch((search) => {
      const item = { ...search };
      item.userInfoVisible = true;
      return item;
    });
  };

  // 조회 이벤트
  const onClickSearchEvt = (e) => {
    if (search.userId === "") {
      kendo.alert("수용가가 선택되지 않았습니다. 수용가를 선택 해주세요.");
      return;
    }
    getMeainListData();
  };

  return (
    <>
      {isMobile === "Y" ? (
        <div className="uk-grid">
          <div className="uk-width-medium-1-1">
            <div>
              <div className="md-card-content small-padding MobileUserByUserDiv">
                <TabStrip selected={selected} onSelect={handleSelect}>
                  <TabStripTab title="수용가선택">
                    <div className="userByUserDivPadding">
                      <span className="searchSubTitleA">수용가</span>
                      <Input
                        id="userTerm"
                        type="text"
                        className={styles.SetSearchTerm}
                        placeholder="수용가"
                        readOnly
                        value={search.userTerm ? search.userTerm : ""}
                        onClick={searchUserId}
                      />
                      <Button className={"userByUserSearchBtn"} onClick={searchUserId} icon="search" />
                    </div>
                  </TabStripTab>
                  <TabStripTab title="기간선택">
                    <div>
                      <table className="userByUserTable">
                        <tr>
                          <td>
                            <RadioButton value="HH" label="" className="radioBtn" checked={radioStatus === "HH"} onChange={radioChange} />
                            <label>시간별</label>
                          </td>
                          <td>
                            <RadioButton value="DD" label="" className="radioBtn" checked={radioStatus === "DD"} onChange={radioChange} />
                            <label>일별</label>
                          </td>
                          <td>
                            <RadioButton value="MM" label="" className="radioBtn" checked={radioStatus === "MM"} onChange={radioChange} />
                            <label>월별</label>
                          </td>
                          <td>
                            <DropDownList
                              className="userByUserDdl"
                              data={dateList}
                              dataItemKey="codeNo"
                              textField="codeVal"
                              defaultValue={{ codeNo: 23006, codeVal: "직접조회" }}
                              onChange={onchangeEvt}
                              value={search.dateStatus}
                            />
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div className="userByUserDivPadding">
                      <DatePickerKo
                        id="startGathDtm"
                        name="startGathDtm"
                        className="userByUserdatePicker"
                        defaultValue={search.yesterday}
                        format={search.dateType === "MM" ? "yyyy-MM" : "yy-MM-dd"}
                        view={search.dateType === "MM" ? "year" : "month"}
                        setDtm={(v) => {
                          setSearch((search) => {
                            const item = { ...search };
                            item.endMin = v;
                            item.startGathDtm = v;
                            item.dateStatus = { codeNo: 23006, codeVal: "직접조회" };
                            return item;
                          });
                        }}
                        value={search.startGathDtm}
                        readonly={true}
                        max={search.startMax}
                      />
                      {selectedValue !== "HH" && <span className="textColorGr datePeriodIcon ">~</span>}
                      {selectedValue !== "HH" && (
                        <DatePickerKo
                          id="endGathDtm"
                          name="endGathDtm"
                          className="userByUserdatePicker"
                          defaultValue={search.yesterday}
                          format={search.dateType === "MM" ? "yyyy-MM" : "yy-MM-dd"}
                          view={search.dateType === "MM" ? "year" : "month"}
                          setDtm={(v) => {
                            setSearch((search) => {
                              const item = { ...search };
                              item.startMax = v;
                              item.endGathDtm = v;
                              item.dateStatus = { codeNo: 23006, codeVal: "직접조회" };
                              return item;
                            });
                          }}
                          min={search.endMin}
                          max={search.yesterday}
                          readonly={true}
                        />
                      )}
                    </div>
                  </TabStripTab>
                </TabStrip>
                <div className="mobileFullBtnDiv">
                  <Button className="mobileFullBtn" onClick={onClickSearchEvt} icon="search">
                    조회
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="uk-grid">
          <div className="uk-width-medium-1-1">
            <div className="md-card TBgA">
              <div className="md-card-content small-padding">
                <span className="searchSubTitleA">수용가</span>
                <span className={styles.userInfoArea}>
                  <div className={styles.SetSearch}>
                    <Input
                      onClick={searchUserId}
                      id="userTerm"
                      type="text"
                      className={styles.SetSearchTerm}
                      placeholder="수용가"
                      readOnly
                      value={search.userTerm ? search.userTerm : ""}
                    />
                  </div>
                  <div className={styles.SetSearch}>
                    <a icon="search" className={styles.searchBtn} onClick={searchUserId}>
                      <img className={styles.searchImg} src={"/images/icon_d.png"} />
                    </a>
                  </div>
                </span>
                <span className={styles.radioForm}>
                  <RadioButton name="cc" value="HH" label="" className="radioBtn" checked={radioStatus === "HH"} onChange={radioChange} />
                  <label>
                    <span></span>시간별
                  </label>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <RadioButton name="cc" value="DD" label="" className="radioBtn" checked={radioStatus === "DD"} onChange={radioChange} />
                  <label>
                    <span></span>일별
                  </label>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <RadioButton name="cc" value="MM" label="" className="radioBtn" checked={radioStatus === "MM"} onChange={radioChange} />
                  <label>
                    <span></span>월별
                  </label>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </span>
                <span className="searchSubTitleA">날짜검색</span>
                <DropDownList
                  className="ensoDropDown yearDropDown"
                  id="date"
                  name="date"
                  data={dateList}
                  dataItemKey="codeNo"
                  textField="codeVal"
                  defaultValue={{ codeNo: 23006, codeVal: "직접조회" }}
                  onChange={onchangeEvt}
                  value={search.dateStatus}
                />
                <span className="searchSubTitleA">조회기간</span>
                <DatePickerKo
                  id="startGathDtm"
                  name="startGathDtm"
                  className="datePicker"
                  defaultValue={search.yesterday}
                  format={search.dateType === "MM" ? "yyyy-MM" : "yyyy-MM-dd"}
                  setDtm={(v) => {
                    setSearch((search) => {
                      const item = { ...search };
                      item.endMin = v;
                      item.startGathDtm = v;
                      item.dateStatus = { codeNo: 23006, codeVal: "직접조회" };
                      return item;
                    });
                  }}
                  value={search.startGathDtm}
                  readonly={true}
                  max={search.startMax}
                />
                {selectedValue !== "HH" && <span className="textColorGr datePeriodIcon ">~</span>}
                {selectedValue !== "HH" && (
                  <DatePickerKo
                    id="endGathDtm"
                    name="endGathDtm"
                    className="datePicker"
                    defaultValue={search.yesterday}
                    format={search.dateType === "MM" ? "yyyy-MM" : "yyyy-MM-dd"}
                    setDtm={(v) => {
                      setSearch((search) => {
                        const item = { ...search };
                        item.startMax = v;
                        item.endGathDtm = v;
                        item.dateStatus = { codeNo: 23006, codeVal: "직접조회" };
                        return item;
                      });
                    }}
                    min={search.endMin}
                    max={search.yesterday}
                    readonly={true}
                  />
                )}
                <Button icon="search" className="searchUserByUserBtn" onClick={onClickSearchEvt}></Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CmpUserByUserSearch;
