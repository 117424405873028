import QnaAdd from "./qnaAdd";
import QnaReply from "./qnaReply";
import QnaGrid from "./qnaGrid";
import QnaSearch from "./qnaSearch";

import "./qna.css";
import styles from "./qna.module.css";

import React, { useState, useEffect, memo } from "react";
import { BeatLoader } from 'react-spinners'

import kendo from "@progress/kendo-ui";
import { Create } from "@material-ui/icons";
import { DropDownList } from "@progress/kendo-react-dropdowns";

import { setQna } from "../../service/bbs/qnaService";
import { setReplyQna } from "../../service/bbs/qnaService";
import { setDeleteQna } from "../../service/bbs/qnaService";
import { setViewCnt } from "../../service/bbs/qnaService";
import { setAddQna } from "../../service/bbs/qnaReplyService";
import { setAddQnaReply } from "../../service/bbs/qnaReplyService";
import { setDeleteQnaReply } from "../../service/bbs/qnaReplyService";
import { get } from "../../service/bbs/qnaReplyService";
import { qnaList } from "../../service/bbs/qnaService";
import { getQnaFileList } from "../../service/bbs/qnaService";

import { getMessage } from "../../com/messageUtil";
import {setDeleteFileList} from "../../service/fileService";

import { getUserTypeCode } from "../../service/authService";
import { getUserMngRgnList } from "../../service/authService";
import { getUserRtuEnteCode } from "../../service/authService";
import { getUser } from "../../service/authService";

import { loadMessages } from "@progress/kendo-react-intl";
import koMessage from "./ko-language.json";
loadMessages(koMessage, "ko-KR");

const Qna = memo(({ conn }) => {

  // 로딩 스피너
  const [loading, setLoading] = useState(false);

  const loginUserTypeCode = getUserTypeCode();
  const loginUserMngRgnList = getUserMngRgnList();
  const loginUserRtuEnteCode = getUserRtuEnteCode();
  const loginUser = getUser();
  
  const [remsData, setRemsData] = useState({
    data: [],
    total : 0
  });

  const dropSearchKind = ["제목", "업체명"];
  const dropStatusKind = ["접수", "완료"];

  const [paging, setPaging] = useState({ take: 20, skip: 0 });
  const [sort, setSort] = useState([{ field: "", dir: "" }]);

  const [search, setSearch] = useState({
    qestnSj: null,
    companyTerm: null,

  });

  // 페이징 이벤트
  const onPageChange = (e) => {
    paging.skip = e.page.skip;
    paging.take = e.page.take;
    setQnaList(search, paging);
  };

  // 정렬 이벤트
  const onSortChange = (e) => {
    const { sort } = e;
    setSort(sort);
  };
  const [form, setForm] = useState({
    aType: 0,
    bType: 0,
  });
  // 리스트
  const [dataState, setDataState] = useState({
    datalist: [],
    total: 0,
  });

  // 윈도우 글쓰기
  const [windowWrite, setWindowWrite] = useState({
    visible: false,
    left: 350,
    top: 50,
    width: 1100,
    height: 400,
  });

  // 윈도우 답변
  const [windowReply, setWindowReply] = useState({
    visible: false,
    left: 350,
    top: 50,
    width: 1100,
    height: 400,
  });

  // QNA
  const [qnaState, setQnaState] = useState({
    qnaNo: "",
    companyTerm: "",
    regiTerm: "",
    email: "",
    emailAnswerYn: "N",
    lockYn: "N",
    useYn: "",
    qestnSj: "",
    qestnCtnt: "",
    procStatusCode: "",
    answerCtnt: null,
    replyDtm: "",
    viewCnt: "",
    regiDtm: "",
    qestnSj: "",
    userId: loginUser.userId
  });

  // 질문 답변 및 추가문의, 추가 문의 답변 여부 확인
  const [checkState, setCheckState] = useState({
    answerCtnt: null,
    addCtnt: null,
    addAnswerCtnt: null,
  });

  // 추가문의
  const [qnaAddState, setQnaAddState] = useState({
    seq: 1,
    regiDtm: null,
    replyType: 1,
    qestnSj: "[추가문의]",
    procStatusCode: 3,
    lockYn: "N",
    regiId: null,
    ctnt: null,
    regiTerm: null,
    companyTerm: null,
  });
  // 추가답변
  const [qnaAddReplyState, setQnaAddReplyState] = useState({
    seq: 2,
    regiDtm: null,
    replyType: 2,
    qestnSj: null,
    procStatusCode: null,
    lockYn: "N",
    regiId: null,
    ctnt: null,
    regiTerm: null,
    companyTerm: null,
  });

  // 수정, 답변 등 폼 변경 BOOLSTATE
  const [formChange, setFormChange] = useState({
    boolState: false,
    replyState: false,
    updateBody: false,
    addQestnBody: false,
    searchBool: false,
    addQestnReplyBody: false,
    replyUpdate: false,
    addQestnUpdate: false,
    addQestnReplyUpdate: false,
    viewAddQestn: false,
  });

  // 제목 검색
  const appChange = (e) => {
    if (formChange.searchBool == false) {
      setSearch((state) => {
        const item = { ...state };
        item.qestnSj = e.target.value;
        return item;
      });
    } else {
      setSearch((state) => {
        const item = { ...state };
        item.companyTerm = e.target.value;
        return item;
      });
    }
  };

  // 제목 검색
  const appKeyPress = (e) => {
    if (e.key === "Enter") {
      searchHandler();
    }
  };

  // 제목 검색
  const searchHandler = (e) => {
    setQnaList(search, paging);
  };

  const searchCondition = (e) => {
    if (e.value == "제목") {
      setFormChange((state) => {
        const item = { ...state };
        item.searchBool = false;
        return item;
      });
    } else {
      setFormChange((state) => {
        const item = { ...state };
        item.searchBool = true;
        return item;
      });
    }
  };

  // 글쓰기 폼
  const writebbs = (props) => {

    setFormChange((state) => {
      const item = { ...state };
      item.boolState = false;
      return item;
    });
    setWindowWrite((state) => {
      const item = { ...state };
      item.visible = !windowWrite.visible;
      return item;
    });
    setQnaState((state) => {
      const item = { ...state };
      item.viewCnt = 0;
      item.userId = loginUser.userId
      return item;
    });
  };

  // 윈도우폼 취소
  const cancelWrite = () => {
    setWindowWrite({
      visible: !windowWrite.visible,
    });
  };
  const cancelReply = () => {
    setWindowReply({
      visible: !windowReply.visible,
    });
    setForm({
      aType: 0,
    });
    setFormChange((state) => {
      const item = { ...state };
      item.boolState = false;
      return item;
    });
  };

  
  // 파일
  const [fileState, setFileState] = useState({
    fileList: [],
    currentFiles: []
  });

  const [showFileState, setShowFileState] = useState({
    fileList:[]

  })

  const onAddFile = (e) => {
    console.log("onAdd e ", e, fileState);
    setFileState((state) => {
      const item = { ...state };
      item.currentFiles = e.affectedFiles;
      return item;
    });
  };

  const onRemoveFile = (e) => {
    console.log("onRemove e", e, fileState);
    let removeFileIds = [];
    let updateFiles = [];
    fileState.fileList.forEach( f => {
      e.affectedFiles.forEach(v => {
        if(v.uid == f.uid && v.name == f.name)
        removeFileIds.push(f.fileId);
      });
      e.newState.forEach(n => {
        if(f.uid == n.uid && f.name == n.name)
          updateFiles.push(f);
      });
    });
    
    setDeleteFileList(conn, removeFileIds)
    .then( r => {
      alert("삭제되었습니다.");
      setFileState((state) => {
        const item = { ...state };
        item.fileList = updateFiles;
        return item;
      });
    }).catch(e => {
      alert("삭제되지 않았습니다.");
    });

  }

  const onStatusChangeFile = (e) => {
    console.log("onStatusChange e", e, fileState);
    if(e.response?.response){
      let list = [...fileState.fileList, ...e.response.response];
      setFileState((state) => {
        const item = { ...state };
        item.fileList = list;
        item.currentFiles = e.response.response;
        return item;
      });
    }
  };

  // 제목 입력
  const sjChange = (e) => {
    setQnaState((state) => {
      const item = { ...state };
      item.qestnSj = e.target.value;
      return item;
    });
  };

  // 내용 입력
  const ctntChange = (e) => {
    setQnaState((state) => {
      const item = { ...state };
      item.qestnCtnt = e.html.substr(3).replace("</p>", "");
      return item;
    });
  };

  // 답변 내용 변경
  const replyChange = (e) => {
    setQnaState((state) => {
      const item = { ...state };
      item.answerCtnt = e.html.substr(3).replace("</p>", "");
      return item;
    });
  };

  // 추가문의 제목 변경
  const addSjChange = (e) => {
    setQnaAddState((state) => {
      const item = { ...state };
      item.qestnSj = e.target.value;
      return item;
    });
  };

  // 추가문의 내용 변경
  const addCtntChange = (e) => {
    setQnaAddState((state) => {
      const item = { ...state };
      item.ctnt = e.html.substr(3).replace("</p>", "");
      return item;
    });
  };

  // 추가답변 제목 변경
  const addReplySjChange = (e) => {
    setQnaAddReplyState((state) => {
      const item = { ...state };
      item.qestnSj = e.target.value;
      return item;
    });
  };
  // 추가답변 내용변경
  const addReplyCtntChange = (e) => {
    setQnaAddReplyState((state) => {
      const item = { ...state };
      item.ctnt = e.html.substr(3).replace("</p>", "");
      return item;
    });
  };
  // 이메일 변경
  const emailChange = (e) => {
    setQnaState((state) => {
      const item = { ...state };
      item.email = e.target.value;
      return item;
    });
  };

  // 업체명 변경
  const companyChange = (e) => {
    console.log("e.target.value = ", e.target.value)
    setQnaState((state) => {
      const item = { ...state };
      item.userId = e.target.value;
      return item;
    });
  };

  const regiChange = (e) => {
    setQnaState((state) => {
      const item = { ...state };
      item.regiTerm = e.target.value;
      return item;
    });
  };

  const secretQna = (e) => {
    if (e.value == true) {
      setQnaState((state) => {
        const item = { ...state };
        item.lockYn = "Y";
        return item;
      });
    } else {
      setQnaState((state) => {
        const item = { ...state };
        item.lockYn = "N";
        return item;
      });
    }
  };

  const emailReply = (e) => {
    if (e.value == true) {
      setQnaState((state) => {
        const item = { ...state };
        item.emailAnswerYn = "Y";
        return item;
      });
    } else {
      setQnaState((state) => {
        const item = { ...state };
        item.emailAnswerYn = "N";
        return item;
      });
    }
  };

  const statusCondition = (e) => {

    if (e.value == "접수") {
      setQnaState((state) => {
        const item = { ...state };
        item.procStatusCode = 1;
        return item;
      });
    } else if (e.value == "완료") {
      setQnaState((state) => {
        const item = { ...state };
        item.procStatusCode = 2;
        return item;
      });
    } else if (e.value == "추가접수") {
      setQnaState((state) => {
        const item = { ...state };
        item.procStatusCode = 3;
        return item;
      });
    } else if (e.value == "추가완료") {
      setQnaState((state) => {
        const item = { ...state };
        item.procStatusCode = 4;
        return item;
      });
    }
  };

  // Grid - 상세내용
  const expandChange = (props) => {
    
    props.dataItem.expanded = !props.dataItem.expanded;
    setQnaFileList(props.dataItem.qnaNo);

    if (props.dataItem.procStatusCode == 3 || props.dataItem.procStatusCode == 4) {
      setQnaState((state) => {
      const item = { ...state };
      item.qestnSj2 = props.dataItem.qestnSj2;
      return item;
      });
      setFormChange((state) => {
        const item = { ...state };
          item.boolState = false;
        item.replyState = false;
        item.addQestnBody = false;
        item.addQestnReplyBody = false;
        item.replyUpdate = false;
        item.addQestnUpdate = false;
        item.addQestnReplyUpdate = false;
       item.viewAddQestn = true;
      return item;
      })
    } else {
      setFormChange((state) => {
        const item = { ...state };
        item.boolState = false;
        item.replyState = false;
        item.addQestnBody = false;
        item.addQestnReplyBody = false;
        item.replyUpdate = false;
        item.addQestnUpdate = false;
        item.addQestnReplyUpdate = false;
        item.viewAddQestn = false;
        return item;
      });

    }
    viewCnt(props);
    setWindowReply((state) => {
      const item = { ...state };
      item.visible = !windowReply.visible;
      return item;
    });
    setQnaState((state) => {
      const item = { ...state };
      item.qnaNo = props.dataItem.qnaNo;
      item.regiTerm = props.dataItem.regiTerm;
      item.companyTerm = props.dataItem.companyTerm;
      item.qestnSj = props.dataItem.qestnSj;
      item.procStatusCode = props.dataItem.procStatusCode;
      item.qestnCtnt = props.dataItem.qestnCtnt;
      item.email = props.dataItem.email;
      item.answerCtnt = props.dataItem.answerCtnt;
      item.lockYn = props.dataItem.lockYn;
      item.emailAnswerYn = props.dataItem.emailAnswerYn;
      item.replyDtm = props.dataItem.replyDtm;
      item.viewCnt = props.dataItem.viewCnt;
      item.regiDtm = props.dataItem.regiDtm;
      return item;
    });
    setCheckState((state) => {
      const item = { ...state };
      item.answerCtnt = props.dataItem.answerCtnt;
      return item;
    });
   
    setQnaAddState((state) => {
      const item = { ...state };
      item.qestnSj = "[추가문의]";
      return item;
    });
      
    setQnaReplyList(props.dataItem.qnaNo);
  };

  // 추가문의버튼
  const addQestnButtonClick = (props) => {
    setFormChange((state) => {
      const item = { ...state };
      item.boolState = false;
      item.replyState = false;
      item.addQestnBody = true;
      item.addQestnReplyBody = false;
      return item;
    });


    setQnaAddState((state) => {
      const item = { ...state };
      item.seq = 1;
      item.regiDtm = kendo.toString(new Date(), "yyyy-MM-dd HH:mm:ss");
      item.replyType = 1;
      item.procStatusCode = 3;
      item.lockYn = qnaState.lockYn;
      item.regiId = null;
      item.regiTerm = qnaState.regiTerm;
      item.companyTerm = qnaState.companyTerm;
      return item;
    });
  };

  // 추가답변버튼
  const addQestnReplyButtonClick = (props) => {
    setFormChange((state) => {
      const item = { ...state };
      item.boolState = false;
      item.replyState = false;
      item.addQestnBody = false;
      item.addQestnReplyBody = true;
      return item;
    });
    setQnaAddState((state) => {
      const item = { ...state };
      item.procStatusCode = 4;

      return item;
    });
    setQnaAddReplyState((state) => {
      const item = { ...state };
      item.seq = 2;
      item.regiDtm = kendo.toString(new Date(), "yyyy-MM-dd HH:mm:ss");
      item.replyType = 2;
      item.procStatusCode = null;
      item.lockYn = "N";
      item.regiId = null;
      item.regiTerm = qnaState.regiTerm;
      item.companyTerm = qnaState.companyTerm;
      return item;
    });
  };

  // 수정버튼
  const updateButtonClick = (props) => {
    if (formChange.updateBody == true && form.aType == 1) {
      saveQna();
    }
    form.aType = 1;
    if (formChange.replyState == true && form.bType == 1) {
      setReply();
    }
    setCheckState((state) => {
      const item = { ...state };
      item.answerCtnt = null;
      item.addCtnt = null;
      item.addReplyCtnt = null;
      return item;
    });
    // setQnaState((state) => {
    //   const item = { ...state };
    //   item.answerCtnt = null;
    // });
    setFormChange((state) => {
      const item = { ...state };
      item.boolState = true;
      item.replyState = false;
      item.updateBody = true;
      item.addQestnBody = false;
      item.addQestnReplyBody = false;
      item.replyUpdate = false;
      item.addQestnUpdate = false;
      item.addQestnReplyUpdate = false;
      return item;
    });
  };

  // 답변버튼
  const replyButtonClick = (props) => {
    if (formChange.updateBody == true && form.aType == 1) {
      saveQna();
    }
    form.bType = 1;
    if (formChange.replyState == true && form.bType == 1) {
      setReply();
    }

    setFormChange((state) => {
      const item = { ...state };
      item.boolState = false;
      item.replyState = true;
      item.addQestnBody = false;
      item.addQestnReplyBody = false;
      item.replyUpdate = false;
      item.addQestnUpdate = false;
      item.addQestnReplyUpdate = false;
      return item;
    });
  };

  // 답변수정버튼
  const updateReply = (props) => {
    setFormChange((state) => {
      const item = { ...state };
      item.boolState = false;
      item.replyState = false;
      item.updateBody = false;
      item.addQestnBody = false;
      item.addQestnReplyBody = false;
      item.replyUpdate = true;
      item.addQestnUpdate = false;
      item.addQestnReplyUpdate = false;
      return item;
    });

    setCheckState((state) => {
      const item = { ...state };
      item.answerCtnt = null;
      item.addCtnt = null;
      item.addReplyCtnt = null;
      return item;
    });
  };
  // 추가문의수정
  const updateAddQestn = (props) => {
    setFormChange((state) => {
      const item = { ...state };
      item.boolState = false;
      item.replyState = false;
      item.updateBody = true;
      item.addQestnBody = false;
      item.addQestnReplyBody = false;
      item.replyUpdate = false;
      item.addQestnUpdate = true;
      item.addQestnReplyUpdate = false;
      return item;
    });

    setCheckState((state) => {
      const item = { ...state };
      // item.answerCtnt = null;
      item.addCtnt = null;
      item.addReplyCtnt = null;
      return item;
    });
  };
  // 추가답변수정
  const updateAddQestnReply = (props) => {
    setFormChange((state) => {
      const item = { ...state };
      item.boolState = false;
      item.replyState = false;
      item.updateBody = false;
      item.addQestnBody = false;
      item.addQestnReplyBody = false;
      item.replyUpdate = false;
      item.addQestnUpdate = false;
      item.addQestnReplyUpdate = true;
      return item;
    });

    setCheckState((state) => {
      const item = { ...state };
      // item.answerCtnt = null;
      // item.addCtnt = null;
      item.addReplyCtnt = null;
      return item;
    });
  };

  // 삭제버튼
  const deleteButtonClick = (props) => {
    del(qnaState.qnaNo);
  };

  // 리셋버튼
  const resetButtonClick = (props) => {
    setWindowReply((state) => {
      const item = { ...state };
      item.visible = !windowReply.visible;
      return item;
    });
    setForm({
      aType: 0,
    });
  };

  // 답변시 진행상태 Custom
  const replyStateCell = (props) => {
    const wait = "접수대기";
    const register = "접수";
    const success = "완료";
    const addRegister = "추가접수";
    const addSuccess = "추가완료";

    if (props.dataItem.procStatusCode == 0) {
      return <td>{wait}</td>;
    } else if (props.dataItem.procStatusCode == 1) {
      return <td>{register}</td>;
    } else if (props.dataItem.procStatusCode == 2) {
      return <td>{success}</td>;
    } else if (props.dataItem.procStatusCode == 3) {
      return <td>{addRegister}</td>;
    } else if (props.dataItem.procStatusCode == 4) {
      return <td>{addSuccess}</td>;
    }
  };

  const setQnaList = (search, paging) => {
    setLoading(true)
    qnaList(conn, search, paging)
      .then((result) => {
        setRemsData((state) => {
          const item = { ...state };
          item.data = result.results;
          item.total = result.total;
          return item;
        });
        setLoading(false);
      })
      .catch((error) => {
        console.error("error = ", error)
      });
  }

  const setQnaReplyList = (qnaNo) => {
    get(conn, qnaNo)
      .then((result) => {
        if (result.length != 0) {
          setCheckState((state) => {
            const item = { ...state };
            item.addCtnt = result[0].ctnt;
            item.addReplyCtnt = null;
            if (result[1] != null) {
              item.addReplyCtnt = result[1].ctnt;
              return item;
            } else {
              return item;
            }
          });
          setQnaAddState((state) => {
            const item = { ...state };
            item.qestnSj = result[0].qestnSj;
            item.regiDtm = result[0].regiDtm;
            item.ctnt = result[0].ctnt;
            return item;
          });
          if (result[1] != null) {
            setQnaAddReplyState((state) => {
              const item = { ...state };
              item.regiDtm = result[1].regiDtm;
              item.ctnt = result[1].ctnt;
              return item;
            });
          }
        } else {
          setCheckState((state) => {
            const item = { ...state };
            item.addCtnt = null;
            item.addReplyCtnt = null;
            return item;
          });
        }
      })
      .catch((error) => {
        console.log("에러입니다.");
        alert(getMessage(error.errCode));
      });
  };


  const saveQna = () => {
    // 수정
    if (formChange.boolState) {
      var qnaNo = qnaState.qnaNo;
      var regiDtm = qnaState.regiDtm;
      var lockYn = qnaState.lockYn;
      var emailAnswerYn = qnaState.emailAnswerYn;
      var replyDtm = qnaState.replyDtm;
      var answerCtnt = qnaState.answerCtnt;
      var procStatusCode = qnaState.procStatusCode;
      var companyTerm = qnaState.companyTerm;
    }
    //추가
    else {
      var regiDtm = kendo.toString(new Date(), "yyyy-MM-dd HH:mm:ss");
      var useYn = "Y";
      var procStatusCode = 0;
      var lockYn = qnaState.lockYn;
      var emailAnswerYn = qnaState.emailAnswerYn;
      var companyTerm = qnaState.userId;
    }
    var regiTerm = qnaState.regiTerm;
    var viewCnt = qnaState.viewCnt;
    var qestnSj = qnaState.qestnSj;
    var qestnCtnt = qnaState.qestnCtnt;
    var email = qnaState.email;

    var files = fileState.fileList;
    
    const obj = {
      qnaNo: qnaNo,
      companyTerm: companyTerm,
      regiTerm: regiTerm,
      regiDtm: regiDtm,
      email: email,
      emailAnswerYn: emailAnswerYn,
      lockYn: lockYn,
      useYn: useYn,
      qestnSj: qestnSj,
      qestnCtnt: qestnCtnt,
      viewCnt: viewCnt,
      procStatusCode: procStatusCode,
      replyDtm: replyDtm,
      answerCtnt: answerCtnt,
      boardQnaFileDtoList: files,
    };
    if (window.confirm("질문을 저장하시겠습니까?")) {
      setQna(conn, obj)
        .then((result) => {
          alert("저장되었습니다.");
          setQnaList(search, paging);
          setForm((state) => {
            const item = { ...state };
            item.aType = 0;
            return item;
          });
          if (windowWrite.visible == true && windowReply.visible == false) {
            setWindowWrite((state) => {
              const item = { ...state };
              item.visible = !windowWrite.visible;
              return item;
            });
          } else {
            setWindowReply((state) => {
              const item = { ...state };
              item.visible = !windowReply.visible;
              return item;
            });
          }
        })
        .catch((error) => {
          alert(getMessage(error.errCode));
        });
    } else {
      console.log("취소");
      if (windowReply.visible == true && form.aType == 1) {
        setWindowReply((state) => {
          const item = { ...state };
          item.visible = !windowReply.visible;
          return item;
        });
        setForm({
          aType: 0,
        });
      }
    }
  };

  const setReply = () => {
    var qnaNo = qnaState.qnaNo;
    var regiDtm = qnaState.regiDtm;
    var lockYn = qnaState.lockYn;
    var useYn = "Y";
    var emailAnswerYn = qnaState.emailAnswerYn;
    var companyTerm = qnaState.companyTerm;
    var regiTerm = qnaState.regiTerm;
    var viewCnt = qnaState.viewCnt;
    var qestnSj = qnaState.qestnSj;
    var qestnCtnt = qnaState.qestnCtnt;
    var email = qnaState.email;

    var procStatusCode = qnaState.procStatusCode;
    var replyDtm = kendo.toString(new Date(), "yyyy-MM-dd HH:mm:ss");
    var answerCtnt = qnaState.answerCtnt;

    const obj = {
      qnaNo: qnaNo,
      companyTerm: companyTerm,
      regiTerm: regiTerm,
      regiDtm: regiDtm,
      email: email,
      emailAnswerYn: emailAnswerYn,
      lockYn: lockYn,
      useYn: useYn,
      qestnSj: qestnSj,
      qestnCtnt: qestnCtnt,
      viewCnt: viewCnt,

      procStatusCode: procStatusCode,
      replyDtm: replyDtm,
      answerCtnt: answerCtnt,
    };
    if (window.confirm("답변을 저장하시겠습니까?")) {
      setReplyQna(conn, obj)
        .then((result) => {
          alert("저장되었습니다.");
          setCheckState((state) => {
            const item = { ...state };
            item.answerCtnt = result.answerCtnt;
            return item;
          });
          setQnaList(search, paging);
          setWindowReply((state) => {
            const item = { ...state };
            item.visible = !windowReply.visible;
            return item;
          });
        })
        .catch((error) => {
          if (error.errCode === "L007") {
            alert("해당 기능을 사용할 수 있는 권한이 없습니다. 사용중인 계정의 권한을 확인해주세요.")
            return;
          } else {
            alert("알수 없는 오류가 발생하였습니다. 관리자에게 문의해주세요.")
            return;
          }
          
        });
    } else {
      console.log("취소");
      setWindowReply((state) => {
        const item = { ...state };
        item.visible = !windowReply.visible;
        return item;
      });
      setForm({
        aType: 0,
      });
    }
  };

  // 추가문의
  const saveAddQna = () => {
    var qnaNo = qnaState.qnaNo;
    var seq = qnaAddState.seq;
    var regiDtm = qnaAddState.regiDtm;
    var replyType = qnaAddState.replyType; // 식별변호
    var qestnSj = qnaAddState.qestnSj; // 추가접수제목
    var procStatusCode = qnaAddState.procStatusCode; // 진행상태
    var lockYn = qnaAddState.lockYn;
    var regiId = qnaAddState.regiId; // 작성자 아이디
    var ctnt = qnaAddState.ctnt;
    var regiTerm = qnaAddState.regiTerm; // 작성자명
    var companyTerm = qnaAddState.companyTerm; // 업체명

    const obj = {
      qnaNo: qnaNo,
      seq: seq,
      regiDtm: regiDtm,
      replyType: replyType,
      qestnSj: qestnSj,
      procStatusCode: procStatusCode,
      lockYn: lockYn,
      regiId: regiId,
      ctnt: ctnt,
      regiTerm: regiTerm,
      companyTerm: companyTerm,
    };

    if (window.confirm("추가질문을 저장하시겠습니까?")) {
      setAddQna(conn, obj)
        .then((result) => {
          alert("저장되었습니다.");
          setCheckState((state) => {
            const item = { ...state };
            item.addCtnt = result.ctnt;
            return item;
          });
          setQnaList(search, paging);
          setForm((state) => {
            const item = { ...state };
            item.aType = 0;
            return item;
          });
          if (windowWrite.visible == true && windowReply.visible == false) {
            setWindowWrite((state) => {
              const item = { ...state };
              item.visible = !windowWrite.visible;
              return item;
            });
          } else {
            setWindowReply((state) => {
              const item = { ...state };
              item.visible = !windowReply.visible;
              return item;
            });
          }
        })
        .catch((error) => {
          alert(getMessage(error.errCode));
        });
    } else {
      console.log("취소");
      if (windowReply.visible == true && form.aType == 1) {
        setWindowReply((state) => {
          const item = { ...state };
          item.visible = !windowReply.visible;
          return item;
        });
        setForm({
          aType: 0,
        });
      }
    }
  };

  // 추가문의답변
  const saveAddQnaReply = () => {
    var qnaNo = qnaState.qnaNo;
    var seq = qnaAddReplyState.seq;
    var regiDtm = qnaAddReplyState.regiDtm;
    var replyType = qnaAddReplyState.replyType; // 식별변호
    var qestnSj = qnaAddReplyState.qestnSj; // 추가접수제목
    var procStatusCode = qnaAddReplyState.procStatusCode; // 진행상태
    var lockYn = qnaAddReplyState.lockYn;
    var regiId = null; // 작성자 아이디
    var ctnt = qnaAddReplyState.ctnt;
    var regiTerm = null; // 작성자명
    var companyTerm = qnaAddReplyState.companyTerm; // 업체명

    const obj = {
      qnaNo: qnaNo,
      seq: seq,
      regiDtm: regiDtm,
      replyType: replyType,
      qestnSj: qestnSj,
      procStatusCode: procStatusCode,
      lockYn: lockYn,
      regiId: regiId,
      ctnt: ctnt,
      regiTerm: regiTerm,
      companyTerm: companyTerm,
    };

    if (window.confirm("추가질문에 대한 답변을 저장하시겠습니까?")) {
      setAddQnaReply(conn, obj)
        .then((result) => {
          alert("저장되었습니다.");
          setCheckState((state) => {
            const item = { ...state };
            item.addReplyCtnt = result.ctnt;
            return item;
          });
          setQnaList(search, paging);
          setForm((state) => {
            const item = { ...state };
            item.aType = 0;
            return item;
          });
          if (windowWrite.visible == true && windowReply.visible == false) {
            setWindowWrite((state) => {
              const item = { ...state };
              item.visible = !windowWrite.visible;
              return item;
            });
          } else {
            setWindowReply((state) => {
              const item = { ...state };
              item.visible = !windowReply.visible;
              return item;
            });
          }
          var qnaNo = qnaState.qnaNo;
          var seq = qnaAddState.seq;
          var regiDtm = qnaAddState.regiDtm;
          var replyType = qnaAddState.replyType; // 식별변호
          var qestnSj = qnaAddState.qestnSj; // 추가접수제목
          var procStatusCode = 4; // 진행상태
          var lockYn = qnaAddState.lockYn;
          var regiId = qnaAddState.regiId; // 작성자 아이디
          var ctnt = qnaAddState.ctnt;
          var regiTerm = qnaAddState.regiTerm; // 작성자명
          var companyTerm = qnaAddState.companyTerm; // 업체명

          const updateObj = {
            qnaNo: qnaNo,
            seq: seq,
            regiDtm: regiDtm,
            replyType: replyType,
            qestnSj: qestnSj,
            procStatusCode: procStatusCode,
            lockYn: lockYn,
            regiId: regiId,
            ctnt: ctnt,
            regiTerm: regiTerm,
            companyTerm: companyTerm,
          };
          setAddQna(conn, updateObj);
        })
        .catch((error) => {
          if (error.errCode === "L007") {
            alert("해당 기능을 사용할 수 있는 권한이 없습니다. 사용중인 계정의 권한을 확인해주세요.")
            return;
          } else {
            alert("알수 없는 오류가 발생하였습니다. 관리자에게 문의해주세요.")
            return;
          }
          
        });
    } else {
      console.log("취소");
      if (windowReply.visible == true && form.aType == 1) {
        setWindowReply((state) => {
          const item = { ...state };
          item.visible = !windowReply.visible;
          return item;
        });
        setForm({
          aType: 0,
        });
      }
    }
  };

  const del = (dataItem) => {
    if (window.confirm("정말 삭제하시겠습니까?")) {
      setDeleteQna(conn, dataItem)
        .then(() => {
          alert("삭제되었습니다.");
          setQnaList(search, paging);
          setWindowReply((state) => {
            const item = { ...state };
            item.visible = !windowReply.visible;
            return item;
          });
        })
        .catch((error) => {
          if (error.errCode === "L007") {
            alert("해당 기능을 사용할 수 있는 권한이 없습니다. 사용중인 계정의 권한을 확인해주세요.")
            return;
          } else {
            alert("알수 없는 오류가 발생하였습니다. 관리자에게 문의해주세요.")
            return;
          }
        });
    } else {
      console.log("취소");
    }
  };

  const viewCnt = (e) => {
    const data = {
      qnaNo: e.dataItem.qnaNo,
      qestnSj: e.dataItem.qestnSj,
      qestnCtnt: e.dataItem.qestnCtnt,
      emailAnswerYn: e.dataItem.emailAnswerYn,
      lockYn: e.dataItem.lockYn,
      regiDtm: e.dataItem.regiDtm,
      regiTerm: e.dataItem.regiTerm,
      useYn: e.dataItem.useYn,
      viewCnt: e.dataItem.viewCnt,
    };
    setViewCnt(conn, data)
      .then((result) => {
        setQnaState((state) => {
          const item = { ...state };
          item.viewCnt = e.dataItem.viewCnt + 1;
          return item;
        });
      })
      .catch((error) => {
        alert(getMessage(error.errCode));
      });
  };

  const setQnaFileList = (qnaNo) => {
    getQnaFileList(conn, qnaNo)
      .then((result) => {
        setShowFileState((state) => {
          const item = { ...state };
          item.fileList = result.boardQnaFileDtoList;
        return item;
    });
      })
      .catch((error) => {
        console.log("error = ", error)
      });
  };

  useEffect(() => {
    setQnaList(search, paging);
  }, []);

  return (
    <>
      <div className={loading ? "qna-grid" : ""}>
      <div className="uk-grid">
        <div className="uk-width-medium-1-1">
          <div className="md-card TBgA">
            <div className="md-card-content small-padding">
              <span className="searchSubTitleC">
                <a className={styles.writeBtn} onClick={writebbs}>
                  <i className="material-icons2">
                    <Create />
                  </i>
                  글쓰기
                </a>
                {windowWrite.visible && (
                  <QnaAdd
                    windowWrite={windowWrite}
                    writebbs={writebbs}
                    styles={styles}
                    companyChange={companyChange}
                    regiChange={regiChange}
                    emailChange={emailChange}
                    emailReply={emailReply}
                    secretQna={secretQna}
                    sjChange={sjChange}
                    ctntChange={ctntChange}
                    onAddFile={onAddFile}
                    onRemoveFile={onRemoveFile}
                    onStatusChangeFile={onStatusChangeFile}
                    fileState={fileState}
                    saveQna={saveQna}
                    cancelWrite={cancelWrite}
                    showFileState={showFileState}
                    onAddFile={onAddFile}
                    onRemoveFile={onRemoveFile}
                    onStatusChangeFile={onStatusChangeFile}
                    qnaState={qnaState}
                    loginUser={loginUser}
                  ></QnaAdd>
                )}
              </span>
                <QnaSearch
                  styles={styles}
                  dropSearchKind={dropSearchKind}
                  searchCondition={searchCondition}
                  search={search}
                  appChange={appChange}
                  appKeyPress={appKeyPress}
                  searchHandler={searchHandler}
                  searchCondition={searchCondition}
                  formChange={formChange}
                  appKeyPress={appKeyPress}
                  DropDownList={DropDownList}
                ></QnaSearch>
            </div>
          </div>
        </div>
      </div>
      <br></br>
      <div className="uk-grid">
        <div className="uk-width-medium-1-1">
          <div className="Table-scr">
            <QnaGrid
              dataState={dataState}
              paging={paging}
              onPageChange={onPageChange}
              onSortChange={onSortChange}
              sort={sort}
              expandChange={expandChange}
              replyStateCell={replyStateCell}
              remsData={remsData}
            ></QnaGrid>
          </div>
        </div>
        {/* 리스트 */}
        {windowReply.visible && (
          <QnaReply
            windowReply={windowReply}
            checkState={checkState}
            qnaState={qnaState}
            qnaAddState={qnaAddState}
            cancelReply={cancelReply}
            updateReply={updateReply}
            resetButtonClick={resetButtonClick}
            deleteButtonClick={deleteButtonClick}
            updateButtonClick={updateButtonClick}
            addQestnButtonClick={addQestnButtonClick}
            replyButtonClick={replyButtonClick}
            addQestnReplyButtonClick={addQestnReplyButtonClick}
            formChange={formChange}
            styles={styles}
            DropDownList={DropDownList}
            dropStatusKind={dropStatusKind}
            statusCondition={statusCondition}
            replyChange={replyChange}
            sjChange={sjChange}
            ctntChange={ctntChange}
            addSjChange={addSjChange}
            addCtntChange={addCtntChange}
            regiChange={regiChange}
            saveQna={saveQna}
            setReply={setReply}
            saveAddQna={saveAddQna}
            saveAddQnaReply={saveAddQnaReply}
            qnaAddReplyState={qnaAddReplyState}
            addReplySjChange={addReplySjChange}
            addReplyCtntChange={addReplyCtntChange}
            updateAddQestn={updateAddQestn}
            updateAddQestnReply={updateAddQestnReply}
            showFileState={showFileState}
            onAddFile={onAddFile}
            onRemoveFile={onRemoveFile}
            onStatusChangeFile={onStatusChangeFile}
            fileState={fileState}
            loginUserTypeCode={loginUserTypeCode}
          ></QnaReply>
        )}
        </div>
      </div>
      {loading && <p className={styles.qnaLoading}><BeatLoader loading={true} size={24} color='#1e88e5' /></p>}
    </>
  );
});

export default Qna;
