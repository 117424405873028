import { parseDate } from "./../../../com/dateUtil";

export const getAlarmPage = async (conn, dataState) => {
  const { take, skip, userId, userTerm, rgnCode, startDate, endDate } = dataState;

  const config = {
    params: {
      page: (take + skip) / take,
      size: take,
    },
  };

  if (userId) config.params.userId = userId;
  if (userTerm) config.params.userTerm = userTerm;
  if (rgnCode) config.params.rgnCode = rgnCode;
  if (startDate) config.params.startDate = parseDate("yyyy-mm-dd", startDate);
  if (endDate) config.params.endDate = parseDate("yyyy-mm-dd", endDate);

  const result = await conn.get("alarm/log", config);

  return result;
};
