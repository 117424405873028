import { useState, useEffect, useRef } from 'react';
import GathStatusDayChart from './gathStatusDayChart';
import GathStatusDayGrid from './gathStatusDayGrid';
import DatePickerKo from '../../com/dateInputs/datePickerKo';
import { getGathStatusDay } from '../../../service/analy/gathStatusService';
import kendo from '@progress/kendo-ui';
import { Button } from '@progress/kendo-react-buttons';
import styles from './gathStatus.module.css';
import { BeatLoader } from 'react-spinners';

const GathStatusDay = ({ conn, ensoTypeCodeNo }) => {
  const [loading, setLoading] = useState(false);

  // 오늘날
  const today = new Date();
  // 설정한 날짜
  const day = useRef(new Date());
  // 데이터 소스
  const [dataSource, setDataSource] = useState({
    grid: [],
    chart: [],
    x_categories: [],
    excelData: null,
  });

  // 데이터 불러오기
  const getDs = () => {
    setLoading(true);

    getGathStatusDay(conn, getResult, getRejection, ensoTypeCodeNo, day.current, setLoading);
  };

  // 결과 데이터
  const getResult = (result) => {
    // x카테고리 작성
    const x_categories = [];
    for (let i = 0; i < 24; i++) {
      x_categories.push(i);
    }
    // 차트 데이터 생성
    let chart_data = [];
    let col = 0;
    let row = 0;
    for (var i = 0; i < 24 * 4; i++) {
      chart_data.push({
        x: col,
        y: row,
        value: null,
      });
      col += 1;
      if (col >= 24) {
        col = 0;
        row += 1;
      }
    }
    // 차트 데이터 가공
    result.forEach((element) => {
      const date = kendo.toString(new Date(element.gathDtm), 'yyyy-MM-dd HH:mm:ss');
      const strArr = date.split(' ');
      const timeArr = strArr[1].split(':');
      const hh = timeArr[0] * 1;
      const mm = timeArr[1] * 1;
      const idx = hh + (mm / 15) * 24;
      chart_data[idx].value = element.gathRate;
    });
    // 시간 값 변경
    result.forEach((element) => {
      element.gathDtm = kendo.toString(new Date(element.gathDtm), 'yyyy년 MM월 dd일 HH시 mm분');
    });

    setDataSource((dataSource) => {
      const item = { ...dataSource };
      item.grid = result;
      item.chart = chart_data;
      item.x_categories = x_categories;
      return item;
    });
  };

  // rejection 함수
  const getRejection = (error) => {
    // console.log("error = ", error);
  };

  useEffect(() => {
    getDs();
  }, [ensoTypeCodeNo]);

  // 엑셀 다운로드 이벤트
  const onClickEvt = (e) => {
    dataSource.excelData.save();
  };

  // 엑셀 변수 상태 설정
  const setExcelData = (_excelData) => {
    setDataSource((dataSource) => {
      const item = { ...dataSource };
      item.excelData = _excelData;
      return item;
    });
  };

  return (
    <div
      className="uk-width-medium-1-2"
      style={
        loading
          ? {
              opacity: 0.6,
              pointerEvents: 'none',
            }
          : null
      }
    >
      <div className="md-card">
        <div className="md-card-content">
          <div className="md-card-toolbar">
            <h3 className="md-card-toolbar-heading-text">일간 시간별 수집률</h3>
            <div className={styles.gathStatusToolbar}>
              날짜검색&nbsp;&nbsp;
              <DatePickerKo
                id="start"
                name="start"
                className="datePicker"
                defaultValue={today}
                format="yyyy-MM-dd "
                setDtm={(v) => {
                  day.current = v;
                  getDs();
                }}
                max={today}
                view="month"
              />
              <Button className="gathStatusExcelBtn" onClick={onClickEvt}>
                엑셀저장
              </Button>
            </div>
          </div>
          <GathStatusDayChart dataSource={dataSource.chart} x_categories={dataSource.x_categories} />
          <GathStatusDayGrid dataSource={dataSource.grid} ensoTypeCodeNo={ensoTypeCodeNo} setExcelData={setExcelData} />
        </div>
        {loading && (
          <p
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
            }}
          >
            <BeatLoader loading={true} size={24} color="#1e88e5" />
          </p>
        )}
      </div>
    </div>
  );
};

export default GathStatusDay;
