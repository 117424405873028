import { Button } from '@progress/kendo-react-buttons';
import React from 'react';
import './error.css';
import styles from './error.module.css';

const Error = (props) => {
 
  const moveHome = (e) => {
    window.location.href = "/";
  }

  return (
    <div className = { [ styles.pageArea , "uk-grid" ].join(' ') } >
      <div className = "uk-width-medium-1-1">
        <div className = {styles.titleArea}>
          <table className = {styles.titleTable}>
            <colgroup>
              <col width="42%"/>
            </colgroup>
            <tr>
              <td>
                <img className = {styles.errorImg} src = "/images/page/error/errorPageImg1.png" ></img> 
              </td>
              <td>
                <div className = {styles.titleText}> 서버 장애 발생 </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div className = "uk-width-medium-1-1">
        <div className = {styles.contentsArea}>
            <div className = {styles.contentsTextArea}>
              <div className = {styles.contentsText}>일시적으로 이용에 불편을 드리게 되어 진심으로 사과 드립니다.</div>
              <div className = {styles.contentsText}>가능한 빠르게 원활한 서비스를 제공할 수 있도록 최선을 다하고 있습니다.</div>
              <div className = {styles.contentsText}>불편하시더라도 잠시 후에 다시 방문하여 주시기 바랍니다.</div>
            </div>
            <div>
              <Button className = "errorPageConfirmBtn" onClick = {moveHome} >확인</Button>
            </div>
        </div>
      </div>
    </div>
  );
};

export default Error;