// React
import React, { useCallback, useEffect, useState } from "react";

// Kendo
import { Dialog } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";
import { Error } from "@progress/kendo-react-labels";

// Css
import "./installerModal.css";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { IntlProvider } from "@progress/kendo-react-intl";
import { LocalizationProvider } from "@progress/kendo-react-intl";
import { getMessage } from "./../../../com/messageUtil";
import kendo from "@progress/kendo-ui";
import { checkEdsm } from "../../../service/analy/rawKepcoService";
import { pwdRegex } from "./../../../com/regexUtil";

const InstallerModal = ({ modalForm, setModalForm, fixbylaw, rasys, handleModalSaveSubmit, handleModalUpdateSubmit, conn }) => {
  const smsUseYn = ["N", "Y"];
  const emailUseYn = ["N", "Y"];

  // 수정 유무 설정
  modalForm.formItem.createUpdateYn = modalForm.createUpdateYn;
  const isCreateModal = modalForm.createUpdateYn;

  const rasysCode = rasys.filter((e) => e.codeNo === Number(modalForm.formItem.rasysCodeNo) && e.grpCodeNo === 14);
  const fixbylawBund = fixbylaw.filter((e) => e.fixbylawBundCode === modalForm.formItem.fixbylawBundCode);
  rasysCode.length !== 0 ? (modalForm.formItem.rasysCode = rasysCode[0]) : (modalForm.formItem.rasysCode = { codeNo: "", codeVal: "요금제 검색" });
  fixbylawBund.length !== 0
    ? (modalForm.formItem.fixbylawBund = fixbylawBund[0])
    : (modalForm.formItem.fixbylawBund = { fixbylawBundCode: "", fixbylawBundTerm: "주소검색" });

  const idRegex = new RegExp(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/);
  const idValidator = (value) => (!idRegex.test(value) ? "" : "영어만 입력해 주시기 바랍니다.");

  const PwdInput = useCallback((fieldRenderProps) => {
    const { validationMessage, visited, ...others } = fieldRenderProps;
    return (
      <div>
        <Input {...others} />
        {visited && validationMessage && <Error>{validationMessage}</Error>}
      </div>
    );
  });

  const PwdChkInput = useCallback((fieldRenderProps) => {
    const { validationMessage, visited, ...others } = fieldRenderProps;
    return (
      <div>
        <Input {...others} />
        {visited && validationMessage && <Error>{validationMessage}</Error>}
      </div>
    );
  });

  const PhoneInput = useCallback((fieldRenderProps) => {
    const { validationMessage, visited, ...others } = fieldRenderProps;
    return (
      <div>
        <Input {...others} />
        {visited && validationMessage && <Error>{validationMessage}</Error>}
      </div>
    );
  });

  const EmailInput = useCallback((fieldRenderProps) => {
    const { validationMessage, visited, ...others } = fieldRenderProps;
    return (
      <div>
        <Input {...others} />
        {visited && validationMessage && <Error>{validationMessage}</Error>}
      </div>
    );
  });

  // 닫기, 취소 이벤트
  const handleClose = useCallback(() => {
    setModalForm({ ...modalForm, visible: !modalForm.visible, title: "", formItem: [] });
  }, [modalForm]);

  // 저장 버튼
  const handleSubmit = useCallback((e) => {
    kendo
      .confirm("저장하시겠습니까?")
      .then(() => {
        modalForm.title === "추가" ? handleModalSaveSubmit(e) : handleModalUpdateSubmit(e);
      })
      .catch(() => {});
  });

  const [infoVisible, setInfoVisible] = useState(false);
  const [edsmCheck, setEdsmCheck] = useState(false);
  const [edsmResponse, setEdsmResponse] = useState([]);
  const custNoField = (fieldRenderProps) => {
    const { validationMessage, visited, value, ...others } = fieldRenderProps;
    const handleMouseOver = () => {
      setInfoVisible(true);
    };
    const handleMouseOut = () => {
      setInfoVisible(false);
    };
    const kepcoChkBtnClkEvt = (e) => {
      e.preventDefault();
      checkEdsm(conn, value)
        .then((response) => {
          setEdsmResponse(response);
          setEdsmCheck(true);
        })
        .catch((error) => {
          kendo.alert(getMessage(error.errCode));
        });
    };

    const agreeYnCell = (e) => {
      const { dataItem } = e;

      const result = dataItem.agreeYn === "Y" ? <td style={{ color: "blue" }}>연동</td> : <td style={{ color: "red" }}>미연동</td>;
      return result;
    };
    return (
      <div>
        <span style={{ width: "80%", display: "inline-block" }}>
          <Input value={value} {...others} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}></Input>
        </span>
        <span style={{ width: "20%", display: "inline-block" }}>
          <Button className="kepcoChkBtn" onClick={kepcoChkBtnClkEvt}>
            검토
          </Button>
        </span>
        {infoVisible && (
          <div className="kepcoInfoWin">
            여러 번호를 입력 시 , 로 구분해서 입력 해주세요.<br></br>
            ex : 01012345678,01011111111
          </div>
        )}
        {edsmCheck && (
          <Dialog
            title="한전고객 연동여부 확인 결과"
            onClose={() => {
              setEdsmCheck(false);
            }}
            width={"30%"}
          >
            <LocalizationProvider language="ko-KR">
              <IntlProvider locale="ko">
                <Grid style={{ border: "1px solid gainsboro" }} data={edsmResponse}>
                  <GridColumn field="custNo" title="한전고객번호"></GridColumn>
                  <GridColumn field="agreeYn" title="결과 메시지" cell={agreeYnCell}></GridColumn>
                </Grid>
                <div style={{ margin: "5px" }}>※ 미연동인 경우, EDSM 고객 정보에 동의하여야 합니다.</div>
                <Button style={{ float: "right", background: "white", fontWeight: "500" }} onClick={() => setEdsmCheck(false)}>
                  확인
                </Button>
              </IntlProvider>
            </LocalizationProvider>
          </Dialog>
        )}
      </div>
    );
  };

  const [edsmIsChng, setEdsmIsChng] = useState(false);
  useEffect(() => {
    setEdsmIsChng(false);
  }, [edsmResponse]);
  const edsmYnField = (props) => {
    //검토버튼 누를때 props 변경
    if (edsmResponse.length && !edsmIsChng) {
      let agreeYn = "Y";
      edsmResponse.forEach((e) => {
        if (e.agreeYn === "N") agreeYn = "N";
      });
      setEdsmIsChng(true);
      props.onChange({
        value: agreeYn,
      });
    }
    return <Input readOnly={true} value={props.value}></Input>;
  };

  return (
    <Dialog title={modalForm.title} onClose={handleClose} width={"23%"}>
      <Form
        onSubmit={handleSubmit}
        initialValues={modalForm.formItem}
        render={(formRenderProps) => (
          <FormElement>
            <table className="installerModalTable">
              <tbody>
                <tr>
                  <th>ID</th>
                  <td>
                    <Field
                      name="userId"
                      component={Input}
                      placeholder="아이디 입력"
                      readOnly={modalForm.formItem.userId ? true : false}
                      required
                      validator={idValidator}
                    />
                  </td>
                </tr>
                <tr>
                  <th>수용가</th>
                  <td>
                    <Field name="userTerm" component={Input} placeholder="사용자 이름 입력" />
                  </td>
                </tr>
                <tr>
                  <th>읍/면/동</th>
                  <td>
                    <Field
                      name="fixbylawBund"
                      component={DropDownList}
                      data={fixbylaw}
                      textField="fixbylawBundTerm"
                      dataItemKey="fixbylawBundCode"
                      defaultValue={{ fixbylawBundCode: "", fixbylawBundTerm: "주소검색" }}
                      popupSettings={{ className: "autoFitDropDown" }}
                    />
                  </td>
                </tr>
                <tr>
                  <th>비밀번호</th>
                  <td>
                    <Field
                      name="loginPwd"
                      type="password"
                      component={PwdInput}
                      validator={modalForm.title === "수정" ? null : pwdRegex}
                      placeholder={isCreateModal ? "8자 이상 영문,숫자,특수문자 입력" : "수정 시에만 입력"}
                    />
                  </td>
                </tr>
                <tr>
                  <th>비밀번호 확인</th>
                  <td>
                    <Field name="loginPwdChk" type="password" component={PwdChkInput} />
                  </td>
                </tr>
                <tr>
                  <th>연락처</th>
                  <td>
                    <Field name="userCelphon" type="tel" component={PhoneInput} placeholder="010-1234-5678" />
                  </td>
                </tr>
                <tr>
                  <th>이메일</th>
                  <td>
                    <Field
                      name="userEmail"
                      type="email"
                      component={EmailInput}
                      placeholder="myname@example.com"
                      // validator={emailValidator}
                    />
                  </td>
                </tr>
                <tr>
                  <th>한전 고객번호</th>
                  <td>
                    <Field name="custNo" component={custNoField} placeholder="숫자만 입력"></Field>
                  </td>
                </tr>
                <tr>
                  <th>
                    <div>한전 고객번호</div> <div>연동 여부</div>
                  </th>
                  <td>
                    <Field name="edsmYn" component={edsmYnField}></Field>
                  </td>
                </tr>
                <tr>
                  <th>요금제</th>
                  <td>
                    <Field
                      name="rasysCode"
                      component={DropDownList}
                      data={rasys}
                      textField="codeVal"
                      dataItemKey="codeNo"
                      defaultValue={{ codeNo: "", codeVal: "요금제 검색" }}
                    />
                  </td>
                </tr>
                <tr>
                  <th>알림톡 수신</th>
                  <td>
                    <Field name="kakaoNotiUseYn" component={DropDownList} data={smsUseYn} defaultValue={smsUseYn[0]} />
                  </td>
                </tr>
                {/* <tr><td>
                  <Field name="emailNotiUseYn" component={DropDownList} data={emailUseYn} defaultValue={emailUseYn[0]} />
                </td></tr> */}
              </tbody>
            </table>
            <span className="installerModalSubmitBtnArea">
              <Button onClick={handleClose}>취소</Button>
              <Button type="submit" disabled={!formRenderProps.allowSubmit}>
                저장
              </Button>
            </span>
          </FormElement>
        )}
      />
    </Dialog>
  );
};

export default InstallerModal;
