import { numFormat } from "../../com/dataUtil";

export const getLocationEnergyList = async (conn, param) => {
  const response = await conn.get("location/depth1/list", {
    params: {
      bizSct: param.bizSct,
      bldSrvCodeNo: param.bldSrvCodeNo,
      bizYy: param.bizYy,
      rgnCode: param.rgnCode,
      fixbylawBundCode: param.fixbylawBundCode,
    },
  });

  return { locationEnergyList: response };
};

export const getAreaInstallMeainStatData = async (conn, param) => {
  const response = await conn.get("location/depth1/count", {
    params: {
      bizSct: param.bizSct,
      bldSrvCodeNo: param.bldSrvCodeNo,
      bizYy: param.bizYy,
      rgnCode: param.rgnCode,
      fixbylawBundCode: param.fixbylawBundCode,
    },
  });

  return { rgnNormalCnt: response };
};
