/*
 * [드롭다운 데이터소스 #1]
 *  사업연도 리스트 DS 제작
 */
export const yearList = () => {
  const today = new Date();
  const Y = today.getFullYear();
  let yearList = [];
  for (var i = Y + 1; i >= 2000; i--) {
    yearList.push(i);
  }
  return yearList;
};

/*
 * [드롭다운 데이터소스 #2]
 *  포트번호 리스트 데이터 소스 제작
 */
export const portList = () => {
  /* 설비 포트(port) */
  const PORT_MIN_NUM = 0;
  const PORT_MAX_NUM = 3;
  // 설비 포트 리스트 제작
  var portList = [];
  for (var i = PORT_MIN_NUM; i <= PORT_MAX_NUM; i++) {
    portList.push(i);
  }
  return portList;
};

/*
 * [드롭다운 데이터소스 #3]
 *  통신방식 데이터 소스 제작
 */
export const communicationMthdList = () => {
  // 설비 포트 리스트 제작
  // var communicationMthdList = [
  //     "TCP/IP(OPC-UA)","LTE","WI-FI","LoRa(SKT)","NBIoT(KT)","NBIoT(LG)","HTTPS"
  // ];
  // const communicationMthdList = ['HTTPS(LTE, WI-FI)', 'LoRa(SKT)'];
  const communicationMthdList = ["LoRa(SKT)", "기타(운영준비중)", "서버 연계"];
  return communicationMthdList;
};

/*
 * [드롭다운 데이터소스 #3]
 *  통신모듈 데이터 소스 제작
 */
export const cmnctMdlList = () => {
  // 설비 포트 리스트 제작
  const cmnctMdlList = ["일체형", "분리형"];
  return cmnctMdlList;
};
