import { React, useState, useEffect } from "react";
import { Route, Switch } from "react-router-dom";

import Login from "./components/login/login";
import Header from "./components/header";
import MenuNavigatorBackup from "./components/menuNavigatorBackup";
import Error from "./components/error";
import Ready from "./components/ready";
import MenuRole from "./components/menu/menuRole/menuRole";
import InstallerGath from "./components/monitor/installerGath/installerGath";
import InstallerMeain from "./components/monitor/installerMeain/installerMeain";
import AreaEnergy from "./components/monitor/area/areaEnergy";
import EventLog from "./components/mngt/eventLog/eventLog.jsx";
import CodeMngt from "./components/code/codeMngt.jsx";
import Manager from "./components/mngt/manager/manager.jsx";
import Meain from "./components/meain/meain/meain";
import MenuApi from "./components/menu/menuApi/menuApi";
import MenuMngt from "./components/menu/menuMngt";
import Noti from "./components/bbs/noti";
import OperationStat from "./components/monitor/operation/operationStat";
import GathRawDataLog from "./components/stats/operHist/gathRawDataLog";
import RegiLora from "./components/meain/LoRa/regiLora";
import RegiLoraLog from "./components/meain/LoRaLog/regiLoraLog";
import Qna from "./components/bbs/qna";
import CmpEnso from "./components/stats/type/enso/cmpEnso";
import Installer from "./components/mngt/installer/installer";
import Iotkt from "./components/meain/iotMakers/iotkt";
import IotktLog from "./components/meain/iotMakersLog/iotktLog";
import CmpArea from "./components/stats/type/area/cmpArea";
import GathStatus from "./components/analy/gathStatus/gathStatus";
import UserInfo from "./components/mngt/userInfo/userInfo";
import LocationDepth1 from "./components/location/depth1/locationDepth1";
import RtuMngt from "./components/mngt/rtuEnte/rtuMngt";
import ReportDay from "./components/report/total/reportDay";
import WeathCmp from "./components/analy/weathCmp/weathCmp";
import { LocationDepth3 } from "./components/location/depth3/locationDepth3.jsx";
import Sys from "./components/sys/sys/sys";
import SysServer from "./components/sys/sysServer/sysServer";
import BatchProcHist from "./components/sys/batchProcHist/batchProcHist";
import Enso from "./components/stats/event/enso";
import Consm from "./components/stats/event/consm/consm";
import Makr from "./components/stats/event/makr/makr";
import ReportConsm from "./components/report/consm/reportConsm";
import CmpUserByUser from "./components/stats/type/userByUser/cmpUserByUser";
import UserBetweenUser from "./components/stats/type/userBetweenUser/userBetweenUser";
import NonOperHist from "./components/stats/nonOperHist/nonOperHist";
import VisitorStatistics from "./components/sys/visitorStatistics/visitorStatistics";
import StatsByConsm from "./components/stats/type/consm/statsByConsm";

import queryString from "query-string";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { isLogin } from "./service/authService";
import MakrMngt from "./components/meain/makr/makrMngt";
import MeainModel from "./components/meain/meainModel/meainModel";
import GroupInstaller from "./components/mngt/groupInstaller/groupInstaller";
import GroupManager from "./components/mngt/groupManager/groupManager";
import ExpctConsm from "./components/stats/expct/consm/expctConsm";
import ExpctBuildBsman from "./components/stats/expct/buildBsman/expctBuildBsman";
import MenuNavigator from "./components/menuNavigator";
import AlarmLog from "./components/mngt/alarmLog/alarmLog";
import EdsmCmp from "./components/analy/edsm/edsmCmp";
import EdsmLog from "./components/analy/edsm/edsmLog";
import ThermalCmp from "./components/analy/thermal/thermalCmp";
import TempMonitorDraft from "./components/monitor/tempDashboard01/tempMonitorDraft";
import RenewStat from "./components/monitor/renew/renewStat";
import BpmRps from "./components/meain/bpmRps/bpmRps";
import MonitorAS from "./components/meain/monitorAS/monitorAS";
import RenewStatV2 from "./components/monitor/renew/renewStatV2";
import PopUp from "./components/popUp.jsx";

function Contents({ codeList, rgnList, dongList, conn, loginStatus, setUpdateLoginStatus, setError, roleMenu, getRoleMenu, menuState, setSelectMenu }) {
  const location = useLocation();
  const isMobile = localStorage.getItem("isMobile");
  if (location.pathname == "/login") document.body.style.backgroundColor = "#01579B";
  else document.body.style.backgroundColor = "#f5f5f5";

  const history = useHistory();
  if (location.pathname === "/") {
    getRoleMenu("/monitor/renewStat");
    history.push("/monitor/renewStat");
  }

  const setContentLayoutMargin = (url) => {
    const page_content = document.getElementById("page_content");
    const page_content_inner = document.getElementById("page_content_inner");
    if (isMobile === "N") {
      if (url === "/location/thridDepth" || url.includes("draft")) {
        page_content.style.margin = "12px 0px 0px 0px";
        page_content_inner.style.padding = "10px 0px 0px 0px";
      } else {
        page_content.style.margin = "40px 0 0px 0";
        page_content_inner.style.padding = "10px 24px 24px 24px";
      }
    }
  };
  useEffect(() => {
    setContentLayoutMargin(history.location.pathname);
  }, [history.location.pathname]);

  useEffect(() => {
    setUpdateLoginStatus(true);
    if (!isLogin()) {
      // 로그인 여부 확인
      setUpdateLoginStatus(false);
      history.push("/");
    }
  }, []);
  return (
    <div id="app">
      <Header
        conn={conn}
        setUpdateLoginStatus={setUpdateLoginStatus}
        roleMenu={roleMenu}
        getRoleMenu={getRoleMenu}
        menuState={menuState}
        setSelectMenu={setSelectMenu}
        loginStatus={loginStatus}
      />

      <PopUp
        name={'inquiry-introduce-popup'}
        content={<div><img src={'/images/popup/inquiry.png'} alt="popup"/></div>}
      />

      <MenuNavigator roleMenu={roleMenu} getRoleMenu={getRoleMenu} setSelectMenu={setSelectMenu}>
        <Switch>
          <Route exact path="/error" render={() => <Error />} />
          <Route
            exact
            path="/login"
            render={() => <Login getRoleMenu={getRoleMenu} conn={conn} setUpdateLoginStatus={setUpdateLoginStatus} loginStatus={loginStatus} />}
          />
          {/* 메뉴 01 - 대시보드*/}
          {/* <Route exact path="/monitor/areaEnergy" render= {() => <AreaEnergy conn={conn} codeList={codeList} setError={setError} rgnList={rgnList} />} /> */}
          <Route exact path="/monitor/renewStat" render={() => <RenewStat conn={conn} codeList={codeList} setError={setError} rgnList={rgnList} />} />
          <Route exact path="/monitor/areaEnergy" render={() => <AreaEnergy conn={conn} codeList={codeList} setError={setError} rgnList={rgnList} />} />
          <Route
            exact
            path="/monitor/installerMeain"
            render={() => <InstallerMeain conn={conn} qs={queryString} codeList={codeList} rgnList={rgnList} dongList={dongList} />}
          />
          <Route exact path="/monitor/draft" render={() => <RenewStatV2 conn={conn} codeList={codeList} setError={setError} rgnList={rgnList} />} />
          <Route exact path="/monitor/draft2" render={() => <TempMonitorDraft conn={conn} codeList={codeList} setError={setError} rgnList={rgnList} />} />
          {/* <Route exact path="/monitor/draft" render={() => <TempMonitorDraft />} /> */}
          {/* 메뉴 02 - 설치현황 */}
          <Route exact path="/location/firstDepth" render={() => <LocationDepth1 conn={conn} codeList={codeList} rgnList={rgnList} dongList={dongList} />} />
          {/* <Route exact path="/location/firstDepth" render={() => <CmpArea conn={conn}  codeList={codeList}  rgnList={rgnList} />} /> */}
          {/* <Route exact path="/location/secondDepth" render={() => <LocationDepth2 conn={conn}  codeList={codeList}/>} /> */}
          <Route
            exact
            path="/location/thridDepth"
            render={() => <LocationDepth3 conn={conn} codeList={codeList} rgnList={rgnList} dongList={dongList} qs={queryString} />}
          />
          {/* 메뉴 03 - 비교 */}
          <Route exact path="/compare/enso" render={() => <CmpEnso conn={conn} codeList={codeList} />} />
          <Route exact path="/compare/area" render={() => <CmpArea conn={conn} codeList={codeList} rgnList={rgnList} />} />
          <Route exact path="/compare/edsm" render={() => <EdsmCmp conn={conn} codeList={codeList} rgnList={rgnList} dongList={dongList} />} />
          <Route exact path="/compare/thermal" render={() => <ThermalCmp conn={conn} codeList={codeList} rgnList={rgnList} dongList={dongList} />} />
          <Route exact path="/compare/weather" render={() => <WeathCmp conn={conn} codeList={codeList} rgnList={rgnList} />} />
          {/* 메뉴 04 - 예측 */}
          <Route exact path="/expct/consm" render={() => <ExpctConsm conn={conn} rgnList={rgnList} dongList={dongList} />} />
          <Route exact path="/expct/buildBsman" render={() => <ExpctBuildBsman conn={conn} rgnList={rgnList} dongList={dongList} />} />
          {/* 메뉴 05 - 이력 */}
          <Route exact path="/logs/gathRawData" render={() => <GathRawDataLog conn={conn} codeList={codeList} rgnList={rgnList} dongList={dongList} />} />
          <Route exact path="/logs/nonOperHist" render={() => <NonOperHist conn={conn} codeList={codeList} rgnList={rgnList} />} />
          <Route exact path="/logs/alarm" render={() => <AlarmLog conn={conn} codeList={codeList} rgnList={rgnList} dongList={dongList} />} />
          {/* 메뉴 06 - 보고서 */}
          <Route exact path="/report" render={() => <ReportDay conn={conn} codeList={codeList} rgnList={rgnList} />} />
          {/* 메뉴 07 - 계정관리 */}
          <Route exact path="/user/installer" render={() => <Installer conn={conn} codeList={codeList} dongList={dongList} />} />
          <Route exact path="/user/manager" render={() => <Manager conn={conn} rgnList={rgnList} dongList={dongList} />} />
          <Route exact path="/user/groupManager" render={() => <GroupManager conn={conn} rgnList={rgnList} dongList={dongList} />} />
          <Route exact path="/user/myself" render={() => <UserInfo conn={conn} />} />
          {/* 메뉴 08 - 설비관리 */}
          <Route exact path="/meain" render={() => <Meain conn={conn} codeList={codeList} rgnList={rgnList} dongList={dongList} />} />
          <Route exact path="/meain/iot/sk" render={() => <RegiLora conn={conn} qs={queryString} />} />
          <Route exact path="/meain/iot/sk/log" render={() => <RegiLoraLog conn={conn} />} />
          <Route exact path="/meain/iot/kt" render={() => <Iotkt conn={conn} />} />
          <Route exact path="/meain/iot/kt/log" render={() => <IotktLog conn={conn} />} />
          <Route exact path="/meain/makrMngt" render={() => <MakrMngt conn={conn} codeList={codeList} />} />
          <Route exact path="/meain/modelMngt" render={() => <MeainModel conn={conn} codeList={codeList} />} />
          <Route exact path="/meain/bpmRps" render={() => <BpmRps conn={conn} codeList={codeList} rgnList={rgnList} />} />
          <Route exact path="/meain/mntrAS" render={() => <MonitorAS conn={conn} codeList={codeList} rgnList={rgnList} dongList={dongList} />} />

          {/* 메뉴 10 - 개발자메뉴 */}

          <Route exact path="/mngt/rtu" render={() => <RtuMngt conn={conn} codeList={codeList} rgnList={rgnList} dongList={dongList} />} />

          <Route exact path="/report/consm" render={() => <ReportConsm conn={conn} rgnList={rgnList} dongList={dongList} />} />

          <Route
            exact
            path="/stats/compare/userbyuser"
            render={() => <CmpUserByUser conn={conn} codeList={codeList} rgnList={rgnList} dongList={dongList} />}
          />
          <Route exact path="/stats/compare/userBetweenUser" render={() => <UserBetweenUser conn={conn} codeList={codeList} rgnList={rgnList} />} />
          <Route exact path="/stats/compare/event" render={() => <Ready />} />

          <Route exact path="/stats/raw/gath" render={() => <GathStatus conn={conn} codeList={codeList} />} />

          <Route exact path="/stats/consm" render={() => <StatsByConsm conn={conn} codeList={codeList} rgnList={rgnList} dongList={dongList} />} />

          <Route exact path="/stats/edsm/log" render={() => <EdsmLog conn={conn} codeList={codeList} rgnList={rgnList} dongList={dongList} />} />

          <Route
            exact
            path="/mngt/analy/event/log"
            render={() => <EventLog conn={conn} codeList={codeList} rgnList={rgnList} dongList={dongList} qs={queryString} />}
          />
          <Route exact path="/board/notification" render={() => <Noti conn={conn} />} />
          <Route exact path="/board/qna" render={() => <Qna conn={conn} />} />
          <Route exact path="/board/archive" render={() => <Ready />} />

          <Route exact path="/monitor/server" render={() => <Ready />} />

          <Route exact path="/mngt/code" render={() => <CodeMngt conn={conn} />} />

          <Route exact path="/mngt/menu" render={() => <MenuMngt conn={conn} />} />
          <Route exact path="/mngt/menu/role" render={() => <MenuRole conn={conn} />} />
          <Route exact path="/mngt/menu/api" render={() => <MenuApi conn={conn} />} />

          <Route exact path="/sys/sys" render={() => <Sys conn={conn} codeList={codeList} />} />
          <Route exact path="/sys/sysServer" render={() => <SysServer conn={conn} codeList={codeList} />} />
          <Route exact path="/sys/batchProcHist" render={() => <BatchProcHist conn={conn} codeList={codeList} />} />
          <Route exact path="/sys/visitor" render={() => <VisitorStatistics conn={conn} codeList={codeList} />} />

          <Route exact path="/stats/event/enso" render={() => <Enso conn={conn} codeList={codeList} />} />

          <Route exact path="/stats/event/consm/consm" render={() => <Consm conn={conn} codeList={codeList} rgnList={rgnList} dongList={dongList} />} />

          <Route exact path="/stats/event/makr/makr" render={() => <Makr conn={conn} codeList={codeList} rgnList={rgnList} />} />

          {/* Ready 페이지 라우트 추가 */}
          <Route exact path="/ready" render={() => <Ready />} />

          <Route exact path="/mngt/group/installer" render={() => <GroupInstaller conn={conn} codeList={codeList} rgnList={rgnList} dongList={dongList} />} />

          <Route exact path="/monitor/operationStat" render={() => <OperationStat conn={conn} />} />
          <Route exact path="/monitor/installerGath" render={() => <InstallerGath conn={conn} codeList={codeList} rgnList={rgnList} dongList={dongList} />} />
        </Switch>
      </MenuNavigator>
    </div>
  );
}

export default Contents;
