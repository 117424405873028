import React, { useEffect } from "react";
const RenewStatV2 = (props) => {
  useEffect(() => {}, []);
  return (
    <div style={{ width: "1850px" }}>
      <img src="/images/dashboard_v2.png" alt="대시보드디자인"></img>
    </div>
  );
};

export default RenewStatV2;
