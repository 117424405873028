// import QnaDetail from "./qnaDetail";
import React, { useState, useEffect, memo } from "react";
import kendo from "@progress/kendo-ui";

import {
  Grid,
  GridColumn as Column,
  GridNoRecords,
} from "@progress/kendo-react-grid";

import {
  IntlProvider,
  LocalizationProvider,
  loadMessages,
} from "@progress/kendo-react-intl";

const QnaGrid = ({
  dataState,
  paging,
  onPageChange,
  onSortChange,
  sort,
  expandChange,
  replyStateCell,
  remsData
}) => {
  const cell = (props) => {
    if (props.dataItem.lockYn == "Y") {
      if (props.dataItem.procStatusCode == 3 || props.dataItem.procStatusCode == 4) {
        return (
          <td style={{ textAlign: "left" }}>
            <span className='k-icon k-i-arrow-right'></span>{props.dataItem.qestnSj} <span className="k-icon k-i k-i-lock"></span>
          </td>
        );
      } else {
        return (
          <td style={{ textAlign: "left" }}>
            {props.dataItem.qestnSj} <span className="k-icon k-i k-i-lock"></span>
          </td>
        );
      }
    } else {
     if (props.dataItem.procStatusCode == 3 || props.dataItem.procStatusCode == 4) {
        return (
          <td style={{ textAlign: "left" }}>
            <span className='k-icon k-i-arrow-right'></span>{props.dataItem.qestnSj} 
          </td>
        );
      } else {
        return (
          <td style={{ textAlign: "left" }}>
            {props.dataItem.qestnSj} 
          </td>
        );
      }
    }
  };

  const qnaNoCell = (props) => {
    console.log("qnaNoCell props = ", props)
    var record = 0;
    // record = (props.dataItem.qnaNo - props.dataIndex)
    //record = this.dataSource.total() - ((this.dataSource.page() -1) * this.dataSource.pageSize());
    record = remsData.total - props.dataIndex;
    // return <td>{props.dataItem.rnnum}</td>
    return <td>{record}</td>
    
  }
  const dateFormatCell = (props) => {
    var date = kendo.toString(new Date(props.dataItem.regiDtm), "yyyy-MM-dd");
    return <td>{date}</td>
  }

  return (
    <>
      <LocalizationProvider language="ko-KR">
        <IntlProvider locale="ko">
          <Grid
            className="qnaGrid"
            style={{ height: "700px" }}
            data={remsData.data}
            total={remsData.total}
            // data={dataState.datalist}
            // total={dataState.total}
            skip={paging.skip}
            take={paging.take}
            onPageChange={onPageChange}
            onSortChange={onSortChange}
            sort={sort}
            page={paging}
            sortable
            pageable={{
              type: "numeric",
              pageSizes: [5, 10, 20, 50, 100],
              previousNext: true,
              buttonCount: 5,
            }}
            onRowClick={expandChange}
            // detail={checkState.addCtnt ? QnaDetail : null}
          >
            <Column
              headerClassName="gridHeader"
              field="qnaNo"
              format="{0:n0}"
              title="글번호"
              width="90px"
              cell={qnaNoCell}
            />
            <Column
              headerClassName="gridHeader"
              field="qestnSj"
              title="제목"
              cell={cell}
            />
            <Column
              className="gridBody"
              headerClassName="gridHeader"
              field="companyTerm"
              title="업체명"
              width="120px"
            />
            <Column
              headerClassName="gridHeader"
              field="regiTerm"
              title="작성자"
              width="120px"
            />
            <Column
              headerClassName="gridHeader"
              field="procStatusCode"
              cell={replyStateCell}
              title="진행상태"
              width="160px"
            />
            <Column
              headerClassName="gridHeader"
              field="regiDtm"
              title="작성일자"
              cell={dateFormatCell}
              width="180px"
            />
            <GridNoRecords>데이터가 존재하지 않습니다.</GridNoRecords>
          </Grid>
        </IntlProvider>
      </LocalizationProvider>
    </>
  );
};
export default QnaGrid;
