import NotiAdd from "./notiAdd";
import NotiGrid from "./notiGrid";
import NotiSearch from "./notiSearch";

import "./noti.css";
import styles from "./noti.module.css";
// import "./noti.dk.css";
// import styles from "./noti.dk.module.css";

import { getNotiList } from "../../service/bbs/notiService";
import { setNoti } from "../../service/bbs/notiService";
import { setDeleteNoti } from "../../service/bbs/notiService";
import { setViewCnt } from "../../service/bbs/notiService";

import { getMessage } from "../../com/messageUtil";
import { setDeleteFileList } from "../../service/fileService";
import { getUserTypeCode } from "../../service/authService";

import React, { useState, useEffect, memo } from "react";
import { BeatLoader } from "react-spinners";

import kendo from "@progress/kendo-ui";
import { Create, Delete } from "@material-ui/icons";
import { loadMessages } from "@progress/kendo-react-intl";
import koMessage from "../../com/ko-language.json";
loadMessages(koMessage, "ko-KR");

const Noti = memo(({ conn }) => {
  const isMobile = localStorage.getItem("isMobile");
  const innerWidth = window.innerWidth;
  const innerHeight = window.innerHeight;
  // 로딩 스피너
  const [loading, setLoading] = useState(false);

  const loginUserTypeCode = getUserTypeCode();

  const [paging, setPaging] = useState({ take: 20, skip: 0 });
  const [sort, setSort] = useState([{ field: "", dir: "" }]);

  const [search, setSearch] = useState({
    title: "",
  });

  // 페이징 이벤트
  const onPageChange = (e) => {
    paging.skip = e.page.skip;
    paging.take = e.page.take;
    setNotiList(search, paging);
  };

  // 정렬 이벤트
  const onSortChange = (e) => {
    const { sort } = e;
    setSort(sort);
  };

  // 리스트
  const [dataState, setDataState] = useState({
    datalist: [],
    total: 0,
  });

  const [data, setData] = useState({
    noticeNo: "",
    titl: "",
    ctnt: "",
    viewCnt: 0,
    regiDtm: "",
    noticeYn: "N",
  });

  // 윈도우
  const [windowState, setWindowState] = useState({
    visible: false,
    left: isMobile === "Y" ? 0 : 350,
    top: isMobile === "Y" ? 0 : 50,
    width: isMobile === "Y" ? innerWidth : 1100,
    height: isMobile === "Y" ? innerHeight : 400,
  });

  // 파일
  const [fileState, setFileState] = useState({
    fileList: [],
    currentFiles: [],
  });

  const onAddFile = (e) => {
    setFileState((state) => {
      const item = { ...state };
      item.currentFiles = e.affectedFiles;
      return item;
    });
  };

  const onRemoveFile = (e) => {
    let removeFileIds = [];
    let updateFiles = [];
    fileState.fileList.forEach((f) => {
      e.affectedFiles.forEach((v) => {
        if (v.uid == f.uid && v.name == f.name) removeFileIds.push(f.fileId);
      });
      e.newState.forEach((n) => {
        if (f.uid == n.uid && f.name == n.name) updateFiles.push(f);
      });
    });

    setDeleteFileList(conn, removeFileIds)
      .then((r) => {
        alert("삭제되었습니다.");
        setFileState((state) => {
          const item = { ...state };
          item.fileList = updateFiles;
          return item;
        });
      })
      .catch((e) => {
        alert("삭제되지 않았습니다.");
      });
  };

  const onStatusChangeFile = (e) => {
    if (e.response?.response) {
      let list = [...fileState.fileList, ...e.response.response];
      setFileState((state) => {
        const item = { ...state };
        item.fileList = list;
        item.currentFiles = e.response.response;
        return item;
      });
    }
  };

  // 제목 입력
  const titlChange = (e) => {
    setData((state) => {
      const item = { ...state };
      item.titl = e.target.value;
      return item;
    });
  };

  // 내용 입력
  const ctntChange = (e) => {
    setData((state) => {
      const item = { ...state };
      item.ctnt = e.html.substr(3).replace("</p>", "");
      return item;
    });
  };

  // 글쓰기 폼
  const writebbs = (props) => {
    setWindowState((state) => {
      const item = { ...state };
      item.visible = !windowState.visible;
      return item;
    });
    setData({
      viewCnt: 0,
      noticeNo: "",
    });

    setFileState((state) => {
      const item = { ...state };
      item.fileList = [];
      return item;
    });
    expandChange(false);
  };

  // 수정 폼
  const updatebbs = (props) => {
    setWindowState((state) => {
      const item = { ...state };
      item.visible = !windowState.visible;
      return item;
    });
    setData((state) => {
      const item = { ...state };
      item.titl = props.dataItem.titl;
      item.ctnt = props.dataItem.ctnt;
      item.noticeNo = props.dataItem.noticeNo;
      item.viewCnt = props.dataItem.viewCnt;
      item.regiDtm = props.dataItem.regiDtm;
      return item;
    });

    setFileState((state) => {
      const item = { ...state };
      item.fileList = props.dataItem.boardNoticeFileDtoList;
      return item;
    });
  };

  // Grid - 수정 아이콘
  const updateCell = (props) => {
    return (
      <td>
        <a onClick={() => updatebbs(props)}>
          <i>
            <Create />
          </i>
        </a>
      </td>
    );
  };

  // Grid - 삭제 아이콘
  const deleteCell = (props) => {
    const { dataItem } = props;
    return (
      <td>
        <a onClick={() => del(dataItem)}>
          <i>
            <Delete />
          </i>
        </a>
      </td>
    );
  };

  // Grid - 데이터 가운데 정렬
  const alignCell = (props) => {
    return <td style={{ textAlign: "left" }}>{props.dataItem[props.field]}</td>;
  };

  // Grid - 상세내용
  const expandChange = (event) => {
    if (event == false) {
    } else {
      event.dataItem.expanded = !event.dataItem.expanded;

      event.dataItem.pwd = conn;
      viewCnt(event);
    }
  };

  // 윈도우폼 취소
  const cancel = () => {
    setWindowState({
      visible: !windowState.visible,
    });
  };

  // 제목 검색
  const appChange = (e) => {
    setSearch({
      [e.target.name]: e.target.value,
    });
  };

  // 제목 검색
  const appKeyPress = (e) => {
    if (e.key === "Enter") {
      searchHandler();
    }
  };

  // 제목 검색
  const searchHandler = (e) => {
    setNotiList(search, paging);
  };

  const notiNoCell = (props) => {
    if (props.dataItem.noticeYn === "Y") {
      return <td>[공지]</td>;
    } else {
      var record = 0;
      record = dataState.total - props.dataIndex;
      return <td>{record}</td>;
    }
  };
  const noticeChange = (e) => {
    if (e.value == true) {
      setData((state) => {
        const item = { ...state };
        item.noticeYn = "Y";
        return item;
      });
    } else {
      setData((state) => {
        const item = { ...state };
        item.noticeYn = "N";
        return item;
      });
    }
  };

  const setNotiList = (search, paging) => {
    setLoading(true);
    getNotiList(conn, search, paging)
      .then((result) => {
        setDataState((state) => {
          const item = { ...state };
          item.datalist = result.results;
          item.total = result.total;
          return item;
        });
        setLoading(false);
      })
      .catch((error) => {
        alert(getMessage(error.errCode));
      });
  };

  const saveNoti = () => {
    // 수정
    if (data.noticeNo != "") {
      var noticeNo = data.noticeNo;
      var regiDtm = data.regiDtm;
      var viewCnt = data.viewCnt;
      var noticeYn = data.noticeYn;
      var files = fileState.fileList;
    }
    // 추가
    else {
      var pubYn = "N";
      var noticeYn = data.noticeYn;
      var regiDtm = kendo.toString(new Date(), "yyyy-MM-dd HH:mm:ss");
      var viewCnt = data.viewCnt;
      var files = fileState.fileList;
    }
    var titl = data.titl;
    var ctnt = data.ctnt;

    var modifyDtm = kendo.toString(new Date(), "yyyy-MM-dd HH:mm:ss");

    const obj = {
      noticeNo: noticeNo,
      titl: titl,
      ctnt: ctnt,
      viewCnt: viewCnt,
      pubYn: pubYn,
      noticeYn: noticeYn,
      regiDtm: regiDtm,
      modifyDtm: modifyDtm,
      boardNoticeFileDtoList: files,
    };
    if (window.confirm("저장하시겠습니까?")) {
      setNoti(conn, obj)
        .then((result) => {
          setWindowState((state) => {
            const item = { ...state };
            item.visible = !windowState.visible;
            return item;
          });
          kendo.alert("저장되었습니다.");
          setFileState((state) => {
            const item = { ...state };
            item.fileList = [];
            item.currentFiles = [];
            return item;
          });
          setNotiList(search, paging);
        })
        .catch((error) => {
          if (error.errCode === "L007") {
            alert("해당 기능을 사용할 수 있는 권한이 없습니다. 사용중인 계정의 권한을 확인해주세요.");
            return;
          } else {
            alert("알수 없는 오류가 발생하였습니다. 관리자에게 문의해주세요.");
            return;
          }
        });
    } else {
      setFileState((state) => {
        const item = { ...state };
        item.fileList = [];
        item.currentFiles = [];
        return item;
      });
    }
  };

  const del = (dataItem) => {
    if (window.confirm("정말 삭제하시겠습니까?")) {
      setDeleteNoti(conn, dataItem)
        .then(() => {
          kendo.alert("삭제었습니다.");
          setNotiList(search, paging);
        })
        .catch((error) => {
          if (error.errCode === "L007") {
            alert("해당 기능을 사용할 수 있는 권한이 없습니다. 사용중인 계정의 권한을 확인해주세요.");
            return;
          } else {
            alert("알수 없는 오류가 발생하였습니다. 관리자에게 문의해주세요.");
            return;
          }
        });
    } else {
    }
  };

  const viewCnt = (e) => {
    const data = {
      noticeNo: e.dataItem.bbsSctCode,
      noticeNo: e.dataItem.noticeNo,
      titl: e.dataItem.titl,
      ctnt: e.dataItem.ctnt,
      pwd: e.dataItem.pwd,
      pubYn: e.dataItem.pubYn,
      viewCnt: e.dataItem.viewCnt,
      noticeYn: e.dataItem.noticeYn,
    };
    setViewCnt(conn, data)
      .then((result) => {
        setData(() => ({
          viewCnt: result,
        }));
      })
      .catch((error) => {
        alert(getMessage(error.errCode));
      });
  };

  // 윈도우 Visible 제어
  const setVisible = (visible) => {
    setWindowState(() => {
      const item = { ...windowState };
      item.visible = !visible;
      return item;
    });
  };

  useEffect(() => {
    setNotiList(search, paging);
  }, []);

  return (
    <>
      <div className={loading ? "noti-grid" : ""}>
        <div className="uk-grid">
          <div className="uk-width-medium-1-1">
            <div className={isMobile === "Y" ? null : "md-card TBgA"}>
              <div className={isMobile === "Y" ? null : "md-card-content small-padding"}>
                {loginUserTypeCode != 2004 && (
                  <NotiSearch
                    styles={styles}
                    search={search}
                    appChange={appChange}
                    appKeyPress={appKeyPress}
                    searchHandler={searchHandler}
                    isMobile={isMobile}
                  ></NotiSearch>
                )}
                {loginUserTypeCode === 2003 || loginUserTypeCode === 2005 ? (
                  <div className={isMobile === "Y" ? styles.writeBtnDiv : null}>
                    <a className={styles.chBtnAA} onClick={writebbs}>
                      <i className="material-icons2">
                        <Create />
                      </i>
                      글쓰기
                    </a>
                  </div>
                ) : null}

                {windowState.visible && (
                  <NotiAdd
                    windowState={windowState}
                    styles={styles}
                    data={data}
                    writebbs={writebbs}
                    titlChange={titlChange}
                    noticeChange={noticeChange}
                    ctntChange={ctntChange}
                    onAddFile={onAddFile}
                    onRemoveFile={onRemoveFile}
                    onStatusChangeFile={onStatusChangeFile}
                    fileState={fileState}
                    saveNoti={saveNoti}
                    cancel={cancel}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <br></br>

        <div className="uk-grid">
          <div className="uk-width-medium-1-1">
            <div className="Table-scr">
              <NotiGrid
                dataState={dataState}
                paging={paging}
                onPageChange={onPageChange}
                onSortChange={onSortChange}
                sort={sort}
                expandChange={expandChange}
                notiNoCell={notiNoCell}
                alignCell={alignCell}
                updateCell={updateCell}
                deleteCell={deleteCell}
                loginUserTypeCode={loginUserTypeCode}
              ></NotiGrid>
            </div>
          </div>
        </div>
      </div>
      {loading && (
        <p className={styles.notiLoading}>
          <BeatLoader loading={true} size={24} color="#1e88e5" />
        </p>
      )}
    </>
  );
});
export default Noti;
