import { useState, useEffect } from "react";
import { BeatLoader } from "react-spinners";

import OperationStatToday from "./operationStat_today";
import OperationStatEnergyStat from "./operationStat_energyStat";
import OperationStatBuildingStat from "./operationStat_buildingStat";
import OperationStatError from "./operationStat_error";
import OperationStatNew from "./operationStat_new";
import OperationStatPower from "./operationStat_power";
import OperationStatAreaToday from "./operationStat_area_today";
import OperationStatAreaYesterday from "./operationStat_area_yesterday";
import OperationStatArea3day from "./operationStat_area_3day";

import { getMessage } from "../../../com/messageUtil";
import {
  getOperEnergyList,
  getOperMeainStatCntList,
  getOperNewMeainList,
  getOperPowerWeek,
  getOperWarnMeainList,
} from "../../../service/monitor/operationService";

//CSS
import "./operationStat.css";
import styles from "./operationStat.module.css";

const OperEnergy = ({ conn }) => {
  const AREA_TYPE_RGN = "RGN";

  // 로딩 스피너
  const [loading, setLoading] = useState(true);

  const [state, setState] = useState({
    // today
    areaType: AREA_TYPE_RGN,
    cityProvCode: "",
    rgnCode: "",
    totalEnergy: {
      dayCo2: 0,
      dayPower: 0,
      dayPowerCo2: 0,
      dayUse: 0,
      dayUseCo2: 0,
      heatDayUse: 0,
      heathDayUse: 0,
    },
    // energyStat
    meatinStatCnt: [
      {
        normalCnt: 0,
        errorCnt: 0,
        waringCnt: 0,
        readyCnt: 0,
        unOperCnt: 0,
        totalCnt: 0,
      },
      {
        normalCnt: 0,
        errorCnt: 0,
        waringCnt: 0,
        readyCnt: 0,
        unOperCnt: 0,
        totalCnt: 0,
      },
      {
        normalCnt: 0,
        errorCnt: 0,
        waringCnt: 0,
        readyCnt: 0,
        unOperCnt: 0,
        totalCnt: 0,
      },
      {
        normalCnt: 0,
        errorCnt: 0,
        waringCnt: 0,
        readyCnt: 0,
        unOperCnt: 0,
        totalCnt: 0,
      },
      {
        normalCnt: 0,
        errorCnt: 0,
        waringCnt: 0,
        readyCnt: 0,
        unOperCnt: 0,
        totalCnt: 0,
      },
      {
        normalCnt: 0,
        errorCnt: 0,
        waringCnt: 0,
        readyCnt: 0,
        unOperCnt: 0,
        totalCnt: 0,
      },
      {
        normalCnt: 0,
        errorCnt: 0,
        waringCnt: 0,
        readyCnt: 0,
        unOperCnt: 0,
        totalCnt: 0,
      },
    ],
    //error
    meainErrorList: [],
    //new
    meainNewList: [],
    // power
    sumCapa: [],
    sumCnt: [],
    sumDayPower: [],
    // area_today
    // areaType: AREA_TYPE_CITY, //CITY:시도, RGN:시군구, DONG:읍면동
    rgnTerm: "",
    areaEnergyList: [],
    chartCount: [],
    chartInstCapa: [],
  });

  useEffect(() => {
    setList({
      areaType: AREA_TYPE_RGN,
      cityProvCode: "",
      cityProvTerm: "",
      rgnCode: "",
      rgnTerm: "",
    });
    setInterval();
  }, []);

  const setInterval = () => {
    setTimeout(() => {
      setList({
        areaType: AREA_TYPE_RGN,
        cityProvCode: "",
        cityProvTerm: "",
        rgnCode: "",
        rgnTerm: "",
      });
    }, 180000);
  };

  const setList = (selectedArea) => {
    Promise.all([
      getOperEnergyList(conn, selectedArea),
      getOperMeainStatCntList(conn, selectedArea),
      getOperWarnMeainList(conn, selectedArea),
      getOperNewMeainList(conn, selectedArea),
      getOperPowerWeek(conn),
    ])
      .then((results) => {
        const result = results[1];
        setState((state) => {
          const item = { ...state };
          // today
          item.totalEnergy = results[0].areaEnergyTotal;
          // energyStat
          item.meatinStatCnt[0] = result[0];
          item.meatinStatCnt[1] = result[1];
          item.meatinStatCnt[2] = result[2];
          item.meatinStatCnt[3] = result[3];
          item.meatinStatCnt[4] = result[4];
          item.meatinStatCnt[5] = result[5];
          item.meatinStatCnt[6] = result[6];
          // error
          item.meainErrorList = results[2];
          // new
          item.meainNewList = results[3];
          // power
          item.sumCapa = results[4].sumCapa;
          item.sumCnt = results[4].sumCnt;
          item.sumDayPower = results[4].sumDayPower;
          // area_today
          item.areaType = selectedArea.areaType;
          item.cityProvCode = selectedArea.cityProvCode;
          item.cityProvTerm = selectedArea.cityProvTerm;
          item.rgnCode = selectedArea.rgnCode;
          item.rgnTerm = selectedArea.rgnTerm;
          item.areaEnergyList = results[0].areaEnergyList;
          item.chartCount = results[0].chartCount;
          item.chartInstCapa = results[0].chartInstCapa;
          item.chartPowerEfic = results[0].chartPowerEfic;
          item.chartCalEfic = results[0].chartCalEfic;
          return item;
        });
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        alert(getMessage(e.errCode));
      });
  };

  return (
    <>
      <div className={loading ? styles.operationG : ""}>
        <div>
          <div className="uk-grid">
            <OperationStatToday state={state} styles={styles} />
            <OperationStatEnergyStat state={state} styles={styles} />
            {/* <OperationStatBuildingStat /> */}
          </div>

          <div className="uk-grid">
            <OperationStatError state={state} />
            <OperationStatNew state={state} />
            <OperationStatPower state={state} />
          </div>

          <div className="uk-grid">
            <OperationStatAreaToday state={state} />
            {/* <OperationStatAreaYesterday /> */}
            {/* <OperationStatArea3day /> */}
          </div>
        </div>
      </div>
      {loading && (
        <p className={styles.operationLoading}>
          <BeatLoader loading={true} size={24} color="#1e88e5" />
        </p>
      )}
    </>
  );
};

export default OperEnergy;
