import MenuRoleInst from './menuRole_inst';
import MenuRoleMngt from './menuRole_mngt';
import MenuRoleGuest from './menuRole_guest';
import MenuRoleDev from './menuRole_dev';
import MenuRoleRpst from './menuRole_rpst';
import MenuRoleRtu from './menuRole_rtu';

import {getMessage} from '../../../com/messageUtil';

import { useState, useEffect } from 'react'

import { setUpdateMenuRole, setDeleteMenuRole } from '../../../service/menu/menuService'

import {Button} from '@progress/kendo-react-buttons'
import kendo from '@progress/kendo-ui';

import './menuRole.css'

const MenuRole = ({conn}) => {

  // const [checkDataList, setCheckDataList] = useState({})
  const [instCheckData, setInstCheckData] = useState({
      menuData: [],
      menuList: []
  })
  const [mngtCheckData, setMngtCheckData] = useState({
      menuData: [],
      menuList: []
  })
  const [guestCheckData, setGuestCheckData] = useState({
    menuData: [],
    menuList: []
  })
  const [devCheckData, setDevCheckData] = useState({
      menuData: [],
      menuList: []
  })
  const [rpstCheckData, setRpstCheckData] = useState({
      menuData: [],
      menuList: []
  })
  const [rtuCheckData, setRtuCheckData] = useState({
      menuData: [],
      menuList: []
  })

   /* 저장 버튼 */
   const saveChanges = () => {
    let menuData = [instCheckData, mngtCheckData, guestCheckData, devCheckData, rpstCheckData, rtuCheckData];

    setUpdateMenuRole(conn, menuData)
    .then((response) => {
      kendo.alert('저장되었습니다.');
      window.location.replace("");
    })
    .catch(function (error) {  // API 호출이 실패한 경우
      // kendo.alert("저장을 실패 하였습니다.");
      // console.error(error);  // 에러표시

      alert(getMessage(error.errCode));
    })
  };
 
  const setCheckData = ( userTypeCode, menuData, menuList ) => {
    if(userTypeCode == 2001){
      setInstCheckData({
        ...instCheckData,
        menuData: menuData,
        menuList: menuList,
        userTypeCode: userTypeCode
      })
    } else if(userTypeCode == 2003){
      setMngtCheckData({
        ...mngtCheckData,
        menuData: menuData,
        menuList: menuList,
        userTypeCode: userTypeCode
      })
    } else if(userTypeCode == 2004){
      setGuestCheckData({
        ...guestCheckData,
        menuData: menuData,
        menuList: menuList,
        userTypeCode: userTypeCode
      })
    } else if(userTypeCode == 2005){
      setDevCheckData({
        ...devCheckData,
        menuData: menuData,
        menuList: menuList,
        userTypeCode: userTypeCode
      })
    } else if(userTypeCode == 2002){
      setRpstCheckData({
        ...rpstCheckData,
        menuData: menuData,
        menuList: menuList,
        userTypeCode: userTypeCode
      })
    } else if(userTypeCode == 2007){
      setRtuCheckData({
        ...rtuCheckData,
        menuData: menuData,
        menuList: menuList,
        userTypeCode: userTypeCode
      })
    } 
  }

  return (
    <div>
      <div className="uk-grid">
        <div className="uk-width-medium-1-1">
          <Button
          title="update"
          className="k-button k-menuButton"
          onClick={saveChanges}
          icon = "save"
        >
        저장
        </Button>
        </div>
      </div>
      
      <div className="uk-grid">
        <MenuRoleInst conn = {conn}  setCheckData = {setCheckData} />
        <MenuRoleMngt conn = {conn}  setCheckData = {setCheckData} />
        <MenuRoleGuest conn = {conn}  setCheckData = {setCheckData} />
        <MenuRoleDev conn = {conn}  setCheckData = {setCheckData} />
        <MenuRoleRpst conn = {conn}  setCheckData = {setCheckData} />
        <MenuRoleRtu conn = {conn}  setCheckData = {setCheckData} />
      </div>
    </div>
  )
}

export default MenuRole