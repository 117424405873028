import React, { useState, useEffect, useCallback, useRef } from "react";
import { BeatLoader } from "react-spinners";

import kendo from "@progress/kendo-ui";
import { Button } from "@progress/kendo-react-buttons";
import { IntlProvider, LocalizationProvider, loadMessages } from "@progress/kendo-react-intl";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Upload } from "@progress/kendo-react-upload";

import { numFormat } from "../../../com/dataUtil";

import BpmRpsSearch from "./bpmRpsSearch";
import BpmRpsGrid from "./bpmRpsGrid";
import BpmRpsModal from "./bpmRpsModal";
import styles from "./bpmRps.module.css";
import "./bpmRps.css";

import { getMessage } from "./../../../com/messageUtil";
import { getBpmRpsDataList, setUpdateBpmRps, setUpdateBpmRpsExcel } from "../../../service/meain/bpmRps/bpmRpsService";

import { SvgIcon } from "@material-ui/core";
import SolarPowerIcon from "@mui/icons-material/SolarPower";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import BoltIcon from "@mui/icons-material/Bolt";
import { getUser, isManager } from "../../../service/authService";

const BpmRps = ({ conn, rgnList, codeList }) => {
  const [loading, setLoading] = useState(false);

  const heatBoxRef = useRef(null);
  const elecBoxRef = useRef(null);
  const rpsBoxRef = useRef(null);

  const [search, setSearch] = useState({
    ensoType: { codeNo: "", codeVal: "에너지원" },
    bizSct: { codeNo: "", codeVal: "사업구분" },
    bizYy: "사업연도",
    rgn: { rgnCode: isManager() ? getUser().rgnCode : "", rgnTermSimple: "지역" },
    searchMode: "bpm", //bpm : 지원사업 조회, rps : 발전사업자 조회
  });

  //엑셀 업로드 팝업창 제어
  const [uploadState, setUploadState] = useState({
    files: [],
    events: [],
    filePreviews: {},
    afterStateChange: [],
    response: [],
    visible: false,
  });

  // 추가 팝업창 제어
  const [modalItem, setModalItem] = useState({
    title: "",
    item: [],
    visible: false,
  });

  const [paging, setPage] = useState({
    skip: 0,
    take: 20,
    count: 0,
    list: [],
    totalHeatCapa: 0,
    totalHeatCount: 0,
    totalHeathCapa: 0,
    totalHeathCount: 0,
    totalElecCapa: 0,
    totalElecCount: 0,
  });

  // 조회 로직
  const getBpmRpsData = (search, paging) => {
    setLoading(true);
    getBpmRpsDataList(conn, getDataSource, getRejection, search, paging, setLoading);
  };

  // 데이터 소스 설정 CALLBACK
  const getDataSource = (result) => {
    setPage((paging) => {
      const item = { ...paging };
      item.list = result.results;
      item.count = result.total;
      if (result.results.length > 0) {
        item.totalHeatCapa = result.results[0].totalHeatCapa;
        item.totalHeatCount = result.results[0].totalHeatCount;
        item.totalHeathCapa = result.results[0].totalHeathCapa;
        item.totalElecCapa = result.results[0].totalElecCapa;
        item.totalElecCount = result.results[0].totalElecCount;
      } else {
        item.totalHeatCapa = 0;
        item.totalHeatCount = 0;
        item.totalHeathCapa = 0;
        item.totalElecCapa = 0;
        item.totalElecCount = 0;
      }
      return item;
    });
  };

  // 데이터 소스 설정 rejection 함수 (현재 불필요)
  const getRejection = (error) => {};

  // 엑셀 업로드 헤더 설정
  const handleUploadHeaders = useCallback((e) => (e.headers["X-AUTH-TOKEN"] = localStorage.getItem("atk")));

  // 엑셀 파일 추가 부분
  const onAdd = useCallback(
    ({ target, newState, affectedFiles }) => {
      const afterStateChange = () => {
        affectedFiles
          .filter((file) => !file.validationErrors)
          .forEach((file) => {
            const reader = new FileReader();
            reader.onloadend = (ev) => {
              setUploadState({
                ...uploadState,
                filePreviews: {
                  ...uploadState.filePreviews,
                  [file.uid]: ev.target.result,
                },
              });
            };
            reader.readAsDataURL(file.getRawFile());
          });
      };

      setUploadState({
        ...uploadState,
        files: newState,
        afterStateChange,
      });
    },
    [uploadState],
  );

  // 엑셀 파일 추가 후 데이터 추출 부분
  const onStatusChange = useCallback(({ target, newState, affectedFiles, response }) => {
    setUploadState({ ...uploadState, files: newState, response });
  });

  //FIXME : IMEI와 Hashkey 로직 생성되면 진행
  // 엑셀 업로드 버튼
  const handleExcelUpload = useCallback(
    (e) => {
      e.preventDefault();
      const uploadExcelData = uploadState.response.response.data;
      setUpdateBpmRpsExcel(conn, uploadExcelData)
        .then(() => {
          kendo.alert("엑셀 저장을 완료하였습니다.");
          setUploadState({ ...uploadState, visible: !uploadState.visible });
          getBpmRpsData(search, paging);
        })
        .catch((error) => {
          kendo.alert(getMessage(error.errCode));
        });
    },
    [uploadState, paging],
  );

  const closeUpload = () => {
    setUploadState({ ...uploadState, visible: false });
  };

  // 엑셀 업로드 모달폼
  const handleExcelFormControl = useCallback(
    (e) => {
      setUploadState({
        ...uploadState,
        files: [],
        events: [],
        filePreviews: {},
        afterStateChange: [],
        response: [],
        visible: !uploadState.visible,
      });
    },
    [uploadState],
  );

  // 모드 선택 이벤트
  const selectBpmRps = (e) => {
    paging.skip = 0;
    if (e.target.id == "bpmBtn") {
      setSearch((search) => {
        const item = { ...search };
        item.ensoType = { codeNo: "", codeVal: "에너지원" };
        item.bizSct = { codeNo: "", codeVal: "사업구분" };
        item.bizYy = "사업연도";
        item.rgn = { rgnCode: isManager() ? getUser().rgnCode : "", rgnTermSimple: "지역" };
        item.searchMode = "bpm";
        return item;
      });
    } else if (e.target.id == "rpsBtn") {
      setSearch((search) => {
        const item = { ...search };
        item.ensoType = { codeNo: "", codeVal: "에너지원" };
        item.bizSct = { codeNo: "33009", codeVal: "RPS" };
        item.bizYy = "사업연도";
        item.rgn = { rgnCode: isManager() ? getUser().rgnCode : "", rgnTermSimple: "지역" };
        item.searchMode = "rps";
        return item;
      });
    }
  };

  /*  개소 및 설비용량 표시 부분 오버 이벤트 */
  const heatBoxOverEvt = (e) => {
    heatBoxRef.current.style.display = "block";
    heatBoxRef.current.style.top = e.pageY + 15 + "px";
    heatBoxRef.current.style.left = e.pageX + 15 + "px";
  };
  /* 개소 및 설비용량 표시 부분에서 마우스 벗어났을 때 이벤트 */
  const heatBoxOutEvt = (e) => {
    // setFixMapVis(true);
    heatBoxRef.current.style.display = "none";
  };

  /*  개소 및 설비용량 표시 부분 오버 이벤트 */
  const elecBoxOverEvt = (e) => {
    elecBoxRef.current.style.display = "block";
    elecBoxRef.current.style.top = e.pageY + 15 + "px";
    elecBoxRef.current.style.left = e.pageX + 15 + "px";
  };
  /* 개소 및 설비용량 표시 부분에서 마우스 벗어났을 때 이벤트 */
  const elecBoxOutEvt = (e) => {
    // setFixMapVis(true);
    elecBoxRef.current.style.display = "none";
  };

  useEffect(() => {
    getBpmRpsData(search, paging);
  }, [search.searchMode]);

  useEffect(() => {
    // 처음 데이터 불러오는 부분 제어 로직
    getBpmRpsData(search, paging);
  }, []);

  return (
    <>
      <div className={loading ? "loadingState" : null}>
        <BpmRpsSearch
          rgnList={rgnList}
          codeList={codeList}
          search={search}
          setSearch={setSearch}
          setLoading={setLoading}
          paging={paging}
          styles={styles}
          getBpmRpsData={getBpmRpsData}
          uploadState={uploadState}
          setUploadState={setUploadState}
          setModalItem={setModalItem}
        />
        <div className="uk-grid">
          <div className="uk-width-medium-1-1">
            <Button
              id="bpmBtn"
              className="bpmRpsBtn"
              onClick={selectBpmRps}
              style={search.searchMode === "bpm" ? { background: "#428bca", color: "white" } : null}
            >
              <SvgIcon component={HolidayVillageIcon} />
              &nbsp; 지원사업
            </Button>
            <Button
              id="rpsBtn"
              className="bpmRpsBtn"
              onClick={selectBpmRps}
              style={search.searchMode === "rps" ? { background: "#428bca", color: "white" } : null}
            >
              <SvgIcon component={SolarPowerIcon} />
              &nbsp; 발전사업자
            </Button>
            <div className="bpmRpsFloatRight bpmRpsCapa" style={{ height: "60px" }} onMouseMove={elecBoxOverEvt} onMouseOut={elecBoxOutEvt}>
              <span>
                <BoltIcon fontSize="large" style={{ color: "#FFAD01" }} />
                &nbsp; 설비용량 : {numFormat(paging.totalElecCapa)} MW
              </span>
            </div>
            <div className="bpmRpsFloatRight" style={{ height: "60px" }} onMouseMove={elecBoxOverEvt} onMouseOut={elecBoxOutEvt}>
              <span>
                {search.searchMode === "bpm" ? <HolidayVillageIcon fontSize="large" color="primary" /> : <SolarPowerIcon fontSize="large" color="primary" />}{" "}
                &nbsp;
                {search.searchMode === "bpm" ? "전기에너지" : "태양광"} 개소 : {numFormat(paging.totalElecCount)} 개
              </span>
            </div>
            {search.searchMode === "bpm" ? (
              <>
                <div className="bpmRpsFloatRight bpmRpsCapa" onMouseMove={heatBoxOverEvt} onMouseOut={heatBoxOutEvt}>
                  <table class="tg">
                    <thead>
                      <tr>
                        <td class="tg-0pky" rowspan="2">
                          <LocalFireDepartmentIcon fontSize="large" style={{ color: "#FE4A59" }} />
                          &nbsp;설비용량
                        </td>
                        <td class="tg-0pky">{numFormat(paging.totalHeathCapa)} MW</td>
                      </tr>
                      <tr>
                        <td class="tg-0pky">{numFormat(paging.totalHeatCapa)} k㎡</td>
                      </tr>
                    </thead>
                  </table>
                </div>
                <div className="bpmRpsFloatRight" style={{ height: "60px" }} onMouseMove={heatBoxOverEvt} onMouseOut={heatBoxOutEvt}>
                  <span>
                    <HolidayVillageIcon fontSize="large" color="primary" />
                    &nbsp;열에너지 개소 : {numFormat(paging.totalHeatCount)} 개
                  </span>
                </div>
              </>
            ) : null}
            &nbsp;
          </div>
        </div>

        <BpmRpsGrid
          conn={conn}
          search={search}
          setSearch={setSearch}
          paging={paging}
          getBpmRpsData={getBpmRpsData}
          modalItem={modalItem}
          setModalItem={setModalItem}
        />
        {uploadState.visible && (
          <Dialog title={"엑셀파일 가져오기"} onClose={handleExcelFormControl} height={250} width={600}>
            <div>
              <LocalizationProvider language="ko-KR">
                <IntlProvider locale="ko">
                  <Upload
                    className={styles.iotktUpload}
                    onBeforeUpload={handleUploadHeaders}
                    batch={false}
                    multiple={false}
                    files={uploadState.files}
                    restrictions={{ allowedExtensions: [".xlsx"] }}
                    withCredentials={true}
                    saveUrl={"/api/v2.2/meain/bpmRps/xlsx"}
                    onAdd={onAdd}
                    onStatusChange={onStatusChange}
                  />
                </IntlProvider>
              </LocalizationProvider>
              <div style={{ float: "right" }}>
                <Button icon="save" style={{ margin: "5px" }} onClick={handleExcelUpload}>
                  저장
                </Button>
                <Button icon="close" style={{ margin: "5px" }} onClick={closeUpload}>
                  취소
                </Button>
              </div>
            </div>
          </Dialog>
        )}
        {modalItem.visible && (
          <BpmRpsModal
            title={modalItem.title}
            item={modalItem.item}
            codeList={codeList}
            rgnList={rgnList}
            cancelEdit={() => {
              setModalItem({
                title: "",
                item: [],
                visible: !modalItem.visible,
              });
            }}
            onSubmit={(dataItem) => {
              if (modalItem.title === "추가") {
                dataItem.ensoTypeCode = dataItem.ensoInfo.codeNo;
                dataItem.bizSctCode = dataItem.bizInfo.codeNo;
                dataItem.rgnCode = dataItem.rgnInfo.rgnCode;
                if (dataItem.ensoTypeCode === "") kendo.alert("에너지원을 선택해주세요");
                else if (dataItem.bizSctCode === "") kendo.alert("사업구분을 선택해주세요");
                else if (dataItem.rgnCode === "") kendo.alert("지역을 선택해주세요");
                else if (dataItem.bizYy === "") kendo.alert("사업연도를 선택해주세요");
                else if (isNaN(dataItem.instCapa)) kendo.alert("설비 용량에는 숫자만 입력해주세요");
                else {
                  setUpdateBpmRps(conn, { ...dataItem })
                    .then(() => {
                      kendo.alert("저장되었습니다.");
                      getBpmRpsData(search, paging);
                    })
                    .catch((e) => {
                      kendo.alert("저장을 실패 하였습니다.");
                    });
                }
              } else if (modalItem.title === "수정") {
                dataItem.ensoTypeCode = dataItem.ensoInfo.codeNo;
                dataItem.bizSctCode = dataItem.bizInfo.codeNo;
                dataItem.rgnCode = dataItem.rgnInfo.rgnCode;
                if (dataItem.ensoTypeCode === "") kendo.alert("에너지원을 선택해주세요");
                else if (dataItem.bizSctCode === "") kendo.alert("사업구분을 선택해주세요");
                else if (dataItem.rgnCode === "") kendo.alert("지역을 선택해주세요");
                else if (dataItem.bizYy === "") kendo.alert("사업연도를 선택해주세요");
                else if (isNaN(dataItem.instCapa)) kendo.alert("설비 용량에는 숫자만 입력해주세요");
                else {
                  setUpdateBpmRps(conn, { ...dataItem })
                    .then(() => {
                      kendo.alert("저장되었습니다.");
                      getBpmRpsData(search, paging);
                    })
                    .catch((e) => {
                      kendo.alert("저장을 실패 하였습니다.");
                    });
                }
              }
              setModalItem({
                title: "",
                item: [],
                visible: !modalItem.visible,
              });
            }}
          />
        )}
        {loading && (
          <p className={styles.bpmRpsLoading}>
            <BeatLoader loading={true} size={24} color="#1e88e5" />
          </p>
        )}
      </div>
      <div style={{ borderLeft: "8px solid #FE4A59" }} ref={heatBoxRef} className={styles.bpmRpsStatTbl}>
        열에너지: 태양열(k㎡), 지열(MW)
      </div>
      <div style={{ borderLeft: "8px solid #FFAD01" }} ref={elecBoxRef} className={styles.bpmRpsStatTbl}>
        전기에너지: 태양광, 풍력, 연료전지(MW)
      </div>
    </>
  );
};

export default BpmRps;
