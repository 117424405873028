import { ListView } from '@progress/kendo-react-listview';
import MeainNewItem from './meainNewItem';
import { useState, useEffect } from 'react';
import {getMessage} from '../../../com/messageUtil';

import { getOperNewMeainList } from '../../../service/monitor/operationService'

const OperationStatNew = ({state}) => {

  return (
    <div className="uk-width-medium-1-3" >
      <div className="md-card">
        <div className="md-card-content" className="operationPage1-3">
          <div className="md-card-toolbar">
            <h3 className="md-card-toolbar-heading-text">
              신규설비
            </h3>
            <div className="md-card-toolbar-actions">
              건수&nbsp;{state.meainNewList.length}&nbsp;&nbsp;(최근 2일)
            </div>
          </div>
          <div className="scroolY">
            {state.meainNewList.length !== 0 ? 
              (<ListView
                className = "ListViewItem"
                data = {state.meainNewList}
                item={MeainNewItem}
              />): <span className = "scroolYOper"><b>데이터가 존재하지 않습니다.</b></span>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default OperationStatNew;