import { Window } from "@progress/kendo-react-dialogs";
import { Input, TextArea } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";

const NonOperHistEtcUpdateWindow = ({ styles, windowState, update, updateEtcSave, cancel, etcChange }) => {
  return (
    <>
      <Window title="비고 수정" left={700} top={100} initialHeight={250} initialWidth={500} onClose={cancel} minimizeButton="none" maximizeButton={"none"}>
        <TextArea rows={6} style={{ width: 460 }} value={windowState.etc} onChange={etcChange}></TextArea>
        <Button icon="save" className={styles.etcBtn} onClick={updateEtcSave}>
          저장
        </Button>
        <Button icon="close" className={styles.etcBtn} onClick={cancel}>
          닫기
        </Button>
      </Window>
    </>
  );
};

export default NonOperHistEtcUpdateWindow;
