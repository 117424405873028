// React
import React, { useState, useEffect } from "react";
// Kendo
import { Dialog } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";

import { yearList } from "../../../com/dropDownDs";

function BpmRpsModal({ title, item, cancelEdit, onSubmit, codeList, rgnList }) {
  const rgnInfo = rgnList.filter((e) => e.rgnCode === item.rgnCode);
  rgnInfo.length !== 0 ? (item.rgnInfo = rgnInfo[0]) : (item.rgnInfo = { rgnCode: "", rgnTerm: "읍/면/동" });

  // 에너지원 데이터 소스
  const ensoList = codeList.filter((c) => c.grpCodeNo === 15 && c.codeNo !== 15007);
  const ensoInfo = ensoList.filter((e) => e.codeNo == item.ensoTypeCode);
  ensoInfo.length !== 0 ? (item.ensoInfo = ensoInfo[0]) : (item.ensoInfo = { codeNo: "", codeVal: "에너지원" });

  // 사업구분
  const bizSctList = codeList.filter((c) => c.grpCodeNo === 33);
  const bizInfo = bizSctList.filter((e) => e.codeNo == item.bizSctCode);
  bizInfo.length !== 0 ? (item.bizInfo = bizInfo[0]) : (item.bizInfo = { codeNo: "", codeVal: "사업구분" });

  return (
    <Dialog title={title} onClose={cancelEdit} width={500}>
      <Form
        onSubmit={onSubmit}
        initialValues={item}
        render={(formRenderProps) => (
          <FormElement>
            <fieldset className={"k-form-fieldset"}>
              <div className="mb-3">
                <Field
                  style={{ width: "100%" }}
                  name="ensoInfo"
                  label="에너지원"
                  component={DropDownList}
                  placeholder="에너지원"
                  data={ensoList}
                  textField="codeVal"
                  dataItemKey="codeNo"
                  defaultValue={{ codeNo: "", codeVal: "에너지원" }}
                  required
                />
              </div>
              <div className="mb-3">
                <Field
                  style={{ width: "100%" }}
                  name="bizInfo"
                  label="사업구분"
                  component={DropDownList}
                  placeholder="사업구분"
                  data={bizSctList}
                  textField="codeVal"
                  dataItemKey="codeNo"
                  defaultValue={{ codeNo: "", codeVal: "사업구분" }}
                  required
                />
              </div>
              <div className="mb-3">
                <Field
                  style={{ width: "100%" }}
                  name="bizYy"
                  label="사업연도"
                  component={DropDownList}
                  placeholder="사업연도"
                  data={yearList()}
                  defaultValue={"사업연도"}
                  required
                />
              </div>
              <div className="mb-3">
                <Field
                  style={{ width: "100%" }}
                  name="rgnInfo"
                  label="지역"
                  component={DropDownList}
                  placeholder="지역"
                  data={rgnList}
                  textField="rgnTerm"
                  dataItemKey="rgnCode"
                  defaultValue={{ rgnCode: "", rgnTerm: "읍/면/동" }}
                  required
                />
              </div>
              <div className="mb-3">
                <Field style={{ width: "100%" }} name="address" label="주소" component={Input} placeholder="주소" />
              </div>
              <div className="mb-3">
                <Field style={{ width: "100%" }} name="instCapa" label="설비용량(kW)" component={Input} placeholder="설비용량(kW)" type="number" />
              </div>
              <div className="mb-3">
                <Field style={{ width: "100%" }} name="consEnteName" label="시공기업" component={Input} placeholder="시공기업" />
              </div>
              <div className="mb-3">
                <Field style={{ width: "100%" }} name="remarks" label="비고" component={Input} placeholder="비고" />
              </div>
            </fieldset>
            <div className="k-form-buttons">
              <Button type={"submit"} disabled={!formRenderProps.allowSubmit}>
                {title}
              </Button>
              <Button type={"submit"} onClick={cancelEdit}>
                취소
              </Button>
            </div>
          </FormElement>
        )}
      />
    </Dialog>
  );
}

export default BpmRpsModal;
