// React
import React, { useState, useEffect } from "react";
import log from "../../../com/log";

// Kendo
import { Dialog } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";

const MeainModelModal = ({ title, item, cancelEdit, onSubmit, codeList, makrData }) => {
  // Y,  N 데이터 소스
  const authYnDDL = ["Y", "N"];

  // 에너지원 값
  const [ensoTypeData, setEnsoTypeData] = useState({ codeNo: "", codeVal: "전체" });
  // 분류 값
  const [makrClsfNoData, setMakrClsfNoData] = useState({ codeNo: "", codeVal: "전체" });
  // 제조사 값
  const [makrValue, setMakrValue] = useState({ makrSeriNo: "", makrTerm: "제조사" });

  // 분류 에너지원 설정
  const makrClsfType = codeList.filter((c) => c.grpCodeNo === 30);
  makrClsfType.forEach((element) => {
    if (element.codeVal.includes("태양광", 0) == true) {
      element.ensoTypeCode = 15001;
    }
    if (element.codeVal.includes("태양열", 0) == true) {
      element.ensoTypeCode = 15002;
    }
    if (element.codeVal.includes("지열", 0) == true) {
      element.ensoTypeCode = 15003;
    }
    if (element.codeVal.includes("풍력", 0) == true) {
      element.ensoTypeCode = 15004;
    }
    if (element.codeVal.includes("연료전지", 0) == true) {
      element.ensoTypeCode = 15006;
    }
    if (element.codeVal.includes("ESS", 0) == true) {
      element.ensoTypeCode = 15007;
    }
  });

  /* 에너지원 드롭다운리스트 */
  const customEnsoTypeDDL = (props) => {
    const handleChange = (e) => {
      setEnsoTypeData(e.value);
      setMakrClsfNoData({ codeNo: "", codeVal: "전체" });
      setMakrValue({ makrSeriNo: "", makrTerm: "제조사" });
      item.makrClsf = { codeNo: "", codeVal: "전체" };
      item.makr = { makrSeriNo: "", makrTerm: "제조사" };
      props.onChange({
        value: e.value,
      });
    };
    return (
      <DropDownList
        style={{ width: "100%" }}
        name="ensoTypeCode"
        label="에너지원"
        data={codeList.filter((c) => c.grpCodeNo === 15)}
        value={ensoTypeData}
        textField="codeVal"
        dataItemKey="codeNo"
        required={true}
        onChange={handleChange}
        validationMessage={"에너지원을 선택하세요"}
      />
    );
  };

  /* 분류 드롭다운 리스트 */
  const customMakrClsfDDL = (props) => {
    const handleChange = (e) => {
      setMakrClsfNoData(e.value);
      setMakrValue({ makrSeriNo: "", makrTerm: "제조사" });
      item.makr = { makrSeriNo: "", makrTerm: "제조사" };
      props.onChange({
        value: e.value,
      });
    };
    return (
      <DropDownList
        style={{ width: "100%" }}
        name="makrClsfNo"
        label="분류"
        data={makrClsfType.filter((v) => v.ensoTypeCode === ensoTypeData.codeNo)}
        value={makrClsfNoData}
        required={true}
        onChange={handleChange}
        textField="codeVal"
        dataItemKey="codeNo"
        validationMessage={"분류를 선택하세요"}
      />
    );
  };

  /* 제조사 드롭다운 리스트 */
  const customMakrDDL = (props) => {
    const handleChange = (e) => {
      setMakrValue(e.value);
      props.onChange({
        value: e.value,
      });
    };
    return (
      <DropDownList
        style={{ width: "100%" }}
        name="makr"
        label="제조사"
        data={makrData.filter((v) => v.makrClsfNo === makrClsfNoData.codeNo)}
        value={makrValue}
        required={true}
        onChange={handleChange}
        textField="makrTerm"
        dataItemKey="makrSeriNo"
        validationMessage={"제조사를 선택하세요"}
      />
    );
  };

  // converter
  const dataConverter = (item) => {
    // 에너지원
    if (item.ensoTypeCodeNo) {
      const ensoTypeValue = codeList.filter((v) => v.codeNo === item.ensoTypeCodeNo)[0];
      setEnsoTypeData({ codeNo: ensoTypeValue.codeNo, codeVal: ensoTypeValue.codeVal });
    }
    // 분류
    if (item.makrClsfNo) {
      const makrClsfValue = codeList.filter((v) => v.codeNo === item.makrClsfNo)[0];
      setMakrClsfNoData({ codeNo: makrClsfValue.codeNo, codeVal: makrClsfValue.codeVal });
    }
    // 제조사
    if (item.makrSeriNo) {
      const makrValue = makrData.filter((v) => v.makrSeriNo === item.makrSeriNo)[0];
      setMakrValue({ makrSeriNo: makrValue.makrSeriNo, makrTerm: makrValue.makrTerm });
    }
  };

  useEffect(() => {
    dataConverter(item);
  }, []);

  return (
    <Dialog title={title} onClose={cancelEdit} width={400}>
      <Form
        onSubmit={(e) => {
          // 이미 선택 후 다시 전체 상태로 변경 시 방어 코드
          if (makrClsfNoData.codeNo === "") {
            e.makrClsf = null;
          }
          if (makrValue.makrSeriNo === "") {
            e.makr = null;
          }
          onSubmit(e);
        }}
        initialValues={item}
        render={(formRenderProps) => (
          <FormElement>
            <fieldset className={"k-form-fieldset"}>
              {/* <div className="mb-3">
                <Field
                  style={{ width: '100%' }}
                  name="meainTypeSeriNo"
                  label={'모델번호'}
                  component={Input}
                  placeholder={'자동생성됩니다.'}
                  readOnly={true}
                />
              </div> */}
              <div className="mb-3">
                <Field style={{ width: "100%" }} name="meainTypeTerm" label="모델명" component={Input} placeholder="모델명을 입력하세요" required />
              </div>
              <div className="mb-3">
                <Field name="ensoTypeCode" label="에너지원" component={customEnsoTypeDDL} />
              </div>
              <div className="mb-3">
                <Field style={{ width: "100%" }} name="makrClsf" label="분류" component={customMakrClsfDDL} />
              </div>
              <div className="mb-3">
                <Field style={{ width: "100%" }} name="makr" label="제조사" component={customMakrDDL} />
              </div>
              <div className="mb-3">
                <Field style={{ width: "100%" }} name="meainTypeExpl" label="설명" component={Input} placeholder="설비 모델 설명을 입력하세요." />
              </div>
              <div className="mb-3">
                <Field
                  style={{ width: "100%" }}
                  name="authYn"
                  label="KS인증여부"
                  component={DropDownList}
                  data={authYnDDL}
                  defaultValue={authYnDDL[0]}
                  placeholder="사용 여부"
                />
              </div>
            </fieldset>
            <div className="k-form-buttons">
              <Button type={"submit"} disabled={!formRenderProps.allowSubmit}>
                {title}
              </Button>
              <Button type={"submit"} onClick={cancelEdit}>
                취소
              </Button>
            </div>
          </FormElement>
        )}
      />
    </Dialog>
  );
};

export default MeainModelModal;
