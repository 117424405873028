import React from "react";

const BronzeMedal = () => {
  return (
    <>
      {" "}
      <img className="cmpAreaMedal" src="/images/bronze_medal.png"></img>
    </>
  );
};

export default BronzeMedal;
