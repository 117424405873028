// 인버터 효율 15001

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { numFormat, roundNum } from "../../../../com/dataUtil";

// 시간별 차트
export const ReportDayIntegratedChart = ({ timeChart, height, width, clsName }) => {
  // 차트 옵션
  Highcharts.setOptions({
    lang: {
      resetZoom: "초기화",
    },
  });
  const chartConfig = {
    chart: { type: "column", zoomType: "xy", width: width !== null ? width : "100%", height: height !== null ? height : null },
    title: { text: null },
    xAxis: [{ type: "category", crosshair: true }],
    yAxis: [
      {
        title: {
          text: null,
        },
        stackLables: {
          enabled: true,
        },
      },
    ],
    credits: { enabled: false },
    tooltip: {
      shared: true,
      formatter: function () {
        let msg = "";
        for (let i = 0; i < this.points.length; i++) {
          msg +=
            '<span style="color:' +
            this.points[i].point.color +
            '">\u25CF</span> ' +
            this.points[i].series.name +
            " : " +
            "<b>" +
            numFormat(this.points[i].y) +
            " kWh" +
            "</b>" +
            "<br>";
        }
        return msg;
      },
    },
    legend: {
      layout: "horizontal",
      align: "center",
      verticalAlign: "top",
      floating: false,
      backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || "#FFFFFF",
    },
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: true,
          color: "#ffffff",
          style: { textShadow: false },
          formatter: function () {
            return "<span>" + "<b>" + numFormat(roundNum(this.y, 0)) + "</b>" + "</span>";
          },
        },
      },
    },
    series: [
      {
        name: "발전량",
        tooltip: {
          valueSuffix: " kWh",
        },
        color: "#69bcc8",
        data: timeChart.chartTimeData.power,
      },
      {
        name: "생산열량",
        tooltip: {
          valueSuffix: " kWh",
        },
        color: "#8fff94",
        data: timeChart.chartTimeData.cal,
      },
      {
        name: "ESS충전량",
        tooltip: {
          valueSuffix: " kWh",
        },
        color: "#ff6f6f",
        data: timeChart.chartTimeData.ess,
      },
    ],
  };

  return (
    <div className={clsName !== null ? clsName : "reportDayBackground"} style={{ marginBottom: "10px" }}>
      <HighchartsReact Highcharts={Highcharts} options={chartConfig} containerProps={{ style: { border: "1px solid gainsboro" } }}></HighchartsReact>
    </div>
  );
};

export default ReportDayIntegratedChart;
