import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Grid, GridColumn as Column, GridNoRecords } from "@progress/kendo-react-grid";
import "./reportConsm.css";

function ReportConsmGrid({ selectMeain, chartMonthData, chartYearData, width }) {
  // 차트 옵션
  Highcharts.setOptions({
    lang: {
      resetZoom: "초기화",
    },
  });
  let chartYear = {
    chart: {
      type: "column",
      zoomType: "xy",
      height: 350,
      backgroundColor: "#f7f9fa",
      width: width !== null ? width : "100%",
    },
    title: { text: null },
    xAxis: [{ type: "category", crosshair: true }],
    yAxis: [
      {
        labels: {
          format: "{value:,.0f}",
        },
        title: {
          text: "(단위)KWh",
        },
      },
    ],
    credits: { enabled: false },
    tooltip: { shared: true },
    legend: { layout: "horizontal", align: "center", verticalAlign: "bottom", floating: false },
    series: [
      {
        name: "금년발전량",
        tooltip: {
          valueSuffix: " KWh",
        },
        color: "#87CEEB",
        data: chartYearData.energy,
      },
      {
        name: "작년발전량",
        tooltip: {
          valueSuffix: " KWh",
        },
        color: "#F08080",
        data: chartYearData.preEnergy,
      },
    ],
  };

  // 차트 옵션
  let chartMonth = {
    chart: { type: "column", zoomType: "xy", height: 350, backgroundColor: "#f7f9fa", width: width !== null ? width : "100%" },
    title: { text: null },
    xAxis: [{ type: "category", crosshair: true }],
    yAxis: [
      {
        labels: {
          format: "{value:,.0f}",
        },
        title: {
          text: "(단위)KWh",
        },
      },
    ],
    credits: { enabled: false },
    tooltip: { shared: true },
    legend: { layout: "horizontal", align: "center", verticalAlign: "bottom", floating: false },
    series: [
      {
        name: "금월발전량",
        tooltip: {
          valueSuffix: " KWh",
        },
        color: "#87CEEB",
        data: chartMonthData.energy,
      },
      {
        name: "전월발전량",
        tooltip: {
          valueSuffix: " KWh",
        },
        color: "#F08080",
        data: chartMonthData.preEnergy,
      },
    ],
  };

  return (
    <>
      <div className="reportDayBackground">
        <b> · &nbsp;전월대비 일별 발전현황 </b>
      </div>
      <div className="repConsmLeftChart">
        <HighchartsReact options={chartMonth} />
      </div>
      <div className="repConsmRightGrid">
        <Grid className="reportGrid" data={selectMeain} style={{ height: "350px" }} sortable>
          <GridNoRecords>{"조회 결과 데이터가 없습니다."}</GridNoRecords>
          <Column field="dtm" title="일" />
          <Column field="preMonthPower" title="전월 발전량(kWh)" />
          <Column field="monthPower" title="금월 발전량(kWh)" />
          <Column field="increaseMonthRate" title="증감률(%)" />
        </Grid>
      </div>
      <div className="reportDayBackground">
        <b> · &nbsp;전년대비 일별 발전현황 </b>
      </div>
      <div className="repConsmLeftChart">
        <HighchartsReact Highcharts={Highcharts} options={chartYear} />
      </div>
      <div className="repConsmRightGrid">
        <Grid className="reportGrid" data={selectMeain} style={{ height: "350px" }} sortable>
          <GridNoRecords>{"조회 결과 데이터가 없습니다."}</GridNoRecords>
          <Column field="dtm" title="일" />
          <Column field="preYearPower" title="작년발전량(kWh)" />
          <Column field="yearPower" title="금년발전량(kWh)" />
          <Column field="increaseYearRate" title="증감률(%)" />
        </Grid>
      </div>
    </>
  );
}

export default ReportConsmGrid;
