// 설비 정보 연료전지
import React , {useState , useEffect} from "react";
import kendo from '@progress/kendo-ui';
import {DropDownList} from '@progress/kendo-react-dropdowns';
import styles from './meain.module.css';
import DateTimePickerKo from '../../com/dateInputs/dateTimePickerKo';
import DatePickerKo from '../../com/dateInputs/datePickerKo';
import { Input } from '@progress/kendo-react-inputs';

// 연료전지 설비 정보
function Meain_info_fuelcell({ selectMeain , setMeainInfo  , codeList , meainModlList , inputValidMsg , itemRender }){

  const [fuelCellMakr , setFuelCellMakr] = useState( selectMeain.fuelCellMakrNo ? { makrSeriNo : selectMeain.fuelCellMakrNo , makrTerm : "" } : { makrSeriNo : "" , makrTerm : "" } );
  const [fuelCellModl , setFuelCellModl] = useState( selectMeain.fuelCellModlNo ? { meainTypeSeriNo : selectMeain.fuelCellModlNo , meainTypeTerm : "" } : { meainTypeSeriNo : "" , meainTypeTerm : "" } );
  const [bldSrvCodeNo , setBldSrvCodeNo] = useState( selectMeain.bldSrvCodeNo ? { codeNo : selectMeain.bldSrvCodeNo , codeVal : "" } : { codeNo : "" , codeVal : "" } );
  const bldSrvDs = codeList.filter( c => c.grpCodeNo === 31 );

  // 연료전지 제조사
  const fuelCellMakrList = meainModlList.length !== 0 ? meainModlList.makrDtoList.filter(c => c.makrClsfNo == 30010) : [];
  // 연료전지 모델 
  const fuelCellModlList = meainModlList.length !== 0 ? meainModlList.meainModelDtoList.filter(c=> c.ensoTypeCodeNo === 15006 && c.makrSeriNo === selectMeain.fuelCellMakrNo) : []; 
  
  // Input 데이터 값 변경 이벤트
  const onchageInput = (e) => {
    let meainInfo = {};
    const objName = e.target.name;
    meainInfo = {
      ...selectMeain
      ,  [objName] : e.target.value
    };
    setMeainInfo(meainInfo);
  }

  // Date 데이터 값 변경 이벤트
  const onchageDate = (v , name) => {
    // 값변경한 부분에 대해서 추적하여 객체 이름 기입하기 위해 name 값 가져오기
    const meainInfo = {
        ...selectMeain
        ,  [name] : kendo.toString(v , 'yyyy-MM-dd') 
    };
    setMeainInfo(meainInfo);
  }

  // DateTimeOnChangeDate
  const onChangeDateTiem = (v , name) =>{
    const meainInfo = {
      ...selectMeain
      ,  [name] : v
      // ,  [name] : kendo.toString(v , 'yyyy-MM-dd HH:mm:00')
    };
    setMeainInfo(meainInfo);
  }

  // 날짜 포맷 지정
  const dateFormat = (date) =>{
    if( typeof date !== "undefined" ){
        if( date !== "" ){ date = new Date(date); }
        else{ date = ""; } }
    else{ date = ""; }
    return date;
  }

  // 제조사 Converter
  const makrConverter = () => {
    if( Object.keys(selectMeain).length !== 0 ){
      if( selectMeain.fuelCellMakrNo ){
        let makrSeriNo = "";
        let makrTerm = "";
        fuelCellMakrList.forEach(element => {
            if( element.makrSeriNo == selectMeain.fuelCellMakrNo ){
                makrSeriNo = element.makrSeriNo;
                makrTerm = element.makrTerm;
            }
        });
        setFuelCellMakr({ makrSeriNo: makrSeriNo, makrTerm: makrTerm });
      }
    }else{
      setFuelCellMakr({ makrSeriNo: '' , makrTerm: '' });
    }
  }

  // 모델 Converter
  const modlConverter = () => {
    if( Object.keys(selectMeain).length !== 0 ){
      if( selectMeain.fuelCellModlNo ){
        let meainTypeSeriNo = "";
        let meainTypeTerm = "";
        fuelCellModlList.forEach(element => {
            if( element.meainTypeSeriNo == selectMeain.fuelCellModlNo ){
                meainTypeSeriNo = element.meainTypeSeriNo;
                meainTypeTerm = element.meainTypeTerm;
            }
        });
        setFuelCellModl({ meainTypeSeriNo: meainTypeSeriNo, meainTypeTerm: meainTypeTerm });
      }else{
        setFuelCellModl({ meainTypeSeriNo: '', meainTypeTerm: '' });
      }
    }else{
      setFuelCellModl({ meainTypeSeriNo: '', meainTypeTerm: '' });
    }
  }

  // Converter 건물용도별
  const  bldSrvConverter = () =>{
    if( Object.keys(selectMeain).length !== 0 ){
        let codeNo = "";
        let codeVal = "";
        bldSrvDs.forEach(element => {
            if( element.codeNo == selectMeain.bldSrvCodeNo ){
                codeNo = element.codeNo;
                codeVal = element.codeVal;
            }
        });
        setBldSrvCodeNo({ codeNo: codeNo, codeVal: codeVal });
    }else{
        setBldSrvCodeNo({ codeNo: '', codeVal: '' });
    }
  }

  useEffect(() => {
     // props의 데이터를 각각 데이터 소스 객체에 맞게 Convert 작업 해준다.
    makrConverter();
    modlConverter();
    bldSrvConverter();
  }, [selectMeain]); // <- 이걸 해줘야 다른 컴포넌트에 값을 입력해준다.

  return(
      

    <table className="Ch-TableN">
        <tr><th colSpan="6"><div></div></th></tr>
        <tr>
            <th>연료전지 제조사</th>     
            <td>
                <DropDownList className = "meainInfoDropDown" id = "fuelCellMakrNo" name = "fuelCellMakrNo" 
                    data = {fuelCellMakrList} 
                    value = { fuelCellMakr  } valid = {selectMeain.fuelCellMakrNo !== "" && selectMeain.fuelCellMakrNo !== null && typeof selectMeain.fuelCellMakrNo !== "undefined" }
                    dataItemKey="makrSeriNo" textField = "makrTerm" required = {true} validationMessage = {inputValidMsg.normalText}
                    onChange = { (e) =>{ const obj = { ...selectMeain , [e.target.name] : e.value.makrSeriNo }; itemRender = {itemRender}
                    setFuelCellMakr({makrSeriNo : e.target.value.makrSeriNo , makrTerm : e.target.value.makrTerm}); setMeainInfo(obj); } } 
                />
            </td>
            <th>연료전지 모델명</th>
            <td>
                <DropDownList className = "meainInfoDropDown" id = "fuelCellModlNo" name = "fuelCellModlNo" 
                    data = {fuelCellModlList} 
                    valid = {selectMeain.fuelCellModlNo !== "" && selectMeain.fuelCellModlNo !== null && typeof selectMeain.fuelCellModlNo !== "undefined" }
                    value = { fuelCellModl  } disabled = { selectMeain.fuelCellMakrNo ? false : true } itemRender = {itemRender}
                    dataItemKey="meainTypeSeriNo" textField = "meainTypeTerm"  required = {true} validationMessage = {inputValidMsg.normalText}
                    onChange = { (e) =>{ const obj = { ...selectMeain , [e.target.name] : e.value.meainTypeSeriNo , meainTypeSeriNo : e.value.meainTypeSeriNo }; 
                    setFuelCellModl({meainTypeSeriNo : e.target.value.meainTypeSeriNo , meainTypeTerm : e.target.value.meainTypeTerm}); setMeainInfo(obj); } } 
                />
            </td>
            <th>연료전지 용량</th>
            <td><Input id = "fuelCellCapa" name = "instCapa" type="number" className={styles.searchTermTBC} step="0.01" min = {0}
                    value = { selectMeain.instCapa ? selectMeain.instCapa : "" } required = {true} validationMessage = {inputValidMsg.number2Fix}
                    onChange = {onchageInput}/></td>
        </tr>
        <tr><th colSpan="6"><div></div></th></tr>
        <tr>
            <th>건물용도</th>
            <td>
              <DropDownList className = "bldSrvCodeNo" id = "bldSrvCodeNo" name = "bldSrvCodeNo" 
                data = { bldSrvDs } valid = {selectMeain.bldSrvCodeNo !== "" && selectMeain.bldSrvCodeNo !== null && typeof selectMeain.bldSrvCodeNo !== "undefined" }
                value = { bldSrvCodeNo } required = {true} validationMessage = {inputValidMsg.normalText}
                dataItemKey="codeNo" textField = "codeVal" itemRender = {itemRender}
                onChange = { (e) =>{ const obj = { ...selectMeain , [e.target.name] : e.value.codeNo }; setBldSrvCodeNo({codeNo : e.target.value.codeNo , codeVal : e.target.value.codeVal }); setMeainInfo(obj); } } 
              />
            </td>
            <th>시공업체</th>
            <td>
              <Input id = "consEnte" name = "consEnte" type="text" className={styles.searchTermTBC} required = {true} validationMessage = {inputValidMsg.normalText}
              value = { selectMeain.consEnte ? selectMeain.consEnte : "" } onChange = {onchageInput}/>
            </td>
            <th>시공업체 연락처</th>
            <td><Input id = "consEnteCntct" name = "consEnteCntct" type="text" className={styles.searchTermTBC} required = {true} validationMessage = {inputValidMsg.normalText}
            placeholder = "하이픈(-) 없이 입력해주세요."
            value = { selectMeain.consEnteCntct ? selectMeain.consEnteCntct : "" } onChange = {onchageInput}/></td>
        </tr>
        <tr><th colSpan="6"><div></div></th></tr>
        <tr>
            <th>시공업체<br/>핸드폰 연락처</th>
            <td><Input id = "consPhoneCntct" name = "consPhoneCntct" type="text" className={styles.searchTermTBC} 
                    value = { selectMeain.consPhoneCntct ? selectMeain.consPhoneCntct : "" } required = {true} validationMessage = {inputValidMsg.normalText}
                    placeholder = "하이픈(-) 없이 입력해주세요."
                    onChange = {onchageInput}/></td>
            <th>시공일시</th>
            <td>
                <DateTimePickerKo id = "meainInstDtm"  name = "meainInstDtm"  className = "meainDatePicker"
                    value = {dateFormat(selectMeain.meainInstDtm)} 
                    format = {"yyyy-MM-dd HH:mm"}
                    formatPlaceholder={{ year: '년', month: '월', day: '일' , hour : "시간" , minute : "분" }}
                    setDtm = {(v) => { onChangeDateTiem(v , "meainInstDtm"); }} required = {true} validationMessage = {inputValidMsg.normalText}
                    >
                </DateTimePickerKo> 
            </td>
            <th>모니터링일시</th>
            <td>
                <DateTimePickerKo id = "mntrStrtDtm" name = "mntrStrtDtm"  className = "meainDatePicker"
                    value = {dateFormat(selectMeain.mntrStrtDtm)} 
                    format = {"yyyy-MM-dd HH:mm"}
                    formatPlaceholder={{ year: '년', month: '월', day: '일' , hour : "시간" , minute : "분" }}
                    setDtm = {(v) => { onChangeDateTiem(v , "mntrStrtDtm"); }} required = {true} validationMessage = {inputValidMsg.normalText}
                    >
                </DateTimePickerKo> 
            </td>
        </tr>
        <tr><th colSpan="6"><div></div></th></tr>
        <tr>
            <th>AS만료일</th>
                <td>
                  <DatePickerKo 
                  id = "asExpiDtm" name = "asExpiDtm"  className = "meainDatePicker"  
                  value = {dateFormat(selectMeain.asExpiDtm)} 
                  format="yyyy-MM-dd"
                  formatPlaceholder={{ year: '년', month: '월', day: '일' }}
                  setDtm = {(v) => { onchageDate(v , "asExpiDtm"); }} required = {true} validationMessage = {inputValidMsg.normalText}
                  ></DatePickerKo>
                </td>
            <th>특이사항</th>
            <td colSpan = "3"><Input id = "etc" name = "etc" type="text" className={styles.searchTermTBC} value = { selectMeain.etc ? selectMeain.etc : "" } onChange = {onchageInput}/></td>
        </tr>
        <tr><th colSpan="6"><div></div></th></tr>
    </table>

                  
  );
}

export default Meain_info_fuelcell;