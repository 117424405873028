import log from '../../com/log';
import parseDate from '../../com/dateUtil';

const sysComUrl = 'sys'

// 그리드 데이터 조회
export const getSys = async(conn, data) => {
    const {take, skip, sysCodeNo, startDate, endDate} = data;
    const config = {
      params: {
        page: (take + skip) / take
        , pageSize: take
        , sysCodeNo
        , startDate
        , endDate
      }
    };
    const result = await conn.get(sysComUrl + '/getSys/page', config);

    log(result, 'getSys');

    // 그리드 등급 로직
    // 모두가 3001이나 3002이면 '정보'
    // 3003이면 경고
    // 3004이면 고장
    for(let swap of result.results) {
      let cpuCodeNo = swap.cpuStatCodeNo;
      let memCodeNo = swap.memoryStatCodeNo;
      let diskCodeNo = swap.prtnStatCodeNo;
      let swapCodeNo = cpuCodeNo;
      
      if(memCodeNo > cpuCodeNo) swapCodeNo = memCodeNo;
      if(diskCodeNo > memCodeNo) swapCodeNo = diskCodeNo;
      
      switch(swapCodeNo) {
        case 3001:
          swapCodeNo = '정상';
        case 3002:
          swapCodeNo = '정보';
          break;
        case 3003:
          swapCodeNo = '경고';
          break;
        case 3004:
          swapCodeNo = '고장';
          break;
      }
      swap.statCodeNo = swapCodeNo;
    }
    return result;

};