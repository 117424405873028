// React
import log from "../../../com/log";
import kendo from '@progress/kendo-ui';

const ensoEventComUrl = "stat/event/enso";

// 시간대별 그래프
export const getEnsoEventTime = async (conn, data) => {
  const startDate = kendo.toString(new Date(data.startDate), 'yyyy-MM-dd');
  const endDate = kendo.toString(new Date(data.endDate), 'yyyy-MM-dd');
    const config = {
      params: { startDate, endDate },
    };
    const result = await conn.get(ensoEventComUrl + "/ensoEventTime", config);
    log(result, "getEnsoEventTime");
    return result;
  
};

// 에너지원별 상세정보
export const getEnsoEventContent = async (conn, data) => {
  const startDate = kendo.toString(new Date(data.startDate), 'yyyy-MM-dd'); 
  const endDate = kendo.toString(new Date(data.endDate), 'yyyy-MM-dd');
    const config = {
      params: { startDate, endDate },
    };
    const result = await conn.get(ensoEventComUrl + "/ensoEventContent", config);
    log(result, "getEnsoEventContent");
    return result;
};
