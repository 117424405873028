import { useEffect, useState } from "react";
import { getToken } from "../../../service/authService";
import { initialGoogleAnalytics, getQueryData, getSessionDataListByBrowserName, getDailySessionData } from "../../../service/sys/visitorStatisticsSevice";
import LastWeekVisitorCountChart from "./lastWeekVisitorCountChart";
import RealTimeUserCount from "./realTimeUserCount";
import styles from "./visitorStatistics.module.css";
import "./visitorStatistics.css";
import kendo from "@progress/kendo-ui";

// 그래프
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import LastYearVisitorCountChart from "./lastYearVisitorCountChart";
import TopBrowserChart from "./topBrowserChart";
import TopDeviceChart from "./topDeviceChart";
import OsSessionChart from "./osSessionChart";
import MobileDeviceSessionChart from "./mobileDeviceSessionChart";
import RgnSessionChart from "./rgnSessionChart";
import BrowserKindGrid from "./browserKindGrid";
import BrowserKindChart from "./browserKindChart";
import DailySessionChart from "./dailySessionChart";
import { getLastWeek } from "../../../com/dateUtil";

const VisitorStatistics = ({ conn }) => {
  // 화살표 컨트롤러
  const [controller, setController] = useState({
    leftStatus: true,
    rightStatus: false,
  });

  // 일자별 세션 검색 상태
  const [search, setSearch] = useState({
    selectBrowserId: "",
    startGathDtm: getLastWeek(new Date()),
    endGathDtm: new Date(),
    today: new Date(),
    startMax: new Date(),
    endMin: new Date(),
  });

  // 데이터 집합체
  const [resultQueryData, setResultQueryData] = useState({
    realTimeUserCount: 0,
    todayUserCount: 0,
    monthUserCount: 0,
    weekUserCount: 0,
    yearUserCount: 0,
    totalUserCount: 0,
    lastWeekChartData: [],
    thisWeekChartData: [],
    lastYearChartData: [],
    thisYearChartData: [],
    topBrowserChartData: [],
    topDeviceChartData: [],
    rgnSessionChartData: [],
    mobileDeviceChartData: [],
    osSessionChartData: [],
    browserKindsData: [],
    targetBrowserDataChartData: [],
    targetBrowserDataCategory: [],
    dailySessionChartData: [],
    dailySessionChartCategory: [],
  });

  // 구글 Analytics 초기화 코드
  const initailAnalytics = () => {
    initialGoogleAnalytics(conn, initailAnalyticsResult, getRejection);
  };

  // 구글 Analytics 초기화 콜백
  const initailAnalyticsResult = (result) => {
    //금일 조회 카운트
    getTotalData();
  };

  //Reject 함수
  // reject 함수
  const getRejection = (error) => {
    if (error.errCode === "F001") {
      kendo.alert("시스템 오류가 발생 하였습니다.");
      return;
    } else if (error.errCode === "E001") {
      kendo.alert("필요한 값이 존재하지 않습니다. 다시 선택 또는 입력 해주세요.");
      return;
    }
  };

  // 구글 쿼리 요청
  const getTotalData = () => {
    getQueryData(conn, getTotalDataResult, getRejection);
  };

  const getTotalDataResult = (result) => {
    // 선택한 브라우저 ID 설정
    search.selectBrowserId = result.selectBrowserId;
    // 데이터 설정
    setResultQueryData((resultQueryData) => {
      const item = { ...resultQueryData };
      item.monthUserCount = result.monthUserCount;
      item.realTimeUserCount = result.realTimeUserCount;
      item.todayUserCount = result.todayUserCount;
      item.totalUserCount = result.totalUserCount;
      item.weekUserCount = result.weekUserCount;
      item.yearUserCount = result.yearUserCount;
      item.lastWeekChartData = result.lastWeekChartData;
      item.thisWeekChartData = result.thisWeekChartData;
      item.lastYearChartData = result.lastYearChartData;
      item.thisYearChartData = result.thisYearChartData;
      item.topBrowserChartData = result.topBrowserChartData;
      item.topDeviceChartData = result.topDeviceChartData;
      item.rgnSessionChartData = result.rgnSessionChartData;
      item.mobileDeviceChartData = result.mobileDeviceChartData;
      item.osSessionChartData = result.osSessionChartData;
      item.browserKindsData = result.browserKindsData;
      item.targetBrowserDataChartData = result.targetBrowserDataChartData;
      item.targetBrowserDataCategory = result.targetBrowserDataCategory;
      item.dailySessionChartData = result.dailySessionChartData;
      item.dailySessionChartCategory = result.dailySessionChartCategory;
      return item;
    });
  };

  // 브라우저 클릭 시 어제 기준 1주일 전부터 어제까지 세션 갯수 구하는 함수
  const getTargetBrowserSessionList = () => {
    getSessionDataListByBrowserName(conn, getTargetBrowserSessionResult, getRejection, search.selectBrowserId);
  };

  // 브라우저 클릭 시 어제 기준 1주일 전부터 어제까지 세션 데이터 결과
  const getTargetBrowserSessionResult = (result) => {
    setResultQueryData((resultQueryData) => {
      const item = { ...resultQueryData };
      item.targetBrowserDataChart = result.targetBrowserDataChart;
      item.targetBrowserDataCategory = result.targetBrowserDataCategory;
      return item;
    });
  };

  // 왼쪽 화살표 클릭
  const onClickLeft = () => {
    setController((controller) => {
      const item = { ...controller };
      item.leftStatus = true;
      item.rightStatus = false;
      return item;
    });
  };

  // 오른쪽 화살표 클릭
  const onClickRight = () => {
    setController((controller) => {
      const item = { ...controller };
      item.leftStatus = false;
      item.rightStatus = true;
      return item;
    });
  };

  //  Browser 종류 (sessions) 의 로우 클릭
  const setBrowserSelectId = (name) => {
    search.selectBrowserId = name;
    getTargetBrowserSessionList();
  };

  // 일자별 세션 검색 로직
  const getDailySessionDataList = () => {
    getDailySessionData(conn, getDailySessionDataListResult, getRejection, search);
  };

  // 일자별 세션 검색 결과
  const getDailySessionDataListResult = (result) => {
    // 데이터 설정
    setResultQueryData((resultQueryData) => {
      const item = { ...resultQueryData };
      item.dailySessionChartData = result.dailySessionChartData;
      item.dailySessionChartCategory = result.dailySessionChartCategory;
      return item;
    });
  };

  useEffect(() => {
    getToken();
    initailAnalytics();
  }, []);

  return (
    <div className={styles.parent}>
      <div className={styles.wrapChild}>
        <div className={styles.colXs1Left}>
          <div className={styles.helper}></div>
          <span
            id="left"
            className={[
              "k-icon",
              "k-i-arrow-chevron-left",
              "k-icon-64",
              styles.arrow,
              controller.rightStatus === true ? styles.showArrow : styles.hideArrow,
            ].join(" ")}
            onClick={onClickLeft}
          ></span>
        </div>
        <div id="child" className={styles.colXs10Child}>
          <header>
            <div id="embed-api-auth-container"></div>
            <div id="view-cnt" className={styles.dashboardSection}>
              <div style={{ display: "inline-block" }}>
                <div className={styles.cntSection}>
                  <span className={styles.cntSub}>오늘 방문자 수 </span>
                  <span className={styles.cntCon} id="cnt-0">
                    {resultQueryData.todayUserCount}
                  </span>
                </div>
                <div className={styles.cntSection}>
                  <span className={styles.cntSub}>주간 방문자 수 </span>
                  <span className={styles.cntCon} id="cnt-1">
                    {resultQueryData.weekUserCount}
                  </span>
                </div>
                <div className={styles.cntSection}>
                  <span className={styles.cntSub}>이번달 방문자 수 </span>
                  <span className={styles.cntCon} id="cnt-2">
                    {resultQueryData.monthUserCount}
                  </span>
                </div>
                <div className={styles.cntSection}>
                  <span className={styles.cntSub}>올해 방문자 수 </span>
                  <span className={styles.cntCon} id="cnt-3">
                    {resultQueryData.yearUserCount}
                  </span>
                </div>
                <div className={styles.cntSection}>
                  <span className={styles.cntSub}>전체 방문자 수 </span>
                  <span className={styles.cntCon} id="cnt-4">
                    {resultQueryData.totalUserCount}
                  </span>
                </div>
              </div>
            </div>
          </header>
          <div className={styles.ActiveUsers} id="active-users-container">
            실시간 방문자 현황 :
            <RealTimeUserCount conn={conn} realTimeUserCount={resultQueryData.realTimeUserCount} getTotalData={getTotalData} />
          </div>

          <div className={controller.rightStatus === true ? styles.hideLeft : styles.showLeft} id="section1">
            <div className={styles.fixHeghit_1}>
              <h3 className={styles.subject}>&sdot; 지난주 대비 방문자 수</h3>
              <div>
                <LastWeekVisitorCountChart
                  lastWeekChartData={resultQueryData.lastWeekChartData}
                  thisWeekChartData={resultQueryData.thisWeekChartData}
                  Highcharts={Highcharts}
                  HighchartsReact={HighchartsReact}
                />
              </div>
            </div>
            <div className={styles.fixHeghit_1}>
              <h3 className={styles.subject}>&sdot; 작년대비 방문자 수</h3>
              <div>
                <LastYearVisitorCountChart
                  lastYearChartData={resultQueryData.lastYearChartData}
                  thisYearChartData={resultQueryData.thisYearChartData}
                  Highcharts={Highcharts}
                  HighchartsReact={HighchartsReact}
                />
              </div>
            </div>
            <div className={styles.fixHeghit_1}>
              <h3 className={styles.subject}>&sdot; Top Browsers (pageview)</h3>
              <div>
                <TopBrowserChart Highcharts={Highcharts} HighchartsReact={HighchartsReact} topBrowserChartData={resultQueryData.topBrowserChartData} />
              </div>
            </div>
            <div className={styles.fixHeghit_1}>
              <h3 className={styles.subject}>&sdot; Top Device (sessions)</h3>
              <div>
                <TopDeviceChart Highcharts={Highcharts} HighchartsReact={HighchartsReact} topDeviceChartData={resultQueryData.topDeviceChartData} />
              </div>
            </div>
          </div>

          <div className={controller.leftStatus === true ? styles.hideRight : styles.showRight} id="section2" display={controller.leftMove}>
            <div className={styles.fixHeghit_1}>
              <h3 className={styles.subject}>&sdot; Browser 종류 (sessions)</h3>
              <div>
                <BrowserKindGrid
                  browserKindsData={resultQueryData.browserKindsData}
                  selectBrowserId={search.selectBrowserId}
                  setBrowserSelectId={setBrowserSelectId}
                />
              </div>
              <div class="comment">* 각 브라우저 클릭시 오른쪽에 상세 그래프가 표시됩니다.</div>
            </div>
            <div className={[styles.fixHeghit_1, styles.Wspace01].join(" ")}>
              <div>
                <BrowserKindChart
                  targetBrowserDataChartData={resultQueryData.targetBrowserDataChartData}
                  targetBrowserDataCategory={resultQueryData.targetBrowserDataCategory}
                  Highcharts={Highcharts}
                  HighchartsReact={HighchartsReact}
                />
              </div>
            </div>
            <div className={[styles.fixHeghit_1, styles.Wspace02].join(" ")}>
              <h3 className={styles.subject}>&sdot; 일자별 세션</h3>
              <DailySessionChart
                styles={styles}
                Highcharts={Highcharts}
                HighchartsReact={HighchartsReact}
                dailySessionChartData={resultQueryData.dailySessionChartData}
                search={search}
                setSearch={setSearch}
                dailySessionChartCategory={resultQueryData.dailySessionChartCategory}
                getDailySessionDataList={getDailySessionDataList}
              />
            </div>
            <div className={[styles.fixHeghit_1, styles.Wspace02].join(" ")}>
              <h3 className={styles.subject}>&sdot; 시도별 세션(sessions)</h3>
              <div>
                <RgnSessionChart Highcharts={Highcharts} HighchartsReact={HighchartsReact} rgnSessionChartData={resultQueryData.rgnSessionChartData} />
              </div>
            </div>
            <div className={[styles.fixHeghit_1, styles.Wspace03].join(" ")}>
              <h3 className={styles.subject}>&sdot; 모바일 기기 모델별 세션</h3>
              <div>
                <MobileDeviceSessionChart
                  Highcharts={Highcharts}
                  HighchartsReact={HighchartsReact}
                  mobileDeviceChartData={resultQueryData.mobileDeviceChartData}
                />
              </div>
            </div>
            <div className={[styles.fixHeghit_1, styles.Wspace03].join(" ")}>
              <h3 className={styles.subject}>&sdot; 운영체제별 세션</h3>
              <div>
                <OsSessionChart Highcharts={Highcharts} HighchartsReact={HighchartsReact} osSessionChartData={resultQueryData.osSessionChartData} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.colXs1Right}>
          <div className={styles.helper}></div>
          <span
            id="right"
            className={[
              "k-icon",
              "k-i-arrow-chevron-right",
              "k-icon-64",
              styles.arrow,
              controller.rightStatus === false ? styles.showArrow : styles.hideArrow,
            ].join(" ")}
            onClick={onClickRight}
          ></span>
        </div>
      </div>
    </div>
  );
};

export default VisitorStatistics;
