import React from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";

import RgnDropdown from "../../com/dropdown/rgnDropdown";
import { yearList } from "../../../com/dropDownDs";

const MonitorASSearch = ({ rgnList, codeList, search, setSearch, paging, styles, getMonitorASData }) => {

    // 에너지원 데이터 소스
    const ensoList = codeList.filter((c) => c.grpCodeNo === 15 && c.codeNo !== 15007);

    const bizSctList = codeList.filter((c) => c.grpCodeNo === 33);

    const onChange = (e) => {
        if (e.target.name === "ensoTypeDropDown") {
            setSearch((search) => {
                const item = { ...search };
                item.ensoType = { codeNo: e.value.codeNo, codeVal: e.value.codeVal };
                return item;
            })
        } else if (e.target.name === "bizSctDropDown") {
            setSearch((search) => {
                const item = { ...search };
                item.bizSct = { codeNo: e.value.codeNo, codeVal: e.value.codeVal };
                return item;
            })
        } else if (e.target.name === "bizYyDropDown") {
            setSearch((search) => {
                const item = { ...search };
                item.bizYy = e.value;
                return item;
            })
        } else if (e.target.name === "rgnDropDown") {
            setSearch((search) => {
                const item = { ...search };
                item.rgn = e.value;
                return item;
            });
        }
    }

    // 조회 버튼
    const searchData = () => {
        // 페이징 초기화 로직 추가
        paging.skip = 0;
        paging.take = 20;
        getMonitorASData(search, paging);
    };
    return (
        <div className="uk-grid">
            <div className="uk-width-medium-1-1">
                <div className="md-card TBgA">
                    <div className={["md-card-content", styles.searchPadding].join(" ")}>
                        <label for="enso">
                            <DropDownList
                                data={ensoList}
                                name="ensoTypeDropDown"
                                textField="codeVal"
                                dataItemKey="codeNo"
                                onChange={onChange}
                                className="monitorASSearchDropDown"
                                defaultItem={{ codeNo: "", codeVal: "에너지원" }}
                                value={search.ensoType}
                            />
                        </label>
                        <label for="bizSct">
                            <DropDownList
                                data={bizSctList}
                                name="bizSctDropDown"
                                textField="codeVal"
                                dataItemKey="codeNo"
                                onChange={onChange}
                                className="monitorASSearchDropDown"
                                defaultItem={{ codeNo: "", codeVal: "사업구분" }}
                                value={search.bizSct}
                            />
                        </label>
                        <label for="bizYy">
                            <DropDownList
                                id="bizYy"
                                name="bizYyDropDown"
                                data={yearList()}
                                className="monitorASSearchDropDown"
                                defaultItem="사업연도"
                                onChange={onChange}
                            />
                        </label>
                        <label for="rgn">
                            <RgnDropdown
                                name="rgnDropDown"
                                rgnList={rgnList}
                                search={search}
                                objKey={"rgn"}
                                setSearch={setSearch}
                                onChange={onChange}
                                defaultItem={{ rgnTermSimple: "시/군/구", rgnCode: "" }}
                            />
                        </label>
                        <span className="searchSubTitleC">
                            <Button id="inqBtn" icon="search" name="inqBtn" onClick={searchData} style={{ paddingLeft: "20px", paddingRight: "20px" }}></Button>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MonitorASSearch;
