// React
import React, { useState, useEffect, useCallback } from 'react';
import { getCpuMem, getDisk, setCpuMem, setDisk } from '../../../service/sys/sysServerService';
import log from '../../../com/log';

// Kendo
import kendo from "@progress/kendo-ui";
import { Grid, GridColumn, GridNoRecords } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';
import { Button } from '@progress/kendo-react-buttons';
import { Create } from "@material-ui/icons";

// Css
import './sysServer.css';
import styles from './sysServer.module.css';

function SysServer({ conn, codeList }) {


  // 서버 리스트
  const serverList = codeList.filter(codeItem => codeItem.grpCodeNo === 11);

  const [highBkupData, setHighBkupData] = useState([]);
  // 상단 그리드 state
  const [highDataSource, setHighDataSource] = useState([]);
  const [highDataState, setHighDataState] = useState({
    take: 20
    , skip: 0
    , total: 0
    , sort: []
  });
  const [lowBkupData, setLowBkupData] = useState([]);
  // 하단 그리드 state
  const [lowDataSource, setLowDataSource] = useState([]);
  const [lowDataState, setLowDataState] = useState({
    take: 20
    , skip: 0
    , total: 0
    , sort: []
  });


  useEffect(() => {
    getCpuMem(conn, highDataState, serverList)
    .then( response => {
      const { results, total } = response;
      setHighDataSource(results);
      setHighDataState({...highDataState, total});
    })
    .catch( err => {
      log(`useEffect.getCpuMem error: ${err}`);
    });

    getDisk(conn, lowDataState, serverList)
    .then( response => {
      const { results, total } = response;
      setLowDataSource(results);
      setLowDataState({...lowDataState, total});
    })
    .catch( err => {
      log(`useEffect.getDisk error: ${err}`);
    });
  }, []);
  

  // 상단 그리드 페이징 처리
  const handleHighPageChange = useCallback( e => {
    const {take, skip} = e.page;
    const {sort} = highDataState;

    const stateObj = new Object();
    stateObj.take = take;
    stateObj.skip = skip;

    if(sort[0] !== undefined) {
      stateObj.sort = sort[0].field + ',' + sort[0].dir;
    } else {
      delete stateObj.sort;
    }

    getCpuMem(conn, stateObj, serverList)
    .then( response => {
      const { results, total } = response;
      setHighDataSource(results);
      setHighDataState({...highDataState, take, skip, total});
    })
    .catch( err => {
      log(`handleHighPageChange.getCpuMem err: ${err}`);
    });
  }, [highDataSource, highDataState]);


  // 상단 그리드 소팅 처리
  const handleHighSortChange = useCallback( e => {
    const {sort} = e;
    const {take, skip} = highDataState;

    const stateObj = new Object();
    stateObj.take = take;
    stateObj.skip = skip;

    if(sort[0] !== undefined) {
      if(sort[0].field === 'sysCodeTerm') {
        stateObj.sort = 'sysCodeNo,' + sort[0].dir;
      } else {
        stateObj.sort = sort[0].field + ',' + sort[0].dir;
      }
    } else {
      delete stateObj.sort;
    }

    getCpuMem(conn, stateObj, serverList)
    .then( response => {
      const { results, total } = response;
      setHighDataSource(results);
      setHighDataState({...highDataState, sort, total});
    })
    .catch( err => {
      log(`handleHighSortChange.getCpuMem err: ${err}`);
    });
  }, [highDataSource, highDataState]);


  // 하단 그리드 페이징 처리
  const handleLowPageChange = useCallback( e => {
    const {take, skip} = e.page;
    const {sort} = lowDataState;

    const stateObj = new Object();
    stateObj.take = take;
    stateObj.skip = skip;

    if(sort[0] !== undefined) {
      stateObj.sort = sort[0].field + ',' + sort[0].dir;
    } else {
      delete stateObj.sort;
    }

    getDisk(conn, stateObj, serverList)
    .then( response => {
      const { results, total } = response;
      setLowDataSource(results);
      setLowDataState({...lowDataState, take, skip, total});
    })
    .catch( err => {
      log(`handleLowPageChange.getDisk err: ${err}`);
    })
  }, [lowDataSource, lowDataState]);


  // 하단 그리드 소팅 처리
  const handleLowSortChange = useCallback( e => {
    const {sort} = e;
    const {take, skip} = lowDataState;

    const stateObj = new Object();
    stateObj.take = take;
    stateObj.skip = skip;

    if(sort[0] !== undefined) {
      if(sort[0].field === 'sysCodeTerm') {
        stateObj.sort = 'sysCodeNo,' + sort[0].dir;
      } else {
        stateObj.sort = sort[0].field + ',' + sort[0].dir;
      }
    } else {
      delete stateObj.sort;
    }

    getDisk(conn, stateObj, serverList)
    .then( response => {
      const { results, total } = response;
      setLowDataSource(results);
      setLowDataState({...lowDataState, sort, total});
    })
    .catch( err => {
      log(`handleLowSortChange.getDisk err: ${err}`);
    });
  }, [lowDataSource, lowDataState]);


  // 상단 그리드 아이템 변화 이벤트
  const handleHighItemChange = useCallback( e => {

    const data = highDataSource.map(
      item =>
        item.sysCodeNo === e.dataItem.sysCodeNo
        ? {...item, [e.field]: e.value}
        : item
    );

    setHighDataSource(data);
  }, [highDataSource]);


  // 상단 그리드 저장 버튼 이벤트
  const handleHighUpdate = useCallback( dataItem => {

    const { cpuBounWarnRate, cpuBounErrRate, memoryBounWarnRate, memoryBounErrRate } = dataItem;
    
    let validationFlag = false;

    if( !(cpuBounWarnRate > cpuBounErrRate) ) {
      // cpu임계경고비율은 cpu임계에러비율보다 커야 한다.
      kendo.alert('cpu임계경고비율은 cpu임계에러비율보다 커야 합니다.');

    } else if( !(memoryBounWarnRate > memoryBounErrRate) ) {
      // memory임계경고비율은 memory임계에러비율보다 커야 한다.
      kendo.alert('memory임계경고비율은 memory임계에러비율보다 커야 합니다.');
      
    } else if(cpuBounWarnRate < 0) {
      // cpu임계경고비율은 0보다 작을 수 없다.
      kendo.alert('cpu임계경고비율은 0보다 작을 수 없습니다.');
      
    } else if(cpuBounErrRate < 0) {
      // cpu임계에러비율은 0보다 작을 수 없다.
      kendo.alert('cpu임계에러비율은 0보다 작을 수 없습니다.');
      
    } else if(memoryBounWarnRate < 0) {
      // memory임계경고비율은 0보다 작을 수 없다.
      kendo.alert('memory임계경고비율은 0보다 작을 수 없습니다.');
      
    } else if(memoryBounErrRate < 0) {
      // memory임계에러비율은 0보다 작을 수 없다.
      kendo.alert('memory임계에러비율은 0보다 작을 수 없습니다.');
      
    } else if(cpuBounWarnRate > 100) {
      // cpu임계경고비율은 100보다 클 수 없다.
      kendo.alert('cpu임계경고비율은 100보다 클 수 없습니다.');
      
    } else if(cpuBounErrRate > 100) {
      // cpu임계에러비율은 100보다 클 수 없다.
      kendo.alert('cpu임계에러비율은 100보다 클 수 없습니다.');
      
    } else if(memoryBounWarnRate > 100) {
      // memory임계경고비율은 100보다 클 수 없다.
      kendo.alert('memory임계경고비율은 100보다 클 수 없습니다.');
      
    } else if(memoryBounErrRate > 100) {
      // memory임계에러비율은 100보다 클 수 없다.
      kendo.alert('memory임계에러비율은 100보다 클 수 없습니다.');

    } else {
      validationFlag = true;
    }

    if(validationFlag) {
      setCpuMem(conn, dataItem)
      .then( () => {

        getCpuMem(conn, highDataState, serverList)
        .then( response => {
          const { results, total } = response;
          setHighDataSource(results);
          setHighDataState({...highDataState, total});
        })
        .catch( err => {
          log(`sysService.getCpuMem error: ${err}`);
        });

      })
      .catch( err => {
        log(`sysService.handleHighUpdate err: ${err}`);
      });
    }

  }, []);


  // 상단 그리드 취소 버튼 이벤트
  const handleHighCancel = useCallback( dataItem => {

    const data = highBkupData.map(
      item =>
        item.sysCodeNo === dataItem.sysCodeNo
        ? {...item, inEdit: false}
        : item
    );

    setHighDataSource(data);
    setHighBkupData(data);
  }, [highDataSource, highBkupData]);


  // 상단 그리드 수정 버튼 이벤트
  const handleHighEnterEdit = useCallback( dataItem => {

    const data = highDataSource.map(
      item =>
        item.sysCodeNo === dataItem.sysCodeNo
        ? {...item, inEdit: true}
        : item
    );

    setHighDataSource(data);
    setHighBkupData(data);
  }, [highDataSource, highBkupData]);


  // 상단 그리드 Cell 버튼 관리
  const handleHighCellBtn = props => {

    const { dataItem } = props;
    const inEdit = dataItem.inEdit;

    return inEdit ? (
      <div className='sysServerCellBtn'>
        <Button onClick={() => handleHighUpdate(dataItem)}>저장</Button>
        <Button onClick={() => handleHighCancel(dataItem)}>취소</Button>
      </div>
    ) : (
      <div className='sysServerCellBtn'>
        <Button onClick={() => handleHighEnterEdit(dataItem)}>
          <i>
            <Create />
          </i>
          수정
        </Button>
      </div>
    );
  };


  // 하단 그리드 아이템 변화 이벤트
  const handleLowItemChange = useCallback( e => {

    const data = lowDataSource.map(
      item =>
        item.sysCodeNo === e.dataItem.sysCodeNo
        ? {...item, [e.field]: e.value}
        : item
    );

    setLowDataSource(data);
  }, [lowDataSource]);


  // 하단 그리드 저장 버튼 이벤트
  const handleLowUpdate = useCallback( dataItem => {
    
    const {prtnUseWarnRate, prtnUseErrRate} = dataItem;

    let validationFlag = false;

    if( !(prtnUseWarnRate > prtnUseErrRate) ) {
      // disk임계경고비율은 disk임계에러비율보다 커야 한다.
      kendo.alert('disk임계경고비율은 disk임계에러비율보다 커야 합니다.');

    } else if(prtnUseWarnRate < 0) {
      // disk임계경고비율은 0보다 작을 수 없다.
      kendo.alert('disk임계경고비율은 0보다 작을 수 없습니다.');

    } else if(prtnUseErrRate < 0) {
      // disk임계에러비율은 0보다 작을 수 없다.
      kendo.alert('disk임계에러비율은 0보다 작을 수 없습니다.');

    } else if(prtnUseWarnRate > 100) {
      // disk임계경고비율은 100보다 클 수 없다.
      kendo.alert('disk임계경고비율은 100보다 클 수 없습니다.');

    } else if(prtnUseErrRate > 100) {
      // disk임계경고비율은 100보다 클 수 없다.
      kendo.alert('disk임계경고비율은 100보다 클 수 없습니다.');

    } else {
      validationFlag = true;
    }

    if(validationFlag) {
      setDisk(conn, dataItem)
      .then( () => {

        getDisk(conn, lowDataState, serverList)
        .then( response => {
          const { results, total } = response;
          setLowDataSource(results);
          setLowDataState({...lowDataState, total});
        })
        .catch( err => {
          log(`useEffect.getDisk error: ${err}`);
        });

      })
      .catch( err => {
        log(`sysService.handleLowUpdate err: ${err}`);
      });
    }

  }, []);


  // 하단 그리드 취소 버튼 이벤트
  const handleLowCancel = useCallback( dataItem => {
    
    const data = lowBkupData.map(
      item =>
        item.sysCodeNo === dataItem.sysCodeNo
        ? {...item, inEdit: false}
        : item
    );

    setLowDataSource(data);
    setLowBkupData(data);
  }, [lowDataSource, lowBkupData]);


  // 하단 그리드 수정 버튼 이벤트
  const handleLowEnterEdit = useCallback( dataItem => {

    const data = lowDataSource.map(
      item =>
        item.sysCodeNo === dataItem.sysCodeNo
        ? {...item, inEdit: true}
        : item
    );

    setLowDataSource(data);
    setLowBkupData(data);
  }, [lowDataSource, lowBkupData]);


  // 하단 그리드 Cell 버튼 관리
  const handleLowCellBtn = props => {

    const { dataItem } = props;
    const inEdit = dataItem.inEdit;

    return inEdit ? (
      <div className='sysServerCellBtn'>
        <Button onClick={() => handleLowUpdate(dataItem)}>저장</Button>
        <Button onClick={() => handleLowCancel(dataItem)}>취소</Button>
      </div>
    ) : (
      <div className='sysServerCellBtn'>
        <Button onClick={() => handleLowEnterEdit(dataItem)}>
          <i>
            <Create />
          </i>
          수정
        </Button>
      </div>
    );
  };


  return (
    <>
      <div className='sysServerBody'>
        <div className='sysServerHighGrid'>
          <LocalizationProvider language="ko-KR">
            <IntlProvider locale="ko">
              <Grid
                className={styles.sysServerHighGrid}
                data={highDataSource}
                {...highDataState}
                editField='inEdit'
                onItemChange={handleHighItemChange}
                pageable={{buttonCount: 5}}
                sortable
                onPageChange={handleHighPageChange}
                onSortChange={handleHighSortChange}
              >
                <GridColumn title='시스템' field='sysCodeTerm' width='300' editable={false} />
                <GridColumn title='CPU 임계경고비율(%)' field='cpuBounWarnRate' editor='numeric' />
                <GridColumn title='CPU 임계오류비율(%)' field='cpuBounErrRate' editor='numeric' />
                <GridColumn title='메모리 임계경고비율(%)' field='memoryBounWarnRate' editor='numeric' />
                <GridColumn title='메모리 임계오류비율(%)' field='memoryBounErrRate' editor='numeric' />
                <GridColumn width='100' cell={handleHighCellBtn} />
                <GridNoRecords>
                  데이터가 존재하지 않습니다.
                </GridNoRecords>
              </Grid>
            </IntlProvider>
          </LocalizationProvider>
        </div>
        <div className="sysServerLowGrid">
          <LocalizationProvider language="ko-KR">
            <IntlProvider locale="ko">
              <Grid
                className={styles.sysServerLowGrid}
                data={lowDataSource}
                {...lowDataState}
                editField='inEdit'
                onItemChange={handleLowItemChange}
                pageable={{buttonCount: 5}}
                sortable
                onPageChange={handleLowPageChange}
                onSortChange={handleLowSortChange}
              >
                <GridColumn title='시스템' field='sysCodeTerm' width='300' editable={false} />
                <GridColumn title='파티션' field='prtnTerm' editable={false} />
                <GridColumn title='파티션 사용경고비율(%)' field='prtnUseWarnRate' editor='numeric' />
                <GridColumn title='파티션 사용오류비율(%)' field='prtnUseErrRate' editor='numeric' />
                <GridColumn width='100' cell={handleLowCellBtn} />
                <GridNoRecords>
                  데이터가 존재하지 않습니다.
                </GridNoRecords>
              </Grid>
            </IntlProvider>
          </LocalizationProvider>
        </div>
      </div>
    </>
  );
}

export default SysServer;