import React, { useEffect, useState } from "react";
//components
import { roundNum } from "./../../../com/dataUtil";
//icon
import Co2Icon from "@mui/icons-material/Co2";
import SolarPowerIcon from "@mui/icons-material/SolarPower";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import { SvgIcon } from "@material-ui/core";
//css
import styles from "./renewStat.module.css";
import CountUp from "react-countup";
const RenewStatLeft = (props) => {
  const { currMap, dashData, realPowerBoxRef, estPowerBoxRef, realUseBoxRef, carbDecBoxRef } = props;
  const sumObjArr = (prpt) => {
    const sum = dashData.reduce((acc, obj) => {
      return acc + obj[prpt];
    }, 0);
    return roundNum(sum, 2);
  };
  const [dataSource, setDataSource] = useState({
    cnt: 0,
    dayPower: 0,
    dayAddPower: 0,
    dayUse: 0,
    dayAddUse: 0,
    carbDec: 0,
  });

  useEffect(() => {
    if (!currMap.rgnCode) {
      setDataSource({
        cnt: sumObjArr("cnt"),
        dayPower: sumObjArr("dayPower"),
        dayAddPower: sumObjArr("dayAddPower"),
        dayUse: sumObjArr("dayUse"),
        dayAddUse: sumObjArr("dayAddUse"),
        carbDec: sumObjArr("carbDec"),
      });
    } else {
      const currData = dashData.find((e) => e.rgnCode === currMap.rgnCode);
      setDataSource({
        cnt: currData.cnt,
        dayPower: currData.dayPower,
        dayAddPower: currData.dayAddPower,
        dayUse: currData.dayUse,
        dayAddUse: currData.dayAddUse,
        carbDec: currData.carbDec,
      });
    }
  }, [currMap.rgnCode, dashData]);

  /*  지도에 마우스 오버 이벤트 */
  const realPowerOverEvt = (e) => {
    realPowerBoxRef.current.style.display = "block";
    realPowerBoxRef.current.style.top = e.pageY + 15 + "px";
    realPowerBoxRef.current.style.left = e.pageX + 15 + "px";
  };
  /* 지도에서 마우스 벗어났을 때 이벤트 */
  const realPowerOutEvt = (e) => {
    // setFixMapVis(true);
    realPowerBoxRef.current.style.display = "none";
  };

  /*  지도에 마우스 오버 이벤트 */
  const estPowerOverEvt = (e) => {
    estPowerBoxRef.current.style.display = "block";
    estPowerBoxRef.current.style.top = e.pageY + 15 + "px";
    estPowerBoxRef.current.style.left = e.pageX + 15 + "px";
  };
  /* 지도에서 마우스 벗어났을 때 이벤트 */
  const estPowerOutEvt = (e) => {
    // setFixMapVis(true);
    estPowerBoxRef.current.style.display = "none";
  };

  /*  지도에 마우스 오버 이벤트 */
  const realUseOverEvt = (e) => {
    realUseBoxRef.current.style.display = "block";
    realUseBoxRef.current.style.top = e.pageY + 15 + "px";
    realUseBoxRef.current.style.left = e.pageX + 15 + "px";
  };
  /* 지도에서 마우스 벗어났을 때 이벤트 */
  const realUseOutEvt = (e) => {
    // setFixMapVis(true);
    realUseBoxRef.current.style.display = "none";
  };

  /*  지도에 마우스 오버 이벤트 */
  const carbDecOverEvt = (e) => {
    carbDecBoxRef.current.style.display = "block";
    carbDecBoxRef.current.style.top = e.pageY + 15 + "px";
    carbDecBoxRef.current.style.left = e.pageX + 15 + "px";
  };
  /* 지도에서 마우스 벗어났을 때 이벤트 */
  const carbDecOutEvt = (e) => {
    // setFixMapVis(true);
    carbDecBoxRef.current.style.display = "none";
  };

  return (
    <>
      <div className={styles.sun} />
      <span className={styles.renewHeader}>
        충청남도 <br />
        신재생에너지 현황
      </span>
      <div className={styles.tgDiv}>
        <table className={styles.tg} onMouseMove={estPowerOverEvt} onMouseOut={estPowerOutEvt}>
          <thead>
            <tr>
              <td rowspan="2" className={styles.secTd}>
                <SvgIcon component={SolarPowerIcon} />
                <br />
                <span>설비개수</span>
              </td>
              <td>
                <CountUp end={dataSource.cnt} separator="," duration={1.5} decimals={0} delay={2} redraw={true} suffix=" 개" />
              </td>
            </tr>
            {/* <tr>
              <td>설비 개수</td>
            </tr> */}
          </thead>
        </table>
        <table className={styles.tg} onMouseMove={realPowerOverEvt} onMouseOut={realPowerOutEvt}>
          <thead>
            <tr>
              <td rowspan="2" className={styles.firTd}>
                <SvgIcon component={ElectricBoltIcon} />
                <br />
                <span>전기에너지</span>
              </td>
              <td>
                <CountUp end={dataSource.dayPower + dataSource.dayAddPower} separator="," duration={1.5} decimals={1} delay={2} redraw={true} suffix=" MWh" />
              </td>
            </tr>
            {/* <tr>
              <td>금일 발전량</td>
            </tr> */}
          </thead>
        </table>
        <table className={styles.tg} onMouseMove={realUseOverEvt} onMouseOut={realUseOutEvt}>
          <thead>
            <tr>
              <td rowspan="2" className={styles.thrTd}>
                <SvgIcon component={LocalFireDepartmentIcon} />
                <br />
                <span>열에너지</span>
              </td>
              <td>
                <CountUp end={dataSource.dayUse + dataSource.dayAddUse} separator="," duration={1.5} decimals={1} delay={2} redraw={true} suffix=" MWh" />
              </td>
            </tr>
            {/* <tr>
              <td>금일 사용량</td>
            </tr> */}
          </thead>
        </table>
        <table className={styles.tg} onMouseMove={carbDecOverEvt} onMouseOut={carbDecOutEvt}>
          <thead>
            <tr>
              <td rowspan="2" className={styles.fourTd}>
                <SvgIcon component={Co2Icon} />
                <br />
                <span>탄소저감량</span>
              </td>
              <td>
                <CountUp end={dataSource.carbDec} separator="," duration={1.5} decimals={1} delay={2} redraw={true} suffix=" tCO" />
                <sub>2</sub>
              </td>
            </tr>
            {/* <tr>
              <td>금일 탄소 저감량</td>
            </tr> */}
          </thead>
        </table>
      </div>

      <div id={styles.percentInfoDiv}>
        <div style={{ padding: "5px", fontSize: "18px", textAlign: "center" }}>
          <b>지역별 설비보급량</b> <span style={{ float: "right" }}></span>
        </div>
        <div style={{ padding: "5px" }}>
          <table style={{ borderCollapse: "collapse" }}>
            <tbody>
              <tr>
                <td>
                  <b>낮음</b>&nbsp;
                </td>
                <td style={{ padding: "0px" }}>
                  <img src="/images/percent/fourthGra.png" alt="4" />
                </td>
                <td style={{ padding: "0px" }}>
                  <img src="/images/percent/thirdGra.png" alt="2" />
                </td>
                <td style={{ padding: "0px" }}>
                  <img src="/images/percent/secondGra.png" alt="3" />
                </td>
                <td style={{ padding: "0px" }}>
                  <img src="/images/percent/firstGra.png" alt="1" />
                </td>
                <td>
                  &nbsp;<b>높음</b>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div style={{ borderLeft: "8px solid #7AB700" }} ref={estPowerBoxRef} className={styles.renewStatTbl}>
        설치된 총 설비의 개수입니다.
        <br />
        <sub>(에너지원 : 태양광, 태양열, 지열, 풍력, 연료전지)</sub>
      </div>
      <div style={{ borderLeft: "8px solid #FFAD01" }} ref={realPowerBoxRef} className={styles.renewStatTbl}>
        금일 전기에너지 발전량입니다. <br />
        <sub>(에너지원 : 태양광, 풍력, 연료전지)</sub>
      </div>
      <div style={{ borderLeft: "8px solid #FE4A59" }} ref={realUseBoxRef} className={styles.renewStatTbl}>
        금일 열에너지 사용량입니다.
        <br />
        <sub>(에너지원 : 태양열, 지열)</sub>
      </div>
      <div style={{ borderLeft: "8px solid #0B92CC" }} ref={carbDecBoxRef} className={styles.renewStatTbl}>
        금일 신재생에너지를 발전/사용하여 <br />
        감소한 탄소 저감량입니다.
        <br />
        <sub>
          (탄소저감식 = 단위 MWh * 0.4653 tCO<sub>2</sub>)
        </sub>
      </div>
    </>
  );
};

export default RenewStatLeft;
