import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-data-query";
import React, { useState, useEffect } from "react";
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import kendo from "@progress/kendo-ui";

const TimePowerGrid = ({ dataSource, ensoTypeCodeNo, unit, setExcelData, completeExcel, LocalizationProvider, IntlProvider }) => {
  const [paging, setPaging] = useState({ take: 20, skip: 0 });
  const [sort, setSort] = useState([{ field: "", dir: "" }]);

  // 페이징 이벤트
  const onPageChange = (e) => {
    const { page } = e;
    setPaging(page);
  };

  // 정렬 이벤트
  const onSortChange = (e) => {
    const { sort } = e;
    setSort(sort);
  };

  // 엑셀 다운로드 변수 (레퍼런스 사용)
  let _excelData;

  // 에너지원별 생산량 , 발전량 분리
  const isUse = ensoTypeCodeNo === 15002 || ensoTypeCodeNo === 15003 ? true : false;

  // 파일 이름
  const fileName = () => {
    let name = "전체";
    if (ensoTypeCodeNo === 15001) {
      name = "태양광";
    } else if (ensoTypeCodeNo === 15002) {
      name = "태양열";
    } else if (ensoTypeCodeNo === 15003) {
      name = "지열";
    } else if (ensoTypeCodeNo === 15004) {
      name = "풍력";
    } else if (ensoTypeCodeNo === 15006) {
      name = "연료전지";
    } else if (ensoTypeCodeNo === 15007) {
      name = "ESS";
    }

    if (unit === "HH") name += "_시간별_";
    else if (unit === "DD") name += "_일별_";
    else if (unit === "MM") name += "_월별_";

    const template = name + "시간대별 데이터_" + kendo.toString(new Date(), "yyyyMMdd") + ".xlsx";
    return template;
  };

  useEffect(() => {
    // 엑셀 다운로드 데이터 설정
    setExcelData(_excelData);
  }, []);

  return (
    <div>
      <ExcelExport
        className="dataGathGrid"
        data={orderBy(dataSource, sort)}
        ref={(exporter) => (_excelData = exporter)}
        fileName={fileName()}
        onExportComplete={completeExcel}
      >
        <ExcelExportColumn title="시간" field="gathDtm" width={100} />
        {isUse === false && <ExcelExportColumn title="발전량(kWh)" field="dayPower" width={150} />}
        {isUse === true && <ExcelExportColumn title="생산량(kWh)" field="dayPower" width={150} />}
        <ExcelExportColumn title="평균효율(%)" field="efic" width={150} />
        <ExcelExportColumn title="최고효율(%)" field="maxEfic" width={150} />
        <ExcelExportColumn title="최저효율(%)" field="minEfic" width={150} />
      </ExcelExport>
      <LocalizationProvider language="ko-KR">
        <IntlProvider locale="ko">
          <Grid
            style={{ height: "50vh" }}
            id="timeGrid"
            className="ensoTimeGrid"
            data={orderBy(dataSource, sort).slice(paging.skip, paging.take + paging.skip)}
            total={dataSource.length}
            skip={paging.skip}
            take={paging.take}
            onPageChange={onPageChange}
            onSortChange={onSortChange}
            sort={sort}
            page={paging}
            sortable
            pageable
          >
            <GridNoRecords>{"  "}</GridNoRecords>
            <GridColumn title="시간" field="gathDtm" width="100px"></GridColumn>
            {isUse === false && <GridColumn title="발전량(kWh)" field="dayPower"></GridColumn>}
            {isUse === true && <GridColumn title="생산량(kWh)" field="dayPower"></GridColumn>}
            <GridColumn title="평균효율(%)" field="efic"></GridColumn>
            <GridColumn title="최고효율(%)" field="maxEfic"></GridColumn>
            <GridColumn title="최저효율(%)" field="minEfic"></GridColumn>
          </Grid>
        </IntlProvider>
      </LocalizationProvider>
    </div>
  );
};

export default TimePowerGrid;
