import { useCallback, useEffect, useRef, useState } from "react";
import { BeatLoader } from "react-spinners";
import Map from "./map";
import InstallerList from "./installerList";
import MeainCount from "./meainCount";
import styles from "./locationDepth3.module.css";
import "./locationDepth3.css";
import { getInstallAreaDataList } from "../../../service/location/locationDepth3Service";
import { getInstallMeainStatData } from "../../../service/location/locationService";
import LocationSearch from "./locationSearch";
import { MeainInfo, ClusterInfo } from "./meainInfo";
import { useHistory, useLocation } from "react-router-dom";
import { getUser, getUserMngRgnList, isInstaller, isManager } from "../../../service/authService";

// 카카오 적용
const { kakao } = window;

/***
 * 주의사항 : 카카오는 React 기반이 아닌 바닐라 자바스크립트 코드로 이루어져서 마커업과 클러스터 작업 시
 *           데이터 소스를 useState 가 아닌 useRef 로 제어 해야한다.
 *           맵은 데이터 소스가 랜더링 하기 전에 맵이 변경 되어 데이터 소스에 따라서 내가 원하는 마커와 클러스터를 그릴 수 없다.
 *           그래서 데이터 소스를 state 방식이 아닌 useRef 로 제어 하여 랜더링에 제안이 아닌 쉽게 제어가 되도록 해야한다.
 * */

// 계측 정보 확인 버튼 하나 추가 하기
// 설치현황 Depth3
export function LocationDepth3({ conn, codeList, rgnList, dongList, qs }){
  // 로딩 스피너
  const [loading, setLoading] = useState(false);

  // 히스토리 사용
  const history = useHistory();

  let location = useLocation();
  let { rgnCode } = qs.parse(location.search);

  const rgnObj = rgnList.filter((c) => c.rgnCode === rgnCode)[0];
  const dongObj = dongList.filter((c) => c.rgnCode === rgnCode);

  // 유저정보
  const userInfo = getUser();

  // 담당자 일 때 시군구 리스트 설정
  if (isManager() === true) {
    const userMngRgnList = getUserMngRgnList();
    const rgnListfilter = [];
    rgnList.forEach((element) => {
      if (userMngRgnList.length != 0) {
        userMngRgnList.forEach((rgnCode) => {
          if (rgnCode === element.rgnCode) {
            rgnListfilter.push(element);
          }
        });
      } else {
        rgnListfilter.push(element);
      }
    });
    rgnList = rgnListfilter;
  }

  // 검색 조건
  const [search, setSearch] = useState({
    userTerm: isInstaller() === true ? userInfo.userTerm : "",
    rgnCode: rgnCode ? rgnCode : "",
    fixbylawBundCode: "",
    bldSrvCodeNo: "",
    bizSct: "",
    bizYy: "",
    rgnTermSimple: rgnObj.rgnTermSimple,
    dongTermSimple: "",
    lati: rgnObj.lati,
    longi: rgnObj.longi,
    rgnLati: rgnObj.lati,
    rgnLongi: rgnObj.longi,
    rgn: { rgnCode: "", rgnTermSimple: "시군구" },
    dong: { fixbylawBundCode: "", fixbylawBundTermSimple: "읍면동" },
  });
  // 맵
  const [map, setMapObj] = useState("");
  // 클러스터
  const [cluster, setCluster] = useState("");
  // 네비게이션
  const [nav, setNav] = useState("충남");
  // 맵 초기화 및 설정 후 데이터 가져오기
  const setMaps = (map, cluster) => {
    setMapObj(map);
    // 클러스터 관련
    setCluster(cluster);
    // 클러스터 이벤트 적용
    setClusterEvt(cluster, map);
    // 데이터 로드
    setSearchInst(map, cluster);
  };

  // 클러스터 색깔 설정
  const setClusterStyle = (e) => {};

  // 지도에 표시된 마커 객체
  let markers = useRef([]);

  // 글로벌 인포윈도우
  var [globalInfoWindows, setGlobalInfoWindows] = useState([]);

  // 수용가 정보 리스트
  const [instList, setInstList] = useState([]);
  // 맵 마커 문제로 useRef 로 변경 (마커 데이터 소스)
  const meainList = useRef([]);

  // 운영현황
  const [statCnt, setStatCnt] = useState({
    totalCnt: 0,
    readyCnt: 0,
    normalCnt: 0,
    unOperCnt: 0,
    warnCnt: 0,
    errorCnt: 0,
  });

  // 검색 조건 실행
  const setSearchInst = (map, cluster) => {
    // 맵 포지션 이동
    setMapPosition(search.lati, search.longi, map);
    // 왼쪽 상단 네비게이터
    setNaviGator();
    // 수용가목록
    getInstallArea(map, cluster);
    // 설비 상태
    getInstallMeainStat();

    if(search.rgnCode !== "") {
      const searchParam = new URLSearchParams(location.search);
      searchParam.set('rgnCode', search.rgnCode)
      history.replace({
        ...location,
        search: searchParam.toString()
      })
    }
  };

  // 설지 지역 데이터 리스트
  const getInstallArea = (map, cluster) => {
    setLoading(true);
    getInstallAreaDataList(conn, getResultInstallData, getRejection, search, map, cluster, setLoading);
  };

  // 설지 지역 데이터 가공
  const getResultInstallData = (result, map, cluster) => {
    // 데이터 설정
    setInstList(result.installer);
    meainList.current = result.meainList;

    // 맵 마크업 진행 // 맵 객체가 있다면 진행
    if (map) {
      setMapMarkUp(meainList.current, map, cluster);
    } else {
      return;
    }
  };

  // 리젝션 함수 제작
  const getRejection = (result) => {
    // 리젝트 함수 사용 시 필요한 변수를 사용 안하거나 잘못된 접근 시 사용
  };

  // 검색설비 운영현황 데이터
  const getInstallMeainStat = () => {
    getInstallMeainStatData(conn, getResultMeainStat, getRejection, search);
  };

  // 검색설비 운영현황 가공 Callback
  const getResultMeainStat = (result) => {
    setStatCnt((statCnt) => {
      const item = { ...statCnt };
      item.totalCnt = result.totalCnt;
      item.readyCnt = result.readyCnt;
      item.normalCnt = result.normalCnt;
      item.unOperCnt = result.unOperCnt;
      item.warnCnt = result.warnCnt;
      item.errorCnt = result.errorCnt;
      return item;
    });
  };

  // 맵 좌표 요청 (맵 이동)
  const setMapPosition = (lati, longi, map) => {
    if (map) {
      // lati , longi 값이 빈값으로 들어오면 천안시청으로 이동
      if (!lati) {
        lati = 36.81508;
      }
      if (!longi) {
        longi = 127.11392;
      }
      const moveLocation = new kakao.maps.LatLng(lati, longi);
      map.setCenter(moveLocation);
    }
  };

  // 네비게이터 설정
  const setNaviGator = () => {
    const rgnTitle = search.rgnCode ? " > " + search.rgnTermSimple : " ";
    const dongTitle = search.fixbylawBundCode ? " > " + search.dongTermSimple : " ";
    setNav("충남" + rgnTitle + dongTitle);
  };

  // 맵 마크 업 작성
  const setMapMarkUp = (meainList, map, cluster) => {
    // 클러스터 초기화
    cluster.clear();
    // 이전 마커 삭제
    removeMarkers();
    // 읍면동 코드 여부에 따라서 적용
    if (search.fixbylawBundCode === "") {
      // 설비 갯수 만큼 마커 제작
      dongObj.forEach((element) => {
        meainList.forEach((meain) => {
          if (element.fixbylawBundCode === meain.dongCode) {
            const markerPosition = new kakao.maps.LatLng(element.lati, element.longi);
            const marker = new kakao.maps.Marker({
              position: markerPosition,
              title: JSON.stringify({
                rgnCode: element.rgnCode,
                rgnTermSimple: meain.rgnTerm,
                dongCode: element.fixbylawBundCode,
                dongTermSimple: element.fixbylawBundTermSimple,
                lati: element.lati,
                longi: element.longi,
              }),
            });
            markers.current.push(marker);
          }
        });
      });
      // 클러스터에 마커 적용
      cluster.addMarkers(markers.current);
    }
    // 읍면동 코드가 있을 경우 마커만 표시
    else {
      // 위치 정보 중복 처리 로직 추가 하기 (설비가 만약 천안시 중복으로 있을 경우 위치 정보 중복 제거로 마크를 중복으로 찍기 안하기 위해 작업)
      const uniqMeaindata = meainList.filter((item, i) => {
        return (
          meainList.findIndex((item2, j) => {
            return item.userId === item2.userId && item.lati === item2.lati && item.longi === item2.longi;
          }) === i
        );
      });

      uniqMeaindata.forEach((element) => {
        // 마커이미지의 주소입니다
        let imageSrc = "";
        if (element.meainStatCodeNo === 7001) {
          imageSrc = "/images/mark/mark_ready.png";
        } else if (element.meainStatCodeNo === 7002) {
          imageSrc = "/images/mark/mark_normal.png";
        } else if (element.meainStatCodeNo === 7004) {
          imageSrc = "/images/mark/mark_unoper.png";
        } else if (element.meainStatCodeNo === 7006) {
          imageSrc = "/images/mark/mark_warn.png";
        } else if (element.meainStatCodeNo === 7007) {
          imageSrc = "/images/mark/mark_error.png";
        } else {
          imageSrc = "/images/mark/mark_error.png";
        }

        // 마커이미지의 크기입니다
        const markSize = new kakao.maps.Size(27, 32);
        // 마커이미지의 옵션입니다. 마커의 좌표와 일치시킬 이미지 안에서의 좌표를 설정합니다.
        const imageOption = { offset: new kakao.maps.Point(16.5, 32) };
        // 마커의 이미지정보를 가지고 있는 마커이미지를 생성합니다
        const markerImage = new kakao.maps.MarkerImage(imageSrc, markSize, imageOption);
        const markerPosition = new kakao.maps.LatLng(element.lati, element.longi);

        // 마커를 생성합니다
        const marker = new kakao.maps.Marker({
          position: markerPosition,
          image: markerImage, // 마커이미지 설정
          clickable: true,
        });

        // 마커가 지도 위에 표시되도록 설정합니다
        marker.setMap(map);
        markers.current.push(marker);

        // 인포 윈도우와 오버레이커스텀 방법 2가지 있다.
        // 오버레이커스텀으로 position 설정 시 마커 위치와 같지 않으면 윈도우 창을 그려주지 않는다.
        // 인포 윈도우는 포지션을 설정할 필요 없이 고정으로 마커 위에 그려준다. (대신 마커를 안그려주면 position 설정을 고려해본다.)
        // 인포윈도우를 생성합니다
        const infowindow = new kakao.maps.InfoWindow({
          content: MeainInfo(element),
        });

        // 마커에 클릭이벤트를 등록합니다
        kakao.maps.event.addListener(marker, "mouseover", () => infowindow.open(map, marker));
        kakao.maps.event.addListener(marker, "mouseout", () => infowindow.close());
        kakao.maps.event.addListener(marker, "click", () => {
          history.push("/monitor/installerGath?installerId=" + element.userId + "&ensoType=" + element.ensoTypeCode);
        });
      });
    }
  };

  // 카카오 맵의 마커를 제어 (삭제)
  const removeMarkers = () => {
    for (let i = 0; i < markers.current.length; i++) {
      markers.current[i].setMap(null);
    }
    // 저장된 마커 전부 초기화
    if (search.fixbylawBundCode === "") {
      markers.current = [];
    }
  };

  // 클러스터 마우스 오버 이벤트
  const setClusterEvt = (cluster, map) => {
    let clusterInfowindow = "";
    // 클러스터 마우스 오버 이벤트
    kakao.maps.event.addListener(cluster, "clusterover", function (e) {
      // 마커 정보(여러 마커 중 첫번쨰)
      const markerInfo = e.getMarkers()[0];
      // 인포윈도우를 생성하고 지도에 표시합니다
      clusterInfowindow = new kakao.maps.InfoWindow({
        map: map, // 인포윈도우가 표시될 지도
        position: e.getCenter(),
        content: ClusterInfo(meainList.current, markerInfo),
      });
    });

    // 클러스터 마커 마우스 아웃 이벤트
    kakao.maps.event.addListener(cluster, "clusterout", function (e) {
      if (clusterInfowindow) clusterInfowindow.close(); // 인포윈도우 삭제
    });

    // 클러스터 클릭 이벤트
    kakao.maps.event.addListener(cluster, "clusterclick", function (e) {
      if (clusterInfowindow) clusterInfowindow.close(); // 인포윈도우 삭제
      // 마커 정보(여러 마커 중 첫번쨰)
      const marker = e.getMarkers()[0];
      // 값 추출
      const areaData = JSON.parse(marker.Gb);
      setSearch((search) => {
        const item = { ...search };
        // item.rgnCode = areaData.rgnCode
        item.rgnTermSimple = areaData.rgnTermSimple;
        item.fixbylawBundCode = areaData.dongCode;
        item.dongTermSimple = areaData.dongTermSimple;
        item.rgn = { rgnCode: areaData.rgnCode, rgnTermSimple: areaData.rgnTermSimple };
        item.dong = { fixbylawBundCode: areaData.dongCode, fixbylawBundTermSimple: areaData.dongTermSimple };
        item.lati = areaData.lati;
        item.longi = areaData.longi;
        return item;
      });

      // 맵 이동
      setMapPosition(areaData.lati, areaData.longi, map);
      map.setLevel(5);
      // 시군구 / 읍면동 값 바로 설정 하기
      search.fixbylawBundCode = areaData.dongCode;
      search.dongTermSimple = areaData.dongTermSimple;
      search.rgnCode = areaData.rgnCode;

      // 데이터 설정
      // 마커 삭제
      removeMarkers();
      // 왼쪽 상단 네비게이터
      setNaviGator();
      // 수용가목록
      getInstallArea(map, cluster);
      // 설비 상태
      getInstallMeainStat();
    });
  };
  // 서버에서 rgnList 를 못가져올 경우 랜더링을 제외하고 rgnList에 리스트가 있을경우 랜더링 진행
  return (
    <>
      <div className={loading ? "locationDepth" : ""}>
        <div className="uk-grid">
          <div className="uk-width-medium-1-1">
            <div className="md-card">
              <div className="md-card-content">
                <div className="md-card-toolbar">
                  <h3 className="md-card-toolbar-heading-text">지도</h3>
                  <div className="md-card-toolbar-actions">
                    <b className="textBl">{nav}</b>
                  </div>
                </div>
                {rgnList.length !== 0 && (
                  <Map rgnList={rgnList} rgnCode={rgnCode} setMaps={setMaps} meainList={meainList.current} setMapMarkUp={setMapMarkUp} />
                )}
                <div className={styles.menuWrap}>
                  <div className="chmapTBox">
                    <div className="chmapTT">충남 설치지역검색</div>
                    {rgnList.length !== 0 && (
                      <LocationSearch
                        codeList={codeList}
                        rgnList={rgnList}
                        dongList={dongList}
                        search={search}
                        setSearchInst={setSearchInst}
                        setSearch={setSearch}
                        map={map}
                        cluster={cluster}
                      />
                    )}
                    {rgnList.length !== 0 && <InstallerList installer={instList} map={map} setMapPosition={setMapPosition} />}
                  </div>
                  {rgnList.length !== 0 && <MeainCount statCnt={statCnt}></MeainCount>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && (
        <p className="locationDepthLoading">
          <BeatLoader loading={true} size={24} color="#1e88e5" />
        </p>
      )}
    </>
  );
};