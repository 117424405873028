import React, { useEffect, useState } from "react";
import styles from "./areaEnergy.module.css";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { DropDownList as DropDown } from "@progress/kendo-react-dropdowns";
import CountUp from "react-countup";
// 로딩 스피너
import { BeatLoader } from "react-spinners";
import { TurnedIn } from "@material-ui/icons";

const AreaEnergyPrst = ({
  areaGridLoading,
  setCityClickEvt,
  cityTerm,
  // state,
  // remsState,
  // totalState,     
  stateType,
  selectStateType, 
  setRgnClickEvt,
  setColumnNumberFormat,
  setColumnSum,
  setOperClickEvt,
  setOperCntClickEvt,
  getEnsoTerm,
}) => {  
  const dropDownListItem = ["충남REMS", "공단REMS", "합계"]; 
  const [selectedListItem, setSelectedListItem] = useState("충남REMS"); 
  // const [stateType, setStateType] = useState({    
  //   rgnCode: "",
  //   rgnTerm: "",
  //   dongCode: "",
  //   dongTerm: "",
  //   areaEnergyList: [],
  //   totalEnergy: {
  //     dayPower: 0,
  //     dayPowerCo2: 0,
  //     powerCapa: 0,
  //     accumPower: 0,
  //     dayUse: 0,
  //     dayUseCo2: 0,
  //     heatCapa: 0,
  //     heathCapa: 0,
  //     accumUse: 0,
  //   },
  //   meatinStatCnt: [],
  // });

  const handleListChange = (e) => {
    setSelectedListItem(e.target.value);
    selectStateType(e);    
  }  

  // useEffect(() => {
  //   setStateType(item => {
  //     item = {...totalState};
  //     selectStateType(item);     
  //     return item;
  //   });           

  //   // setSelectedListItem("합계");       
  // }, [totalState]);

  // useEffect(() => {
  //   setStateType(item => {
  //     item = {...state};
  //     selectStateType(item);     
  //     return item;
  //   });               
  // }, [state]);

  return (
    <>             
      <div className={areaGridLoading ? styles.areaTable : ""}>        
        <div className={styles.TitleHistory}>           
          {selectedListItem === "충남REMS" ? (
            <>
              <a href="#" onClick={setCityClickEvt} className={styles.rgnTerm}>
                {cityTerm}
              </a>
              {stateType.rgnTerm == "" ? (
                ""
              ) : (
                <span id="dongTerm" className={styles.dongTerm}>
                  &gt; {stateType.rgnTerm}
                </span>
              )}
            </>            
          ) : (
            <label className={styles.rems}>
              {cityTerm}
            </label>
          )}  
          {/* <DropDown 
            className={styles.dropDownList}
            data={dropDownListItem}
            value={selectedListItem}
            onChange={handleListChange}
          />                    */}
        </div>
        <div className="uk-grid">
          <div className="uk-width-medium-1-1">
            <div className="Table-scr">
              <Grid filterable={false} sortable={false} pageable={false} data={stateType.areaEnergyList} className="areaEnergyGrid" onRowClick={selectedListItem === "충남REMS" && setRgnClickEvt}>
                <Column className={styles.areaTerm} field="areaTerm" title="지 역" footerCell={() => <td>총계</td>} />
                <Column title="태 양 광">
                  <Column field="solaCnt" title="개소" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                  <Column field="solaCapa" title="설비용량(kW)" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                  <Column field="solaDayPower" title="발전량(kWh)" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                </Column>
                <Column title="태 양 열">
                  <Column field="heatCnt" title="개소" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                  <Column field="heatCapa" title="설비용량(km²)" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                  <Column field="heatDayUse" title="사용량(kWh)" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                </Column>
                <Column title="지 열">
                  <Column field="heathCnt" title="개소" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                  <Column field="heathCapa" title="설비용량(kW)" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                  <Column field="heathDayUse" title="사용량(kWh)" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                </Column>
                <Column title="풍 력">
                  <Column field="veloCnt" title="개소" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                  <Column field="veloCapa" title="설비용량(kW)" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                  <Column field="veloDayPower" title="발전량(kWh)" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                </Column>
                <Column title="연료전지">
                  <Column field="fuelcellCnt" title="개소" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                  <Column field="fuelcellCapa" title="설비용량(kW)" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                  <Column field="fuelcellDayPower" title="발전량(kWh)" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                </Column>
                <Column title="ESS">
                  <Column field="essCnt" title="개소" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                  <Column field="essCapa" title="설비용량(kW)" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                  <Column field="essCharge" title="충전량(kWh)" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                  <Column field="essDecahrge" title="방전량(kWh)" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                </Column>
              </Grid>
            </div>
          </div>
        </div>
        <div className="uk-grid">
          <div className="uk-width-medium-1-6">
            <div className="md-card">
              <div className="md-card-content">
                <div className="md-card-toolbar">
                  <h3 className="md-card-toolbar-heading-text">
                    <span className={styles.rgnTerm}>{stateType.rgnTerm == "" ? cityTerm : stateType.rgnTerm}</span> 전기에너지
                  </h3>
                </div>
                <table className="Ch-TableB">
                  <tbody>
                    <tr>
                      <th>금일발전량</th>
                      <td className={styles.totPowerNum}>
                        <CountUp end={stateType.totalEnergy.dayPower} separator="," duration={1.5} />
                      </td>
                      <td>kWh</td>
                    </tr>
                    <tr>
                      <th>
                        금일CO<sub>2</sub> 저감량
                      </th>
                      <td className={styles.totPowerNum}>
                        <CountUp end={stateType.totalEnergy.dayPowerCo2} decimals={2} separator="," duration={1.5} />
                      </td>
                      <td>
                        tCO<sub>2</sub>
                      </td>
                    </tr>
                    <tr>
                      <th>설비용량</th>
                      <td className={styles.totPowerNum}>
                        <CountUp end={stateType.totalEnergy.powerCapa} separator="," duration={1.5} />
                      </td>
                      <td>kW</td>
                    </tr>
                    <tr>
                      <th>누적발전량</th>
                      <td className={styles.totPowerNum}>
                        <CountUp end={stateType.totalEnergy.accumPower / 1000} separator="," duration={1.5} />
                      </td>
                      <td>MWh</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="uk-width-medium-1-6">
            <div className="md-card">
              <div className="md-card-content">
                <div className="md-card-toolbar">
                  <h3 className="md-card-toolbar-heading-text">
                    <span className={styles.rgnTerm}>{stateType.rgnTerm == "" ? cityTerm : stateType.rgnTerm}</span> 열에너지
                  </h3>
                </div>
                <table className="Ch-TableC">
                  <tbody>
                    <tr>
                      <th>금일사용량</th>
                      <td className="textcoB">
                        <CountUp end={stateType.totalEnergy.dayUse} separator="," duration={1.5} />
                      </td>
                      <td>kWh</td>
                    </tr>
                    <tr>
                      <th>
                        금일CO<sub>2</sub> 저감량
                      </th>
                      <td className="textcoB">
                        <CountUp end={stateType.totalEnergy.dayUseCo2} decimals={3} separator="," duration={1.5} />
                      </td>
                      <td>
                        tCO<sub>2</sub>
                      </td>
                    </tr>
                    <tr>
                      <th>설비용량 태양열</th>
                      <td className="textcoB">
                        <CountUp end={stateType.totalEnergy.heatCapa} separator="," duration={1.5} />
                      </td>
                      <td>
                        km²<sup></sup>
                      </td>
                    </tr>
                    <tr>
                      <th>설비용량 지열</th>
                      <td className="textcoB">
                        <CountUp end={stateType.totalEnergy.heathCapa} separator="," duration={1.5} />
                      </td>
                      <td>kW</td>
                    </tr>
                    <tr>
                      <th>누적사용량</th>
                      <td className="textcoB">
                        <CountUp end={stateType.totalEnergy.accumUse / 1000} separator="," duration={1.5} />
                      </td>
                      <td>MWh</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="uk-width-medium-2-3">
            <div className="md-card">
              <div className="md-card-content">
                <div className="md-card-toolbar">
                  <h3 className="md-card-toolbar-heading-text">
                    {selectedListItem === "충남REMS" ? (
                      <>
                        {" "}
                        <a onClick={setOperClickEvt}>
                          {" "}
                          <span className={styles.rgnTerm}>{stateType.rgnTerm == "" ? cityTerm : stateType.rgnTerm}</span> 운영현황{" "}
                        </a>{" "}
                      </>
                    ) : (                                              
                      <>
                        <span className={styles.rems}>{cityTerm}</span>
                        <span className={styles.rems}> 운영현황</span>
                      </>                      
                    )}                    
                  </h3>
                </div>                
                <div className={styles.meainStatCntBox} 
                // onClick={selectedListItem === "충남REMS" && setOperCntClickEvt}
                >
                  {stateType.meatinStatCnt.map((ensoCnt, index) => {
                    const totalCnt = ensoCnt.normalCnt + ensoCnt.unOperCnt + ensoCnt.readyCnt + ensoCnt.waringCnt + ensoCnt.errorCnt;
                    return selectedListItem === "충남REMS" ? 
                    (                      
                      <table className={`${styles.meainStatCnt} cnt-table-${ensoCnt.ensoTypeCode}`} key={index} onClick={setOperCntClickEvt}>
                        <thead>
                          <tr className="textcoB">
                            <th
                              style={
                                ensoCnt.ensoTypeCode === 15001
                                  ? { background: "#d9ecf1", color: "#A17145" }
                                  : ensoCnt.ensoTypeCode === 15002
                                  ? { background: "#f2e8dc", color: "#4B8EA6" }
                                  : ensoCnt.ensoTypeCode === 15003
                                  ? { background: "#f2e8dc", color: "#4B8EA6" }
                                  : ensoCnt.ensoTypeCode === 15004
                                  ? { background: "#d9ecf1", color: "#A17145" }
                                  : ensoCnt.ensoTypeCode === 15005
                                  ? { background: "#f2e8dc", color: "#4B8EA6" }
                                  : ensoCnt.ensoTypeCode === 15006
                                  ? { background: "#d9ecf1", color: "#A17145" }
                                  : ensoCnt.ensoTypeCode === 15007
                                  ? { background: "#e3ecd5", color: "#9C5AA1" }
                                  : null
                              }
                              colSpan="2"
                              className={`${ensoCnt.ensoTypeCode} ${styles.meainStatCntEnso}`}
                            >
                              {getEnsoTerm(ensoCnt.ensoTypeCode)}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th className={`${ensoCnt.ensoTypeCode} ${7002} ${styles.meainStatCntType}`}>정&nbsp;&nbsp;&nbsp;&nbsp;상</th>
                            <td className={`${ensoCnt.ensoTypeCode} ${7002} ${styles.colorG} ${styles.meainStatCntVal}`}>
                              <CountUp className={`${ensoCnt.ensoTypeCode} ${7002}`} end={ensoCnt.normalCnt} separator="," duration={1.5} />
                              <b className={`${ensoCnt.ensoTypeCode} ${7002} ${styles.meainStatCntTerm}`}>개소</b>
                            </td>
                          </tr>
                          <tr>
                            <th className={`${ensoCnt.ensoTypeCode} ${7001} ${styles.meainStatCntType}`}>준비중</th>
                            <td className={` ${ensoCnt.ensoTypeCode} ${7001} ${styles.colorG} ${styles.meainStatCntVal}`}>
                              <CountUp className={`${ensoCnt.ensoTypeCode} ${7001}`} end={ensoCnt.readyCnt} separator="," duration={1.5} />
                              <b className={`${ensoCnt.ensoTypeCode} ${7001} ${styles.meainStatCntTerm}`}>개소</b>
                            </td>
                          </tr>
                          <tr>
                            <th className={`${ensoCnt.ensoTypeCode} ${7006} ${styles.meainStatCntType}`}>경&nbsp;&nbsp;&nbsp;&nbsp;고</th>
                            <td className={`${ensoCnt.ensoTypeCode} ${7006} ${styles.colorG} ${styles.meainStatCntVal}`}>
                              <CountUp className={`${ensoCnt.ensoTypeCode} ${7006}`} end={ensoCnt.waringCnt} separator="," duration={1.5} />
                              <b className={`${ensoCnt.ensoTypeCode} ${7006} ${styles.meainStatCntTerm}`}>개소</b>
                            </td>
                          </tr>
                          <tr>
                            <th className={`${ensoCnt.ensoTypeCode} ${7004} ${styles.meainStatCntType}`}>미작동</th>
                            <td className={`${ensoCnt.ensoTypeCode} ${7004} ${styles.colorG} ${styles.meainStatCntVal}`}>
                              <CountUp className={`${ensoCnt.ensoTypeCode} ${7004}`} end={ensoCnt.unOperCnt} separator="," duration={1.5} />
                              <b className={`${ensoCnt.ensoTypeCode} ${7004} ${styles.meainStatCntTerm}`}>개소</b>
                            </td>
                          </tr>
                          <tr>
                            <th className={` ${ensoCnt.ensoTypeCode} ${7007} ${styles.meainStatCntType}`}>고&nbsp;&nbsp;&nbsp;&nbsp;장</th>
                            <td className={`${ensoCnt.ensoTypeCode} ${7007} ${styles.colorG} ${styles.meainStatCntVal}`}>
                              <CountUp className={`${ensoCnt.ensoTypeCode} ${7007}`} end={ensoCnt.errorCnt} separator="," duration={1.5} />
                              <b className={`${ensoCnt.ensoTypeCode} ${7007} ${styles.meainStatCntTerm}`}>개소</b>
                            </td>
                          </tr>
                          <tr>
                            <th className={`${ensoCnt.ensoTypeCode} ${7000} ${styles.meainStatCntType}`}>총개소</th>
                            <td className={`${ensoCnt.ensoTypeCode} ${7000} ${styles.colorG} ${styles.meainStatCntVal}`}>
                              <CountUp className={`${ensoCnt.ensoTypeCode} ${7000}`} end={totalCnt} separator="," duration={1.5} />
                              <b className={`${ensoCnt.ensoTypeCode} ${7000} ${styles.meainStatCntTerm}`}>개소</b>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    ) : (
                      <table className={`${styles.meainStatCnt} cnt-table-${ensoCnt.ensoTypeCode}`} key={index}>
                        <thead>
                          <tr className="textcoB">
                            <th
                              style={
                                ensoCnt.ensoTypeCode === 15001
                                  ? { background: "#d9ecf1", color: "#A17145" }
                                  : ensoCnt.ensoTypeCode === 15002
                                  ? { background: "#f2e8dc", color: "#4B8EA6" }
                                  : ensoCnt.ensoTypeCode === 15003
                                  ? { background: "#f2e8dc", color: "#4B8EA6" }
                                  : ensoCnt.ensoTypeCode === 15004
                                  ? { background: "#d9ecf1", color: "#A17145" }
                                  : ensoCnt.ensoTypeCode === 15005
                                  ? { background: "#f2e8dc", color: "#4B8EA6" }
                                  : ensoCnt.ensoTypeCode === 15006
                                  ? { background: "#d9ecf1", color: "#A17145" }
                                  : ensoCnt.ensoTypeCode === 15007
                                  ? { background: "#e3ecd5", color: "#9C5AA1" }
                                  : null
                              }
                              colSpan="2"
                              className={`${ensoCnt.ensoTypeCode} ${styles.meainStatCntEnso}`}
                            >
                              {getEnsoTerm(ensoCnt.ensoTypeCode)}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th className={`${ensoCnt.ensoTypeCode} ${7002} ${styles.meainStatCntType}`}>정&nbsp;&nbsp;&nbsp;&nbsp;상</th>
                            <td className={`${ensoCnt.ensoTypeCode} ${7002} ${styles.colorG} ${styles.meainStatCntVal}`}>
                              <CountUp className={`${ensoCnt.ensoTypeCode} ${7002}`} end={ensoCnt.normalCnt} separator="," duration={1.5} />
                              <b className={`${ensoCnt.ensoTypeCode} ${7002} ${styles.meainStatCntTerm}`}>개소</b>
                            </td>
                          </tr>
                          <tr>
                            <th className={`${ensoCnt.ensoTypeCode} ${7001} ${styles.meainStatCntType}`}>준비중</th>
                            <td className={` ${ensoCnt.ensoTypeCode} ${7001} ${styles.colorG} ${styles.meainStatCntVal}`}>
                              <CountUp className={`${ensoCnt.ensoTypeCode} ${7001}`} end={ensoCnt.readyCnt} separator="," duration={1.5} />
                              <b className={`${ensoCnt.ensoTypeCode} ${7001} ${styles.meainStatCntTerm}`}>개소</b>
                            </td>
                          </tr>
                          <tr>
                            <th className={`${ensoCnt.ensoTypeCode} ${7006} ${styles.meainStatCntType}`}>경&nbsp;&nbsp;&nbsp;&nbsp;고</th>
                            <td className={`${ensoCnt.ensoTypeCode} ${7006} ${styles.colorG} ${styles.meainStatCntVal}`}>
                              <CountUp className={`${ensoCnt.ensoTypeCode} ${7006}`} end={ensoCnt.waringCnt} separator="," duration={1.5} />
                              <b className={`${ensoCnt.ensoTypeCode} ${7006} ${styles.meainStatCntTerm}`}>개소</b>
                            </td>
                          </tr>
                          <tr>
                            <th className={`${ensoCnt.ensoTypeCode} ${7004} ${styles.meainStatCntType}`}>미작동</th>
                            <td className={`${ensoCnt.ensoTypeCode} ${7004} ${styles.colorG} ${styles.meainStatCntVal}`}>
                              <CountUp className={`${ensoCnt.ensoTypeCode} ${7004}`} end={ensoCnt.unOperCnt} separator="," duration={1.5} />
                              <b className={`${ensoCnt.ensoTypeCode} ${7004} ${styles.meainStatCntTerm}`}>개소</b>
                            </td>
                          </tr>
                          <tr>
                            <th className={` ${ensoCnt.ensoTypeCode} ${7007} ${styles.meainStatCntType}`}>고&nbsp;&nbsp;&nbsp;&nbsp;장</th>
                            <td className={`${ensoCnt.ensoTypeCode} ${7007} ${styles.colorG} ${styles.meainStatCntVal}`}>
                              <CountUp className={`${ensoCnt.ensoTypeCode} ${7007}`} end={ensoCnt.errorCnt} separator="," duration={1.5} />
                              <b className={`${ensoCnt.ensoTypeCode} ${7007} ${styles.meainStatCntTerm}`}>개소</b>
                            </td>
                          </tr>
                          <tr>
                            <th className={`${ensoCnt.ensoTypeCode} ${7000} ${styles.meainStatCntType}`}>총개소</th>
                            <td className={`${ensoCnt.ensoTypeCode} ${7000} ${styles.colorG} ${styles.meainStatCntVal}`}>
                              <CountUp className={`${ensoCnt.ensoTypeCode} ${7000}`} end={totalCnt} separator="," duration={1.5} />
                              <b className={`${ensoCnt.ensoTypeCode} ${7000} ${styles.meainStatCntTerm}`}>개소</b>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        {areaGridLoading && (
          <p className={styles.areaTableLoading}>
            <BeatLoader loading={true} size={24} color="#1e88e5" />
          </p>
        )}
      </div>
    </>
  );
};

export default AreaEnergyPrst;
