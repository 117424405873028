import React, { useState, useEffect } from "react";
import { BeatLoader } from "react-spinners";
import { useHistory } from "react-router-dom";

import kendo from "@progress/kendo-ui";

import "./monitorAS.css";
import styles from "./monitorAS.module.css";

import { getMessage } from "../../../com/messageUtil";
import {
  getMonitorASDataList,
  openMeainDetail,
  setAddlAgreements,
  getMeainMngtLogListData,
  setAddlHistory,
  delAddlHistory,
} from "../../../service/meain/monitorAS/monitorASService";
import MonitorASSearch from "./monitorASSearch";
import MonitorASGrid from "./monitorASGrid";
import MonitorASModal from "./monitorASModal";
import MeainInfoModal from "./meainInfoModal";
import { getUser, isManager } from "../../../service/authService";

const MonitorAS = ({ conn, rgnList, codeList, dongList }) => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [monitorASModalLoading, setMonitorASModalLoading] = useState(false);

  const [search, setSearch] = useState({
    installerTerm: "",
    ensoType: { codeNo: "", codeVal: "에너지원" },
    bizSct: { codeNo: "", codeVal: "사업구분" },
    bizYy: "사업연도",
    rgn: { rgnCode: isManager() ? getUser().rgnCode : "", rgnTermSimple: "지역" },
  });

  const [paging, setPage] = useState({
    skip: 0,
    take: 10,
    count: 0,
    list: [],
  });

  // 추가 팝업창 제어
  const [modalItem, setModalItem] = useState({
    title: "",
    item: [],
    visible: false,
  });

  const [meainInfoVisible, setMeainInfoVisible] = useState(false);

  const [meainMngtLogItem, setMeainMngtLogItem] = useState([]);

  // 조회 로직
  const getMonitorASData = (search, paging) => {
    setLoading(true);
    getMonitorASDataList(conn, getDataSource, getRejection, search, paging, setLoading);
  };

  // 설비 상세 조회
  const openMeainDetailInfo = () => {
    setMeainInfoVisible(!meainInfoVisible);
    openMeainDetail(history, modalItem.item);
  };

  // 추가 계약 여부 확인
  const confirmAddlAgreements = () => {
    kendo
      .confirm(modalItem.item.editInfo.charAt(modalItem.item.editInfo.length - 1) === "Y" ? "추가계약을 취소하시겠습니까?" : "추가계약 하시겠습니까?")
      .then(() => {
        setLoading(true);
        setAddlAgreements(conn, modalItem.item)
          .then(() => {
            setLoading(false);
            kendo.alert(modalItem.item.editInfo.charAt(modalItem.item.editInfo.length - 1) === "Y" ? "추가계약이 취소되었습니다" : "추가계약이 완료되었습니다");
            let tempItem = modalItem.item;
            tempItem.editInfo =
              modalItem.item.editInfo.substr(0, modalItem.item.editInfo.length - 2) + modalItem.item.editInfo.charAt(modalItem.item.editInfo.length - 1) === "Y"
                ? "N"
                : "Y";
            setModalItem({
              ...modalItem,
              item: tempItem,
            });
            getMeainMngtLogList(modalItem.item);
            getMonitorASData(search, paging);
          })
          .catch((error) => {
            setLoading(false);
            kendo.alert(getMessage(error.errCode));
          });
      });
  };

  // 추가기록 조회 함수
  const getMeainMngtLogList = (param) => {
    setMonitorASModalLoading(true);
    getMeainMngtLogListData(conn, getMeainMngtLogData, getRejection, param, setLoading)
      .then(() => {
        setMonitorASModalLoading(false);
      })
      .catch((error) => {
        kendo.alert(getMessage(error.errCode));
        setMonitorASModalLoading(false);
      });
  };

  // 데이터 소스 설정 CALLBACK
  const getDataSource = (result) => {
    setPage((paging) => {
      const item = { ...paging };
      item.list = result.results;
      item.count = result.total;
      return item;
    });
  };

  // 관리이력 팝업창에서 관리이력 목록 설정
  const getMeainMngtLogData = (result) => {
    setMeainMngtLogItem(result);
  };

  // 데이터 소스 설정 rejection 함수 (현재 불필요)
  const getRejection = (error) => {};

  // 관리이력 팝업창에서 추가기록을 등록하는 함수
  const monitorAsModalSubmit = (data) => {
    let tempItem = modalItem.item;
    tempItem.addlHistory = data;
    setModalItem({
      ...modalItem,
      item: tempItem,
    });
    setMonitorASModalLoading(true);
    setAddlHistory(conn, modalItem.item)
      .then(() => {
        getMeainMngtLogList(modalItem.item);
        getMonitorASData(search, paging);
        kendo.alert("등록이 완료되었습니다");
      })
      .catch((error) => {
        kendo.alert(getMessage(error.errCode));
        setMonitorASModalLoading(false);
      });
  };

  // 관리이력 팝업창에서 삭제 버튼을 누르면 실행되는 함수
  const deleteAddlHistoryComment = (id) => {
    kendo.confirm("해당 기록을 삭제하시겠습니까?").then(() => {
      delAddlHistory(conn, id)
        .then(() => {
          getMeainMngtLogList(modalItem.item);
          getMonitorASData(search, paging);
          kendo.alert("삭제가 완료되었습니다");
        })
        .catch((error) => {
          kendo.alert(getMessage(error.errCode));
          setMonitorASModalLoading(false);
        });
    });
  };

  useEffect(() => {
    // 처음 데이터 불러오는 부분 제어 로직
    getMonitorASData(search, paging);
  }, []);

  return (
    <div className={loading ? "loadingState" : null}>
      <MonitorASSearch
        rgnList={rgnList}
        codeList={codeList}
        search={search}
        setSearch={setSearch}
        paging={paging}
        styles={styles}
        getMonitorASData={getMonitorASData}
      />
      <MonitorASGrid
        search={search}
        setSearch={setSearch}
        paging={paging}
        getMonitorASData={getMonitorASData}
        modalItem={modalItem}
        setModalItem={setModalItem}
      />
      {modalItem.visible && (
        <MonitorASModal
          conn={conn}
          title={modalItem.title}
          item={modalItem.item}
          onSubmit={(data) => monitorAsModalSubmit(data)}
          cancelEdit={() => {
            setModalItem({
              title: "",
              item: [],
              visible: !modalItem.visible,
            });
          }}
          openMeainDetailInfo={openMeainDetailInfo}
          confirmAddlAgreements={confirmAddlAgreements}
          getMeainMngtLogList={getMeainMngtLogList}
          meainMngtLogItem={meainMngtLogItem}
          styles={styles}
          monitorASModalLoading={monitorASModalLoading}
          deleteAddlHistoryComment={deleteAddlHistoryComment}
        />
      )}
      {meainInfoVisible && (
        <MeainInfoModal
          title={modalItem.title}
          cancelEdit={() => {
            setMeainInfoVisible(!meainInfoVisible);
          }}
          conn={conn}
          codeList={codeList}
          rgnList={rgnList}
          dongList={dongList}
        />
      )}
      {loading && (
        <p className={styles.monitorASLoading}>
          <BeatLoader loading={true} size={24} color="#1e88e5" />
        </p>
      )}
    </div>
  );
};

export default MonitorAS;
