import React from "react";

const GoldMedal = () => {
  return (
    <>
      <img className="cmpAreaMedal" src="/images/gold_medal.png"></img>
    </>
  );
};

export default GoldMedal;
