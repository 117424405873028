// React
import React from 'react';

// HighCharts
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import HighchartsSolidGauge from "highcharts/modules/solid-gauge";

// set HighCharts
HighchartsMore(Highcharts);
HighchartsSolidGauge(Highcharts);

export const SysGause = ({dataSource}) => {

  const options = {
    chart: {
      type: 'solidgauge'
      , height: '77.5%'
    }
    , title: null
    , tooltip: { enabled: false }
    , credits: { enabled: false }
    , pane: {
      center: ['50%', '70%']
      , size: '100%'
      , startAngle: -90
      , endAngle: 90
      , background: {
          backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || '#EEE'
          , innerRadius: '60%'
          , outerRadius: '100%'
          , shape: 'arc'
      }
    }
    , yAxis: {
      min: 0
      , max: 100
      , stops: [
        [0.1, '#55BF3B'] // green
        , [0.5, '#DDDF0D'] // yellow
        , [0.9, '#DF5353'] // red
      ]
      , lineWidth: 0
      , tickWidth: 0
      , minorTickInterval: null
      , tickAmount: 2
      , labels: { y: 16 }
    }
    , plotOptions: {
        solidgauge: {
          dataLabels: {
            borderWidth: 0
          }
        }
    }
    , series: [{
      data: dataSource
      , dataLabels: {
          format:
              '<div style="text-align:center">' +
                '<span style="font-size:25px">{y} %</span><br/>' +
              '</div>'
      }
    }]
  };

  return (
    <HighchartsReact
      Highcharts={Highcharts}
      options={options}
    />
  );
}