const makrComUrl = "makr";

/**
 * ### 설비 제조사 관리 - 조회
 */
export const getMakr = async (conn) => {
  const result = await conn.get(makrComUrl + "/list");
  return result;
};

/**
 * ### 설비 제조사 관리 - 추가, 수정
 */
export const setUpdateMakr = async (conn, data) => {
  // 제조사 분류 codeVal => codeNo로 변경하는 코드
  data.makrClsfNo = data.clsfNoList.filter((it) => it.codeVal === data.makrClsfNo).map((item) => item.codeNo)[0];

  const config = {
    headers: { "Content-Type": "application/json" },
  };
  const result = await conn.post(makrComUrl, JSON.stringify(new Array(data)), config);

  return result;
};

export const setDeleteMakr = async (conn, data) => {
  const config = {
    headers: { "Content-Type": "application/json" },
    data: JSON.stringify(data.makrSeriNo),
  };
  const result = await conn.delete(makrComUrl, config);

  return result;
};
