import { useState, useEffect } from "react";
import { Input } from "@progress/kendo-react-inputs";
import kendo from "@progress/kendo-ui";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";

import ExpctBuildBsmanGrid from "./expctBuildBsmanGrid";

import styles from "./expctBuildBsman.module.css";
import "./expctBuildBsman.css";
import { getUser, getUserMngRgnList, isManager } from "../../../../service/authService";
import { getExpctBuildBsmanList } from "../../../../service/stats/expt/expctBuildBsmanService";
import RgnDropdown from "./../../../com/dropdown/rgnDropdown";
import { isOneManager } from "./../../../../service/authService";

function ExpctBuildBsman({ conn, rgnList, dongList }) {
  // 유저정보
  const userInfo = getUser();
  // 검색 조건
  const [search, setSearch] = useState({
    rgnCode: isOneManager() === true ? userInfo.userMngRgnList[0] : "",
  });

  const [dataSource, setDataSource] = useState({
    grid: [],
    chart: [],
    excelData: null,
  });

  // 조회 버튼
  const onChangeEvt = (e) => {
    setSearch((search) => {
      const item = { ...search };
      item.rgnCode = e.target.value.rgnCode;
      return item;
    });
  };

  const getExpctBuildBsmanData = () => {
    getExpctBuildBsmanList(conn, getResult, getRejection, search);
  };

  // 결과 데이터
  const getResult = (gridList, chartData) => {
    setDataSource((dataSource) => {
      const item = { ...dataSource };
      item.grid = gridList;
      item.chart = chartData;
      return item;
    });
  };

  // rejection
  const getRejection = (name, error) => {
    if (error.errCode === "F011") {
      kendo.alert("");
      return;
    }
  };

  // useEffect(() => {
  //   if(isManager() === true){
  //     if(userInfo.userMngRgnList.length < 2){
  //       getExpctBuildBsmanData(search)
  //     }
  //   }
  // }, []);
  useEffect(() => {
    getExpctBuildBsmanData(search);
  }, [search.rgnCode]);

  return (
    <>
      <div className="consmDiv">
        <div className="consmDivIn">
          <div className="uk-gridExpctBuildBsmanSeach">
            <div className="uk-width-medium-1-2">
              <b>지역검색 &nbsp; &nbsp;</b>
              <RgnDropdown name="rgn" rgnList={rgnList} search={search} objKey={"rgn"} setSearch={setSearch} onChange={onChangeEvt} />
              {/* <DropDownList
                className={styles.LocationDropDownRgn}
                data={rgnList}
                textField="rgnTermSimple"
                dataItemKey="rgnCode"
                name="rgn"
                // value = {search.rgnCode ? search.rgnCode : ""}
                defaultItem={{ rgnCode: "", rgnTermSimple: "전체" }}
                onChange={onChangeEvt}
              /> */}
            </div>
          </div>
          <ExpctBuildBsmanGrid
            dataSourceGrid={dataSource.grid}
            dataSourceChart={dataSource.chart}
            dataSourceExcel={dataSource.excelData}
            setDataSource={setDataSource}
            userInfo={userInfo}
          />
        </div>
      </div>
    </>
  );
}

export default ExpctBuildBsman;
