//react
import { useState, useEffect } from "react";
import useHistoryState from "../../../com/useHistoryState";
//css
import "./edsmCmp.css";
// Kendo
import kendo from "@progress/kendo-ui";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input, RadioButton } from "@progress/kendo-react-inputs";
import DatePickerKo from "../../com/dateInputs/datePickerKo";
//component
import { getUser, isInstaller } from "../../../service/authService";
import UserInfoWindow from "./../../stats/type/userByUser/userInfoWindow";
import CmpUserByUserMeainList from "./../../stats/type/userByUser/cmpUserByUserMeainList";
import { getCidListByUserId, getRawKepcoHour } from "../../../service/analy/rawKepcoService";
import { getMessage } from "./../../../com/messageUtil";
import useDidMountEffect from "../../../com/useDidMountEffect";
import EdsmCmpChart from "./edsmCmpChart";
// 로딩 스피너
import { BeatLoader } from "react-spinners";
import { numFormat } from "./../../../com/dataUtil";
const EdsmCmp = (props) => {
  const { rgnList, dongList, conn, codeList } = props;
  const [loading, setLoading] = useState(false);
  //그리드 데이터소스
  const [gridData, setGridData] = useState([]);
  //차트 데이터소스
  const [chartData, setChartData] = useState({ pwrQtyList: [], dayPowerList: [] });
  //수용가 선택 후 설비 리스트(cid)
  const [meainList, setMeainList] = useState([]);

  // 날짜 검색 데이터 소스
  const today = new Date();
  const yesterday = new Date();
  const lastWeek = new Date();
  const lastMonth = new Date();
  const lastYear = new Date();
  yesterday.setDate(today.getDate() - 1);
  lastWeek.setDate(yesterday.getDate() - 7);
  lastMonth.setDate(yesterday.getDate() - 30);
  lastYear.setDate(yesterday.getDate() - 365);
  const dateList = codeList.filter((c) => c.grpCodeNo === 23 && c.codeNo !== 23001);
  const [dateVal, setDateVal] = useState();

  const [search, setSearch] = useState({
    skip: 0,
    take: 10,
    userId: isInstaller() ? getUser().userId : "",
    userTerm: isInstaller() ? getUser().userTerm : "",
    cid: "",
    dateType: "HH",
    startDate: yesterday,
    endDate: yesterday,
    userInfoVisible: false,
    callApi: false, // 토글 될때 api요청
  });
  const pageChngEvt = (e) => {
    const { take, skip } = e.page;
    setSearch({ ...search, skip: skip, take: take });
  };
  const startDateChngEvt = (item) => {
    setSearch({ ...search, startDate: item });
  };
  const endDateChngEvt = (item) => {
    setSearch({ ...search, endDate: item });
  };
  const radioClickEvt = (e) => {
    const radioName = e.target.name;
    setSearch({ ...search, dateType: radioName });
  };
  const dateDdlChngEvt = (e) => {
    setDateVal(e.target.value);
    if (e.target.value.codeNo === 23002) {
      setSearch({ ...search, startDate: yesterday, endDate: yesterday });
    } else if (e.target.value.codeNo === 23003) {
      setSearch({ ...search, startDate: lastWeek, endDate: yesterday });
    } else if (e.target.value.codeNo === 23004) {
      setSearch({ ...search, startDate: lastMonth, endDate: yesterday });
    } else if (e.target.value.codeNo === 23005) {
      setSearch({ ...search, startDate: lastYear, endDate: yesterday });
    } else if (e.target.value.codeNo === 23006) {
      setSearch({ ...search, startDate: yesterday, endDate: yesterday });
    }
  };

  // 유저 ID 검색
  const searchUserId = (e) => {
    setSearch({ ...search, userInfoVisible: true });
  };
  useEffect(() => {
    if (isInstaller() || search.userId) {
      getMeainList();
    }
  }, [search.userId]);

  // 발전량 셀
  const dayPowerCell = (e) => {
    const { dataItem } = e;
    const result = numFormat(dataItem.dayPower);

    return <td>{result}</td>;
  };
  //전력 소비량 셀
  const pwrQtyCell = (e) => {
    const { dataItem } = e;

    const result = numFormat(dataItem.pwrQty);

    return <td>{result}</td>;
  };

  //step 1 검색 버튼 클릭
  const srchBtnClkEvt = () => {
    if (!search.userTerm) {
      kendo.alert("수용가를 선택해 주시기 바랍니다.");
      return;
    } else {
      getMeainList();
    }
  };
  //step 2 - cid list 조회 후 cid 세팅
  const getMeainList = () => {
    getCidListByUserId(conn, search)
      .then((response) => {
        setMeainList(response);
        //데이터 있다면 ApiCall
        if (response.length) setSearch({ ...search, skip: 0, cid: "", callApi: !search.callApi });
        //데이터 없다면 초기화
        else setGridData([]);
      })
      .catch((err) => alert(getMessage(err)));
  };
  //step 3 토글 될때 api 요청
  useDidMountEffect(() => {
    getDataSource();
  }, [search.callApi]);
  //step 4 데이터소스 요청
  const getDataSource = () => {
    setLoading(true);
    getRawKepcoHour(conn, search, setChartData)
      .then((response) => {
        setLoading(false);
        setGridData(response);
      })
      .catch((err) => {
        if (err.errCode === "F011") {
          kendo.alert("해당 날짜의 데이터가 없습니다.");
        } else {
          kendo.alert(getMessage(err));
        }
        setGridData([]);
        setChartData({ pwrQtyList: [], dayPowerList: [] });
        setLoading(false);
      });
  };
  return (
    <div className="edCmpDiv" style={loading ? { opacity: "0.6", pointerEvents: "none" } : null}>
      <div className="edCmpSrchDiv">
        <span className="edCmpSrchLeft">
          <span>수용가 검색</span>&nbsp;&nbsp;&nbsp;
          <Input
            onClick={searchUserId}
            id="userTerm"
            type="text"
            placeholder="수용가"
            readOnly
            value={search.userTerm ? search.userTerm : ""}
            disabled={isInstaller()}
          />
          <Button onClick={searchUserId} icon="search" disabled={isInstaller()} />
          {/* &nbsp;&nbsp;&nbsp;<span>CID</span>&nbsp;&nbsp;&nbsp;
          <DropDownList></DropDownList> */}
        </span>
        <span className="edCmpSrchRight">
          <label style={{ margin: "3px", display: "inline-flex" }}>조회 기간</label>
          <span className="edCmpRadGrp">
            <DropDownList
              className="ensoDropDown yearDropDown"
              id="date"
              name="date"
              data={dateList}
              dataItemKey="codeNo"
              textField="codeVal"
              defaultValue={{ codeNo: 23006, codeVal: "직접조회" }}
              onChange={dateDdlChngEvt}
              value={dateVal}
            />
            <RadioButton name="HH" label="시간별" checked={search.dateType === "HH"} onClick={radioClickEvt}></RadioButton>
            <RadioButton name="DD" label="일별" checked={search.dateType === "DD"} onClick={radioClickEvt}></RadioButton>
            <RadioButton name="MM" label="월별" checked={search.dateType === "MM"} onClick={radioClickEvt}></RadioButton>
          </span>
          <DatePickerKo
            className="edCmpDtStrt"
            value={search.startDate}
            setDtm={startDateChngEvt}
            max={search.endDate}
            format={search.dateType === "MM" ? "yyyy-MM" : "yyyy-MM-dd"}
            view={search.dateType === "MM" ? "year" : null}
          ></DatePickerKo>
          {search.dateType !== "HH" && (
            <>
              {"~"}
              <DatePickerKo
                className="edCmpDtEnd"
                value={search.endDate}
                setDtm={endDateChngEvt}
                min={search.startDate}
                max={yesterday}
                format={search.dateType === "MM" ? "yyyy-MM" : "yyyy-MM-dd"}
                view={search.dateType === "MM" ? "year" : null}
              ></DatePickerKo>
            </>
          )}

          <Button icon="search" style={{ backgroundColor: "white", paddingLeft: "20px", paddingRight: "20px" }} onClick={srchBtnClkEvt}></Button>
        </span>
      </div>
      {!meainList.length ? (
        <div>설비 데이터가 존재하지 않습니다. 수용가를 선택해 주시기 바랍니다.</div>
      ) : (
        <>
          <div className="edCmptitleName"> ⋅ 설비목록</div>
          <CmpUserByUserMeainList meainList={meainList} search={search} setSearch={setSearch} getDataList={getDataSource} allCidView={true} />
          <div className="edCmpContent">
            <div className="edCmpChartHeader"> ⋅ 전력소비량, 발전량 그래프</div>
            <div className="edCmpChartDiv">
              <div className="edCmpChartContent">
                <EdsmCmpChart chartData={chartData}></EdsmCmpChart>
              </div>
            </div>
            <div className="edCmpGridHeader"> ⋅ 시간대별 상세정보</div>
            <div className="edCmpGridDiv">
              <div className="edCmpGridContent">
                <LocalizationProvider language="ko-KR">
                  <IntlProvider locale="ko">
                    <Grid
                      className="edCmpGrid"
                      data={gridData.slice(search.skip, search.take + search.skip)}
                      skip={search.skip}
                      take={search.take}
                      total={gridData.length}
                      onPageChange={pageChngEvt}
                      pageable={{
                        type: "numeric",
                        previousNext: true,
                        buttonCount: 5,
                      }}
                    >
                      <GridColumn field="gathDtm" title="시간"></GridColumn>
                      <GridColumn field="userTerm" title="수용가"></GridColumn>
                      <GridColumn field="custNo" title="한전 고객번호"></GridColumn>
                      <GridColumn field="dayPower" title="발전량 (단위:kWh)" cell={dayPowerCell}></GridColumn>
                      <GridColumn field="pwrQty" title="전력소비량 (단위:kWh)" cell={pwrQtyCell}></GridColumn>
                    </Grid>
                  </IntlProvider>
                </LocalizationProvider>
              </div>
            </div>
          </div>
        </>
      )}
      {search.userInfoVisible && <UserInfoWindow conn={conn} search={search} setSearch={setSearch} kendo={kendo} rgnList={rgnList} dongList={dongList} />}

      {loading && (
        <p className="loadingSpinner">
          <BeatLoader loading={true} size={24} color="#1e88e5" />
        </p>
      )}
    </div>
  );
};

export default EdsmCmp;
