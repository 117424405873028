// React
import React, { useState, useEffect, useCallback, useRef } from "react";
import log from "../../../com/log";
import { parseDate, subDate } from "../../../com/dateUtil";
import { numFormat } from "../../../com/dataUtil";
import { downloadExcel } from "../../../com/downloadExcel";
import DatePickerKo from "../../com/dateInputs/datePickerKo";

import { getEnsoEventTime, getEnsoEventContent } from "../../../service/stats/event/ensoEventService";

// Kendo
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { IntlProvider, LocalizationProvider, loadMessages } from "@progress/kendo-react-intl";
import { Button } from "@progress/kendo-react-buttons";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import kendo from "@progress/kendo-ui";

// Highcharts
import { EnsoPieCharts } from "./ensoPieCharts";
import { EnsoLineCharts } from "./ensoLineCharts";

// Css
import "./enso.css";
// import "./enso.dk.css";
import styles from "./enso.module.css";
import { Search } from "@material-ui/icons";

// 로딩 스피너
import { BeatLoader } from "react-spinners";

const Enso = ({ conn, codeList }) => {
  // 로딩 스피너
  const [loading, setLoading] = useState(false);

  // 날짜 검색 데이터 소스
  const dateList = codeList.filter((c) => c.grpCodeNo === 23);
  // 날짜검색
  // const dateCate = useMemo(() => codeList.filter((item) => item.grpCodeNo === 23), [codeList]);
  // 날짜조회
  const [dateVal, setDateVal] = useState({ codeNo: 23002, codeVal: "어제" });

  // 어제 날짜 정의
  const today = new Date();
  const yesterday = new Date();
  const lastWeek = new Date();
  const lastMonth = new Date();
  const lastYear = new Date();
  yesterday.setDate(today.getDate() - 1);
  lastWeek.setDate(yesterday.getDate() - 7);
  lastMonth.setDate(yesterday.getDate() - 30);
  lastYear.setDate(yesterday.getDate() - 365);

  // 날짜
  const [start, setStart] = useState(yesterday);
  const end = useRef(yesterday);
  const [startMax, setStartMax] = useState(yesterday);
  const [endMin, setEndMin] = useState(yesterday);

  // 데이터 소스
  const [dataSource, setDataSource] = useState({
    eventTime: [],
    eventContent: [
      { ensoType: "태양광", warn: 0, err: 0, all: 0, manaTm: 0 },
      { ensoType: "태양열", warn: 0, err: 0, all: 0, manaTm: 0 },
      { ensoType: "지열", warn: 0, err: 0, all: 0, manaTm: 0 },
      { ensoType: "풍력", warn: 0, err: 0, all: 0, manaTm: 0 },
      { ensoType: "연료전지", warn: 0, err: 0, all: 0, manaTm: 0 },
      { ensoType: "ESS", warn: 0, err: 0, all: 0, manaTm: 0 },
    ],
    dashboard: {
      total: 0,
      totalWarn: 0,
      totalErr: 0,
      totalManaTm: 0,
    },
    actHour: 0,
    actMinute: 0,
  });

  const [category, setCategory] = useState({
    endMin: new Date(),
    startMax: new Date(),
    dateState: { codeNo: 23001, codeVal: "오늘" },
    startDate: new Date(),
    endDate: new Date(),
  });

  // 조회 로직 (수정 20210423 jhchoi)
  // 시간대별 경고/고장 상세정보 (차트)
  const getEnsoEventTimeList = () => {
    setLoading(true);
    getEnsoEventTime(conn, category)
      .then((response) => {
        setDataSource((dataSource) => {
          const item = { ...dataSource };
          item.eventTime = response;
          return item;
        });
        setLoading(false);
      })
      .catch((err) => {
        log(`enso.useEffect.getEnsoEventTime : ${err}`);
      });
  };

  // 에너지원별 이벤트 (차트) & 시간대별 경고/고장 상세정보 (그리드)
  const getEnsoEventContentList = () => {
    setLoading(true);
    getEnsoEventContent(conn, category)
      .then((response) => {
        let total = 0;
        let totalWarn = 0;
        let totalErr = 0;
        let totalManaTm = 0;
        response.forEach((item) => {
          item.all = item.warn + item.err;
          total += item.all;
          totalWarn += item.warn;
          totalErr += item.err;
          totalManaTm += item.manaTm;
        });

        // 총 수동조치시간
        const actTime = totalManaTm;
        // 수동조치시간 시간
        const actHour = Math.floor(actTime / 60);
        // 수동조치시간 분
        const actMinute = Math.floor(actTime % 60);

        setDataSource((dataSource) => {
          const item = { ...dataSource };
          item.eventContent = response;
          item.dashboard.total = total;
          item.dashboard.totalWarn = totalWarn;
          item.dashboard.totalErr = totalErr;
          item.dashboard.totalManaTm = totalManaTm;
          item.actHour = actHour;
          item.actMinute = actMinute;
          return item;
        });
        setLoading(false);
      })
      .catch((err) => {
        log(`enso.useEffect.getEnsoEventContent : ${err}`);
      });
  };

  // 데이터 불러오기
  const getAllData = () => {
    getEnsoEventTimeList();
    getEnsoEventContentList();
  };

  // 시작 날짜 변경 이벤트
  const handleStartDateChange = useCallback(
    (e) => {
      const { value } = e.target;
      setCategory({ ...category, startDate: parseDate("yyyy-mm-dd", value) });
    },
    [category],
  );

  // 끝 날짜 변경 이벤트
  const handleEndDateChange = useCallback(
    (e) => {
      const { value } = e.target;
      setCategory({ ...category, endDate: parseDate("yyyy-mm-dd", value) });
    },
    [category],
  );

  // 날짜검색 변경
  const handleOnChange = useCallback(
    (e) => {
      // 날짜 검색
      if (e.target.name === "dateState") {
        if (e.target.value.codeNo === 23001) {
          setCategory((state) => {
            const item = { ...state };
            item.startDate = today;
            item.dateState = e.target.value;
            return item;
          });
        } else if (e.target.value.codeNo === 23002) {
          setCategory((state) => {
            const item = { ...state };
            item.startDate = yesterday;
            item.dateState = e.target.value;
            return item;
          });
        } else if (e.target.value.codeNo === 23003) {
          setCategory((state) => {
            const item = { ...state };
            item.startDate = lastWeek;
            item.dateState = e.target.value;
            return item;
          });
        } else if (e.target.value.codeNo === 23004) {
          setCategory((state) => {
            const item = { ...state };
            item.startDate = lastMonth;
            item.dateState = e.target.value;
            return item;
          });
        } else if (e.target.value.codeNo === 23005) {
          setCategory((state) => {
            const item = { ...state };
            item.startDate = lastYear;
            item.dateState = e.target.value;
            return item;
          });
        } else if (e.target.value.codeNo === 23006) {
          setCategory((state) => {
            const item = { ...state };
            item.startDate = today;
            item.dateState = e.target.value;
            return item;
          });
        }
      }
    },
    //   const { value } = e.target;

    //   switch (value) {
    //     case "어제":
    //       setCategory({
    //         ...category,
    //         dateList: value,
    //         startDate: subDate("D"),
    //       });
    //       break;

    //     case "1주일":
    //       setCategory({
    //         ...category,
    //         dateList: value,
    //         startDate: subDate("W"),
    //       });
    //       break;

    //     case "1개월":
    //       setCategory({
    //         ...category,
    //         dateList: value,
    //         startDate: subDate("M"),
    //       });
    //       break;

    //     case "1년":
    //       setCategory({
    //         ...category,
    //         startDate: subDate("Y"),
    //       });
    //       break;

    //     default:
    //       setCategory({
    //         dateList: value,
    //         startDate: parseDate("yyyy-mm-dd"),
    //         endDate: parseDate("yyyy-mm-dd"),
    //       });
    //   }
    // },
    // [category]
  );

  // 상단 카테고리 검색버튼 클릭 이벤트
  // const handleClick = useCallback(
  //   (e) => {
  //     e.preventDefault();
  //     getAllData();
  //   },
  //   [dataSource, category]
  // );

  const handleClick = (e) => {
    getAllData();
  };
  // 엑셀저장
  const ensoDownloadExcel = useCallback((e) => {
    setLoading(true);
    e.preventDefault();
    const url = "/api/v2.2/stat/event/enso";
    const fileName = "경보_에너지원별_" + kendo.toString(new Date(), "yyyyMMdd") + ".xlsx";
    downloadExcel(category, fileName, url, setLoading);
  }, []);

  useEffect(() => {
    getAllData();
  }, []);

  const warnCell = (data) => {
    return <td>{numFormat(data.dataItem.warn)}</td>;
  };
  const errCell = (data) => {
    return <td>{numFormat(data.dataItem.err)}</td>;
  };
  const manaTmCell = (data) => {
    return <td>{numFormat(data.dataItem.manaTm)}</td>;
  };

  // 대시보드 클릭 이벤트 (이벤트 이력 이동 (전체))
  const clickEventWholeData = (e) => {
    window.location.href = "/mngt/analy/event/log?start=" + category.startDate + "&end=" + category.endDate;
  };
  // 대시보드 클릭 이벤트 (이벤트 이력 이동 (전체))
  const clickEventWarnData = (e) => {
    window.location.href = "/mngt/analy/event/log?start=" + category.startDate + "&end=" + category.endDate + "&facilityState=7006";
  };
  // 대시보드 클릭 이벤트 (이벤트 이력 이동 (전체))
  const clickEventErrData = (e) => {
    window.location.href = "/mngt/analy/event/log?start=" + category.startDate + "&end=" + category.endDate + "&facilityState=7007";
  };

  return (
    <>
      <div
        className="ensoBody"
        style={
          loading
            ? {
                opacity: 0.6,
                pointerEvents: "none",
              }
            : null
        }
      >
        <LocalizationProvider language="ko-KR">
          <IntlProvider locale="ko">
            <div className="ensoCate">
              <span>날짜검색</span>
              <DropDownList
                className={styles.ensoCateDateDropDown}
                data={dateList}
                dataItemKey="codeNo"
                textField="codeVal"
                name="dateState"
                value={category.dateState}
                defaultValue={{ codeNo: 23001, codeVal: "오늘" }}
                onChange={handleOnChange}
              />
              <span>
                <DatePickerKo
                  id="start"
                  name="start"
                  className="datePicker"
                  defaultValue={today}
                  format={"yyyy-MM-dd"}
                  setDtm={(v) => {
                    setCategory((search) => {
                      const item = { ...search };
                      item.endMin = v;
                      item.startDate = v;
                      item.dateState = { codeNo: 23006, codeVal: "직접조회" };
                      return item;
                    });
                  }}
                  max={category.startMax}
                  value={category.startDate}
                />
                ~
                <DatePickerKo
                  id="end"
                  name="end"
                  className="datePicker"
                  defaultValue={today}
                  format={"yyyy-MM-dd"}
                  setDtm={(v) => {
                    setStartMax(v);
                    setCategory((search) => {
                      const item = { ...search };
                      item.startMax = v;
                      item.endDate = v;
                      return item;
                    });
                  }}
                  min={category.endMin}
                  max={today}
                />
                <span style={{ paddingLeft: 10 }}>
                  <Button className="searchBtn" onClick={handleClick}>
                    <Search className="material-icons" />
                  </Button>
                </span>
              </span>
            </div>

            <div className="ensoBoard">
              <div className="ensoBoardSub" onClick={clickEventWholeData}>
                <div>
                  <span>총발생 신호</span>
                  <br />
                  <span className="ensoBoardContent">
                    <b>{numFormat(dataSource.dashboard.total)}</b>
                    <span>건</span>
                  </span>
                </div>
              </div>
              <div className="ensoBoardSub" onClick={clickEventWarnData}>
                <div>
                  <span>누적 경고 신호</span>
                  <br />
                  <span className="ensoBoardContent">
                    <b>{numFormat(dataSource.dashboard.totalWarn)}</b>
                    <span>건</span>
                  </span>
                </div>
              </div>
              <div className="ensoBoardSub" onClick={clickEventErrData}>
                <div>
                  <span>누적 고장 신호</span>
                  <br />
                  <span className="ensoBoardContent">
                    <b>{numFormat(dataSource.dashboard.totalErr)}</b>
                    <span>건</span>
                  </span>
                </div>
              </div>
              <div className="ensoBoardSubTime">
                <div>
                  <span>수동 조치시간</span>
                  <br />
                  <span className="ensoBoardContent">
                    {dataSource.dashboard.totalManaTm >= 60 && (
                      <>
                        {" "}
                        <b>{dataSource.actHour}</b> <span>시간 </span> <b>{dataSource.actMinute}</b> <span>분</span>{" "}
                      </>
                    )}
                    {dataSource.dashboard.totalManaTm < 60 && (
                      <>
                        {" "}
                        <b>{dataSource.actMinute}</b> <span>분</span>{" "}
                      </>
                    )}
                  </span>
                </div>
              </div>
            </div>

            <div className="ensoArticle">
              <div className="ensoArticleSub">
                <div className="ensoArticleSubTitle">
                  <h3>에너지원별 이벤트</h3>
                </div>
                <div className="ensoArticleEngCharts">
                  <div>
                    <EnsoPieCharts chartTitle="발생건수" data={dataSource.eventContent} />
                  </div>
                  <div>
                    <EnsoPieCharts chartTitle="조치소요시간" data={dataSource.eventContent} />
                  </div>
                </div>
              </div>
              <div className="ensoArticleSub">
                <div className="ensoArticleSubTitle">
                  <h3>시간대별 경고/고장 상세정보</h3>
                  <Button className={styles.ensoExcelBtn} onClick={ensoDownloadExcel}>
                    <img src="/images/Icon_ExelSDown.svg" alt="" />
                    &nbsp; 엑셀저장
                  </Button>
                </div>
                <div className="ensoArticleTimeCharts">
                  <EnsoLineCharts data={dataSource.eventTime} />
                </div>
                <div className="ensoArticleTimeGrid">
                  <Grid className={styles.ensoGrid} data={dataSource.eventContent}>
                    <GridColumn field="ensoType" title="에너지원" />
                    <GridColumn field="warn" title="경고" cell={warnCell} />
                    <GridColumn field="err" title="고장" cell={errCell} />
                    <GridColumn field="manaTm" title="조치시간(분)" cell={manaTmCell} />
                  </Grid>
                </div>
              </div>
            </div>
          </IntlProvider>
        </LocalizationProvider>

        {loading && (
          <p
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
            }}
          >
            <BeatLoader loading={true} size={24} color="#1e88e5" />
          </p>
        )}
      </div>
    </>
  );
};

export default Enso;
