//components
import RenewEnsoCht from "./renewCht/renewEnsoCht";
import RenewOperCht from "./renewCht/renewOperCht";
import RenewBizCht from "./renewCht/renewBizCht";
import RenewMonthCht from "./renewCht/renewMonthCht";
import RenewStatDetailBox from "./renewStatDetailBox";
//css
import styles from "./renewStat.module.css";
//icon
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { SvgIcon } from "@mui/material";

// 켄도 버튼
import { Button } from '@progress/kendo-react-buttons';
import { getRpsBpmSyncMeainListExcelDownload } from "../../../service/meain/bpmRps/bpmRpsService";
import { getUserTypeCode } from "../../../service/authService";

const RenewStatDetail = (props) => {
  const { operCntData, ensoStatData, bizStatData, monthStatData, currMap, prevTabBtnClickEvt, nextTabBtnClickEvt, currTab, top3EnsoStatData, setLoading } = props;

  const today = new Date();

  const loginUserTypeCode = getUserTypeCode();

  const getLogo = (currMap) => {
    const { rgnCode } = currMap;
    if (rgnCode) return "/images/logos/" + rgnCode + "_logo.png";
    else return "/images/logos/44000_logo.png";
  };

  // 엑셀 저장
  const saveExcel = () => {
    setLoading(true);
    getRpsBpmSyncMeainListExcelDownload(setLoading);
  };

  return (
    <div>
      <div className={styles.h1Div}>
        <div className={styles.h1}>
          <img src={getLogo(currMap)} alt="로고" style={{ width: "30px", position: "absolute", right: "10px" }} />
          {currMap.rgnTerm}
          &nbsp;상세정보
        </div>
      </div>

      <div className={styles.control}>
        <div className={styles.tabBtn}>
          {/* <button onClick={prevTabBtnClickEvt} disabled={currTab===0?true:false}><SvgIcon component={ArrowBackIosNewIcon}/></button>
          <button onClick={nextTabBtnClickEvt} disabled={currTab===1?true:false}><SvgIcon component={ArrowForwardIosIcon}/></button> */}
        </div>

        {[2003, 2005].includes(loginUserTypeCode) && (
          <button className={styles['excel-download']} onClick={saveExcel}>
            <img src="/images/excel.svg" alt="" style={{marginRight:'5px'}}/>
            엑셀 다운로드
          </button>
        )}
      </div>
      
      {
      currTab%2 === 1
      ? <>
          <div className={styles.renewDivHead}>설비 운영현황</div>
            <div className={styles.renewDiv}>
              <RenewOperCht chartData={operCntData} />
            </div>
            <div className={styles.renewDivHead}>에너지원별 통계</div>
            <div className={styles.renewDiv}>
              <RenewEnsoCht chartData={ensoStatData} />
            </div>
            <div className={styles.renewDivHead}>사업구분별 통계</div>
            <div className={styles.renewDiv}>
              <RenewBizCht chartData={bizStatData} />
          </div>
        </>
      : <>
          <div className={styles.renewDivHead}>사용량 상위 TOP3 에너지원별 통계</div>
          <div className={styles.renewDiv}>
            <div className={styles.renewBox}>
              <RenewStatDetailBox top3EnsoStatData={top3EnsoStatData[0]}/>
              <RenewStatDetailBox top3EnsoStatData={top3EnsoStatData[1]}/>
              <RenewStatDetailBox top3EnsoStatData={top3EnsoStatData[2]}/>
            </div>
          </div>
    
          <div className={styles.renewDivHead}>{today.getFullYear()} 년 월간 평균 발전량 추이</div>
          <div className={styles.renewDiv}>
            <RenewMonthCht monthStatData={monthStatData} today={today}/>
          </div>
        </>
      }
      
    </div>
  );
};

export default RenewStatDetail;
