import LocationDepth1Search from "./locationDepth1_search";
import LocationDepth1Map from "./locationDepth1_map";
import React, { useState, useEffect } from "react";

import "./locationDepth1.css";
import { getLocationEnergyList, getAreaInstallMeainStatData } from "../../../service/location/locationDepth1Service";
import { getInstallMeainStatData } from "../../../service/location/locationService";
import { getUser, isInstaller } from "../../../service/authService";
import { useHistory } from "react-router";
import { BeatLoader } from "react-spinners";

import styles from "./locationDepth1.module.css";
const LocationDepth1 = ({ conn, codeList, rgnList, dongList }) => {
  // 로딩 스피너
  const [loading, setLoading] = useState(false);

  // 히스토리 사용
  const history = useHistory();

  // 수용가면 바로 depth3 으로 이동
  if (isInstaller() === true) {
    const userinfo = getUser();
    if (userinfo.rgnCode) {
      history.push("/location/thridDepth?rgnCode=" + userinfo.rgnCode);
    }
  }

  let [search, setSearch] = useState({
    userTerm: "",
    rgnCode: "",
    // fixbylawBundCode : dongCode ,
    bldSrvCodeNo: "",
    bizSct: "",
    bizYy: "",
    rgnTerm: "",
    dongTerm: "",
    rgn: { rgnCode: "", rgnTerm: "시군구" },
    dong: { fixbylawBundCode: "", fixbylawBundTerm: "읍면동" },
  });

  // 운영현황
  const [statCnt, setStatCnt] = useState({
    totalCnt: 0,
    readyCnt: 0,
    normalCnt: 0,
    unOperCnt: 0,
    warnCnt: 0,
    errorCnt: 0,
  });

  const [rgnMapDataList, setRgnMapDataList] = useState({
    rgnNormalCnt: [],
    dataMapList: [],
  });

  const [areaMeainStatCnt, setAreaMeainStatCnt] = useState({
    data: [
      { circle1: "", circle2: "", areaTotalCnt: 0 },
      { circle1: "", circle2: "", areaTotalCnt: 0 },
      { circle1: "", circle2: "", areaTotalCnt: 0 },
      { circle1: "", circle2: "", areaTotalCnt: 0 },
      { circle1: "", circle2: "", areaTotalCnt: 0 },
      { circle1: "", circle2: "", areaTotalCnt: 0 },
      { circle1: "", circle2: "", areaTotalCnt: 0 },
      { circle1: "", circle2: "", areaTotalCnt: 0 },
      { circle1: "", circle2: "", areaTotalCnt: 0 },
      { circle1: "", circle2: "", areaTotalCnt: 0 },
      { circle1: "", circle2: "", areaTotalCnt: 0 },
      { circle1: "", circle2: "", areaTotalCnt: 0 },
      { circle1: "", circle2: "", areaTotalCnt: 0 },
      { circle1: "", circle2: "", areaTotalCnt: 0 },
      { circle1: "", circle2: "", areaTotalCnt: 0 },
    ],
  });

  const meainCntStat = () => {
    getInstallMeainStatData(conn, getResultMeainStat, getRejection, search);
  };

  const getResultMeainStat = (result) => {
    setStatCnt((statCnt) => {
      const item = { ...statCnt };
      item.totalCnt = result.totalCnt;
      item.readyCnt = result.readyCnt;
      item.normalCnt = result.normalCnt;
      item.unOperCnt = result.unOperCnt;
      item.warnCnt = result.warnCnt;
      item.errorCnt = result.errorCnt;
      return item;
    });
  };

  // reject 함수
  const getRejection = (error) => {};

  useEffect(() => {
    // setLocationList({})
    setMapDataList({});
    meainCntStat();
  }, []);

  const setMapDataList = () => {
    setLoading(true);
    Promise.all([getLocationEnergyList(conn, search), getAreaInstallMeainStatData(conn, search)]).then((result) => {
      setLoading(false);
      // rgnList
      setRgnMapDataList((rgnMapDataList) => {
        const item = { ...rgnMapDataList };

        let areaMeainCnt = [];

        item.dataMapList = result[0].locationEnergyList;
        item.rgnNormalCnt = result[1].rgnNormalCnt;

        rgnList.forEach((rgn) => {
          let operationStatus = 0;
          let circleName1 = "";
          let circleName2 = "";
          let areaTotalCnt = 0;
          const count = item.dataMapList.find((m) => m.rgnCode === rgn.rgnCode);
          if (count) areaTotalCnt = count.solaCnt + count.heatCnt + count.heathCnt + count.veloCnt + count.fuelcellCnt + count.essCnt;
          const nmCount = item.rgnNormalCnt.find((v) => v.rgnCode === rgn.rgnCode);

          if (areaTotalCnt !== 0) operationStatus = nmCount ? nmCount.normalCnt : (0 / areaTotalCnt) * 100;
          else operationStatus = 0;

          const ratio = areaTotalCnt ? (nmCount.normalCnt / areaTotalCnt) * 100 : 0;
          if (ratio < 5) {
            circleName1 = "ChMapBg-FF";
            circleName2 = "ChMapBg-FFBg";
          } else if (5 <= ratio && ratio <= 20) {
            circleName1 = "ChMapBg-FE";
            circleName2 = "ChMapBg-FEBg";
          } else if (21 <= ratio && ratio <= 40) {
            circleName1 = "ChMapBg-FD";
            circleName2 = "ChMapBg-FDBg";
          } else if (41 <= ratio && ratio <= 60) {
            circleName1 = "ChMapBg-FC";
            circleName2 = "ChMapBg-FCBg";
          } else if (61 <= ratio && ratio <= 80) {
            circleName1 = "ChMapBg-FB";
            circleName2 = "ChMapBg-FBBg";
          } else if (81 <= ratio && ratio <= 100) {
            circleName1 = "ChMapBg-FA";
            circleName2 = "ChMapBg-FABg";
          }
          areaMeainCnt.push({
            areaTotalCnt: areaTotalCnt,
            operationStatus: operationStatus,
            rgnCode: rgn.rgnCode,
            rgnTerm: rgn.rgnTerm,
            circle1: circleName1,
            circle2: circleName2,
          });
        });
        setAreaMeainStatCnt({
          data: areaMeainCnt,
        });
        return item;
      });
    });
  };

  return (
    <div>
      <div className={loading ? "locationDepth" : ""}>
        <div className="uk-grid">
          <div className="uk-width-medium-1-1">
            <div className="md-card-content">
              <div className="md-card-toolbar">
                <h3 className="md-card-toolbar-heading-text">지도</h3>
                {/* <div className="md-card-toolbar-actions">
                <b className="textBl">충남</b>
              </div> */}
              </div>
              <div className="uk-grid location1">
                <LocationDepth1Search
                  conn={conn}
                  search={search}
                  setSearch={setSearch}
                  codeList={codeList}
                  rgnList={rgnList}
                  dongList={dongList}
                  rgnMapDataList={rgnMapDataList}
                  setRgnMapDataList={setRgnMapDataList}
                  setMapDataList={setMapDataList}
                  setStatCnt={setStatCnt}
                />
                <div className="uk-width-medium-7-10">
                  <div className="Ch-Map">
                    <LocationDepth1Map areaMeainStatCnt={areaMeainStatCnt} conn={conn} codeList={codeList} rgnList={rgnList} />
                    <div className="Depth1">
                      <div className="ChMapTb-A">
                        <table>
                          <tr>
                            <th>
                              검색설비 운영현황
                              {/* ({searchMeainStatTotal}) */}({statCnt.totalCnt})
                            </th>
                          </tr>
                          <tr>
                            <td className="textGre">
                              <img src="/images/icon_gre.png" alt="정상" />
                              &nbsp;&nbsp;정&nbsp;&nbsp;&nbsp;&nbsp;상&nbsp;
                              {/* ({dataList.meatinStatCnt.normalCnt}) */}({statCnt.normalCnt})
                            </td>
                          </tr>
                          <tr>
                            <td className="textGry">
                              <img src="/images/icon_gray.png" alt="미작동" />
                              &nbsp;&nbsp;미작동&nbsp;
                              {/* ({dataList.meatinStatCnt.unOperCnt}) */}({statCnt.unOperCnt})
                            </td>
                          </tr>
                          <tr>
                            <td className="textBl">
                              <img src="/images/icon_bl.png" alt="준비중" />
                              &nbsp;&nbsp;준비중&nbsp;
                              {/* ({dataList.meatinStatCnt.readyCnt}) */}({statCnt.readyCnt})
                            </td>
                          </tr>
                          <tr>
                            <td className="textYl">
                              <img src="/images/icon_yl.png" alt="경고" />
                              &nbsp;&nbsp;경&nbsp;&nbsp;&nbsp;&nbsp;고&nbsp;
                              {/* ({dataList.meatinStatCnt.waringCnt}) */}({statCnt.warnCnt})
                            </td>
                          </tr>
                          <tr>
                            <td className="textRd">
                              <img src="/images/icon_rd.png" alt="고장" />
                              &nbsp;&nbsp;고&nbsp;&nbsp;&nbsp;&nbsp;장&nbsp;
                              {/* ({dataList.meatinStatCnt.errorCnt}) */}({statCnt.errorCnt})
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className={styles.chMapTbB}>
                      <table>
                        <tr>
                          <th>지역별 가동현황(%)</th>
                        </tr>
                        <tr>
                          <td>
                            <img src="/images/iconGr_01.png" alt="5" />
                            &nbsp;&nbsp;&#60;&nbsp;5
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <img src="/images/iconGr_02.png" alt="20" />
                            &nbsp;&nbsp;5-20
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <img src="/images/iconGr_03.png" alt="40" />
                            &nbsp;&nbsp;20-40
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <img src="/images/iconGr_04.png" alt="60" />
                            &nbsp;&nbsp;40-60
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <img src="/images/iconGr_05.png" alt="80" />
                            &nbsp;&nbsp;60-80
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <img src="/images/iconGr_06.png" alt="100" />
                            &nbsp;&nbsp;80-100
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && (
        <p className="locationDepthLoading">
          <BeatLoader loading={true} size={24} color="#1e88e5" />
        </p>
      )}
    </div>
  );
};
export default LocationDepth1;
