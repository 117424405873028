import styles from "./cmpUserByUser.module.css";

// 대시보드
const CmpUserByUserDashboard = ({ dashboard, search }) => {
  // 태양열, 지열인지 판별
  const isHeat = dashboard.ensoTypeCode === 15002 || dashboard.ensoTypeCode === 15003 ? true : false;

  return (
    <div className="uk-grid">
      <div className="uk-width-medium-1-5" id="ttlInstCapa">
        <div className="md-card TBgA">
          <div className="md-card-content">
            <div className={[styles.dashBoardArea, "FourGridT"].join(" ")}>
              <span className="FourTT">설비용량</span>
              <br />
              <b>{dashboard.instCapa}</b>
              <span className="FourDa">kW</span>
            </div>
          </div>
        </div>
      </div>
      <div className="uk-width-medium-1-5" id="ttlInstCapa">
        <div className="md-card TBgA">
          <div className="md-card-content">
            <div className={[styles.dashBoardArea, "FourGridT"].join(" ")}>
              {isHeat === false ? <span className="FourTT">누적발전량</span> : <span className="FourTT">누적생산량</span>}
              <br />
              <b>{dashboard.accumPower}</b>
              <span className="FourDa">kWh</span>
            </div>
          </div>
        </div>
      </div>
      <div className="uk-width-medium-1-5" id="ttlInstCapa">
        <div className="md-card TBgA">
          <div className="md-card-content">
            <div className={[styles.dashBoardArea, "FourGridT"].join(" ")}>
              <span className="FourTT">평균효율</span>
              <br />
              <b>{dashboard.efic}</b>
              <span className="FourDa">%</span>
            </div>
          </div>
        </div>
      </div>
      <div className="uk-width-medium-1-5" id="ttlInstCapa">
        <div className="md-card TBgA">
          <div className="md-card-content">
            <div className={[styles.dashBoardArea, "FourGridT"].join(" ")}>
              {isHeat === false ? <span className="FourTT">발전량</span> : <span className="FourTT">생산량</span>}
              <br />
              <b>{dashboard.dayPower}</b>
              <span className="FourDa">kWh</span>
            </div>
          </div>
        </div>
      </div>
      <div className="uk-width-medium-1-5" id="ttlInstCapa">
        <div className="md-card TBgA">
          <div className="md-card-content">
            <div className={[styles.dashBoardArea, "FourGridT"].join(" ")}>
              <span className="FourTT">
                CO<sub>2</sub>저감량
              </span>
              <br />
              <b>{dashboard.co2}</b>
              <span className="FourDa">
                kgCO<sub>2</sub>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CmpUserByUserDashboard;
