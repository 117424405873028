// 그래프
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { numFormat } from '../../../../com/dataUtil';

const UserBetweenUserListChart = ({userDataEfic , userDataRatio , search , getMakrMeainModelDataList }) => {

  // 에너지원 코드 번호
  const ensoTypeCodeNo = search.ensoTypeCode; 
  // 발전량 , 생산량 조건부 렌더링
  const isCal = ensoTypeCodeNo === 15002 || ensoTypeCodeNo === 15003 ? true : false;

  // 시/군 생사량 목록
  const chartConfig = {
    chart: { zoomType: 'xy' },
    title: { text: null },
    credits : { enabled: false },
    noData: { style:{ color: '#FFFFFF', fontSize: '14px' } },
    tooltip: {
        shared: true,
        positioner: function (labelWidth, labelHeight, point) {
          // 툴팁 위치 조정 
          const tooltipX = point.plotX;
          const tooltipY = 150;
          return {
              x: tooltipX,
              y: tooltipY
          };
        },
        formatter: function () {
          var s = '<b>' + this.points[0].point.name + '</b>';
          s += '<br/>'+ '<span style="color:' + this.points[0].point.color + '">\u25CF</span> '  + this.points[0].series.name + ': ' + Math.round(this.points[0].y*100)/100 + ' kWh/'+(search.ensoTypeCodeNo==15002?'㎡':'kW')+'.day';
          s += '<br/>' + '<span style="color:' + this.points[1].point.color + '">\u25CF</span> '  + this.points[1].series.name + ': ' + Math.round(this.points[1].y) +' % (' +  numFormat(this.points[1].point.instCapa)  +(search.ensoTypeCodeNo==15002?'㎡':'kW')+')';
          return s;
        }
    },
    plotOptions: {
        series: {
            cursor: 'pointer',
            events: {
                click: function (event) {
                  // 드릴다운 이벤트 
                    search.makrSeriNo = "";
                    search.userId = event.point.userId;
                    getMakrMeainModelDataList();
                 }
            }
        }
    },
    xAxis: [{ type: 'category', crosshair: true }],
    yAxis: [{ // Primary yAxis
        labels: {
            format: '{value:,.0f}',
            style: {
                color: "#69bcc8"
            }
        },
        title: {
            text:  isCal === false ? '용량대비생산량(kWh/' : '용량대비생산량(kWh/'  +(search.ensoTypeCodeNo==15002?'㎡':'kW')+'.day)',
            style: {
                color: "#69bcc8"
            }
        }
    }, { // Secondary yAxis
        title: {
            text: '설비용량비율(%)',
            style: {
                color: "#c1ce01"
            }
        },
        labels: {
            format: '{value:,.0f}',
            style: {
                color: "#c1ce01"
            }
        },
        opposite: true
    }],
    legend: {
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'top',
        backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#FFFFFF'
    },
    series: [{
        name: '용량대비생산량',
        type: 'column',
        color: '#69bcc8',
        dataLabels: {
            enabled: true,
            format: '{point.y:,.2f}'
        },
        data: userDataEfic,
        tooltip: {
            pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:,.2f} kWh/'+(search.ensoTypeCodeNo==15002?'㎡':'kW')+'.day</b><br/>'		            
        }

    }, {
        name: '설비용량비율',
        type: 'column',
        yAxis: 1,
        color: '#c1ce01',
        dataLabels: {
            enabled: true,
            format: '{point.y:,.2f}%'
        },
        data: userDataRatio,
        tooltip: {
            pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:,.2f} %</b><br/>'		            
        }
    }]
  }



  return(
    <div style = {{height : "370px"}}>
        <HighchartsReact Highcharts = {Highcharts} options = {chartConfig} />
    </div>
  );
}

export default UserBetweenUserListChart;
