import React from "react";

// HighCharts
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import drilldown from "highcharts/modules/drilldown";
import { numFormat, roundNum } from "../../../../com/dataUtil";

drilldown(Highcharts);

export const MakrEventCntCharts = ({ dataSource }) => {
  const options = {
    chart: {
      type: "pie",
      marginTop: 50,
      spacingTop: 30,
    },
    noData: {
      style: {
        color: "#FFFFFF",
        fontSize: "14px",
      },
    },
    colors: [
      "#498FA8",
      "#929292",
      "#78913D",
      "#C9C03F",
      "#EECC3F",
      "#BB7E29",
      "#B76325",
      "#B2321B",
      "#AF1919",
      "#AD498A",
      "#782450",
      "#4F2B86",
      "#2D2D78",
      "#3960AA",
    ],

    title: {
      text: "제조사별 이벤트 발생건수",
      align: "center",
      verticalAlign: "top",
      style: {
        fontSize: "16px",
        fontWeight: "bold",
      },
    },
    credits: {
      enabled: false,
    },
    navigation: {
      buttonOptions: {
        enabled: false,
      },
    },
    plotOptions: {
      series: {
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          style: {
            textOutline: "none",
            fontSize: "14px",
          },
          formatter: function () {
            return "<span>" + "<b>" + this.point.name + "-" + numFormat(this.y) + "건(" + roundNum(this.percentage, 1) + "%)</b>" + "</span>";
          },
          showInLegend: true,
        },
      },
    },
    tooltip: {
      shared: true,
      formatter: function () {
        let msg = "<span>" + "<b>" + this.key + "</b>" + "</span>" + "<br>";
        msg += "<span>" + "발생건수" + "</span>" + " : " + "<b>" + numFormat(this.y) + "건(" + roundNum(this.percentage, 1) + "%)</b>" + "<br>";
        return msg;
      },
    },
    series: [
      {
        name: "발생건수",
        colorByPoint: true,
        data: dataSource.data,
      },
    ],
    drilldown: {
      series: dataSource.drilldownData,
    },
  };
  return (
    <>
      <HighchartsReact Highcharts={Highcharts} options={options} />
    </>
  );
};

export default MakrEventCntCharts;
