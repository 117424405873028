import { ExcelExport, ExcelExportColumn, ExcelExportColumnGroup } from '@progress/kendo-react-excel-export';
import { Grid, GridColumn as Column , GridNoRecords } from '@progress/kendo-react-grid';
import { useState , useEffect } from 'react';
import { orderBy } from '@progress/kendo-data-query';
import kendo from '@progress/kendo-ui';
import {
  IntlProvider,
  LocalizationProvider,
  loadMessages,
} from "@progress/kendo-react-intl";


const GathStatusMonthGrid = ({dataSource , ensoTypeCodeNo, setExcelData}) =>{

  // 엑셀 다운로드 변수 (레퍼런스 사용)
  let _excelData;

  // 페이징, 정렬 상태
  let [paging , setPaging] = useState({ take: 10, skip: 0 });
  let [sort , setSort] = useState([{field: '', dir: ''}]);

  // 페이징 이벤트
  const onPageChange = (e) =>{ const {page} = e; setPaging(page); }

  // 정렬 이벤트
  const onSortChange = (e) =>{ const {sort} = e; setSort(sort); }

   // 파일 이름
   const fileName = () =>{
    let name = "전체";
    if(ensoTypeCodeNo === 15001){name = "태양광"}
    else if(ensoTypeCodeNo === 15002){name = "태양열"}
    else if(ensoTypeCodeNo === 15003){name = "지열"}
    else if(ensoTypeCodeNo === 15004){name = "풍력"}
    else if(ensoTypeCodeNo === 15006){name = "연료전지"}
    else if(ensoTypeCodeNo === 15007){name = "ESS"}
    const template = name + "_월별 수집데이터_" + kendo.toString(new Date() , "yyyyMMdd") + ".xlsx";
    return template;
  }

  useEffect(() => {
    // 엑셀 다운로드 데이터 설정
    setExcelData(_excelData)
  }, []);

  return(
    <div className="Table-scr" style = {{height : "420px"}}>
      <ExcelExport className = "dataGathGrid"
        data={orderBy ( dataSource, sort )}
        ref={exporter => _excelData = exporter }
        fileName = {fileName()}
        >
        <ExcelExportColumn field="gathDtm" title="시간" width = {250}/>
        <ExcelExportColumnGroup title="데이터 수집 건수">
            <ExcelExportColumn field="modelCnt" title="기준"width = {150}/>
            <ExcelExportColumn field="rawCnt" title="정상"width = {150}/>
            <ExcelExportColumn field="ajstCnt" title="장애"width = {150}/>
        </ExcelExportColumnGroup>
        <ExcelExportColumn field="gathRate" title="수집률 (%)"width = {150}/>
      </ExcelExport>
<LocalizationProvider language="ko-KR">
          <IntlProvider locale="ko">
      <Grid className = "dataGathGrid"
        style={{height:"400px"}}
        data =  { orderBy ( dataSource, sort ).slice(paging.skip , paging.take + paging.skip)} 
        total = {dataSource.length}
        skip = {paging.skip}
        take = {paging.take}
        onPageChange={onPageChange}
        onSortChange={onSortChange}
        sort = {sort}
        page = {paging}
        sortable
        pageable
        >
        <GridNoRecords>
            {"조회 결과 데이터가 없습니다."}
        </GridNoRecords>
        <Column field="gathDtm" title="시간" width="250px" />
        <Column title="데이터 수집 건수">
            <Column field="modelCnt" title="기준"/>
            <Column field="rawCnt" title="정상"/>
            <Column field="ajstCnt" title="장애"/>
        </Column>
        <Column field="gathRate" title="수집률 (%)" width="250px"/>
      </Grid>
                </IntlProvider>
                </LocalizationProvider>
    </div>
  );
}

export default GathStatusMonthGrid;