import kendo from '@progress/kendo-ui';

// 일별 데이터 가져오기
export const getGathStatusDay = async (conn, callback, reject, ensoTypeCodeNo, day, setLoading) => {
  // 검색 객체 생성
  await conn
    .get('analy/gathdata/day', {
      params: {
        ensoTypeCodeNo: ensoTypeCodeNo,
        day: kendo.toString(day, 'yyyy-MM-dd'),
      },
    })
    .then((response) => {
      setLoading(false);
      callback(response);
    })
    .catch((error) => {
      setLoading(false);
      reject(error);
    });
};

// 월별 데이터 가져오기
export const getGathStatusMonth = async (conn, callback, reject, ensoTypeCodeNo, day, setLoading) => {
  // 마지막 날짜 설정
  const year = kendo.toString(day, 'yyyy');
  const month = kendo.toString(day, 'MM');
  const date = new Date(year, month, 0);
  // 검색 객체 생성
  await conn
    .get('analy/gathdata/month', {
      params: {
        ensoTypeCodeNo: ensoTypeCodeNo,
        monthStart: kendo.toString(day, 'yyyy-MM-01'),
        monthEnd: kendo.toString(date, 'yyyy-MM-dd'),
      },
    })
    .then((response) => {
      setLoading(false);
      callback(response);
    })
    .catch((error) => {
      setLoading(false);
      reject(error);
    });
};
