import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { numFormat, roundNum } from "../../../../com/dataUtil";

const RenewBizCht = (props) => {
  const { chartData } = props;

  let dataSource = {
    cnt: [],
    instCapa: [],
    dayPower: [],
    dayUse: [],
    carbDec: [],
  };
  chartData.forEach((item) => {
    dataSource.cnt.push(item.cnt);
    dataSource.instCapa.push(item.instCapa);
    dataSource.dayPower.push(item.dayPower);
    dataSource.dayUse.push(item.dayUse);
    dataSource.carbDec.push(item.carbDec);
  });

  const chartOpt = {
    chart: {
      zoomType: "xy",
      type: "column",
      height: "255px",
      backgroundColor: "white",
      // backgroundColor: {
      //   linearGradient: [100, 100, 10, 10],
      //   stops: [
      //     [0, "#ffffff"],
      //     [1, "#f6f6f6"],
      //   ],
      // },
      borderRaidus: "5px",
    },
    lang: {
      noData: "데이터가 없습니다.",
    },
    noData: {
      style: {
        fontWeight: "bold",
        fontSize: "15px",
        color: "#303030",
      },
    },
    title: {
      text: null,
      style: {
        fontWeight: "bold",
        fontSize: "15px",
      },
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      column: {
        dataLabels: {
          enabled: true,
        },
      },
    },
    xAxis: [
      {
        categories: ["시/도", "국비", "민간"],
        type: "category",
        crosshair: true,
      },
    ],
    yAxis: [
      {
        visible: false,
        showEmpty: true,
        labels: {
          format: "{value:,f}개",
          style: {
            color: "#6DB6EE",
          },
        },
        title: {
          text: "설비개수",
          style: {
            color: "#6DB6EE",
          },
          // ,
          rotation: 0,
          y: -20,
          x: -10,
          offset: 0,
          align: "high",
        },
        // min: 0,
      },
      {
        visible: false,
        showEmpty: false,
        // showEmpty: true,
        labels: {
          format: "{value:,.0f}kWh",
          style: {
            // color: "#EE7951",
          },
        },
        title: {
          text: "설비용량",
          style: {
            // color: "#EE7951",
          },
          rotation: 0,
          y: -20,
          x: -10,
          offset: 15,
          align: "high",
        },
        min: 0,
      },
      {
        visible: false,
        showEmpty: false,
        // showEmpty: true,
        labels: {
          format: "{value:,.0f}kWh",
          style: {
            // color: "#EE7951",
          },
        },
        title: {
          text: "발전/사용량",
          style: {
            // color: "#EE7951",
          },
          rotation: 0,
          y: -20,
          x: -10,
          offset: 15,
          align: "high",
        },
        opposite: true,
        // min: 0,
        // max: 100,
      },
      {
        visible: false,
        showEmpty: false,
        // showEmpty: true,
        labels: {
          format: "{value:,.0f}kWh",
          style: {
            // color: "#EE7951",
          },
        },
        title: {
          text: "발전/사용량",
          style: {
            // color: "#EE7951",
          },
          rotation: 0,
          y: -20,
          x: -10,
          offset: 15,
          align: "high",
        },
        opposite: true,
        // min: 0,
        // max: 100,
      },
      {
        visible: false,
        showEmpty: false,
        // showEmpty: true,
        labels: {
          format: "{value:,.0f}kWh",
          style: {
            // color: "#EE7951",
          },
        },
        title: {
          text: "발전/사용량",
          style: {
            // color: "#EE7951",
          },
          rotation: 0,
          y: -20,
          x: -10,
          offset: 15,
          align: "high",
        },
        opposite: true,
        // min: 0,
        // max: 100,
      },
    ],
    tooltip: {
      shared: true,
      formatter: function () {
        let s = "<b>" + this.points[0].key + "</b>";

        if (this.points[0]) {
          s +=
            "<br/>" +
            '<span style="color:' +
            this.points[0].point.color +
            '">\u25CF</span> ' +
            this.points[0].series.name +
            ": " +
            numFormat(roundNum(this.points[0].y, 2)) +
            " 개";
        }

        if (this.points[1]) {
          s +=
            "<br/>" +
            '<span style="color:' +
            this.points[1].point.color +
            '">\u25CF</span> ' +
            this.points[1].series.name +
            ": " +
            numFormat(roundNum(this.points[1].y, 2)) +
            (this.x === "태양열" ? " k㎡" : " MW");
        }

        if (this.points[2]) {
          s +=
            "<br/>" +
            '<span style="color:' +
            this.points[2].point.color +
            '">\u25CF</span> ' +
            this.points[2].series.name +
            ": " +
            numFormat(roundNum(this.points[2].y, 2)) +
            " MWh";
        }
        if (this.points[3]) {
          s +=
            "<br/>" +
            '<span style="color:' +
            this.points[3].point.color +
            '">\u25CF</span> ' +
            this.points[3].series.name +
            ": " +
            numFormat(roundNum(this.points[3].y, 2)) +
            " kWh";
        }
        if (this.points[4]) {
          s +=
            "<br/>" +
            '<span style="color:' +
            this.points[4].point.color +
            '">\u25CF</span> ' +
            this.points[4].series.name +
            ": " +
            numFormat(roundNum(this.points[4].y, 2)) +
            " tCO2";
        }
        return s;
      },
    },
    legend: {
      align: "center",
      verticalAlign: "top",
    },
    series: [
      {
        name: "설비개수",
        data: dataSource.cnt,
        color: "#0B92CC",
        yAxis: 0,
      },
      {
        name: "설비용량",
        data: dataSource.instCapa,
        color: "#7AB700",
        yAxis: 1,
      },
      {
        name: "발전량",
        data: dataSource.dayPower,
        color: "#FFAD01",
        yAxis: 2,
      },
      {
        name: "사용량",
        data: dataSource.dayUse,
        color: "#FE4A59",
        yAxis: 3,
      },
      {
        name: "탄소저감량",
        data: dataSource.carbDec,
        color: "#AF6CC9",
        yAxis: 4,
      },
    ],
  };
  Highcharts.setOptions({
    lang: {
      resetZoom: "초기화",
      thousandsSep: ",",
    },
  });
  return <HighchartsReact highcharts={Highcharts} options={chartOpt} />;
};

export default RenewBizCht;
