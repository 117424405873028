import kendo from "@progress/kendo-ui";
import { numFormat, roundNum } from "../../../com/dataUtil";
/**
 * 생산량 분포 (사용자 리스트)
 *
 * @param  conn , search
 * @return 각 에너지원별의 사용자 생산량 데이터
 */
export const getUserEnergyDataList = async (conn, callback, reject, search) => {
  await conn
    .get("stat/userbetweenuser/rankpage", {
      params: {
        ensoTypeCode: search.ensoTypeCode,
        rgnCode: search.rgnCode,
        startGathDtm: kendo.toString(search.startGathDtm, "yyyy-MM-dd"),
        endGathDtm: kendo.toString(search.endGathDtm, "yyyy-MM-dd"),
      },
    })
    .then((response) => {
      // 순위
      let no = 0;

      // * 그리드 파트
      // 그리드 데이터 값 가공(소수점 2자리 , 1000자리 이상 3자리 컴마)
      response.userBetweenUserDataDtoList.forEach((element) => {
        no = no + 1;
        element.no = no;
        // 비율 %는 100%를 넘길 수 없다는 가정으로 소수점 처리만 한다.
        element.ratio = element.ratio !== null ? roundNum(element.ratio, 2) : 0;
        element.instCapaPerEnergy = element.instCapaPerEnergy !== null ? numFormat(roundNum(element.instCapaPerEnergy, 2)) : 0;
        element.instCapa = element.instCapa !== null ? numFormat(roundNum(element.instCapa, 2)) : 0;
        element.dayEnergy = element.dayEnergy !== null ? numFormat(roundNum(element.dayEnergy, 2)) : 0;
        element.count = element.count !== null ? numFormat(roundNum(element.count, 2)) : 0;
      });

      // * 차트 파트
      // 용량대비생산량 차트 데이터 저장
      const userDataEfic = [];
      // 설비용량비율 차트 데이터 저장
      const userDataRatio = [];
      // 차트 데이터 가공
      response.userTopRank.forEach((element) => {
        element.ratio = element.ratio !== null ? roundNum(element.ratio, 2) : 0;
        element.instCapaPerEnergy = element.instCapaPerEnergy !== null ? roundNum(element.instCapaPerEnergy, 2) : 0;
        element.instCapa = element.instCapa !== null ? roundNum(element.instCapa, 2) : 0;
        element.dayEnergy = element.dayEnergy !== null ? roundNum(element.dayEnergy, 2) : 0;
        element.count = element.count !== null ? roundNum(element.count, 2) : 0;

        userDataEfic.push({ name: element.userTerm, y: element.instCapaPerEnergy, userId: element.userId, color: "#69bcc8" });
        userDataRatio.push({ name: element.userTerm, y: element.ratio, instCapa: element.instCapa, userId: element.userId, color: "#c1ce01" });
      });

      // 그래프 색깔 설정
      if (userDataEfic.length > 0) {
        userDataEfic[0].color = "#3e7bab";
      }

      // 차트 데이터 response 에 설정
      response.userDataEfic = userDataEfic;
      response.userDataRatio = userDataRatio;

      callback(response);
    })
    .catch((error) => {
      reject(error);
    });
};

/**
 * 해당 사용자의 설비 제조사 , 모델 별 데이터 리스트
 *
 * @param  conn , search
 * @return 해당 사용자의 설비 제조사 , 모델 별 데이터 리스트
 */

export const getMakrMeainModelEnergyDataList = async (conn, callback, reject, search) => {
  await conn
    .get("stat/userbetweenuser/makrMeainModelData", {
      params: {
        ensoTypeCode: search.ensoTypeCode,
        userId: search.userId,
        makrSeriNo: search.makrSeriNo,
        startGathDtm: kendo.toString(search.startGathDtm, "yyyy-MM-dd"),
        endGathDtm: kendo.toString(search.endGathDtm, "yyyy-MM-dd"),
      },
    })
    .then((response) => {
      // 순위
      let no = 0;
      // * 데이터 가공
      // 그리드
      const makrMeainModelGrid = [];
      // 차트
      const makrDataEfic = [];
      const makrDataRatio = [];
      response.forEach((element) => {
        no = no + 1;
        // 제조사
        if (element.meainTypeSeriNo) {
          makrDataEfic.push({
            name: element.meainTypeTerm,
            y: roundNum(element.instCapaPerEnergy, 2),
            makrSeriNo: null,
            meainTypeSeriNo: element.meainTypeSeriNo,
            color: "#69bcc8",
          });
          makrDataRatio.push({
            name: element.meainTypeTerm,
            y: roundNum(element.ratio, 2),
            makrSeriNo: null,
            meainTypeSeriNo: element.meainTypeSeriNo,
            color: "#c1ce01",
          });
        } else {
          makrDataEfic.push({ name: element.makrTerm, y: roundNum(element.instCapaPerEnergy, 2), makrSeriNo: element.makrSeriNo, color: "#69bcc8" });
          makrDataRatio.push({ name: element.makrTerm, y: roundNum(element.ratio, 2), makrSeriNo: element.makrSeriNo, color: "#c1ce01" });
        }

        // 그리드 데이터 가공
        makrMeainModelGrid.push({
          no: no,
          makrSeriNo: element.makrSeriNo,
          makrTerm: element.makrTerm,
          meainTypeSeriNo: element.meainTypeSeriNo,
          meainTypeTerm: element.meainTypeTerm,
          ratio: element.ratio !== null ? roundNum(element.ratio, 2) : 0,
          instCapaPerEnergy: element.instCapaPerEnergy !== null ? numFormat(roundNum(element.instCapaPerEnergy, 2)) : 0,
          instCapa: element.instCapa !== null ? numFormat(roundNum(element.instCapa, 2)) : 0,
          dayEnergy: element.dayEnergy !== null ? numFormat(roundNum(element.dayEnergy, 2)) : 0,
          count: element.count !== null ? numFormat(roundNum(element.count, 2)) : 0,
        });
      });

      // 값 설정
      const result = {
        makrMeainModelGrid: makrMeainModelGrid,
        makrDataEfic: makrDataEfic,
        makrDataRatio: makrDataRatio,
      };

      callback(result);
    })
    .catch((error) => {
      reject(error);
    });
};
