import kendo from "@progress/kendo-ui";
import { downloadExcel } from "../../../com/downloadExcel";
/**
 * 운전이력 조회
 *
 * @param  conn , callback 함수 , rejection 함수 , 검색 파라미터 , paging 파라미터
 * @return 각 에너지원별 운전이력 데이터 리스트
 */
export const getRawDataList = async ({ conn, callback, reject, param, paging, setLoading }) => {
  let url = "";
  if (param.ensoTypeCode === 15001) {
    url = "/raw/sola/page";
  } else if (param.ensoTypeCode === 15002) {
    url = "/raw/heat/page";
  } else if (param.ensoTypeCode === 15003) {
    url = "/raw/heath/page";
  } else if (param.ensoTypeCode === 15004) {
    url = "/raw/velo/page";
  } else if (param.ensoTypeCode === 15006) {
    url = "/raw/fuelcell/page";
  }

  await conn
    .get(url, {
      params: {
        cidUserTerm: param.cidUserTerm,
        ensoTypeCode: param.ensoTypeCode,
        rtuEnteCode: param.rtuEnteCode,
        meainTypeSeriNo: param.meainTypeSeriNo,
        makrSeriNo: param.makrSeriNo,
        rgnCode: param.rgnCode,
        fixbylawBundCode: param.fixbylawBundCode,
        startGathDtm: kendo.toString(param.startGathDtm ? param.startGathDtm : new Date(), "yyyy-MM-dd HH:mm:00"),
        endGathDtm: kendo.toString(param.endGathDtm ? param.endGathDtm : new Date(), "yyyy-MM-dd HH:mm:59"),
        page: paging.skip / paging.take + 1,
        size: paging.take,
      },
    })
    .then((response) => {
      setLoading(false);
      callback(response);
    })
    .catch((error) => {
      setLoading(false);
      reject(error);
    });
};

/**
 * 운전이력 엑셀 다운로드
 *
 * @param  검색 파라미터 , paging 파라미터
 * @return 각 에너지원별 운전이력 데이터 엑셀다운로드
 */
export const getExcelDownload = (param, paging, setLoading) => {
  // 정렬 조건 가공
  let sortValue = "";
  let direction = "";
  if (paging.sort.length !== 0) {
    if (
      paging.sort[0].field === "userTerm" ||
      paging.sort[0].field === "dataStatSctCodeTerm" ||
      paging.sort[0].field === "cid" ||
      paging.sort[0].field === "gathDtm"
    ) {
      if (paging.sort[0].field === "userTerm") {
        sortValue = "meainSola.installer.userTerm";
      } else if (paging.sort[0].field === "dataStatSctCodeTerm") {
        sortValue = "dataStatSctCodeNo";
      } else {
        sortValue = paging.sort[0].field;
      }
      direction = paging.sort[0].dir;
    } else {
      sortValue = paging.sort[0].field;
      direction = paging.sort[0].dir;
    }
  }

  // 수용가명, CID 입력 시 아래 분리 작업 진행
  let cid = "";
  let userTerm = "";
  if (param.cidUserTerm) {
    if (isNaN(param.cidUserTerm) === true) {
      userTerm = param.cidUserTerm;
    } else {
      cid = param.cidUserTerm;
    }
  }

  // 엑셀 다운로드 진행 할 검색 + 페이징 파라미터 통합 본
  const search = {
    ensoTypeCode: param.ensoTypeCode,
    cid: cid,
    userTerm: userTerm,
    cityProvCode: param.cityProvCode,
    rgnCode: param.rgnCode,
    // fixbylawBundCode : param.fixbylawBundCode,
    makrSeriNo: param.makrSeriNo,
    meainTypeSeriNo: param.meainTypeSeriNo,
    rtuEnteCode: param.rtuEnteCode,
    startGathDtm: kendo.toString(param.startGathDtm ? param.startGathDtm : new Date(), "yyyy-MM-dd HH:mm:00"),
    endGathDtm: kendo.toString(param.endGathDtm ? param.endGathDtm : new Date(), "yyyy-MM-dd HH:mm:59"),
    sort: sortValue,
    direction: direction,
  };

  // URL 설정
  let url = "";
  let excelTitle = "";
  const today = kendo.toString(new Date(), "yyyy-MM-dd");
  if (param.ensoTypeCode === 15001) {
    url = "/api/v2.2/raw/sola";
    excelTitle = "태양광_운전이력_" + "(" + today + ")";
  } else if (param.ensoTypeCode === 15002) {
    url = "/api/v2.2/raw/heat";
    excelTitle = "태양열_운전이력_" + "(" + today + ")";
  } else if (param.ensoTypeCode === 15003) {
    url = "/api/v2.2/raw/heath";
    excelTitle = "지열_운전이력_" + "(" + today + ")";
  } else if (param.ensoTypeCode === 15004) {
    url = "/api/v2.2/raw/velo";
    excelTitle = "풍력_운전이력_" + "(" + today + ")";
  } else if (param.ensoTypeCode === 15006) {
    url = "/api/v2.2/raw/fuelcell";
    excelTitle = "연료전지_운전이력_" + "(" + today + ")";
  } else if (param.ensoTypeCode === 15007) {
    url = "/api/v2.2/raw/ess";
    excelTitle = "ESS_운전이력_" + "(" + today + ")";
  }
  if (url === "") {
    kendo.alert("에너지원을 선택 해주세요.");
    return;
  }
  // const excelTitle = "엑셀다운로드";
  try {
    const result = downloadExcel(search, excelTitle, url, setLoading);
  } catch (error) {
    setLoading(false);
    kendo.alert("엑셀 다운로드를 실패 하였습니다.");
    return;
  }
};
