import React, { useState, useEffect, useRef, useCallback } from "react";
import { getDashboard } from "../../../../service/stats/type/ensoDataService";
import { roundNum, numFormat } from "../../../../com/dataUtil";
import styles from "./cmpEnso.module.css";

const EnsoDashBoard = ({ search, conn, setLoading }) => {
  // 대시 보드 데이터
  const [dashBoard, setDashBoard] = useState({
    ttlInstCapa: "-",
    ttlAccumGelecQty: "-",
    ttlEfic: "-",
    ttlGelecQty: "-",
    modelCnt: "-",
    co2ReduQty: "-",
    dayUse: "-",
    accumUse: "-",
  });

  // 대시보드 단위
  const [dashBoardUnit, setDashBoardUnit] = useState({
    eficUnit: "%",
    ttlInstCapaUnit: "kW",
  });

  // 조건부 랜더링을 위한 처리
  const isUse = search.ensoTypeCodeNo === 15002 || search.ensoTypeCodeNo === 15003 ? true : false;

  // 각 에너지원 필요한 데이터 폼 제어
  const controlDataForm = () => {
    // 태양광
    if (search.ensoTypeCodeNo === 15001) {
      setDashBoardUnit((dashBoardUnit) => {
        const item = { ...dashBoardUnit };
        item.eficUnit = "%";
        item.ttlInstCapaUnit = "kW";
        return item;
      });
    }
    // 태양열
    else if (search.ensoTypeCodeNo === 15002) {
      setDashBoardUnit((dashBoardUnit) => {
        const item = { ...dashBoardUnit };
        item.eficUnit = "%";
        item.ttlInstCapaUnit = "㎡";
        return item;
      });
    }
    // 지열
    else if (search.ensoTypeCodeNo === 15003) {
      setDashBoardUnit((dashBoardUnit) => {
        const item = { ...dashBoardUnit };
        item.eficUnit = "(COP)";
        item.ttlInstCapaUnit = "kW";
        return item;
      });
    }
    // 디폴트
    else {
      setDashBoardUnit((dashBoardUnit) => {
        const item = { ...dashBoardUnit };
        item.eficUnit = "%";
        item.ttlInstCapaUnit = "kW";
        return item;
      });
    }
  };

  // 데이터 불러오는 로직
  const getDataSource = useCallback(async () => {
    setLoading(true);
    getDashboard(conn, getDataDashboard, getDashRejec, search);
  }, [search]);

  // 대시 보드 데이터 세팅 CallBackFn
  const getDataDashboard = (getData) => {
    setLoading(false);
    setDashBoard((dashBoard) => {
      const item = { ...dashBoard };
      item.ttlInstCapa = getData.instCapa !== null ? numFormat(roundNum(getData.instCapa, 2)) : "-";
      item.ttlAccumGelecQty = getData.accumPower !== null ? numFormat(roundNum(getData.accumPower / 1000, 2)) : "-";
      item.ttlEfic = getData.efic !== null ? numFormat(roundNum(getData.efic, 2)) : "-";
      item.ttlGelecQty = getData.dayPower !== null ? numFormat(roundNum(getData.dayPower, 2)) : "-";
      item.modelCnt = getData.count !== null ? numFormat(roundNum(getData.count, 2)) : "-";
      item.co2ReduQty = getData.co2 !== null ? numFormat(roundNum(getData.co2 / 1000, 2)) : "-";
      item.dayUse = getData.dayUse !== null ? numFormat(roundNum(getData.dayUse, 2)) : "-";
      item.accumUse = getData.dayAccumUse !== null ? numFormat(roundNum(getData.dayAccumUse / 1000, 2)) : "-";
      return item;
    });
    // // 에너지원별 단위와 글씨 변경
    controlDataForm();
  };

  // 대시 보드 데이터 세팅 reJection
  const getDashRejec = (error) => {
    setLoading(false);
  };

  /// 데이터 한번 불러오기
  useEffect(() => {
    // 처음 데이터 불러오는 부분 제어 로직
    getDataSource();
  }, [getDataSource]);

  return (
    <div className="uk-grid">
      <div className="uk-width-medium-1-6" id="ttlInstCapa">
        <div className="md-card TBgA">
          <div className="md-card-content">
            <div className={[styles.dashBoardArea, "FourGridT"].join(" ")}>
              <span className="FourTT">설비용량</span>
              <br />
              <b>{dashBoard.ttlInstCapa}</b>
              <span className="FourDa">{dashBoardUnit.ttlInstCapaUnit}</span>
            </div>
          </div>
        </div>
      </div>
      {isUse === false && (
        <div className="uk-width-medium-1-6" id="ttlAccumGelecQty">
          <div className="md-card TBgA">
            <div className="md-card-content">
              <div className={[styles.dashBoardArea, "FourGridT"].join(" ")}>
                <span className="FourTT">누적발전량</span>
                <br />
                <b>{dashBoard.ttlAccumGelecQty}</b>
                <span className="FourDa">MWh</span>
              </div>
            </div>
          </div>
        </div>
      )}
      {isUse === true && (
        <div className="uk-width-medium-1-6" id="ttlAccumPrdctQty">
          <div className="md-card TBgA">
            <div className="md-card-content">
              <div className={[styles.dashBoardArea, "FourGridT"].join(" ")}>
                <span className="FourTT">누적생산량</span>
                <br />
                <b>{dashBoard.ttlAccumGelecQty}</b>
                <span className="FourDa">MWh</span>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="uk-width-medium-1-6" id="eficUnit">
        <div className="md-card TBgA">
          <div className="md-card-content">
            <div className={[styles.dashBoardArea, "FourGridT"].join(" ")}>
              <span className="FourTT">평균효율</span>
              <br />
              <b>{dashBoard.ttlEfic}</b>
              <span className="FourDa">{dashBoardUnit.eficUnit}</span>
            </div>
          </div>
        </div>
      </div>
      {isUse === false && (
        <div className="uk-width-medium-1-6" id="ttlGelecQty">
          <div className="md-card TBgA">
            <div className="md-card-content">
              <div className={[styles.dashBoardArea, "FourGridT"].join(" ")}>
                <span className="FourTT">금일발전량</span>
                <br />
                <b>{dashBoard.ttlGelecQty}</b>
                <span className="FourDa">kWh</span>
              </div>
            </div>
          </div>
        </div>
      )}
      {isUse === true && (
        <div className="uk-width-medium-1-6" id="ttlPrdctQty">
          <div className="md-card TBgA">
            <div className="md-card-content">
              <div className={[styles.dashBoardArea, "FourGridT"].join(" ")}>
                <span className="FourTT">금일생산량</span>
                <br />
                <b>{dashBoard.ttlGelecQty}</b>
                <span className="FourDa">kWh</span>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="uk-width-medium-1-6" id="modelCnt">
        <div className="md-card TBgA">
          <div className="md-card-content">
            <div className={[styles.dashBoardArea, "FourGridT"].join(" ")}>
              <span className="FourTT">운영개소</span>
              <br />
              <b>{dashBoard.modelCnt}</b>
              <span className="FourDa">개소</span>
            </div>
          </div>
        </div>
      </div>
      <div className="uk-width-medium-1-6" id="co2ReduQty">
        <div className="md-card TBgA">
          <div className="md-card-content">
            <div className={[styles.dashBoardArea, "FourGridT"].join(" ")}>
              <span className="FourTT">
                CO<sub>2</sub>저감량
              </span>
              <br />
              <b>{dashBoard.co2ReduQty}</b>
              <span className="FourDa">
                tCO<sub>2</sub>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnsoDashBoard;
