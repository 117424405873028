import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { numFormat, roundNum } from "../../../../com/dataUtil";
import styles from "../renewStat.module.css";

const RenewOperCht = (props) => {
  const { chartData } = props;

  let operTotalCnt = 0;
  for (const b in chartData) {
    operTotalCnt = operTotalCnt + chartData[b];
  }
  const chartOpt = {
    chart: {
      type: "pie",
      marginTop: 0,
      spacingTop: 0,
      height: "237px",
      backgroundColor: "white",
      borderRaidus: "5px",
    },
    lang: {
      noData: "데이터가 없습니다.",
    },
    noData: {
      style: {
        fontWeight: "bold",
        fontSize: "15px",
        color: "#303030",
      },
    },
    title: {
      text: null,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        // size: 170,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          // distance: -30,
          crop: true,
          overflow: "allow",
          style: {
            color: "#444444",
            fontSize: "11px",
            textOutline: "0px",
            fontWeight: "400",
            textAlign: "center",
          },
          formatter: function (e) {
            if (this.y !== 0) {
              return "<span>" + this.key + "<br/>" + numFormat(this.y) + "</span>";
            }
          },
        },
      },
    },
    tooltip: {
      shadow: true,
      shared: true,
      // formatter: function () {
      //   let s = "<b>" + this.points[0].key + "</b>";

      //   if (this.points[0]) {
      //     s +=
      //       "<br/>" +
      //       '<span style="color:' +
      //       this.points[0].point.color +
      //       '">\u25CF</span> ' +
      //       this.points[0].series.name +
      //       ": " +
      //       numFormat(roundNum(this.points[0].y, 2)) +
      //       " 개";
      //   }

      //   return s;
      // },
    },
    legend: {
      align: "left",
      layout: "vertical",
      verticalAlign: "middle",
    },
    series: [
      {
        name: "운영현황",
        innerSize: "40%",
        showInLegend: true,
        marker: {
          symbol: "circle",
          radius: 12,
        },
        data: [
          {
            name: "정상",
            color: "#7AB700",
            y: chartData.nmlCnt,
          },
          {
            name: "경고",
            color: "#FFAD01",
            y: chartData.warnCnt,
          },
          {
            name: "고장",
            color: "#FE4A59",
            y: chartData.brkCnt,
          },
          {
            name: "미작동",
            color: "gray",
            y: chartData.offCnt,
          },
          // {
          //   name: "준비중",
          //   y: chartData.rdyCnt,
          //   color: "#0B92CC",
          // },
          {
            name: "미연동",
            y: chartData.noLinkCnt,
            color: "#0B92CC",
            visible: false,
          },
        ],
      },
    ],
  };
  Highcharts.setOptions({
    lang: {
      thousandsSep: ",",
    },
  });
  return (
    <>
      {/* <span className={styles.operChtTitle}>[전체 : {numFormat(operTotalCnt)}개]</span> */}
      <HighchartsReact highcharts={Highcharts} options={chartOpt} />
    </>
  );
};
export default RenewOperCht;
