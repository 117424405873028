//지난주 대비 방문자 수
const LastWeekVisitorCountChart = ({Highcharts ,HighchartsReact , thisWeekChartData , lastWeekChartData}) => {

  // 카테고리
  const category = ["일" , "월" , "화" , "수" , "목" , "금" , "토"];

  // 차트 옵션
  const chartConfig = {
    chart:{ 
      height: 370,
      type: 'line',
      backgroundColor: "#ffffff"
    },
    title: { text: null },
    credits: { enabled: false },
    xAxis: { categories : category },
    yAxis: {
        title: {
            text: '방문자수',
            rotation: 0,
            y: -20,
            offset: 0,
            align: 'high',
        }
        , tickInterval : 2
        , labels : {
          format: '{value:,.0f}',
        }
    },
    legend: {  layout: 'horizontal', align: 'center', verticalAlign: 'top', floating: false },
    plotOptions: {
        series: { label: { connectorAllowed: false } }
    },
    series: [{
      name: '지난주',
      data:lastWeekChartData
    },{
      name: '이번주',
      data:thisWeekChartData  
    }],
    responsive: {
      rules: [{
          condition: { maxWidth: 500 },
          chartOptions: {
              legend: {
                  layout: 'horizontal',
                  align: 'center',
                  verticalAlign: 'bottom'
              }
          }
      }]
    }
  };

  return(
    <>
      <HighchartsReact Highcharts = {Highcharts} options = {chartConfig} />
    </>
  )
}


export default LastWeekVisitorCountChart;