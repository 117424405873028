import kendo from "@progress/kendo-ui";
import { numFormat, roundNum } from "../../../com/dataUtil";
/**
 * 설비 리스트 조회
 *
 * @param  conn , UserId
 * @return 각 에너지원별 수용가의 설비 리스트
 */
export const getMeainListOfUser = async (conn, callback, reject, userId) => {
  await conn
    .get("/stat/userbyuser/meainList", {
      params: {
        userId: userId,
      },
    })
    .then((response) => {
      callback(response);
    })
    .catch((error) => {
      reject(error);
    });
};

/**
 * 설비 클릭 시 데이터 리스트 조회
 *
 * @param  conn , UserId
 * @return 각 에너지원별 수용가의 설비 리스트
 */
export const getDataByMeainInfo = async (conn, callback, reject, search) => {
  // 날짜 설정
  let startGathDtm = search.yesterday;
  let endGathDtm = search.yesterday;

  if (search.dateType === "HH") {
    startGathDtm = kendo.toString(search.startGathDtm, "yyyy-MM-dd");
    endGathDtm = kendo.toString(search.startGathDtm, "yyyy-MM-dd");
  } else if (search.dateType === "DD") {
    startGathDtm = kendo.toString(search.startGathDtm, "yyyy-MM-dd");
    endGathDtm = kendo.toString(search.endGathDtm, "yyyy-MM-dd");
  } else if (search.dateType === "MM") {
    startGathDtm = kendo.toString(search.startGathDtm, "yyyy-MM-01");
    // 마지막 날짜 설정
    const endDate = search.endGathDtm;
    const year = kendo.toString(endDate, "yyyy");
    const month = kendo.toString(endDate, "MM");
    const lastDay = new Date(year, month, 0);
    endGathDtm = kendo.toString(lastDay, "yyyy-MM-dd");
  }
  await conn
    .get("/stat/userbyuser/dataList", {
      params: {
        cid: search.cid,
        userId: search.userId,
        rgnCode: search.rgnCode,
        dongCode: search.dongCode,
        dateType: search.dateType,
        startGathDtm: startGathDtm,
        endGathDtm: endGathDtm,
      },
    })
    .then((response) => {
      //(1) 대시보드 : 값 가공
      if (response.userByDashboardDto.instCapa !== null) {
        response.userByDashboardDto.instCapa = numFormat(roundNum(response.userByDashboardDto.instCapa, 2));
      } else {
        response.userByDashboardDto.instCapa = 0;
      }
      if (response.userByDashboardDto.accumPower !== null) {
        response.userByDashboardDto.accumPower = numFormat(roundNum(response.userByDashboardDto.accumPower, 2));
      } else {
        response.userByDashboardDto.accumPower = 0;
      }
      if (response.userByDashboardDto.dayPower !== null) {
        response.userByDashboardDto.dayPower = numFormat(roundNum(response.userByDashboardDto.dayPower, 2));
      } else {
        response.userByDashboardDto.dayPower = 0;
      }
      if (response.userByDashboardDto.efic !== null) {
        response.userByDashboardDto.efic = numFormat(roundNum(response.userByDashboardDto.efic, 2));
      } else {
        response.userByDashboardDto.efic = 0;
      }
      if (response.userByDashboardDto.co2 !== null) {
        response.userByDashboardDto.co2 = numFormat(roundNum(response.userByDashboardDto.co2, 2));
      } else {
        response.userByDashboardDto.co2 = 0;
      }

      //(2) 시간대별 데이터 : 가공 설정
      const timeCategory = [];
      const timeSeries = [];
      if (response.userByTimeDataResultDto.userByTimeDataDtoList.length > 0) {
        response.userByTimeDataResultDto.userByTimeDataDtoList.forEach((element) => {
          // 그래프 값 가공 설정 (소수점 자리수 같이 설정)
          timeCategory.push(element.gathDtm);
          element.dayEnergy = element.dayEnergy !== null ? roundNum(element.dayEnergy, 2) : 0;
          element.efic = element.efic !== null ? roundNum(element.efic, 2) : 0;
          element.instCapaPerPower = element.instCapaPerPower !== null ? roundNum(element.instCapaPerPower, 2) : 0;
          element.co2 = element.co2 !== null ? roundNum(element.co2, 2) : 0;
          timeSeries.push({
            y: element.dayEnergy,
            efic: element.efic,
            instCapaPerPower: element.instCapaPerPower,
            co2: element.co2,
          });

          // 그리드의 3자리 콤마 설정
          element.dayEnergy = numFormat(element.dayEnergy);
          element.efic = numFormat(element.efic);
          element.instCapaPerPower = numFormat(element.instCapaPerPower);
          element.co2 = numFormat(element.co2);
        });
      }
      //  그래프 값 설정
      response.userByTimeDataResultDto.timeCategory = timeCategory;
      response.userByTimeDataResultDto.timeSeries = timeSeries;

      //(3) 발전량 분포 차트 카테고리 설정
      const areaXcategory = [];
      areaXcategory.push(response.userByAreaDto.wholeDataList[0].wholeTerm !== null ? response.userByAreaDto.wholeDataList[0].wholeTerm : "");
      areaXcategory.push(response.userByAreaDto.rgnDataList[0].rgnTerm !== null ? response.userByAreaDto.rgnDataList[0].rgnTerm : "");
      areaXcategory.push(response.userByAreaDto.dongDataList[0].dongTerm !== null ? response.userByAreaDto.dongDataList[0].dongTerm : "");
      areaXcategory.push(response.userByAreaDto.userDataList[0].userTerm !== null ? response.userByAreaDto.userDataList[0].userTerm : "");

      // (3) 발전량 분포 - 에너지원별 배열
      const enso15001 = [];
      const enso15002 = [];
      const enso15003 = [];
      const enso15004 = [];
      const enso15006 = [];
      const enso15007 = [];

      // (3) 발전량 분포 - 최종 배열 초기화
      const areaChartSeries = [];

      // (3) 발전량 분포 - 값 설정
      response.userByAreaDto.wholeDataList.forEach((element) => {
        if (element.ensoTypeCode === 15001) {
          enso15001.push(element.instCapaPerDayPower !== null ? roundNum(element.instCapaPerDayPower, 2) : 0);
        } else if (element.ensoTypeCode === 15002) {
          enso15002.push(element.instCapaPerDayPower !== null ? roundNum(element.instCapaPerDayPower, 2) : 0);
        } else if (element.ensoTypeCode === 15003) {
          enso15003.push(element.instCapaPerDayPower !== null ? roundNum(element.instCapaPerDayPower, 2) : 0);
        } else if (element.ensoTypeCode === 15004) {
          enso15004.push(element.instCapaPerDayPower !== null ? roundNum(element.instCapaPerDayPower, 2) : 0);
        } else if (element.ensoTypeCode === 15005) {
          enso15006.push(element.instCapaPerDayPower !== null ? roundNum(element.instCapaPerDayPower, 2) : 0);
        } else if (element.ensoTypeCode === 15006) {
          enso15007.push(element.instCapaPerDayPower !== null ? roundNum(element.instCapaPerDayPower, 2) : 0);
        }
      });
      response.userByAreaDto.rgnDataList.forEach((element) => {
        if (element.ensoTypeCode === 15001) {
          enso15001.push(element.instCapaPerDayPower !== null ? roundNum(element.instCapaPerDayPower, 2) : 0);
        } else if (element.ensoTypeCode === 15002) {
          enso15002.push(element.instCapaPerDayPower !== null ? roundNum(element.instCapaPerDayPower, 2) : 0);
        } else if (element.ensoTypeCode === 15003) {
          enso15003.push(element.instCapaPerDayPower !== null ? roundNum(element.instCapaPerDayPower, 2) : 0);
        } else if (element.ensoTypeCode === 15004) {
          enso15004.push(element.instCapaPerDayPower !== null ? roundNum(element.instCapaPerDayPower, 2) : 0);
        } else if (element.ensoTypeCode === 15005) {
          enso15006.push(element.instCapaPerDayPower !== null ? roundNum(element.instCapaPerDayPower, 2) : 0);
        } else if (element.ensoTypeCode === 15006) {
          enso15007.push(element.instCapaPerDayPower !== null ? roundNum(element.instCapaPerDayPower, 2) : 0);
        }
      });
      response.userByAreaDto.dongDataList.forEach((element) => {
        if (element.ensoTypeCode === 15001) {
          enso15001.push(element.instCapaPerDayPower !== null ? roundNum(element.instCapaPerDayPower, 2) : 0);
        } else if (element.ensoTypeCode === 15002) {
          enso15002.push(element.instCapaPerDayPower !== null ? roundNum(element.instCapaPerDayPower, 2) : 0);
        } else if (element.ensoTypeCode === 15003) {
          enso15003.push(element.instCapaPerDayPower !== null ? roundNum(element.instCapaPerDayPower, 2) : 0);
        } else if (element.ensoTypeCode === 15004) {
          enso15004.push(element.instCapaPerDayPower !== null ? roundNum(element.instCapaPerDayPower, 2) : 0);
        } else if (element.ensoTypeCode === 15005) {
          enso15006.push(element.instCapaPerDayPower !== null ? roundNum(element.instCapaPerDayPower, 2) : 0);
        } else if (element.ensoTypeCode === 15006) {
          enso15007.push(element.instCapaPerDayPower !== null ? roundNum(element.instCapaPerDayPower, 2) : 0);
        }
      });
      response.userByAreaDto.userDataList.forEach((element) => {
        if (element.ensoTypeCode === 15001) {
          enso15001.push(element.instCapaPerDayPower !== null ? roundNum(element.instCapaPerDayPower, 2) : 0);
        } else if (element.ensoTypeCode === 15002) {
          enso15002.push(element.instCapaPerDayPower !== null ? roundNum(element.instCapaPerDayPower, 2) : 0);
        } else if (element.ensoTypeCode === 15003) {
          enso15003.push(element.instCapaPerDayPower !== null ? roundNum(element.instCapaPerDayPower, 2) : 0);
        } else if (element.ensoTypeCode === 15004) {
          enso15004.push(element.instCapaPerDayPower !== null ? roundNum(element.instCapaPerDayPower, 2) : 0);
        } else if (element.ensoTypeCode === 15005) {
          enso15006.push(element.instCapaPerDayPower !== null ? roundNum(element.instCapaPerDayPower, 2) : 0);
        } else if (element.ensoTypeCode === 15006) {
          enso15007.push(element.instCapaPerDayPower !== null ? roundNum(element.instCapaPerDayPower, 2) : 0);
        }
      });

      if (enso15001.length > 0) {
        areaChartSeries.push({ name: "태양열", data: enso15001 });
      }
      if (enso15002.length > 0) {
        areaChartSeries.push({ name: "태양열", data: enso15002 });
      }
      if (enso15003.length > 0) {
        areaChartSeries.push({ name: "지열", data: enso15003 });
      }
      if (enso15004.length > 0) {
        areaChartSeries.push({ name: "풍력", data: enso15004 });
      }
      if (enso15006.length > 0) {
        areaChartSeries.push({ name: "연료전지", data: enso15006 });
      }
      if (enso15007.length > 0) {
        areaChartSeries.push({ name: "ESS", data: enso15007 });
      }

      // (3) 발전량 분포 - 그래프 데이터 가공 후 response 객체에 설정
      response.areaChartSeries = areaChartSeries;
      response.areaXcategory = areaXcategory;

      //(4) 설비별 발전량 리스트 : 값 가공
      let meainListNo = 0;
      response.userByMeainInfoDtoList.forEach((element) => {
        meainListNo = 1 + meainListNo;
        element.no = meainListNo;
        element.instCapa = element.instCapa !== null ? numFormat(roundNum(element.instCapa, 2)) + " kW" : 0;
        element.dayPower = element.dayPower !== null ? numFormat(roundNum(element.dayPower, 2)) + " kWh" : 0;
        element.instCapaPerPower = element.instCapaPerPower !== null ? numFormat(roundNum(element.instCapaPerPower, 2)) + " kWh/kW.day" : 0;
      });

      callback(response);
    })
    .catch((error) => {
      reject(error);
    });
};
