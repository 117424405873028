// React
import React, { useCallback, useMemo } from "react";
import log from "../../../com/log";

// Kendo
import { Dialog } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";
import { Error } from "@progress/kendo-react-labels";

// Css
import "./groupManagerModal.css";
import { pwdRegex, phoneRegex, emailRegex } from "./../../../com/regexUtil";

const GroupManagerModal = ({ modalState, setModalState, rgnList, handleModalSaveSubmit, handleModalUpdateSubmit }) => {
  const smsUseYn = ["Y", "N"];
  const emailUseYn = ["Y", "N"];

  // 지역 Converter
  const rgnInfo = rgnList.filter((e) => e.rgnCode === modalState.formItem.rgnCode);
  rgnInfo.length !== 0 ? (modalState.formItem.rgnInfo = rgnInfo[0]) : (modalState.formItem.rgnInfo = { rgnCode: "", rgnTermSimple: "주소검색" });
  // servrRgnInfo Converter
  if (typeof modalState.formItem.servrRgnInfo !== "undefined") {
    if (modalState.formItem.servrRgnInfo.length !== 0) {
      const servrRgnInfoArray = [];
      const servrRgnInfo = modalState.formItem.servrRgnInfo;
      servrRgnInfo.forEach((element) => {
        const rgnData = rgnList.filter((e) => e.rgnTermSimple === element)[0];
        servrRgnInfoArray.push({ rgnCode: rgnData.rgnCode, rgnTermSimple: rgnData.rgnTermSimple, rgnTerm: rgnData.rgnTermSimple });
      });
      modalState.formItem.servrRgnList = servrRgnInfoArray;
    }
  }

  // password Validation
  const PwdInput = useCallback((fieldRenderProps) => {
    const { validationMessage, visited, ...others } = fieldRenderProps;
    return (
      <div>
        <Input {...others} />
        {visited && validationMessage && <Error>{validationMessage}</Error>}
      </div>
    );
  });

  // phon Validation
  const PhoneInput = useCallback((fieldRenderProps) => {
    const { validationMessage, visited, ...others } = fieldRenderProps;
    return (
      <div>
        <Input {...others} />
        {visited && validationMessage && <Error>{validationMessage}</Error>}
      </div>
    );
  });

  // Email Validation
  const EmailInput = useCallback((fieldRenderProps) => {
    const { validationMessage, visited, ...others } = fieldRenderProps;
    return (
      <div>
        <Input {...others} />
        {visited && validationMessage && <Error>{validationMessage}</Error>}
      </div>
    );
  });

  // 닫기, 취소 이벤트
  const handleClose = useCallback(() => {
    setModalState({ ...modalState, visible: !modalState.visible, title: "", formItem: [] });
  }, [modalState]);

  // 저장 버튼
  const handleSubmit = useCallback((e) => {
    modalState.title === "추가" ? handleModalSaveSubmit(e) : handleModalUpdateSubmit(e);
  });

  return (
    <>
      <Dialog title={modalState.title} onClose={handleClose}>
        <div className="managerModalForm">
          <div className="leftModalItem">
            <div>ID</div>
            <div>담당자</div>
            <div>시/군</div>
            <div>패스워드</div>
            <div>연락처</div>
            <div>이메일</div>
            <div>SMS 수신여부</div>
            <div>EMAIL 수신여부</div>
          </div>
          <div className="rightModalItem">
            <Form
              onSubmit={handleSubmit}
              initialValues={modalState.formItem}
              render={(formRenderProps) => (
                <FormElement>
                  <div>
                    <Field name="userId" component={Input} placeholder="아이디 입력" readOnly={modalState.formItem.userId ? true : false} required />
                  </div>
                  <div>
                    <Field name="userTerm" component={Input} placeholder="담당자 이름 입력" />
                  </div>
                  <div>
                    <Field
                      name="rgnInfo"
                      component={DropDownList}
                      data={rgnList}
                      textField="rgnTermSimple"
                      dataItemKey="rgnCode"
                      defaultValue={{ rgnCode: "", rgnTermSimple: "주소검색" }}
                    />
                  </div>
                  <div>
                    <Field name="loginPwd" type="password" component={PwdInput} placeholder="8자 이상 영문,숫자,특수문자 입력" />
                  </div>
                  <div>
                    <Field name="userCelphon" type="tel" component={PhoneInput} placeholder="01012345678" />
                  </div>
                  <div>
                    <Field name="userEmail" type="email" component={EmailInput} placeholder="myname@example.com" />
                  </div>
                  <div>
                    <Field name="smsNotiUseYn" component={DropDownList} data={smsUseYn} defaultValue={smsUseYn[0]} />
                  </div>
                  <div>
                    <Field name="emailNotiUseYn" component={DropDownList} data={emailUseYn} defaultValue={emailUseYn[0]} />
                  </div>
                  <div>
                    <Button onClick={handleClose}>취소</Button>
                    <Button type="submit" disabled={!formRenderProps.allowSubmit}>
                      저장
                    </Button>
                  </div>
                </FormElement>
              )}
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default GroupManagerModal;
