
// 설비 운영현황 
const MeainCount = ({statCnt}) => {

  return(
    <div>
      <div className="Ch-Map"></div>
      <div className="ChMapTb-A">
        <table>
          <tbody>
            <tr>
              <th>검색설비 운영현황({statCnt.totalCnt})</th>
            </tr>
            <tr>
              <td className="textGre">&nbsp;&nbsp;정&nbsp;&nbsp;&nbsp;&nbsp;상&nbsp;({statCnt.normalCnt })</td>
            </tr>
            <tr>
              <td className="textGry">&nbsp;&nbsp;미작동&nbsp;({statCnt.unOperCnt})</td>
            </tr>
            <tr>
              <td className="textBl">&nbsp;&nbsp;준비중&nbsp;({statCnt.readyCnt})</td>
            </tr>
            <tr>
              <td className="textYl">&nbsp;&nbsp;경&nbsp;&nbsp;&nbsp;&nbsp;고&nbsp;({statCnt.warnCnt})</td>
            </tr>
            <tr>
              <td className="textRd">&nbsp;&nbsp;고&nbsp;&nbsp;&nbsp;&nbsp;장&nbsp;({statCnt.errorCnt})</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default MeainCount;