import { numFormat } from "../../../com/dataUtil";
import DatePickerKo from "../../com/dateInputs/datePickerKo";

// 일자별 세션 차트 와 날짜 선택 영역
const DailySessionChart = ({styles , Highcharts, HighchartsReact, dailySessionChartData , search, setSearch , dailySessionChartCategory , getDailySessionDataList }) => {
  
  // 차트 옵션
  const chartConfig = {
    chart:{ 
      height: 335,
      type: 'spline',
      backgroundColor: "#ffffff",
      width: 753
    },
    title: { text: null },
    credits: { enabled: false },
    xAxis: { categories : dailySessionChartCategory },
    yAxis: {
        title: {
            text: '세션 갯수',
            rotation: 0,
            y: -20,
            offset: 0,
            align: 'high',
        }
        , labels : {
          format: '{value:,.0f}',
        }
    },
    tooltip: {
      formatter: function () {
        let label = "";
        label += '<b>' + this.point.name + '</b>'; 
        label += '<br/>'+ "<b>" + "session : "  +"</b>" + + numFormat(this.point.y);
        return label;
      }
    },
    legend: {  layout: 'horizontal', align: 'center', verticalAlign: 'top', floating: false },
    plotOptions: {
        series: { label: { connectorAllowed: false } }
    },
    series: [{
      name: 'session 갯수',
      data: dailySessionChartData
    }],
    responsive: {
      rules: [{
          condition: { maxWidth: 500 },
          chartOptions: {
              legend: {
                  layout: 'horizontal',
                  align: 'center',
                  verticalAlign: 'bottom'
              }
          }
      }]
    }
  };

  return(
    <>
      <HighchartsReact Highcharts = {Highcharts} options = {chartConfig} />
      <div className = {styles.dateContainer}>
        <span>검색 시작 날짜 : </span>
        <DatePickerKo id = "start" name = "start" className = "datePicker" defaultValue={search.today} format = "yyyy-MM-dd " 
            value={search.startGathDtm}
            readonly={true}
            max={search.startMax}
            setDtm={(v) => {
              search.endMin = v;
              search.startGathDtm = v;
              getDailySessionDataList();
            }}
          /> 
        <span className="textColorGr">&nbsp;&nbsp;~&nbsp;&nbsp;</span>
        <span>검색 종료 날짜 : </span>
        <DatePickerKo id = "end" name = "end" className = "datePicker" defaultValue={search.today} format = "yyyy-MM-dd" 
          min={search.endMin}
          max={search.today}
          readonly={true}
          setDtm={(v) => {
            search.startMax = v;
            search.endGathDtm = v;
            getDailySessionDataList();
          }}
        />
      </div>	
    </> 
  );
}

export default DailySessionChart;