import * as React from "react";

export const InEditCommandCell = (props) => {
  const { dataItem } = props;
  const inEdit = dataItem[props.editField];
  const isNewItem = props.isNewItem;

  return inEdit ? (
    <td style={{ textAlign: "center" }}>
      <a onClick={() => (isNewItem ? props.add(dataItem) : props.update(dataItem))}>
        <img src="/images/icon_save.png" alt="" style={{ paddingLeft: "10px", paddingRight: "10px" }} />
      </a>
      <a onClick={() => (isNewItem ? props.discard(dataItem) : props.cancel(dataItem))}>
        {isNewItem ? (
          <img src="/images/icon_del.png" alt="" style={{ paddingLeft: "10px", paddingRight: "10px" }} />
        ) : (
          <img src="/images/icon_x.png" alt="" style={{ paddingLeft: "10px", paddingRight: "10px" }} />
        )}
      </a>
    </td>
  ) : (
    <td>
      <a onClick={() => props.edit(dataItem)}>
        <img src="/images/icon_su.png" alt="" style={{ paddingLeft: "10px", paddingRight: "10px" }} />
      </a>
      <a onClick={() => props.remove(dataItem)}>
        <img src="/images/icon_del.png" alt="" style={{ paddingLeft: "10px", paddingRight: "10px" }} />
      </a>
    </td>
  );
};
