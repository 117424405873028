import kendo from "@progress/kendo-ui";
import { roundNum, numFormat, reduceTCo2 } from "../../com/dataUtil";
const reportConsmUrl = "/report/consm";

export const getEnergyMonth = async (conn, callback, reject, search) => {
  await conn
    .get(reportConsmUrl + "/list", {
      params: {
        dtm: kendo.toString(search.dtm, "yyyy-MM-dd"),
        dtmMonth: kendo.toString(search.dtm, "MM"),
        dtmYear: kendo.toString(search.dtm, "yyyy"),
        consmId: search.consmId,
      },
    })
    .then((response) => {
      const result = response;
      const powerMonth = [];
      let totalPower = 0;
      let totalCal = 0;

      // 발전량 생산열량 누적 값 구하기
      let accumPower = 0;
      let accumCal = 0;

      result.forEach((element) => {
        if (element.ensoTypeCode === 15001 || element.ensoTypeCode === 15004 || element.ensoTypeCode === 15006) {
          totalPower += element.energy !== null ? element.energy : 0;
          accumPower += element.accumPower !== null ? element.accumPower : 0;
        }
        if (element.ensoTypeCode === 15002 || element.ensoTypeCode === 15003) {
          totalCal += element.energy !== null ? element.energy : 0;
          accumCal += element.accumPower !== null ? element.accumPower : 0;
        }
      });

      powerMonth.push({
        totalPower: numFormat(roundNum(totalPower, 2)),
        accumPower: numFormat(roundNum(accumPower, 2)),
        powerTime: 0,
        powerCo2: accumPower !== null ? reduceTCo2(roundNum(accumPower, 2) * 0.46553) : 0,
        powerEfic: 0,
        totalCal: numFormat(roundNum(totalCal, 2)),
        accumCal: numFormat(roundNum(accumCal, 2)),
      });

      callback(powerMonth);
    })
    .catch((error) => {
      reject("수용가 보고서", error);
    });
};

export const getEnergyCount = async (conn, callback, reject, search) => {
  await conn
    .get(reportConsmUrl + "/count", {
      params: {
        dtm: kendo.toString(search.dtm, "yyyy-MM-dd"),
        consmId: search.consmId,
      },
    })
    .then((response) => {
      const result = response;
      const powerCount = [];
      let solaCnt = 0;
      let solaCapa = 0;
      let heatCnt = 0;
      let heatCapa = 0;
      let heathCnt = 0;
      let heathCapa = 0;
      let veloCnt = 0;
      let veloCapa = 0;
      let fuelcellCnt = 0;
      let fuelcellCapa = 0;

      result.forEach((element) => {
        if (element.ensoTypeCode === 15001) {
          solaCnt = element.count !== null ? element.count : 0;
          solaCapa = element.instCapa !== null ? element.instCapa : 0;
        }
        if (element.ensoTypeCode === 15002) {
          heatCnt = element.count !== null ? element.count : 0;
          heatCapa = element.instCapa !== null ? element.instCapa : 0;
        }
        if (element.ensoTypeCode === 15003) {
          heathCnt = element.count !== null ? element.count : 0;
          heathCapa = element.instCapa !== null ? element.instCapa : 0;
        }
        if (element.ensoTypeCode === 15004) {
          veloCnt = element.count !== null ? element.count : 0;
          veloCapa = element.instCapa !== null ? element.instCapa : 0;
        }
        if (element.ensoTypeCode === 15006) {
          fuelcellCnt = element.count !== null ? element.count : 0;
          fuelcellCapa = element.instCapa !== null ? element.instCapa : 0;
        }
      });

      powerCount.push({
        totalCnt: numFormat(solaCnt + heatCnt + heathCnt + veloCnt + fuelcellCnt),
        totalCapa: numFormat(roundNum(solaCapa + heatCapa + heathCapa + veloCapa + fuelcellCapa, 2)),
        solaCnt: solaCnt,
        solaCapa: numFormat(roundNum(solaCapa, 2)),
        heatCnt: heatCnt,
        heatCapa: numFormat(roundNum(heatCapa, 2)),
        heathCnt: heathCnt,
        heathCapa: numFormat(roundNum(heathCapa, 2)),
        veloCnt: veloCnt,
        veloCapa: numFormat(roundNum(veloCapa, 2)),
        fuelcellCnt: fuelcellCnt,
        fuelcellCapa: numFormat(roundNum(fuelcellCapa, 2)),
        essCnt: 0,
        essCapa: 0,
      });

      callback(powerCount);
    })
    .catch((error) => {
      reject("수용가 보고서", error);
    });
};

export const getEnergyWarn = async (conn, callback, reject, search) => {
  // 마지막 날짜 설정
  const today = new Date();
  const year = kendo.toString(search.dtm, "yyyy");
  const month = kendo.toString(search.dtm.getMonth() + 1, "MM");
  const todayYear = kendo.toString(today.getFullYear(), "yyyy");
  const todayMonth = kendo.toString(today.getMonth() + 1, "MM");
  let date;

  if (year !== todayYear || month !== todayMonth) {
    date = new Date(year, month, 0);
  } else {
    date = today;
  }

  await conn
    .get(reportConsmUrl + "/warn", {
      params: {
        dtm: kendo.toString(search.dtm, "yyyy-MM-dd"),
        monthStart: kendo.toString(search.dtm, "yyyy-MM-01"),
        monthEnd: kendo.toString(date, "yyyy-MM-dd"),
        consmId: search.consmId,
      },
    })
    .then((response) => {
      const result = response;
      const energyWarn = {
        unOperCnt: [],
        waringCnt: [],
        errorCnt: [],
      };

      result.forEach((element) => {
        energyWarn.unOperCnt.push({ name: element.dtm.substring(8, 10), y: element.unOperCnt });
        energyWarn.waringCnt.push({ name: element.dtm.substring(8, 10), y: element.waringCnt });
        energyWarn.errorCnt.push({ name: element.dtm.substring(8, 10), y: element.errorCnt });
      });

      callback(energyWarn);
    })
    .catch((error) => {
      reject("수용가 보고서", error);
    });
};

export const getPowerCompare = async (conn, callback, reject, search) => {
  await conn
    .get(reportConsmUrl + "/chart", {
      params: {
        dtm: kendo.toString(search.dtm, "yyyy-MM-dd"),
        dtmMonth: kendo.toString(search.dtm, "MM"),
        dtmYear: kendo.toString(search.dtm, "yyyy"),
        consmId: search.consmId,
      },
    })
    .then((response) => {
      const result = response;
      const chartMonth = { energy: [], preEnergy: [] };
      const chartYearh = { energy: [], preEnergy: [] };
      const gridList = [];

      const gridData = response;
      // 그리드 데이터에 단위 넣기
      gridData.forEach((element) => {
        gridList.push({
          dtm: element.dtm.substring(8, 10) + "일",
          monthPower: element.monthPower !== null ? numFormat(roundNum(element.monthPower, 2)) : 0,
          preMonthPower: element.preMonthPower !== null ? numFormat(roundNum(element.preMonthPower, 2)) : 0,
          increaseMonthRate:
            element.preMonthPower !== 0 && element.monthPower !== 0
              ? parseFloat((((element.monthPower - element.preMonthPower) / element.preMonthPower) * 100).toFixed(2))
              : 0,

          yearPower: element.yearPower !== null ? numFormat(roundNum(element.yearPower, 2)) : 0,
          preYearPower: element.preYearPower !== null ? numFormat(roundNum(element.preYearPower, 2)) : 0,
          increaseYearRate:
            element.preYearPower !== 0 && element.yearPower !== 0
              ? parseFloat((((element.yearPower - element.preYearPower) / element.preYearPower) * 100).toFixed(2))
              : 0,
        });
      });

      result.forEach((element) => {
        // 전월 비교
        element.monthPower = element.monthPower !== null ? element.monthPower : 0;
        element.preMonthPower = element.preMonthPower !== null ? element.preMonthPower : 0;

        // 전년비교
        element.yearPower = element.yearPower !== null ? element.yearPower : 0;
        element.preYearPower = element.preYearPower !== null ? element.preYearPower : 0;

        // 전월 차트 데이터
        chartMonth.energy.push({ name: element.dtm.substring(8, 10), y: parseFloat(element.monthPower.toFixed(2)) });
        chartMonth.preEnergy.push({ name: element.dtm.substring(8, 10), y: parseFloat(element.preMonthPower.toFixed(2)) });

        // 전년 차트 데이터
        chartYearh.energy.push({ name: element.dtm.substring(8, 10), y: parseFloat(element.yearPower.toFixed(2)) });
        chartYearh.preEnergy.push({ name: element.dtm.substring(8, 10), y: parseFloat(element.preYearPower.toFixed(2)) });
      });

      callback(gridList, chartMonth, chartYearh);
    })
    .catch((error) => {
      reject("수용가 보고서", error);
    });
};
