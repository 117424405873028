import React, { useState, useEffect } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import kendo from "@progress/kendo-ui";
import { yearList, portList, communicationMthdList, cmnctMdlList } from "../../../com/dropDownDs.js";
import User_info_list from "./user_info_list";
import styles from "./meain.module.css";
import { Input } from "@progress/kendo-react-inputs";
import DatePickerKo from "../../com/dateInputs/datePickerKo.jsx";

// 설비 정보 공통 컨트롤러
function Meain_info_com({ conn, paging, setMeainInfo, codeList, rtuEnteList, inputValidMsg, rgnList, dongList, searchUserId, itemRender }) {
  const selectMeain = paging.selectMeain;
  const [bizSct, setBizSct] = useState(selectMeain.bizSct ? { codeNo: selectMeain.bizSct, codeVal: "" } : { codeNo: "", codeVal: "" });
  const [rtuEnte, setRtuEnte] = useState(
    selectMeain.bizSct ? { rtuEnteCode: selectMeain.rtuEnteCode, rtuEnteTermAndCode: "" } : { rtuEnteCode: "", rtuEnteTermAndCode: "" },
  );

  //데이터 소스(드롭다운 : 사업구분)
  const bizSctTestDs = codeList.filter((c) => c.grpCodeNo === 33);

  // 날짜 포맷 지정
  const dateFormat = (date) => {
    if (typeof date !== "undefined") {
      if (date !== "") {
        date = new Date(date);
      } else {
        date = "";
      }
    } else {
      date = "";
    }
    return date;
  };

  // 통신방식에 따른 컴포넌트 Disable / Enable
  const isLteTcpId =
    selectMeain.communicationMthd === "TCP/IP(OPC-UA)" ||
    selectMeain.communicationMthd === "LTE" ||
    selectMeain.communicationMthd === "HTTPS" ||
    selectMeain.communicationMthd === "HTTPS(LTE, WI-FI)"||
    selectMeain.communicationMthd === "기타(운영준비중)" ||
    selectMeain.communicationMthd === "서버 연계"
      ? true
      : false;
  const isLoRaDevid = selectMeain.communicationMthd === "LoRa(SKT)" ? true : false;
  const isNbDevid = selectMeain.communicationMthd === "NBIoT(KT)" || selectMeain.communicationMthd === "NBIoT(LG)" ? true : false;

  // 드롭다운 값 변경 이벤트 (Single 데이터 버전)
  const onchageDropdown = (e) => {
    if (e.target.name === "communicationMthd") {
      // 입력된 다른 통신방식의 값을 초기화 시킨다.
      selectMeain.nbDevid = "";
      selectMeain.loraDevid = "";
    }
    const objName = e.target.name;
    const obj = {
      ...selectMeain,
      [objName]: e.value,
    };
    setMeainInfo(obj);
  };

  // Input 데이터 값 변경 이벤트
  const onchageInput = (e) => {
    const objName = e.target.name;
    const obj = {
      ...selectMeain,
      [objName]: e.target.value,
    };
    setMeainInfo(obj);
  };

  // Date 데이터 값 변경 이벤트
  const onchageDate = (v, name) => {
    // 값변경한 부분에 대해서 추적하여 객체 이름 기입하기 위해 name 값 가져오기
    const meainInfo = {
      ...selectMeain,
      [name]: kendo.toString(v, "yyyy-MM-dd"),
    };
    setMeainInfo(meainInfo);
  };

  // Converter 사업구분
  const bizSctConverter = () => {
    if (Object.keys(selectMeain).length !== 0) {
      let codeVal = "";
      let bizSct = "";
      bizSctTestDs.forEach((element) => {
        if (element.codeNo === Number(selectMeain.bizSct)) {
          bizSct = element.codeNo;
          codeVal = element.codeVal;
        }
      });
      setBizSct({ bizSct: bizSct, codeVal: codeVal });
    } else {
      setBizSct({ bizSct: "", codeVal: "" });
    }
  };

  // Converter RTU업체
  const rtuEnteConverter = () => {
    if (Object.keys(selectMeain).length !== 0) {
      let rtuEnteCode = "";
      let rtuEnteTermAndCode = "";
      rtuEnteList.forEach((element) => {
        if (element.rtuEnteCode === selectMeain.rtuEnteCode) {
          rtuEnteCode = element.rtuEnteCode;
          rtuEnteTermAndCode = element.rtuEnteTermAndCode;
        }
      });
      setRtuEnte({ rtuEnteCode: rtuEnteCode, rtuEnteTermAndCode: rtuEnteTermAndCode });
    } else {
      setRtuEnte({ rtuEnteCode: "", rtuEnteTermAndCode: "" });
    }
  };

  useEffect(() => {
    // props의 데이터를 각각 데이터 소스 객체에 맞게 Convert 작업 해준다.
    bizSctConverter();
    rtuEnteConverter();
  }, [selectMeain]);

  return (
    <table className="Ch-TableN">
      <tr>
        <th colSpan="6">
          <div></div>
        </th>
      </tr>
      <tr>
        <th>CID</th>
        <td colSpan="3">
          <input
            id="cid"
            type="text"
            className={styles.searchTermTBC}
            placeholder="자동생성 됩니다."
            style={{ width: "96%" }}
            readOnly
            value={selectMeain.cid ? selectMeain.cid : ""}
          />
        </td>
        <th>사업연도</th>
        <td>
          <DropDownList
            className="meainInfoDropDown"
            id="bizYy"
            name="bizYy"
            data={yearList()}
            value={selectMeain.bizYy ? selectMeain.bizYy : ""}
            required={true}
            validationMessage={inputValidMsg.normalText}
            itemRender={itemRender}
            onChange={onchageDropdown}
          ></DropDownList>
        </td>
      </tr>
      <tr>
        <th colSpan="6">
          <div></div>
        </th>
      </tr>
      <tr>
        <th>수용가</th>
        <td>
          <div className={styles.SetSearch}>
            <Input
              id="userTerm"
              type="text"
              className={styles.SetSearchTerm}
              // className = "SetSearchTerm"
              placeholder="수용가"
              readOnly
              value={selectMeain.userTerm ? selectMeain.userTerm : ""}
            />
          </div>
          <div className={styles.SetSearch}>
            <a icon="search" className={styles.searchBtn} onClick={searchUserId}>
              <img className={styles.searchImg} src={"/images/icon_d.png"} alt="검색버튼" />
            </a>
          </div>
        </td>
        <th>설치계획서번호</th>
        <td>
          <Input
            id="instlPlnnm"
            name="instlPlnnm"
            type="text"
            className={styles.searchTermTBC}
            value={selectMeain.instlPlnnm ? selectMeain.instlPlnnm : ""}
            // required={true} // 설치 계획서 번호 validation 에서 제외
            // validationMessage={inputValidMsg.normalText}
            onChange={onchageInput}
          />
        </td>
        <th>사업구분</th>
        <td>
          <DropDownList
            className="meainInfoDropDown"
            id="bizSct"
            name="bizSct"
            data={bizSctTestDs}
            value={bizSct}
            required={true}
            validationMessage={inputValidMsg.normalText}
            dataItemKey="codeNo"
            textField="codeVal"
            valid={selectMeain.bizSct !== "" && selectMeain.bizSct !== null && typeof selectMeain.bizSct !== "undefined"}
            onChange={(e) => {
              const obj = { ...selectMeain, bizSct: e.value.codeNo };
              setMeainInfo(obj);
            }}
            itemRender={itemRender}
          />
        </td>
      </tr>
      <tr>
        <th colSpan="6">
          <div></div>
        </th>
      </tr>
      <tr>
        <th>설치확인일</th>
        <td>
          <DatePickerKo
            id="instCnfmDtm"
            name="instCnfmDtm"
            className="meainDatePicker"
            value={dateFormat(selectMeain.instCnfmDtm)}
            format="yyyy-MM-dd"
            formatPlaceholder={{ year: "년", month: "월", day: "일" }}
            setDtm={(v) => {
              onchageDate(v, "instCnfmDtm");
            }}
          ></DatePickerKo>
        </td>
        <th>설치확인번호</th>
        <td>
          <input
            id="instCnfrmCode"
            name="instCnfrmCode"
            type="text"
            className={styles.searchTermTBC}
            // value = {instCnfrmCode}
            value={selectMeain.instCnfrmCode ? selectMeain.instCnfrmCode : ""}
            onChange={onchageInput}
          />
        </td>
        <th>최종접속일시</th>
        <td>
          <input
            id="finalAcceDtm"
            name="finalAcceDtm"
            type="text"
            className={styles.searchTermTBC}
            value={selectMeain.finalAcceDtm ? selectMeain.finalAcceDtm : ""}
            readOnly={true}
          ></input>
        </td>
      </tr>
      <tr>
        <th colSpan="6">
          <div></div>
        </th>
      </tr>
      <tr>
        <th>최종수신일시</th>
        <td>
          <input
            id="finalRcpmsgDtm"
            name="finalRcpmsgDtm"
            type="text"
            className={styles.searchTermTBC}
            value={selectMeain.finalRcpmsgDtm ? selectMeain.finalRcpmsgDtm : ""}
            readOnly={true}
          ></input>
        </td>
        <th>RTU 업체</th>
        <td>
          <DropDownList
            className="meainInfoDropDown"
            id="rtuEnteCode"
            name="rtuEnteCode"
            data={rtuEnteList}
            value={rtuEnte}
            required={true}
            validationMessage={inputValidMsg.normalText}
            dataItemKey="rtuEnteCode"
            textField="rtuEnteTermAndCode"
            onChange={(e) => {
              const obj = { ...selectMeain, rtuEnteCode: e.value.rtuEnteCode };
              setMeainInfo(obj);
            }}
            valid={selectMeain.rtuEnteCode !== "" && selectMeain.rtuEnteCode !== null && typeof selectMeain.rtuEnteCode !== "undefined"}
            itemRender={itemRender}
          />
        </td>
        <th>RTU 업체 연락처</th>
        <td>
          <input
            id="rtuEnteCntct"
            name="rtuEnteCntct"
            type="text"
            className={styles.searchTermTBC}
            value={selectMeain.rtuEnteCntct ? selectMeain.rtuEnteCntct : ""}
            placeholder="하이픈(-) 없이 입력해주세요."
            onChange={onchageInput}
          />
        </td>
      </tr>
      <tr>
        <th colSpan="6">
          <div></div>
        </th>
      </tr>
      <tr>
        <th>통신방식</th>
        <td>
          <DropDownList
            className="meainInfoDropDown"
            id="communicationMthd"
            name="communicationMthd"
            data={communicationMthdList()}
            value={selectMeain.communicationMthd ? selectMeain.communicationMthd : ""}
            onChange={onchageDropdown}
            required={true}
            validationMessage={inputValidMsg.normalText}
            itemRender={itemRender}
          ></DropDownList>
        </td>
        <th>통신모듈</th>
        <td>
          <DropDownList
            className="meainInfoDropDown"
            id="cmnctMdl"
            name="cmnctMdl"
            data={cmnctMdlList()}
            value={selectMeain.cmnctMdl ? selectMeain.cmnctMdl : ""}
            required={true}
            validationMessage={inputValidMsg.normalText}
            itemRender={itemRender}
            onChange={onchageDropdown}
          ></DropDownList>
        </td>

        {isLteTcpId === true && (
          <>
            <th className="lteTcpIdClass">인증키</th>
            <td className="lteTcpIdClass">
              <input
                id="lteTcpId"
                name="lteTcpId"
                type="text"
                className={styles.searchTermTBC}
                value={selectMeain.token ? selectMeain.token : ""}
                placeholder="자동생성 됩니다"
                onChange={onchageInput}
                readOnly
              />
            </td>
          </>
        )}
      </tr>
      <tr>
        <th colSpan="6">
          <div></div>
        </th>
      </tr>
      <tr>
        {isLoRaDevid === true && (
          <>
            <th className="loraDevidClass">LoRa ID</th>
            <td className="loraDevidClass">
              <input
                id="loraDevid"
                name="loraDevid"
                type="text"
                className={styles.searchTermTBC}
                value={selectMeain.loraDevid ? selectMeain.loraDevid : ""}
                onChange={onchageInput}
              />
            </td>
          </>
        )}
        {isLteTcpId === true && (
          <>
            <th className="nbDevidClass">IMEI</th>
            <td className="nbDevidClass">
              <input
                id="nbDevid"
                name="nbDevid"
                type="text"
                className={styles.searchTermTBC}
                value={selectMeain.nbDevid ? selectMeain.nbDevid : ""}
                onChange={onchageInput}
              />
            </td>
          </>
        )}
        {isNbDevid === true && (
          <>
            <th className="tokenClass">토큰</th>
            <td className="tokenClass">
              <input
                id="token"
                name="token"
                type="text"
                className={styles.searchTermTBC}
                value={selectMeain.token ? selectMeain.token : ""}
                placeholder="자동생성 됩니다"
                onChange={onchageInput}
                readOnly
              />
            </td>
          </>
        )}
      </tr>
      <tr>
        <th colSpan="6">
          <div></div>
        </th>
      </tr>
      <tr>
        <th>Multi</th>
        <td>
          <DropDownList
            className="meainInfoDropDown"
            id="port"
            name="port"
            value={selectMeain.port !== null || typeof selectMeain.port !== "undefined" ? selectMeain.port : ""}
            data={portList()}
            itemRender={itemRender}
            onChange={onchageDropdown}
          ></DropDownList>
        </td>
        <th></th>
        <td></td>
        <th></th>
        <td></td>
      </tr>
      <tr>
        <th colSpan="6">
          <div></div>
        </th>
      </tr>
      <tr>
        <th>주소</th>
        <td colSpan="5">
          <Input
            id="address"
            name="address"
            type="text"
            className={styles.searchTermTBC}
            value={selectMeain.address ? selectMeain.address : ""}
            onChange={onchageInput}
            required={true}
            validationMessage={inputValidMsg.normalText}
          />
        </td>
      </tr>
      <tr>
        <th colSpan="6">
          <div></div>
        </th>
      </tr>
      <tr>
        <th>위도</th>
        <td>
          <Input
            id="lati"
            name="lati"
            type="number"
            className={styles.searchTermTBC}
            value={selectMeain.lati ? selectMeain.lati : ""}
            required={true}
            validationMessage={inputValidMsg.number6Fix}
            step="0.000001"
            onChange={onchageInput}
          />
        </td>
        <th>경도</th>
        <td>
          <Input
            id="longi"
            name="longi"
            type="number"
            className={styles.searchTermTBC}
            value={selectMeain.longi ? selectMeain.longi : ""}
            required={true}
            validationMessage={inputValidMsg.number6Fix}
            step="0.000001"
            onChange={onchageInput}
          />
        </td>
        <th></th>
      </tr>
      <tr>
        <th colSpan="6">
          <div></div>
        </th>
      </tr>
    </table>
  );
}

export default Meain_info_com;
