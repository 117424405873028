import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import queryString from "query-string";
import { getRawDataList } from "../../../service/stats/raw/rawDataService";
import { getUser, isInstaller } from "../../../service/authService";
import { getMeainModlMakr } from "../../../service/meain/meain/meainService";
import { getRtu } from "../../../service/mngt/rtuEnte/rtuService";

export const useGathRawLog = ({ conn }) => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [isSearched, setIsSearched] = useState(false);
  const { cid, ensoType, rgnCode, startGathDtm, endGathDtm, makrSeriNo, meainTypeSeriNo, rtuEnteCode } = queryString.parse(location.search);
  const hasParam = [cid, ensoType, rgnCode, startGathDtm, endGathDtm, makrSeriNo, meainTypeSeriNo, rtuEnteCode].some((item) => ![undefined, ""].includes(item));
  let today = new Date();

  const [search, setSearch] = useState({
    ensoTypeCode: ensoType ? Number(ensoType) : 15001,
    ensoType: { codeNo: "15001", codeVal: "태양광" },
    rgnCode: rgnCode ? rgnCode : "",
    rgn: { rgnCode: "", rgnTermSimple: "시/군/구" },
    fixbylawBundCode: "",
    startGathDtm: startGathDtm ? startGathDtm : new Date(today.setDate(today.getDate() - 1)),
    endGathDtm: endGathDtm ? endGathDtm : new Date(),
    makrSeriNo: makrSeriNo ? makrSeriNo : "",
    makr: { makrTerm: "제조사", makrSeriNo: "" },
    meainModl: { meainTypeTerm: "설비 모델", meainTypeSeriNo: "" },
    meainTypeSeriNo: meainTypeSeriNo ? meainTypeSeriNo : "",
    rtuEnteCode: rtuEnteCode ? rtuEnteCode : "",
    rtu: { rtuEnteTerm: "RTU 업체", rtuEnteCode: "" },
    cidUserTerm: cid ? (isInstaller() ? getUser().userTerm : cid) : "",
    search: cid ? true : false,
  });
  const [filter, setFilter] = useState({ ...search });
  const [paging, setPage] = useState({
    skip: 0,
    take: 20,
    sort: [{ field: "gathDtm", dir: "desc" }],
  });
  const [data, setData] = useState({
    count: 0,
    list: [],
  });
  const [rtuList, setRtuList] = useState([]);
  const [makrMeainModelList, setMakrMeainModelList] = useState({
    makrList: [],
    meainModelList: [],
  });

  const fetchData = () => {
    setLoading(true);
    setIsSearched(true);
    setFilter({ ...search });
    getRawDataList({
      conn,
      callback: (result) => {
        setData((prev) => {
          const item = { ...prev };
          item.count = result.total;
          item.list = result.results;
          return item;
        });
      },
      reject: (error) => console.log(error),
      param: search,
      paging,
      setLoading,
    });
  };

  useEffect(() => {
    if (hasParam) fetchData();
    getMeainModlMakr(conn, (result) => {
      setMakrMeainModelList((makrMeainModelList) => {
        const item = { ...makrMeainModelList };
        item.makrList = result.makrDtoList;
        item.meainModelList = result.meainModelDtoList;
        return item;
      });
    });
    getRtu(conn)
      .then((response) => {
        setRtuList(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return {
    search,
    setSearch,
    filter,
    paging,
    setPage,
    fetchData,
    loading,
    setLoading,
    data,
    isSearched,
    makrMeainModelList,
    rtuList,
  };
};
