import { ListView } from "@progress/kendo-react-listview";
import EnsoTypeItem from "./ensoTypeItem";
import styles from "./installerItem.module.css";

// 카카오 적용
const { kakao } = window;

// 좌측 수용가별 목록
const InstallerItem = ({ dataItem }) => {
  const ensoList = dataItem.installer;

  // 맵 이동 이벤트
  const movePostEvt = (e) => {
    let lati = dataItem.lati;
    let longi = dataItem.longi;
    const map = dataItem.map;
    if (map) {
      // lati , longi 값이 빈값으로 들어오면 천안시청으로 이동
      if (!lati) {
        lati = 36.81508;
      }
      if (!longi) {
        longi = 127.11392;
      }
      const moveLocation = new kakao.maps.LatLng(lati, longi);
      map.setCenter(moveLocation);
    }
  };

  return (
    <>
      <table className={styles.installerEnsoListTable}>
        <thead>
          <tr>
            <th colSpan="2">
              {" "}
              <a className={styles.movePosTerm} onClick={movePostEvt}>
                {dataItem.userTerm}
              </a>{" "}
            </th>
            <td>{dataItem.sumCount}개소</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan="3">
              {ensoList.length !== 0 ? (
                <ListView data={ensoList} item={EnsoTypeItem} onChange={movePostEvt} />
              ) : (
                <span className="none-data">데이터가 존재하지 않습니다.</span>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default InstallerItem;
