import kendo from "@progress/kendo-ui";
import { downloadExcel } from "../../../com/downloadExcel";

export const getBpmRpsDataList = async (conn, callback, reject, param, paging, setLoading) => {
  let url = "meain/bpmRps/page";
  if (param.bizYy == "사업연도") param.bizYy = null;
  await conn
    .get(url, {
      params: {
        ensoType: param.ensoType.codeNo,
        bizSct: param.bizSct.codeNo,
        bizYy: param.bizYy,
        rgnCode: param.rgn.rgnCode,
        row: paging.skip,
        size: paging.take,
      },
    })
    .then((response) => {
      setLoading(false);
      callback(response);
    })
    .catch((error) => {
      setLoading(false);
      reject(error);
    });
};

export const getRpsBpmExcelDownload = (param, paging, setLoading) => {
  // 엑셀 다운로드 진행 할 검색 + 페이징 파라미터 통합 본
  const search = {
    ensoType: param.ensoType.codeNo,
    bizSct: param.bizSct.codeNo,
    bizYy: param.bizYy,
    rgnCode: param.rgn.rgnCode,
    row: paging.skip,
    size: paging.take,
  };

  // URL 설정
  let url = "/api/v2.2/meain/bpmRps";
  const today = kendo.toString(new Date(), "yyyyMMdd");
  let excelTitle = "국비지원_RPS_설비_" + today;

  try {
    downloadExcel(search, excelTitle, url, setLoading);
  } catch (error) {
    setLoading(false);
    kendo.alert("엑셀 다운로드를 실패 하였습니다.");
    return;
  }
};

/**
 * ### BPM RPS 설비관리 - 추가, 수정
 */
export const setUpdateBpmRps = async (conn, data) => {
  let url = "meain/bpmRps";
  const config = {
    headers: { "Content-Type": "application/json" },
  };
  const result = await conn.post(
    url,
    JSON.stringify(new Array(data)),
    config
  );

  return result;
};

/**
 * 위의 함수와 같은 함수를 사용했더니 제대로 실행이 안됨
 * 이미 배열의 형태로 가져와서 그런 것으로 추정이 되어 JSON 화 하지 않고 그냥 가져온 상태로 보냈더니 정상작동하여 함수를 분리
 */
export const setUpdateBpmRpsExcel = async (conn, data) => {
  let url = "meain/bpmRps";
  const config = {
    headers: { "Content-Type": "application/json" },
  };
  const result = await conn.post(
    url,
    data,
    // JSON.stringify(new Array(data)),
    config
  );

  return result;
};

export const setDeleteBpmRps = async (conn, data) => {
  let url = "meain/bpmRps";
  const result = await conn.delete(
    url, {
    headers: { "Content-Type": "application/json" },
    data: JSON.stringify(data),
  });

  return result;
}

/**
 * BPM , RPS , 연동 설비 엑셀 다운로드
 * @param {*} setLoading 
 * @returns 
 */
export const getRpsBpmSyncMeainListExcelDownload = (setLoading) => {
  // 엑셀 다운로드 진행 할 검색 + 페이징 파라미터 통합 본
  const search = {};

  // URL 설정
  let url = "/api/v2.2/meain/bpmRps/all";
  const today = kendo.toString(new Date(), "yyyyMMdd");
  let excelTitle = "국비지원_RPS_연동 설비 전체 리스트_" + today;

  try {
    downloadExcel(search, excelTitle, url, setLoading);
  } catch (error) {
    setLoading(false);
    kendo.alert("엑셀 다운로드를 실패 하였습니다.");
    return;
  }
};