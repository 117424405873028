import { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Search } from "@material-ui/icons";
import { Window } from "@progress/kendo-react-dialogs";

import "./installerMeainItem.css";
// import './installerMeainItem.dk.css';
import styles from "./installerMeainItem.module.css";
import { numFormat, roundNum } from "../../../com/dataUtil";

const InstallerMeainItem = (props) => {
  const isMobile = localStorage.getItem("isMobile");
  const history = useHistory();
  let item = props.dataItem;
  const innerWidth = window.innerWidth;
  const innerHeight = window.innerHeight;

  const [state, setState] = useState({
    visible: false,
    leftPosition: 200,
    rightPosition: 200,
  });

  const meainList = [
    { cid: "1111111111", dayPower: 1111, meainStat: 7001 },
    { cid: "1111111112", dayPower: 2222, meainStat: 7002 },
    { cid: "1111111113", dayPower: 3333, meainStat: 7006 },
  ];

  const viewMeainInfo = (e) => {
    let x = e.clientX || 200;
    let y = e.clientY || 200;
    if (isMobile === "Y") {
      x = 0;
      y = innerHeight * 0.2;
    } else {
      if (x > 1000 && x < 1400) x = 1000;
      else if (x > 1400) x = 1200;
      if (y > 600 && y < 700) y = 600;
      else if (y > 700) y = 620;
    }
    setState({
      ...state,
      visible: !state.visible,
      leftPosition: x,
      rightPosition: y,
    });
  };

  const goGathInfo = (e) => {
    if (item.meainStatCodeNo === 7001) alert("운영 준비중인 설비 입니다.");
    else history.push("/monitor/installerGath?installerId=" + item.consmId + "&ensoType=" + item.ensoTypeCode);
  };

  const goOperHistory = (e) => {
    if (item.meainStatCodeNo === 7001) alert("운영 준비중인 설비 입니다.");
    else history.push("/logs/gathRawData?cid=" + item.cid + "&ensoType=" + item.ensoTypeCode);
  };

  const selectMeain = (e) => {
    setState({
      ...state,
      selected: e.selected,
    });
  };

  const getTitleStyle = useCallback((meainStatCodeNo) => {
    switch (meainStatCodeNo) {
      case 7001:
        return styles.ready;
      case 7002:
        return styles.normal;
      case 7004:
        return styles.unOper;
      case 7006:
        return styles.warn;
      case 7007:
        return styles.error;
      default:
        return styles.unOper;
    }
  });

  const getEnsoImgSrc = useCallback((ensoTypeCode, meainStatCodeNo) => `/images/enso/icon_${ensoTypeCode}_${meainStatCodeNo}_Big.png`);

  const getEnsoItem = () => {
    if (item.ensoTypeCode == 15001) {
      return (
        <tr>
          <th>금일 발전량</th>
          <td>{item.dayPower} kWh</td>
          <th>누적 발전량</th>
          <td>{item.accumPower ? numFormat(roundNum(item.accumPower, 2)) : 0} kWh</td>
        </tr>
      );
    } else {
    }
  };
  const getEnsoPower = () => {
    if (item.ensoTypeCode == 15001 || item.ensoTypeCode == 15004 || item.ensoTypeCode == 15006) {
      return <th>금일발전량</th>;
    } else if (item.ensoTypeCode == 15002 || item.ensoTypeCode == 15003) {
      return <th>금일사용량</th>;
    } else if (item.ensoTypeCode == 15007) {
      return <th>금일충전량</th>;
    }
  };

  const getDayValue = () => {
    let value = 0;
    if (item.ensoTypeCode == 15001 || item.ensoTypeCode == 15004 || item.ensoTypeCode == 15006) {
      value = item.dayPower ? item.dayPower : 0;
    } else if (item.ensoTypeCode == 15002 || item.ensoTypeCode == 15003) {
      value = item.dayUse ? item.dayUse : 0;
    } else if (item.ensoTypeCode == 15007) {
      value = item.dayPower ? item.dayPower : 0;
    }
    return value;
  };

  return (
    <div className="uk-width-medium-1-5" style={{ display: "inline-block", paddingLeft: "35px", marginTop: "10px" }}>
      <table className="installerMeain">
        <thead>
          <tr>
            <th rowSpan="2">
              <img className={styles.blankImg} src={getEnsoImgSrc(item.ensoTypeCode, item.meainStatCodeNo)} alt="" />
            </th>
            <td colSpan="3" className={getTitleStyle(item.meainStatCodeNo)}>
              {item.userTerm}
            </td>
          </tr>
          <tr>
            <th onClick={viewMeainInfo} className={styles.infoClick}>
              설비정보 <Search className="material-icons" />
            </th>
            <th onClick={goGathInfo} className={styles.infoClick}>
              계측정보 <Search className="material-icons" />
            </th>
            <th onClick={goOperHistory} className={styles.infoClick}>
              운전이력 <Search className="material-icons" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>사업구분</th>
            <td>{item.bizSctTerm || "-"}</td>
            <th>건물용도</th>
            <td>{item.bldSrvTerm || "-"}</td>
          </tr>
          <tr>
            <th>지역</th>
            <td>{item.rgnTerm}</td>
            <th>설치날짜</th>
            <td>{item.meainInstDtm}</td>
          </tr>
          <tr>
            <th>설비용량</th>
            <td>{item.instCapa ? item.instCapa + " kW" : ""}</td>
            {getEnsoPower()}
            <td>{getDayValue()} kWh</td>
          </tr>
        </tbody>
      </table>
      {state.visible && (
        <Window
          className="installerMeain-info"
          title={item.userTerm}
          onClose={viewMeainInfo}
          height={isMobile === "Y" ? innerHeight * 0.5 : 280}
          width={isMobile === "Y" ? innerWidth : 500}
          left={state.leftPosition}
          top={state.rightPosition}
          doubleClickStageChange={false}
          minimizeButton={"none"}
          maximizeButton={"none"}
          stage={"DEFAULT"}
        >
          <table className="installerMeainPopW">
            <tbody>
              <tr>
                <th>CID</th>
                <td colSpan="3">{item.cid}</td>
              </tr>
              <tr>
                <th>위치</th>
                <td colSpan="3">{item.address ? item.address : "-"}</td>
              </tr>
              <tr>
                <th>사업구분</th>
                <td>{item.bizSctTerm}</td>
                <th>사업연도</th>
                <td>{item.bizYy ? item.bizYy : "-"}</td>
              </tr>
              <tr>
                <th>설비용량</th>
                <td>{item.instCapa ? item.instCapa : "-"} kW</td>
                <th>설비 상태</th>
                <td>{item.meainStatVal ? item.meainStatVal : "-"}</td>
              </tr>
              {getEnsoItem()}
              <tr>
                <th>설치일시</th>
                <td>{item.meainInstDtm ? item.meainInstDtm : "-"}</td>
                <th>최종수신일시</th>
                <td>{item.finalRcpmsgDtm ? item.finalRcpmsgDtm.replace("T", " ") : "-"}</td>
              </tr>
              <tr>
                <th>특이사항</th>
                <td colSpan="3">{item.etc ? item.etc : "-"}</td>
              </tr>
            </tbody>
          </table>
        </Window>
      )}
    </div>
  );
};
export default InstallerMeainItem;
