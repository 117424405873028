import styles from "./userInfo.module.css";
import "./userInfo.css";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { useState } from "react";
import TimePickerKo from "./../../com/dateInputs/timePickerKo";

const UserInfoData = ({ userInfo, setUserInfoData, saveUserInfo, timeChngEvt }) => {
  // 체크 박스 이벤트
  const checkboxOnchageEvt = (e) => {
    setUserInfoData(e.target.checked, e.target.name, "checkbox");
  };

  // 체크 박스 이벤트(이벤트 수신여부 요일)
  const checkboxOnchageWeekendsEvt = (e) => {
    setUserInfoData(e.target.checked, e.target.name, "weekends");
  };

  // 입력 TEXT
  const inputOnchageEvt = (e) => {
    setUserInfoData(e.target.value, e.target.name, "input");
  };

  return (
    <>
      <div className="usrInfoLeftCont">
        <table className={styles.produceTb}>
          <tbody>
            <tr>
              <td>
                <b>비밀번호 변경(수정 필요시 입력)</b>
                <br></br>
                <div>
                  <input
                    className="search-input"
                    name="loginPwd"
                    style={{ width: "600px" }}
                    type="password"
                    placeholder="*************"
                    onChange={inputOnchageEvt}
                    value={userInfo.loginPwd ? userInfo.loginPwd : ""}
                  />
                </div>
                <div>
                  <input
                    className="search-input"
                    name="loginPwdCheck"
                    style={{ width: "600px" }}
                    type="password"
                    placeholder="비밀번호 확인"
                    onChange={inputOnchageEvt}
                    value={userInfo.loginPwdCheck ? userInfo.loginPwdCheck : ""}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <b>이메일</b>
                <br></br>
                <input
                  className="search-input"
                  name="userEmail"
                  style={{ width: "600px" }}
                  type="email"
                  placeholder="sr@screen.co.kr"
                  onChange={inputOnchageEvt}
                  value={userInfo.userEmail ? userInfo.userEmail : ""}
                />
              </td>
            </tr>
            <tr>
              <td>
                <b>핸드폰번호</b>
                <br></br>
                <input
                  className="search-input"
                  name="userCelphon"
                  style={{ width: "600px" }}
                  type="tel"
                  placeholder="010-1234-5678"
                  onChange={inputOnchageEvt}
                  value={userInfo.userCelphon ? userInfo.userCelphon : ""}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="usrInfoRightCont">
        <table className={styles.produceTb}>
          <tbody>
            <tr>
              <td>
                <b>알림 수신여부</b>
                <br></br>
                <input
                  type="checkbox"
                  name="kakaoNotiUseYn"
                  className={styles.checkBoxForm}
                  onChange={checkboxOnchageEvt}
                  checked={userInfo.kakaoNotiUseYn === "Y" ? true : false}
                />
                <label>알림톡(카카오)</label>&nbsp;&nbsp;&nbsp;&nbsp;
                {/* <input type="checkbox" name="smsNotiUseYn" className={styles.checkBoxForm} onChange={checkboxOnchageEvt} checked={userInfo.smsNotiUseYn === "Y" ? true : false} /> */}
                {/* <label>문자(SMS)</label>&nbsp;&nbsp;&nbsp;&nbsp; */}
                {/* <input
                  type="checkbox"
                  name="emailNotiUseYn"
                  className={styles.checkBoxForm}
                  onChange={checkboxOnchageEvt}
                  checked={userInfo.emailNotiUseYn === "Y" ? true : false}
                />
                <label>이메일</label>&nbsp;&nbsp;&nbsp;&nbsp; */}
              </td>
            </tr>
            <tr>
              <td>
                <b>알림 수신시간</b>
                <br></br>
                <input type="checkbox" id="c1" name="sun" className={styles.checkBoxForm} onChange={checkboxOnchageWeekendsEvt} checked={userInfo.sun === "1" ? true : false} />
                <label>일요일</label>&nbsp;&nbsp;&nbsp;&nbsp;
                <input type="checkbox" id="c2" name="mon" className={styles.checkBoxForm} onChange={checkboxOnchageWeekendsEvt} checked={userInfo.mon === "1" ? true : false} />
                <label>월요일</label>&nbsp;&nbsp;&nbsp;&nbsp;
                <input type="checkbox" id="c3" name="tues" className={styles.checkBoxForm} onChange={checkboxOnchageWeekendsEvt} checked={userInfo.tues === "1" ? true : false} />
                <label>화요일</label>&nbsp;&nbsp;&nbsp;&nbsp;
                <input
                  type="checkbox"
                  id="c4"
                  name="wednes"
                  className={styles.checkBoxForm}
                  onChange={checkboxOnchageWeekendsEvt}
                  checked={userInfo.wednes === "1" ? true : false}
                />
                <label>수요일</label>&nbsp;&nbsp;&nbsp;&nbsp;
                <input type="checkbox" id="c5" name="thurs" className={styles.checkBoxForm} onChange={checkboxOnchageWeekendsEvt} checked={userInfo.thurs === "1" ? true : false} />
                <label>목요일</label>&nbsp;&nbsp;&nbsp;&nbsp;
                <input type="checkbox" id="c6" name="fri" className={styles.checkBoxForm} onChange={checkboxOnchageWeekendsEvt} checked={userInfo.fri === "1" ? true : false} />
                <label>금요일</label>&nbsp;&nbsp;&nbsp;&nbsp;
                <input type="checkbox" id="c7" name="satur" className={styles.checkBoxForm} onChange={checkboxOnchageWeekendsEvt} checked={userInfo.satur === "1" ? true : false} />
                <label>토요일</label>&nbsp;&nbsp;&nbsp;&nbsp;
                {/* <input type="checkbox" id="c8" name="none" className={styles.checkBoxForm} onChange = {checkboxOnchageEvt} />
      <label>수신안함</label> */}
                {/* <br></br><input className="search-input" type="number" name = "notiRcpmsgTm" min = {0} max = {23}  /> */}
                {/* <br></br><input className={styles.notiRcpmsgTm} type="number" name = "notiRcpmsgTm" min = {0} max = {23} step = {1} /> */}
                <br></br>
                {/* <NumericTextBox type="number" min={0} max={23} step={1} name="notiRcpmsgTm" onChange={inputOnchageEvt} value={userInfo.notiRcpmsgTm} format="0시" /> */}
                <TimePickerKo format="HH:mm" name="notiRcpmsgStart" value={userInfo.notiRcpmsgStart} onChange={timeChngEvt} max={userInfo.notiRcpmsgEnd}></TimePickerKo>
                &nbsp;~&nbsp;
                <TimePickerKo format="HH:mm" name="notiRcpmsgEnd" value={userInfo.notiRcpmsgEnd} onChange={timeChngEvt} min={userInfo.notiRcpmsgStart}></TimePickerKo>
              </td>
            </tr>
            <tr>
              <td>
                <Button className="saveUserInfo" icon="save" onClick={saveUserInfo}>
                  저장
                </Button>
              </td>
            </tr>
            {/* <tr>
  <td>
      <b>홈페이지 이벤트 수신여부</b><br></br>
      <input type="checkbox" id="d1" name="lisenNotiUseYn" className={styles.checkBoxForm} onChange = {checkboxOnchageEvt} checked = {userInfo.lisenNotiUseYn === 'Y' ? true : false} />
      <label>가시</label>&nbsp;&nbsp;&nbsp;&nbsp;
      <input type="checkbox" id="d2" name="ausenNotiUseYn" className={styles.checkBoxForm} onChange = {checkboxOnchageEvt} checked = {userInfo.ausenNotiUseYn === 'Y' ? true : false} />
      <label>가청</label>&nbsp;&nbsp;&nbsp;&nbsp;
  </td>
</tr>
<tr>
  <td>
      <b>* 홈페이지 이벤트는 발생 즉시 가시, 가청 형태로 제공됩니다.</b><br></br>
      <b>* 가시 - 이벤트 발생시 우측 하단 알림창 표시</b><br></br>
      <b>* 가청 - 이벤트 발생시 알림음 발생(스피커 등 음향장치 필요)</b>
  </td>
</tr> */}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default UserInfoData;
