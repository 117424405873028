import jwt_decode from "jwt-decode";

export const authGuest = async (conn, status, user) => {
  const userInfo = user;
  const response = await conn.get("login", {
    params: {
      status: status,
      userId: userInfo ? userInfo.userId : null,
    },
  });
  return response;
};

export const login = async (conn, id, loginPwd) => {
  const response = await conn.post("login", {
    userId: id,
    password: loginPwd,
  });
  return response;
};

// 새로운 계정의 패스워드 등록 시 사용 서비스
export const setNewPassword = async (conn, id, loginPwd) => {
  const response = await conn.post("/user/new/password", {
    userId: id,
    password: loginPwd,
  });
  return response;
};

export const getUser = () => {
  const token = localStorage.getItem("atk");
  if (token) {
    const decode = jwt_decode(token);
    return {
      userId: decode.sub,
      userTerm: decode.userTerm,
      userTypeCode: decode.userTypeCode,
      userCelphone: decode.userCelphone,
      exp: decode.exp,
      iat: decode.iat,
      userMngRgnList: decode.userMngRgnList,
      userRtuEnteCode: decode.userRtuEnteCode,
      rgnCode: decode.rgnCode,
    };
  }
  return null;
};

export const getToken = () => localStorage.getItem("atk") || null;
export const setToken = (token) => localStorage.setItem("atk", token);
export const setDelToken = () => localStorage.removeItem("atk");

export const getUserTypeCode = () => {
  const token = localStorage.getItem("atk");
  if (token) {
    const decode = jwt_decode(token);
    return decode.userTypeCode;
  }
  return null;
};

export const getUserMngRgnList = () => {
  const token = localStorage.getItem("atk");
  if (token) {
    const decode = jwt_decode(token);
    return decode.userMngRgnList;
  }
  return null;
};

export const getUserRtuEnteCode = () => {
  const token = localStorage.getItem("atk");
  if (token) {
    const decode = jwt_decode(token);
    return decode.userRtuEnteCode;
  }
  return null;
};

export const isAuthenticated = () => {
  if (!localStorage.getItem("atk")) return false;
  const user = jwt_decode(localStorage.getItem("atk"));
  if (
    user &&
    (user.userTypeCode === 2001 || user.userTypeCode === 2002 || user.userTypeCode === 2003 || user.userTypeCode === 2004 || user.userTypeCode === 2005 || user.userTypeCode === 2007)
  )
    return true;
  else return false;
};

export const isLogin = () => {
  if (!localStorage.getItem("atk")) return false;
  const user = jwt_decode(localStorage.getItem("atk"));
  if (
    user &&
    (user.userTypeCode === 2001 || user.userTypeCode === 2002 || user.userTypeCode === 2003 || user.userTypeCode === 2005 || user.userTypeCode === 2007)
  )
    return true;
  else return false;
};

export const isAdmin = () => {
  const token = localStorage.getItem("atk");
  if (!token) return false;
  const user = jwt_decode(token);
  if (user.userTypeCode === 2003 || user.userTypeCode === 2005) return true;
  else return false;
};

export const isManager = () => {
  const token = localStorage.getItem("atk");
  if (!token) return false;
  const user = jwt_decode(token);
  if (user.userTypeCode === 2002) return true;
  else return false;
};

export const isOneManager = () => {
  const token = localStorage.getItem("atk");
  if (!token) return false;
  const user = jwt_decode(token);
  if (user.userTypeCode === 2002 && user.userMngRgnList.length === 1) return true;
  else return false;
};

export const isRtuUser = () => {
  const token = localStorage.getItem("atk");
  if (!token) return false;
  const user = jwt_decode(token);
  if (user.userTypeCode === 2007) return true;
  else return false;
};

export const isInstaller = () => {
  const token = localStorage.getItem("atk");
  if (!token) return false;
  const user = jwt_decode(token);
  if (user.userTypeCode === 2001) return true;
  else return false;
};
