import React from "react";
import { useState, useEffect, memo } from "react";

import { DropDownList as DropDown } from "@progress/kendo-react-dropdowns";
import { Grid, GridColumn as Column, GridNoRecords } from "@progress/kendo-react-grid";
import { num, reduceTCo2, wood, isPowerEnsoType, numFormat, roundNum } from "../../../com/dataUtil";
import { parseDate } from "../../../com/dateUtil";
import GathInfo from "./gathInfo";

import "./installerGath.css";
import styles from "./installerGath.module.css";

// 로딩 스피너
import { BeatLoader } from "react-spinners";

import {
  getLatestGathEnergy,
  getHourlyEnergyList,
  getAreaEnergyList,
  getLatestEventList,
  getWeekEnergyList,
  getInstaller,
  getInstallerMeainList,
  getInstallerEnsoList,
  getInstallerEnergy,
} from "../../../service/monitor/installerGathService";
// from '../../../service/monitor/installerGathServiceTest'

import WeekEnergyChart from "./weekEnergyChart";
import HourEnergyChart from "./hourEnergyChart";
import AreaEnergyChart from "./areaEnergyChart";

import { useLocation } from "react-router";
import queryString from "query-string";

/**
 *  수용가 계측 정보
 **/
const InstallerGath = memo(({ conn, codeList, rgnList, dongList }) => {
  const isMobile = localStorage.getItem("isMobile");
  const location = useLocation();
  const { installerId, ensoType } = queryString.parse(location.search);

  if (!installerId || !ensoType) {
    window.location.href = "/monitor/areaEnergy";
    alert("선택된 수용가 정보가 없습니다.");
  }

  // 로딩 스피너
  const [loading, setLoading] = useState(false);

  // const installerId = 'test1';
  const [ensoTypeCode, setEnsoTypeCode] = useState(ensoType);
  const [state, setState] = useState({ installer: {}, ensoList: [] });
  const [energy, setEnergy] = useState({});
  const [meainList, setMeainList] = useState([]);
  const [weekEnergyList, setWeekEnergyList] = useState([]); // 주간 발전량/소비량
  const [latestGath, setLatestGath] = useState([]); // 실시간 계측정보
  const [hourEnergyList, setHourEnergyList] = useState([]); // 금일 시간당 발전량/소비량 추이
  const [areaEnergyList, setAreaEnergyList] = useState([]); // 지역별 발전량/소비량 비교
  const [latestEventList, setLatestEventList] = useState([]); // 최근 설비상태(이벤트) 이력
  const [diagramPath, setDiagramPath] = useState("");
  const meainDefaultValue = { term: "설비전체", cid: 0 };

  const setEnsoDiagramPath = (enso) => {
    if (enso.ensoTypeCode == 15002) setDiagramPath(`/images/diagram/schema_${enso.ensoTypeCode}_${enso.sysMode}.gif`);
    else setDiagramPath(`/images/diagram/schema_${enso.ensoTypeCode}.gif`);
  };

  const setInstallerGath = (selectedEnsoTypeCode, selectedCid) => {
    setLoading(true);
    Promise.all([
      //
      getInstallerEnergy(conn, installerId, selectedEnsoTypeCode, selectedCid),
      getWeekEnergyList(conn, installerId, selectedEnsoTypeCode, selectedCid),
      getLatestGathEnergy(conn, installerId, selectedEnsoTypeCode, selectedCid),
      getHourlyEnergyList(conn, installerId, selectedEnsoTypeCode, selectedCid),
      getAreaEnergyList(conn, installerId, selectedEnsoTypeCode, selectedCid),
      getLatestEventList(conn, installerId, selectedEnsoTypeCode, selectedCid),
    ]).then((results) => {
      setEnergy({ ...results[0], ...{ latestGathDtm: results[2].latestGathDtm } });
      setEnsoDiagramPath(results[0]);
      setWeekEnergyList(results[1]);
      setLatestGath(results[2].gath);
      setHourEnergyList(results[3]);
      setAreaEnergyList(results[4]);
      setLatestEventList(results[5]);
      setLoading(false);
    });
  };

  const [meain, setMeain] = useState(meainDefaultValue);

  const setEnsoChangeEvt = (e) => {
    let selectedEnsoTypeCode = e.target.value.ensoTypeCode;
    setEnsoTypeCode(selectedEnsoTypeCode);
    getInstallerMeainList(conn, installerId, selectedEnsoTypeCode).then((meainList) => {
      setMeainList([...[meainDefaultValue], ...meainList]);
      setMeain(meainDefaultValue);
      setInstallerGath(selectedEnsoTypeCode, "");
    });
  };

  const setMeainChangeEvt = (e) => {
    let selectedCid = e.target.value.cid;
    setMeain(meainList.find((v) => v.cid == selectedCid));
    setInstallerGath(ensoTypeCode, selectedCid);
  };

  useEffect(() => {
    setLoading(true);
    Promise.all([
      //
      getInstaller(conn, installerId),
      getInstallerEnsoList(conn, installerId),
      // , getInstallerMeainList(conn, installerId, ensoTypeCode)
    ]).then((results) => {
      setState({
        ...state,
        installer: results[0],
        ensoList: results[1],
      });
      setEnsoTypeCode(ensoType);
      getInstallerMeainList(conn, installerId, ensoType).then((meainList) => {
        setMeainList([...[meainDefaultValue], ...meainList]);
        setMeain(meainDefaultValue);
        setInstallerGath(ensoType, "");
      });

      // setMeainList(results[2]);
      // setInstallerGath(ensoType, '');
    });
  }, []);

  const ensoTypeInfo = (energy) => {
    if (ensoTypeCode == 15001 || ensoTypeCode == 15004 || ensoTypeCode == 15006)
      return (
        <th className="installerGathTBgA">
          <div>금일발전량</div>
          <br />
          <b>{energy.dayEnergy && numFormat(num(energy.dayEnergy))}</b>
          <span>kWh</span>
          <br />
          <small style={{ float: "left", fontSize: "11px" }}>금월 {energy.monthEnergy && numFormat(num(energy.monthEnergy))} kWh</small>
          {isMobile === "Y" ? <br></br> : null}
          <small style={{ float: "left", fontSize: "11px" }}>금년 {energy.yearEnergy && numFormat(num(energy.yearEnergy))}kWh</small>
          <small style={{ float: "left", fontSize: "11px" }}>누적 {energy.monthEnergy && numFormat(roundNum(energy.accumEnergy / 1000, 2))} MWh</small>
        </th>
      );
    else if (ensoTypeCode == 15002 || ensoTypeCode == 15003) {
      if (energy.sysMode === 29002) {
        return (
          <th className="installerGathTBgA">
            <div>금일사용량</div>
            <br />
            <b>{energy.dayUse && numFormat(num(energy.dayUse))}</b>
            <span>kWh</span>
            <br />
            <small>금월&nbsp;&nbsp; 미제공 </small>
            <small>금년&nbsp;&nbsp; 미제공 </small>
          </th>
        );
      } else {
        return (
          <th className="installerGathTBgA">
            <div>금일생산량</div>
            <br />
            <b>{energy.dayEnergy && numFormat(num(energy.dayEnergy))}</b>
            <span>kWh</span>
            <br />
            <small>금월&nbsp;&nbsp; {energy.monthEnergy && numFormat(num(energy.monthEnergy))} MWh</small>
            <small>금년&nbsp;&nbsp; {energy.yearEnergy && numFormat(num(energy.yearEnergy))}MWh</small>
          </th>
        );
      }
    } else if (ensoTypeCode == 15007) {
      return (
        <th className="installerGathTBgA">
          <div>금일충전량</div>
          <br />
          <b>{energy.dayEnergy ? numFormat(num(energy.dayEnergy)) : 0}</b>
          <span>kWh</span>
          <br />
          <small>금월&nbsp;&nbsp; {energy.monthEnergy ? numFormat(num(energy.monthEnergy)) : 0} MWh</small>
          {isMobile === "Y" ? <br></br> : null}
          <small>금년&nbsp;&nbsp; {energy.yearEnergy ? numFormat(num(energy.yearEnergy)) : 0} MWh</small>
        </th>
      );
    }
  };

  const ocurDtmCell = (e) => {
    const temp = e.dataItem.ocurDtm.replace("T", " ");
    return <td>{temp}</td>;
  };

  // Cell 의 각 데이터 값에 대한 포맷
  const cellFormat = (data) => {
    const field = data.field;
    let value = "-";
    // 동적으로 객체 존재를 확인 하고 있다면 포맷 적용 (undefined 와 null 체크 )
    if (data.dataItem.hasOwnProperty(field) && data.dataItem[field] != null) {
      if (field === "eventCtnt") {
        value = data.dataItem[field];
      }
    }
    return <td style={{ textAlign: "center", paddingRight: "6px" }}>{value}</td>;
  };

  return (
    <div className={loading ? styles.loadingArea : ""}>
      <div>
        <div className="uk-grid">
          <div className="uk-width-medium-1-1">
            <div className="md-card">
              <div className="md-card-content">
                <div className="md-card-toolbar">
                  <h3 className="md-card-toolbar-heading-text">
                    {state.installer.userTerm}
                    <span className={styles.marginLeft10} />
                    <div className="installerGathDdlDiv">
                      <DropDown
                        className="installerGathDdl"
                        data={state.ensoList}
                        textField="ensoTypeTerm"
                        dataItemKey="ensoTypeCode"
                        defaultValue={{
                          ensoTypeTerm: energy ? energy.ensoTypeTerm : "태양광",
                          ensoTypeCode: energy ? energy.ensoTypeCode : 15001,
                        }}
                        onChange={setEnsoChangeEvt}
                      />
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <DropDown
                        className="installerGathDdl"
                        data={meainList}
                        textField="term"
                        dataItemKey="cid"
                        defaultValue={meainDefaultValue}
                        value={meain}
                        onChange={setMeainChangeEvt}
                      />
                    </div>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mobilePaddingDiv"></div>
        <div className="uk-grid">
          <div className="uk-width-medium-1-3">
            <div className="md-card">
              <div className="md-card-content">
                <div className="md-card-toolbar">
                  <h3 className="md-card-toolbar-heading-text">
                    발전정보
                    <span className={styles.marginLeft10} />
                    <small>
                      [{energy.bizYyyy}: {energy.bizTerm}]
                    </small>
                  </h3>
                </div>
                <div className="installerGath-infoBox">
                  <table className="installerGath-infoTable">
                    <tr>
                      <th>
                        <div>설비용량</div>
                        <br />
                        <b>{energy.instCapa && numFormat(roundNum(energy.instCapa, 2))}</b>
                        <span>kW</span>
                        <br />
                        <small>
                          설비설치일 &nbsp;&nbsp;
                          {energy.instDtm && parseDate("yyyy-mm-dd", energy.instDtm)}
                        </small>
                      </th>
                      {ensoTypeInfo(energy)}
                    </tr>
                    {ensoTypeCode != 15007 && (
                      <tr>
                        <th className="installerGathTBgA">
                          <div>
                            누적 CO<sub>2</sub>저감량
                          </div>
                          <br />
                          <b>
                            {energy.accumUse | energy.accumEnergy &&
                              reduceTCo2(energy.ensoTypeCode === 15002 || energy.ensoTypeCode === 15003 ? energy.accumUse : energy.accumEnergy)}
                          </b>
                          <span>
                            tCO<sub>2</sub>
                          </span>
                        </th>
                        <th>
                          <div>식수효과</div>
                          <br />
                          <b>{energy.accumEnergy && wood(energy.accumEnergy)}</b>
                          <span>그루</span>
                        </th>
                      </tr>
                    )}
                  </table>
                </div>
                <div>
                  <div className={styles.weekEnergyTitle}>
                    {ensoTypeCode != 15007
                      ? !isPowerEnsoType(ensoTypeCode)
                        ? energy.sysMode === 29002
                          ? "주간소비량"
                          : "주간생산량"
                        : "주간발전량"
                      : "주간 충전량"}
                  </div>
                  <div>
                    {weekEnergyList.length > 0 ? (
                      <WeekEnergyChart weekEnergyList={weekEnergyList} ensoTypeCode={ensoTypeCode} energy={energy} />
                    ) : (
                      <div className="weekEnergyChartNoneData"> 조회된 데이터가 없습니다.</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="MobilePaddingDiv"></div>
          <div className="uk-width-medium-2-3">
            <div className="md-card">
              <div className="md-card-content">
                <div className="md-card-toolbar">
                  <h3 className="md-card-toolbar-heading-text">
                    실시간 계측정보
                    <span className={styles.marginLeft10} />
                    <small>[모니터링시작일 : {parseDate("yyyy-mm-dd", energy.monitorDtm)}]</small>
                    <small className={styles.recentGathDtm}>
                      최근수집일시 : {energy.latestGathDtm ? parseDate("yyyy-mm-dd hh:mm:ss", energy.latestGathDtm) : "미수집"}
                    </small>
                  </h3>
                </div>
                <div className="uk-grid">
                  <div className="uk-width-medium-7-10">
                    <div className={styles.diagramBox}>
                      <img className={styles.diagramImg} src={diagramPath} alt="" />
                      <GathInfo data={latestGath} ensoTypeCode={ensoTypeCode} sysMode={energy.sysMode} />
                    </div>
                  </div>
                  <div className="uk-width-medium-3-10">
                    <div className={styles.latestGathInfo}>
                      {latestGath.map((item) => (
                        <div className={styles.gathItem}>
                          <span className={styles.gathTerm}>{item.term}</span>
                          <span className={styles.gathValue}>
                            {item.value !== null ? numFormat(roundNum(item.value, 2)) : "미수집"}
                            <span className={styles.gathUnit}>{item.unit}</span>
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="uk-grid">
          <div className="uk-width-medium-1-3">
            <div className="md-card">
              <div className="md-card-content">
                <div className="md-card-toolbar">
                  <h3 className="md-card-toolbar-heading-text">
                    {" "}
                    {ensoTypeCode != 15007 ? (!isPowerEnsoType(ensoTypeCode) ? (energy.sysMode === 29002 ? "사용량" : "생산량") : "발전량") : "충전량"}추이
                  </h3>
                </div>
                <div className={styles.bottomChartArea}>
                  {ensoTypeCode != 15007 ? (
                    !isPowerEnsoType(ensoTypeCode) ? (
                      energy.sysMode === 29002 ? (
                        <div style={{ height: "160px", textAlign: "center", marginTop: "30%" }}> 자연순환식은 시간대별 사용량 추이를 제공하지 않습니다. </div>
                      ) : hourEnergyList.length > 0 ? (
                        <HourEnergyChart hourEnergyList={hourEnergyList} ensoTypeCode={ensoTypeCode} />
                      ) : (
                        <div style={{ height: "160px", textAlign: "center", marginTop: "30%" }}>조회된 데이터가 없습니다.</div>
                      )
                    ) : hourEnergyList.length > 0 ? (
                      <HourEnergyChart hourEnergyList={hourEnergyList} ensoTypeCode={ensoTypeCode} />
                    ) : (
                      <div style={{ height: "160px", textAlign: "center", marginTop: "30%" }}>조회된 데이터가 없습니다.</div>
                    )
                  ) : hourEnergyList.length > 0 ? (
                    <HourEnergyChart hourEnergyList={hourEnergyList} ensoTypeCode={ensoTypeCode} />
                  ) : (
                    <div style={{ height: "160px", textAlign: "center", marginTop: "30%" }}>조회된 데이터가 없습니다.</div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="uk-width-medium-1-3">
            <div className="md-card">
              <div className="md-card-content">
                <div className="md-card-toolbar">
                  <h3 className="md-card-toolbar-heading-text">
                    지역비교<small>(누적효율)</small>
                  </h3>
                </div>
                <div className={styles.bottomChartArea}>
                  <AreaEnergyChart areaEnergyList={areaEnergyList} ensoTypeCode={ensoTypeCode} energy={energy} />
                </div>
              </div>
            </div>
          </div>
          <div className="uk-width-medium-1-3">
            <div className="md-card">
              <div className="md-card-content">
                <div className="md-card-toolbar">
                  <h3 className="md-card-toolbar-heading-text">
                    설비상태 이력
                    <span className={styles.statusViewHelp}>
                      &nbsp;&nbsp;
                      <img src="/images/info.png" alt="" />
                      <small>이벤트명</small>
                      <div className={styles.statusHelpImg}>
                        <span className={styles.helpTxtLine}>
                          임계치초과 : 누적발전량(혹은 생산량)이 감소하거나, 시간당 발전량이 기준치를 초과할 경우(기준치는 설비용량에 비례)
                        </span>
                        <span className={styles.helpTxtLine}>
                          <br />
                          프로토콜에러 : RTU에서 보낸 데이터가 표준프로토콜에 부합하지 않는 경우(데이터 크기가 불일치 할때)
                        </span>
                        <span className={styles.helpTxtLine}>
                          <br />
                          인버터고장 : 설비로부터 에러코드가 0이 아닌 값을 받을 경우
                        </span>
                        <span className={styles.helpTxtLine}>
                          <br />
                          누적값 0 : 누적발전/생산/사용열량 값이 0으로 수집될 경우
                        </span>
                        <span className={styles.helpTxtLine}>
                          <br />
                          누적값 없음 : 누적발전/생산/사용열량 값이 Null로 수신될 경우
                        </span>
                        <span className={styles.helpTxtLine}>
                          <br />
                          누적값 음수 : 누적발전/생산/사용열량 값이 0보다 작을 경우
                        </span>
                        <span className={styles.helpTxtLine}>
                          <br />
                          누적값 초과 : 생산/부하측 누적 생산량이 생산량 한계치를 초과할 경우
                        </span>
                      </div>
                    </span>
                  </h3>
                  <div className="md-card-toolbar-actions"></div>
                </div>
                <div className="Table-scr">
                  <Grid
                    filterable={false}
                    sortable={false}
                    pageable={false}
                    data={latestEventList}
                    className="latestEventList"
                    style={{ height: "335px", fontSize: "14px" }}
                  >
                    {/* <Column field="eventNo" title="No" width="60" /> */}
                    <Column
                      field="ocurDtm"
                      title="발생일시"
                      width={isMobile === "Y" ? "130px" : "200px"}
                      cell={(data) => <td>{parseDate("yyyy-mm-dd hh:mm:ss", data.dataItem[data.field])}</td>}
                    />
                    <Column field="cid" title="CID" />
                    <Column field="meainStatCodeTerm" title="설비상태" />
                    <Column field="eventCtnt" title="이벤트명" cell={cellFormat} />
                    <GridNoRecords>{"조회 결과 데이터가 없습니다."}</GridNoRecords>
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && (
        <p className={styles.installerGathLoading}>
          <BeatLoader loading={true} size={24} color="#1e88e5" />
        </p>
      )}
    </div>
  );
});

export default InstallerGath;
