// 모듈 효율 15001
import { darken } from "@material-ui/core";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { numFormat, roundNum } from "../../../../com/dataUtil";

// 경보 차트
export const ReportDayAlertChart = ({ eventList, ensoTypeCode, width, clsName, height }) => {
  const ensoTypeEventList = eventList.length !== 0 ? eventList.filter((c) => c.ensoTypeCode === ensoTypeCode) : [];
  // 차트 옵션
  Highcharts.setOptions({
    lang: {
      resetZoom: "초기화",
    },
  });
  let chartConfig = {
    chart: {
      type: "bar",
      zoomType: "xy",
      backgroundColor: "#f7f9fa",
      width: width !== null ? width : "100%",
      height: height !== null ? height : null,
    },
    title: { text: null },
    xAxis: [
      {
        type: "category",
        crosshair: true,
      },
    ],
    yAxis: [
      {
        title: {
          text: "(건)",
          align: "high",
        },
      },
    ],
    credits: { enabled: false },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        pointWidth: 40,
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          color: "#ffffff",
          // style: { textShadow: false },
          formatter: function () {
            return "<span>" + "<b>" + numFormat(roundNum(this.y, 0)) + "건</b>" + "</span>";
          },
        },
      },
    },
    legend: {
      enabled: false,
      backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || "#FFFFFF",
    },
    series: [
      {
        data: [
          { name: "미작동", y: ensoTypeEventList.length !== 0 ? ensoTypeEventList[0].unnormalCnt : [], color: "#5a5a5a" },
          { name: "경고", y: ensoTypeEventList.length !== 0 ? ensoTypeEventList[0].warningCnt : [], color: "#fff171" },
          { name: "고장", y: ensoTypeEventList.length !== 0 ? ensoTypeEventList[0].errorCnt : [], color: "#ff4343" },
          { name: "총 발생", y: ensoTypeEventList.length !== 0 ? ensoTypeEventList[0].totalCnt : [], color: "#3866ff" },
        ],
      },
    ],
  };
  Highcharts.setOptions({
    lang: {
      resetZoom: "초기화",
    },
  });

  return (
    <div className={clsName !== null ? clsName : "reportDayBackground"}>
      <HighchartsReact Highcharts={Highcharts} options={chartConfig} containerProps={{ style: { border: "1px solid gainsboro" } }}></HighchartsReact>
    </div>
  );
};

export default ReportDayAlertChart;
