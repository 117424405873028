//react
import { useState, useEffect } from "react";
//css
import "./edsmCmp.css";
import "./edsmLog.css";
// Kendo
import kendo from "@progress/kendo-ui";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input, RadioButton } from "@progress/kendo-react-inputs";
import DatePickerKo from "../../com/dateInputs/datePickerKo";
//component
import { getUser, isInstaller } from "../../../service/authService";
import UserInfoWindow from "./../../stats/type/userByUser/userInfoWindow";
import useHistoryState from "../../../com/useHistoryState";
import { getRawKepcoFifteen, getRawKepcoDay, retryEdsm } from "../../../service/analy/rawKepcoService";
import { getMessage } from "./../../../com/messageUtil";
import useDidMountEffect from "../../../com/useDidMountEffect";
// 로딩 스피너
import { BeatLoader } from "react-spinners";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { numFormat } from "./../../../com/dataUtil";
import { retryDevEdsm } from "./../../../service/analy/rawKepcoService";
import { parseDate } from "./../../../com/dateUtil";

const EdsmLog = (props) => {
  const { rgnList, dongList, conn, codeList } = props;
  const [fifLoading, setFifLoading] = useState(false);
  const [dayLoading, setDayLoading] = useState(false);
  //15분 데이터
  const [fifDataSource, setFifDataSource] = useState({ results: [], total: 0 });
  //일일 데이터
  const [dayDataSource, setDayDataSource] = useState([]);
  const [selected, setSelected] = useState(0);
  const handleSelect = (e) => {
    setSelected(e.selected);
  };

  // 날짜 검색 데이터 소스
  const today = new Date();
  const yesterday = new Date();
  const lastWeek = new Date();
  const lastMonth = new Date();
  const lastYear = new Date();
  yesterday.setDate(today.getDate() - 1);
  lastWeek.setDate(yesterday.getDate() - 7);
  lastMonth.setDate(yesterday.getDate() - 30);
  lastYear.setDate(yesterday.getDate() - 365);
  const dateList = codeList.filter((c) => c.grpCodeNo === 23);
  const [dateVal, setDateVal] = useState();

  const [search, setSearch] = useState({
    userId: isInstaller() ? getUser().userId : "",
    userTerm: isInstaller() ? getUser().userTerm : "",
    cid: "",
    startDate: today,
    endDate: today,
    userInfoVisible: false,
    callApi: false, // 토글 될때 api요청
  });
  //15분 데이터 페이징
  const [fifPage, setFifPage] = useState({
    take: 20,
    skip: 0,
  });
  //일일 데이터 페이징
  const [dayPage, setDayPage] = useState({
    take: 20,
    skip: 0,
  });

  const fifPageChngEvt = (e) => {
    setFifPage(e.page);
  };
  const dayPageChngEvt = (e) => {
    setDayPage(e.page);
  };
  const startDateChngEvt = (item) => {
    setSearch({ ...search, startDate: item });
  };
  const endDateChngEvt = (item) => {
    setSearch({ ...search, endDate: item });
  };
  const dateDdlChngEvt = (e) => {
    setDateVal(e.target.value);
    if (e.target.value.codeNo === 23001) {
      setSearch({ ...search, startDate: today, endDate: today });
    } else if (e.target.value.codeNo === 23002) {
      setSearch({ ...search, startDate: yesterday, endDate: today });
    } else if (e.target.value.codeNo === 23003) {
      setSearch({ ...search, startDate: lastWeek, endDate: today });
    } else if (e.target.value.codeNo === 23004) {
      setSearch({ ...search, startDate: lastMonth, endDate: today });
    } else if (e.target.value.codeNo === 23005) {
      setSearch({ ...search, startDate: lastYear, endDate: today });
    } else if (e.target.value.codeNo === 23006) {
      setSearch({ ...search, startDate: yesterday, endDate: today });
    }
  };

  // 유저 ID 검색
  const searchUserId = (e) => {
    setSearch({ ...search, userInfoVisible: true });
  };

  //step 1 검색 버튼 클릭
  const srchBtnClkEvt = () => {
    getDataSource();
  };
  //step 4 데이터소스 요청
  const getDataSource = () => {
    if (fifPage.skip > 0) setFifPage({ ...fifPage, skip: 0 });
    else getFifGridData();
    // if (dayPage.skip > 0) setDayPage({ ...dayPage, skip: 0 });
    // else
    getDayGridData();
  };

  //15분 데이터 페이지 이동 시 조회
  useDidMountEffect(() => {
    getFifGridData();
  }, [fifPage.skip]);
  const getFifGridData = () => {
    setFifLoading(true);
    getRawKepcoFifteen(conn, search, fifPage)
      .then((response) => {
        setFifDataSource(response);
        setFifLoading(false);
      })
      .catch((error) => console.log(error));
  };

  //일일 데이터 페이지 이동 시 조회
  // useDidMountEffect(() => {
  //   getDayGridData();
  // }, [dayPage.skip]);
  const getDayGridData = () => {
    setDayLoading(true);
    getRawKepcoDay(conn, search, fifPage)
      .then((response) => {
        setDayDataSource(response);
        setDayLoading(false);
      })
      .catch((error) => console.log(error));
  };

  //재수집 요청 셀
  const retryEdsmCell = (e) => {
    const { dataItem } = e;
    const userId = dataItem.userId;
    const retryDate = dataItem.gathDtmDay;
    const retryBtnClkEvt = () => {
      kendo.confirm("재수집요청 하시겠습니까?").then(() => {
        setDayLoading(true);
        retryEdsm(conn, userId, retryDate)
          .then(() => {
            setDayLoading(false);
            kendo.alert("재수집 완료");
            getDataSource();
          })
          .catch((err) => setDayLoading(false));
      });
    };
    return (
      <td>
        <Button className="edsmRetryBtn" onClick={retryBtnClkEvt}>
          수집
        </Button>
      </td>
    );
  };

  //전력 소비량 셀
  const pwrQtyCell = (e) => {
    const { dataItem } = e;

    const result = numFormat(dataItem.pwrQty);

    return <td>{result}</td>;
  };

  //개발자 전용 재수집
  const devEdsmRetryEvt = () => {
    kendo.confirm(parseDate("yyyy-mm-dd", search.startDate) + "의 데이터를 재수집 하시겠습니까?").then(() => {
      retryDevEdsm(conn, search.startDate)
        .then(() => {
          kendo.alert("재수집 완료");
          getDayGridData();
          getFifGridData();
        })
        .catch((error) => {
          kendo.alert("재수집 실패");
        });
    });
  };

  useEffect(() => {
    getDataSource();
  }, []);
  return (
    <div className="edCmpDiv">
      <div className="edCmpSrchDiv">
        <span className="edCmpSrchLeft">
          <span>수용가</span>&nbsp;&nbsp;&nbsp;
          <Input
            onClick={searchUserId}
            id="userTerm"
            type="text"
            placeholder="수용가"
            readOnly
            value={search.userTerm ? search.userTerm : ""}
            disabled={isInstaller()}
          />
          <Button onClick={searchUserId} icon="search" disabled={isInstaller()} />
        </span>
        <span className="edCmpSrchRight">
          <DropDownList
            className="ensoDropDown yearDropDown"
            id="date"
            name="date"
            data={dateList}
            dataItemKey="codeNo"
            textField="codeVal"
            defaultValue={{ codeNo: 23006, codeVal: "직접조회" }}
            onChange={dateDdlChngEvt}
            value={dateVal}
          />
          &nbsp; &nbsp;
          <label style={{ margin: "3px", display: "inline-flex" }}>조회기간</label>
          <DatePickerKo className="edCmpDtStrt" value={search.startDate} setDtm={startDateChngEvt} max={search.endDate} format={"yyyy-MM-dd"}></DatePickerKo>
          {"~"}
          <DatePickerKo
            className="edCmpDtEnd"
            value={search.endDate}
            setDtm={endDateChngEvt}
            min={search.startDate}
            max={today}
            format={"yyyy-MM-dd"}
          ></DatePickerKo>
          <Button icon="search" style={{ backgroundColor: "white", paddingLeft: "20px", paddingRight: "20px" }} onClick={srchBtnClkEvt}></Button>
        </span>
      </div>
      <div className="edLogContent">
        {getUser().userTypeCode === 2005 && (
          <Button className="devEdsmBtn" onClick={devEdsmRetryEvt}>
            개발자 전용 재수집
          </Button>
        )}
        <TabStrip selected={selected} onSelect={handleSelect} className="edLogTabStrip">
          <TabStripTab title="15분 데이터">
            <LocalizationProvider language="ko-KR">
              <IntlProvider locale="ko">
                <Grid
                  style={fifLoading ? { opacity: "0.5", pointerEvents: "none" } : null}
                  className="edLogGrid"
                  onPageChange={fifPageChngEvt}
                  data={fifDataSource.results}
                  skip={fifPage.skip}
                  take={fifPage.take}
                  total={fifDataSource.total}
                  pageable={{
                    type: "numeric",
                    pageSizes: [5, 10, 15, 20, 50],
                    previousNext: true,
                    buttonCount: 5,
                  }}
                >
                  <GridColumn field="gathDtmHour" title="수집일시"></GridColumn>
                  <GridColumn field="userTerm" title="수용가"></GridColumn>
                  <GridColumn field="custNo" title="한전고객번호"></GridColumn>
                  <GridColumn field="pwrQty" title="전력 소비량 (단위 : kWh)" cell={pwrQtyCell}></GridColumn>
                </Grid>
                {fifLoading && (
                  <p className="loadingSpinner">
                    <BeatLoader loading={true} size={24} color="#1e88e5" />
                  </p>
                )}
              </IntlProvider>
            </LocalizationProvider>
          </TabStripTab>
          <TabStripTab title="일일 데이터">
            <LocalizationProvider language="ko-KR">
              <IntlProvider locale="ko">
                <Grid
                  style={dayLoading ? { opacity: "0.5", pointerEvents: "none" } : null}
                  className="edLogGrid"
                  onPageChange={dayPageChngEvt}
                  data={dayDataSource.slice(dayPage.skip, dayPage.take + dayPage.skip)}
                  skip={dayPage.skip}
                  take={dayPage.take}
                  total={dayDataSource.length}
                  pageable={{
                    type: "numeric",
                    pageSizes: [5, 10, 15, 20, 50],
                    previousNext: true,
                    buttonCount: 5,
                  }}
                >
                  <GridColumn field="gathDtmDay" title="수집일"></GridColumn>
                  <GridColumn field="userTerm" title="수용가"></GridColumn>
                  <GridColumn field="custNo" title="한전고객번호"></GridColumn>
                  <GridColumn field="pwrQty" title="전력 소비량 (단위 : kWh)" cell={pwrQtyCell}></GridColumn>
                  <GridColumn field="rawCount" title="수집 건수"></GridColumn>
                  <GridColumn field="" title="재수집 요청" cell={retryEdsmCell}></GridColumn>
                </Grid>
              </IntlProvider>
            </LocalizationProvider>
            {dayLoading && (
              <p className="loadingSpinner">
                <BeatLoader loading={true} size={24} color="#1e88e5" />
              </p>
            )}
          </TabStripTab>
        </TabStrip>
      </div>
      {search.userInfoVisible && <UserInfoWindow conn={conn} search={search} setSearch={setSearch} kendo={kendo} rgnList={rgnList} dongList={dongList} />}
    </div>
  );
};

export default EdsmLog;
