
export const qnaList = async (conn, search, paging) => {

  const take = paging.take
  const skip = paging.skip
  const page = paging.skip / paging.take + 1
  const pageSize = paging.take
  const qestnSj = search.qestnSj
  const companyTerm = search.companyTerm
  const response = await conn.get("bbs/qna/qnaList",
    {
      params: {
        take: take,
        skip: skip,
        page: page,
        pageSize: pageSize,
        qestnSj: qestnSj,
        companyTerm: companyTerm,
      },
    }
  );
  return response;

}

export const setQna = async (conn, obj) => {
  const response = await conn.post("bbs/qna", obj, {
    config: {
      headers: {
        "Content-Type": "application/json",
      },
    },
  });
  return response;
};

export const setReplyQna = async (conn, obj) => {
  const response = await conn.post("bbs/qna/reply", obj, {
    config: {
      headers: {
        "Content-Type": "application/json",
      },
    },
  });
  return response;
};

export const setDeleteQna = async (conn, dataItem) => {
  const response = await conn.delete("bbs/qna", {
    params: {
      qnaNo: dataItem,
    },
  });
  return response;
};

export const setViewCnt = async (conn, data) => {
  const response = await conn.post("bbs/qna/viewCntInc", data, {
    config: {
      headers: {
        "Content-Type": "application/json",
      },
    },
  });
  return response;
};

export const getQnaFileList = async (conn, qnaNo) => {
  const response = await conn.get("bbs/qna/qnaFile", {
    params: {
      qnaNo: qnaNo
    }
  });
  return response;
};

export const setDeleteQnaReply = async (conn, dataItem) => {
  const response = await conn.delete("bbs/qnaReply/replyDelete", {
    params: {
      qnaNo: dataItem,
      seq:1,
    },
  });
  return response;
};