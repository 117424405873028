import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { numFormat, roundNum } from "./../../../com/dataUtil";

const EdsmCmpChart = (props) => {
  const { chartData } = props;
  const dayPowerList = chartData.dayPowerList;
  const pwrQtyList = chartData.pwrQtyList;

  const chartOpt = {
    chart: {
      backgroundColor: "#FFFFFF",
    },
    lang: {
      noData: "데이터가 없습니다.",
    },
    noData: {
      style: {
        fontWeight: "bold",
        fontSize: "15px",
        color: "#303030",
      },
    },
    title: {
      text: null,
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    xAxis: [{ type: "category", crosshair: true }],
    yAxis: [
      {
        showEmpty: false,
        labels: {
          format: "{value:,f}kWh",
          style: {
            color: "#6DB6EE",
          },
        },
        title: {
          text: "발전량",
          style: {
            color: "#6DB6EE",
          },
          // ,
          rotation: 0,
          y: -20,
          x: -10,
          offset: 0,
          align: "high",
        },
        // min: 0,
      },
      {
        showEmpty: false,
        // showEmpty: true,
        labels: {
          format: "{value:,.0f}kWh",
          style: {
            color: "#EE7951",
          },
        },
        title: {
          text: "전력소비량",
          style: {
            color: "#EE7951",
          },
          rotation: 0,
          y: -20,
          x: -10,
          offset: 15,
          align: "high",
        },
        opposite: true,
        // min: 0,
        // max: 100,
      },
    ],
    tooltip: {
      shared: true,
      formatter: function () {
        let s = "<b>" + this.points[0].key + "</b>";
        if (this.points[0]) {
          s +=
            "<br/>" +
            '<span style="color:' +
            this.points[0].point.color +
            '">\u25CF</span> ' +
            this.points[0].series.name +
            ": " +
            numFormat(roundNum(this.points[0].y, 2)) +
            " kWh";
        }
        if (this.points[1]) {
          s +=
            "<br/>" +
            '<span style="color:' +
            this.points[1].point.color +
            '">\u25CF</span> ' +
            this.points[1].series.name +
            ": " +
            numFormat(roundNum(this.points[1].y, 2)) +
            " kWh";
        }
        return s;
      },
    },
    legend: {
      align: "center",
      verticalAlign: "top",
      backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || "#FFFFFF",
    },
    series: [
      {
        name: "발전량",
        type: "column",
        data: dayPowerList,
        color: "#6DB6EE",
        yAxis: 0,
      },
      {
        name: "전력소비량",
        type: "spline",
        data: pwrQtyList,
        color: "#EE7951",
        yAxis: 1,
      },
    ],
  };
  Highcharts.setOptions({
    lang: {
      thousandsSep: ",",
    },
  });

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={chartOpt} />
    </div>
  );
};

export default EdsmCmpChart;
