import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { numFormat, roundNum } from "../../../../com/dataUtil";
import HighchartsBrokenAxis from "highcharts/modules/broken-axis";

const RenewEnsoCht = (props) => {
  const { chartData } = props;
  let dataSource = {
    cnt: [],
    instCapa: [],
    dayPower: [],
  };
  chartData.forEach((item) => {
    dataSource.cnt.push(item.cnt);
    dataSource.instCapa.push(item.instCapa);
    dataSource.dayPower.push(item.dayPower);
  });

  HighchartsBrokenAxis(Highcharts);
  Highcharts.wrap(Highcharts.Axis.prototype, "getLinePath", function (proceed, lineWidth) {
    var axis = this,
      brokenAxis = axis.brokenAxis,
      path = proceed.call(this, lineWidth),
      start = path[0],
      x = start[1],
      y = start[2];

    (brokenAxis.breakArray || []).forEach(function (brk) {
      if (axis.horiz) {
        x = axis.toPixels(brk.from);
        path.splice(
          1,
          0,
          ["L", x - 4, y], // stop
          ["M", x - 9, y + 5],
          ["L", x + 1, y - 5], // left slanted line
          ["M", x - 1, y + 5],
          ["L", x + 9, y - 5], // higher slanted line
          ["M", x + 4, y],
        );
      } else {
        y = axis.toPixels(brk.from);
        path.splice(
          1,
          0,
          ["L", x, y - 4], // stop
          ["M", x + 5, y - 9],
          ["L", x - 5, y + 1], // lower slanted line
          ["M", x + 5, y - 1],
          ["L", x - 5, y + 9], // higher slanted line
          ["M", x, y + 4],
        );
      }
    });
    return path;
  });

  /**
   * On top of each column, draw a zigzag line where the axis break is.
   */
  function pointBreakColumn(e) {
    var point = e.point,
      brk = e.brk,
      shapeArgs = point.shapeArgs,
      x = shapeArgs.x,
      y = this.translate(brk.from, 0, 1, 0, 1),
      w = shapeArgs.width,
      key = ["brk", brk.from, brk.to],
      path = ["M", x, y, "L", x + w * 0.25, y + 4, "L", x + w * 0.75, y - 4, "L", x + w, y];

    if (!point[key]) {
      point[key] = this.chart.renderer
        .path(path)
        .attr({
          "stroke-width": 2,
          stroke: point.series.options.borderColor,
        })
        .add(point.graphic.parentGroup);
    } else {
      point[key].attr({
        d: path,
      });
    }
  }
  const chartOpt = {
    chart: {
      zoomType: "xy",
      type: "column",
      height: "255px",
      backgroundColor: "white",
      // backgroundColor: {
      //   linearGradient: [100, 100, 10, 10],
      //   stops: [
      //     [0, "#ffffff"],
      //     [1, "#f6f6f6"],
      //   ],
      // },
      borderRaidus: "5px",
    },
    lang: {
      noData: "데이터가 없습니다.",
    },
    noData: {
      style: {
        fontWeight: "bold",
        fontSize: "15px",
        color: "#303030",
      },
    },
    title: {
      text: null,
      style: {
        fontWeight: "bold",
        fontSize: "15px",
      },
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      column: {
        dataLabels: {
          enabled: true,
        },
      },
    },
    xAxis: [
      {
        categories: ["태양광", "태양열", "지열", "풍력", "연료전지"],
        type: "category",
        crosshair: true,
      },
    ],
    yAxis: [
      {
        // tickInterval: 100,
        // breaks: [
        //   {
        //     from: 4000,
        //     to: 42000,
        //   },
        // ],
        // events: {
        //   pointBreak: pointBreakColumn,
        // },
        visible: false,
        showEmpty: true,
        labels: {
          format: "{value:,f}개",
          style: {
            color: "#6DB6EE",
          },
        },
        title: {
          text: "설비개수",
          style: {
            color: "#6DB6EE",
          },
          // ,
          rotation: 0,
          y: -20,
          x: -10,
          offset: 0,
          align: "high",
        },
        // min: 0,
      },
      {
        // breaks: [
        //   {
        //     from: 300,
        //     to: 2400,
        //   },
        // ],
        // events: {
        //   pointBreak: pointBreakColumn,
        // },
        visible: false,
        showEmpty: false,
        // showEmpty: true,
        labels: {
          format: "{value:,.0f}MWh",
          style: {
            // color: "#EE7951",
          },
        },
        title: {
          text: "설비용량",
          style: {
            // color: "#EE7951",
          },
          rotation: 0,
          y: -20,
          x: -10,
          offset: 15,
          align: "high",
        },
        min: 0,
      },
      {
        // breaks: [
        //   {
        //     from: 100,
        //     to: 5000,
        //   },
        // ],
        // events: {
        //   pointBreak: pointBreakColumn,
        // },
        visible: false,
        showEmpty: false,
        // showEmpty: true,
        labels: {
          format: "{value:,.0f}MWh",
          style: {
            // color: "#EE7951",
          },
        },
        title: {
          text: "발전/사용량",
          style: {
            // color: "#EE7951",
          },
          rotation: 0,
          y: -20,
          x: -10,
          offset: 15,
          align: "high",
        },
        opposite: true,
        // min: 0,
        // max: 100,
      },
    ],
    tooltip: {
      shared: true,
      formatter: function () {
        let s = "<b>" + this.points[0].key + "</b>";

        if (this.points[0]) {
          s +=
            "<br/>" +
            '<span style="color:' +
            this.points[0].point.color +
            '">\u25CF</span> ' +
            this.points[0].series.name +
            ": " +
            numFormat(roundNum(this.points[0].y, 2)) +
            " 개";
        }

        if (this.points[1]) {
          s +=
            "<br/>" +
            '<span style="color:' +
            this.points[1].point.color +
            '">\u25CF</span> ' +
            this.points[1].series.name +
            ": " +
            numFormat(roundNum(this.points[1].y, 2)) +
            (this.x === "태양열" ? " k㎡" : " MW");
        }

        if (this.points[2]) {
          s +=
            "<br/>" +
            '<span style="color:' +
            this.points[2].point.color +
            '">\u25CF</span> ' +
            (this.x === "태양열" || this.x === "지열" ? "사용량" : "발전량") +
            ": " +
            numFormat(roundNum(this.points[2].y, 2)) +
            " MWh";
        }
        return s;
      },
    },
    legend: {
      align: "center",
      verticalAlign: "top",
    },
    series: [
      {
        name: "설비개수",
        data: dataSource.cnt,
        color: "#0B92CC",
        yAxis: 0,
      },
      {
        name: "설비용량",
        data: dataSource.instCapa,
        color: "#7AB700",
        yAxis: 1,
      },
      {
        name: "발전/사용량",
        data: dataSource.dayPower,
        color: "#FE4A59",
        yAxis: 2,
      },
    ],
  };
  Highcharts.setOptions({
    lang: {
      thousandsSep: ",",
      resetZoom: "초기화",
    },
  });
  return <HighchartsReact highcharts={Highcharts} options={chartOpt} />;
};

export default RenewEnsoCht;
