//React
import React, { useState, useEffect } from "react";
import ReportDayIntegratedChart from "./chart/reportDayIntegratedChart";
import ReportDayProductionChart from "./chart/reportDayProductionChart";
import ReportDayGenerationChart from "./chart/reportDayGenerationChart";
import ReportDayEnergyTable from "./reportDayEnergyTable";
import ReportDayAlertChart from "./chart/reportDayAlertChart";
import ReportDayPrintModal from "./reportDayPrintModal";
import { BeatLoader } from "react-spinners";
//kendo
import kendo from "@progress/kendo-ui";
import { Grid, GridColumn as Column, GridNoRecords } from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-data-query";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { IntlProvider, load, loadMessages, LocalizationProvider } from "@progress/kendo-react-intl";
import { DatePicker, MultiViewCalendar } from "@progress/kendo-react-dateinputs";
//css
import "./reportDay.css";
import ReportDayCompareEnergyTable from "./reportDayCompareEnergyTable";
import DatePickerKo from "../../com/dateInputs/datePickerKo";
import { getAreaData, getCompareData, getEnergyData, getEventData } from "../../../service/report/reportService";
import { CustomCalendar } from "../consm/customCalender";

function ReportDay({ conn, codeList, rgnList }) {
  // 로딩 스피너
  const [loading, setLoading] = useState(false);
  // 날짜 Default 정의
  let today = new Date();
  let yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);

  //연월일 버튼 누를 시 변경되는 컴포넌트 데이터들
  const btnDatas = [
    {
      dateType: "DD",
      name: "일간",
      dateformat: "yyyy-MM-dd",
      view: "month",
    },
    {
      dateType: "MM",
      name: "월간",
      dateformat: "yyyy-MM",
      view: "year",
    },
    // {
    //   dateType: "YYYY",
    //   name: "연간",
    //   dateformat: "yyyy",
    //   view: "decade",
    // },
  ];

  // 검색 조건
  const [search, setSearch] = useState({
    dateType: btnDatas[0].dateType,
    dateformat: btnDatas[0].dateformat,
    dtm: yesterday,
    view: btnDatas[0].view,
  });

  // 보고서 데이터 리스트
  const [reportData, setReportData] = useState({
    energyList: [],
    timeChart: {
      chartTime_category: [],
      chartTimeData: {
        power: [],
        cal: [],
        ess: [],
      },
    },
    areaList: [],
    compareList: [],
    compareChart: [],
    eventList: [],
    dashBoard: { power: 0, accumPower: 0, accumCo2: 0, cal: 0, accumCal: 0 },
    compareDashboard: {
      totalPower: 0,
      totalPrePower: 0,
      totalRate: 0,
      solaPrePower: 0,
      solaPower: 0,
      solaRate: 0,
      veloPrePower: 0,
      veloPower: 0,
      veloRate: 0,
      fuelcellPrePower: 0,
      fuelcellPower: 0,
      fuelcellRate: 0,
      totalCal: 0,
      totalPreCal: 0,
      totalCalRate: 0,
      heatCal: 0,
      heatPreCal: 0,
      heatRate: 0,
      heathCal: 0,
      heathPreCal: 0,
      heathRate: 0,
      referDate: kendo.toString(yesterday, "yyyy-MM-dd"),
    },
    dateType: "DD",
  });

  /*인쇄용 팝업창 State */
  const [modalItem, setModalItem] = useState({
    visible: false,
  });

  const [sortState, setSortState] = useState([]);

  // 데이터 불러오기
  const getReportData = () => {
    setLoading(true);
    Promise.all([
      getEnergyData(conn, getResultEnergy, getRejection, search),
      getAreaData(conn, getResultArea, getRejection, search, rgnList),
      getCompareData(conn, getResultCompare, getRejection, search, codeList),
      getEventData(conn, getResultEvent, getRejection, search, codeList),
    ]).then((results) => {
      setLoading(false);
    });
  };
  // const getReportData = () => {
  //   setReportData(getReportDayData());
  // };

  // 에너지원 callback
  const getResultEnergy = (gridData, timeChart) => {
    setReportData((reportData) => {
      const item = { ...reportData };
      item.energyList = gridData;
      item.timeChart = timeChart;
      item.dateType = search.dateType;
      return item;
    });
  };

  // 지역 callback
  const getResultArea = (result) => {
    setReportData((reportData) => {
      const item = { ...reportData };
      item.areaList = result;
      return item;
    });
  };

  // 비교 callback
  const getResultCompare = (result, compareChart, dashBoard, compareDashboard) => {
    setReportData((reportData) => {
      const item = { ...reportData };
      item.compareList = result;
      item.compareChart = compareChart;
      item.dashBoard = dashBoard;
      item.compareDashboard = compareDashboard;
      return item;
    });
  };

  // 이벤트(경보) callback
  const getResultEvent = (result) => {
    setReportData((reportData) => {
      const item = { ...reportData };
      item.eventList = result;
      return item;
    });
  };

  // rejection
  const getRejection = (name, error) => {
    if (error.errCode === "F011") {
      kendo.alert("필요한 검색 값이 없습니다. '일간', '월간', '연간' 버튼을 선택 또는 날짜를 선택하였는지 확인해주세요.");
      return;
    }
  };

  useEffect(() => {
    getReportData();
  }, []);

  //연월일 구분 버튼 이벤트
  const ymdBtnClickEvt = (e) => {
    // 인덱스 값 가져오기
    const idx = btnDatas.findIndex((item) => item.name === e.target.innerText);
    // 검색 설정
    setSearch((search) => {
      const item = { ...search };
      item.dateType = btnDatas[idx].dateType;
      item.dateformat = btnDatas[idx].dateformat;
      item.view = btnDatas[idx].view;
      return item;
    });
  };

  //조회 버튼 이벤트
  const inquiryBtnClickEvt = (e) => {
    getReportData();
  };

  /* 정렬 이벤트 */
  const sortChange = (event) => {
    if (event.sort.length === 0) {
      setSortState([
        {
          field: "",
          dir: "",
        },
      ]);
    } else {
      setSortState([
        {
          field: event.sort[0].field,
          dir: event.sort[0].dir,
        },
      ]);
    }
  };

  const print = () => {
    // window.open('','보고서 프린트', width=430, height=500, location=no, status=no, scrollbars=yes');
    // window.print();
    setModalItem({ ...modalItem, visible: true });
  };
  const onUnload = () => {
    setModalItem({ ...modalItem, visible: false });
  };

  return (
    <div
      style={
        loading
          ? {
              opacity: 0.6,
              pointerEvents: "none",
            }
          : null
      }
    >
      <div className="uk-grid">
        <div className="uk-width-medium-1-1">
          <ButtonGroup selection="single">
            {btnDatas.map((item) => {
              return (
                <div>
                  <Button togglable={true} selected={search.dateType === item.dateType} onClick={ymdBtnClickEvt}>
                    {item.name}
                  </Button>
                </div>
              );
            })}
          </ButtonGroup>
          {reportData.dateType === "DD" && <div className="reportDayBackground"> 일간 종합 운영 보고서 </div>}
          {reportData.dateType === "MM" && <div className="reportDayBackground"> 월간 종합 운영 보고서 </div>}
          {reportData.dateType === "YYYY" && <div className="reportDayBackground"> 연간 종합 운영 보고서 </div>}
          {reportData.dateType === "DD" && <div className="reportDayBackground"> Daily Operation Report </div>}
          {reportData.dateType === "MM" && <div className="reportDayBackground"> Monthly Operation Report </div>}
          {reportData.dateType === "YYYY" && <div className="reportDayBackground"> Yearly Operation Report </div>}
          <div className="reportDayBackground">
            보고일 : &nbsp;
            <LocalizationProvider language="ko-KR">
              <IntlProvider locale="ko">
                <DatePicker
                  defaultValue={yesterday}
                  format={search.dateformat}
                  onChange={(e) => {
                    setSearch((search) => {
                      const item = { ...search };
                      item.dtm = e.target.value;
                      return item;
                    });
                    return e.target.value;
                  }}
                  calendar={(props) => <CustomCalendar {...props} topView={search.view} bottomView={search.view} max={yesterday} />}
                />
              </IntlProvider>
            </LocalizationProvider>
            <Button className="reportDayBtn" onClick={inquiryBtnClickEvt} icon="search"></Button>
            <img onClick={print} src="/images/printer.png" alt=" " style={{ cursor: "pointer", position: "absolute", right: 50 }} />
          </div>
        </div>
      </div>
      {/* 전기에너지, 열에너지, ESS 테이블 */}
      <ReportDayEnergyTable reportData={reportData} dashBoard={reportData.dashBoard} print={false} />
      <div className="uk-grid">
        <div className="uk-width-medium-1-1">
          {reportData.dateType === "DD" && <div className="reportDayBackground"> ·&nbsp;일간 시간별 생산량 </div>}
          {reportData.dateType === "MM" && <div className="reportDayBackground"> ·&nbsp;월간 일별 생산량 </div>}
          {reportData.dateType === "YYYY" && <div className="reportDayBackground"> ·&nbsp;년간 월별 생산량 </div>}
          <div className="md-card" style={{ border: "none" }}>
            <div className="md-card-content">
              {/**
               * 일간시간별 생산량 차트
               */}
              <ReportDayIntegratedChart timeChart={reportData.timeChart} />
            </div>
          </div>
        </div>
        <div className="uk-width-medium-1-2">
          {/**
           * 전일기준값과 에너지 비교 테이블
           */}
          <ReportDayCompareEnergyTable compareDashboard={reportData.compareDashboard} dateType={reportData.dateType} />
          {/**
           * 금일 경보 발생 현황 차트
           */}
          {reportData.dateType === "DD" && <div className="reportDayBackground"> ·&nbsp;일간 경보 발생 현황 </div>}
          {reportData.dateType === "MM" && <div className="reportDayBackground"> ·&nbsp;월간 경보 발생 현황 </div>}
          {reportData.dateType === "YYYY" && <div className="reportDayBackground"> ·&nbsp;년간 경보 발생 현황 </div>}
          <ReportDayAlertChart eventList={reportData.eventList} ensoTypeCode={15000} />
        </div>
        <div className="uk-width-medium-1-2">
          {/* 시간별 생산량 그리드 테이블 */}
          <Grid
            className="reportGrid"
            style={{ height: "870px" }}
            data={orderBy(reportData.energyList, sortState)}
            sortable
            sort={sortState}
            onSortChange={sortChange}
          >
            <GridNoRecords>{"조회 결과 데이터가 없습니다."}</GridNoRecords>
            <Column field="dtm" title={reportData.dateType === "DD" ? "시간대" : "날짜"} />
            <Column field="power" title="발전량" />
            <Column field="cal" title="생산열량" />
            <Column field="ess" title="ESS 충전량" />
          </Grid>
        </div>

        <div className="uk-width-medium-1-2">
          {reportData.dateType === "DD" && <div className="reportDayBackground"> ·&nbsp;에너지원별 일간 발전량 </div>}
          {reportData.dateType === "MM" && <div className="reportDayBackground"> ·&nbsp;에너지원별 월간 발전량 </div>}
          {reportData.dateType === "YYYY" && <div className="reportDayBackground"> ·&nbsp;에너지원별 년간 발전량 </div>}
          <ReportDayGenerationChart compareChart={reportData.compareChart} />
        </div>

        <div className="uk-width-medium-1-2">
          {reportData.dateType === "DD" && <div className="reportDayBackground"> ·&nbsp;에너지원별 일간 생산열량 </div>}
          {reportData.dateType === "MM" && <div className="reportDayBackground"> ·&nbsp;에너지원별 월간 생산열량 </div>}
          {reportData.dateType === "YYYY" && <div className="reportDayBackground"> ·&nbsp;에너지원별 년간 생산열량 </div>}
          <ReportDayProductionChart compareChart={reportData.compareChart} />
        </div>

        <div className="uk-width-medium-1-1">
          {reportData.dateType === "DD" && <div className="reportDayBackground"> -&nbsp;에너지원별 경보 발생 현황(주간) </div>}
          {reportData.dateType === "MM" && <div className="reportDayBackground"> -&nbsp;에너지원별 경보 발생 현황(월간) </div>}
          {reportData.dateType === "YYYY" && <div className="reportDayBackground"> -&nbsp;에너지원별 경보 발생 현황(년간) </div>}
        </div>

        {/* eventList */}

        <div className="uk-width-medium-1-2">
          <div className="reportDayBackground">·&nbsp;태양광</div>
          <ReportDayAlertChart eventList={reportData.eventList} ensoTypeCode={15001} />
        </div>
        <div className="uk-width-medium-1-2">
          <div className="reportDayBackground">·&nbsp;태양열</div>
          <ReportDayAlertChart eventList={reportData.eventList} ensoTypeCode={15002} />
        </div>
        <div className="uk-width-medium-1-2">
          <div className="reportDayBackground">·&nbsp;지열</div>
          <ReportDayAlertChart eventList={reportData.eventList} ensoTypeCode={15003} />
        </div>
        <div className="uk-width-medium-1-2">
          <div className="reportDayBackground">·&nbsp;풍력</div>
          <ReportDayAlertChart eventList={reportData.eventList} ensoTypeCode={15004} />
        </div>
        <div className="uk-width-medium-1-2">
          <div className="reportDayBackground">·&nbsp;연료전지</div>
          <ReportDayAlertChart eventList={reportData.eventList} ensoTypeCode={15006} />
        </div>
        <div className="uk-width-medium-1-2">
          <div className="reportDayBackground">·&nbsp;ESS</div>
          <ReportDayAlertChart eventList={reportData.eventList} ensoTypeCode={15007} />
        </div>

        <div className="uk-width-medium-1-1">
          <div className="reportDayBackground">·&nbsp;시군구별 운영 현황</div>
          <Grid className="reportGrid" data={reportData.areaList}>
            <GridNoRecords>{"조회 결과 데이터가 없습니다."}</GridNoRecords>
            <Column field="rgnTerm" title="지역" />
            <Column field="power" title="발전량" />
            <Column field="cal" title="생산열량" />
            <Column field="ess" title="ESS 충전량" />
          </Grid>
        </div>
        {modalItem.visible && <ReportDayPrintModal reportData={reportData} sortState={sortState} onUnload={onUnload} search={search} />}
      </div>
      {loading && (
        <p
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
        >
          <BeatLoader loading={true} size={24} color="#1e88e5" />
        </p>
      )}
    </div>
  );
}

export default ReportDay;
