import log from "../../../com/log";
import { parseDate } from "../../../com/dateUtil";
import kendo from "@progress/kendo-ui";
import { getMessage } from "../../../com/messageUtil";

const managerComUrl = "user/groupManager";

/**
 * ### 담당자 관리 - 그리드 전체 조회
 */
export const getGroupManager = async (conn, data, rgnList) => {
  const { take, skip, sort, filter } = data;
  const config = {
    params: {
      page: (take + skip) / take,
      size: take,
      sort,
      useYn: "Y",
    },
  };

  if (filter !== undefined) {
    if (filter.userId !== undefined) config.params.userId = filter.userId;
    if (filter.userTerm !== undefined) config.params.userTerm = filter.userTerm;
    if (filter.rgnCode !== undefined) config.params.rgnCode = filter.rgnCode;
  }

  let response = await conn.get(managerComUrl + "/page", config);
  // LocalDateTime -> yyyy-mm-dd hh:mm:ss 변환
  for (let item of response.results) {
    item.regiDtm = parseDate("yyyy-mm-dd hh:mm:ss", item.regiDtm);
  }

  return response;
};

/**
 * ### 담당자 관리 - 수용가 추가, 수정
 */
export const setUpdateGroupManager = async (conn, saveData, rgnList) => {
  try {
    // regiDtm 형식 변경
    saveData.forEach((element) => {
      element.regiDtm = parseDate("LocalDateTime", element.regiDtm);
      // servrRgnInfo Term -> Code 변환
      if (element.servrRgnInfo !== undefined) {
        const servrRgnInfo = [];
        element.servrRgnInfo.forEach((servrItem) => {
          servrRgnInfo.push(servrItem.rgnCode);
        });
        element.servrRgnInfo = servrRgnInfo;
      }
    });

    const response = await conn.post(managerComUrl, JSON.stringify(saveData), {
      headers: { "Content-Type": "application/json" },
    });

    return response;
  } catch (e) {
    const { errCode } = e;
    kendo.alert(getMessage(errCode));
  }
};

/**
 * ### 담당자 관리 - 데이터 1건 삭제
 */
export const setDeleteGroupManager = async (conn, data) => {
  try {
    const config = {
      headers: { "Content-Type": "application/json" },
      data: JSON.stringify(new Array(data)),
    };
    const response = await conn.delete(managerComUrl, config);
  } catch (e) {
    kendo.alert(getMessage(e.errCode));
  }
};
