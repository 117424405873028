import { numFormat } from "../../com/dataUtil";
/**
 * 설치현황 Depth3 각 수용가의 설비 정보
 *
 * @param 검색 조건
 * @return 각 수용가 별 설비 정보와 설비 위치 정보
 */
export const getInstallAreaDataList = async (conn, callback, rejection, param, map, cluster, setLoading) => {
  await conn
    .get("location/depth3/installmeain", {
      params: {
        userTerm: param.userTerm,
        bizSct: param.bizSct,
        bldSrvCodeNo: param.bldSrvCodeNo,
        bizYy: param.bizYy,
        rgnCode: param.rgnCode,
        fixbylawBundCode: param.fixbylawBundCode,
      },
    })
    .then((response) => {
      setLoading(false);
      // 데이터 설정
      const result = response;

      // 수용가별 리스트
      const installer = [];
      const meainList = [];

      // 설비 좌표에 보여줄 데이터 저장용 배열
      const meainDataBoxList = [];

      if (result.locationInstallerMeainListDtoList.length !== 0) {
        const userIdArray = [];
        // 수용가별 에너지원별 데이터 가공
        result.locationInstallerMeainListDtoList.forEach((element) => {
          if (element.accumUse === null) element.accumUse = 0;
          if (element.accumPower === null) element.accumPower = 0;
          if (element.meainCnt === null) element.meainCnt = 0;
          if (element.ensoTypeCode === 15002 || element.ensoTypeCode === 15003) {
            element.co2 = "-";
            element.isUse = true;
          } else {
            element.co2 = element.accumPower * 0.424;
            element.isUse = false;
          }
          // 저장
          userIdArray.push({ userId: element.userId, userTerm: element.userTerm });
        });

        // 중복 ID 제거 후 유니크 ID 추출 *객체의 속성이 1개만 있거나, 객체 값이 완전히 같은 것을 중복 제거 할 경우만 new Set사용
        // const uniqUserId = [...new Set(userIdArray.map(JSON.stringify))].map(JSON.parse);
        const uniqUserId = userIdArray.filter((item, i) => {
          return (
            userIdArray.findIndex((item2, j) => {
              return item.userId === item2.userId;
            }) === i
          );
        });

        // 중복 ID를 KEY 로 설정 후 값을 가공한다.
        for (let index = 0; index < uniqUserId.length; index++) {
          let sumCount = 0;
          const installerData = [];
          result.locationInstallerMeainListDtoList.forEach((element) => {
            if (uniqUserId[index].userId === element.userId) {
              sumCount += element.meainCnt;
              installerData.push(element);
              // 설비 좌표에 보여줄 데이터 저장용 배열
              meainDataBoxList.push(element);
            }
          });
          installer.push({
            userId: uniqUserId[index].userId,
            userTerm: uniqUserId[index].userTerm,
            sumCount: numFormat(sumCount, 2),
            installer: installerData,
            map: map,
          });
        }
      }

      // 각 마커에 표시할 데이터
      if (result.locationMeainListDtoList.length !== 0) {
        const meainResultList = result.locationMeainListDtoList;
        const userIdArray = [];

        meainResultList.forEach((element) => {
          // 유저 ID , 명 저장
          userIdArray.push({ userId: element.userId, userTerm: element.userTerm });
        });

        // 마커 데이터 가공 (왼쪽 수용가 정보를 마커 데이터에 주입한다.)
        if (meainDataBoxList.length !== 0) {
          meainResultList.forEach((item1) => {
            meainDataBoxList.forEach((item2) => {
              if (item1.userId === item2.userId && item1.ensoTypeCode === item2.ensoTypeCode) {
                item1.accumPower = item2.accumPower;
                item1.accumUse = item2.accumUse;
                item1.instCapa = item2.instCapa;
              }
            });
            meainList.push(item1);
          });
        }
      }

      // 최종 가공된 데이터
      const totalResult = { installer: installer, meainList: meainList };

      // 왼쪽 리스트 데이터 좌표 설정 (여러 설비 중 첫번째 설비의 좌표를 가져온다.) (순회 하는 부분 수정해야함 전체 순회 함)
      if (totalResult.installer.length !== 0) {
        totalResult.installer.forEach((item1) => {
          totalResult.meainList.some((item2) => {
            if (item1.userId === item2.userId) {
              item1.lati = item2.lati;
              item1.longi = item2.longi;
              return true;
            }
          });
        });
      }
      //callback
      callback(totalResult, map, cluster);
    })
    .catch((error) => {
      rejection(error);
    });
};
