import styles from "./areaEnergy.module.css";
import "./areaEnergy.css";
import { getAreaEnergyList, getMeainStatCntList, getRemsAreaEnergyList, getRemsMeainStatCntList, getTotalAreaEnergyList, getTotalMeainStatCntList } from "../../../service/monitor/areaEnergyService";
import { useHistory } from "react-router-dom";

import { Grid, GridColumn as Column, setSelectedState } from "@progress/kendo-react-grid";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { useState, useEffect } from "react";
import kendo from "@progress/kendo-ui";
import CountUp from "react-countup";
import { getUser, getUserTypeCode, isManager } from "../../../service/authService";

// 로딩 스피너
import { BeatLoader } from "react-spinners";
import AreaEnergyPrst from "./areaEnergyPrst";
import AreaEnergyMobPrst from "./areaEnergyMobPrst";

const AreaEnergy = ({ conn, codeList, setError, rgnList }) => {
  const [selected, setSelected] = useState(0);
  const [today, setToday] = useState(new Date());
  const handleSelect = (e) => {
    setSelected(e.selected);
  };

  const isMobile = localStorage.getItem("isMobile");

  // 로딩 스피너
  const [areaGridLoading, setAreaGridLoading] = useState(false);

  const history = useHistory();
  const loginUserTypeCode = getUserTypeCode(); // = getUserTypeCode();
  const cityTerm = "충청남도";
  const AREA_TYPE_RGN = "RGN";
  const AREA_TYPE_DONG = "DONG";

  /**
   *  @author Kimseungtae
   *  @date   20230426
   *  @description  CHNREMS용 state
   */
  const [state, setState] = useState({
    areaType: AREA_TYPE_RGN, //CITY:시도, RGN:시군구, DONG:읍면동
    rgnCode: "",
    rgnTerm: "",
    dongCode: "",
    dongTerm: "",
    areaEnergyList: [],
    totalEnergy: {
      dayPower: 0,
      dayPowerCo2: 0,
      powerCapa: 0,
      accumPower: 0,
      dayUse: 0,
      dayUseCo2: 0,
      heatCapa: 0,
      heathCapa: 0,
      accumUse: 0,
    },
    meatinStatCnt: [],
  });

  /**
   *  @author Kimseungtae
   *  @date   20230426
   *  @description  REMS용 state
   */
  const [remsState, setRemsState] = useState({
    areaType: AREA_TYPE_RGN, //CITY:시도, RGN:시군구, DONG:읍면동
    rgnCode: "",
    rgnTerm: "",
    areaTerm: "",
    dongCode: "",
    dongTerm: "",
    areaEnergyList: [],
    totalEnergy: {
      dayPower: 0,
      dayPowerCo2: 0,
      powerCapa: 0,
      accumPower: 0,
      dayUse: 0,
      dayUseCo2: 0,
      heatCapa: 0,
      heathCapa: 0,
      accumUse: 0,
    },
    meatinStatCnt: [],
  });

  /**
   *  @author Kimseungtae
   *  @date   20230426
   *  @description  CHNREMS + REMS state
   */
  const [totalState, setTotalState] = useState({
    areaType: AREA_TYPE_RGN, //CITY:시도, RGN:시군구, DONG:읍면동
    rgnCode: "",
    rgnTerm: "",
    dongCode: "",
    dongTerm: "",
    areaEnergyList: [],
    totalEnergy: {
      dayPower: 0,
      dayPowerCo2: 0,
      powerCapa: 0,
      accumPower: 0,
      dayUse: 0,
      dayUseCo2: 0,
      heatCapa: 0,
      heathCapa: 0,
      accumUse: 0,
    },
    meatinStatCnt: [],
  });

  const [stateType, setStateType] = useState({
    areaType: AREA_TYPE_RGN, //CITY:시도, RGN:시군구, DONG:읍면동
    rgnCode: "",
    rgnTerm: "",
    dongCode: "",
    dongTerm: "",
    areaEnergyList: [],
    totalEnergy: {
      dayPower: 0,
      dayPowerCo2: 0,
      powerCapa: 0,
      accumPower: 0,
      dayUse: 0,
      dayUseCo2: 0,
      heatCapa: 0,
      heathCapa: 0,
      accumUse: 0,
    },
    meatinStatCnt: [],
  });

  useEffect(() => {    
    if (getUser().userTypeCode === 2001) {
      history.push("/monitor/installerMeain");
    }
    //담당자인데 관리하는 지역이 하나라면 바로 동으로 조회하게 함
    if (getUser().userTypeCode === 2002 && getUser().userMngRgnList.length === 1) {
      const mngRgn = rgnList.find((e) => e.rgnCode === getUser().userMngRgnList[0]);
      getAreaEnergy({
        areaType: AREA_TYPE_DONG,
        rgnCode: mngRgn.rgnCode,
        rgnTerm: mngRgn.rgnTermSimple,
        dongCode: mngRgn.dongCode,
        dongTerm: mngRgn.dongTerm,
      });
    } else {
      getAreaEnergy({
        areaType: AREA_TYPE_RGN,
        rgnCode: "",
        rgnTerm: "",
      });
    }

    getRemsAreaEnergy({
      areaType: AREA_TYPE_RGN,
      rgnCode: "",
      rgnTerm: "",
    });
    
    setInterval();
    // setError(true);    
  }, [today]);  

  useEffect(() => {
    setStateType({...state});
  }, [state]);

  const selectStateType = e => {    
    if(e.value === '충남REMS') {
      setStateType({...state});
    } else if(e.value === '공단REMS') {
      setStateType({...remsState});
    } else if(e.value === '합계') {
      setStateType({...totalState});    
    }
  }

  const getAreaEnergy = (selectedArea, flag) => {
    // 스피너
    setAreaGridLoading(true);
    Promise.all([getAreaEnergyList(conn, selectedArea), getMeainStatCntList(conn, selectedArea)]) //
      .then((results) => {
        console.log("getAreaEnergy results = ", results);
        // 스피너
        setAreaGridLoading(false);
        // 데이터 세팅
        setState((state) => {          
          const item = { ...state };
          item.areaType = selectedArea.areaType;
          item.rgnCode = selectedArea.rgnCode;
          item.rgnTerm = selectedArea.rgnTerm;
          item.areaEnergyList = results[0].areaEnergyList;
          item.totalEnergy = results[0].areaEnergyTotal;
          item.meatinStatCnt = results[1];
          return item;
        }); 
        
        if(flag) {
          setStateType((stateType) => {
            const item = {...stateType};            
            item.areaType = selectedArea.areaType;
            item.rgnCode = selectedArea.rgnCode;
            item.rgnTerm = selectedArea.rgnTerm;
            item.areaEnergyList = results[0].areaEnergyList;
            item.totalEnergy = results[0].areaEnergyTotal;
            item.meatinStatCnt = results[1];
            return item;
          });
        }
        
        // setRemsState((remsState) => {
        //   const item = {...remsState};
        //   item.areaType = selectedArea.areaType;
        //   item.rgnCode = selectedArea.rgnCode;
        //   item.rgnTerm = selectedArea.rgnTerm;
        //   item.areaEnergyList = results[2].areaEnergyList;    
        //   item.totalEnergy = results[2].areaEnergyTotal;  
        //   item.meatinStatCnt = results[3];
        //   return item;              
        // }); 

        // setTotalState((totalState) => {
        //   const item = {...totalState};
        //   item.areaType = selectedArea.areaType;
        //   item.rgnCode = selectedArea.rgnCode;
        //   item.rgnTerm = selectedArea.rgnTerm;
        //   item.areaEnergyList = results[4].areaEnergyList;
        //   item.totalEnergy = results[4].areaEnergyTotal; 
        //   item.meatinStatCnt = results[5];          

        //   return item;          
        // });            
      });
  };  

  const getRemsAreaEnergy = (selectedArea) => {
    // 스피너
    setAreaGridLoading(true);
    Promise.all([getRemsAreaEnergyList(conn, selectedArea), getRemsMeainStatCntList(conn, selectedArea), 
      getTotalAreaEnergyList(conn, selectedArea), getTotalMeainStatCntList(conn, selectedArea)]) //
      .then((results) => {
        console.log("getRemsAreaEnergy results = ", results);
        // 스피너
        setAreaGridLoading(false);
                
        setRemsState((remsState) => {
          const item = {...remsState};
          item.areaType = selectedArea.areaType;
          item.rgnCode = selectedArea.rgnCode;
          item.rgnTerm = selectedArea.rgnTerm;
          item.areaEnergyList = results[0].areaEnergyList;    
          item.totalEnergy = results[0].areaEnergyTotal;  
          item.meatinStatCnt = results[1];
          return item;              
        }); 

        setTotalState((totalState) => {
          const item = {...totalState};
          item.areaType = selectedArea.areaType;
          item.rgnCode = selectedArea.rgnCode;
          item.rgnTerm = selectedArea.rgnTerm;
          item.areaEnergyList = results[2].areaEnergyList;
          item.totalEnergy = results[2].areaEnergyTotal; 
          item.meatinStatCnt = results[3];          

          return item;          
        });            
      });    
  };

  const setCityClickEvt = (e) => {
    e.preventDefault();
    getAreaEnergy({
      areaType: AREA_TYPE_RGN,
      rgnCode: "",
      rgnTerm: "",
    }, true);
  };

  // 지역별현황 그리드 ROW 클릭 시
  const setRgnClickEvt = (e) => {    
    let rgn = e.dataItem;
    let dong = e.dataItem.dongCode;
    getAreaEnergy({
      areaType: AREA_TYPE_DONG,
      rgnCode: rgn.rgnCode,
      rgnTerm: rgn.rgnTerm,
      dongCode: rgn.dongCode,
      dongTerm: rgn.dongTerm,
    }, true);

    if (isAdmin() || isManager()) {
      if (dong) {
        history.push("/monitor/installerMeain?rgnCode=" + rgn.rgnCode + "&dongCode=" + rgn.dongCode);
      }
    } else {
      if (state.areaType === AREA_TYPE_DONG) return;
    }
  };

  // 운영현황 클릭 시 이동
  const setOperClickEvt = (e) => {
    if (isAdmin() || isManager()) {
      history.push("/monitor/installerMeain?rgnCode=" + state.rgnCode + "&dongCode=" + state.dongCode + "&dongTerm=" + state.dongTerm);
    } else {
      return;
    }
  };

  // 운영현황의 에너지원 클릭 시 이동
  const setOperCntClickEvt = (e) => {
    let setEnsoTypeCode = e.target.classList[0];
    setEnsoTypeCode = setEnsoTypeCode.replace("cnt-header-", "");
    const setStateCode = e.target.classList[1];
    let statNormal = false;
    let statUnOper = false;
    let statReady = false;
    let statWarn = false;
    let statError = false;

    if (setStateCode == 7002) {
      statNormal = true;
    } else if (setStateCode == 7001) {
      statReady = true;
    } else if (setStateCode == 7006) {
      statWarn = true;
    } else if (setStateCode == 7004) {
      statUnOper = true;
    } else if (setStateCode == 7007) {
      statError = true;
    } else if (setStateCode == 7000) {
      statNormal = true;
      statReady = true;
      statWarn = true;
      statUnOper = true;
      statError = true;
    }

    if (isAdmin() || isManager()) {
      history.push(
        "/monitor/installerMeain?rgnCode=" +
          state.rgnCode +
          "&ensoTypeCode=" +
          setEnsoTypeCode +
          "&statNormal=" +
          statNormal +
          "&statUnOper=" +
          statUnOper +
          "&statReady=" +
          statReady +
          "&statWarn=" +
          statWarn +
          "&statError=" +
          statError,
      );
    } else {
      return;
    }
  };

  //FIXME common 으로 이동할 것
  const setColumnSum = (props) => {     
    // const sum = state.areaEnergyList.reduce((acc, current) => acc + current[props.field], 0);
    const sum = stateType.areaEnergyList.reduce((acc, current) => acc + current[props.field], 0);
    return (
      <td colSpan={props.colSpan} style={{ textAlign: "right", paddingRight: "7px" }}>
        {kendo.format("{0:n0}", sum)}
      </td>
    );
  };

  //FIXME common 으로 이동할 것
  const setColumnNumberFormat = (data) => {
    const field = data.field;
    let value = "-";
    // 동적으로 객체 존재를 확인 하고 있다면 포맷 적용 (undefined 와 null 체크 )
    if (data.dataItem.hasOwnProperty(field) && data.dataItem[field] != null) {
      if (field === "errCode") {
        value = data.dataItem[field];
      } else {
        value = kendo.format("{0:n1}", data.dataItem[field]);
      }
    }
    if (value == 0) {
      value = "-";
    }
    return <td style={{ textAlign: "right", paddingRight: "10px" }}>{value}</td>;
  };

  const getEnsoTerm = (ensoTypeCode) => {
    let code = codeList.find((code) => code.codeNo === ensoTypeCode);
    return code ? code.codeVal : "전체";
  };

  //10분마다 데이터 새로고침
  const setInterval = () => {
    setTimeout(() => {
      setToday(new Date());
    }, 10 * 60 * 1000);
  };

  // 해당 유저 타입이 관리자, 개발자 인지 확인
  const isAdmin = () => {
    let isFalse = false;
    if (loginUserTypeCode === 2003 || loginUserTypeCode === 2005) {
      isFalse = true;
    }
    return isFalse;
  };

  return (
    <>
      {isMobile === "Y" ? (
        <AreaEnergyMobPrst
          selected={selected}
          handleSelect={handleSelect}
          areaGridLoading={areaGridLoading}
          setCityClickEvt={setCityClickEvt}
          cityTerm={cityTerm}
          state={state}
          setRgnClickEvt={setRgnClickEvt}
          setColumnNumberFormat={setColumnNumberFormat}
          setColumnSum={setColumnSum}
          setOperClickEvt={setOperClickEvt}
          setOperCntClickEvt={setOperCntClickEvt}
          getEnsoTerm={getEnsoTerm}
        ></AreaEnergyMobPrst>
      ) : (
        <AreaEnergyPrst
          areaGridLoading={areaGridLoading}
          setCityClickEvt={setCityClickEvt}
          cityTerm={cityTerm}
          // state={state}
          // remsState={remsState}
          // totalState={totalState}         
          stateType={stateType}
          selectStateType={selectStateType}                   
          setRgnClickEvt={setRgnClickEvt}          
          setColumnNumberFormat={setColumnNumberFormat}
          setColumnSum={setColumnSum}
          setOperClickEvt={setOperClickEvt}
          setOperCntClickEvt={setOperCntClickEvt}
          getEnsoTerm={getEnsoTerm}
        ></AreaEnergyPrst>
      )}
    </>
  );
};

export default AreaEnergy;
