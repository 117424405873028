import React, { useState, useEffect, memo } from "react";
import kendo from "@progress/kendo-ui";

import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import { orderBy } from "@progress/kendo-data-query";

import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";

import { Button } from "@progress/kendo-react-buttons";
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";

const OperationStatMeainModl = ({ styles, dataSource, setSearchClickEvt, setSearch }) => {
  // 페이징, 정렬 상태
  const [paging, setPaging] = useState({ take: 10, skip: 0 });
  const [sort, setSort] = useState([{ field: "", dir: "" }]);

  // 페이징 이벤트
  const onPageChange = (e) => {
    const { page } = e;
    setPaging(page);
  };

  // 정렬 이벤트
  const onSortChange = (e) => {
    const { sort } = e;
    setSort(sort);
  };

  // 엑셀 파일 이름
  let [fileName, setFileNameExcel] = useState("");

  function setFileName() {
    let template = "미작동/고장 이력 설비모델별 현황" + kendo.toString(new Date(), "yyyyMMdd") + ".xlsx";
    return template;
  }

  // 엑셀 다운로드
  let _export;
  const downloadExcel = () => {
    setFileNameExcel(setFileName());
    _export.save();
  };

  const noCell = (props) => {
    let dataIndex = props.dataIndex + 1;
    return <td style={{ textAlign: "center" }}>{dataIndex}</td>;
  };

  const cntCell = (data) => {
    var value = "-";
    if (data.dataItem.invCnt >= 0) {
      value = data.dataItem.invCnt;
    }
    return <td style={{ textAlign: "right" }}>{value}</td>;
  };
  return (
    <>
      <div className="md-card-content">
        <div className="md-card-toolbar">
          <h3 className="md-card-toolbar-heading-text">설비모델별 현황</h3>
          <div className="md-card-toolbar-actions">
            <Button icon="excel" className={styles.nonOperHistExcelBtn} onClick={downloadExcel}>
              엑셀저장
            </Button>
          </div>
        </div>
        <ExcelExport data={orderBy(dataSource, sort)} ref={(exporter) => (_export = exporter)} fileName={fileName}>
          <ExcelExportColumn field="makrTerm" title="제조사" width={250} />
          <ExcelExportColumn field="meainTypeTerm" title="설비모델" width={150} />
          <ExcelExportColumn field="invCnt" title="미작동 / 고장 개소" width={150} />
        </ExcelExport>
        <LocalizationProvider language="ko-KR">
          <IntlProvider locale="ko">
            <Grid
              className="nonOperHistGrid"
              style={{ height: "420px" }}
              data={orderBy(dataSource, sort).slice(paging.skip, paging.take + paging.skip)}
              total={dataSource.length}
              skip={paging.skip}
              take={paging.take}
              onPageChange={onPageChange}
              onSortChange={onSortChange}
              sort={sort}
              page={paging}
              sortable={true}
              pageable={{
                buttonCount: 4,
                pageSizes: [5, 10, 20, 50, 100],
              }}
              resizable={true}
              reorderable={true}
              selectable="row"
            >
              <Column title="No" width="60px" cell={noCell}></Column>
              <Column title="제조사" field="makrTerm"></Column>
              <Column title="설비모델" field="meainTypeTerm"></Column>
              <Column title="미작동 / 고장 개소" field="invCnt" cell={cntCell}></Column>
            </Grid>
          </IntlProvider>
        </LocalizationProvider>
      </div>
    </>
  );
};
export default OperationStatMeainModl;
