// React
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { parseDate, subDate } from "../../../../com/dateUtil";

// Kendo
import kendo from "@progress/kendo-ui";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { IntlProvider, LocalizationProvider, loadMessages } from "@progress/kendo-react-intl";
import { Button } from "@progress/kendo-react-buttons";
import { Grid, GridColumn, GridNoRecords, GridToolbar } from "@progress/kendo-react-grid";
import { Search } from "@material-ui/icons";

// Service
import DatePickerKo from "../../../com/dateInputs/datePickerKo";
import { isInstaller } from "../../../../service/authService";
import {
  getMakrDashboard,
  getMakrList,
  getMakrModel,
  getMakrCharts,
  getMakrEventCntCharts,
  getExcelDownload,
} from "../../../../service/stats/event/makrEventService";

// Css
import "./makr.css";
import styles from "./makr.module.css";

import { MakrDashboard } from "./makrDashboard";

// 로딩 스피너
import { BeatLoader } from "react-spinners";

function Makr({ conn, codeList, rgnList }) {
  const [loading, setLoading] = useState(false);

  // 페이징
  const [paging, setPaging] = useState({ take: 10, skip: 0 });
  // 정렬
  const [sort, setSort] = useState([{ field: "ocurDtm", dir: "desc" }]);
  // 페이징 이벤트
  const onPageChange = (e) => {
    paging.skip = e.page.skip;
    paging.take = e.page.take;
    setMakrList();
  };
  // 정렬 이벤트
  const onSortChange = (e) => {
    const { sort } = e;
    setSort(sort);
  };

  // 날짜 Default 정의
  let today = new Date();
  let yesterday = new Date();
  let lastWeek = new Date();
  let lastMonth = new Date();
  let lastYear = new Date();
  yesterday.setDate(today.getDate() - 1);
  lastWeek.setDate(yesterday.getDate() - 7);
  lastMonth.setDate(yesterday.getDate() - 30);
  lastYear.setDate(yesterday.getDate() - 365);

  // 검색 조건
  const [search, setSearch] = useState({
    ensoTypeCodeNo: 15001,
    rgnCode: "",
    startGathDtm: "",
    endGathDtm: "",
    start: new Date(),
    end: new Date(),
    endMin: new Date(),
    startMax: new Date(),
    dateState: { codeNo: 23001, codeVal: "오늘" },
  });

  // 검색버튼 클릭 후 저장되는 데이터
  const [searchClick, setSearchClick] = useState({
    ensoTypeCodeNo: 15001,
    rgnCode: "",
    start: new Date(),
    end: new Date(),
  });

  var rgns = rgnList;
  const defaultValRgn = { rgnTermSimple: "전체", rgnCode: "" };
  const ensoList = codeList.filter((c) => c.grpCodeNo === 15);
  const dateList = codeList.filter((c) => c.grpCodeNo === 23 && c.codeNo !== 23001);

  // 데이터 소스
  const [dataSource, setDataSource] = useState({
    dashboard: [], // Dashboard
    list: [], // List
    listTotal: 0,
    xCategories: [], // 시간대별 Charts
    wawrData: [],
    errData: [],
    eventCntCharts: [], // 제조사별 이벤트발생건수 Charts
    data: [],
    tmData: [],
    etc: 0,
    tmEtc: 0,
    drilldownData: [], // 모델별 이벤트발생건수 Charts
    drilldownTmData: [],
  });

  // 날짜검색
  const dateCate = useMemo(() => codeList.filter((item) => item.grpCodeNo === 23), [codeList]);

  const [category, setCategory] = useState({
    dateList: "오늘",
    startDate: parseDate("yyyy-mm-dd"),
    endDate: parseDate("yyyy-mm-dd"),
    start: kendo.toString(new Date(), "yyyyMMdd"),
    end: kendo.toString(new Date(), "yyyyMMdd"),
  });

  // 에너지원 및 지역 변경 이벤트
  const searchData = (e) => {
    console.log("e = ", e);
    if (e.target.name == "ensoTypeCodeNo") {
      setSearch((state) => {
        const item = { ...state };
        item.ensoTypeCodeNo = e.target.value.codeNo;
        return item;
      });
    } else if (e.target.name == "rgn") {
      setSearch((state) => {
        const item = { ...state };
        item.rgnCode = e.target.value.rgnCode;
        return item;
      });
    }
  };

  // // 시작 날짜 변경 이벤트
  // const handleStartDateChange = useCallback(e => {
  //   const {value} = e.target;
  //   setCategory({ ...category, startDate: parseDate('yyyy-mm-dd', value) });

  //   setCategory(start => {
  //     const item = { ...start };
  //     item.start = kendo.toString(e.value, "yyyyMMdd");
  //     return item;
  //   })
  // }, [category]);

  // // 끝 날짜 변경 이벤트
  // const handleEndDateChange = useCallback( e => {
  //   const {value} = e.target;
  //   setCategory({ ...category, endDate: parseDate('yyyy-mm-dd', value) });

  //   setCategory(end => {
  //     const item = { ...end };
  //     item.end = kendo.toString(e.value, "yyyyMMdd");
  //     return item;
  //   })
  // }, [category]);

  // 드롭다운 이벤트
  const onChangeEvt = (e) => {
    // 날짜 검색
    if (e.target.name === "dateState") {
      if (e.target.value.codeNo === 23001) {
        setSearch((state) => {
          const item = { ...state };
          item.start = today;
          item.dateState = e.target.value;
          return item;
        });
      } else if (e.target.value.codeNo === 23002) {
        setSearch((state) => {
          const item = { ...state };
          item.start = yesterday;
          item.dateState = e.target.value;
          return item;
        });
      } else if (e.target.value.codeNo === 23003) {
        setSearch((state) => {
          const item = { ...state };
          item.start = lastWeek;
          item.dateState = e.target.value;
          return item;
        });
      } else if (e.target.value.codeNo === 23004) {
        setSearch((state) => {
          const item = { ...state };
          item.start = lastMonth;
          item.dateState = e.target.value;
          return item;
        });
      } else if (e.target.value.codeNo === 23005) {
        setSearch((state) => {
          const item = { ...state };
          item.start = lastYear;
          item.dateState = e.target.value;
          return item;
        });
      } else if (e.target.value.codeNo === 23006) {
        setSearch((state) => {
          const item = { ...state };
          item.start = today;
          item.dateState = e.target.value;
          return item;
        });
      }
    }
  };

  const downloadExcel = () => {
    getExcelDownload(search, paging);
  };

  const [bool, setBool] = useState(false);

  // 조회 클릭
  const setSearchClickEvt = (e) => {
    paging.take = 10;
    paging.skip = 0;

    setSearchClick((state) => {
      const item = { ...state };
      item.ensoTypeCodeNo = search.ensoTypeCodeNo;
      item.rgnCode = search.rgnCode;
      item.start = search.start;
      item.end = search.end;
      return item;
    });

    setMakrEventCntCharts();
    setMakrCharts();
    setMakrDashboard();
    setMakrList();
    if (bool === true) {
      setMakrModel();
    }
  };

  // Dashboard
  const setMakrDashboard = () => {
    setLoading(true);
    getMakrDashboard(conn, search)
      .then((response) => {
        setDataSource((dataSource) => {
          const item = { ...dataSource };
          item.dashboard = response;
          return item;
        });
        // setLoading(false);
      })
      .catch((error) => {
        // setLoading(false);
      });
  };

  // List
  const setMakrList = () => {
    setLoading(true);
    getMakrList(conn, search, paging)
      .then((response) => {
        setDataSource((dataSource) => {
          const item = { ...dataSource };
          item.list = response.results;
          item.listTotal = response.total;
          return item;
        });
        setLoading(false);
      })
      .catch((error) => {
        console.log("error = ", error);
        setLoading(false);
      });
  };

  // Model
  const setMakrModel = () => {
    setLoading(true);
    getMakrModel(conn, search)
      .then((response) => {
        setDataSource((dataSource) => {
          const item = { ...dataSource };
          item.model = response;
          return item;
        });
        var drilldownData = [],
          drilldownTmData = [];
        for (var ente in dataSource.eventCntCharts) {
          var seriesData = [];
          var seriesTmData = [];
          for (var idx in response) {
            if (dataSource.eventCntCharts[ente].makrTerm === response[idx].makrTerm) {
              seriesData.push([response[idx].meainTypeTerm, response[idx].ttl]);
              seriesTmData.push([response[idx].meainTypeTerm, response[idx].manaTm]);
            }
          }
          drilldownData.push({
            name: dataSource.eventCntCharts[ente].makrTerm,
            id: dataSource.eventCntCharts[ente].makrSeriNo,
            data: seriesData,
          });
          drilldownTmData.push({
            name: dataSource.eventCntCharts[ente].makrTerm,
            id: dataSource.eventCntCharts[ente].makrSeriNo,
            data: seriesTmData,
          });
        }
        setDataSource((dataSource) => {
          const item = { ...dataSource };
          item.drilldownData = drilldownData;
          item.drilldownTmData = drilldownTmData;
          return item;
        });
        setLoading(false);
      })
      .catch((error) => {
        console.log("error = ", error);
        setLoading(false);
      });
  };

  // 시간대별 Charts
  const setMakrCharts = () => {
    setLoading(true);
    getMakrCharts(conn, search)
      .then((response) => {
        const xCategories = [];
        const warnData = [];
        const errData = [];
        for (var i = 0; i < 24; i++) {
          let errCnt = 0;
          let warnCnt = 0;
          let name = "";
          response.forEach((element) => {
            if (i < 10) {
              name = "0" + i;
            } else {
              name = String(i);
            }
            if (name == element.hh) {
              errCnt = element.err;
              warnCnt = element.warn;
            }
          });
          warnData.push({ name: name, y: warnCnt });
          errData.push({ name: name, y: errCnt });
          xCategories.push(name);
        }
        setDataSource((dataSource) => {
          const item = { ...dataSource };
          item.xCategories = xCategories;
          item.warnData = warnData;
          item.errData = errData;
          return item;
        });
        // setLoading(false);
      })
      .catch((error) => {
        console.log("error = ", error);
      });
  };

  // 이벤트 발생건수 charts
  const setMakrEventCntCharts = () => {
    setLoading(true);
    setBool(true);
    getMakrEventCntCharts(conn, search, paging)
      .then((response) => {
        // setLoading(false);
        setDataSource((dataSource) => {
          const item = { ...dataSource };
          item.eventCntCharts = response;
          return item;
        });
        dataSource.eventCntCharts = response;
        var data = [],
          tmData = [];
        var etc = 0,
          tmEtc = 0;
        for (var idx in response) {
          // 발생건수
          if (response[idx].ttl > 5) {
            data.push({
              name: response[idx].makrTerm,
              y: response[idx].ttl,
              drilldown: response[idx].makrSeriNo,
              makrSeriNo: response[idx].makrSeriNo,
            });
          } else {
            etc = etc + response[idx].ttl;
          }
          // 조치시간
          if (response[idx].manaTm > 60) {
            tmData.push({
              name: response[idx].makrTerm,
              y: response[idx].manaTm,
              drilldown: response[idx].makrSeriNo,
            });
          } else {
            tmEtc = tmEtc + response[idx].manaTm;
          }
        }

        if (etc > 0) {
          data.push({
            name: "기타",
            y: etc,
            drilldown: null,
          });
        }
        tmData.push({
          name: "기타",
          y: tmEtc,
          drilldown: null,
        });
        setDataSource((dataSource) => {
          const item = { ...dataSource };
          item.data = data;
          item.tmData = tmData;
          item.etc = etc;
          item.tmEtc = tmEtc;
          return item;
        });
      })
      .catch((error) => {
        console.log("error = ", error);
      });
  };

  useEffect(() => {
    setMakrModel();
    setMakrDashboard();
    setMakrList();
    setMakrCharts();
    setMakrEventCntCharts();
  }, []);

  useEffect(() => {
    setMakrDashboard();
    setMakrList();
    setMakrCharts();
    setMakrEventCntCharts();
  }, []);

  return (
    <>
      <div className="makrBody">
        <LocalizationProvider language="ko-KR">
          <IntlProvider locale="ko">
            <div className="makrCate">
              <span className="searchSubTitleA">에너지원</span>
              <DropDownList
                data={ensoList.filter((e) => e.codeNo !== 15007)}
                defaultValue={{ codeVal: "태양광", codeNo: 15001 }}
                name="ensoTypeCodeNo"
                textField="codeVal"
                dataItemKey="codeNo"
                onChange={searchData}
                className="searchArea"
                // disabled = { isInstaller() === true ? true : false }
              ></DropDownList>
              <span className="searchSubTitleB">지역검색</span>
              <DropDownList
                data={rgns}
                textField="rgnTermSimple"
                name="rgn"
                dataItemKey="rgnCode"
                defaultItem={defaultValRgn}
                onChange={searchData}
                disabled={isInstaller() === true ? true : false}
              />
              <span className="searchSubTitleB">날짜검색</span>
              <span>
                <DropDownList
                  data={dateList}
                  dataItemKey="codeNo"
                  textField="codeVal"
                  name="dateState"
                  value={search.dateState}
                  defaultValue={{ codeNo: 23001, codeVal: "오늘" }}
                  onChange={onChangeEvt}
                />
              </span>
              <span className="searchSubTitleB">조회기간</span>
              <span>
                <DatePickerKo
                  id="start"
                  name="start"
                  className="datePicker"
                  defaultValue={today}
                  format="yyyy-MM-dd "
                  setDtm={(v) => {
                    setSearch((search) => {
                      const item = { ...search };
                      item.endMin = v;
                      item.start = v;
                      item.dateState = { codeNo: 23006, codeVal: "직접조회" };
                      return item;
                    });
                  }}
                  max={search.startMax}
                  value={search.start}
                />
              </span>{" "}
              ~
              <span>
                <DatePickerKo
                  id="end"
                  name="end"
                  className="datePicker"
                  defaultValue={today}
                  format="yyyy-MM-dd "
                  setDtm={(v) => {
                    setSearch((search) => {
                      const item = { ...search };
                      item.startMax = v;
                      item.end = v;
                      return item;
                    });
                  }}
                  min={search.endMin}
                  max={today}
                />
              </span>
              <span style={{ paddingLeft: 10 }}>
                <Button className="searchBtn" onClick={setSearchClickEvt}>
                  <Search className="material-icons" />
                </Button>
              </span>
            </div>
          </IntlProvider>
        </LocalizationProvider>
        <div>
          <MakrDashboard
            styles={styles}
            dataSource={dataSource}
            searchClick={searchClick}
            paging={paging}
            sort={sort}
            onPageChange={onPageChange}
            onSortChange={onSortChange}
            downloadExcel={downloadExcel}
            loading={loading}
          />
        </div>
        {loading && (
          <p
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
            }}
          >
            <BeatLoader loading={true} size={24} color="#1e88e5" />
          </p>
        )}
      </div>
    </>
  );
}
export default Makr;
