// React
import React, { useEffect, useRef, useState } from "react";
import { BeatLoader } from "react-spinners";

// Kendo
import kendo from "@progress/kendo-ui";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Form, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";

import AddlHistoryComment from "./addlHistoryComment";
import { Input } from "@progress/kendo-react-inputs";

const MonitorASModal = ({
  conn,
  title,
  item,
  cancelEdit,
  onSubmit,
  openMeainDetailInfo,
  confirmAddlAgreements,
  styles,
  getMeainMngtLogList,
  meainMngtLogItem,
  monitorASModalLoading,
  deleteAddlHistoryComment,
}) => {
  const scrollRef = useRef();
  const [addlHistoryText, setAddlHistoryText] = useState("");
  const addlHistoryOnchange = (e) => {
    setAddlHistoryText(e.value);
  };

  const submitBtnClick = () => {
    if (addlHistoryText === "") {
      kendo.alert("추가기록을 작성해주세요");
    } else {
      kendo.confirm("추가기록을 등록하시겠습니까?").then(() => {
        onSubmit(addlHistoryText);
        setAddlHistoryText("");
        scrollRef.current.scrollTop = 0;
      });
    }
  };

  useEffect(() => {
    getMeainMngtLogList(item);
  }, []);

  return (
    <Dialog title={title} onClose={cancelEdit} width={500}>
      <Form
        render={() => (
          <FormElement>
            <div className="monitorASModalBtnSpace">
              {item.editInfo.charAt(item.editInfo.length - 1) === "Y" ? (
                <Button type="button" onClick={confirmAddlAgreements} className={styles.addlHistoryPopUpBtn}>
                  추가계약 취소
                </Button>
              ) : (
                <Button type="button" onClick={confirmAddlAgreements} className={styles.addlHistoryPopUpBtn}>
                  추가계약
                </Button>
              )}
              <Button type="button" onClick={openMeainDetailInfo} className={styles.addlHistoryPopUpBtn}>
                설비 상세정보
              </Button>
            </div>
            <div className={monitorASModalLoading ? "loadingState" : null}>
              <div className={styles.addlHistoryCommentBox} ref={scrollRef}>
                {meainMngtLogItem.length ? (
                  meainMngtLogItem.map((meainMngtLog) => (
                    <AddlHistoryComment
                      conn={conn}
                      meainMngtLog={meainMngtLog}
                      key={meainMngtLog.id}
                      styles={styles}
                      deleteAddlHistoryComment={deleteAddlHistoryComment}
                    />
                  ))
                ) : (
                  <div className={styles.noHistoryCommentBox}>
                    관리이력이 없습니다. <br />
                    기록을 남겨주세요.
                  </div>
                )}
              </div>
              {monitorASModalLoading && (
                <p className={styles.monitorASModalLoading}>
                  <BeatLoader loading={true} size={24} color="#1e88e5" />
                </p>
              )}
            </div>
            <div className="mb-3 addlHistoryBox">
              <Input
                name="addlHistory"
                placeholder="추가기록을 남겨주세요"
                value={addlHistoryText}
                className={styles.addlHistoryInputBox}
                onChange={addlHistoryOnchange}
              />
              <Button type={"submit"} className={styles.addlHistoryAddBtn} onClick={submitBtnClick}>
                등록
              </Button>
            </div>
          </FormElement>
        )}
      />
    </Dialog>
  );
};

export default MonitorASModal;
