import React, { useEffect, useRef, useState } from "react";
import RenewStatPrzt from "./renewStatPrzt";
import { getRenewBizStatDtoList, getRenewDashboardList, getRenewEnsoStatDtoList, getRenewMonthPowerData, getRenewOperCntDtoList, getRenewTop3EnsoStatDtoList } from "./../../../service/monitor/renewStatService";
// 로딩 스피너
import { BeatLoader } from "react-spinners";
//css
import styles from "./renewStat.module.css";

const RenewStat = (props) => {
  const [dashData, setDashData] = useState([]);
  const [operCntData, setOperCntData] = useState([]);
  const [ensoStatData, setEnsoStatData] = useState([]);
  const [bizStatData, setBizStatData] = useState([]);
  const [top3EnsoStatData, setTop3EnsoStatData] = useState([]);
  const [today, setToday] = useState(new Date());
  const [monthStatData, setMonthStatData] =useState([]);

  const [currMap, setCurrMap] = useState({
    rgnCode: "",
    rgnTerm: "충청남도",
    color: "#DCDCDC",
  });
  const [currTab, setCurrTab] = useState(0);
  const depthBoxRef = useRef(null);
  const realPowerBoxRef = useRef(null);
  const estPowerBoxRef = useRef(null);
  const realUseBoxRef = useRef(null);
  const carbDecBoxRef = useRef(null);
  const [loading, setLoading] = useState(false);

  /*  지도에 마우스 오버 이벤트 */
  const mapOverEvt = (e) => {
    const { currentTarget } = e;
    let targetAreaId = currentTarget.id.substring(8);
    if (targetAreaId === currMap.rgnCode) {
      depthBoxRef.current.style.display = "none";
    } else {
      depthBoxRef.current.style.display = "block";
      depthBoxRef.current.style.top = e.pageY + 15 + "px";
      depthBoxRef.current.style.left = e.pageX + 15 + "px";
    }
  };
  /* 지도에서 마우스 벗어났을 때 이벤트 */
  const mapOutEvt = (e) => {
    // setFixMapVis(true);
    depthBoxRef.current.style.display = "none";
  };
  /* 지도 클릭 이벤트 */
  const mapClickEvt = (e) => {
    depthBoxRef.current.style.display = "none";
    const { currentTarget } = e;
    let targetAreaId = currentTarget.id.substring(8);
    const comp = document.getElementById("rgnCode_" + targetAreaId);
    const compTabl = document.getElementById("rgnHead_" + targetAreaId);
    /* 같은거 클릭했을 때 */
    if (currMap.rgnCode === targetAreaId) {
      comp.style.fill = currMap.color;
      compTabl.style.background = "#428bca";
      setCurrMap({ rgnCode: "", color: "#DCDCDC", rgnTerm: "충청남도" });
    } /* 맨처음 클릭했을 때 */ else if (!currMap.rgnCode) {
      setCurrMap({ rgnCode: targetAreaId, color: comp.style.fill, rgnTerm: rgnList.find((e) => e.rgnCode === targetAreaId).rgnTermSimple });
      comp.style.fill = "url(#overGra)";
      compTabl.style.background = "linear-gradient(#FF9A57, #E8613B)";
    } /* 맨처음이 아니고 다른거 클릭했을 때 */ else {
      document.getElementById("rgnCode_" + currMap.rgnCode).style.fill = currMap.color;
      document.getElementById("rgnHead_" + currMap.rgnCode).style.background = "#428bca";
      setCurrMap({ rgnCode: targetAreaId, color: comp.style.fill, rgnTerm: rgnList.find((e) => e.rgnCode === targetAreaId).rgnTermSimple });
      comp.style.fill = "url(#overGra)";
      compTabl.style.background = "linear-gradient(#FF9A57, #E8613B)";
    }
  };

  /**
   * 각 동별로 맵의 색상 칠하기(발전량 비율별)
   * @param {*} result getDongRankPer조회 result
   */
  const fillColorRgnRank = (result) => {
    setLoading(false);
    const getMapColorByPer = (e) => {
      if (e < 20) return "url(#top20)";
      else if (e >= 20 && e < 40) return "url(#top40)";
      else if (e >= 40 && e < 80) return "url(#top60)";
      else if (e >= 80 && e <= 100) return "url(#top80)";
      else return "#e1e1e1";
    };
    result.forEach((e) => {
      /**
       * 만약 클릭되어있는 값이 있다면 그대로 두기
       */
      if (e.rgnCode === currMap.rgnCode) {
        return;
      }
      const rgnMap = document.getElementById("rgnCode_" + e.rgnCode);
      if (!rgnMap) return;
      const mapColor = getMapColorByPer(e.rankPer);
      rgnMap.style.fill = mapColor;
    });
  };

  //10분마다 데이터 새로고침
  const setInterval = () => {
    setTimeout(() => {
      setToday(new Date());
    }, 10 * 60 * 1000);
  };

  useEffect(() => {
    getRenewDashboardList(conn)
      .then((r) => {
        console.log(r);
        setDashData(r);
        fillColorRgnRank(r);
      })
      .catch((e) => console.log("getRenewDashboardList error"));
    setInterval();
  }, [today]);

  useEffect(() => {
    getRenewOperCntDtoList(conn, currMap.rgnCode)
      .then((r) => {
        setOperCntData(r);
      })
      .catch((e) => console.log("getRenewDashboardList error"));
    getRenewEnsoStatDtoList(conn, currMap.rgnCode)
      .then((r) => {
        setEnsoStatData(r);
      })
      .catch((e) => console.log("getRenewDashboardList error"));
    getRenewBizStatDtoList(conn, currMap.rgnCode)
      .then((r) => {
        setBizStatData(r);
      })
      .catch((e) => console.log("getRenewDashboardList error"));
    getRenewTop3EnsoStatDtoList(conn, currMap.rgnCode)
      .then((r) => {
        setTop3EnsoStatData(r);
      })
      .catch((e) => console.log("getRenewTop3EnsoStatDtoList error"));
    getRenewMonthPowerData(conn, currMap.rgnCode)
    .then((r) => {
      setMonthStatData(r);
    })
    .catch((e) => console.log("getRenewMonthPowerData error"));
  }, [currMap.rgnCode, today]);

  const prevTabBtnClickEvt = (e) => {
    if(currTab === 0) return;
    else{setCurrTab(currTab - 1)}
  }
  const nextTabBtnClickEvt = (e) => {
    if(currTab === 1) return;
    else{setCurrTab(currTab + 1)}
  }

  const { conn, rgnList } = props;

  return (
    <>
      <RenewStatPrzt
        currMap={currMap}
        mapOverEvt={mapOverEvt}
        mapOutEvt={mapOutEvt}
        mapClickEvt={mapClickEvt}
        depthBoxRef={depthBoxRef}
        dashData={dashData}
        operCntData={operCntData}
        ensoStatData={ensoStatData}
        bizStatData={bizStatData}
        top3EnsoStatData={top3EnsoStatData}
        rgnList={rgnList}
        realPowerBoxRef={realPowerBoxRef}
        estPowerBoxRef={estPowerBoxRef}
        realUseBoxRef={realUseBoxRef}
        carbDecBoxRef={carbDecBoxRef}
        prevTabBtnClickEvt={prevTabBtnClickEvt}
        nextTabBtnClickEvt={nextTabBtnClickEvt}
        currTab={currTab}
        monthStatData={monthStatData}
        setLoading = {setLoading}
      />
      <div>
        {loading && (
        <p className={styles.loading}>
          <BeatLoader loading={true} size={24} color="#1e88e5" />
        </p>
      )}
      </div>
    </>
    
  );
};

export default RenewStat;
