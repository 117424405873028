// 메인 프레임
// 다른 데이터 소스
// 1. 유저 리스트
// 2. 유저에 따른 설비 리스트
// 총 4개 데이터 소스 필요 (정보 데이터)
// 1. 시간데이터 차트 ,그리드
// 2. 지역별 비교
// 3. 대시보드 데이터
// 4. 설비별 데이터

import { useState } from "react";
import { BeatLoader } from "react-spinners";
import CmpUserByUserSearch from "./cmpUserByUserSearch";
import UserInfoWindow from "./userInfoWindow";
import "./cmpUserByUser.css";
import styles from "./cmpUserByUser.module.css";
import CmpUserByUserMeainList from "./cmpUserByUserMeainList";
import { getDataByMeainInfo, getMeainListOfUser } from "../../../../service/stats/type/userByUserDataService";
import CmpUserByUserArea from "./cmpUserByUserArea";
import CmpUserByUserMeainInfoGrid from "./cmpUserByUserMeainInfoGrid";
import CmpUserByUserDashboard from "./cmpUserByUserDashboard";
import CmpUserByUserTimeChart from "./cmpUserByUserTimeChart";
import CmpUserByUserTimeGrid from "./cmpUserByUserTimeGrid";
import kendo from "@progress/kendo-ui";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { getUserMngRgnList, isManager } from "../../../../service/authService";

const CmpUserByUser = ({ conn, codeList, rgnList, dongList }) => {
  const innerHeight = window.innerHeight;
  const innerWidth = window.innerWidth;
  const isMobile = localStorage.getItem("isMobile");
  // 로딩 스피너
  const [loading, setLoading] = useState(false);

  // 담당자 일 떄 시군구 리스트 설정
  if (isManager() === true) {
    const userMngRgnList = getUserMngRgnList();
    const rgnListfilter = [];
    rgnList.forEach((element) => {
      if (userMngRgnList.length != 0) {
        userMngRgnList.forEach((rgnCode) => {
          if (rgnCode === element.rgnCode) {
            rgnListfilter.push(element);
          }
        });
      } else {
        rgnListfilter.push(element);
      }
    });
    rgnList = rgnListfilter;
  }

  // 날짜 Default 정의
  let today = new Date();
  let yesterday = new Date();
  let lastWeek = new Date();
  let lastMonth = new Date();
  let lastYear = new Date();
  yesterday.setDate(today.getDate() - 1);
  lastWeek.setDate(yesterday.getDate() - 7);
  lastMonth.setDate(yesterday.getDate() - 30);
  lastYear.setDate(yesterday.getDate() - 365);

  // 검색 조건
  const [search, setSearch] = useState({
    userId: "",
    userTerm: "",
    dateType: "HH",
    cid: "",
    rgnCode: "",
    dongCode: "",
    startGathDtm: yesterday,
    endGathDtm: yesterday,
    endMin: yesterday,
    startMax: yesterday,
    yesterday: yesterday,
    dateStatus: { codeNo: 23006, codeVal: "직접조회" },
    userInfoVisible: false,
  });

  // 데이터 리스트 (정보)
  const [dataList, setDataList] = useState({
    meainList: [],
    dashboard: {
      instCapa: 0,
      accumPower: 0,
      efic: 0,
      dayPower: 0,
      co2: 0, // <- 사용량 기준 : 사용량 * 0.4653 발전량 : 발전량 * 0.4653
    },
    timeData: {
      // <- 시간대별 데이터
      ensoTypeCode: "",
      timeDataList: [],
      timeSeries: [],
      timeCategory: [],
    },
    timeDataListChart: [], // <- 차트
    areaCmpPowerList: [], // 지역구별 발전량 or 생산량 분포 차트 데이터
    areaXcategory: [],
    // 1.전국 , 시군구 , 읍면동 , 수용가
    meainCmpPowerList: [], // 설비별로 데이터
  });

  // 데이터 불러오기
  const getMeainListData = () => {
    getMeainListOfUser(conn, getMeainList, getRejection, search.userId);
  };

  // 설비 callback 함수
  const getMeainList = (meainList) => {
    // CID 설정
    if (meainList.length > 0) {
      search.cid = meainList[0].cid;
    }
    // 굳이 셋 스테이트 말고 바로 설정 로직 확인 후 변경 하기
    setDataList((dataList) => {
      const item = { ...dataList };
      item.meainList = meainList;
      return item;
    });
    // 데이터 불러오기 // 첫번쨰 값
    getDataList();
  };

  // 통합 Reject함수
  const getRejection = (error) => {
    setLoading(false);
    if (error.errCode === "F011") {
      kendo.alert("필요한 검색 파라미터가 선택 및 입력 되지 않았습니다. 다시 확인 해주세요.");
      return;
    } else {
      kendo.alert("해당 시스템이 문제가 있습니다. 다시 확인 해주세요.");
      return;
    }
  };

  // 데이터 설정
  const getDataList = () => {
    setLoading(true);
    getDataByMeainInfo(conn, getDataByMeainInfoResult, getRejection, search);
  };

  // 백엔드 데이터와 프론트 데이터 연동 설정
  const getDataByMeainInfoResult = (result) => {
    setDataList((dataList) => {
      const item = { ...dataList };
      item.dashboard = result.userByDashboardDto;
      item.timeData.ensoTypeCode = result.userByTimeDataResultDto.ensoTypeCode;
      item.timeData.timeDataList = result.userByTimeDataResultDto.userByTimeDataDtoList;
      item.timeData.timeSeries = result.userByTimeDataResultDto.timeSeries;
      item.timeData.timeCategory = result.userByTimeDataResultDto.timeCategory;
      item.areaCmpPowerList = result.areaChartSeries;
      item.areaXcategory = result.areaXcategory;
      item.meainCmpPowerList = result.userByMeainInfoDtoList;
      return item;
    });
    setLoading(false);
  };

  return (
    <div style={loading ? { opacity: 0.5 } : null}>
      <CmpUserByUserSearch search={search} setSearch={setSearch} codeList={codeList} getMeainListData={getMeainListData} kendo={kendo} />
      {dataList.meainList.length !== 0 ? (
        <>
          <div className={styles.titleName}> ⋅ 설비목록</div>
          <CmpUserByUserMeainList meainList={dataList.meainList} search={search} setSearch={setSearch} getDataList={getDataList} />
          <div className={styles.titleName}> ⋅ 생산현황 요약</div>
          <CmpUserByUserDashboard dashboard={dataList.dashboard} search={search} />
          <div className="uk-grid">
            <CmpUserByUserTimeChart
              Highcharts={Highcharts}
              HighchartsReact={HighchartsReact}
              ensoTypeCode={dataList.timeData.ensoTypeCode}
              timeSeries={dataList.timeData.timeSeries}
              timeCategory={dataList.timeData.timeCategory}
            />
            <CmpUserByUserTimeGrid
              timeDataList={dataList.timeData.timeDataList}
              search={search}
              kendo={kendo}
              ensoTypeCode={dataList.timeData.ensoTypeCode}
              setLoading={setLoading}
            />
          </div>
          <div className={styles.titleName}> ⋅ 발전량 분포</div>
          <CmpUserByUserArea
            Highcharts={Highcharts}
            HighchartsReact={HighchartsReact}
            areaCmpPowerList={dataList.areaCmpPowerList}
            areaXcategory={dataList.areaXcategory}
          />
          <div className={styles.titleName}> ⋅ 설비별 발전량</div>
          <CmpUserByUserMeainInfoGrid setLoading={setLoading} meainCmpPowerList={dataList.meainCmpPowerList} search={search} kendo={kendo} />
        </>
      ) : (
        <div className={styles.noDataCommnet}> 설비 데이터가 존재 하지 않습니다. 사용자를 선택 해주세요.</div>
      )}
      {search.userInfoVisible && (
        <UserInfoWindow
          conn={conn}
          search={search}
          setSearch={setSearch}
          kendo={kendo}
          rgnList={rgnList}
          dongList={dongList}
          height={isMobile === "Y" ? innerHeight : null}
          width={isMobile === "Y" ? innerWidth : null}
        />
      )}

      {loading && (
        <p
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
        >
          <BeatLoader loading={true} size={24} color="#1e88e5" />
        </p>
      )}
    </div>
  );
};

export default CmpUserByUser;
