import { useState, useEffect } from "react";
import { Input } from "@progress/kendo-react-inputs";
import kendo from "@progress/kendo-ui";

import ExpctConsmList from "./expctConsmList";
import ExpctConsmGrid from "./expctConsmGrid";
import { getExpctConsmCidList, getExpctConsmList } from "../../../../service/stats/expt/exptConsmService";

import styles from "./expctConsm.module.css";
import "./expctConsm.css";
import { getMessage } from "../../../../com/messageUtil";
import { getUser, isInstaller } from "../../../../service/authService";
import UserInfoWindow from "./../../type/userByUser/userInfoWindow";
import { Button } from "@progress/kendo-react-buttons";

function ExpctConsm({ conn, rgnList, dongList }) {
  // 유저정보
  const userInfo = getUser();

  // 검색 조건
  const [search, setSearch] = useState({
    userId: isInstaller() === true ? userInfo.userId : "",
    userTerm: isInstaller() === true ? userInfo.userTerm : "",
    userInfoVisible: false,
  });

  const [dataSource, setDataSource] = useState({
    grid: [],
    chart: [],
    excelData: null,
  });

  const getExpctConsmData = (search) => {
    getExpctConsmList(conn, getResult, getRejection, search);
  };

  // 결과 데이터
  const getResult = (gridList, chartData) => {
    setDataSource((dataSource) => {
      const item = { ...dataSource };
      item.grid = gridList;
      item.chart = chartData;
      return item;
    });
  };

  // rejection
  const getRejection = (name, error) => {
    if (error.errCode === "F011") {
      kendo.alert("");
      return;
    }
  };

  useEffect(() => {
    if (search.userId) {
      getExpctConsmData(search);
    }
  }, [search.userId]);

  const userInptClckEvt = () => {
    setSearch({ ...search, userInfoVisible: true });
  };

  return (
    <>
      <div className="consmDiv">
        <div className="consmDivIn">
          <div className="uk-gridExpctConsmSeach">
            <div className="uk-width-medium-1-2">
              <b>수용가 검색 &nbsp; &nbsp;</b>
              <Input
                id="userTerm"
                type="text"
                className={styles.SetSearchTerm}
                placeholder="수용가"
                readOnly
                value={search.userTerm ? search.userTerm : ""}
                disabled={isInstaller() === true ? true : false}
                onClick={userInptClckEvt}
              />
              <Button icon="search" onClick={userInptClckEvt}></Button>
              {search.userInfoVisible && (
                <UserInfoWindow conn={conn} search={search} setSearch={setSearch} kendo={kendo} rgnList={rgnList} dongList={dongList} />
              )}
            </div>
          </div>
          {search.userId ? (
            <ExpctConsmGrid
              search={search}
              dataSourceGrid={dataSource.grid}
              dataSourceChart={dataSource.chart}
              dataSourceExcel={dataSource.excelData}
              setDataSource={setDataSource}
            />
          ) : (
            <>데이터가 존재하지 않습니다. 수용가를 선택해 주시기 바랍니다.</>
          )}
        </div>
      </div>
    </>
  );
}

export default ExpctConsm;
