export const getMessage = (code) => {
  const list = [
    { code: "F001", message: "시스템 오류로 관리자에 문의하십시오." },
    { code: "F002", message: "유효하지 않은 값이 존재합니다." },
    { code: "F010", message: "시스템 오류로 파일을 업로드하지 못하였습니다. " },
    { code: "F011", message: "필수 파라미터 값이 없습니다." },
    { code: "T000", message: "인증 토큰이 없습니다." },
    { code: "T001", message: "유효하지 않은 접속 토큰입니다." },
    { code: "T002", message: "유효하지 않은 이용자 토큰입니다." },
    { code: "L001", message: "존재하지 않는 아이디 입니다." },
    { code: "L002", message: "아이디 및 패스워드가 잘못되었습니다." },
    { code: "L003", message: "비밀번호 형식이 알맞지 않습니다." },
    { code: "L004", message: "핸드폰번호 형식이 알맞지 않습니다." },
    { code: "L005", message: "이메일 형식이 알맞지 않습니다." },
    { code: "L006", message: "아이디가 중복되었습니다." },
    { code: "L009", message: "아이디는 영어만 입력할 수 있습니다." },
    { code: "M004", message: "RTU업체코드가 유효하지 않습니다." },
  ];
  const msg = list.find((item) => item.code === code);
  return msg?.message || "존재하지 않는 오류 코드입니다.";
};
