import GathStatusMonthChart from './gathStatusMonthChart';
import GathStatusMonthGrid from './gathStatusMonthGrid';
import { useState, useEffect, useRef } from 'react';
import DatePickerKo from '../../com/dateInputs/datePickerKo';
import { getGathStatusMonth } from '../../../service/analy/gathStatusService';
import kendo from '@progress/kendo-ui';
import { Button } from '@progress/kendo-react-buttons';
import styles from './gathStatus.module.css';
import { BeatLoader } from 'react-spinners';

const GathStatusMonth = ({ conn, ensoTypeCodeNo }) => {
  const [loading, setLoading] = useState(false);

  // 오늘날
  const today = new Date();
  // 설정한 날짜
  const day = useRef(new Date());
  // 데이터 소스
  const [dataSource, setDataSource] = useState({
    grid: [],
    chart: [],
    x_categories: [],
    excelData: null,
  });

  // 데이터 불러오기
  const getDs = () => {
    setLoading(true);

    getGathStatusMonth(conn, getResult, getRejection, ensoTypeCodeNo, day.current, setLoading);
  };

  // 결과 데이터
  const getResult = (result) => {
    // 년도 , 월 구하기
    const year = kendo.toString(day.current, 'yyyy');
    const month = kendo.toString(day.current, 'MM');
    // 해당 월의 마지막 일자 구하기
    const dayMonth = new Date(year, month, 0).getDate();
    // 차트 변수 정의
    let chart_data = [];
    let col = 0;
    let row = 0;
    let x_categories = [];
    // 카테고리 가공
    for (let i = 1; i <= dayMonth; i++) {
      x_categories.push(i);
    }
    // 차트 데이터 초기화
    for (var i = 0; i < dayMonth * 4; i++) {
      chart_data.push({
        x: col,
        y: row,
        value: null,
      });
      col += 1;
      if (col >= dayMonth) {
        col = 0;
        row += 1;
      }
    }
    // 차트 데이터 가공
    result.forEach((element) => {
      let date = kendo.toString(new Date(element.gathDtm), 'yyyy-MM-dd HH:mm:ss');
      let strArr = date.split(' ');
      let dayArr = strArr[0].split('-');
      let timeArr = strArr[1].split(':');
      let HH = (timeArr[0] * 1) / 6;
      let DD = dayArr[2] * 1 - 1;
      let idx = DD + HH * dayMonth;
      chart_data[idx].value = element.gathRate;
      // 시간 값 변경
      element.gathDtm = dayArr[0] + '년 ' + dayArr[1] + '월 ' + dayArr[2] + '일 ' + (timeArr[0] * 1 === 0 || timeArr[0] * 1 === 6 ? timeArr[0] : timeArr[0]) + '시';
    });

    // 데이터 소스 세팅
    setDataSource((dataSource) => {
      const item = { ...dataSource };
      item.grid = result;
      item.chart = chart_data;
      item.x_categories = x_categories;
      return item;
    });
  };

  // rejection 함수
  const getRejection = (error) => {
    // console.log("error = ", error);
  };

  useEffect(() => {
    getDs();
  }, [ensoTypeCodeNo]);

  // 엑셀 다운로드 이벤트
  const onClickEvt = (e) => {
    dataSource.excelData.save();
  };

  // 엑셀 변수 상태 설정
  const setExcelData = (_excelData) => {
    setDataSource((dataSource) => {
      const item = { ...dataSource };
      item.excelData = _excelData;
      return item;
    });
  };

  return (
    <div
      className="uk-width-medium-1-2"
      style={
        loading
          ? {
              opacity: 0.6,
              pointerEvents: 'none',
            }
          : null
      }
    >
      <div className="md-card">
        <div className="md-card-content">
          <div className="md-card-toolbar">
            <h3 className="md-card-toolbar-heading-text">월간 일별 수집률</h3>
            <div className={styles.gathStatusToolbar}>
              날짜검색&nbsp;&nbsp;
              <DatePickerKo
                id="start"
                name="start"
                className="datePicker"
                defaultValue={today}
                format="yyyy-MM "
                setDtm={(v) => {
                  day.current = v;
                  getDs();
                }}
                max={today}
                view="year"
              />
              <Button className="gathStatusExcelBtn" onClick={onClickEvt}>
                엑셀저장
              </Button>
            </div>
          </div>
          <GathStatusMonthChart dataSource={dataSource.chart} x_categories={dataSource.x_categories} />
          <GathStatusMonthGrid dataSource={dataSource.grid} ensoTypeCodeNo={ensoTypeCodeNo} setExcelData={setExcelData} />
        </div>
        {loading && (
          <p
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
            }}
          >
            <BeatLoader loading={true} size={24} color="#1e88e5" />
          </p>
        )}
      </div>
    </div>
  );
};

export default GathStatusMonth;
