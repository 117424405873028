import kendo from "@progress/kendo-ui";
import { roundNum } from "../../../com/dataUtil";

// 지역별 그래프
export const getAreaDataList = async (conn, callback, reject, param, setLoading) => {
  await conn
    .get("stat/area", {
      params: {
        ensoTypeCodeNo: param.ensoTypeCodeNo,
        bizSct: param.bizSct,
        bizYy: param.bizYy,
        bldSrvCodeNo: param.bldSrvCodeNo,
        unit: param.unit,
        startGathDtm: kendo.toString(param.start, "yyyy-MM-dd"),
        endGathDtm: kendo.toString(param.end, "yyyy-MM-dd"),
        dateFormat: param.unit,
        rgnCode: param.rgnCode,
      },
    })
    .then((response) => {
      const result = response;
      // 설비 용량 전체 합
      let sumInstCapa = 0;
      let no = 1;
      result.forEach((element) => {
        sumInstCapa += element.instCapa;
      });

      // (순위 , 설비용량비율 구하기) , 설비용량, 생산량, 용량대비 생산량 , 소수점2자리 붙이기
      result.forEach((element) => {
        element.ratio = element.instCapa / sumInstCapa ? roundNum((element.instCapa / sumInstCapa) * 100, 2) : 0;
        element.no = no++;
        if (element.instVsPower !== null) {
          element.instVsPower = roundNum(element.instVsPower, 2);
        }
        if (element.instVsUse !== null) {
          element.instVsUse = roundNum(element.instVsUse, 2);
        }
        if (element.dayPower !== null) {
          element.dayUse = roundNum(element.dayPower, 2);
        }
        if (element.dayUse !== null) {
          element.dayUse = roundNum(element.dayUse, 2);
        }
        element.instCapa = roundNum(element.instCapa, 2);
      });

      // 그래프 데이터 가공
      let meainTypeData = [];
      let meainTypeInstCapaData = [];
      result.forEach((element) => {
        meainTypeData.push({ name: element.rgnTerm, y: element.efic });
        meainTypeInstCapaData.push({ name: element.rgnTerm, y: element.ratio, instCapa: element.instCapa });
      });
      const chart = { meainTypeData: meainTypeData, meainTypeInstCapaData: meainTypeInstCapaData };
      setLoading(false);
      callback(result, chart, param.ensoTypeCodeNo);
    })
    .catch((error) => {
      setLoading(false);
      reject(error);
    });
};

export const getMakrDataList = async (conn, callback, reject, param, rgnCode) => {
  let url = "";
  if (param.ensoTypeCodeNo === 15001) {
    url = "/stat/area/sola/makr";
  } else if (param.ensoTypeCodeNo === 15002) {
    url = "/stat/area/heat/makr";
  } else if (param.ensoTypeCodeNo === 15003) {
    url = "/stat/area/heath/makr";
  } else if (param.ensoTypeCodeNo === 15004) {
    url = "/stat/area/velo/makr";
  } else if (param.ensoTypeCodeNo === 15006) {
    url = "/stat/area/fuelcell/makr";
  }

  await conn
    .get(url, {
      params: {
        rgnCode: rgnCode,
        startGathDtm: kendo.toString(param.start, "yyyy-MM-dd"),
        endGathDtm: kendo.toString(param.end, "yyyy-MM-dd"),
      },
    })
    .then((response) => {
      const result = response;

      // 설비 용량 전체 합
      let sumInstCapa = 0;
      let no = 1;
      result.forEach((element) => {
        sumInstCapa += element.instCapa;
      });
      // (순위 , 설비용량비율 구하기) , 설비용량, 생산량, 용량대비 생산량 , 소수점2자리 붙이기
      result.forEach((element) => {
        element.ratio = element.instCapa / sumInstCapa ? roundNum((element.instCapa / sumInstCapa) * 100, 2) : 0;
        element.no = no++;
        if (element.instVsPower !== null) {
          element.instVsPower = roundNum(element.instVsPower, 2);
        }
        if (element.instVsUse !== null) {
          element.instVsUse = roundNum(element.instVsUse, 2);
        }
        if (element.dayPower !== null) {
          element.dayUse = roundNum(element.dayPower, 2);
        }
        if (element.dayUse !== null) {
          element.dayUse = roundNum(element.dayUse, 2);
        }
        element.instCapa = roundNum(element.instCapa, 2);
      });
      callback(result, rgnCode);
    })
    .catch((error) => {
      reject(error);
    });
};

export const getModlDataList = async (conn, callback, reject, param, makrSeriNo, rgnCode) => {
  let url = "";
  if (param.ensoTypeCodeNo === 15001) {
    url = "/stat/area/sola/makr";
  } else if (param.ensoTypeCodeNo === 15002) {
    url = "/stat/area/heat/makr";
  } else if (param.ensoTypeCodeNo === 15003) {
    url = "/stat/area/heath/makr";
  } else if (param.ensoTypeCodeNo === 15004) {
    url = "/stat/area/velo/makr";
  } else if (param.ensoTypeCodeNo === 15006) {
    url = "/stat/area/fuelcell/makr";
  }

  await conn
    .get(url, {
      params: {
        rgnCode: rgnCode,
        makrSeriNo: makrSeriNo,
        startGathDtm: kendo.toString(param.start, "yyyy-MM-dd"),
        endGathDtm: kendo.toString(param.end, "yyyy-MM-dd"),
      },
    })
    .then((response) => {
      const result = response;
      // 설비 용량 전체 합
      let sumInstCapa = 0;
      let no = 1;
      result.forEach((element) => {
        sumInstCapa += element.instCapa;
      });
      // (순위 , 설비용량비율 구하기) , 설비용량, 생산량, 용량대비 생산량 , 소수점2자리 붙이기
      result.forEach((element) => {
        element.ratio = element.instCapa / sumInstCapa ? roundNum((element.instCapa / sumInstCapa) * 100, 2) : 0;
        element.no = no++;
        if (element.instVsPower !== null) {
          element.instVsPower = roundNum(element.instVsPower, 2);
        }
        if (element.instVsUse !== null) {
          element.instVsUse = roundNum(element.instVsUse, 2);
        }
        if (element.dayPower !== null) {
          element.dayUse = roundNum(element.dayPower, 2);
        }
        if (element.dayUse !== null) {
          element.dayUse = roundNum(element.dayUse, 2);
        }
        element.instCapa = roundNum(element.instCapa, 2);
      });
      callback(result, makrSeriNo);
    })
    .catch((error) => {
      reject(error);
    });
};
