import log from "../../../com/log";
import kendo from "@progress/kendo-ui";

import { roundNum, numFormat } from "../../../com/dataUtil";
import { color } from "highcharts";

const expctConsmComUrl = "stat/expctBuildBsman";

// 시간대별 그래프
export const getExpctBuildBsmanList = async (conn, callback, reject, search) => {
  // 날짜 설정
  const today = new Date();
  const startGathDtm = kendo.toString(new Date(Date.parse(today) - 5 * 1000 * 60 * 60 * 24), "yyyy-MM-dd");
  const dayAgo4 = kendo.toString(new Date(Date.parse(today) - 4 * 1000 * 60 * 60 * 24), "yyyy-MM-dd");
  const dayAgo3 = kendo.toString(new Date(Date.parse(today) - 3 * 1000 * 60 * 60 * 24), "yyyy-MM-dd");
  const dayAgo2 = kendo.toString(new Date(Date.parse(today) - 2 * 1000 * 60 * 60 * 24), "yyyy-MM-dd");
  const dayAgo1 = kendo.toString(new Date(Date.parse(today) - 1 * 1000 * 60 * 60 * 24), "yyyy-MM-dd");
  const endGathDtm = kendo.toString(today, "yyyy-MM-dd");

  const config = {
    params: {
      startGathDtm: startGathDtm,
      endGathDtm: endGathDtm,
      rgnCode: search.rgnCode,
      dayAgo4: dayAgo4,
      dayAgo3: dayAgo3,
      dayAgo2: dayAgo2,
      dayAgo1: dayAgo1,
    },
  };

  await conn
    .get(expctConsmComUrl + "/grid", config)
    .then((response) => {
      const result = response;

      // 그리드 데이터 가공
      const gridList = [];

      // 차트 데이터 가공
      const chartData = [];

      // 그리드 데이터에 단위 넣기
      result.forEach((element) => {
        gridList.push({
          ensoTypeCode: element.ensoTypeCode,
          ensoTypeTerm: element.ensoTypeTerm,
          dayAgo0: element.dayAgo0 !== null ? numFormat(roundNum(element.dayAgo0, 0)) : 0,
          dayAgo1: element.dayAgo1 !== null ? numFormat(roundNum(element.dayAgo1, 0)) : 0,
          dayAgo2: element.dayAgo2 !== null ? numFormat(roundNum(element.dayAgo2, 0)) : 0,
          dayAgo3: element.dayAgo3 !== null ? numFormat(roundNum(element.dayAgo3, 0)) : 0,
          dayAgo4: element.dayAgo4 !== null ? numFormat(roundNum(element.dayAgo4, 0)) : 0,
          dayAgo5: element.dayAgo5 !== null ? numFormat(roundNum(element.dayAgo5, 0)) : 0,
          tomorrow: element.tomorrow !== null ? numFormat(roundNum(element.tomorrow, 0)) : 0,
        });

        // 차트 값 가공
        chartData.push({
          name: element.ensoTypeTerm,
          data: [
            element.dayAgo5 !== null ? roundNum(element.dayAgo5, 0) : 0,
            element.dayAgo4 !== null ? roundNum(element.dayAgo4, 0) : 0,
            element.dayAgo3 !== null ? roundNum(element.dayAgo3, 0) : 0,
            element.dayAgo2 !== null ? roundNum(element.dayAgo2, 0) : 0,
            element.dayAgo1 !== null ? roundNum(element.dayAgo1, 0) : 0,
            element.dayAgo0 !== null ? roundNum(element.dayAgo0, 0) : 0,
            element.tomorrow !== null ? roundNum(element.tomorrow, 0) : 0,
          ],
        });
      });

      // callback( gridList, chartData );
      callback(gridList, chartData);
    })
    .catch((error) => {
      reject("수용가 보고서", error);
    });
};
