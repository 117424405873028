import React, { useState, useEffect } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";
import kendo from "@progress/kendo-ui";
import { Input } from "@progress/kendo-react-inputs";
import styles from "./gathRawDataLog.module.css";

// 엑셀 다운로드
import { getExcelDownload } from "../../../service/stats/raw/rawDataService";
import DateTimePickerKo from "../../com/dateInputs/dateTimePickerKo";
import { getRtu } from "../../../service/mngt/rtuEnte/rtuService";
import { getMeainModel } from "../../../service/meainModelService";
import { isInstaller } from "../../../service/authService";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { ArrowRightAlt } from "@material-ui/icons";
import useHistoryState from "../../../com/useHistoryState";
import RgnDropdown from "../../com/dropdown/rgnDropdown";

const GathRawDataLogSearch = ({
  search,
  setSearch,
  conn,
  codeList,
  rgnList,
  dongList,
  paging,
  rtuList,
  makrList,
  meainModelList,
  setLoading,
  fetchData,
}) => {
  const isMobile = localStorage.getItem("isMobile");
  const [selected, setSelected] = useState(0);
  const handleSelect = (e) => {
    setSelected(e.selected);
  };
  // 에너지원 데이터 소스
  const ensoList = codeList.filter((c) => c.grpCodeNo === 15 && c.codeNo !== 15007);
  const [defaultEnsoCode, setDefaultEnsoCode] = useState({ codeVal: "태양광", codeNo: 15001 });
  // 지역
  // const dongs = dongList.filter((e) => e.rgnCode === search.rgnCode);

  // 에너지원별 설비타입기준 제조사 코드
  if (search.ensoTypeCode === 15001) {
    makrList = makrList.filter((e) => e.ensoTypeCodeNo === search.ensoTypeCode && e.makrClsfNo === 30002);
  } else if (search.ensoTypeCode === 15002) {
    makrList = makrList.filter((e) => e.ensoTypeCodeNo === search.ensoTypeCode && (e.makrClsfNo === 30003 || e.makrClsfNo === 30013));
  } else if (search.ensoTypeCode === 15003) {
    makrList = makrList.filter((e) => e.ensoTypeCodeNo === search.ensoTypeCode && e.makrClsfNo === 30005);
  } else if (search.ensoTypeCode === 15004) {
    makrList = makrList.filter((e) => e.ensoTypeCodeNo === search.ensoTypeCode && e.makrClsfNo === 30007);
  } else if (search.ensoTypeCode === 15006) {
    makrList = makrList.filter((e) => e.ensoTypeCodeNo === search.ensoTypeCode && e.makrClsfNo === 30010);
  } else {
    makrList = [];
  }

  // optionLabel 검색 랜더링으로 인해서 무의식적인 랜더링을 방지하기 위한 라벨
  const [optionLabel, setOptionLabel] = useHistoryState(
    {
      dongValue: { fixbylawBundTermSimple: "읍/면/동", fixbylawBundCode: "" },
      meainModl: { meainTypeTerm: "설비 모델", meainTypeSeriNo: "" },
      makr: { makrTerm: "제조사", makrSeriNo: "" },
      rtu: { rtuEnteTerm: "RTU 업체", rtuEnteCode: "" },
      cidUserTerm: "",
      rgnCode: "",
      makrSeriNo: "",
      rtuEnteCode: "",
    },
    "optionLabel",
  );

  // Rtu 업체 , 설비 모델 , 제조사 리스트
  const [makrModlRtuList, setMakrModlRtuList] = useState({
    makrList: [],
    filterMakrList: [],
    meainModlList: [],
    rtuList: [],
  });

  // 날짜
  const [startMax, setStartMax] = useState(new Date());
  const [endMin, setEndMin] = useState(new Date());

  // 드롭다운 체인지 이벤트
  const onchange = (e) => {
    if (e.target.name === "ensoTypeDropDown") {
      search.ensoTypeCode = e.value.codeNo;
      search.makrSeriNo = "";
      search.meainTypeSeriNo = "";
      search.cidUserTerm = "";
      search.rtuEnteCode = "";
      optionLabel.cidUserTerm = "";
      optionLabel.makr = { makrTerm: "제조사", makrSeriNo: "" };
      optionLabel.meainModl = { meainTypeTerm: "설비 모델", meainTypeSeriNo: "" };
      optionLabel.rtu = { rtuEnteTerm: "RTU 업체", rtuEnteCode: "" };
      setDefaultEnsoCode({ codeNo: e.value.codeNo, codeVal: e.value.codeVal });
      setSearch((search) => {
        const item = { ...search };
        item.ensoType = { codeNo: e.value.codeNo, codeVal: e.value.codeVal };
        item.ensoTypeCode = e.value.codeNo;
        return item;
      });
    } else if (e.target.name === "rgnDropDown") {
      search.rgnCode = e.value.rgnCode;
      search.fixbylawBundCode = "";
      optionLabel.dongValue = { fixbylawBundTermSimple: "읍/면/동", fixbylawBundCode: "" };
      setOptionLabel((optionLabel) => {
        const item = { ...optionLabel };
        item.rgnCode = e.value.rgnCode;
        return item;
      });
      setSearch((optionLabel) => {
        const item = { ...optionLabel };
        item.rgnCode = e.value.rgnCode;
        item.rgn = e.value;
        return item;
      });
    } else if (e.target.name === "fixbylawBundDropDown") {
      search.fixbylawBundCode = e.value.fixbylawBundCode;
      setOptionLabel((optionLabel) => {
        const item = { ...optionLabel };
        item.dongValue = e.value;
        return item;
      });
    } else if (e.target.name === "makrSeriNo") {
      // search.makrSeriNo = e.value.makrSeriNo;
      search.meainTypeSeriNo = "";
      search.makrSeriNo = e.value.makrSeriNo;
      optionLabel.meainModl = { meainTypeTerm: "설비 모델", meainTypeSeriNo: "" };
      setOptionLabel((optionLabel) => {
        const item = { ...optionLabel };
        item.makrSeriNo = e.value.makrSeriNo;
        item.makr = e.value;
        return item;
      });
      setSearch((optionLabel) => {
        const item = { ...optionLabel };
        item.makrSeriNo = e.value.makrSeriNo;
        item.makr = e.value;
        return item;
      });
    } else if (e.target.name === "meainTypeSeriNo") {
      search.meainTypeSeriNo = e.value.meainTypeSeriNo;
      setOptionLabel((optionLabel) => {
        const item = { ...optionLabel };
        item.meainModl = e.value;
        return item;
      });
      setSearch((optionLabel) => {
        const item = { ...optionLabel };
        item.meainTypeSeriNo = e.value.meainTypeSeriNo;
        item.meainModl = e.value;
        return item;
      });
    } else if (e.target.name === "rtuEnte") {
      search.rtuEnteCode = e.value.rtuEnteCode;
      setOptionLabel((optionLabel) => {
        const item = { ...optionLabel };
        item.rtu = e.value;
        return item;
      });
      setSearch((optionLabel) => {
        const item = { ...optionLabel };
        item.rtuEnteCode = e.value.rtuEnteCode;
        item.rtu = e.value;
        return item;
      });
    } else if (e.target.name === "cidUserTerm") {
      search.cidUserTerm = e.value;
      setOptionLabel((optionLabel) => {
        const item = { ...optionLabel };
        item.cidUserTerm = e.value;
        return item;
      });
      setSearch((optionLabel) => {
        const item = { ...optionLabel };
        item.cidUserTerm = e.value;
        return item;
      });
    }
  };

  // 조회 버튼
  const searchData = () => {
    if (search.ensoTypeCode === "") {
      kendo.alert("에너지원을 선택 해주세요");
      return;
    } else if (search.ensoTypeCode === 15007) {
      kendo.alert("준비 중 입니다.");
      return;
    }
    // 페이징 초기화 로직 추가
    paging.skip = 0;
    paging.take = 20;
    paging.sort = [{ field: "gathDtm", dir: "desc" }];
    fetchData();
  };

  // 엑셀 다운로드
  const excelDownload = () => {
    if (search.ensoTypeCode === "") {
      kendo.alert("에너지원을 선택 해주세요.");
      return;
    }
    setLoading(true);
    getExcelDownload(search, paging, setLoading);
  };

  // RTU 업체 , 설비 모델 , 제조사 리스트 가져오기
  const getRtuMakrModlList = () => {
    Promise.all([
      getRtu(conn),
      // getMakr(conn),
      getMeainModel(conn),
    ])
      .then((result) => {
        // 필요한 제조사 데이터만 가져오도록 설비모델에서 가져오기
        const makrList = [];
        result[1].forEach((element) => {
          makrList.push({
            makrSeriNo: element.makr.makrSeriNo,
            makrTerm: element.makr.makrTerm,
            useYn: element.makr.useYn,
            authYn: element.makr.authYn,
            ensoTypeCode: element.ensoTypeCodeNo,
          });
        });

        // 중복 ID 제거 후 유니크 ID 추출 *객체의 속성이 1개만 있거나, 객체 값이 완전히 같은 것을 중복 제거 할 경우만 new Set사용
        const uniqMakrList = makrList.filter((item, i) => {
          return (
            makrList.findIndex((item2, j) => {
              return item.makrSeriNo === item2.makrSeriNo;
            }) === i
          );
        });
        setMakrModlRtuList((makrModlRtuList) => {
          const item = { ...makrModlRtuList };
          item.rtuList = result[0];
          item.makrList = uniqMakrList;
          item.meainModlList = result[1];
          return item;
        });
      })
      .catch((error) => {
        // 사용안함
      });
  };

  // 에너지원 코드 확인 후 값 설정
  const setEnsoTypeCodeDropDown = () => {
    const ensoTypeCode = search.ensoTypeCode;
    if (ensoTypeCode) {
      ensoList.forEach((element) => {
        if (element.codeNo === ensoTypeCode) {
          setDefaultEnsoCode({ codeNo: element.codeNo, codeVal: element.codeVal });
        }
      });
    } else {
      setDefaultEnsoCode({ codeVal: "태양광", codeNo: 15001 });
    }
  };

  useEffect(() => {
    // 에너지원 코드 확인 후 값 설정
    setEnsoTypeCodeDropDown();
    // RTU 업체 , 설비 모델 , 제조사 불러오기
    getRtuMakrModlList();
  }, []);

  const appKeyPress = (e) => {
    if (e.key === "Enter") {
      searchData();
    }
  };

  return (
    <>
      {isMobile === "Y" ? (
        <div>
          <TabStrip selected={selected} onSelect={handleSelect}>
            <TabStripTab title="기간선택">
              <table className="gathRawTable">
                <tr>
                  <th>수집기간</th>
                </tr>
                <tr>
                  <td>
                    <DateTimePickerKo
                      className="gathRawSearchCal"
                      id="start"
                      name="start"
                      width={200}
                      defaultValue={search.startGathDtm}
                      format="yy-MM-dd HH:mm"
                      setDtm={(v) => {
                        search.startGathDtm = v;
                        setEndMin(v);
                      }}
                      max={startMax}
                    ></DateTimePickerKo>
                    <span className="textColorGr datePeriodIcon">~</span>
                    <DateTimePickerKo
                      className="gathRawSearchCal"
                      id="end"
                      name="end"
                      width={200}
                      defaultValue={search.endGathDtm}
                      format="yy-MM-dd HH:mm"
                      setDtm={(v) => {
                        search.endGathDtm = v;
                        setStartMax(v);
                      }}
                      min={endMin}
                      max={new Date()}
                    ></DateTimePickerKo>
                  </td>
                </tr>
              </table>
              <div className="gathRawBtnDiv">
                <Button className="gathRawBtn" icon="search" name="inqBtn" onClick={searchData} style={{ paddingLeft: "20px", paddingRight: "20px" }}></Button>
              </div>
              <div className="mobileCmpUserSpan" style={{ textAlign: "right" }}>
                <ArrowRightAlt />
                &nbsp;표를 옆으로 드래그하여 확인
              </div>
            </TabStripTab>

            <TabStripTab title="상세조회">
              <table className="gathRawTable">
                <tr>
                  <th>에너지원</th>
                  <td>
                    <DropDownList
                      data={ensoList}
                      name="ensoTypeDropDown"
                      textField="codeVal"
                      dataItemKey="codeNo"
                      onChange={onchange}
                      className="searchArea"
                      value={defaultEnsoCode}
                    ></DropDownList>
                  </td>
                  <td>
                    <Input
                      type="text"
                      className="searchTerm cidUserTerm"
                      name="cidUserTerm"
                      id="cidUserTerm"
                      placeholder="수용가명 또는 CID"
                      onChange={onchange}
                      onKeyPress={appKeyPress}
                      value={search.cidUserTerm}
                      disabled={isInstaller() === true ? true : false}
                    />
                  </td>
                </tr>

                <tr>
                  <th>지역검색</th>
                  <td colSpan="2">
                    <RgnDropdown name="rgnDropDown" rgnList={rgnList} search={search} objKey={"rgn"} setSearch={setSearch} onChange={onchange} />
                    {/* 현재 백엔드 에서 읍면동 입력 시 null 값 인식으로 오류 발생 주석 처리 : jhchoi - 20210430 */}
                    {/* <DropDownList
                          data = {dongs} 
                          disabled = { search.rgnCode === "" } value = {optionLabel.dongValue} disabled = { isInstaller() === true ? true : false }
                          defaultItem = { {fixbylawBundTermSimple : "읍/면/동" , fixbylawBundCode : ""} } name = "fixbylawBundDropDown" 
                          textField = "fixbylawBundTermSimple" dataItemKey="fixbylawBundCode" onChange = {onchange} className = "searchArea"
                      ></DropDownList> */}
                  </td>
                </tr>
                <tr>
                  <td>
                    <DropDownList
                      disabled={isInstaller() === true ? true : false}
                      data={rtuList}
                      defaultItem={{ rtuEnteTerm: "RTU 업체", rtuEnteCode: "" }}
                      name="rtuEnte"
                      textField="rtuEnteTerm"
                      dataItemKey="rtuEnteCode"
                      onChange={onchange}
                      className="searchArea"
                      value={search.rtu}
                    ></DropDownList>
                  </td>
                  <td>
                    <DropDownList
                      data={makrList}
                      defaultItem={{ makrTerm: "제조사", makrSeriNo: "" }}
                      name="makrSeriNo"
                      textField="makrTerm"
                      dataItemKey="makrSeriNo"
                      onChange={onchange}
                      className="searchArea"
                      value={search.makr}
                    ></DropDownList>
                  </td>
                  <td>
                    <DropDownList
                      disabled={search.makrSeriNo === ""}
                      value={search.meainModl}
                      data={meainModelList.filter((e) => e.ensoTypeCodeNo === search.ensoTypeCode && e.makrSeriNo === search.makrSeriNo)}
                      defaultItem={{ meainTypeTerm: "설비 모델", meainTypeSeriNo: "" }}
                      name="meainTypeSeriNo"
                      textField="meainTypeTerm"
                      dataItemKey="meainTypeSeriNo"
                      onChange={onchange}
                      className="searchArea"
                    ></DropDownList>
                  </td>
                </tr>
              </table>
              <div className="gathRawBtnDiv">
                <Button className="gathRawBtn" icon="search" name="inqBtn" onClick={searchData} style={{ paddingLeft: "20px", paddingRight: "20px" }}></Button>
                <div className="mobileCmpUserSpan" style={{ textAlign: "right" }}>
                  <ArrowRightAlt />
                  &nbsp;표를 옆으로 드래그하여 확인
                </div>
              </div>
            </TabStripTab>
          </TabStrip>
        </div>
      ) : (
        <div className="uk-grid">
          <div className="uk-width-medium-1-1">
            <div className="md-card TBgA">
              <div className={["md-card-content", styles.searchPadding].join(" ")}>
                <span className="searchSubTitleA">에너지원</span>
                <label for="enso">
                  <DropDownList
                    data={ensoList}
                    name="ensoTypeDropDown"
                    textField="codeVal"
                    dataItemKey="codeNo"
                    onChange={onchange}
                    className="searchArea"
                    value={defaultEnsoCode}
                  ></DropDownList>
                </label>
                <span className="searchSubTitleA">지역검색</span>
                <label for="rgn">
                  <RgnDropdown name="rgnDropDown" rgnList={rgnList} search={search} objKey={"rgn"} setSearch={setSearch} onChange={onchange} />
                </label>
                &nbsp;&nbsp;&nbsp;
                {/* 현재 백엔드 에서 읍면동 입력 시 null 값 인식으로 오류 발생 주석 처리 : jhchoi - 20210430 */}
                {/* <DropDownList
                          data = {dongs} 
                          disabled = { search.rgnCode === "" } value = {optionLabel.dongValue} disabled = { isInstaller() === true ? true : false }
                          defaultItem = { {fixbylawBundTermSimple : "읍/면/동" , fixbylawBundCode : ""} } name = "fixbylawBundDropDown" 
                          textField = "fixbylawBundTermSimple" dataItemKey="fixbylawBundCode" onChange = {onchange} className = "searchArea"
                      ></DropDownList> */}
                <label for="rtu">
                  <DropDownList
                    disabled={isInstaller() === true ? true : false}
                    data={rtuList}
                    defaultItem={{ rtuEnteTerm: "RTU 업체", rtuEnteCode: "" }}
                    name="rtuEnte"
                    textField="rtuEnteTerm"
                    dataItemKey="rtuEnteCode"
                    onChange={onchange}
                    className="searchArea"
                    value={search.rtu}
                  ></DropDownList>
                </label>
                <label for="cidUserTerm">
                  <Input
                    type="text"
                    className="searchTerm cidUserTerm"
                    name="cidUserTerm"
                    id="cidUserTerm"
                    placeholder="수용가명 또는 CID"
                    onKeyPress={appKeyPress}
                    onChange={onchange}
                    value={search.cidUserTerm}
                    disabled={isInstaller() === true ? true : false}
                  />
                </label>
                <span className="searchSubTitleA">조회기간</span>
                <label for="start">
                  <DateTimePickerKo
                    id="start"
                    name="start"
                    width={200}
                    defaultValue={search.startGathDtm}
                    format="yyyy-MM-dd HH:mm"
                    setDtm={(v) => {
                      search.startGathDtm = v;
                      setEndMin(v);
                      setSearch((optionLabel) => {
                        const item = { ...optionLabel };
                        item.startGathDtm = v;
                        return item;
                      });
                    }}
                    max={startMax}
                  ></DateTimePickerKo>
                </label>
                <span className="textColorGr datePeriodIcon">~</span>
                <label for="end">
                  <DateTimePickerKo
                    id="end"
                    name="end"
                    width={200}
                    defaultValue={search.endGathDtm}
                    format="yyyy-MM-dd HH:mm"
                    setDtm={(v) => {
                      search.endGathDtm = v;
                      setStartMax(v);
                      setSearch((optionLabel) => {
                        const item = { ...optionLabel };
                        item.endGathDtm = v;
                        return item;
                      });
                    }}
                    min={endMin}
                    max={new Date()}
                  ></DateTimePickerKo>
                </label>
                <span className="searchSubTitleC">
                  <Button id="inqBtn" icon="search" name="inqBtn" onClick={searchData} style={{ paddingLeft: "20px", paddingRight: "20px" }}></Button>
                </span>
              </div>
              <div className={["md-card-content", styles.searchPadding].join(" ")}>
                <span className="searchSubTitleA">설비 제조사 / 모델</span>
                <label for="makr">
                  <DropDownList
                    popupSettings={{ width: "auto" }}
                    data={makrList}
                    defaultItem={{ makrTerm: "제조사", makrSeriNo: "" }}
                    name="makrSeriNo"
                    textField="makrTerm"
                    dataItemKey="makrSeriNo"
                    onChange={onchange}
                    className="searchArea"
                    value={optionLabel.makr}
                  ></DropDownList>
                </label>
                <label for="meain">
                  <DropDownList
                    popupSettings={{ width: "auto" }}
                    disabled={search.makrSeriNo === ""}
                    value={optionLabel.meainModl}
                    data={meainModelList.filter((e) => e.ensoTypeCodeNo === search.ensoTypeCode && e.makrSeriNo === search.makrSeriNo)}
                    defaultItem={{ meainTypeTerm: "설비 모델", meainTypeSeriNo: "" }}
                    name="meainTypeSeriNo"
                    textField="meainTypeTerm"
                    dataItemKey="meainTypeSeriNo"
                    onChange={onchange}
                    className="searchArea"
                  ></DropDownList>
                </label>
                <span className="searchSubTitleC">
                  <Button id="excelBtn" icon="excel" name="excelBtn" onClick={excelDownload} style={{ marginRight: "20px" }}>
                    엑셀저장
                  </Button>
                </span>
                <span className="statusViewHelp">
                  수집항목
                  <img src="/images/info.png" alt="수집항목" />
                  <div className="statusHelpImg">
                    {/* if (search.ensoTypeCode == '15001') { */}

                    {search.ensoTypeCode === 15001 && (
                      <>
                        <span className="helpTxtLine">
                          ● 태양광(단상) : 누적발전량(Wh), 입력단 전압(V), 입력단 전류(V), 입력단 출력(W), 출력단 전압(V), 출력단 전류(V), 출력단 출력(W), 계통
                          R상전압(V),
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          계통 S상전압(V), 계통 T상전압(V), 계통 R상전류(V), 계통 S상전류(V), 계통 T상전류(V), 주파수(Hz), 역률(%)
                        </span>
                        <span className="helpTxtLine">
                          <br />● 태양광(삼상) : 누적발전량(Wh), 입력단 전압(V), 입력단 전류(V), 입력단 출력(W), 출력단 전압(V), 출력단 전류(V), 출력단 출력(W),
                          계통 R상전압(V),
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          계통 S상전압(V), 계통 T상전압(V), 계통 R상전류(V), 계통 S상전류(V), 계통 T상전류(V), 주파수(Hz), 역률(%)
                        </span>
                      </>
                    )}
                    {search.ensoTypeCode === 15002 && (
                      <>
                        <span className="helpTxtLine">
                          ● 태양열(강제순환식) : 누적생산량(kcal), 집열기 입구온도(℃), 집열기 출구온도(℃), 측열조 상부온도(℃), 측열조 하부온도(℃), 유량(LPM)
                        </span>
                        <span className="helpTxtLine">
                          <br />● 태양열(자연순환식) : 누적사용열량(kcal), 급수배관온도(℃), 급탕배관온도(℃), 유량(LPM)
                        </span>
                      </>
                    )}
                    {search.ensoTypeCode === 15003 && (
                      <span className="helpTxtLine">
                        ● 지열 : 누적생산열량(kWh), 생산열량(W), 누적전력소비량(kWh), 지열수입구온도(℃), 지열수출구온도(℃), 온수 입구온도(℃), 온구출구온도(℃),
                        냉난방측-입구온도(℃), 냉난방측-출구온도(℃), 급탕측-급수온도(℃), 급탕측-급탕온도(℃), 전압(V), 전류(A), 전력(W), 지열수유량(LPM),
                        냉난방측-사용유량(LPM), 냉난방측-누적사용열량(kWh), 급탕측-사용유량(LPM), 급탕측-누적사용열량(kWh), 펌프상태
                      </span>
                    )}
                    {search.ensoTypeCode === 15004 && (
                      <span className="helpTxtLine">
                        ● 풍력 : 누적발전량(Wh), (전)전압(V), (전)전류(V), (전)출력(W), (후)전압(V), (후)전류(V), (후)출력(W), 주파수(Hz)
                      </span>
                    )}
                    {search.ensoTypeCode === 15006 && (
                      <span className="helpTxtLine">
                        ● 연료전지 : 누적발전량(Wh), 누적생산열량(kWh), 생산열량(W), 누적사용열량(kWh), 전일누적사용열량(kWh), (전)전압(V), (전)전류(V),
                        (전)출력(W), (후)전압(V), (후)전류(V), (후)출력(W), 급수온도(℃), 출수온도(℃), 역률(%), 주파수(Hz)
                      </span>
                    )}
                    {search.ensoTypeCode === 15007 && (
                      <span className="helpTxtLine">
                        <br />● ESS : (PCS입력)전압(V), (PCS입력)전류(A), (PCS입력)출력(W), (배터리입력)전압(V), (배터리입력)전류(A), (배터리입력)출력(W),
                        배터리상태, SOC(%), SOH(%), 최고온도(℃), 최저온도(℃), 평균온도(℃), 전압(V), 전류(A)
                      </span>
                    )}
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default GathRawDataLogSearch;
