// React
import React, { useState, useEffect, useCallback, useMemo } from "react";
import log from "../../../com/log";
import { parseDate } from "../../../com/dateUtil";
import managerExcelSample from "../../../files/sample_manager.xlsx";
import { getManager, setUpdateManager, setDeleteManager } from "../../../service/managerService";
import ManagerModal from "./managerModal";
import { downloadExcel } from "../../../com/downloadExcel";
import { BeatLoader } from "react-spinners";

// Kendo
import kendo from "@progress/kendo-ui";
import { IntlProvider, LocalizationProvider, loadMessages } from "@progress/kendo-react-intl";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Upload } from "@progress/kendo-react-upload";

import GridColumnInputFilter from "../../com/gridFilter/gridColumnInputFilter";

// Css
import "./manager.css";
import styles from "./manager.module.css";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { useHistory } from "react-router";
import { pwdRegex } from "./../../../com/regexUtil";

const Manager = ({ conn, rgnList, dongList }) => {
  // 로딩 스피너
  const [loading, setLoading] = useState(true);
  // 엑셀 로딩 스피너
  const [saveLoading, setSaveLoading] = useState(false);

  // 히스토리 사용
  const history = useHistory();

  // 드롭다운 필터 상태
  const [filterDropValue, setFilterDropValue] = useState({
    rgn: { rgnCode: "", rgnTermSimple: "주소검색" },
  });

  const [dataSource, setDataSource] = useState({
    total: 0,
    dataList: [],
  });
  const [dataState, setDataState] = useState({
    take: 20,
    skip: 0,
    sort: [],
    sortStatus: [],
    filter: { userId: "", userTerm: "", rgnCode: "" },
  });
  const [modalState, setModalState] = useState({
    visible: false,
    title: "",
    formItem: [],
  });
  const [uploadState, setUploadState] = useState({
    files: [],
    events: [],
    filePreviews: {},
    afterStateChange: [],
    response: [],
    visible: false,
  });

  // 단순 그리드 데이터 조회
  const getGridData = () => {
    getManager(conn, dataState, rgnList)
      .then((response) => {
        setLoading(false);
        setDataSource((dataSource) => {
          const item = { ...dataSource };
          item.total = response.total;
          item.dataList = response.results;
          return item;
        });
      })
      .catch((error) => {
        if (error.errCode === "L007") {
          alert("로그인 한 ID는 해당 메뉴에 접근할 수 없습니다.");
          history.goBack();
          return;
        }
        setLoading(false);
      });
  };

  // 그리드 페이징 처리
  const handlePageChange = (e) => {
    const { take, skip } = e.page;
    dataState.skip = skip;
    dataState.take = take;
    getGridData();
  };

  // 그리드 소팅 처리
  const handleSortChange = (e) => {
    const { sort } = e;
    dataState.sort = sort;
    dataState.sortStatus = sort;
    if (sort.length !== 0) {
      if (sort[0].field === "rgnTermSimple") {
        dataState.sort = "rgnCode." + sort[0].field + "," + sort[0].dir;
      } else {
        dataState.sort = sort[0].field + "," + sort[0].dir;
      }
    }
    getGridData();
  };

  // 그리드 필터링 처리
  const handleFilterChange = (e) => {
    // 필터 값 설정
    const isFilter = e.filter.filters;
    if (isFilter.length !== 0) {
      if (isFilter[0].field === "userId") {
        dataState.filter.userId = isFilter[0].value;
      } else if (isFilter[0].field === "userTerm") {
        dataState.filter.userTerm = isFilter[0].value;
      } else if (isFilter[0].field === "rgnTermSimple") {
        dataState.filter.rgnCode = isFilter[0].value.rgnCode;
      }
    }
    getGridData();
  };

  // 추가 모달창 저장 처리
  const handleModalSaveSubmit = useCallback(
    (e) => {
      kendo
        .confirm(modalState.title + " 하시겠습니까?")
        .then(() => {
          //PW 밸리데이션 처리
          if (e.loginPwd) {
            if (pwdRegex(e.loginPwd)) {
              kendo.alert("비밀번호 입력 시 8자 이상 영문,숫자,특수문자를 입력해주시기 바랍니다.");
              return;
            }
            if (e.loginPwd !== e.loginPwdChk) {
              kendo.alert("입력하신 비밀빈호와 비밀번호 확인이 같지 않습니다        ");
              return;
            }
          }
          if (!e.rgnInfo) {
            kendo.alert("시/군을 선택해 주시기 바랍니다.");
            return;
          }
          if (!e.servrRgnList) {
            kendo.alert("서버지역정보를 선택해 주시기 바랍니다.");
            return;
          }

          // setSaveLoading(true);

          // if(e.rgnTermSimple === undefined) e.rgnTermSimple = rgn[0];
          if (e.smsNotiUseYn === undefined) e.smsNotiUseYn = "Y";
          if (e.emailNotiUseYn === undefined) e.emailNotiUseYn = "Y";
          if (e.kakaoNotiUseYn === undefined) e.kakaoNotiUseYn = "N";

          // const rgnData = rgnList.filter(rgnItem => rgnItem.rgnTermSimple === e.rgnTermSimple)[0];

          e.userAddr = e.rgnInfo ? e.rgnInfo.rgnTerm : null;
          e.rgnCode = e.rgnInfo ? e.rgnInfo.rgnCode : null;
          e.cityProvCode = e.rgnInfo ? e.rgnInfo.cityProvCode : null;
          e.rgnTermSimple = e.rgnInfo ? e.rgnInfo.rgnTermSimple : null;
          e.regiDtm = parseDate("LocalDateTime");
          e.useYn = e.useYn ? e.useYn : "Y";
          e.createUpdateYn = true;
          e.servrRgnInfo = e.servrRgnList;

          // 배열로 저장
          const saveDataList = [e];

          setUpdateManager(conn, saveDataList, rgnList)
            .then((response) => {
              setSaveLoading(false);
              kendo.alert("담당자 " + modalState.title + " 이(가) 완료되었습니다.");
              setModalState({ ...modalState, visible: !modalState.visible, title: "", formItem: [] });
              getGridData();
            })
            .catch((err) => {
              setSaveLoading(false);
              log(`manager.handleModalSaveSubmit error: ${err}`);
            });
        })
        .catch(() => {
          return;
        });
    },
    [modalState, dataSource, dataState],
  );

  // 엑셀다운
  const handleExcelDown = useCallback((e) => {
    e.preventDefault();
    // sort 값 확인
    let sort = "";
    let direction = "";
    if (dataState.sortStatus.length !== 0) {
      sort = dataState.sortStatus[0].field;
      direction = dataState.sortStatus[0].dir;
    }
    // 검색 파라미터 설정
    const search = {
      userId: dataState.filter.userId,
      userTerm: dataState.filter.userTerm,
      rgnCode: dataState.filter.rgnCode,
      sort: sort,
      direction: direction,
    };
    const url = "/api/v2.2/user/manager";
    const data = search;
    const fileName = "담당자관리_" + parseDate("yyyymmddhhmmss") + ".xlsx";
    downloadExcel(data, fileName, url, setSaveLoading);
  }, []);

  // 엑셀 업로드 모달폼
  const handleExcelFormControl = useCallback(
    (e) => {
      setUploadState({
        ...uploadState,
        files: [],
        events: [],
        filePreviews: {},
        afterStateChange: [],
        response: [],
        visible: !uploadState.visible,
      });
    },
    [uploadState],
  );

  // 엑셀 파일 추가 부분
  const onAdd = useCallback(
    ({ target, newState, affectedFiles }) => {
      const afterStateChange = () => {
        affectedFiles
          .filter((file) => !file.validationErrors)
          .forEach((file) => {
            const reader = new FileReader();
            reader.onloadend = (ev) => {
              setUploadState({
                ...uploadState,
                filePreviews: {
                  ...uploadState.filePreviews,
                  [file.uid]: ev.target.result,
                },
              });
            };
            reader.readAsDataURL(file.getRawFile());
          });
      };

      setUploadState({
        ...uploadState,
        files: newState,
        afterStateChange,
      });
    },
    [uploadState],
  );

  // 엑셀 파일 추가 후 데이터 추출 부분
  const onStatusChange = useCallback(({ target, newState, affectedFiles, response }) => {
    setUploadState({ ...uploadState, files: newState, response });
  });

  // 엑셀 업로드 버튼
  const handleExcelUpload = useCallback(
    (e) => {
      e.preventDefault();
      const uploadExcelData = uploadState.response.response.data;

      uploadExcelData.forEach((item) => {
        // const rgnData = rgnList.filter(rgnItem => rgnItem.rgnCode === item.rgnCode)[0];
        // item.cityProvCode = rgnData.cityProvCode;
        // item.rgnCode = rgnData.rgnCode;
        // item.rgnTerm = rgnData.rgnTerm;
        // item.userAddr = rgnData.rgnTerm;
        // item.rgnTermSimple = rgnData.rgnTermSimple;
        // item.regiDtm = parseDate('LocalDateTime');
        item.createUpdateYn = true;
        const servrRgnInfo = [];

        if (item.servrRgnInfo.length > 0) {
          item.servrRgnInfo.forEach((element) => {
            servrRgnInfo.push({ rgnCode: element });
          });
        }

        item.servrRgnInfo = servrRgnInfo;
      });

      setUpdateManager(conn, uploadExcelData, rgnList).then(() => {
        kendo.alert("엑셀 저장을 완료하였습니다.");
        setUploadState({ ...uploadState, visible: !uploadState.visible });
        getGridData();
      });
    },
    [uploadState, dataSource, dataState],
  );

  // 엑셀 업로드 헤더 설정
  const handleUploadHeaders = useCallback((e) => (e.headers["X-AUTH-TOKEN"] = localStorage.getItem("atk")));

  // 시/군 필터
  const handleRgnFilterList = (props) => {
    const filterOnchage = (e) => {
      props.onChange({
        value: e.value,
        operator: e.value ? "eq" : "",
        syntheticEvent: e.syntheticEvent,
      });
      setFilterDropValue((filterDropValue) => {
        const item = { ...filterDropValue };
        item.rgn = e.value;
        return item;
      });
    };

    return (
      <div className="k-filtercell">
        <DropDownList
          data={rgnList}
          onChange={filterOnchage}
          defaultItem={{ rgnCode: "", rgnTermSimple: "주소검색" }}
          className="CustomFilterDropDown"
          textField="rgnTermSimple"
          dataItemKey="rgnCode"
          name="rgnTermSimple"
          value={filterDropValue.rgn}
        />
      </div>
    );
  };

  const mngDelCell = (props) => {
    const mngDelEvt = () => {
      kendo
        .confirm("정말 삭제하시겠습니까?")
        .then(() => {
          const { userId } = props.dataItem;
          setDeleteManager(conn, userId)
            .then((response) => {
              kendo.alert("삭제가 완료되었습니다.");
              getGridData(conn, dataState);
            })
            .catch((err) => {
              log(`managerGrid.setDeleteManager error: ${err}`);
            });
        })
        .catch(() => {});
    };
    return (
      <>
        <td>
          <a onClick={mngDelEvt}>
            <img src="/images/icon_del.png" alt="" />
          </a>
        </td>
      </>
    );
  };

  useEffect(() => {
    getGridData();
  }, []);

  return (
    <>
      <div className="managerBody">
        <div className="managerCate">
          <Button
            onClick={useCallback(
              (e) => {
                setModalState({ ...modalState, visible: !modalState.visible, title: "추가", createUpdateYn: true, formItem: [] });
              },
              [modalState],
            )}
          >
            <img src="/images/icon_add.png" alt="" />
            &nbsp; 추가
          </Button>
          {/* <Button onClick={handleExcelFormControl}>
            <img src='/images/icon_Up.png' alt=''/>&nbsp;
            엑셀파일 가져오기
          </Button>
          <Button>
            <img src='/images/icon_down.png' alt=''/>&nbsp;
            <a className='managerExcelTxt' href={managerExcelSample} download='sample_manager.xlsx'>엑셀샘플 다운로드</a>
          </Button>
          <Button onClick={handleExcelDown}>
            <img className='exelSDown' src='/images/Icon_ExelSDown.svg' alt=''/>&nbsp;
            엑셀저장
          </Button> */}
        </div>
        <div className="managerGridDiv">
          <LocalizationProvider language="ko-KR">
            <IntlProvider locale="ko">
              <Grid
                className={styles.managerGrid}
                data={dataSource.dataList}
                skip={dataState.skip}
                take={dataState.take}
                total={dataSource.total}
                sort={dataState.sortStatus}
                pageable={{ buttonCount: 5, pageSizes: true }}
                filterable
                sortable
                onPageChange={handlePageChange}
                onFilterChange={handleFilterChange}
                onSortChange={handleSortChange}
              >
                <GridColumn title="담당자" field="userTerm" filterCell={GridColumnInputFilter} />
                <GridColumn title="ID" field="userId" filterCell={GridColumnInputFilter} />
                <GridColumn title="시/군" field="rgnTermSimple" filterCell={handleRgnFilterList} />
                <GridColumn title="연락처" field="userCelphon" filterable={false} />
                <GridColumn title="이메일" field="userEmail" filterable={false} />
                <GridColumn title="서버지역정보" field="servrRgnInfo" filterable={false} />
                <GridColumn title="등록일시" field="regiDtm" filterable={false} />
                {/* <GridColumn title="이벤트 알림여부" sortable={false}> */}
                {/* <GridColumn title="SMS" field="smsNotiUseYn" filterable={false} width={60} /> */}
                <GridColumn title="알림톡" field="kakaoNotiUseYn" filterable={false} width={80} />
                {/* </GridColumn> */}
                <GridColumn
                  title="수정"
                  filterable={false}
                  sortable={false}
                  width={60}
                  cell={(props) => (
                    <td>
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          setModalState({
                            ...modalState,
                            visible: !modalState.visible,
                            title: "수정",
                            createUpdateYn: false,
                            formItem: props.dataItem,
                          });
                        }}
                      >
                        <img src="/images/icon_su.png" alt="" />
                      </a>
                    </td>
                  )}
                />
                <GridColumn title="삭제" filterable={false} sortable={false} width={60} cell={mngDelCell} />
                <GridNoRecords>데이터가 존재하지 않습니다.</GridNoRecords>
              </Grid>
            </IntlProvider>
          </LocalizationProvider>
          {modalState.visible && (
            <ManagerModal
              modalState={modalState}
              setModalState={setModalState}
              rgnList={rgnList}
              handleModalSaveSubmit={handleModalSaveSubmit}
              handleModalUpdateSubmit={handleModalSaveSubmit}
            />
          )}
          {uploadState.visible && (
            <Dialog title={"엑셀파일 가져오기"} onClose={handleExcelFormControl}>
              <LocalizationProvider language="ko-KR">
                <IntlProvider locale="ko">
                  <div className={styles.managerUploadDiv}>
                    <Upload
                      className={styles.managerUpload}
                      onBeforeUpload={handleUploadHeaders}
                      batch={false}
                      multiple={false}
                      files={uploadState.files}
                      restrictions={{ allowedExtensions: [".xlsx"] }}
                      withCredentials={true}
                      saveUrl={"/api/v2.2/user/manager/xlsx"}
                      onAdd={onAdd}
                      onStatusChange={onStatusChange}
                    />
                    <Button className={styles.managerUploadBtn} onClick={handleExcelUpload}>
                      업로드
                    </Button>
                  </div>
                </IntlProvider>
              </LocalizationProvider>
            </Dialog>
          )}
        </div>
        {loading && (
          <p className={styles.managerLoading}>
            <BeatLoader loading={true} size={24} color="#1e88e5" />
          </p>
        )}
      </div>
      {saveLoading && (
        <p className={styles.managerLoading}>
          <BeatLoader loading={true} size={24} color="#1e88e5" />
        </p>
      )}
    </>
  );
};

export default Manager;
