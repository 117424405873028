// 그래프
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsHeatmap from 'highcharts/modules/heatmap';

// 하이 차트를 히트맵하이차트로 변환 
HighchartsHeatmap(Highcharts);

const GathStatusMonthChart = ( {dataSource , x_categories} ) =>{
  // y축 카테고리
  const y_categories =  ['0-6시',	'6-12시',	'12-18시', '18-24시'];
  //차트옵션
  const config = {
    chart: {
      type: 'heatmap',
      marginTop: 40,
      marginBottom: 80,
      backgroundColor: "#ffffff"
    },
    title: {
        text: null
    },
    xAxis: {
      categories: x_categories
      , visible: true
    },
    exporting: {
      enabled: false
    },
    credits: {
      enabled: false
    },
    yAxis: {
      categories: y_categories,
      title: null,
      visible: true
    },
    colorAxis: {
      reversed: false,
      min: 0,
      max: 100,
      minColor: '#aaeded',
      maxColor: '#084241'
    },
    legend: {
      align: 'right',
      layout: 'vertical',
      margin: 0,
      verticalAlign: 'top',
      y: 25,
      symbolHeight: 280
    },
    tooltip: { formatter: function () { return  (this.point.value ==null ? "수집 데이터가 없습니다!": this.point.value+' % 수집</b></br>'); } },
    series: [{
        borderWidth: 1,
        borderRadius: 2,
        borderColor: 'none',
        data: dataSource,
        nullColor: '#D3D3D3', //Defaults to #f7f7f7.
        dataLabels: {
            enabled: false,
            color: '#000000'
        }
    }]
  };

  return(
    <div style = {{height : "350px"}}>
      <HighchartsReact Highcharts = {Highcharts} options = {config}></HighchartsReact>
    </div>
  );
}

export default GathStatusMonthChart;