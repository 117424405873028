import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { numFormat, roundNum } from "../../../../com/dataUtil";

// 발전량 차트
const ReportDayGenerationChart = ({ compareChart, width, clsName, height }) => {
  // 차트 값 가공
  const solaChart = compareChart.length !== 0 ? compareChart.filter((c) => c.ensoTypeCode === 15001) : [];
  const veloChart = compareChart.length !== 0 ? compareChart.filter((c) => c.ensoTypeCode === 15004) : [];
  const fuelcellChart = compareChart.length !== 0 ? compareChart.filter((c) => c.ensoTypeCode === 15006) : [];
  // 차트 옵션
  Highcharts.setOptions({
    lang: {
      resetZoom: "초기화",
    },
  });
  let chartConfig = {
    chart: {
      type: "column",
      zoomType: "xy",
      backgroundColor: "#f7f9fa",
      width: width !== null ? width : "100%",
      height: height !== null ? height : null,
    },
    title: { text: null },
    xAxis: [{ type: "category", crosshair: true }],
    yAxis: [
      {
        // 발전량 축
        labels: {
          format: "{value:,.0f}",
        },
        title: {
          text: "발전량(kWh)",
        },
      },
    ],
    credits: { enabled: false },
    tooltip: {
      shared: true,
      formatter: function () {
        let msg = "";
        msg +=
          '<span style="color:' +
          this.points[0].point.color +
          '">\u25CF</span> ' +
          "<span>" +
          this.points[0].series.name +
          "</span>" +
          " : " +
          "<b>" +
          numFormat(roundNum(this.points[0].y, 2)) +
          " kWh" +
          "</b>" +
          "<br>";
        return msg;
      },
    },
    legend: {
      layout: "vertical",
      align: "center",
      verticalAlign: "top",
      floating: false,
      backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || "#FFFFFF",
    },
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: true,
          color: "#ffffff",
          style: { textShadow: false },
          formatter: function () {
            return "<span>" + "<b>" + numFormat(roundNum(this.y, 0)) + "kWh</b>" + "</span>";
          },
        },
      },
    },
    series: [
      {
        name: "발전량",
        tooltip: {
          valueSuffix: " kWh",
        },
        labels: {
          format: "{value:,.0f}",
        },
        color: "#69bcc8",
        data: [solaChart[0], veloChart[0], fuelcellChart[0]],
      },
    ],
  };
  Highcharts.setOptions({
    lang: {
      resetZoom: "초기화",
    },
  });

  return (
    <div className={clsName !== null ? clsName : "reportDayBackground"}>
      <HighchartsReact Highcharts={Highcharts} options={chartConfig} containerProps={{ style: { border: "1px solid gainsboro" } }}></HighchartsReact>
    </div>
  );
};

export default ReportDayGenerationChart;
