import { useCallback } from "react";
import { roundNum, numFormat } from "../../../com/dataUtil";

// 좌측 수용가별로 에너지원별 데이터 테이블
const EnsoTypeItem = ({ dataItem }) => {
  // 각 에너지원별 이미지 적용 (현재 설비 상태에 따라서 변경 없음 , 각 에너지원별 여러 설비의 기준을 잡아야 설정이 가능)
  const getEnsoImgSrc = useCallback((ensoTypeCode) => `/images/enso/icon_${ensoTypeCode}_7004_Big.png`);

  // 에너지원별 코드에 따라서 타이틀 설정
  const getEnsoText = useCallback((ensoTypeCode) => {
    let ensoTitle = "";
    if (ensoTypeCode === 15001) {
      ensoTitle = "태양광";
    } else if (ensoTypeCode === 15002) {
      ensoTitle = "태양열";
    } else if (ensoTypeCode === 15003) {
      ensoTitle = "지열";
    } else if (ensoTypeCode === 15004) {
      ensoTitle = "풍력";
    } else if (ensoTypeCode === 15006) {
      ensoTitle = "연료전지";
    } else if (ensoTypeCode === 15007) {
      ensoTitle = "ESS";
    }
    return ensoTitle;
  });

  return (
    <>
      <table className="chmapTable-D">
        <tbody>
          <tr>
            <th rowSpan="4">
              <img src={getEnsoImgSrc(dataItem.ensoTypeCode)} />
              <div>{getEnsoText(dataItem.ensoTypeCode)}</div>
            </th>
            <td>설비용량</td>
            <td>
              {numFormat(roundNum(dataItem.instCapa, 2))}
              <span>kW</span>
            </td>
          </tr>
          {dataItem.isUse === false && (
            <tr>
              <td>누적발전량</td>
              <td>
                {numFormat(roundNum(dataItem.accumPower, 2))}
                <span>kWh</span>
              </td>
            </tr>
          )}
          {dataItem.isUse === true && (
            <tr>
              <td>누적사용량</td>
              <td>
                {numFormat(roundNum(dataItem.accumUse, 2))}
                <span>MWh</span>
              </td>
            </tr>
          )}
          <tr>
            <td>
              누적CO<sub>2</sub>저감량
            </td>
            <td>
              {dataItem.co2 !== "-" ? numFormat(roundNum(dataItem.co2, 2)) : "-"}
              <span>
                tCO<sub>2</sub>
              </span>
            </td>
          </tr>
          <tr>
            <td>운영개소</td>
            <td>
              {numFormat(dataItem.meainCnt)}
              <span>개소</span>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default EnsoTypeItem;
