import { roundNum } from "../../com/dataUtil";

export const getRankPer = () => {
  const data = [
    { rgnCode: "44250", rgnTerm: "계룡시", rankPer: 0.3 },
    { rgnCode: "44710", rgnTerm: "금산군", rankPer: 10.2 },
    { rgnCode: "44150", rgnTerm: "공주시", rankPer: 17.4 },
    { rgnCode: "44230", rgnTerm: "논산시", rankPer: 25 },
    { rgnCode: "44270", rgnTerm: "당진시", rankPer: 30 },
    { rgnCode: "44180", rgnTerm: "보령시", rankPer: 40 },
    { rgnCode: "44760", rgnTerm: "부여군", rankPer: 50 },
    { rgnCode: "44210", rgnTerm: "서산시", rankPer: 55 },
    { rgnCode: "44770", rgnTerm: "서천군", rankPer: 50 },
    { rgnCode: "44200", rgnTerm: "아산시", rankPer: 50 },
    { rgnCode: "44810", rgnTerm: "예산군", rankPer: 88 },
    { rgnCode: "44130", rgnTerm: "천안시", rankPer: 90 },
    { rgnCode: "44790", rgnTerm: "청양군", rankPer: 95 },
    { rgnCode: "44825", rgnTerm: "태안군", rankPer: 98 },
    { rgnCode: "44800", rgnTerm: "홍성군", rankPer: 14 },
  ];
  return data;
};

/**
 * 각 시군구별 설비용량, 발전량, 사용량, 랭크, 실측발전량, 예상발전량, 실측 사용량, 탄소 저감량 where rgnCode
 * @param {*} conn
 * @param {*} rgnCode
 * @returns
 */
export const getRenewDashboardList = async (conn, rgnCode) => {
  const result = await conn.get("renew/get/dashboard", {
    params: {
      rgnCode: rgnCode,
    },
  });
  return result;
};

/**
 * 각 시군구별 설비 운영 현황 상태 코드 개수 where rgnCode
 * @param {*} conn
 * @param {*} rgnCode
 * @returns
 */
export const getRenewOperCntDtoList = async (conn, rgnCode) => {
  const result = await conn.get("renew/get/operCnt", {
    params: {
      rgnCode: rgnCode,
    },
  });
  return result;
};

/**
 * 각 시군구별 에너지원별 통계 ( 설비개수, 설비용량, 발전/사용량)
 * @param {*} conn
 * @param {*} rgnCode
 * @returns
 */
export const getRenewEnsoStatDtoList = async (conn, rgnCode) => {
  const result = await conn.get("renew/get/ensoStat", {
    params: {
      rgnCode: rgnCode,
    },
  });
  return result;
};

/**
 *  각 시군구별 사업구분별 통계 ( 개수, 용량, 발전량, 사용량, 탄소저감량)
 * @param {*} conn
 * @param {*} rgnCode
 * @returns
 */
export const getRenewBizStatDtoList = async (conn, rgnCode) => {
  const result = await conn.get("renew/get/bizStat", {
    params: {
      rgnCode: rgnCode,
    },
  });
  const rems = result.find((e) => e.bizSct === 33000);
  const pwrEff = rems.dayPower / rems.gelecInstCapa;
  const useEff = rems.dayUse / rems.prdctInstCapa;

  result.forEach((item) => {
    item.instCapa = roundNum(item.gelecInstCapa + item.prdctInstCapa, 2);
    item.dayPower = roundNum(item.gelecInstCapa * pwrEff, 2);
    item.dayUse = roundNum(item.prdctInstCapa * useEff, 2);
    item.carbDec = roundNum((item.gelecInstCapa * pwrEff + item.prdctInstCapa * useEff) * 0.4653, 2);
  });
  return result;
};

/**
 *  각 시군구별 사용량 상위 Top3 통계 ( 개수, 용량, 발전량, 사용량 )
 * @param {*} conn
 * @param {*} rgnCode
 * @returns
 */
export const getRenewTop3EnsoStatDtoList = async (conn, rgnCode) => {
  const result = await conn.get("renew/get/top3EnsoStat", {
    params: {
      rgnCode: rgnCode,
    }
  });
  return result;
}

/**
 * 시군구별 현재 년도 월간 발전량 추이
 * @param {*} conn 
 * @param {*} rgnCode 
 * @returns 
 */
export const getRenewMonthPowerData = async (conn, rgnCode) => {
  const result = await conn.get("renew/get/monthPower", {
    params: {
      rgnCode: rgnCode,
    },
  });
  return result;
}