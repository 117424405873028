import React, { useState, useEffect, useRef } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { RadioButton } from "@progress/kendo-react-inputs";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Button } from "@progress/kendo-react-buttons";
import kendo from "@progress/kendo-ui";
import { yearList } from "../../../../com/dropDownDs.js";
import DatePickerKo from "../../../com/dateInputs/datePickerKo";

const EnsoSearch = ({ setSearch, codeList, tabStat }) => {
  // 에너지원 드롭다운
  const ensoTypeCodeNo = useRef(15001);
  // 건물용도
  const bldSrvCodeNo = useRef("");
  // 사업구분
  const bizSct = useRef("");
  // 사업연도
  const bizYy = useRef("");
  // 날짜조회
  const [dateVal, setDateVal] = useState({ codeNo: 23002, codeVal: "어제" });

  // 에너지원 데이터 소스
  const ensoList = codeList.filter((c) => c.grpCodeNo === 15 && c.codeNo !== 15007);
  // 날짜 검색 데이터 소스
  const dateList = codeList.filter((c) => c.grpCodeNo === 23 && c.codeNo !== 23001);
  // 사업구분 데이터 소스
  const bizSctList = codeList.filter((c) => c.grpCodeNo === 33);
  //건물용도별 데이터 소스
  const bldSrvList = codeList.filter((c) => c.grpCodeNo === 31);

  // 체크 박스
  const [selectedValue, setSelectedValue] = useState("HH");

  // 어제 날짜 정의
  const today = new Date();
  const yesterday = new Date();
  const lastWeek = new Date();
  const lastMonth = new Date();
  const lastYear = new Date();
  yesterday.setDate(today.getDate() - 1);
  lastWeek.setDate(yesterday.getDate() - 6);
  lastMonth.setDate(yesterday.getDate() - 30);
  lastYear.setDate(yesterday.getDate() - 365);

  // 날짜
  const [start, setStart] = useState(yesterday);
  const end = useRef(yesterday);
  const [startMax, setStartMax] = useState(yesterday);
  const [endMin, setEndMin] = useState(yesterday);

  // 라디오 버튼 이벤트
  const radioChange = (e) => {
    setSelectedValue(e.value);
  };

  // 드롭다운 체인지 이벤트
  const onchange = (e) => {
    if (e.target.name === "ensoTypeDropDown") {
      ensoTypeCodeNo.current = e.value.codeNo;
    } else if (e.target.name === "bldSrvCodeNo") {
      bldSrvCodeNo.current = e.value.codeNo;
    } else if (e.target.name === "bizSct") {
      bizSct.current = e.value.codeNo;
    } else if (e.target.name === "bizYy") {
      if (e.target.value === "사업연도") {
        bizYy.current = "";
      } else {
        bizYy.current = e.target.value;
      }
    }
    // 날짜 검색
    else if (e.target.name === "date") {
      setDateVal(e.target.value);
      if (e.target.value.codeNo === 23002) {
        setStart(yesterday);
      } else if (e.target.value.codeNo === 23003) {
        setStart(lastWeek);
      } else if (e.target.value.codeNo === 23004) {
        setStart(lastMonth);
      } else if (e.target.value.codeNo === 23005) {
        setStart(lastYear);
      } else if (e.target.value.codeNo === 23006) {
        setStart(yesterday);
      }
    }
  };

  // 조회 버튼 이벤트
  const clickInqBtn = (e) => {
    if (ensoTypeCodeNo.current === "15004" || ensoTypeCodeNo.current === "15006" || ensoTypeCodeNo.current === "15007") {
      kendo.alert("추후 준비 중 입니다.");
      return;
    }

    let startDay = kendo.toString(start, "yyyy-MM-dd");
    let endDay = kendo.toString(end.current, "yyyy-MM-dd");

    // 시간별일 경우 시작일 과 마지막일은 같다.
    if (selectedValue === "HH") {
      startDay = kendo.toString(start, "yyyy-MM-dd");
      endDay = kendo.toString(start, "yyyy-MM-dd");
    }
    // 월별일 경우 시작일은 1일 마지막일은 마지막 날짜로 한다.
    else if (selectedValue === "MM") {
      startDay = kendo.toString(start, "yyyy-MM-01");
      endDay = kendo.toString(end.current, "yyyy-MM-dd");
      // 마지막 날짜 설정
      const endDate = new Date(endDay);
      const year = kendo.toString(endDate, "yyyy");
      const month = kendo.toString(endDate, "MM");
      const lastDay = new Date(year, month, 0);
      endDay = kendo.toString(lastDay, "yyyy-MM-dd");
    } else {
      startDay = kendo.toString(start, "yyyy-MM-dd");
      endDay = kendo.toString(end.current, "yyyy-MM-dd");
    }

    // 탭 초기화
    tabStat.state = 0;
    // 조회 값 설정
    setSearch((search) => {
      const item = { ...search };
      item.ensoTypeCodeNo = ensoTypeCodeNo.current;
      item.bldSrvCodeNo = bldSrvCodeNo.current;
      item.bizSct = bizSct.current;
      item.bizYy = bizYy.current;
      item.unit = selectedValue;
      item.start = startDay;
      item.end = endDay;
      return item;
    });
    // setSearch({
    //   ensoTypeCodeNo: ensoTypeCodeNo.current,
    //   bldSrvCodeNo: bldSrvCodeNo.current,
    //   bizSct: bizSct.current,
    //   bizYy: bizYy.current,
    //   unit: selectedValue,
    //   start: startDay,
    //   end: endDay
    // });
  };

  return (
    <div className="uk-grid">
      <div className="uk-width-medium-1-1">
        <div className="md-card TBgA">
          <div className="md-card-content small-padding">
            <span className="searchSubTitleA">에너지원</span>
            <label for="ensoList">
              <DropDownList
                data={ensoList}
                defaultValue={{ codeVal: "태양광", codeNo: 15001 }}
                name="ensoTypeDropDown"
                textField="codeVal"
                dataItemKey="codeNo"
                onChange={onchange}
                className="searchArea"
              />
            </label>
            <label for="bizSct">
              <DropDownList
                className="ensoDropDown bizSctCodeNoDropDown"
                id="bizSct"
                name="bizSct"
                data={bizSctList}
                defaultItem={{ codeNo: "", codeVal: "사업구분" }}
                dataItemKey="codeNo"
                textField="codeVal"
                onChange={onchange}
              />
            </label>
            <label for="bldSrvCodeNo">
              <DropDownList
                className="ensoDropDown"
                id="bldSrvCodeNo"
                name="bldSrvCodeNo"
                data={bldSrvList}
                defaultItem={{ codeNo: "", codeVal: "건물용도" }}
                dataItemKey="codeNo"
                textField="codeVal"
                onChange={onchange}
              />
            </label>
            <label for="bizYy">
              <DropDownList className="ensoDropDown yearDropDown" id="bizYy" name="bizYy" data={yearList()} defaultItem="사업연도" onChange={onchange} />
            </label>
            <span className="cmpEnsoSrchRight">
              <span className="searchSubTitleA">조회 기간</span>
              <label for="date">
                <DropDownList
                  className="ensoDropDown yearDropDown"
                  id="date"
                  name="date"
                  data={dateList}
                  dataItemKey="codeNo"
                  textField="codeVal"
                  defaultValue={{ codeNo: 23006, codeVal: "직접조회" }}
                  onChange={onchange}
                  value={dateVal}
                />
              </label>
              <span className="radioForm">
                <RadioButton name="cc" value="HH" label="" className="radioBtn" checked={selectedValue === "HH"} onChange={radioChange} />
                <span></span>시간별 &nbsp;&nbsp;&nbsp;&nbsp;
                <RadioButton name="cc" value="DD" label="" className="radioBtn" checked={selectedValue === "DD"} onChange={radioChange} />
                <span></span>일별 &nbsp;&nbsp;&nbsp;&nbsp;
                <RadioButton name="cc" value="MM" label="" className="radioBtn" checked={selectedValue === "MM"} onChange={radioChange} />
                <span></span>월별 &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
              {/* <span className="searchSubTitleA">설비날짜</span> */}
              <label for="start">
                <DatePickerKo
                  id="start"
                  name="start"
                  className="datePicker"
                  defaultValue={yesterday}
                  format={selectedValue === "MM" ? "yyyy-MM" : "yyyy-MM-dd"}
                  setDtm={(v) => {
                    setStart(v);
                    setDateVal({ codeNo: 23006, codeVal: "직접조회" });
                    setEndMin(v);
                  }}
                  value={start}
                  readonly={true}
                  max={startMax}
                  view={selectedValue === "MM" ? "year" : null}
                />
              </label>
              {selectedValue !== "HH" && <span className="textColorGr datePeriodIcon ">~</span>}
              {selectedValue !== "HH" && (
                <label for="end">
                  <DatePickerKo
                    id="end"
                    name="end"
                    className="datePicker"
                    defaultValue={yesterday}
                    format={selectedValue === "MM" ? "yyyy-MM" : "yyyy-MM-dd"}
                    setDtm={(v) => {
                      end.current = v;
                      setDateVal({ codeNo: 23006, codeVal: "직접조회" });
                      setStartMax(v);
                    }}
                    min={endMin}
                    max={yesterday}
                    readonly={true}
                    view={selectedValue === "MM" ? "year" : null}
                  />
                </label>
              )}
              <Button className="inqBtn" onClick={clickInqBtn} icon="search"></Button>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnsoSearch;
