import './sidebar.css';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { ArrowRight, ArrowDropDown, SubdirectoryArrowRight } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

export const Sidebar = forwardRef((props, ref) => {
  const history = useHistory();
  const { width, height, roleMenu } = props;
  const [xPosition, setXPosition] = useState(-width);
  const [sideMenu, setSideMenu] = useState([]);

  //Spread 문법은 배열을 복사할 때 1 레벨 깊이에서만 동작하므로 직접 배열 돌려서 객체복사를 해줘야함 출처: https://kjwsx23.tistory.com/309
  const initSideMenu = () => {
    let _sideMenu = [...roleMenu];
    for (let i = 0; i < roleMenu.length; i++) {
      _sideMenu[i] = { ...roleMenu[i] };
      if (_sideMenu[i].items.length) {
        _sideMenu[i].items = [...roleMenu[i].items];
        for (let j = 0; j < _sideMenu[i].items.length; j++) {
          _sideMenu[i].items[j] = { ...roleMenu[i].items[j] };
        }
      }
    }
    setSideMenu([..._sideMenu]);
  };

  //roleMenu 받아와서 값만 복사
  useEffect(() => {
    initSideMenu();
  }, [roleMenu]);

  //부모 컴포넌트에서 자식 컴포넌트 함수 호출을 위함
  useImperativeHandle(ref, () => ({
    menuClickEvt() {
      if (xPosition !== 0) {
        setXPosition(0);
      } else {
        setXPosition(-width);
      }
    },
  }));

  const sideBarExpandClickEvt = (item) => {
    if (item.clicked === true) {
      item.clicked = false;
    } else {
      item.clicked = true;
    }
    setSideMenu([...sideMenu]);
  };

  const sideBarContentClickEvt = (url) => {
    history.push(url);
    if (xPosition !== 0) {
      setXPosition(0);
    } else {
      setXPosition(-width);
    }
    initSideMenu();
  };

  return (
    <div
      className="sideBar"
      style={{
        transform: `translatex(${xPosition}px)`,
        width: width,
        minHeight: height,
      }}
    >
      <div className="sideBarContentDiv">
        {sideMenu.map((md1) =>
          md1.moblPageYn === 'Y' && md1.checked ? (
            md1.items.length ? (
              <>
                {md1.clicked ? (
                  <>
                    <div className="sideBarClickedContent" onClick={() => sideBarExpandClickEvt(md1)}>
                      {md1.term}
                      <span className="sideBarSpan">
                        <ArrowDropDown style={{ fontSize: '25px' }} />
                      </span>
                    </div>
                    <div className="sideBarSecContents">
                      {md1.items.map((md2) =>
                        md2.moblPageYn === 'Y' && md2.checked ? (
                          md2.items.length ? (
                            <>
                              {md2.clicked ? (
                                <>
                                  <div className="sideBarSecClickedContent" onClick={() => sideBarExpandClickEvt(md2)}>
                                    <span className="subdirectorySpan">
                                      <SubdirectoryArrowRight style={{ fontSize: '15px' }} />
                                    </span>
                                    {md2.term}
                                    <span className="sideBarSpan">
                                      <ArrowDropDown style={{ fontSize: '25px' }} />
                                    </span>
                                  </div>
                                  <div className="sideBarThirdContents">
                                    {md2.items.map((md3) =>
                                      md3.moblPageYn === 'Y' && md3.checked ? (
                                        <div className="sideBarThirdContent" onClick={() => sideBarContentClickEvt(md3.menuUrl)}>
                                          <span className="subdirectorySpan">
                                            <SubdirectoryArrowRight style={{ fontSize: '15px' }} />
                                          </span>
                                          {md3.term}
                                        </div>
                                      ) : null,
                                    )}
                                  </div>
                                </>
                              ) : (
                                <div className="sideBarSecContent" onClick={() => sideBarExpandClickEvt(md2)}>
                                  <span className="subdirectorySpan">
                                    <SubdirectoryArrowRight style={{ fontSize: '15px' }} />
                                  </span>
                                  {md2.term}
                                  <span className="sideBarSpan">
                                    <ArrowRight style={{ fontSize: '25px' }} />
                                  </span>
                                </div>
                              )}
                            </>
                          ) : (
                            <div className="sideBarSecContent" onClick={() => sideBarContentClickEvt(md2.menuUrl)}>
                              <span className="subdirectorySpan">
                                <SubdirectoryArrowRight style={{ fontSize: '15px' }} />
                              </span>
                              {md2.term}
                            </div>
                          )
                        ) : null,
                      )}
                    </div>
                  </>
                ) : (
                  <div className="sideBarContent" onClick={() => sideBarExpandClickEvt(md1)}>
                    {md1.term}
                    <span className="sideBarSpan">
                      <ArrowRight style={{ fontSize: '25px' }} />
                    </span>
                  </div>
                )}
              </>
            ) : (
              <div className="sideBarContent" onClick={() => sideBarContentClickEvt(md1.menuUrl)}>
                {md1.term}
              </div>
            )
          ) : null,
        )}
      </div>
      {/* <div className="sideBarFooter">
        <img src="/images/SR_EMS_header_logo.png"></img>
      </div> */}
    </div>
  );
});
