import React, { useState, useEffect, useCallback } from "react";
import ReportConsmGrid from "./reportConsmGrid";
import ConsmSearch from "./consmSearch";

import "./reportConsm.css";
import ReportConsmWarnChart from "./reportConsmWarnChart";
import ReportConsmPresenter from "./reportConsmPresenter";

function ReportConsm({ conn, rgnList, dongList }) {
  let today = new Date();

  const [selectMeain, setSelectMeain] = useState();
  const [energyData, setEnergyData] = useState([
    {
      totalPower: "-",
      accumPower: "-",
      powerTime: "-",
      powerCo2: "-",
      powerEfic: "-",
      totalCal: "-",
      accumCal: "-",
    },
  ]);

  const [energyCount, setEnergyCount] = useState([
    {
      totalCnt: "-",
      totalCapa: "-",
      solaCnt: "-",
      solaCapa: "-",
      heatCnt: "-",
      heatCapa: "-",
      heathCnt: "-",
      heathCapa: "-",
      veloCnt: "-",
      veloCapa: "-",
      fuelcellCnt: "-",
      fuelcellCapa: "-",
      essCnt: "-",
      essCapa: "-",
    },
  ]);

  const [chartWarn, setChartWarn] = useState({
    unOperCnt: [],
    waringCnt: [],
    errorCnt: [],
  });

  const [chartMonthData, setChartMonthData] = useState({
    energy: [],
    preEnergy: [],
  });

  const [chartYearData, setChartYearData] = useState({
    energy: [],
    preEnergy: [],
  });

  // 검색 조건
  const [search, setSearch] = useState({
    dtm: today,
    consmId: "",
    userTerm: "",
  });

  return (
    <>
      <div className="consmDiv">
        <div className="consmDivIn">
          <ConsmSearch
            conn={conn}
            rgnList={rgnList}
            dongList={dongList}
            search={search}
            setSearch={setSearch}
            selectMeain={selectMeain}
            setSelectMeain={setSelectMeain}
            energyData={energyData}
            setEnergyData={setEnergyData}
            chartMonthData={chartMonthData}
            chartWarn={chartWarn}
            setChartWarn={setChartWarn}
            setChartMonthData={setChartMonthData}
            chartYearData={chartYearData}
            setChartYearData={setChartYearData}
            energyCount={energyCount}
            setEnergyCount={setEnergyCount}
          />
          {search.consmId ? (
            <ReportConsmPresenter
              energyData={energyData}
              energyCount={energyCount}
              chartWarn={chartWarn}
              selectMeain={selectMeain}
              chartMonthData={chartMonthData}
              chartYearData={chartYearData}
            ></ReportConsmPresenter>
          ) : (
            <b>데이터가 존재하지 않습니다. 수용가를 선택해 주시기 바랍니다.</b>
          )}
        </div>
      </div>
    </>
  );
}

export default ReportConsm;
