// React
import React, { useState, useEffect, useCallback, useMemo } from "react";
import log from "../../../com/log";
import { parseDate } from "../../../com/dateUtil";
import { getGroupManager, setUpdateGroupManager, setDeleteGroupManager } from "../../../service/mngt/groupManager/groupManagerService";
import GroupManagerModal from "./groupManagerModal";
import { downloadExcel } from "../../../com/downloadExcel";

// Kendo
import kendo from "@progress/kendo-ui";
import { IntlProvider, LocalizationProvider, loadMessages } from "@progress/kendo-react-intl";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Upload } from "@progress/kendo-react-upload";

import GridColumnInputFilter from "../../com/gridFilter/gridColumnInputFilter";

// Css
import "./groupManager.css";
import styles from "./groupManager.module.css";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { useHistory } from "react-router";

const GroupManager = ({ conn, rgnList, dongList }) => {
  // 히스토리 사용
  const history = useHistory();

  // 드롭다운 필터 상태
  const [filterDropValue, setFilterDropValue] = useState({
    rgn: { rgnCode: "", rgnTermSimple: "주소검색" },
  });

  const [dataSource, setDataSource] = useState({
    total: 0,
    dataList: [],
  });
  const [dataState, setDataState] = useState({
    take: 20,
    skip: 0,
    sort: [],
    sortStatus: [],
    filter: { userId: "", userTerm: "", rgnCode: "" },
  });
  const [modalState, setModalState] = useState({
    visible: false,
    title: "",
    formItem: [],
  });
  const [uploadState, setUploadState] = useState({
    files: [],
    events: [],
    filePreviews: {},
    afterStateChange: [],
    response: [],
    visible: false,
  });

  // 단순 그리드 데이터 조회
  const getGridData = () => {
    getGroupManager(conn, dataState, rgnList)
      .then((response) => {
        setDataSource((dataSource) => {
          const item = { ...dataSource };
          item.total = response.total;
          item.dataList = response.results;
          return item;
        });
      })
      .catch((error) => {
        if (error.errCode === "L007") {
          alert("로그인 한 ID는 해당 메뉴에 접근할 수 없습니다.");
          history.goBack();
          return;
        }
      });
  };

  // 그리드 페이징 처리
  const handlePageChange = (e) => {
    const { take, skip } = e.page;
    dataState.skip = skip;
    dataState.take = take;
    getGridData();
  };

  // 그리드 소팅 처리
  const handleSortChange = (e) => {
    const { sort } = e;
    dataState.sort = sort;
    dataState.sortStatus = sort;
    if (sort.length !== 0) {
      if (sort[0].field === "rgnTermSimple") {
        dataState.sort = "rgnCode." + sort[0].field + "," + sort[0].dir;
      } else {
        dataState.sort = sort[0].field + "," + sort[0].dir;
      }
    }
    getGridData();
  };

  // 그리드 필터링 처리
  const handleFilterChange = (e) => {
    // 필터 값 설정
    const isFilter = e.filter.filters;
    if (isFilter.length !== 0) {
      if (isFilter[0].field === "userId") {
        dataState.filter.userId = isFilter[0].value;
      } else if (isFilter[0].field === "userTerm") {
        dataState.filter.userTerm = isFilter[0].value;
      } else if (isFilter[0].field === "rgnTermSimple") {
        dataState.filter.rgnCode = isFilter[0].value.rgnCode;
      }
    }
    getGridData();
  };

  // 추가 모달창 저장 처리
  const handleModalSaveSubmit = useCallback(
    (e) => {
      setModalState({ ...modalState, visible: !modalState.visible, title: "추가", formItem: [] });

      // 저장할 데이터 설정
      if (e.smsNotiUseYn === undefined) e.smsNotiUseYn = "Y";
      if (e.emailNotiUseYn === undefined) e.emailNotiUseYn = "Y";
      e.userAddr = e.rgnInfo ? e.rgnInfo.rgnTerm : null;
      e.rgnCode = e.rgnInfo ? e.rgnInfo.rgnCode : null;
      e.cityProvCode = e.rgnInfo ? e.rgnInfo.cityProvCode : null;
      e.rgnTermSimple = e.rgnInfo ? e.rgnInfo.rgnTermSimple : null;
      e.regiDtm = parseDate("LocalDateTime");
      e.useYn = e.useYn ? e.useYn : "Y";
      e.createUpdateYn = true;

      // 배열로 저장
      const saveDataList = [e];

      setUpdateGroupManager(conn, saveDataList, rgnList)
        .then((response) => {
          setModalState({ ...modalState, visible: !modalState.visible, title: "", formItem: [] });
          getGridData();
        })
        .catch((err) => {
          log(`manager.handleModalSaveSubmit error: ${err}`);
        });
    },
    [modalState, dataSource, dataState],
  );

  // 시/군 필터
  const handleRgnFilterList = (props) => {
    const filterOnchage = (e) => {
      props.onChange({
        value: e.value,
        operator: e.value ? "eq" : "",
        syntheticEvent: e.syntheticEvent,
      });
      setFilterDropValue((filterDropValue) => {
        const item = { ...filterDropValue };
        item.rgn = e.value;
        return item;
      });
    };

    return (
      <div className="k-filtercell">
        <DropDownList
          data={rgnList}
          onChange={filterOnchage}
          defaultItem={{ rgnCode: "", rgnTermSimple: "주소검색" }}
          className="CustomFilterDropDown"
          textField="rgnTermSimple"
          dataItemKey="rgnCode"
          name="rgnTermSimple"
          value={filterDropValue.rgn}
        />
      </div>
    );
  };

  useEffect(() => {
    getGridData();
  }, []);

  return (
    <>
      <div className="managerBody">
        <div className="managerCate">
          <Button
            onClick={useCallback(
              (e) => {
                setModalState({ ...modalState, visible: !modalState.visible, title: "추가", createUpdateYn: true, formItem: [] });
              },
              [modalState],
            )}
          >
            <img src="/images/icon_add.png" alt="" />
            &nbsp; 추가
          </Button>
        </div>
        <div className="managerGrid">
          <LocalizationProvider language="ko-KR">
            <IntlProvider locale="ko">
              <Grid
                className={styles.managerGrid}
                data={dataSource.dataList}
                skip={dataState.skip}
                take={dataState.take}
                total={dataSource.total}
                sort={dataState.sortStatus}
                pageable={{ buttonCount: 5, pageSizes: true }}
                filterable
                sortable
                onPageChange={handlePageChange}
                onFilterChange={handleFilterChange}
                onSortChange={handleSortChange}
              >
                <GridColumn title="담당자" field="userTerm" filterCell={GridColumnInputFilter} />
                <GridColumn title="ID" field="userId" filterCell={GridColumnInputFilter} />
                <GridColumn title="시/군" field="rgnTermSimple" filterCell={handleRgnFilterList} />
                <GridColumn title="연락처" field="userCelphon" filterable={false} />
                <GridColumn title="이메일" field="userEmail" filterable={false} />
                <GridColumn title="등록일시" field="regiDtm" filterable={false} />
                <GridColumn title="이벤트 알림여부" sortable={false}>
                  <GridColumn title="SMS" field="smsNotiUseYn" filterable={false} width={60} />
                  <GridColumn title="EMAIL" field="emailNotiUseYn" filterable={false} width={80} />
                </GridColumn>
                <GridColumn
                  title="수정"
                  filterable={false}
                  sortable={false}
                  width={60}
                  cell={(props) => (
                    <td>
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          setModalState({
                            ...modalState,
                            visible: !modalState.visible,
                            title: "수정",
                            createUpdateYn: false,
                            formItem: props.dataItem,
                          });
                        }}
                      >
                        <img src="/images/icon_su.png" alt="" />
                      </a>
                    </td>
                  )}
                />
                <GridColumn
                  title="삭제"
                  filterable={false}
                  sortable={false}
                  width={60}
                  cell={(props) => (
                    <td>
                      <a
                        onClick={(e) => {
                          if (window.confirm("정말 삭제하시겠습니까?")) {
                            e.preventDefault();
                            const { userId } = props.dataItem;
                            setDeleteGroupManager(conn, userId)
                              .then((response) => {
                                getGridData(conn, dataState);
                              })
                              .catch((err) => {
                                log(`managerGrid.setDeleteManager error: ${err}`);
                              });
                          } else {
                          }
                        }}
                      >
                        <img src="/images/icon_del.png" alt="" />
                      </a>
                    </td>
                  )}
                />
                <GridNoRecords>데이터가 존재하지 않습니다.</GridNoRecords>
              </Grid>
            </IntlProvider>
          </LocalizationProvider>
          {modalState.visible && (
            <GroupManagerModal
              modalState={modalState}
              setModalState={setModalState}
              rgnList={rgnList}
              handleModalSaveSubmit={handleModalSaveSubmit}
              handleModalUpdateSubmit={handleModalSaveSubmit}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default GroupManager;
