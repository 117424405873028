// 그래프
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

// 그리드
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-data-query";
import React, { useState } from "react";
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import kendo from "@progress/kendo-ui";

// 버튼 이벤트
import { Button } from "@progress/kendo-react-buttons";

// 컴마표시
import { numFormat } from "../../../../com/dataUtil";
import { IntlProvider, LocalizationProvider, loadMessages } from "@progress/kendo-react-intl";

export const AreaMaker = ({ dataSource, search, setModlDataSource, setMakrDataSource }) => {
  // 설비용량 , 사용량 , 발전량 가공
  dataSource.makrList.forEach((element) => {
    element.dayPower = element.dayPower ? numFormat(element.dayPower) : 0;
    element.dayUse = element.dayUse ? numFormat(element.dayUse) : 0;
    element.instCapa = element.instCapa ? numFormat(element.instCapa) : 0;
  });

  // 엑셀 다운로드 변수 (레퍼런스 사용)
  let _excelData;

  // 발전량 , 사용량 조건부 렌더링
  const isUse = search.ensoTypeCodeNo === 15002 || search.ensoTypeCodeNo === 15003 ? true : false;

  // 페이징, 정렬 상태
  let [paging, setPaging] = useState({ take: 10, skip: 0 });
  let [sort, setSort] = useState([{ field: "", dir: "" }]);

  // 페이징 이벤트
  const onPageChange = (e) => {
    const { page } = e;
    setPaging(page);
  };

  // 정렬 이벤트
  const onSortChange = (e) => {
    const { sort } = e;
    setSort(sort);
  };
  Highcharts.setOptions({
    lang: {
      resetZoom: "초기화",
    },
  });
  // 제조사  / 모델 차트 옵션
  const chartConfig = {
    chart: { zoomType: "xy" },
    noData: { style: { color: "#FFFFFF", fontSize: "14px" } },
    title: { text: null },
    legend: { enabled: false },
    tooltip: {
      shared: true,
      positioner: function (labelWidth, labelHeight, point) {
        // 툴팁 위치 조정
        const tooltipX = point.plotX;
        const tooltipY = 150;
        return {
          x: tooltipX,
          y: tooltipY,
        };
      },
      formatter: function () {
        var s = "<b>" + this.points[0].point.name + "</b>";
        s +=
          "<br/>" +
          '<span style="color:' +
          this.points[0].point.color +
          '">\u25CF</span> ' +
          this.points[0].series.name +
          ": " +
          Math.round(this.y * 100) / 100 +
          " kWh/" +
          (search.ensoTypeCodeNo == 15002 ? "㎡" : "kW") +
          ".day";
        s +=
          "<br/>" +
          '<span style="color:' +
          this.points[1].point.color +
          '">\u25CF</span> ' +
          this.points[1].series.name +
          ": " +
          Math.round(this.points[1].y) +
          " % (" +
          numFormat(this.points[1].point.instCapa) +
          (search.ensoTypeCodeNo == 15002 ? "㎡" : "kW") +
          ")";
        return s;
      },
    },
    xAxis: { type: "category", crosshair: true },
    yAxis: [
      {
        // Primary yAxis
        labels: {
          format: "{value:,.2f}",
          style: {
            color: "#69bcc8",
          },
        },
        title: {
          text: isUse === false ? "용량대비발전량(kWh/" : "용량대비발전량(kWh/" + (search.ensoTypeCodeNo == 15002 ? "㎡" : "kW") + ".day)",
          style: {
            color: "#69bcc8",
          },
        },
      },
      {
        // Secondary yAxis
        min: 0,
        title: {
          text: "설비용량비율(%)",
          style: {
            color: "#c1ce01",
          },
        },
        labels: {
          format: "{value:,.0f}",
          style: {
            color: "#c1ce01",
          },
        },
        opposite: true,
      },
    ],
    credits: { enabled: false },
    plotOptions: {
      series: { colorByPoint: true },
      column: {
        cursor: "pointer",
        point: {
          events: {
            click: function (e) {
              if (dataSource.makrDrillDown == false) {
                setModlDataSource(e.point.makrSeriNo);
              } else {
                setMakrDataSource(dataSource.rgnCode);
              }
            },
          },
        },
      },
    },
    colors: ["#b77d08", "#6f8835", "#1f624d", "#542871", "#85275c", "#32768e", "#33427a", "#818385"],
    series: [
      {
        name: "용량대비발전량",
        type: "column",
        data: dataSource.makrRankList.efic ? dataSource.makrRankList.efic : [],
        dataLabels: {
          enabled: true,
          format: "{point.y:,.2f}",
        },
        tooltip: {
          pointFormat:
            '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:,.2f} kWh/' +
            (search.ensoTypeCodeNo == 15002 ? "㎡" : "kW") +
            ".day</b><br/>",
        },
      },
      {
        name: "설비용량비율",
        type: "column",
        yAxis: 1,
        data: dataSource.makrRankList.ratio ? dataSource.makrRankList.ratio : [],
        color: "#c1ce01",
        dataLabels: {
          enabled: true,
          format: "{point.y:,.2f}%",
        },
        tooltip: {
          pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:,.2f} %</b><br/>',
        },
      },
    ],
  };

  // 파일 이름
  const fileName = () => {
    let template = "";
    if (dataSource.makrDrillDown === false) {
      template = "제조사_";
    } else {
      template = "모델_";
    }
    template += "데이터_" + +kendo.toString(new Date(), "yyyyMMdd") + ".xlsx";
    return template;
  };

  return (
    <div className="md-card-content">
      <div className="md-card-toolbar">
        <h3 className="md-card-toolbar-heading-text">
          {dataSource.makrDrillDown === false && <span>제조사간 발전량 분포 | 목록</span>}
          {dataSource.makrDrillDown === true && <span>제조사모델간 발전량 분포 | 목록</span>}
        </h3>
        <div className="md-card-toolbar-actions">
          <Button
            className="cmpAreaExcelBtn"
            icon="excel"
            onClick={(e) => {
              _excelData.save();
            }}
          >
            엑셀저장
          </Button>
        </div>
      </div>
      <div style={{ height: "370px" }}>
        <HighchartsReact
          Highcharts={Highcharts}
          containerProps={{ style: { height: "100%", borderTop: "1px solid gainsboro" } }}
          options={chartConfig}
        ></HighchartsReact>
      </div>
      <div className="Table-scr" style={{ height: "400px" }}>
        <ExcelExport data={orderBy(dataSource.makrList, sort)} ref={(exporter) => (_excelData = exporter)} fileName={fileName()}>
          {dataSource.makrDrillDown === false && <ExcelExportColumn title="제조사" field="makrTerm" />}
          {dataSource.makrDrillDown === true && <ExcelExportColumn title="모델명" field="meainTypeTerm" />}
          <ExcelExportColumn title="발전량(kWh)" field="dayPower" />
          <ExcelExportColumn title="설비용량(kW)" field="instCapa" />
          <ExcelExportColumn title="설비용량비율(%)" field="ratio" />
          <ExcelExportColumn title="용량대비발전량(kWh/kW.day)" field="instVsPower" />
          <ExcelExportColumn title="운영개소" field="count" />
        </ExcelExport>

        <LocalizationProvider language="ko-KR">
          <IntlProvider locale="ko">
            <Grid
              style={{ height: "400px" }}
              className="areaMakerGrid"
              data={orderBy(dataSource.makrList, sort).slice(paging.skip, paging.take + paging.skip)}
              total={dataSource.makrList.length}
              skip={paging.skip}
              take={paging.take}
              onPageChange={onPageChange}
              onSortChange={onSortChange}
              sort={sort}
              page={paging}
              sortable
              pageable
            >
              <GridNoRecords>{"조회 결과 데이터가 없습니다."}</GridNoRecords>
              <GridColumn title="순위" field="no" width="100px" />
              {dataSource.makrDrillDown === false && <GridColumn title="제조사" field="makrTerm" width="150px" />}
              {dataSource.makrDrillDown === true && <GridColumn title="모델명" field="meainTypeTerm" width="150px" />}
              <GridColumn title="발전량(kWh)" field="dayPower" />
              <GridColumn title="설비용량(kW)" field="instCapa" />
              <GridColumn title="설비용량비율(%)" field="ratio" />
              <GridColumn
                title={
                  <div>
                    용량대비발전량<br></br>(kWh/kW.day)
                  </div>
                }
                field="instVsPower"
              />
              <GridColumn title="운영개소" field="count" />
            </Grid>
          </IntlProvider>
        </LocalizationProvider>
      </div>
    </div>
  );
};

export default AreaMaker;
