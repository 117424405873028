import { Button } from "@progress/kendo-react-buttons";
import "./meain_info_control.css";
import kendo from "@progress/kendo-ui";
import { deleteMeain } from "../../../service/meain/meain/meainService";

// 설비 버튼 제어
function Meain_info_control({ conn, paging, resetInfo, setMeainList, ensoTypeCodeNo, setReplaceMeain }) {
  const selectMeain = paging.selectMeain;
  // meainDelete (삭제 로직 )
  const meainDelete = (e) => {
    e.preventDefault();
    kendo.confirm("설비를 삭제하시겠습니까?").then(() => {
      deleteApi();
    });
  };

  // 삭제 API
  const deleteApi = () => {
    // // CID 배열 저장
    var deleteCid = [];
    deleteCid.push(selectMeain.cid);
    deleteMeain(conn, getDeleteResult, getRejection, deleteCid, ensoTypeCodeNo).then(() => {
      resetInfo(paging);
      setMeainList(paging);
    });
  };

  // 삭제 callback
  const getDeleteResult = (result) => {
    kendo.alert("설비를 삭제 하였습니다.");
    meainAdd(paging);
    setMeainList(paging);
  };

  // 설비 저장, 삭제 rejection
  const getRejection = (error, subject) => {
    if (error.errCode === "M001") {
      kendo.alert("설비를 선택하지 않았습니다. 설비를 선택 해주세요.");
      return;
    }
  };

  // meainAdd (완료)
  const meainAdd = (e) => {
    e.preventDefault();
    resetInfo(paging);
  };

  // 설비 교체
  const changeMeain = (e) => {
    e.preventDefault(); //이거 안하면 버그생김 이유는 모르겠음
    if (Object.keys(selectMeain).length === 0) {
      kendo.alert("교체할 설비를 선택 해주세요.");
      return;
    }
    if (selectMeain.meainStatCodeNo === 7008) {
      kendo.alert("교체된 설비는 다시 교체할 수 없습니다. 다른 설비를 선택 해주세요.");
      return;
    }
    kendo.confirm("선택된 설비를 교체 하시겠습니까? 현재 설비는 교체상태로 변경되며 동일정보의 새로운CID로 설비가 생성됩니다.").then((e) => {
      setReplaceMeain();
    });
  };

  return (
    <div>
      <Button icon="save" type="submit" className="meainBtn">
        저장
      </Button>
      <Button icon="delete" className="meainBtn" onClick={meainDelete}>
        삭제
      </Button>
      <Button icon="add" className="meainBtn" onClick={meainAdd}>
        추가
      </Button>
      <Button icon="add" className="meainBtn" onClick={changeMeain}>
        교체
      </Button>
    </div>
  );
}

export default Meain_info_control;
