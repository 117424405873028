import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";
import DatePickerKo from "../../../com/dateInputs/datePickerKo";
import kendo from "@progress/kendo-ui";
import { useState, useEffect, memo } from "react";
import AreaMaker from "./areaMaker";
import AreaRank from "./areaRank";
import { getMakrDataList, getModlDataList, getAreaDataList } from "../../../../service/stats/type/areaDataService";

import "./cmpArea.css";
import { getUserMngRgnList, isManager } from "../../../../service/authService";
import { BeatLoader } from "react-spinners";
import RgnDropdown from "./../../../com/dropdown/rgnDropdown";

const CmpArea = memo(({ conn, codeList, rgnList }) => {
  const [loading, setLoading] = useState(false);
  const ensoList = codeList.filter((c) => c.grpCodeNo === 15 && c.codeNo !== 15007);
  const dateList = codeList.filter((c) => c.grpCodeNo === 23 && c.codeNo !== 23001);

  // 날짜 Default 정의
  let today = new Date();
  let yesterday = new Date();
  let lastWeek = new Date();
  let lastMonth = new Date();
  let lastYear = new Date();
  yesterday.setDate(today.getDate() - 1);
  lastWeek.setDate(yesterday.getDate() - 7);
  lastMonth.setDate(yesterday.getDate() - 30);
  lastYear.setDate(yesterday.getDate() - 365);

  // 날짜 MAX MIN
  let [startMax, setStartMax] = useState(yesterday);
  let [endMin, setEndMin] = useState(yesterday);

  // 날짜 검색 드롭다운
  let [dateVal, setDateVal] = useState({ codeNo: 23002, codeVal: "어제" });

  // 조회 데이터
  const [search, setSearch] = useState({
    ensoTypeCodeNo: 15001,
    rgnCode: "",
    start: yesterday,
    end: yesterday,
  });

  // 랭크 데이터소스
  const [dataSource, setDataSource] = useState({
    rgnList: [],
    rgnRankList: {},
    makrList: [],
    makrRankList: {},
    modlList: [],
    modlRankList: {},
    rgnCode: "",
    makrDrillDown: false,
  });

  // 검색 이벤트
  const setSearchObjEvt = (e) => {
    if (e.target.name === "ensoTypeCodeNo") {
      search.ensoTypeCodeNo = e.target.value.codeNo;
    } else if (e.target.name === "rgnCode") {
      setSearch((state) => {
        const item = { ...state };
        item.rgnCode = e.target.value.rgnCode;
        return item;
      });
    }
    // 날짜 검색
    else if (e.target.name === "date") {
      setDateVal(e.target.value);
      if (e.target.value.codeNo === 23002) {
        setSearch((state) => {
          const item = { ...state };
          item.start = yesterday;
          return item;
        });
      } else if (e.target.value.codeNo === 23003) {
        setSearch((state) => {
          const item = { ...state };
          item.start = lastWeek;
          return item;
        });
      } else if (e.target.value.codeNo === 23004) {
        setSearch((state) => {
          const item = { ...state };
          item.start = lastMonth;
          return item;
        });
      } else if (e.target.value.codeNo === 23005) {
        setSearch((state) => {
          const item = { ...state };
          item.start = lastYear;
          return item;
        });
      } else if (e.target.value.codeNo === 23006) {
        setSearch((state) => {
          const item = { ...state };
          item.start = yesterday;
          return item;
        });
      }
    }
  };

  // 조회 버튼 클릭 이벤트
  const setSearchClickEvt = (e) => {
    setDataSource((dataSource) => {
      const item = { ...dataSource };
      item.makrDrillDown = false;
      return item;
    });
    // dataSource.makrDrillDown = false;
    setRgnDataSource();
  };

  // 지역 데이터 가져와서 데이터 소스 세팅
  const setRgnDataSource = () => {
    setLoading(true);

    getAreaDataList(conn, getRgnList, getRejection, search, setLoading);
  };

  // 지역 데이터 Callback
  const getRgnList = (result) => {
    // 차트데이터 설정
    const obj = getChartCal(result, "rgnCode", "rgnTerm");
    setDataSource((dataSource) => {
      const item = { ...dataSource };
      item.rgnList = result;
      item.rgnRankList = obj;
      return item;
    });
    // 순위 1등 지역 데이터 설정
    setMakrDataSource(obj.efic[0].rgnCode);
  };

  // 제조사 데이터 가져와서 데이터 소스 세팅
  const setMakrDataSource = (rgnCode) => {
    getMakrDataList(conn, getMakrList, getRejection, search, rgnCode);
  };

  const getMakrList = (result, rgnCode) => {
    if (result.length != 0) {
      const obj = getChartCal(result, "makrSeriNo", "makrTerm");
      setDataSource((dataSource) => {
        const item = { ...dataSource };
        item.makrList = result;
        item.makrRankList = obj;
        item.rgnCode = rgnCode;
        item.makrDrillDown = false;
        return item;
      });
    } else {
      setDataSource((dataSource) => {
        const item = { ...dataSource };
        item.makrList = [];
        item.makrRankList = [];
        item.rgnCode = rgnCode;
        item.makrDrillDown = false;
        return item;
      });
    }
  };

  // 모델 데이터 가져와서 데이터 소스 세팅
  const setModlDataSource = (makrSeriNo) => {
    getModlDataList(conn, getModlList, getRejection, search, makrSeriNo, dataSource.rgnCode);
  };

  const getModlList = (result, makrSeriNo) => {
    if (result.length != 0) {
      const obj = getChartCal(result, "meainTypeSeriNo", "meainTypeTerm");
      setDataSource((dataSource) => {
        const item = { ...dataSource };
        item.makrList = result;
        item.makrRankList = obj;
        item.makrDrillDown = true;
        return item;
      });
    }
  };

  // 지역차트 아이템 가공 (동적으로 가공함)
  const getChartCal = (result, key, term) => {
    let ratio = [];
    let efic = [];
    let fullIndex = 6;
    // 최대 6위까지 데이터가 안보이면 데이터 갯수만큼 최대 인덱스를 설정한다.
    if (result.length < 6) {
      fullIndex = result.length;
    }
    // 1위부터 7위까지 랭크
    for (let index = 0; index < fullIndex; index++) {
      ratio.push({
        name: result[index][term],
        y: result[index].ratio,
        instCapa: result[index].instCapa,
        [key]: result[index][key],
      });
      // 색깔 설정
      let color = "#69bcc8";
      if (index == 0) {
        color = "#3e7bab";
      }
      // 설비용량대비효율  (에너지원별 분리 해야함)
      let eficData = 0;
      if (search.ensoTypeCodeNo === 15001 || search.ensoTypeCodeNo === 15004 || search.ensoTypeCodeNo === 15006) {
        eficData = result[index].instVsPower;
      } else {
        eficData = result[index].instVsUse;
      }
      efic.push({
        color: color,
        name: result[index][term],
        y: eficData,
        [key]: result[index][key],
        instCapa: result[index].instCapa,
      });
    }
    const obj = { ratio, efic };
    return obj;
  };

  // reject 함수
  const getRejection = (error) => {
    if (error.errCode === "E001") {
      kendo.alert("에너지원 항목이 선택되지 않았습니다. 에너지원을 다시 선택 해주세요.");
      return;
    } else if (error.errCode === "E002") {
      kendo.alert("지역이 선택되지 않았습니다. 지역을 다시 선택 해주세요.");
      return;
    }
  };

  useEffect(() => {
    // 처음 데이터 불러오는 부분 제어 로직
    setRgnDataSource();
  }, []);

  return (
    <div
      style={
        loading
          ? {
              opacity: 0.6,
              pointerEvents: "none",
            }
          : null
      }
    >
      <div className="uk-grid">
        <div className="uk-width-medium-1-1">
          <div className="md-card TBgA">
            <div className="md-card-content small-padding">
              <span className="searchSubTitleA">에너지원</span>
              <DropDownList
                data={ensoList}
                defaultValue={{ codeVal: "태양광", codeNo: 15001 }}
                name="ensoTypeCodeNo"
                textField="codeVal"
                dataItemKey="codeNo"
                onChange={setSearchObjEvt}
                className="searchArea"
              ></DropDownList>
              <span className="searchSubTitleB">지역검색</span>
              <RgnDropdown
                name="rgnCode"
                className="searchArea"
                rgnList={rgnList}
                search={search}
                objKey={"rgn"}
                setSearch={setSearch}
                onChange={setSearchObjEvt}
              />
              <span className="cmpAreaSrchRight">
                <span className="searchSubTitleB">조회 기간</span>
                <DropDownList
                  data={dateList}
                  defaultValue={{ codeNo: 23006, codeVal: "직접조회" }}
                  name="date"
                  textField="codeVal"
                  dataItemKey="codeNo"
                  onChange={setSearchObjEvt}
                  className="searchArea"
                  value={dateVal}
                ></DropDownList>
                <DatePickerKo
                  id="start"
                  name="start"
                  className="datePicker"
                  defaultValue={yesterday}
                  format="yyyy-MM-dd "
                  setDtm={(v) => {
                    search.start = v;
                    setDateVal({ codeNo: 23006, codeVal: "직접조회" });
                    setEndMin(v);
                  }}
                  max={startMax}
                  value={search.start}
                />
                <span className="textColorGr">&nbsp;~&nbsp;</span>
                <DatePickerKo
                  id="end"
                  name="end"
                  className="datePicker"
                  defaultValue={yesterday}
                  format="yyyy-MM-dd"
                  setDtm={(v) => {
                    search.end = v;
                    setDateVal({ codeNo: 23006, codeVal: "직접조회" });
                    setStartMax(v);
                  }}
                  min={endMin}
                  max={yesterday}
                />
                <span className="searchSubTitleC">
                  <Button className="inqBtn" onClick={setSearchClickEvt} icon="search"></Button>
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="uk-grid">
        <div className="uk-width-medium-1-2">
          <div className="md-card">
            <AreaRank dataSource={dataSource} search={search} setMakrDataSource={setMakrDataSource} />
          </div>
        </div>
        <div className="uk-width-medium-1-2">
          <div className="md-card">
            <AreaMaker dataSource={dataSource} search={search} setModlDataSource={setModlDataSource} setMakrDataSource={setMakrDataSource} />
          </div>
        </div>
      </div>
      {loading && (
        <p
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
        >
          <BeatLoader loading={true} size={24} color="#1e88e5" />
        </p>
      )}
    </div>
  );
});

export default CmpArea;
