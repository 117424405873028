import GathStatusDay from './gathStatusDay';
import GathStatusMonth from './gathStatusMonth';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { useState , memo } from 'react';
import './gathStatus.css';
import { getUser } from '../../../service/authService';

const GathStatus = memo(({ conn , codeList}) => {

  // 데이터 설정
  const [ ensoTypeCodeNo , setEnsoTypeCodeNo ] = useState("");
  const ensoList = codeList.filter( c => c.grpCodeNo === 15 );
  // 드롭다운 체인지 이벤트
  const onchange = (e) =>{
    setEnsoTypeCodeNo( e.target.value.codeNo );
  }
  return(
    <div>
      <div className="uk-grid">
        <div className="uk-width-medium-1-1">
            <div className="md-card TBgA">
                <div className="md-card-content small-padding">
                  <span className="searchSubTitleA">에너지원</span>     
                  <DropDownList
                    data = {ensoList} defaultItem = { {codeVal : "전체" , codeNo : ""} } name = "ensoTypeDropDown"
                    textField = "codeVal" dataItemKey="codeNo" onChange = {onchange} className = "searchArea"
                    />          
                </div>
            </div>
        </div>
      </div>
      <div className="uk-grid">
        <GathStatusDay conn = {conn}  ensoTypeCodeNo = {ensoTypeCodeNo} />
        <GathStatusMonth conn = {conn}  ensoTypeCodeNo = {ensoTypeCodeNo} />
      </div>
    </div>
  );
});

export default GathStatus;