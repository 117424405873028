import "./uikit.almost-flat.css";
import "./kendo.css";
import "./app.css";

import React from "react";
import { useState, useEffect } from "react";
import { authGuest, setToken, isAuthenticated, getToken, setDelToken } from "./service/authService";

import axios from "axios";
import { getCodes } from "./service/codeService";
import { getRgns, getDongs } from "./service/areaService";
import { BrowserRouter, Route, useHistory, useLocation } from "react-router-dom";
import Contents from "./contents";
import { getMessage } from "./com/messageUtil";
import kendo from "@progress/kendo-ui";
import Error from "./components/error";
import { getMenuTree, getMenuRole } from "./service/menu/menuService";
import { getUserTypeCode } from "./service/authService";
import Login from "./components/login/login";

function App() {
  if (navigator.userAgent.indexOf("Mobile") === -1) {
    localStorage.setItem("isMobile", "N");
  } else {
    localStorage.setItem("isMobile", "Y");
  }
  const conn = axios.create({
    baseURL: `${process.env.REACT_APP_REST_API_VERSION}`,
    config: {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    },
  });

  conn.interceptors.request.use(
    (config) => {
      const token = getToken();
      if (token) config.headers.common["X-AUTH-TOKEN"] = token;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  conn.interceptors.response.use(
    (response) => {
      // console.log("response url ", response.config.url, response);
      const result = response.data;
      if (result.code == "T000" || result.code == "T001") {
        console.log("ERROR", result);
        authGuest(conn).then((result) => {
          setToken(result.token);
          // if(kendo.confirm("데이터를 조회하지 못하였습니다. 새로고침 하시겠습니까?"))
          window.location.href = "/";
        });
      } else if (result.code == "T002") {
        window.location.href = "/";
      }

      if (result.status != 200) {
        throw { errCode: result.code };
      }

      return result.data;
    },
    async function (error) {
      console.log("response error", error);
      setDelToken();
      setIsError(true);
      return Promise.reject(error);
    },
  );

  const [state, setState] = useState({
    codeList: [],
    rgnList: [],
    dongList: [],
  });

  const setDefaultData = () => {
    let errList = [];
    Promise.all([getCodes(conn), getRgns(conn), getDongs(conn)]) //
      .then((results) => {
        if (errList.length > 0) {
          const set = new Set(errList);
          const uniqueErrList = [...set];
        }
        setState((state) => {
          const item = { ...state };
          item.codeList = results[0];
          item.rgnList = results[1];
          item.dongList = results[2];
          return item;
        });
      })
      .catch((err) => console.log("initial data load Fail", err));
  };

  const [loginStatus, setLoginStatus] = useState(false);
  const setUpdateLoginStatus = (status) => {
    setLoginStatus(status);
  };

  useEffect(() => {
    if (isAuthenticated()) {
      // 로그인 여부 확인
      setDefaultData();
    } else {
      authGuest(conn) // 게스트 로그인 처리
        .then((result) => {
          setToken(result.token);
          setDefaultData();
        })
        .catch((err) => {
          console.log("guest login access Fail ", err);
        });
    }
  }, []);

  const { codeList, rgnList, dongList } = state;
  const [isError, setIsError] = useState(false);
  const setError = (is) => setIsError(is);

  const [roleMenu, setRoleMenu] = useState([]);

  const [menuState, setMenuState] = useState({
    depth1MenuList: [],
    depth2MenuList: [],
    depth3MenuList: [],
    depth1SelectedMenu: {},
    depth2SelectedMenu: {},
    depth3SelectedMenu: {},
  });

  const setSelectMenu = (selectMenuUrl, roleMenu) => {
    setMenuState((state) => {
      const item = { ...state };
      if (selectMenuUrl == "") {
        return {
          depth1MenuList: [],
          depth2MenuList: [],
          depth3MenuList: [],
          depth1SelectedMenu: {},
          depth2SelectedMenu: {},
          depth3SelectedMenu: {},
        };
      }
      roleMenu.forEach((md1) => {
        if (selectMenuUrl == md1.menuUrl) {
          item.depth3MenuList = [];
          item.depth2MenuList = [];
          item.depth1MenuList = roleMenu.filter((v) => v.checked);
          item.depth1SelectedMenu = md1;
          item.depth2SelectedMenu = {};
          item.depth3SelectedMenu = {};
          // return false;
        }
        md1.items.forEach((md2) => {
          if (selectMenuUrl == md2.menuUrl) {
            item.depth3MenuList = [];
            item.depth2MenuList = md1.items.filter((v) => v.checked);
            item.depth1MenuList = roleMenu.filter((v) => v.checked);
            item.depth1SelectedMenu = md1;
            item.depth2SelectedMenu = md2;
            item.depth3SelectedMenu = {};
            // return false;
          }
          md2.items.forEach((md3) => {
            if (selectMenuUrl == md3.menuUrl) {
              item.depth3MenuList = md2.items.filter((v) => v.checked);
              item.depth2MenuList = md1.items.filter((v) => v.checked);
              item.depth1MenuList = roleMenu.filter((v) => v.checked);
              item.depth1SelectedMenu = md1;
              item.depth2SelectedMenu = md2;
              item.depth3SelectedMenu = md3;
              // return false;
            }
          });
        });
      });
      return item;
    });
  };

  const getRoleMenu = (changeMenuUrl) => {
    // console.log('changeMenuUrl', changeMenuUrl);
    getMenuTree(conn).then((menu) => {
      getMenuRole(conn, menu, getUserTypeCode()).then((roleMenu) => {
        setRoleMenu(roleMenu[0].items);
        let menuUrl = "";
        roleMenu[0].items.forEach((md1) => {
          if (changeMenuUrl == md1.menuUrl) {
            menuUrl = md1.menuUrl;
          }
          md1.items.forEach((md2) => {
            if (changeMenuUrl == md2.menuUrl) {
              menuUrl = md2.menuUrl;
              return false;
            }
            md2.items.forEach((md3) => {
              if (changeMenuUrl == md3.menuUrl) {
                menuUrl = md3.menuUrl;
                return false;
              }
            });
          });
          setSelectMenu(menuUrl, roleMenu[0].items);
        });
      });
    });
  };
  return isError ? (
    <Error />
  ) : (
    <BrowserRouter>
      {codeList.length > 0 && rgnList.length > 0 && dongList.length > 0 ? (
        <Contents
          conn={conn}
          codeList={codeList}
          rgnList={rgnList}
          dongList={dongList}
          loginStatus={loginStatus}
          setUpdateLoginStatus={setUpdateLoginStatus}
          setError={setError}
          getRoleMenu={getRoleMenu}
          roleMenu={roleMenu}
          menuState={menuState}
          setSelectMenu={setSelectMenu}
        />
      ) : (
        <div>로딩중...</div>
      )}
      <Route
        exact
        path="/login"
        render={() => <Login getRoleMenu={getRoleMenu} conn={conn} setUpdateLoginStatus={setUpdateLoginStatus} loginStatus={loginStatus} />}
      />
    </BrowserRouter>
  );
}

export default App;
