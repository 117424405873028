import React, { useState, useEffect, useRef, memo } from "react";
import NonOperHistSearch from "./nonOperHistSearch";
import { getMeainModlMakr } from "./../../../service/meain/meain/meainService";
import kendo from "@progress/kendo-ui";
import { getNonOperHistList, getInvNonOperHistList, getRgnNonOperHistList } from "./../../../service/stats/nonOperHist/nonOperHist";
import { BeatLoader } from "react-spinners";
import { getUserMngRgnList, isManager } from "./../../../service/authService";

const NonOperHist = memo(({ conn, codeList, rgnList }) => {
  // 에너지원 데이터 리스트
  const ensoList = codeList.filter((c) => c.grpCodeNo === 15);
  const defaultValRgn = { rgnTermSimple: "전체", rgnCode: "" };
  // 통신모델 데이터 리스트
  const communicationMthdList = [
    {
      communicationMthd: "TCP/IP(OPC-UA)",
      cm: 1,
    },
    {
      communicationMthd: "LTE",
      cm: 2,
    },
    {
      communicationMthd: "WI-FI",
      cm: 3,
    },
    {
      communicationMthd: "LoRa(SKT)",
      cm: 4,
    },
    {
      communicationMthd: "NBIoT(KT)",
      cm: 5,
    },
    {
      communicationMthd: "NBIoT(LG)",
      cm: 6,
    },
    {
      communicationMthd: "HTTPS",
      cm: 7,
    },
  ];
  const defaultCommunicationModel = { communicationMthd: "전체", cm: "" };

  const [loading, setLoading] = useState(false);
  // 읍면동 / 설비모델 optionLabel
  const [optionLabel, setOptionLabel] = useState({
    meainModl: { meainTypeTerm: "설비 모델", meainTypeSeriNo: "" },
  });
  const [search, setSearch] = useState({
    date: "",
    ensoTypeCode: "", // 에너지원 코드
    rgnCode: "", // 지역 코드
    cid: "", // CID
    installerTerm: "", // 설비명
    defaultEnsoCode: { codeVal: "전체", codeNo: "" },
    communicationMthd: "", // 통신 방식
    makrTerm: "", // 제조사
    makrSeriNo: "", // 제조사 번호
    meainTypeTerm: "", // 모델
    meainTypeSeriNo: "", // 모델 번호
  });
  const [makrList, setMakrList] = useState([]);

  const searchData = (e) => {
    if (e.target.name == "ensoTypeCodeNo") {
      setSearch((state) => {
        const item = { ...state };
        item.ensoTypeCode = e.target.value.codeNo;
        item.defaultEnsoCode = e.target.value;
        return item;
      });
      setMakrListFunc(e.target.value.codeNo);
    } else if (e.target.name == "rgn") {
      setSearch((state) => {
        const item = { ...state };
        item.rgnCode = e.target.value.rgnCode;
        return item;
      });
    } else if (e.target.name == "communicationMthd") {
      if (e.target.value.communicationMthd === "전체") {
        setSearch((state) => {
          const item = { ...state };
          item.communicationMthd = "";
          return item;
        });
      } else {
        setSearch((state) => {
          const item = { ...state };
          item.communicationMthd = e.target.value.communicationMthd;
          return item;
        });
      }
    } else if (e.target.name === "makrSeriNo") {
      search.meainTypeSeriNo = "";
      optionLabel.meainModl = { meainTypeTerm: "설비 모델", meainTypeSeriNo: "" };
      setSearch((search) => {
        const item = { ...search };
        item.makrSeriNo = e.target.value.makrSeriNo;
        item.makrTerm = e.target.value.makrTerm;
        return item;
      });
    } else if (e.target.name === "meainTypeSeriNo") {
      search.meainTypeSeriNo = e.target.value.meainTypeSeriNo;
      setOptionLabel((optionLabel) => {
        const item = { ...optionLabel };
        item.meainModl = e.target.value;
        return item;
      });
    }
  };

  // CID 검색
  const cidChange = (e) => {
    setSearch((state) => {
      const item = { ...state };
      item.cid = e.value;
      return item;
    });
  };
  const cidKeyPress = (e) => {
    if (e.key === "Enter") {
      setSearchClickEvt();
    }
  };

  // 설비명 검색
  const installerChange = (e) => {
    setSearch((state) => {
      const item = { ...state };
      item.installerTerm = e.value;
      return item;
    });
  };

  const installerKeyPress = (e) => {
    if (e.key === "Enter") {
      setSearchClickEvt();
    }
  };

  // 페이징
  const [paging, setPaging] = useState({ take: 10, skip: 0 });
  // 정렬
  const [sort, setSort] = useState([{ field: "serverDiff", dir: "desc" }]);
  // 페이징 이벤트
  const onPageChange = (e) => {
    paging.skip = e.page.skip;
    paging.take = e.page.take;
    setNonOperHistList();
  };
  // 정렬 이벤트
  const onSortChange = (e) => {
    const { sort } = e;
    setSort(sort);
  };

  // 오늘날
  const today = new Date();
  // 설정한 날짜
  const day = useRef(new Date());

  const [dataSource, setDataSource] = useState({
    nonOperHistData: [],
    total: 0,
    rgnNonOperHistData: [],
    rgnTotal: 0,
    invNonOperHistData: [],
    invTotal: 0,
  });

  // 데이터 소스 설정 rejection 함수 (현재 불필요)
  const getRejection = (error) => {};
  // 설비 제조사 & 설비 모델
  const [makrMeainModelList, setMakrMeainModelList] = useState({
    makrList: [],
    meainModelList: [],
  });
  const getMeainModlMakrList = () => {
    getMeainModlMakr(conn, getMeainModleMakrResult, getRejection);
  };
  const getMeainModleMakrResult = (result) => {
    setMakrMeainModelList((makrMeainModelList) => {
      const item = { ...makrMeainModelList };
      item.makrList = result.makrDtoList;
      item.meainModelList = result.meainModelDtoList;
      return item;
    });
    setMakrList(result.makrDtoList);
  };

  const setSearchClickEvt = (e) => {
    setNonOperHistList();
    setRgnNonOperHistList();
    setInvNonOperHistList();
  };

  const setNonOperHistList = () => {
    const data = {
      date: kendo.toString(day.current, "yyyyMMdd"),
      ensoTypeCode: search.ensoTypeCode,
      rgnCode: search.rgnCode,
      cid: search.cid,
      installerTerm: search.installerTerm,
      communicationMthd: search.communicationMthd,
      makrTerm: search.makrTerm,
      makrSeriNo: search.makrSeriNo,
      meainTypeTerm: search.meainTypeTerm,
      meainTypeSeriNo: search.meainTypeSeriNo,
    };

    setSearch((state) => {
      const item = { ...state };
      item.date = data.date;
      return item;
    });
    setLoading(true);
    getNonOperHistList(conn, paging, data)
      .then((result) => {
        setLoading(false);
        setDataSource((dataSource) => {
          const item = { ...dataSource };
          item.nonOperHistData = result.results;
          item.total = result.total;
          return item;
        });
      })
      .catch((error) => {
        console.error("getNonOperHistList error = ", error);
        // alert(getMessage(error.errCode));
      });
  };

  const setRgnNonOperHistList = () => {
    const data = {
      date: kendo.toString(day.current, "yyyyMMdd"),
      ensoTypeCode: search.ensoTypeCode,
      rgnCode: search.rgnCode,
      cid: search.cid,
      installerTerm: search.installerTerm,
      communicationMthd: search.communicationMthd,
      makrTerm: search.makrTerm,
      makrSeriNo: search.makrSeriNo,
      meainTypeTerm: search.meainTypeTerm,
      meainTypeSeriNo: search.meainTypeSeriNo,
    };

    setSearch((state) => {
      const item = { ...state };
      item.date = data.date;
      return item;
    });
    getRgnNonOperHistList(conn, data)
      .then((result) => {
        setDataSource((dataSource) => {
          const item = { ...dataSource };
          item.rgnNonOperHistData = result;
          return item;
        });
      })
      .catch((error) => {
        console.error("getRgnNonOperHistList error = ", error);
        // alert(getMessage(error.errCode));
      });
  };

  const setInvNonOperHistList = () => {
    const data = {
      date: kendo.toString(day.current, "yyyyMMdd"),
      ensoTypeCode: search.ensoTypeCode,
      rgnCode: search.rgnCode,
      cid: search.cid,
      installerTerm: search.installerTerm,
      communicationMthd: search.communicationMthd,
      makrTerm: search.makrTerm,
      makrSeriNo: search.makrSeriNo,
      meainTypeTerm: search.meainTypeTerm,
      meainTypeSeriNo: search.meainTypeSeriNo,
    };

    setSearch((state) => {
      const item = { ...state };
      item.date = data.date;
      return item;
    });

    getInvNonOperHistList(conn, data)
      .then((result) => {
        setDataSource((dataSource) => {
          const item = { ...dataSource };
          item.invNonOperHistData = result;
          return item;
        });
      })
      .catch((error) => {
        console.error("getInvNonOperHistList error = ", error);
        // alert(getMessage(error.errCode));
      });
  };

  const setMakrListFunc = (ensoTypeCode) => {
    // 제조사 데이터 리스트
    let result = makrMeainModelList.makrList;
    // 에너지원별 설비타입기준 제조사 코드
    if (ensoTypeCode === 15001) {
      result = makrMeainModelList.makrList.filter((e) => e.ensoTypeCodeNo === ensoTypeCode && e.makrClsfNo === 30002);
    } else if (ensoTypeCode === 15002) {
      result = makrMeainModelList.makrList.filter((e) => e.ensoTypeCodeNo === ensoTypeCode && (e.makrClsfNo === 30003 || e.makrClsfNo === 30013));
    } else if (ensoTypeCode === 15003) {
      result = makrMeainModelList.makrList.filter((e) => e.ensoTypeCodeNo === ensoTypeCode && e.makrClsfNo === 30005);
    } else if (ensoTypeCode === 15004) {
      result = makrMeainModelList.makrList.filter((e) => e.ensoTypeCodeNo === ensoTypeCode && e.makrClsfNo === 30007);
    } else if (ensoTypeCode === 15006) {
      result = makrMeainModelList.makrList.filter((e) => e.ensoTypeCodeNo === ensoTypeCode && e.makrClsfNo === 30010);
    } else {
      result = makrMeainModelList.makrList;
    }
    setMakrList(result);
  };

  useEffect(() => {
    setNonOperHistList();
    setRgnNonOperHistList();
    setInvNonOperHistList();
    getMeainModlMakrList();
  }, []);

  // 지역 리스트
  let rgnListfilter = [];
  const userMngRgnList = getUserMngRgnList();
  if (isManager() === true) {
    rgnList.forEach((element) => {
      if (userMngRgnList.length != 0) {
        userMngRgnList.forEach((rgnCode) => {
          if (rgnCode === element.rgnCode) {
            rgnListfilter.push(element);
          }
        });
      } else {
        rgnListfilter.push(element);
      }
    });
  } else {
    rgnListfilter = rgnList;
  }
  const rgns = rgnListfilter;

  return (
    <>
      <div className="uk-grid">
        <div
          className="uk-width-medium-1-1"
          style={
            loading
              ? {
                  opacity: 0.6,
                  pointerEvents: "none",
                }
              : null
          }
        >
          <NonOperHistSearch
            conn={conn}
            codeList={codeList}
            rgnList={rgnList}
            rgns={rgns}
            ensoList={ensoList}
            searchData={searchData}
            search={search}
            defaultValRgn={defaultValRgn}
            cidChange={cidChange}
            cidKeyPress={cidKeyPress}
            installerChange={installerChange}
            installerKeyPress={installerKeyPress}
            defaultCommunicationModel={defaultCommunicationModel}
            today={today}
            day={day}
            setSearch={setSearch}
            makrList={makrList}
            optionLabel={optionLabel}
            makrMeainModelList={makrMeainModelList}
            dataSource={dataSource}
            setDataSource={setDataSource}
            paging={paging}
            sort={sort}
            onPageChange={onPageChange}
            onSortChange={onSortChange}
            setSearchClickEvt={setSearchClickEvt}
            communicationMthdList={communicationMthdList}
          />
        </div>
      </div>
      {loading && (
        <p
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
        >
          <BeatLoader loading={true} size={24} color="#1e88e5" />
        </p>
      )}
    </>
  );
});
export default NonOperHist;
