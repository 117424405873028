import { React, useState, useEffect, useCallback, useRef } from "react";
import { Reply, Menu, ArrowDropDown, Notifications } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router";
import { authGuest, setDelToken, setToken, isLogin, getUser } from "../service/authService";
import HeaderFullScr from "./headerFullSc";
import HeaderTimer from "./headerTimer";
import { DropDownList as DropDown } from "@progress/kendo-react-dropdowns";

import "./header.css";
import { getWeathInfo } from "../service/analy/weathCmpService";
import { Sidebar } from "./sidebar";

function Header({ conn, setUpdateLoginStatus, roleMenu, getRoleMenu, menuState, setSelectMenu, loginStatus }) {
  const isMobile = localStorage.getItem("isMobile");
  const childRef = useRef();
  const halfInnerWidth = window.innerWidth / 2;
  const history = useHistory();
  const location = useLocation();
  // 로그인
  const loginClickEvt = () => {
    history.push("/login");
    setSelectMenu("", roleMenu);
  };

  const [weathInfo, setWeathInfo] = useState({
    presWeathVal: "",
    rgnCode: "",
    temp: "",
  });

  useEffect(() => {
    if (loginStatus) {
      getWeathInfo(conn)
        .then((response) => {
          if (response) setWeathInfo(response);
        })
        .catch((error) => console.log(error, "getWeathInfo Error!"));
    } else {
      setWeathInfo({
        presWeathVal: "",
        rgnCode: "",
        temp: "",
      });
    }
  }, [loginStatus]);

  // 로그아웃
  const logoutClickEvt = () => {
    const user = getUser();
    setDelToken();
    authGuest(conn, "LOGOUT", user).then((result) => {
      alert("로그아웃 되었습니다.");
      setToken(result.token);
      setUpdateLoginStatus(false);
      history.push("/");
    });
  };

  const goBack = () => {
    history.goBack();
  };

  const depthOneGo = useCallback((e) => {
    if (e.target.value.checked) {
      if (e.target.value.items.length) {
        const depthTwoTemp = e.target.value.items.filter((item) => item.checked);
        if (depthTwoTemp[0].items.length) {
          const depthThreeTemp = depthTwoTemp[0].items.filter((item) => item.checked);
          setSelectMenu(depthThreeTemp[0].menuUrl, roleMenu);
          getRoleMenu(depthThreeTemp[0].menuUrl);
          history.push(depthThreeTemp[0].menuUrl);
        } else {
          setSelectMenu(depthTwoTemp[0].menuUrl, roleMenu);
          getRoleMenu(depthTwoTemp[0].menuUrl);
          history.push(depthTwoTemp[0].menuUrl);
        }
      } else {
        setSelectMenu(e.target.value.menuUrl, roleMenu);
        getRoleMenu(e.target.value.menuUrl);
        history.push(e.target.value.menuUrl);
      }
    } else {
      alert("로그인 후 이용가능합니다...");
      return false;
    }
  });
  const depthTwoGo = useCallback((e) => {
    if (e.target.value.checked) {
      if (e.target.value.items.length) {
        const depthThreeTemp = e.target.value.items.filter((item) => item.checked);
        setSelectMenu(depthThreeTemp[0].menuUrl, roleMenu);
        getRoleMenu(depthThreeTemp[0].menuUrl);
        history.push(depthThreeTemp[0].menuUrl);
      } else {
        setSelectMenu(e.target.value.menuUrl, roleMenu);
        getRoleMenu(e.target.value.menuUrl);
        history.push(e.target.value.menuUrl);
      }
    } else {
      alert("로그인 후 이용가능합니다...");
      return false;
    }
  });

  const depthThreeGo = useCallback((e) => {
    if (e.target.value.checked) {
      setSelectMenu(e.target.value.menuUrl, roleMenu);
      getRoleMenu(e.target.value.menuUrl);
      history.push(e.target.value.menuUrl);
    } else {
      alert("로그인 후 이용가능합니다...");
      return false;
    }
  });

  const callChildFunc = useCallback(() => {
    childRef.current.menuClickEvt();
  });

  const mainTitleClickEvt = () => {
    history.push("/");
  };
  return (
    <>
      {isMobile === "Y" ? (
        <header className="App-header" id="header_main">
          <table className="mobileMainTitle">
            <tbody>
              <tr>
                <th>
                  <button className="headerBtn">
                    <Menu className="material-icons" style={{ fontSize: 30, color: "white" }} onClick={callChildFunc} />
                  </button>
                </th>
                <td onClick={() => history.push("/")}>
                  <span className="mobileTitleSpan">
                    <small>충청남도 신재생에너지 스마트 통합플랫폼 (충남 REMS)</small>
                  </span>
                </td>
                {isLogin() ? (
                  <th onClick={logoutClickEvt}>
                    <button className="headerBtn">
                      <span className="headimgB">
                        <img src="/images/icon_logout.png" alt="로그아웃" />
                      </span>
                    </button>
                  </th>
                ) : (
                  <th onClick={loginClickEvt}>
                    <button className="headerBtn">
                      <span className="headimgB">
                        <img src="/images/icon_login.png" alt="로그인" />
                      </span>
                    </button>
                  </th>
                )}
              </tr>
            </tbody>
          </table>
          <Sidebar width={halfInnerWidth} height={"100vh"} ref={childRef} roleMenu={roleMenu} />
        </header>
      ) : (
        <header className="App-header" id="header_main">
          <div className="header_main_content">
            <nav className="uk-navbar">
              <div className="uk-float-left header-left">
                <button className="headerGobackBtn" onClick={goBack}>
                  <Reply className="material-icons" style={{ fontSize: 30, color: "white" }} />
                </button>
                {menuState.depth1MenuList.length > 0 && (
                  <label for="depth1">
                    <DropDown
                      className="menuDropDown"
                      data={menuState.depth1MenuList}
                      textField="term"
                      dataItemKey="menuUrl"
                      // defaultItem={menuState.depth1SelectedMenu}
                      defaultValue={menuState.depth1SelectedMenu}
                      onChange={depthOneGo}
                    />
                  </label>
                )}
                {menuState.depth2MenuList.length > 0 && (
                  <label for="depth2">
                    <DropDown
                      className="menuDropDown"
                      id="asdasd"
                      data={menuState.depth2MenuList}
                      textField="term"
                      dataItemKey="menuUrl"
                      defaultValue={menuState.depth2SelectedMenu}
                      onChange={depthTwoGo}
                    />
                  </label>
                )}
                {menuState.depth3MenuList.length > 0 && (
                  <label for="depth3">
                    <DropDown
                      className="menuDropDown"
                      id="asdasd"
                      data={menuState.depth3MenuList}
                      textField="term"
                      dataItemKey="menuUrl"
                      defaultValue={menuState.depth3SelectedMenu}
                      onChange={depthThreeGo}
                    />
                  </label>
                )}
              </div>
              <div style={{ height: 70, cursor: "pointer" }} className="uk-navbar-center header-center">
                <h1 className="mainTitle" onClick={mainTitleClickEvt}>
                  <img src="/images/ch_logo_small.png" alt="로고" style={{ height: "55px", margin: "0px 15px 10px 0px" }} />
                  충청남도 신재생에너지 스마트 통합플랫폼 (충남 REMS)&nbsp;
                  <img src="/images/ctp_logo2.png" alt="로고" style={{ height: "50px", margin: "0px 15px 10px 0px" }} />
                </h1>
              </div>
              <div className="uk-navbar-flip">
                <ul className="uk-navbar-nav user_actions">
                  <li className="user_action_icon ">
                    <a
                      style={{
                        pointerEvents: "none",
                      }}
                    >
                      <HeaderTimer />
                      {/* {weathInfo.rgnCode ? (
                        <span className="headata">
                          <span className="headRgnData" style={{ color: 'gold', fontWeight: 'bold' }}>
                            &nbsp;| {weathInfo.rgnCode} |
                          </span>{' '}
                          <span className="headTempData">
                            {weathInfo.temp}℃ &nbsp; {weathInfo.presWeathVal}
                          </span>
                        </span>
                      ) : null} */}
                      {/* <a href="#" > */}
                      {/* <HeaderTimer /> */}
                      {/* <span className="headata">&nbsp;&nbsp;&#124;&nbsp;&nbsp;</span>
                                  <span className="headimg"><img src="/images/weather/weather_icon01.png" alt="구름"/></span>
                                  <span className="headata">23&#730;C</span>
                                  <span className="headata">&nbsp;&nbsp;&#124;</span> */}
                      {/* </a> */}
                    </a>
                  </li>
                  {/* <li><a href="#"  className="user_action_icon"><Notifications className="material-icons md-24 md-light"/><span className="uk-badge">5</span></a></li> */}
                  {isLogin() ? (
                    <li>
                      <a href="#" onClick={logoutClickEvt} className="user_action_icon">
                        <span className="headimgB">
                          <img src="/images/icon_logout2.png" alt="로그아웃" />

                          {/*&nbsp; <img src="/images/icon_logout.png" alt="로그아웃" />
                            &nbsp;<text style={{ color: 'white', fontSize:'110%' }}>로그아웃</text> */}
                        </span>
                      </a>
                    </li>
                  ) : (
                    <li>
                      <a href="#" onClick={loginClickEvt} className="user_action_icon">
                        <span className="headimgB">
                          <img src="/images/icon_login2.png" alt="로그인" />
                          {/* &nbsp;
                          <text style={{ color: 'white', fontSize:'110%' }}>로그인</text> */}
                        </span>
                      </a>
                    </li>
                  )}
                  <li>
                    {" "}
                    <HeaderFullScr />{" "}
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </header>
      )}
    </>
  );
}
export default Header;
