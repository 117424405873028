// 설비 정보 ESS
import React, { useState, useEffect } from "react";
import kendo from "@progress/kendo-ui";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import styles from "./meain.module.css";
import DateTimePickerKo from "../../com/dateInputs/dateTimePickerKo";
import DatePickerKo from "../../com/dateInputs/datePickerKo";
import { Input } from "@progress/kendo-react-inputs";

const Meain_info_ess = ({ selectMeain, setMeainInfo, codeList, meainModlList, inputValidMsg, itemRender }) => {
  const [batteryMakr, setBatteryMakr] = useState(
    selectMeain.batteryMakrNo ? { makrSeriNo: selectMeain.batteryMakrNo, makrTerm: "" } : { makrSeriNo: "", makrTerm: "" },
  );
  const [batteryType, setBatteryType] = useState(
    selectMeain.meainTypeSeriNo ? { meainTypeSeriNo: selectMeain.meainTypeSeriNo, meainTypeTerm: "" } : { meainTypeSeriNo: "", meainTypeTerm: "" },
  );
  const [invtMakr, setInvtMakr] = useState(selectMeain.invtMakrNo ? { makrSeriNo: selectMeain.invtMakrNo, makrTerm: "" } : { makrSeriNo: "", makrTerm: "" });
  const [invtType, setInvtType] = useState(
    selectMeain.invtTypeNo ? { meainTypeSeriNo: selectMeain.invtTypeNo, meainTypeTerm: "" } : { meainTypeSeriNo: "", meainTypeTerm: "" },
  );
  const [bldSrvCodeNo, setBldSrvCodeNo] = useState(selectMeain.bldSrvCodeNo ? { codeNo: selectMeain.bldSrvCodeNo, codeVal: "" } : { codeNo: "", codeVal: "" });
  const bldSrvDs = codeList.filter((c) => c.grpCodeNo === 31);

  // 배터리 제조사 목록
  const batteryMakrDs = meainModlList.length !== 0 ? meainModlList.makrDtoList.filter((c) => c.makrClsfNo == 30012) : [];
  // 배터리 타입 목록
  const batteryTypeDs =
    meainModlList.length !== 0 ? meainModlList.meainModelDtoList.filter((c) => c.ensoTypeCodeNo === 15007 && c.makrSeriNo === selectMeain.batteryMakrNo) : [];
  // 인버터 제조사 목록
  const invtMakrDs = meainModlList.length !== 0 ? meainModlList.makrDtoList.filter((c) => c.makrClsfNo == 30011) : [];
  // 인버터 타입 목록
  const invtTypeDs =
    meainModlList.length !== 0 ? meainModlList.meainModelDtoList.filter((c) => c.ensoTypeCodeNo === 15007 && c.makrSeriNo === selectMeain.invtMakrNo) : [];

  // 드롭다운 값 변경 이벤트 (Single 데이터 버전)
  const onchageDropdown = (e) => {
    // 값변경한 부분에 대해서 추적하여 객체 이름 기입하기 위해 name 값 가져오기
    const objName = e.target.name;
    const meainInfo = {
      ...selectMeain,
      [objName]: e.value,
    };
    setMeainInfo(meainInfo);
  };

  // Input 데이터 값 변경 이벤트
  const onchageInput = (e) => {
    let meainInfo = {};
    const objName = e.target.name;
    // 모듈 총용량은 태양광 설비 용량 과 같다.
    if (e.target.name === "batteryCapa") {
      meainInfo = {
        ...selectMeain,
        [objName]: e.target.value,
        instCapa: e.target.value,
      };
    } else {
      // 값변경한 부분에 대해서 추적하여 객체 이름 기입하기 위해 name 값 가져오기
      meainInfo = {
        ...selectMeain,
        [objName]: e.target.value,
      };
    }
    setMeainInfo(meainInfo);
  };

  // Date 데이터 값 변경 이벤트
  const onchageDate = (v, name) => {
    // 값변경한 부분에 대해서 추적하여 객체 이름 기입하기 위해 name 값 가져오기
    const meainInfo = {
      ...selectMeain,
      [name]: kendo.toString(v, "yyyy-MM-dd"),
    };
    setMeainInfo(meainInfo);
  };

  // DateTimeOnChangeDate
  const onChangeDateTiem = (v, name) => {
    const meainInfo = {
      ...selectMeain,
      [name]: v,
      // ,  [name] : kendo.toString(v , 'yyyy-MM-dd HH:mm:ss')
    };
    setMeainInfo(meainInfo);
  };

  // 날짜 포맷 지정
  const dateFormat = (date) => {
    if (typeof date !== "undefined") {
      if (date !== "") {
        date = new Date(date);
      } else {
        date = "";
      }
    } else {
      date = "";
    }
    return date;
  };

  // 제조사 Converter
  const makrConverter = () => {
    if (Object.keys(selectMeain).length !== 0) {
      if (selectMeain.batteryMakrNo) {
        let makrSeriNo = "";
        let makrTerm = "";
        batteryMakrDs.forEach((element) => {
          if (element.makrSeriNo == selectMeain.batteryMakrNo) {
            makrSeriNo = element.makrSeriNo;
            makrTerm = element.makrTerm;
          }
        });
        setBatteryMakr({ makrSeriNo: makrSeriNo, makrTerm: makrTerm });
      }

      if (selectMeain.invtMakrNo) {
        let makrSeriNo = "";
        let makrTerm = "";
        invtMakrDs.forEach((element) => {
          if (element.makrSeriNo == selectMeain.invtMakrNo) {
            makrSeriNo = element.makrSeriNo;
            makrTerm = element.makrTerm;
          }
        });
        setInvtMakr({ makrSeriNo: makrSeriNo, makrTerm: makrTerm });
      }
    } else {
      setBatteryMakr({ makrSeriNo: "", makrTerm: "" });
      setInvtMakr({ makrSeriNo: "", makrTerm: "" });
    }
  };

  // 모델 Converter
  const modlConverter = () => {
    if (Object.keys(selectMeain).length !== 0) {
      if (selectMeain.meainTypeSeriNo) {
        let meainTypeSeriNo = "";
        let meainTypeTerm = "";
        batteryTypeDs.forEach((element) => {
          if (element.meainTypeSeriNo == selectMeain.meainTypeSeriNo) {
            meainTypeSeriNo = element.meainTypeSeriNo;
            meainTypeTerm = element.meainTypeTerm;
          }
        });
        setBatteryType({ meainTypeSeriNo: meainTypeSeriNo, meainTypeTerm: meainTypeTerm });
      } else {
        setBatteryType({ meainTypeSeriNo: "", meainTypeTerm: "" });
      }

      if (selectMeain.invtTypeNo) {
        let meainTypeSeriNo = "";
        let meainTypeTerm = "";
        invtTypeDs.forEach((element) => {
          if (element.meainTypeSeriNo == selectMeain.invtTypeNo) {
            meainTypeSeriNo = element.meainTypeSeriNo;
            meainTypeTerm = element.meainTypeTerm;
          }
        });
        setInvtType({ meainTypeSeriNo: meainTypeSeriNo, meainTypeTerm: meainTypeTerm });
      } else {
        setInvtType({ meainTypeSeriNo: "", meainTypeTerm: "" });
      }
    } else {
      setBatteryType({ meainTypeSeriNo: "", meainTypeTerm: "" });
      setInvtType({ meainTypeSeriNo: "", meainTypeTerm: "" });
    }
  };

  // Converter 건물용도별
  const bldSrvConverter = () => {
    if (Object.keys(selectMeain).length !== 0) {
      let codeNo = "";
      let codeVal = "-";
      bldSrvDs.forEach((element) => {
        if (element.codeNo == selectMeain.bldSrvCodeNo) {
          codeNo = element.codeNo;
          codeVal = element.codeVal;
        }
      });
      setBldSrvCodeNo({ codeNo: codeNo, codeVal: codeVal });
    } else {
      setBldSrvCodeNo({ codeNo: "", codeVal: "" });
    }
  };

  useEffect(() => {
    // props의 데이터를 각각 데이터 소스 객체에 맞게 Convert 작업 해준다.
    makrConverter();
    modlConverter();
    bldSrvConverter();
  }, [selectMeain]); // <- 이걸 해줘야 다른 컴포넌트에 값을 입력해준다.

  return (
    <table className="Ch-TableN">
      <tr>
        <th>건물용도</th>
        <td>
          <DropDownList
            className="meainInfoDropDown"
            id="bldSrvCodeNo"
            name="bldSrvCodeNo"
            data={bldSrvDs}
            value={bldSrvCodeNo}
            required={true}
            validationMessage={inputValidMsg.normalText}
            dataItemKey="codeNo"
            textField="codeVal"
            onChange={(e) => {
              const obj = { ...selectMeain, [e.target.name]: e.value.codeNo };
              setBldSrvCodeNo(e.target.value);
              setMeainInfo(obj);
            }}
            valid={selectMeain.bldSrvCodeNo !== "" && selectMeain.bldSrvCodeNo !== null && typeof selectMeain.bldSrvCodeNo !== "undefined"}
            itemRender={itemRender}
          />
        </td>
        <th></th>
        <td></td>
        <th></th>
        <td></td>
      </tr>
      <tr>
        <th colSpan="6">
          <div></div>
        </th>
      </tr>
      <tr>
        <th>배터리 제조사</th>
        <td>
          <DropDownList
            className="meainInfoDropDown"
            id="batteryMakrNo"
            name="batteryMakrNo"
            data={batteryMakrDs}
            value={batteryMakr}
            required={true}
            validationMessage={inputValidMsg.normalText}
            dataItemKey="makrSeriNo"
            textField="makrTerm"
            onChange={(e) => {
              const obj = { ...selectMeain, [e.target.name]: e.value.makrSeriNo };
              setBatteryMakr(e.target.value);
              setMeainInfo(obj);
            }}
            valid={selectMeain.batteryMakrNo !== "" && selectMeain.batteryMakrNo !== null && typeof selectMeain.batteryMakrNo !== "undefined"}
            itemRender={itemRender}
          />
        </td>
        <th>배터리 모델명</th>
        <td>
          <DropDownList
            className="meainInfoDropDown"
            id="meainTypeSeriNo"
            name="meainTypeSeriNo"
            data={batteryTypeDs}
            disabled={selectMeain.batteryMakrNo ? false : true}
            value={batteryType}
            required={true}
            validationMessage={inputValidMsg.normalText}
            dataItemKey="meainTypeSeriNo"
            textField="meainTypeTerm"
            onChange={(e) => {
              const obj = { ...selectMeain, [e.target.name]: e.value.meainTypeSeriNo };
              setInvtType(e.target.value);
              setMeainInfo(obj);
            }}
            valid={selectMeain.meainTypeSeriNo !== "" && selectMeain.meainTypeSeriNo !== null && typeof selectMeain.meainTypeSeriNo !== "undefined"}
            itemRender={itemRender}
          />
        </td>
        <th>배터리 용량(kW)</th>
        <td>
          <Input
            id="batteryCapa"
            name="batteryCapa"
            type="number"
            className={styles.searchTermTBC}
            step="0.01"
            min={0}
            value={selectMeain.batteryCapa ? selectMeain.batteryCapa : ""}
            required={true}
            validationMessage={inputValidMsg.number2Fix}
            onChange={onchageInput}
          />
        </td>
      </tr>
      <tr>
        <th colSpan="6">
          <div></div>
        </th>
      </tr>
      <tr>
        <th>인버터 제조사</th>
        <td>
          <DropDownList
            className="meainInfoDropDown"
            id="invtMakr"
            name="invtMakrNo"
            data={invtMakrDs}
            value={invtMakr}
            required={true}
            validationMessage={inputValidMsg.normalText}
            dataItemKey="makrSeriNo"
            textField="makrTerm"
            onChange={(e) => {
              const obj = { ...selectMeain, [e.target.name]: e.value.makrSeriNo };
              setInvtMakr(e.target.value);
              setMeainInfo(obj);
            }}
            valid={selectMeain.invtMakrNo !== "" && selectMeain.invtMakrNo !== null && typeof selectMeain.invtMakrNo !== "undefined"}
            itemRender={itemRender}
          />
        </td>
        <th>인버터 모델명</th>
        <td>
          <DropDownList
            className="meainInfoDropDown"
            id="invtType"
            name="invtTypeNo"
            data={invtTypeDs}
            disabled={selectMeain.invtMakrNo ? false : true}
            value={invtType}
            required={true}
            validationMessage={inputValidMsg.normalText}
            dataItemKey="meainTypeSeriNo"
            textField="meainTypeTerm"
            onChange={(e) => {
              const obj = { ...selectMeain, [e.target.name]: e.value.meainTypeSeriNo };
              setInvtType(e.target.value);
              setMeainInfo(obj);
            }}
            valid={selectMeain.invtTypeNo !== "" && selectMeain.invtTypeNo !== null && typeof selectMeain.invtTypeNo !== "undefined"}
            itemRender={itemRender}
          />
        </td>
        <th>인버터 용량(kW)</th>
        <td>
          <Input
            id="invtCapa"
            name="invtCapa"
            type="number"
            className={styles.searchTermTBC}
            step="0.01"
            min={0}
            value={selectMeain.invtCapa ? selectMeain.invtCapa : ""}
            required={true}
            validationMessage={inputValidMsg.number2Fix}
            onChange={onchageInput}
          />
        </td>
      </tr>
      <tr>
        <th colSpan="6">
          <div></div>
        </th>
      </tr>
      <tr>
        <th>시공업체</th>
        <td>
          <Input
            id="consEnte"
            name="consEnte"
            type="text"
            className={styles.searchTermTBC}
            value={selectMeain.consEnte ? selectMeain.consEnte : ""}
            required={true}
            validationMessage={inputValidMsg.normalText}
            onChange={onchageInput}
          />
        </td>
        <th>시공업체 연락처</th>
        <td>
          <Input
            id="consEnteCntct"
            name="consEnteCntct"
            type="text"
            className={styles.searchTermTBC}
            value={selectMeain.consEnteCntct ? selectMeain.consEnteCntct : ""}
            placeholder="하이픈(-) 없이 입력해주세요."
            onChange={onchageInput}
          />
        </td>
        <th>
          시공업체
          <br />
          핸드폰 연락처
        </th>
        <td>
          <Input
            id="consPhoneCntct"
            name="consPhoneCntct"
            type="text"
            className={styles.searchTermTBC}
            value={selectMeain.consPhoneCntct ? selectMeain.consPhoneCntct : ""}
            placeholder="하이픈(-) 없이 입력해주세요."
            onChange={onchageInput}
          />
        </td>
      </tr>
      <tr>
        <th colSpan="6">
          <div></div>
        </th>
      </tr>
      <tr>
        <th>시공일시</th>
        <td>
          <DateTimePickerKo
            id="meainInstDtm"
            name="meainInstDtm"
            className="meainDatePicker"
            value={dateFormat(selectMeain.meainInstDtm)}
            format={"yyyy-MM-dd HH:mm"}
            formatPlaceholder={{ year: "년", month: "월", day: "일", hour: "시간", minute: "분" }}
            setDtm={(v) => {
              onChangeDateTiem(v, "meainInstDtm");
            }}
            required={true}
            validationMessage={inputValidMsg.normalText}
          ></DateTimePickerKo>
        </td>
        <th>모니터링일시</th>
        <td>
          <DateTimePickerKo
            id="mntrStrtDtm"
            name="mntrStrtDtm"
            format={"yyyy-MM-dd HH:mm"}
            className="meainDatePicker"
            formatPlaceholder={{ year: "년", month: "월", day: "일", hour: "시간", minute: "분" }}
            value={dateFormat(selectMeain.mntrStrtDtm)}
            setDtm={(v) => {
              onChangeDateTiem(v, "mntrStrtDtm");
            }}
            required={true}
            validationMessage={inputValidMsg.normalText}
          ></DateTimePickerKo>
        </td>
        <th>AS만료일</th>
        <td>
          <DatePickerKo
            id="asExpiDtm"
            name="asExpiDtm"
            className="meainDatePicker"
            format="yyyy-MM-dd"
            value={dateFormat(selectMeain.asExpiDtm)}
            formatPlaceholder={{ year: "년", month: "월", day: "일" }}
            setDtm={(v) => {
              onchageDate(v, "asExpiDtm");
            }}
            required={true}
            validationMessage={inputValidMsg.normalText}
          ></DatePickerKo>
        </td>
      </tr>
      <tr>
        <th colSpan="6">
          <div></div>
        </th>
      </tr>
      {/* <tr>
          <th>사용전검사일</th>
          <td>
              <DatePickerKo id = "useBforInspDtm"  name = "useBforInspDtm" className = "meainDatePicker"
              format="yyyy-MM-dd" value = {dateFormat(selectMeain.useBforInspDtm)} 
              formatPlaceholder={{ year: '년', month: '월', day: '일' }}
              setDtm = { (v) => { onchageDate(v , "useBforInspDtm"); } }
              required = {true} validationMessage = {inputValidMsg.normalText}
                  >
              </DatePickerKo> 
          </td>
          <th>설치확인일</th>
          <td>
              <DatePickerKo id = "instCnfmDtm"  name = "instCnfmDtm" className = "meainDatePicker"
              format="yyyy-MM-dd" value = {dateFormat(selectMeain.instCnfmDtm)} 
              formatPlaceholder={{ year: '년', month: '월', day: '일' }}
              setDtm = { (v) => { onchageDate(v , "instCnfmDtm"); } }
              required = {true} validationMessage = {inputValidMsg.normalText}
                  >
              </DatePickerKo> 
          </td>
          <th></th>   
          <td></td>
      </tr>
      <tr><th colSpan="6"><div></div></th></tr> */}
      <tr>
        <th>특이사항</th>
        <td colSpan="3">
          <input id="etc" name="etc" type="text" className={styles.searchTermTBC} value={selectMeain.etc ? selectMeain.etc : ""} onChange={onchageInput} />
        </td>
      </tr>
      <th></th>
      <td></td>
      <tr>
        <th colSpan="6">
          <div></div>
        </th>
      </tr>
    </table>
  );
};

export default Meain_info_ess;
