import { useState } from "react"
import { createPortal } from "react-dom";
import styles from "./popUp.module.css"

export default function PopUp({
	name,
	content,
	top = 10,
	left = 10,
	hideUntil = 1000 * 60 * 60 * 24
}) {
	const timeLeft = localStorage.getItem(name);
	const [visible, setVisible] = useState(!timeLeft || new Date(timeLeft) < new Date());

	const handleCheck = (e) => {
		const hidePopUp = e.target.checked;
		const now = new Date()
		if(hidePopUp) {
			localStorage.setItem(name, new Date(now.getTime() + hideUntil))
		} else {
			localStorage.removeItem(name);
		}
	}

	return visible && createPortal(
		<div className={styles.popUp} style={{ top, left }}>
			{content}
			<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
				<input
					style={{ display: 'inline-block' }}
					type="checkbox"
					onClick={handleCheck}
				/>
				<label>24시간동안 보지않기</label>
				<button
					type="button"
					onClick={() => { setVisible(false); }}
				>
					[닫기]
				</button>
			</div>
		</div>, document.body
	)
}