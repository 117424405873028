import React from 'react';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { isPowerEnsoType } from '../../../com/dataUtil';
import { numFormat, roundNum } from '../../../com/dataUtil';

const HourEnergyChart = ({ hourEnergyList, ensoTypeCode }) => {
  const setChartData = (data, isEnergy) =>
    data.map((v) => ({ name: v.term, y: !isEnergy ? (v.energy ? v.energy : 0) : v.rate ? v.rate : 0, color: !isEnergy ? v.color || '#EE7951' : '#6DB6EF' }));

  const hourEnergyChartOptions = {
    chart: {
      type: 'column',
      height: '305px',
      backgroundColor: '#f5f5f5',
    },
    title: {
      text: null,
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    xAxis: {
      categories: hourEnergyList.map((v) => v.term),
      crosshair: true,
    },
    yAxis: [
      {
        // min: 0,
        // min: 0,
        // max : 50,
        startOnTick: false,
        title: {
          text: ensoTypeCode != 15007 ? (isPowerEnsoType(ensoTypeCode) ? '발전량(kWh)' : '생산량(kWh)') : '충전량(kWh)',
        },
      },
      {
        min: 0,
        max: 100,
        opposite: true,
        title: {
          text: ensoTypeCode != 15007 ? (isPowerEnsoType(ensoTypeCode) ? '발전효율(%)' : '생산효율(%)') : '충전효율(%)',
        },
      },
    ],
    plotOptions: {
      series: {
        color: '#6DB6EF',
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '13px',
          },
          formatter: function () {
            return '<span>' + '<b>' + numFormat(roundNum(this.y, 2)) + '</b>' + '</span>';
          },
        },
        marker: {
          lineWidth: 2,
          lineColor: '#6DB6EF',
          fillColor: 'white',
        },
      },
    },
    tooltip: {
      shared: true,
      formatter: function () {
        let msg = '<span>' + '<b>' + this.x + '시' + '</b>' + '</span>' + '<br>';
        if (this.points[0]) {
          msg +=
            '<span style="color:' +
            this.points[0].point.color +
            '">\u25CF</span> ' +
            '<span>' +
            this.points[0].series.name +
            '</span>' +
            ' : ' +
            '<b>' +
            numFormat(roundNum(this.points[0].y, 2)) +
            ' (kwh)' +
            '</b>' +
            '<br>';
        }
        if (this.points[1]) {
          msg +=
            '<span style="color:' +
            this.points[1].point.color +
            '">\u25CF</span> ' +
            '<span>' +
            this.points[1].series.name +
            '</span>' +
            ' : ' +
            '<b>' +
            numFormat(roundNum(this.points[1].y, 2)) +
            ' (%)' +
            '</b>' +
            '<br>';
        }
        return msg;
      },
    },
    series: [
      {
        yAxis: 0,
        name: ensoTypeCode != 15007 ? (isPowerEnsoType(ensoTypeCode) ? '발전량' : '생산량') : '충전량',
        data: setChartData(hourEnergyList),
      },
      {
        name: '효율',
        yAxis: 1,
        data: setChartData(hourEnergyList, true),
        type: 'spline',
      },
    ],
  };

  return <HighchartsReact constructorType={'chart'} highcharts={Highcharts} options={hourEnergyChartOptions} />;
};

export default HourEnergyChart;
