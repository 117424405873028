import React from "react";

// service
import { numFormat } from "../../../../com/dataUtil";

// HighCharts
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import drilldown from "highcharts/modules/drilldown";

drilldown(Highcharts);

export const MakrEventCntCharts = ({ dataSource }) => {
  const options = {
    chart: {
      type: "pie",
      marginTop: 50,
      spacingTop: 30,
    },
    noData: {
      style: {
        color: "#FFFFFF",
        fontSize: "14px",
      },
    },
    colors: [
      "#498FA8",
      "#929292",
      "#78913D",
      "#C9C03F",
      "#EECC3F",
      "#BB7E29",
      "#B76325",
      "#B2321B",
      "#AF1919",
      "#AD498A",
      "#782450",
      "#4F2B86",
      "#2D2D78",
      "#3960AA",
    ],

    title: {
      text: "제조사별 이벤트 조치소요시간",
      align: "center",
      verticalAlign: "top",
      style: {
        color: "black",
        fontSize: "16px",
        fontWeight: "bold",
      },
    },
    credits: {
      enabled: false,
    },
    navigation: {
      buttonOptions: {
        enabled: false,
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          enabled: true,
          formatter: function () {
            return this.y > 60
              ? this.point.name + ":" + numFormat(Math.round(this.y / 60)) + " 시간 (" + this.percentage.toFixed(1) + "%)"
              : this.point.name + ":" + this.y + " 분 (" + this.percentage.toFixed(1) + "%)";
          },
          style: {
            color: "black",
            fontSize: "14px",
          },
        },
      },
    },
    tooltip: {
      formatter: function () {
        return this.y > 60
          ? this.point.name + "<br>조치시간 :" + numFormat(Math.round(this.y / 60)) + " 시간 (" + this.percentage.toFixed(1) + "%)"
          : this.point.name + "<br>조치시간 :" + this.y + " 분 (" + this.percentage.toFixed(1) + "%)";
      },
    },
    series: [
      {
        name: "조치시간",
        colorByPoint: true,
        data: dataSource.tmData,
      },
    ],
    drilldown: {
      series: dataSource.drilldownTmData,
    },
  };
  return (
    <>
      <HighchartsReact Highcharts={Highcharts} options={options} />
    </>
  );
};

export default MakrEventCntCharts;
