// React
import React, { useState, useEffect, useCallback } from "react";
import { getInstaller, setUpdateInstaller, setDeleteInstaller, downloadInstallerList } from "../../../service/mngt/installer/installerService";
import log from "../../../com/log";
import { parseDate } from "../../../com/dateUtil";
import InstallerModal from "./installerModal";
import installerExcelSample from "../../../files/sample_installer.xlsx";
import { downloadExcel } from "../../../com/downloadExcel";
import { BeatLoader } from "react-spinners";

// Kendo
import kendo from "@progress/kendo-ui";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Upload } from "@progress/kendo-react-upload";

// Css
import "./installer.css";
import styles from "./installer.module.css";
import GridColumnInputFilter from "../../com/gridFilter/gridColumnInputFilter";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { getMessage } from "../../../com/messageUtil";
import { pwdRegex, phoneRegex, emailRegex } from "./../../../com/regexUtil";
import { isManager, getUserMngRgnList } from "../../../service/authService";

const Installer = ({ conn, codeList, dongList }) => {
  // 로딩 스피너
  const [loading, setLoading] = useState(true);

  // 지역 리스트
  const userMngRgnList = getUserMngRgnList();
  let dongListfilter = [];
  if (isManager() === true) {
    dongList.forEach((element) => {
      if (userMngRgnList.length != 0) {
        userMngRgnList.forEach((rgnCode) => {
          if (rgnCode === element.rgnCode) {
            dongListfilter.push(element);
          }
        });
      } else {
        dongListfilter.push(element);
      }
    });
  } else {
    dongListfilter = dongList;
  }
  const dongs = dongListfilter;

  // dongList 제거
  // dongList.forEach((element) => {
  //   element.fixbylawBundTermFix = element.fixbylawBundTerm.substring(5);
  // });

  // 요금제 유형
  const rasys = codeList.filter((item) => item.grpCodeNo === 14);
  // 드롭다운 필터 상태
  const [filterDropValue, setFilterDropValue] = useState({
    rgn: { fixbylawBundCode: "", fixbylawBundTerm: "전체" },
    rasys: { codeNo: "", codeVal: "전체" },
  });

  // 그리드 데이터
  const [dataSource, setDataSource] = useState({
    total: 0,
    dataList: [],
  });
  // 그리드 데이터 상태
  const [dataState, setDataState] = useState({
    take: 15,
    skip: 0,
    sort: [],
    sortStatus: [],
    filter: { userId: "", userTerm: "", fixbylawBundCode: "", rasysCodeNo: "" },
  });
  // 추가, 수정 모달창
  const [modalForm, setModalForm] = useState({
    visible: false,
    title: "",
    formItem: [],
  });
  // 엑셀 업로드 정보 상태
  const [uploadState, setUploadState] = useState({
    files: [],
    events: [],
    filePreviews: {},
    afterStateChange: [],
    response: [],
    visible: false,
  });

  // 데이터 불러오기
  const getGridData = () => {
    setLoading(true);
    getInstaller(conn, dataState)
      .then((response) => {
        setLoading(false);
        setDataSource((dataSource) => {
          const item = { ...dataSource };
          item.total = response.total;
          item.dataList = response.results;
          return item;
        });
      })
      .catch((error) => {
        setLoading(false);
        log(`getGridData: ${error}`);
      });
  };

  // 그리드 페이징 처리
  const handlePageChange = (e) => {
    const { take, skip } = e.page;
    dataState.skip = skip;
    dataState.take = take;
    getGridData();
  };

  // 그리드 소팅 처리
  const handleSortChange = (e) => {
    const { sort } = e;
    dataState.sort = sort;
    dataState.sortStatus = sort;
    if (sort.length !== 0) {
      if (sort[0].field === "fixbylawBundTermSimple") {
        dataState.sort = "fixbylawBundCode." + sort[0].field + "," + sort[0].dir;
      } else if (sort[0].field === "rasysCodeTerm") {
        dataState.sort = "rasysCodeNo.codeVal," + sort[0].dir;
      } else {
        dataState.sort = sort[0].field + "," + sort[0].dir;
      }
    }
    getGridData();
  };

  // 그리드 필터링 처리
  const handleFilterChange = (e) => {
    // 필터 값 설정
    const isFilter = e.filter.filters;
    if (isFilter.length !== 0) {
      if (isFilter[0].field === "userId") {
        dataState.filter.userId = isFilter[0].value;
      } else if (isFilter[0].field === "userTerm") {
        dataState.filter.userTerm = isFilter[0].value;
      } else if (isFilter[0].field === "fixbylawBundTermSimple") {
        dataState.filter.fixbylawBundCode = isFilter[0].value.fixbylawBundCode;
      } else if (isFilter[0].field === "rasysCodeTerm") {
        dataState.filter.rasysCodeNo = isFilter[0].value.codeNo;
      }
    }
    getGridData();
  };

  // 지역 필터CELL 드롭다운
  const handleFlxbylawFilterList = (props) => {
    const filterOnchage = (e) => {
      props.onChange({
        value: e.value,
        operator: e.value ? "eq" : "",
        syntheticEvent: e.syntheticEvent,
      });
      setFilterDropValue((filterDropValue) => {
        const item = { ...filterDropValue };
        item.rgn = e.value;
        return item;
      });
    };

    return (
      <div className="k-filtercell">
        <label for="dong">
          <DropDownList
            data={dongs}
            onChange={filterOnchage}
            defaultItem={{ fixbylawBundCode: "", fixbylawBundTerm: "전체" }}
            className="CustomFilterDropDown"
            name="fixbylawBund"
            textField="fixbylawBundTerm"
            dataItemKey="fixbylawBundCode"
            popupSettings={{ className: "autoFitDropDown" }}
            value={filterDropValue.rgn}
          />
        </label>
      </div>
    );
  };

  // 요금 필터CELL 드롭다운
  const handleRasysFilterList = (props) => {
    const filterOnchage = (e) => {
      props.onChange({
        value: e.value,
        operator: e.value ? "eq" : "",
        syntheticEvent: e.syntheticEvent,
      });
      setFilterDropValue((filterDropValue) => {
        const item = { ...filterDropValue };
        item.rasys = e.value;
        return item;
      });
    };

    return (
      <div className="k-filtercell">
        <label for="raySys">
          <DropDownList
            data={rasys}
            onChange={filterOnchage}
            defaultItem={{ codeNo: "", codeVal: "전체" }}
            className="CustomFilterDropDown"
            textField="codeVal"
            dataItemKey="codeNo"
            name="raySys"
            value={filterDropValue.rasys}
          />
        </label>
      </div>
    );
  };

  // 추가 모달창 저장/수정 처리
  const handleModalSaveSubmit = useCallback(
    (e) => {
      // Vlidation Check
      const isValid = validationCheck(e);
      if (isValid.isValid === false) {
        kendo.alert(isValid.message);
        return;
      }

      // 데이터 저장
      setModalForm({ ...modalForm, visible: !modalForm.visible, title: "추가", formItem: [] });
      if (e.smsNotiUseYn === undefined) e.smsNotiUseYn = "N";
      if (e.emailNotiUseYn === undefined) e.emailNotiUseYn = "N";
      if (e.kakaoNotiUseYn === undefined) e.kakaoNotiUseYn = "N";
      e.userAddr = e.fixbylawBund.fixbylawBundTerm;
      e.fixbylawBundCode = e.fixbylawBund.fixbylawBundCode;
      e.rgnCode = e.fixbylawBund.rgnCode;
      e.cityProvCode = e.fixbylawBund.provinceCode;
      e.rasysCodeNo = e.rasysCode.codeNo;
      e.regiDtm = parseDate("LocalDateTime");
      e.useYn = e.useYn ? e.useYn : "Y";
      setUpdateInstaller(conn, e)
        .then((response) => {
          setLoading(false);
          if (!response) {
            kendo.alert("저장을 실패 하였습니다.");
            return;
          }
          setModalForm({ ...modalForm, visible: !modalForm.visible, title: "", formItem: [] });
          kendo.alert("저장을 완료 하였습니다.");
          getGridData();
        })
        .catch((err) => {
          setLoading(false);
          kendo.alert("저장을 실패 하였습니다.");
          getRejection(err);
          // log(`installer.handleModalSaveSubmit error: ${err}`);
        });
    },
    [modalForm, dataSource, dataState],
  );

  // 엑셀다운
  const handleExcelDown = useCallback((e) => {
    setLoading(true);
    e.preventDefault();
    downloadInstallerList(dataState, setLoading);
  }, []);

  // 엑셀 업로드 모달폼
  const handleExcelFormControl = useCallback(
    (e) => {
      setUploadState({
        ...uploadState,
        files: [],
        events: [],
        filePreviews: {},
        afterStateChange: [],
        response: [],
        visible: !uploadState.visible,
      });
    },
    [uploadState],
  );

  // 엑셀 업로드 헤더 설정
  const handleUploadHeaders = useCallback((e) => (e.headers["X-AUTH-TOKEN"] = localStorage.getItem("atk")));

  // 엑셀 파일 추가 부분
  const onAdd = useCallback(
    ({ target, newState, affectedFiles }) => {
      const afterStateChange = () => {
        affectedFiles
          .filter((file) => !file.validationErrors)
          .forEach((file) => {
            const reader = new FileReader();
            reader.onloadend = (ev) => {
              setUploadState({
                ...uploadState,
                filePreviews: {
                  ...uploadState.filePreviews,
                  [file.uid]: ev.target.result,
                },
              });
            };
            reader.readAsDataURL(file.getRawFile());
          });
      };

      setUploadState({
        ...uploadState,
        files: newState,
        afterStateChange,
      });
    },
    [uploadState],
  );

  // 엑셀 파일 추가 후 데이터 추출 부분
  const onStatusChange = useCallback(
    ({ target, newState, affectedFiles, response }) => {
      // 여기서 BizException 처리 확인
      const result = response.response;
      if (result) {
        if (result.status !== 200) {
          const isValid = ValidBizException(result.code);
          kendo.alert(isValid.message);
          return;
        }
      }
      setUploadState({ ...uploadState, files: newState, response });
    },
    [uploadState],
  );

  // 엑셀 업로드 버튼
  const handleExcelUpload = useCallback(
    (e) => {
      const uploadExcelData = uploadState.response.response.data;
      // 엑셀 업로드 전 Validation 체크
      let breakFlag = false;
      uploadExcelData.some((item) => {
        // Validation 시작
        const isValid = { isValid: true, message: "" };
        if (!item.userId) {
          isValid.isValid = false;
          isValid.message = "ID를 입력하지 않았습니다. ID를 입력 해주세요.";
        } else if (!item.userTerm) {
          isValid.isValid = false;
          isValid.message = "사용자(수용가) 이름을 입력하지 않았습니다. 사용자(수용가) 이름을 입력 해주세요.";
        } else if (!item.loginPwd) {
          isValid.isValid = false;
          isValid.message = "암호를 입력하지 않았습니다. 암호를 입력 해주세요.";
        } else if (!item.fixbylawBundCode) {
          isValid.isValid = false;
          isValid.message = "읍면동 코드를 입력 하였는지 확인 해주세요.";
        } else if (!item.rasysCodeNo) {
          isValid.isValid = false;
          isValid.message = "요금제 코드를 입력 하였는지 확인 해주세요.";
        }
        // 실패 시 alert 창 보여주고 false 처리
        if (isValid.isValid === false) {
          kendo.alert(isValid.message);
          e.preventDefault();
          breakFlag = true;
          return true;
        }
      });
      // some 에서 return 걸어도 멈추지 않아서 플래그로 제어
      if (breakFlag === true) {
        return;
      }
      uploadExcelData.forEach((item) => {
        const dongData = dongList.filter((dongItem) => dongItem.fixbylawBundCode === item.fixbylawBundCode)[0];
        item.cityProvCode = dongData.provinceCode;
        item.rgnCode = dongData.rgnCode;
        item.userAddr = dongData.fixbylawBundTerm;
        item.fixbylawBundTermSimple = dongData.fixbylawBundTermSimple;
        item.rasysCodeTerm = rasys.filter((rasysItem) => rasysItem.codeNo === Number(item.rasysCodeNo))[0].codeVal;
        item.regiDtm = parseDate("LocalDateTime");
        item.useYn = "Y";
        item.createUpdateYn = true;
      });
      setUpdateInstaller(conn, uploadExcelData)
        .then(() => {
          kendo.alert("엑셀 저장을 완료하였습니다.");
          setUploadState({ ...uploadState, visible: !uploadState.visible });
          getGridData();
        })
        .catch((error) => {
          kendo.alert("엑셀 저장을 실패 하였습니다.");
        });
    },
    [uploadState, dataSource, dataState],
  );

  // 리젝션 코드
  const getRejection = (errorCode) => {
    // 현재 BizException 발생 시 app.jsx 에서 이미 catch 하고 정상으로 response 로 처리하며, 마지막 response 는 undefined 로 오기에 확인이 필요
  };

  // 엑셀 업로드 시 response 값 확인 후 BizException 시 안내 창
  const ValidBizException = (errorCode) => {
    const isValid = false;
    const message = getMessage(errorCode);

    return { isValid: isValid, message: message };
  };

  // Validation 처리 예정
  const validationCheck = (item) => {
    let isValid = true;
    let message = "";
    if (!item.userId) {
      isValid = false;
      message = "ID를 입력하지 않았습니다. ID를 입력 해주세요.";
    } else if (!item.userTerm) {
      isValid = false;
      message = "사용자(수용가) 이름을 입력하지 않았습니다. 사용자(수용가) 이름을 입력 해주세요.";
    } else if (!item.fixbylawBund.fixbylawBundCode) {
      isValid = false;
      message = "읍면동을 선택 또는 입력하지 않았습니다. 읍면동을 선택 하거나 엑셀 업로드라면 읍면동 코드를 다시 확인 해주세요.";
    } else if (!item.rasysCode.codeNo) {
      isValid = false;
      message = "요금제를 선택 또는 입력하지 않았습니다. 요금제를 선택 하거나 엑셀 업로드라면 요금제 코드를 다시 확인 해주세요.";
    } else if (!item.loginPwd && item.createUpdateYn) {
      isValid = false;
      message = "비밀번호를 입력하지 않았습니다. 비밀번호를 입력 해주세요.";
    }
    if (item.loginPwd !== item.loginPwdChk) {
      isValid = false;
      message = "입력하신 비밀빈호와 비밀번호 확인이 같지 않습니다.";
    }
    if (item.loginPwd) {
      if (pwdRegex(item.loginPwd)) {
        isValid = false;
        message = "비밀번호 형식이 맞지 않습니다. 8자 이상 영문,숫자,특수문자를 입력해 주세요.";
      }
    }

    if (item.userCelphone) {
      if (phoneRegex(item.userCelphone)) {
        isValid = false;
        message = "핸드폰 형식이 맞지 않습니다. 010-1234-5678의 형식으로 입력해 주세요.";
      }
    }
    if (item.userEmail) {
      if (emailRegex(item.userEmail)) {
        isValid = false;
        message = "이메일 형식이 맞지 않습니다. myname@example.com 형식으로 입력해 주세요.";
      }
    }
    return { isValid: isValid, message: message };
  };

  const edsmYnCell = (e) => {
    const { dataItem } = e;

    let result = "-";
    if (dataItem.edsmYn === "Y") {
      result = "연동";
    } else if (dataItem.edsmYn === "N") {
      result = "미연동";
    } else {
      result = "-";
    }
    return <td>{result}</td>;
  };

  const userCelphonCell = (e) => {
    const { dataItem } = e;
    let result = "-";
    if (dataItem.userCelphon) {
      result = dataItem.userCelphon;
    }
    return <td>{result}</td>;
  };

  const userEmailCell = (e) => {
    const { dataItem } = e;
    let result = "-";
    if (dataItem.userEmail) {
      result = dataItem.userEmail;
    }
    return <td>{result}</td>;
  };

  const custNoCell = (e) => {
    const { dataItem } = e;
    let result = "-";
    if (dataItem.custNo) {
      result = dataItem.custNo;
    }
    return <td>{result}</td>;
  };
  useEffect(() => {
    getGridData();
  }, []);

  return (
    <>
      <div className={["installerBody", loading ? styles.installG : ""].join(" ")}>
        <div className="installerCate">
          <Button
            onClick={(e) => {
              setModalForm({ ...modalForm, visible: !modalForm.visible, title: "추가", createUpdateYn: true, formItem: [] });
            }}
          >
            <img src="/images/icon_add.png" alt="" />
            &nbsp; 추가
          </Button>
          <Button onClick={handleExcelFormControl}>
            <img src="/images/icon_Up.png" alt="" />
            &nbsp; 엑셀파일 가져오기
          </Button>
          <Button>
            <img src="/images/icon_down.png" alt="" />
            &nbsp;
            <a className="installerExcelTxt" href={installerExcelSample} download="sample_installer.xlsx">
              엑셀샘플 다운로드
            </a>
          </Button>
          <Button onClick={handleExcelDown}>
            <img className="exelSDown" src="/images/Icon_ExelSDown.svg" alt="" />
            &nbsp; 엑셀저장
          </Button>
        </div>

        <div className="installerGrid">
          <LocalizationProvider language="ko-KR">
            <IntlProvider locale="ko">
              <label for="installerGrid">
                <Grid
                  className={styles.InstallerGrid}
                  data={dataSource.dataList}
                  skip={dataState.skip}
                  take={dataState.take}
                  total={dataSource.total}
                  sort={dataState.sortStatus}
                  pageable={{ buttonCount: 5, pageSizes: [5, 10, 15, 20] }}
                  sortable
                  filterable
                  onPageChange={handlePageChange}
                  onSortChange={handleSortChange}
                  onFilterChange={handleFilterChange}
                >
                  <GridColumn field="userId" title="ID" filterCell={GridColumnInputFilter} />
                  <GridColumn field="userTerm" title="수용가" filterCell={GridColumnInputFilter} />
                  <GridColumn field="fixbylawBundTermSimple" title="읍/면/동" filterCell={handleFlxbylawFilterList} />
                  <GridColumn field="userCelphon" title="연락처" filterable={false} cell={userCelphonCell} sortable={false} />
                  <GridColumn field="userEmail" title="이메일" filterable={false} cell={userEmailCell} sortable={false} />
                  <GridColumn field="rasysCodeTerm" title="요금제" filterCell={handleRasysFilterList} sortable={false} />
                  <GridColumn field="custNo" title="한전고객번호" filterable={false} cell={custNoCell} sortable={false} />
                  <GridColumn field="edsmYn" title="한전고객번호 연동여부" cell={edsmYnCell} filterable={false} sortable={false} />
                  <GridColumn field="regiDtm" title="등록일시" filterable={false} />
                  <GridColumn field="kakaoNotiUseYn" title="알림톡" filterable={false} width={80} sortable={false} />
                  {/* <GridColumn field="emailNotiUseYn" title="EMAIL" filterable={false} width={80} /> */}
                  <GridColumn
                    title="수정"
                    filterable={false}
                    sortable={false}
                    width={60}
                    cell={(props) => (
                      <td>
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            setModalForm({
                              ...modalForm,
                              visible: !modalForm.visible,
                              title: "수정",
                              createUpdateYn: false,
                              formItem: props.dataItem,
                            });
                          }}
                        >
                          <img src="/images/icon_su.png" alt="" />
                        </a>
                      </td>
                    )}
                  />
                  <GridColumn
                    title="삭제"
                    filterable={false}
                    sortable={false}
                    width={60}
                    cell={(props) => (
                      <td>
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            const { userId } = props.dataItem;
                            // 삭제 여부 확인
                            kendo
                              .confirm("해당 사용자(수용가)를 삭제 하시겠습니까?")
                              .then((e) => {
                                setDeleteInstaller(conn, userId)
                                  .then((response) => {
                                    kendo.alert("삭제가 완료 되었습니다.");
                                    getGridData(conn, dataState);
                                  })
                                  .catch((err) => {
                                    kendo.alert("삭제를 실패 하였습니다.");
                                    log(`installerGrid.setDeleteInstaller error: ${err}`);
                                  });
                              })
                              .then((e) => {});
                          }}
                        >
                          <img src="/images/icon_del.png" alt="" />
                        </a>
                      </td>
                    )}
                  />
                  <GridNoRecords>데이터가 존재하지 않습니다.</GridNoRecords>
                </Grid>
              </label>
            </IntlProvider>
          </LocalizationProvider>
          {modalForm.visible && (
            <InstallerModal
              modalForm={modalForm}
              setModalForm={setModalForm}
              fixbylaw={dongList}
              rasys={rasys}
              handleModalSaveSubmit={handleModalSaveSubmit}
              handleModalUpdateSubmit={handleModalSaveSubmit}
              conn={conn}
            />
          )}
          {uploadState.visible && (
            <Dialog title={"엑셀파일 가져오기"} onClose={handleExcelFormControl}>
              <LocalizationProvider language="ko-KR">
                <IntlProvider locale="ko">
                  <div className={styles.installerUploadDiv}>
                    <Upload
                      className={styles.installerUpload}
                      onBeforeUpload={handleUploadHeaders}
                      batch={false}
                      multiple={false}
                      files={uploadState.files}
                      restrictions={{ allowedExtensions: [".xlsx"] }}
                      withCredentials={true}
                      saveUrl={"/api/v2.2/user/installer/xlsx"}
                      onAdd={onAdd}
                      onStatusChange={onStatusChange}
                    />
                    <Button className={styles.installerUploadBtn} onClick={handleExcelUpload}>
                      업로드
                    </Button>
                  </div>
                </IntlProvider>
              </LocalizationProvider>
            </Dialog>
          )}
        </div>

        {loading && (
          <p className={styles.installLoading}>
            <BeatLoader loading={true} size={24} color="#1e88e5" />
          </p>
        )}
      </div>
    </>
  );
};

export default Installer;
