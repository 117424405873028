import { useEffect, useState } from "react";
//css
import styles from "./renewStat.module.css";
import CountUp from "react-countup";

const RenewStatDetailBox = (props)=> {
    const {top3EnsoStatData} = props;
    const [dataSource, setDataSource] = useState({
        cnt: 0,
        dayPower: 0,
        ensoTypeCode: 0,
        ensoTypeTerm: '',
        instCapa: 0
    });
    useEffect(()=>{
        if(top3EnsoStatData){
            setDataSource({
                cnt: top3EnsoStatData.cnt,
                dayPower: top3EnsoStatData.dayPower,
                ensoTypeCode: top3EnsoStatData.ensoTypeCode,
                ensoTypeTerm: top3EnsoStatData.ensoTypeTerm,
                instCapa: top3EnsoStatData.instCapa
            })
        }
    }, [top3EnsoStatData])

    const getIcon = (ensoTypeCode) => {
        switch(ensoTypeCode){
            case 15001:
                return (<img src="/images/dashDetail/DetailBox_sola_icon.png" alt="태양광 로고"/>);
            case 15002:
                return (<img src="/images/dashDetail/DetailBox_heath_icon.png" alt="태양열 로고"/>);
            case 15003:
                return (<img src="/images/dashDetail/DetailBox_heat_icon.png" alt="지열 로고"/>);
            case 15004:
                return (<img src="/images/dashDetail/DetailBox_velo_icon.png" alt="풍력 로고"/>)
            case 15006:
                return (<img src="/images/dashDetail/DetailBox_fuelcell_icon.png" alt="연료전지 로고"/>)
            default:
                return (<img alt=""/>)
        }
    }

    return (
        <table>
            <tr>
                <td>
                    <table className={styles.detailEnsoTitleBox}>
                        <tr>{getIcon(dataSource.ensoTypeCode)}</tr>
                        <tr>{dataSource.ensoTypeTerm}</tr>
                    </table>
                </td>

                <td>
                    <table className={styles.detailEnsoBox}>
                    <tr className={styles.detailMeainCntBox}>
                        <th>설비 개수</th>
                        <td><CountUp end={dataSource.cnt} separator="," duration={1.5} delay={2} redraw={true} /></td>
                        <td>개</td>
                    </tr>
                    <tr className={styles.detailMeainCapaBox}>
                        <th>설비 용량</th>
                        <td><CountUp end={dataSource.instCapa} separator="," duration={1.5} decimals={1} delay={2} redraw={true} /></td>
                        <td>{top3EnsoStatData?.ensoTypeCode === 15002 ? 'Km²' : 'MW'}</td>
                    </tr>

                    {top3EnsoStatData?.ensoTypeCode === 15002 || top3EnsoStatData?.ensoTypeCode === 15003 ?
                        <tr className={styles.detailHeatEnergyBox}>
                            <th>열 에너지</th>
                            <td><CountUp end={dataSource.dayPower} separator="," duration={1.5} decimals={1} delay={2} redraw={true} /></td>
                            <td>MWh</td>
                        </tr>
                    :
                        <tr className={styles.detailElecEnergyBox}>
                            <th>전기 에너지</th>
                            <td><CountUp end={dataSource.dayPower} separator="," duration={1.5} decimals={1} delay={2} redraw={true} /></td>
                            <td>MWh</td>
                        </tr>
                    }
                    
                    </table>
                </td>
            </tr>
        </table>
    )
    
}

export default RenewStatDetailBox;