import kendo from "@progress/kendo-ui";
import { useState, useEffect, memo } from "react";
import styles from "./userBetweenUser.module.css";

import "./userBetweenUser.css";
import { getUserMngRgnList, isManager } from "../../../../service/authService";
import UserBetweenUserSearch from "./userBetweenUserSearch";
import { getMakrMeainModelEnergyDataList, getUserEnergyDataList } from "../../../../service/stats/type/userBetweenUserDataService";
import { Button } from "@progress/kendo-react-buttons";
import UserBetweenUserListChart from "./userBetweenUserListChart";
import UserBetweenUserListGrid from "./userBetweenUserListGrid";
import UserBetweenUserMakerChart from "./userBetweenUserMakerChart";
import UserBetweenUserMakerGrid from "./userBetweenUserMakerGrid";

const UserBetweenUser = memo(({ conn, codeList, rgnList }) => {
  let rgns = rgnList;
  // 담당자 일떄 rgns 수정 (해당 담당 지역만 리스트 보여주기)
  if (isManager() === true) {
    const userMngRgnList = getUserMngRgnList();
    const rgnListfilter = [];
    rgnList.forEach((element) => {
      if (userMngRgnList.length != 0) {
        userMngRgnList.forEach((rgnCode) => {
          if (rgnCode === element.rgnCode) {
            rgnListfilter.push(element);
          }
        });
      } else {
        rgnListfilter.push(element);
      }
    });
    rgns = rgnListfilter;
  }

  // 엑셀 데이터 래퍼런스
  let [userExcelData, setUserExcelData] = useState(null);
  let [makrExcelData, setMakrExcelData] = useState(null);

  // 날짜 Default 정의
  let today = new Date();
  let yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);

  // 검색 조건
  const [search, setSearch] = useState({
    ensoTypeCode: 15001,
    startGathDtm: yesterday,
    endGathDtm: yesterday,
    rgnCode: "",
    userId: "",
    makrSeriNo: "",
    dateStatus: { codeNo: 23006, codeVal: "직접조회" },
    startMax: yesterday,
    endMin: yesterday,
    yesterday: yesterday,
    makrDrillDown: false, // <- 제조사 - 모델 드롭다운 플래그
  });

  // 데이터 리스트
  const [dataList, setDataList] = useState({
    userDataEfic: [],
    userDataRatio: [],
    userDataList: [],
    makrDataEfic: [],
    makrDataRatio: [],
    makrMeainModelGrid: [],
  });

  // reject 함수
  const getRejection = (error) => {};

  // 유저 데이터 리스트 불러오는 함수
  const getUserDataList = () => {
    search.makrDrillDown = false;
    getUserEnergyDataList(conn, getUserDataResult, getRejection, search);
  };

  // 불러온 유저 데이터를 가공 또는 설정 하는 함수 (CallBack)
  const getUserDataResult = (result) => {
    // UserId 설정
    if (result.userBetweenUserDataDtoList.length > 0) {
      search.userId = result.userBetweenUserDataDtoList[0].userId;
    }
    setDataList((dataList) => {
      const item = { ...dataList };
      item.userDataEfic = result.userDataEfic;
      item.userDataRatio = result.userDataRatio;
      item.userDataList = result.userBetweenUserDataDtoList;
      return item;
    });
    // 제조사 데이터
    getMakrMeainModelDataList();
  };

  // * 제조사 데이터를 불러오는 조건
  // 1. 처음 페이지 불러올 때 , 조회 시 사용자 리스트 조회 되면서 첫번째 사용자를 조회 하도록 설정
  // 2. 원하는 사용자를 클릭 시 조회 되도록 로직 설정
  // 3. 제조사 그래프를 클릭 시 해당 제조사의 모델 데이터 리스트 나오도록 설정
  // 4. 모델 리스트를 클릭 하면 제조사 리스트로 나오도록 수정
  // 제조사 / 모델 데이터 불러오는 함수
  const getMakrMeainModelDataList = () => {
    getMakrMeainModelEnergyDataList(conn, getMakrMeainModelResult, getRejection, search);
  };

  // 제조사 / 모델 데이터 가공 또는 설정 (CallBack)
  const getMakrMeainModelResult = (result) => {
    setDataList((dataList) => {
      const item = { ...dataList };
      item.makrDataEfic = result.makrDataEfic;
      item.makrDataRatio = result.makrDataRatio;
      item.makrMeainModelGrid = result.makrMeainModelGrid;
      return item;
    });
  };

  useEffect(() => {
    getUserDataList();
  }, []);

  return (
    <div>
      <div className="uk-grid">
        <div className="uk-width-medium-1-1">
          <div className="md-card TBgA">
            <div className="md-card-content small-padding">
              <UserBetweenUserSearch codeList={codeList} rgns={rgns} search={search} setSearch={setSearch} getUserDataList={getUserDataList} />
            </div>
          </div>
        </div>
      </div>
      <div className="uk-grid">
        <div className="uk-width-medium-1-2">
          <div className="md-card">
            <div className="md-card-content">
              <div className="md-card-toolbar">
                <h3 className="md-card-toolbar-heading-text">TOP 생산량 분포 | 수용가 생산량 목록</h3>
                <div className={["md-card-toolbar-actions", styles.buttonArea].join(" ")}>
                  <Button
                    className="userBetweenUserExcelBtnClass"
                    onClick={(e) => {
                      userExcelData.save();
                    }}
                  >
                    엑셀저장
                  </Button>
                </div>
              </div>
              <UserBetweenUserListChart
                search={search}
                getMakrMeainModelDataList={getMakrMeainModelDataList}
                userDataEfic={dataList.userDataEfic}
                userDataRatio={dataList.userDataRatio}
              />
              <UserBetweenUserListGrid search={search} userDataList={dataList.userDataList} kendo={kendo} setUserExcelData={setUserExcelData} />
            </div>
          </div>
        </div>
        <div className="uk-width-medium-1-2">
          <div className="md-card">
            <div className="md-card-content">
              <div className="md-card-toolbar">
                <h3 className="md-card-toolbar-heading-text">
                  {search.makrSeriNo === "" && <span>제조사간 생산량 분포</span>}
                  {search.makrSeriNo !== "" && <span>제조사 모델간 생산량 분포</span>}
                </h3>
                <div className={["md-card-toolbar-actions", styles.buttonArea].join(" ")}>
                  <Button
                    className="userBetweenUserExcelBtnClass"
                    onClick={(e) => {
                      makrExcelData.save();
                    }}
                  >
                    엑셀저장
                  </Button>
                </div>
              </div>
              <UserBetweenUserMakerChart
                makrDataEfic={dataList.makrDataEfic}
                makrDataRatio={dataList.makrDataRatio}
                search={search}
                getMakrMeainModelDataList={getMakrMeainModelDataList}
              />
              <UserBetweenUserMakerGrid search={search} makrMeainModelGrid={dataList.makrMeainModelGrid} kendo={kendo} setMakrExcelData={setMakrExcelData} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default UserBetweenUser;
