import { useState, useEffect, useRef, useCallback, memo } from "react";
import { BeatLoader } from "react-spinners";

import styles from "./installerMeain.module.css";
import "./installerMeain.css";

import { DropDownList as DropDown } from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";
import DatePickerKo from "../../com/dateInputs/datePickerKo";
import { Search } from "@material-ui/icons";
import InstallerMeainList from "./installerMeain_list";
import InstallerMeainCount from "./installerMeain_count";

import { getInstallerMeainStatCnt, getInstallerMeainList } from "../../../service/monitor/installerMeainService";
import { useLocation } from "react-router";
import { getUser, getUserMngRgnList, getUserTypeCode, isInstaller, isManager } from "../../../service/authService";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import useHistoryState from "../../../com/useHistoryState";
import RgnDropdown from "../../com/dropdown/rgnDropdown";

const InstallerMeain = memo(({ conn, codeList, rgnList, dongList, qs }) => {
  const [selected, setSelected] = useState(0);
  const handleSelect = (e) => {
    setSelected(e.selected);
  };
  const isMobile = localStorage.getItem("isMobile");
  let location = useLocation();
  let { rgnCode, dongCode, dongTerm, statNormal, statUnOper, statReady, statWarn, statError, ensoTypeCode } = qs.parse(location.search);

  const defualtVal = { codeVal: "전체", codeNo: "" };
  const defualtValDong = { fixbylawBundTermSimple: "읍면동", fixbylawBundCode: "" };
  const ensoList = codeList.filter((c) => c.grpCodeNo === 15);
  const buildingTypeList = codeList.filter((c) => c.grpCodeNo === 31);

  const accUserInfo = getUser();

  // 검색 조건
  const [search, setSearch] = useHistoryState(
    {
      ensoTypeCode: ensoTypeCode !== "15000" && ensoTypeCode !== "" ? ensoTypeCode : "",
      bldSrvCode: "",
      rgnCode: rgnCode ? rgnCode : "",
      fixbylawBundCode: dongCode ? dongCode : "",
      searchTerm: "",
      statNormal: statNormal === "false" ? false : true,
      statUnOper: statUnOper === "false" ? false : true,
      statReady: statReady === "false" ? false : true,
      statWarn: statWarn === "false" ? false : true,
      statError: statError === "false" ? false : true,
      startDtm: "",
      endDtm: "",
      ensoType: { codeNo: "", codeVal: "전체" },
      rgn: { rgnCode: "", rgnTermSimple: "시군구" },
      dong: { fixbylawBundCode: "", fixbylawBundTermSimple: "읍면동" },
      dongTerm: "",
    },
    "search",
  );

  const [dongs, setDongs] = useState([]);
  useEffect(() => {
    let dongFilter = dongList.filter((e) => e.rgnCode === search.rgnCode);
    setDongs(dongFilter);
  }, [search.rgn]);

  const [statCnt, setStatCnt] = useState({
    readyCnt: 0,
    normalCnt: 0,
    unOperCnt: 0,
    warnCnt: 0,
    errorCnt: 0,
  });

  const [page, setPage] = useState({
    skip: 0,
    take: isMobile === "Y" ? 4 : 25,
    count: 0,
    list: [],
  });

  const setSearchClickEvt = (e) => {
    e.preventDefault();
    page.skip = 0;
    // page.take = 20;
    setInstallerMeainstatCount(search);
    setInstallerMeainList(search, page);
  };

  const setInstallerMeainstatCount = (search) => {
    getInstallerMeainStatCnt(conn, search).then((cnt) => {
      setStatCnt((state) => {
        const item = { ...state };
        item.readyCnt = cnt.readyCnt || 0;
        item.normalCnt = cnt.normalCnt || 0;
        item.unOperCnt = cnt.unOperCnt || 0;
        item.warnCnt = cnt.warnCnt || 0;
        item.errorCnt = cnt.errorCnt || 0;
        return item;
      });
    });
  };

  // 로딩 스피너
  const [loading, setLoading] = useState(false);

  const setInstallerMeainList = (search, page) => {
    setLoading(true);
    getInstallerMeainList(conn, search, page).then((result) => {
      setPage((page) => {
        const item = { ...page };
        item.skip = page.skip;
        item.take = page.take;
        item.count = result.total;
        item.list = result.results;
        return item;
      });
      setLoading(false);
    });
  };

  useEffect(() => {
    setInstallerMeainstatCount(search);
    setInstallerMeainList(search, page);
    changeValue();
  }, []);

  // 드롭다운 이벤트
  const onChangeEvt = (e) => {
    if (e.target.name === "rgn") {
      setSearch((search) => {
        const item = { ...search };
        item.rgnCode = e.target.value.rgnCode;
        item.rgn = e.target.value;
        item.fixbylawBundCode = "";
        item.dongTerm = "";
        item.dong = { fixbylawBundCode: "", fixbylawBundTermSimple: "읍면동" };
        item.rgnTerm = e.target.value.rgnTermSimple;
        return item;
      });
    } else if (e.target.name === "dong") {
      setSearch((search) => {
        const item = { ...search };
        item.fixbylawBundCode = e.target.value.fixbylawBundCode;
        item.dong = e.target.value;
        item.dongTerm = e.target.value.fixbylawBundTermSimple;
        return item;
      });
    } else if (e.target.name === "ensoTypeCode") {
      setSearch((search) => {
        const item = { ...search };
        item.ensoTypeCode = e.value.codeNo;
        item.ensoType = { codeNo: e.value.codeNo, codeVal: e.value.codeVal };
        return item;
      });
    }
  };

  // 값 설정
  const changeValue = () => {
    setSearch((search) => {
      const item = { ...search };
      if (ensoTypeCode !== null) {
        item.ensoType = codeList.filter((c) => c.codeNo.toString() == item.ensoTypeCode)[0];
      }
      if (rgnCode !== null) {
        item.rgn = rgnList.filter((e) => e.rgnCode == item.rgnCode)[0];
      }
      if (dongCode !== null) {
        item.dong = dongList.filter((e) => e.fixbylawBundCode === search.fixbylawBundCode)[0];
      }
      return item;
    });
  };

  return (
    <>
      {isMobile === "Y" ? (
        <div className={loading ? styles.installerMeain : ""}>
          <TabStrip selected={selected} onSelect={handleSelect}>
            <TabStripTab title="설비상태">
              <div style={{ padding: "10px" }}>
                <InstallerMeainCount search={search} statCnt={statCnt} isMobile={isMobile} />
              </div>
            </TabStripTab>
            <TabStripTab title="수용가/CID">
              <div style={{ padding: "10px" }}>
                <span className="searchSubTitleB" style={{ fontWeight: "bold" }}>
                  수용가/CID
                </span>
                <input
                  type="text"
                  className="installerMeainSearchTerm"
                  placeholder="수용가명 또는 CID"
                  disabled={isInstaller() === true ? true : false}
                  value={isInstaller() === true ? accUserInfo.userTerm : search.searchTerm}
                  onChange={(e) => {
                    setSearch((search) => {
                      const item = { ...search };
                      item.searchTerm = e.target.value;
                      return item;
                    });
                  }}
                />
              </div>
            </TabStripTab>
            <TabStripTab title="기간선택">
              <div style={{ padding: "10px" }}>
                <table className="installerMeainSearchTable">
                  <tr>
                    <th colSpan="4">
                      <span className="searchSubTitleA">설치날짜</span>
                    </th>
                  </tr>
                  <tr>
                    <td colSpan="4">
                      <label>
                        <DatePickerKo
                          className="installerMeainMobileCal"
                          format="yyyy-MM-dd"
                          setDtm={(v) => (search.startDtm = v)}
                          disabled={isInstaller() === true ? true : false}
                        />
                      </label>
                      ~
                      <label>
                        <DatePickerKo
                          className="installerMeainMobileCal"
                          format="yyyy-MM-dd"
                          setDtm={(v) => (search.endDtm = v)}
                          disabled={isInstaller() === true ? true : false}
                        />
                      </label>
                    </td>
                  </tr>
                </table>
              </div>
            </TabStripTab>
            <TabStripTab title="상세조회">
              <div>
                <table className="installerMeainSearchTable">
                  <tr>
                    <th>
                      <span>에너지원</span>
                    </th>
                    <td>
                      <label>
                        <DropDown
                          className="installerMeainDropDown"
                          name="ensoTypeCode"
                          data={ensoList}
                          textField="codeVal"
                          dataItemKey="codeNo"
                          defaultItem={defualtVal}
                          value={search.ensoType}
                          onChange={onChangeEvt}
                        />
                      </label>
                    </td>
                    <th>
                      <span>건축물용도</span>
                    </th>
                    <td>
                      <label>
                        <DropDown
                          className="installerMeainDropDown"
                          data={buildingTypeList}
                          textField="codeVal"
                          dataItemKey="codeNo"
                          defaultItem={defualtVal}
                          disabled={isInstaller() === true ? true : false}
                          onChange={(e) =>
                            setSearch((state) => {
                              const item = { ...state };
                              item.bldSrvCode = e.target.value.codeNo;
                              return item;
                            })
                          }
                        />
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <span>지역 검색</span>
                    </th>
                    <td colSpan="3">
                      <label>
                        <RgnDropdown
                          id="rgn"
                          name="rgn"
                          className="installerMeainDropDown"
                          rgnList={rgnList}
                          search={search}
                          objKey={"rgn"}
                          setSearch={setSearch}
                          onChange={onChangeEvt}
                        />
                      </label>
                      <label>
                        <DropDown
                          id="dong"
                          name="dong"
                          data={dongs}
                          disabled={isInstaller() === true ? true : false}
                          className="installerMeainRgnDropDown"
                          value={search.dong}
                          textField="fixbylawBundTermSimple"
                          dataItemKey="fixbylawBundCode"
                          defaultItem={defualtValDong}
                          onChange={onChangeEvt}
                        />
                      </label>
                    </td>
                  </tr>
                </table>
              </div>
            </TabStripTab>
          </TabStrip>
          <div>
            <div style={{ textAlign: "center" }}>
              <span className={styles.searchSubTitleC}>
                <button className={styles.mobileSearchBtn} onClick={setSearchClickEvt}>
                  <Search className="material-icons" /> 조회
                </button>
              </span>
            </div>
            <InstallerMeainList search={search} setInstallerMeainList={setInstallerMeainList} page={page} />
          </div>
          {loading && (
            <p className={styles.installerMeainLoading}>
              <BeatLoader loading={true} size={24} color="#1e88e5" />
            </p>
          )}
        </div>
      ) : (
        <div className={loading ? styles.installerMeain : ""}>
          <div className="uk-grid">
            <div className="uk-width-medium-1-1">
              <div className="md-card installerMeainTBgA">
                <form className="uk-form-stacked">
                  <span className="searchSubTitleA">에너지원</span>
                  <label for="ensoList">
                    <DropDown
                      className="installerMeainDropDown"
                      name="ensoTypeCode"
                      data={ensoList}
                      textField="codeVal"
                      dataItemKey="codeNo"
                      defaultItem={defualtVal}
                      value={search.ensoType}
                      onChange={onChangeEvt}
                    />
                  </label>
                  <span className="searchSubTitleA">지역 검색</span>
                  <label for="rgn">
                    <RgnDropdown
                      id="rgn"
                      name="rgn"
                      className="installerMeainDropDown"
                      rgnList={rgnList}
                      search={search}
                      objKey={"rgn"}
                      setSearch={setSearch}
                      onChange={onChangeEvt}
                    />
                  </label>
                  <label for="dong">
                    <DropDown
                      id="dong"
                      name="dong"
                      data={dongs}
                      disabled={isInstaller() === true ? true : false}
                      className="installerMeainDropDown"
                      value={search.dong}
                      textField="fixbylawBundTermSimple"
                      dataItemKey="fixbylawBundCode"
                      defaultItem={defualtValDong}
                      onChange={onChangeEvt}
                    />
                  </label>
                  <span className="searchSubTitleA">건축물용도</span>
                  <label for="building">
                    <DropDown
                      className="installerMeainDropDown"
                      data={buildingTypeList}
                      textField="codeVal"
                      dataItemKey="codeNo"
                      defaultItem={defualtVal}
                      disabled={isInstaller() === true ? true : false}
                      onChange={(e) =>
                        setSearch((state) => {
                          const item = { ...state };
                          item.bldSrvCode = e.target.value.codeNo;
                          return item;
                        })
                      }
                    />
                  </label>
                  <span className="searchSubTitleB">수용가/CID</span>
                  <label for="installer">
                    <input
                      type="text"
                      className="searchTerm"
                      placeholder="수용가명 또는 CID"
                      disabled={isInstaller() === true ? true : false}
                      value={isInstaller() === true ? accUserInfo.userTerm : search.searchTerm}
                      onChange={(e) => {
                        setSearch((search) => {
                          const item = { ...search };
                          item.searchTerm = e.target.value;
                          return item;
                        });
                      }}
                    />
                  </label>
                  <span className={styles.disableCompo}>
                    <span className="searchSubTitleA">설치날짜</span>
                    <label for="date">
                      <DatePickerKo format="yyyy-MM-dd" setDtm={(v) => (search.startDtm = v)} disabled={isInstaller() === true ? true : false} />
                    </label>
                    <span className="textColorGr"> ~ </span>
                    <label for="date">
                      <DatePickerKo format="yyyy-MM-dd" setDtm={(v) => (search.endDtm = v)} disabled={isInstaller() === true ? true : false} />
                    </label>
                  </span>
                  <span className={styles.searchSubTitleC} align="right">
                    <button className="ch-btnA" onClick={setSearchClickEvt}>
                      <Search className="material-icons" />
                    </button>
                  </span>
                </form>
                <InstallerMeainCount search={search} statCnt={statCnt} setSearch={setSearch} />
              </div>
            </div>
          </div>
          <div>
            <label>
              <InstallerMeainList search={search} setInstallerMeainList={setInstallerMeainList} page={page} />
            </label>
          </div>
          {loading && (
            <p className={styles.installerMeainLoading}>
              <BeatLoader loading={true} size={24} color="#1e88e5" />
            </p>
          )}
        </div>
      )}
    </>
  );
});

export default InstallerMeain;
