import React, { useState, memo, useEffect } from "react";
import GathRawDataLogSearch from "./GathRawDataLogSearch";
import GathRawDataLogGridSola from "./GathRawDataLogGridSola";
import GathRawDataLogGridHeat from "./gathRawDataLogGridHeat";
import GathRawDataLogGridHeath from "./gathRawDataLogGridHeath";
import GathRawDataLogGridVelo from "./GathRawDataLogGridVelo";
import GathRawDataLogGridFuelcell from "./gathRawDataLogGridFuelcell";
import { useGathRawLog } from "./useGathRawLog";
import { BeatLoader } from "react-spinners";

//CSS
import "./GathRawDataLog.css";
import styles from "./gathRawDataLog.module.css"

const OperHist = memo(({ conn, codeList, rgnList, dongList }) => {
  const { search, setSearch, filter, paging, setPage, fetchData,
    loading, setLoading, data, isSearched, makrMeainModelList, rtuList
   } = useGathRawLog({ conn })

  return (
    <div>
      <GathRawDataLogSearch
        search={search}
        setSearch={setSearch}
        conn={conn}
        codeList={codeList}
        rgnList={rgnList}
        dongList={dongList}
        paging={paging}
        rtuList={rtuList}
        makrList={makrMeainModelList.makrList}
        meainModelList={makrMeainModelList.meainModelList}
        setLoading={setLoading}
        fetchData={fetchData}
      />
      <div
        className="gathRawDataGridDiv"
        style={
          loading
            ? {
                opacity: 0.6,
                pointerEvents: "none",
              }
            : null
        }
      >
        {isSearched ? (
          data.count !== 0 ? (
            <>
              {filter.ensoTypeCode === 15001 && <GathRawDataLogGridSola paging={paging} setPage={setPage} data={data} fetchData={fetchData}/>}
              {filter.ensoTypeCode === 15002 && <GathRawDataLogGridHeat paging={paging} setPage={setPage} data={data} fetchData={fetchData}/>}
              {filter.ensoTypeCode === 15003 && <GathRawDataLogGridHeath paging={paging} setPage={setPage} data={data} fetchData={fetchData}/>}
              {filter.ensoTypeCode === 15004 && <GathRawDataLogGridVelo paging={paging} setPage={setPage} data={data} fetchData={fetchData}/>}
              {filter.ensoTypeCode === 15006 && <GathRawDataLogGridFuelcell paging={paging} setPage={setPage} data={data} fetchData={fetchData}/>}
            </>
          ) : (
            <div className={styles.none}>
              조건에 해당하는 데이터가 존재하지 않습니다
            </div>
          )
        ) : (
          <div className={styles.none}>
            <img src="/images/common/document_search.svg" alt="none"/>
            {`조회할 조건을 선택한 뒤,\n검색버튼을 눌러주세요.`}
          </div>
        )}
        {loading && (
          <p
            style={{
              position: "absolute",
              top: "55%",
              left: "50%",
              transform: "translate(-50%,-50%)",
            }}
          >
            <BeatLoader loading={true} size={24} color="#1e88e5" />
          </p>
        )}
      </div>
    </div>
  );
});

export default OperHist;
