import React from "react";
import moment from "moment/moment";
import { Button } from "@progress/kendo-react-buttons";

import Edit from "@mui/icons-material/Edit";
import Delete from "@mui/icons-material/Delete";

import kendo from "@progress/kendo-ui";
import { useRef } from "react";

const AddlHistoryComment = ({ deleteAddlHistoryComment, meainMngtLog, styles }) => {
  // const test = useRef(null);

  // // 관리이력 팝업창에서 관리이력 수정/삭제 함수
  // const editAddlHistoryComment = (id, e) => {
  //   console.log(id);
  //   console.log(e);
  //   test.current.style.backgroundColor = '#D8FFFF';
  //   test.current.style.border = '1px solid #ECECEC';
  //   // kendo.confirm("해당 기록을 수정하시겠습니까?")
  //   //   .then(() => {

  //   //   })
  // }

  return (
    <div className={styles.addlHistoryComment}>
      <div className={styles.addlHistoryCommentBtnSpace}>
        {/* <Button type="button" onClick={(e) => editAddlHistoryComment(meainMngtLog.id, e)}>
          <Edit />
        </Button> */}
        <Button type="button" onClick={() => deleteAddlHistoryComment(meainMngtLog.id)} className={styles.addlHistoryCommentBtn}>
          <Delete />
        </Button>
      </div>
      <div className={styles.addlHistoryCommentId}>{meainMngtLog.regiId}</div>
      {moment(meainMngtLog.regiDtm).format("YYYY-MM-DD HH:mm")}
      <br />
      <br />
      <div className={styles.addlHistoryCommentBody}>{meainMngtLog.addlHistory}</div>
    </div>
  );
};

export default AddlHistoryComment;
