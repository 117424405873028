import React, { useState, useEffect, useCallback } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";
import kendo from "@progress/kendo-ui";

import { yearList } from "../../../com/dropDownDs";

// 엑셀 다운로드
import { getRpsBpmExcelDownload } from "../../../service/meain/bpmRps/bpmRpsService";
import RgnDropdown from "../../com/dropdown/rgnDropdown";

const BpmRpsSearch = ({ conn, rgnList, codeList, search, setSearch, setLoading, paging, styles, getBpmRpsData, uploadState, setUploadState, setModalItem }) => {
  // 에너지원 데이터 소스
  const ensoList = codeList.filter((c) => c.grpCodeNo === 15 && c.codeNo !== 15007);

  // 데이터 상태가 bpm 인 경우 사업구분 목록에서 rps를 제외
  const bizSctList = search.searchMode == "bpm" ? codeList.filter((c) => c.grpCodeNo === 33 && c.codeNo !== 33009) : codeList.filter((c) => c.grpCodeNo === 33);

  const onChange = (e) => {
    if (e.target.name === "ensoTypeDropDown") {
      setSearch((search) => {
        const item = { ...search };
        item.ensoType = { codeNo: e.value.codeNo, codeVal: e.value.codeVal };
        return item;
      });
    } else if (e.target.name === "bizSctDropDown") {
      setSearch((search) => {
        const item = { ...search };
        item.bizSct = { codeNo: e.value.codeNo, codeVal: e.value.codeVal };
        return item;
      });
    } else if (e.target.name === "bizYyDropDown") {
      setSearch((search) => {
        const item = { ...search };
        item.bizYy = e.value;
        return item;
      });
    } else if (e.target.name === "rgnDropDown") {
      setSearch((search) => {
        const item = { ...search };
        item.rgn = e.value;
        return item;
      });
    }
  };

  // 조회 버튼
  const searchData = () => {
    // 페이징 초기화 로직 추가
    paging.skip = 0;
    paging.take = 20;
    getBpmRpsData(search, paging);
  };

  // 엑셀 다운로드
  const excelDownload = () => {
    setLoading(true);
    getRpsBpmExcelDownload(search, paging, setLoading);
  };

  // 엑셀 샘플 다운로드
  const downloadSample = () => {
    const link = document.createElement("a");
    link.href = "/template/meain/template_bpmrps.xlsx";
    document.body.appendChild(link);
    link.click();
  };

  // 엑셀 업로드 모달폼
  const handleExcelFormControl = useCallback(
    (e) => {
      setUploadState({
        ...uploadState,
        files: [],
        events: [],
        filePreviews: {},
        afterStateChange: [],
        response: [],
        visible: !uploadState.visible,
      });
    },
    [uploadState],
  );

  return (
    <>
      <div className="uk-grid">
        <div className="uk-width-medium-1-1">
          <div className="md-card TBgA">
            <div className={["md-card-content", styles.searchPadding].join(" ")}>
              <label for="enso">
                <DropDownList
                  data={ensoList}
                  name="ensoTypeDropDown"
                  textField="codeVal"
                  dataItemKey="codeNo"
                  onChange={onChange}
                  className="bpmRpsSearchDropDown"
                  defaultItem={{ codeNo: null, codeVal: "전체" }}
                  value={search.ensoType}
                />
              </label>
              {search.searchMode == "rps" ? (
                <></>
              ) : (
                <>
                  <label for="bizSct">
                    <DropDownList
                      data={bizSctList}
                      name="bizSctDropDown"
                      textField="codeVal"
                      dataItemKey="codeNo"
                      onChange={onChange}
                      className="bpmRpsSearchDropDown"
                      defaultItem={{ codeNo: "", codeVal: "사업구분" }}
                      value={search.bizSct}
                    />
                  </label>
                  <label for="bizYy">
                    <DropDownList
                      id="bizYy"
                      name="bizYyDropDown"
                      data={yearList()}
                      className="bpmRpsSearchDropDown"
                      defaultItem="사업연도"
                      onChange={onChange}
                    />
                  </label>
                </>
              )}
              <label for="rgn">
                <RgnDropdown
                  name="rgnDropDown"
                  rgnList={rgnList}
                  search={search}
                  objKey={"rgn"}
                  setSearch={setSearch}
                  onChange={onChange}
                  defaultItem={{ rgnTermSimple: "시/군/구", rgnCode: "" }}
                />
              </label>
              <span className="searchSubTitleC">
                <Button id="inqBtn" icon="search" name="inqBtn" onClick={searchData} style={{ paddingLeft: "20px", paddingRight: "20px" }}></Button>
              </span>
              <span className="bpmRpsSearchBtnRight">
                <span className="searchSubTitleC">
                  <Button className="excelBtn" id="excelDownloadBtn" name="excelBtn" onClick={excelDownload} style={{ marginRight: "20px" }} icon="excel">
                    엑셀 저장
                  </Button>
                </span>
                <span className="searchSubTitleC">
                  <Button className="excelBtn" id="excelSampleBtn" name="excelBtn" onClick={downloadSample} style={{ marginRight: "20px" }} icon="download">
                    엑셀 샘플 저장
                  </Button>
                </span>
                <span className="searchSubTitleC">
                  <Button
                    className="excelBtn"
                    id="excelUploadBtn"
                    name="excelBtn"
                    onClick={handleExcelFormControl}
                    style={{ marginRight: "20px" }}
                    icon="upload"
                  >
                    엑셀 업로드
                  </Button>
                </span>
                <span className="searchSubTitleC">
                  <Button
                    id="addBtn"
                    name="addBtn"
                    onClick={() => {
                      const newRecord = { rgnCode: "", bizSctCode: "", ensoTypeCode: "" };
                      setModalItem({
                        title: "추가",
                        item: { rgnCode: newRecord.rgnCode, bizSctCode: newRecord.bizSctCode, ensoTypeCode: newRecord.ensoTypeCode },
                        visible: true,
                      });
                    }}
                    style={{ marginRight: "20px" }}
                    icon="add"
                  >
                    추가
                  </Button>
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BpmRpsSearch;
