import { parseDate } from "./../../com/dateUtil";

//cid list 받기
export const getCidListByUserId = async (conn, search) => {
  const { userId } = search;

  const config = {
    params: {
      userId: userId,
    },
  };
  const result = await conn.get("/kepco/cid/byId", config);
  return result;
};

//edsm 연동여부 확인
export const checkEdsm = async (conn, data) => {
  const config = {
    params: {
      custNoList: data,
    },
  };
  const result = await conn.get("/kepco/edsm/agree", config);

  return result;
};

//한전 소비량, 발전량
export const getRawKepcoHour = async (conn, search, setChartData) => {
  const { userId, cid, startDate, endDate, dateType } = search;

  const config = {
    params: {
      userId: userId,
      cid: cid,
      startDate: parseDate("yyyy-mm-dd", startDate),
      endDate: parseDate("yyyy-mm-dd", endDate),
    },
  };

  const result = await conn.get("/kepco/data/list/" + dateType, config);

  //차트 데이터 가공
  let pwrQtyList = [];
  let dayPowerList = [];
  result.forEach((item) => {
    if (search.dateType === "HH") {
      const name = item.gathDtm.substring(11, 13) + "시";
      const pwrQty = item.pwrQty;
      const dayPower = item.dayPower;
      pwrQtyList.push({ name: name, y: pwrQty });
      dayPowerList.push({ name: name, y: dayPower });
    } else {
      const name = item.gathDtm;
      const pwrQty = item.pwrQty;
      const dayPower = item.dayPower;
      pwrQtyList.push({ name: name, y: pwrQty });
      dayPowerList.push({ name: name, y: dayPower });
    }
  });

  setChartData({ pwrQtyList, dayPowerList });

  return result;
};

//한전 15분 raw데이터 조회
export const getRawKepcoFifteen = async (conn, search, page) => {
  const { userId, cid, startDate, endDate } = search;
  const { take, skip } = page;

  const config = {
    params: {
      page: (take + skip) / take,
      size: take,
      userId: userId,
      cid: cid,
      startDate: parseDate("yyyy-mm-dd", startDate),
      endDate: parseDate("yyyy-mm-dd", endDate),
    },
  };

  const result = await conn.get("/kepco/fifteen/page", config);

  return result;
};

//한전 일별 raw데이터 조회
export const getRawKepcoDay = async (conn, search, page) => {
  const { userId, cid, startDate, endDate } = search;
  const { take, skip } = page;

  const config = {
    params: {
      page: (take + skip) / take,
      size: take,
      userId: userId,
      cid: cid,
      startDate: parseDate("yyyy-mm-dd", startDate),
      endDate: parseDate("yyyy-mm-dd", endDate),
    },
  };

  const result = await conn.get("/kepco/day/page", config);

  return result;
};

//EDSM 재수집
export const retryEdsm = async (conn, userId, date) => {
  const retryDate = parseDate("yyyymmdd", date);
  const result = await conn.post("/kepco/retry/edsm/" + userId + "/" + retryDate);

  return result;
};

//개발자 전용 기간 재수집
export const retryDevEdsm = async (conn, date) => {
  const retryDate = parseDate("yyyymmdd", date);
  const result = await conn.post("/kepco/retryDev/edsm/" + retryDate);

  return result;
};
