import React from "react";
import ReportConsmWarnChart from "./reportConsmWarnChart";
import ReportConsmGrid from "./reportConsmGrid";

const ReportConsmPresenter = (props) => {
  const { energyData, energyCount, chartWarn, selectMeain, chartMonthData, chartYearData } = props;
  return (
    <>
      <div className="uk-grid">
        <div className="uk-width-medium-4-10">
          <table className="reportEnergyTable">
            <thead>
              <th colSpan="3" style={{ color: "#369" }}>
                전기 에너지
              </th>
            </thead>
            <tbody>
              <table>
                <tr>
                  <th> 월간 발전량 </th>
                  <th> 누적 발전량</th>
                </tr>
                <tr>
                  <td scope="row">{energyData[0].totalPower}&nbsp;kWh</td>
                  <td scope="row">{energyData[0].accumPower}&nbsp;kWh</td>
                </tr>
              </table>
              <table>
                <tr>
                  <th>발전시간</th>
                  <th>누적 CO2 저감량</th>
                  <th>발전효율</th>
                </tr>
                <tr>
                  <td scope="row">{energyData[0].powerTime}&nbsp;분</td>
                  <td scope="row">{energyData[0].powerCo2}&nbsp;t</td>
                  <td scope="row">{energyData[0].powerEfic}&nbsp;%</td>
                </tr>
              </table>
            </tbody>
          </table>
        </div>
        <div className="uk-width-medium-3-10">
          <table className="reportEnergyTable">
            <thead>
              <th colSpan="3" style={{ color: "#00420b" }}>
                열 에너지
              </th>
            </thead>
            <tbody>
              <table>
                <tr>
                  <th> 월간 생산열량 </th>
                </tr>
                <tr>
                  <td scope="row">{energyData[0].totalCal}&nbsp;kWh</td>
                </tr>
              </table>
              <table>
                <tr>
                  <th>누적생산열량</th>
                </tr>
                <tr>
                  <td scope="row">{energyData[0].accumCal}&nbsp;kWh</td>
                </tr>
              </table>
            </tbody>
          </table>
        </div>
        <div className="uk-width-medium-3-10">
          <table className="reportEnergyTable">
            <thead>
              <th colSpan="3" style={{ color: "#a52f00" }}>
                ESS
              </th>
            </thead>
            <tbody>
              <table>
                <tr>
                  <th>월간 충전시간</th>
                </tr>
                <tr>
                  <td scope="row"> - &nbsp;kWh</td>
                  <td scope="row"> - &nbsp;시간</td>
                </tr>
              </table>
              <table>
                <tr>
                  <th>누적충전량</th>
                  <th>충전효율</th>
                </tr>
                <tr>
                  <td scope="row"> - &nbsp;kWh</td>
                  <td scope="row"> - &nbsp;%</td>
                </tr>
              </table>
            </tbody>
          </table>
        </div>
      </div>
      <div className="uk-grid">
        <div className="uk-width-medium-1">
          <table className="reportEnergyTable">
            <thead>
              <th colSpan="3" style={{ color: "#369" }}></th>
            </thead>
            <tbody>
              <table>
                <tr>
                  <th>전체&nbsp;{energyCount[0].totalCnt}&nbsp;개소</th>
                  <th>태양광&nbsp;{energyCount[0].solaCnt}&nbsp;개소</th>
                  <th>태양열&nbsp;{energyCount[0].heatCnt}&nbsp;개소</th>
                  <th>지열&nbsp;{energyCount[0].heathCnt}&nbsp;개소</th>
                  <th>풍력&nbsp;{energyCount[0].veloCnt}&nbsp;개소</th>
                  <th>연료전지&nbsp;{energyCount[0].fuelcellCnt}&nbsp;개소</th>
                  <th>ESS&nbsp;{energyCount[0].essCnt}&nbsp;개소</th>
                </tr>
                <tr>
                  <td scope="row">설치용량&nbsp;&nbsp;{energyCount[0].totalCapa}&nbsp;kW</td>
                  <td scope="row">{energyCount[0].solaCapa}&nbsp;kW</td>
                  <td scope="row">{energyCount[0].heatCapa}&nbsp;㎡</td>
                  <td scope="row">{energyCount[0].heathCapa}&nbsp;kW</td>
                  <td scope="row">{energyCount[0].veloCapa}&nbsp;kW</td>
                  <td scope="row">{energyCount[0].fuelcellCapa}&nbsp;kW</td>
                  <td scope="row">{energyCount[0].essCapa}&nbsp;kW</td>
                </tr>
              </table>
            </tbody>
          </table>
        </div>
      </div>
      <ReportConsmWarnChart chartWarn={chartWarn} />
      <ReportConsmGrid selectMeain={selectMeain} chartMonthData={chartMonthData} chartYearData={chartYearData} />
    </>
  );
};

export default ReportConsmPresenter;
