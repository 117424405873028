import { React, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Menu, MenuItem } from "@progress/kendo-react-layout";
import { DesktopWindows, Place, BarChart, InsertDriveFile, Dvr, ChatBubble, Settings } from "@material-ui/icons";
import { getMenuTree, getMenuRole } from "../service/menu/menuService";
import { useLocation } from "react-router";
import { getUserTypeCode } from "../service/authService";

import "./menuNavigatorBackup.css";

/**
 * 레프트 메뉴
 * 이용자의 권한별로 메뉴 표시 및 이동
 * 게스트는 이용자 정보 없음
 * @param {conn, codeList, user} param0
 * @returns
 */
const MenuNavigatorBackup = (props) => {
  const { roleMenu, getRoleMenu, setSelectMenu } = props;

  let location = useLocation();

  useEffect(() => {
    if (location.pathname !== "/") getRoleMenu(location.pathname);
  }, []);

  const get1DepthMenuHtml = (menu) => {
    let menuHtml;
    let iconClass = "material-icons";
    switch (menu.id) {
      /**
       * 사이드 메뉴바 1
       */
      case "010000":
        if (location.pathname.includes("/monitor", 0)) {
          iconClass += " material-icons-active";
        }
        menuHtml = (
          <span className="menu_icon">
            <DesktopWindows className={iconClass} />
          </span>
        );
        break;
      /**
       * 사이드 메뉴바 2
       */
      case "020000":
        if (location.pathname.includes("/location", 0)) {
          iconClass += " material-icons-active";
        }
        menuHtml = (
          <span className="menu_icon">
            <Place className={iconClass} />
          </span>
        );
        break;
      /**
       * 사이드 메뉴바 3
       */
      case "030000":
        if (location.pathname.includes("/stats", 0)) {
          iconClass += " material-icons-active";
        }
        menuHtml = (
          <span className="menu_icon">
            <BarChart className={iconClass} />
          </span>
        );
        break;
      /**
       * 사이드 메뉴바 4
       */
      case "040000":
        if (location.pathname.includes("/report", 0)) {
          iconClass += " material-icons-active";
        }
        menuHtml = (
          <span className="menu_icon">
            <InsertDriveFile className={iconClass} />
          </span>
        );
        break;
      /**
       * 사이드 메뉴바 5
       */
      case "050000":
        if (location.pathname.includes("/sys", 0)) {
          iconClass += " material-icons-active";
        }
        menuHtml = (
          <span className="menu_icon">
            <Dvr className={iconClass} />
          </span>
        );
        break;
      /**
       * 사이드 메뉴바 6
       */
      case "060000":
        if (location.pathname.includes("/board", 0)) {
          iconClass += " material-icons-active";
        }
        menuHtml = (
          <span className="menu_icon">
            <ChatBubble className={iconClass} />
          </span>
        );
        break;
      /**
       * 사이드 메뉴바 7
       */
      case "070000":
        if (location.pathname.includes("/mngt", 0)) {
          iconClass += " material-icons-active";
        }
        menuHtml = (
          <span className="menu_icon">
            <Settings className={iconClass} />
          </span>
        );
        break;

      default:
        break;
    }
    return menuHtml;
  };

  /**
   * 메뉴 네비게이터 클릭 시 동적으로 권한 있는 최하위 url로 접근
   * @param {}} menu click event
   * @returns 최하위 menu route
   */
  const conditionalMenuSelect = (menu) => {
    let url = "";
    if (menu.items) {
      url = conditionalMenuSelect(menu.items[0]);
    } else {
      return menu.data.route;
    }
    return url;
  };

  const setMenuSelectEvt = (event) => {
    if (event.item.data.authority) {
      const url = conditionalMenuSelect(event.item);
      setSelectMenu(url, roleMenu);
      props.history.push(url);
    } else alert("권한이 없습니다.");
  };

  return (
    <>
      <aside id="sidebar_main">
        <div className="menu_section">
          <Menu vertical={true} style={{}} onSelect={setMenuSelectEvt}>
            {roleMenu.map((md1) => (
              //!md1.checked ? '' :
              <MenuItem
                text={get1DepthMenuHtml(md1)}
                data={{ route: md1.menuUrl, id: md1.id, authority: md1.checked }}
                itemId={md1.id}
                id={md1.id}
                key={md1.id}
                cssStyle={{ color: "#1e8825" }}
              >
                {md1.items.map(
                  (md2) =>
                    !md2.checked || (
                      <MenuItem text={md2.term} data={{ route: md2.menuUrl, id: md2.id, authority: md2.checked }} itemId={md1.id} id={md2.id} key={md2.id}>
                        {md2.items.map(
                          (md3) =>
                            !md3.checked || (
                              <MenuItem
                                text={md3.term}
                                data={{ route: md3.menuUrl, id: md3.id, authority: md3.checked }}
                                itemId={md1.id}
                                id={md3.id}
                                key={md3.id}
                              >
                                {}
                              </MenuItem>
                            ),
                        )}
                      </MenuItem>
                    ),
                )}
              </MenuItem>
            ))}
          </Menu>
          <div className="FooterText">
            Copyright 2020.
            <br />
            Chungnam Techno park. All rights reserved.
          </div>
        </div>
      </aside>
      <div id="page_content">
        <div id="page_content_inner">{props.children}</div>
      </div>
    </>
  );
};
export default withRouter(MenuNavigatorBackup);
