//react
import { useState, useEffect } from "react";

//css
import "./alarmLog.css";
// Kendo
import kendo from "@progress/kendo-ui";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";
import DatePickerKo from "../../com/dateInputs/datePickerKo";

//component
import { getAlarmPage } from "../../../service/mngt/alarm/alarmLogService";
import { alarmLogDetail } from "./alarmLogDetail";
import useHistoryState from "./../../../com/useHistoryState";
import { getMessage } from "./../../../com/messageUtil";

// 로딩 스피너
import { BeatLoader } from "react-spinners";
import { getUser, getUserMngRgnList, isInstaller, isManager } from "../../../service/authService";
import RgnDropdown from "./../../com/dropdown/rgnDropdown";

const AlarmLog = (props) => {
  const { rgnList, conn } = props;
  const [loading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState({ results: [], total: 0 });
  const lastMonth = new Date(new Date().setDate(new Date().getDate() - 30));
  const rgnDdlData = isManager()
    ? rgnList.filter((e) => {
        return getUserMngRgnList().find((rgn) => rgn === e.rgnCode);
      })
    : rgnList;
  const [dataState, setDataState] = useHistoryState(
    {
      take: 20,
      skip: 0,
      userId: isInstaller() ? getUser().userId : "",
      userTerm: "",
      rgnCode: "",
      startDate: lastMonth,
      endDate: new Date(),
      searchBtn: false, //검색 트리거
    },
    "dataState",
  );
  const [rgnSrch, setRgnSrch] = useHistoryState({ rgnTermSimple: "전체", rgnCode: "" }, "rgnSrch");

  useEffect(() => {
    getGridData();
  }, [dataState.skip, dataState.take, dataState.searchBtn]);

  const getGridData = () => {
    setLoading(true);
    setDataState({ ...dataState, searchBtn: false });
    getAlarmPage(conn, dataState)
      .then((response) => {
        setDataSource(response);
        setLoading(false);
      })
      .catch((error) => {
        getMessage(error);
        setLoading(false);
      });
  };

  // 그리드 페이징 처리
  const pageChangeEvt = (e) => {
    const { take, skip } = e.page;
    setDataState({ ...dataState, skip: skip, take: take });
  };

  /* 디테일 뷰 보기 */
  const expandChangeEvt = (event) => {
    let newData = dataSource.results.map((item) => {
      if (item.alarmNo === event.dataItem.alarmNo) {
        item.expanded = !event.dataItem.expanded;
      }

      return item;
    });
    setDataSource({ ...dataSource, results: newData });
  };

  const rgnDdlChngEvt = (item) => {
    const { value } = item;
    setRgnSrch(value);
    setDataState({ ...dataState, rgnCode: value.rgnCode });
  };

  const alLogSrchChngEvt = (item) => {
    const { value } = item;
    setDataState({ ...dataState, userTerm: value });
  };

  const appKeyPressEvt = (e) => {
    if (e.key === "Enter") {
      getGridData();
    }
  };

  const startDateChngEvt = (item) => {
    setDataState({ ...dataState, startDate: item });
  };

  const endDateChngEvt = (item) => {
    setDataState({ ...dataState, endDate: item });
  };

  const srchBtnClkEvt = () => {
    setDataState({ ...dataState, skip: 0, searchBtn: true });
  };

  const sendDtmCell = (item) => {
    const { dataItem } = item;
    return <td>{dataItem.sendDtm ? dataItem.sendDtm : "-"}</td>;
  };
  const sendValCell = (item) => {
    const { dataItem } = item;
    let result = "-";
    if (dataItem.sendVal) {
      result = dataItem.sendVal;
    }
    return <td>{result}</td>;
  };
  const sendPhoneCell = (item) => {
    const { dataItem } = item;
    return <td>{dataItem.sendPhone ? dataItem.sendPhone : "-"}</td>;
  };
  const receivePhoneCell = (item) => {
    const { dataItem } = item;
    return <td>{dataItem.receivePhone ? dataItem.receivePhone : "-"}</td>;
  };

  return (
    <div className="alLogDiv">
      <div className="alLogSrchDiv">
        <span className="alLogSrchLeft">
          <span>지역검색</span>
          <RgnDropdown className="alLogSrchDdl" rgnList={rgnList} search={dataState} objKey={"rgn"} setSearch={setDataState} onChange={rgnDdlChngEvt} />
          {/* <DropDownList
            className="alLogSrchDdl"
            data={rgnDdlData}
            textField="rgnTermSimple"
            key="rgnCode"
            onChange={rgnDdlChngEvt}
            value={rgnSrch}
            disabled={isInstaller()}
            defaultItem={{ rgnTermSimple: "전체", rgnCode: "" }}
          ></DropDownList>
          &nbsp;&nbsp; */}
          {!isInstaller() ? <span>수용가명/CID</span> : <span>CID</span>}
          &nbsp;
          <Input
            className="alLogSrchInp"
            placeholder={!isInstaller() ? "수용가명/CID" : "CID"}
            onChange={alLogSrchChngEvt}
            value={dataState.userTerm}
            onKeyPress={appKeyPressEvt}
          ></Input>
        </span>
        <span className="alLogSrchRight">
          <span>조회 기간</span>
          <DatePickerKo className="alLogDtStrt" value={dataState.startDate} setDtm={startDateChngEvt} max={dataState.endDate}></DatePickerKo>
          {"~"}
          <DatePickerKo className="alLogDtEnd" value={dataState.endDate} setDtm={endDateChngEvt} min={dataState.startDate} max={new Date()}></DatePickerKo>
          <Button icon="search" style={{ backgroundColor: "white", paddingLeft: "20px", paddingRight: "20px" }} onClick={srchBtnClkEvt}></Button>
        </span>
      </div>
      <LocalizationProvider language="ko-KR">
        <IntlProvider locale="ko">
          <Grid
            className="alLogGrid"
            style={loading ? { opacity: 0.6, pointerEvents: "none" } : null}
            data={dataSource.results}
            detail={alarmLogDetail}
            expandField="expanded"
            skip={dataState.skip}
            take={dataState.take}
            total={dataSource.total}
            pageable={{
              type: "numeric",
              pageSizes: [5, 10, 15, 20, 50],
              previousNext: true,
              buttonCount: 5,
            }}
            onExpandChange={expandChangeEvt}
            onRowClick={expandChangeEvt}
            onPageChange={pageChangeEvt}
          >
            <GridColumn field="alarmNo" title="연번" width={100}></GridColumn>
            <GridColumn field="ocurDtm" title="발생시간"></GridColumn>
            <GridColumn field="rgnTermSimple" title="지역"></GridColumn>
            <GridColumn field="userTerm" title="수용가 이름"></GridColumn>
            <GridColumn field="cid" title="CID"></GridColumn>
            <GridColumn field="receivePhone" title="수신번호" cell={receivePhoneCell}></GridColumn>
            <GridColumn field="sendPhone" title="발신자" cell={sendPhoneCell}></GridColumn>
            <GridColumn field="sendDtm" title="전송시간" cell={sendDtmCell}></GridColumn>
            <GridColumn field="sendVal" title="전송상태" cell={sendValCell}></GridColumn>
          </Grid>
        </IntlProvider>
      </LocalizationProvider>
      {loading && (
        <p
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
        >
          <BeatLoader loading={true} size={24} color="#1e88e5" />
        </p>
      )}
    </div>
  );
};

export default AlarmLog;
