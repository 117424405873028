import { DropDownList } from "@progress/kendo-react-dropdowns";
import React, { useState, useEffect } from "react";
import "./custom_filter.css";

// 설비 상태 코드
// 7001	7	1	준비중
// 7002	7	2	정상
// 7003	7	3	통신에러
// 7004	7	4	미작동
// 7005	7	5	제거
// 7006	7	6	경고
// 7007	7	7	고장
// 7008	7	8	교체

// 사용법
// 그리드의 컬럼의 filterCell 에 해당
// 1. 해당 컴포넌트를 임포트 한다. import Custom_filter_status_dropdown from '../com/filter/custom_filter_status_dropdown';
// 2. 그리드의 컬럼에 설정한다. /* <Column field="meainStatTerm" title="설비상태"filterCell = {Custom_filter_status_dropdown} /> */

function Custom_filter_status_dropdown(props) {
  let [dataSource, setDataSource] = useState([]);
  const defaultItem = { codeNo: 7000, codeVal: "상태" };
  const getStatusList = () => {
    // 정적 DataSource 제작
    const obj = [
      // { codeNo : 7000, codeVal : "상태" },
      { codeNo: 7002, codeVal: "정상" },
      { codeNo: 7004, codeVal: "미작동" },
      { codeNo: 7001, codeVal: "준비중" },
      { codeNo: 7006, codeVal: "경고" },
      { codeNo: 7007, codeVal: "고장" },
      { codeNo: 7005, codeVal: "제거" },
      { codeNo: 7008, codeVal: "교체" },
    ];
    setDataSource(obj);
  };

  // 드롭다운 값 변경 시 이벤트 발생
  const onChange = (event) => {
    // default 값 선택 시 빈 값 설정
    let onChangeOperator = "";
    onChangeOperator = "eq";
    props.onChange({
      value: event.target.value.codeNo,
      operator: onChangeOperator,
      syntheticEvent: event.syntheticEvent,
    });
  };

  // 드롭다운 아이템 랜더링
  const itemRender = (li, itemProps) => {
    // 이미지 설정
    const index = itemProps.index;
    let imgsrc = "";
    if (index === 0) {
      imgsrc = "/images/icon_gre.png";
    } else if (index === 1) {
      imgsrc = "/images/icon_gray.png";
    } else if (index === 2) {
      imgsrc = "/images/icon_bl.png";
    } else if (index === 3) {
      imgsrc = "/images/icon_yl.png";
    } else if (index === 4) {
      imgsrc = "/images/icon_rd.png";
    } else if (index === 5) {
      imgsrc = "/images/icon_X.png";
    } else if (index === 6) {
      imgsrc = "/images/icon_A.png";
    }

    // 랜더 할 부분 정의
    const itemChildren = (
      <span key={index} style={{ color: "#00F" }}>
        <img src={imgsrc} style={{ width: "13px" }}></img> {li.props.children}{" "}
      </span>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };

  useEffect(() => {
    getStatusList();
  }, []);

  return (
    <label for="state">
      <DropDownList
        data={dataSource}
        textField="codeVal"
        dataItemKey="codeNo"
        onChange={onChange}
        defaultItem={defaultItem}
        // itemRender = {itemRender}
        className="CustomFilterInputStatus"
      />
    </label>
  );
}

export default Custom_filter_status_dropdown;
