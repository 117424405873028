// React
import React, { useState, useEffect, useCallback, useMemo } from "react";
import log from "../../../com/log";
import { parseDate } from "../../../com/dateUtil";
import { downloadExcel } from "../../../com/downloadExcel";
import { getIotKt, getIotKtLog, setIotKt } from "../../../service/meain/iotMakers/iotKtService";
import iotktExcelSample from "../../../files/sample_iotmakers.xlsx";
import { getRtu } from "../../../service/mngt/rtuEnte/rtuService";
import { BeatLoader } from "react-spinners";

// Kendo
import kendo from "@progress/kendo-ui";
import { Button } from "@progress/kendo-react-buttons";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";
import { IntlProvider, LocalizationProvider, loadMessages } from "@progress/kendo-react-intl";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Upload } from "@progress/kendo-react-upload";

// Css
import "./iotkt.css";
import styles from "./iotkt.module.css";
import GridColumnInputFilter from "../../com/gridFilter/gridColumnInputFilter";
import { getUser, isRtuUser } from "../../../service/authService";
import { getMessage } from "./../../../com/messageUtil";

function Iotkt({ conn }) {
  const userData = getUser();

  // 로딩 스피너
  const [loading, setLoading] = useState(true);
  // 엑셀 로딩 스피너
  const [excelLoading, setExcelLoading] = useState(false);

  // const iotSaveStats = ['등록', '실패'];
  const [dataSource, setDataSource] = useState({
    dataList: [],
    total: 0,
  });
  const [dataState, setDataState] = useState({
    take: 20,
    skip: 0,
    total: 0,
    filter: { nbDevid: "", rtuEnteCode: "", stateCode: "" },
    sort: [],
  });
  const [uploadState, setUploadState] = useState({
    files: [],
    events: [],
    filePreviews: {},
    afterStateChange: [],
    response: [],
    visible: false,
  });
  const [rtuCompany, setRtuCompany] = useState([]);
  const [iotInfo, setIotInfo] = useState({
    rtuCompany: "",
    imei: "",
    hashKey: "",
    rtuEnte: { rtuEnteCode: "", rtuEnteTerm: "전체" },
  });

  const [filterDropValue, setFilterDropValue] = useState({
    rtuEnte: { rtuEnteCode: "", rtuEnteTerm: "전체" },
    state: { stateCode: "", stateTerm: "전체" },
  });

  // 데이터 조회 함수
  const getIoTKtList = () => {
    getIotKt(conn, dataState)
      .then((response) => {
        const { results, total } = response;
        setDataSource((dataSource) => {
          const item = { ...dataSource };
          item.dataList = results;
          item.total = total;
          return item;
        });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        log(`IotKt.useEffect.getIotKt error : ${err}`);
      });
  };

  // RTU 업체 리스트 Load
  const getRtuList = () => {
    getRtu(conn)
      .then((response) => {
        setRtuCompany(response);
      })
      .catch((err) => {
        log(`IotKt.useEffect.getRtu error : ${err}`);
      });
  };

  function loginRtu() {
    if (userData.userTypeCode == 2007) {
      setIotInfo({
        ...iotInfo,
        rtuCompany: userData.userRtuEnteCode,
        rtuEnte: {
          rtuEnteCode: userData.userRtuEnteCode,
          rtuEnteTerm: userData.userTerm,
        },
      });

      setFilterDropValue({
        ...filterDropValue,
        rtuEnte: {
          rtuEnteCode: userData.userRtuEnteCode,
          rtuEnteTerm: userData.userTerm,
        },
      });
    }
  }

  useEffect(() => {
    getRtuList();
    getIoTKtList();

    loginRtu();
  }, []);

  // 그리드 페이징 처리
  const handlePageChange = useCallback(
    (e) => {
      const { take, skip } = e.page;
      dataState.skip = skip;
      dataState.take = take;
      getIoTKtList();
    },
    [dataSource, dataState],
  );

  // 그리드 필터링 처리
  const handleFilterChange = useCallback(
    (e) => {
      const { filter } = e;
      // 필터 값 설정
      if (filter !== null) {
        if (filter.filters[0].field === "stateTerm") {
          dataState.filter.stateCode = filter.filters[0].value.stateCode;
        } else if (filter.filters[0].field === "rtuEnteTerm") {
          dataState.filter.rtuEnteCode = filter.filters[0].value.rtuEnteCode;
        } else if (filter.filters[0].field === "nbDevid") {
          dataState.filter.nbDevid = filter.filters[0].value;
        }
      }
      dataState.skip = 0;
      dataState.take = 20;
      getIoTKtList();
    },
    [dataSource, dataState],
  );

  // IoTMakers 정보입력 - RTU 업체
  const handleRtuCompanyChange = useCallback(
    (e) => {
      setIotInfo({
        ...iotInfo,
        rtuCompany: e.target.value.rtuEnteCode,
        rtuEnte: {
          rtuEnteCode: e.value.rtuEnteCode,
          rtuEnteTerm: e.value.rtuEnteTerm,
        },
      });
    },
    [iotInfo],
  );

  // IotMakers 정보입력 - IEMI, HashKey
  const handleInputsChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      setIotInfo({ ...iotInfo, [name]: value });
    },
    [iotInfo],
  );

  //FIXME : IMEI와 Hashkey 로직 생성되면 진행
  // IotMakers 정보입력 - 등록 버튼
  const handleSubmitBtn = useCallback(
    (e) => {
      e.preventDefault();

      console.clear();
      log(iotInfo, "iotInfo");

      const saveData = {
        nbDevid: iotInfo.imei,
        rtuEnteCode: iotInfo.rtuCompany,
      };

      const saveArray = [];
      saveArray.push(saveData);

      setIotKt(conn, saveArray)
        .then((response) => {
          alert(response.resultMessage);
          iotInfo.rtuCompany = "";
          iotInfo.imei = "";
          iotInfo.hashKey = "";
          iotInfo.rtuEnte.rtuEnteCode = "";
          getIoTKtList();
        })
        .catch((err) => {
          log(`IotKt.handleSubmitBtn.setIotKt error: ${err}`);
        });
    },
    [iotInfo],
  );

  // 엑셀다운
  const handleExcelDown = useCallback((e) => {
    e.preventDefault();
    const url = "/api/v2.2/iot/kt";

    const search = {
      nbDevid: dataState.filter.nbDevid,
      stateCode: dataState.filter.stateCode,
      rtuEnteCode: dataState.filter.rtuEnteCode,
    };

    const data = search;
    const fileName = "IoTMakers등록_" + parseDate("yyyy-mm-dd") + ".xlsx";
    downloadExcel(data, fileName, url, setExcelLoading);
  }, []);

  // 엑셀 업로드 모달폼
  const handleExcelFormControl = useCallback(
    (e) => {
      setUploadState({
        ...uploadState,
        files: [],
        events: [],
        filePreviews: {},
        afterStateChange: [],
        response: [],
        visible: !uploadState.visible,
      });
    },
    [uploadState],
  );

  // 엑셀 업로드 헤더 설정
  const handleUploadHeaders = useCallback((e) => (e.headers["X-AUTH-TOKEN"] = localStorage.getItem("atk")));

  // 엑셀 파일 추가 부분
  const onAdd = useCallback(
    ({ target, newState, affectedFiles }) => {
      const afterStateChange = () => {
        affectedFiles
          .filter((file) => !file.validationErrors)
          .forEach((file) => {
            const reader = new FileReader();
            reader.onloadend = (ev) => {
              setUploadState({
                ...uploadState,
                filePreviews: {
                  ...uploadState.filePreviews,
                  [file.uid]: ev.target.result,
                },
              });
            };
            reader.readAsDataURL(file.getRawFile());
          });
      };

      setUploadState({
        ...uploadState,
        files: newState,
        afterStateChange,
      });
    },
    [uploadState],
  );

  // 엑셀 파일 추가 후 데이터 추출 부분
  const onStatusChange = useCallback(({ target, newState, affectedFiles, response }) => {
    setUploadState({ ...uploadState, files: newState, response });
  });

  //FIXME : IMEI와 Hashkey 로직 생성되면 진행
  // 엑셀 업로드 버튼
  const handleExcelUpload = useCallback(
    (e) => {
      e.preventDefault();
      const uploadExcelData = uploadState.response.response.data;
      setIotKt(conn, uploadExcelData)
        .then(() => {
          kendo.alert("엑셀 저장을 완료하였습니다.");
          setUploadState({ ...uploadState, visible: !uploadState.visible });
          getIoTKtList();
        })
        .catch((error) => {
          kendo.alert(getMessage(error.errCode));
        });
    },
    [uploadState, dataSource, dataState],
  );

  const closeUpload = () => {
    setUploadState({ ...uploadState, visible: false });
  };

  // RTU업체 필터CELL 드롭다운
  const handleIotRtuCompany = (props) => {
    const filterOnchage = (e) => {
      props.onChange({
        value: e.value,
        operator: e.value ? "eq" : "",
        syntheticEvent: e.syntheticEvent,
      });
      setFilterDropValue((filterDropValue) => {
        const item = { ...filterDropValue };
        item.rtuEnte = e.value;
        return item;
      });
    };

    return (
      <div className="k-filtercell">
        <DropDownList
          data={rtuCompany}
          onChange={filterOnchage}
          defaultItem={{ rtuEnteCode: "", rtuEnteTerm: "전체" }}
          disabled={isRtuUser() === true ? true : false}
          className="CustomFilterDropDown"
          textField="rtuEnteTerm"
          dataItemKey="rtuEnteCode"
          name="rtuEnte"
          value={filterDropValue.rtuEnte}
        />
      </div>
    );
  };

  // IoTMakers등록상태 필터CELL 드롭다운
  const handleIotSaveStats = (props) => {
    const filterOnchage = (e) => {
      props.onChange({
        value: e.value,
        operator: e.value ? "eq" : "",
        syntheticEvent: e.syntheticEvent,
      });
      setFilterDropValue((filterDropValue) => {
        const item = { ...filterDropValue };
        item.state = e.value;
        return item;
      });
    };

    return (
      <div className="k-filtercell">
        <DropDownList
          data={[
            { stateCode: 200, stateTerm: "등록" },
            { stateCode: 100, stateTerm: "실패" },
          ]}
          onChange={filterOnchage}
          defaultItem={{ stateCode: "", stateTerm: "전체" }}
          className="CustomFilterDropDown"
          textField="stateTerm"
          dataItemKey="stateCode"
          name="stateTerm"
          value={filterDropValue.state}
        />
      </div>
    );
  };

  return (
    <>
      <div className={["iotktBody", excelLoading ? styles.iotKtLogG : ""].join(" ")}>
        <dlv className="leftForm">
          <h3>
            IMEI 목록
            <Button onClick={handleExcelDown} style={{ float: "right" }}>
              <img className="exelSDown" src="/images/Icon_ExelSDown.svg" alt="" />
              &nbsp; 엑셀저장
            </Button>
          </h3>
          <div className="iotktGridCate"></div>
          <LocalizationProvider language="ko-KR">
            <IntlProvider locale="ko">
              <Grid
                className={loading ? styles.iotKtLogG : styles.iotktGrid}
                data={dataSource.dataList}
                total={dataSource.total}
                skip={dataState.skip}
                take={dataState.take}
                // {...dataState}
                pageable={{ buttonCount: 5 }}
                filterable
                onPageChange={handlePageChange}
                onFilterChange={handleFilterChange}
              >
                <GridColumn title="IMEI" field="nbDevid" filterCell={GridColumnInputFilter} />
                <GridColumn title="RTU업체" field="rtuEnteTerm" filterCell={handleIotRtuCompany} />
                <GridColumn title="IoTMakers등록상태" field="stateTerm" filterCell={handleIotSaveStats} />
                <GridNoRecords>데이터가 존재하지 않습니다.</GridNoRecords>
              </Grid>
            </IntlProvider>
          </LocalizationProvider>
          {loading && (
            <p
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
              }}
            >
              <BeatLoader loading={true} size={24} color="#1e88e5" />
            </p>
          )}
        </dlv>
        <div className="rightForm">
          <h3>IoTMakers 정보입력</h3>
          <div>
            <p>·RTU 업체</p>
            <DropDownList
              className={styles.rightFormDropDownList}
              // data={rtuCompany.map(rtuItem => rtuItem.rtuEnteTerm)}
              data={rtuCompany}
              textField="rtuEnteTerm"
              dataItemKey="rtuEnteCode"
              defaultValue={{ rtuEnteCode: "", rtuEnteTerm: "RTU 업체 선택" }}
              // defaultValue='RTU 업체 선택'
              disabled={isRtuUser() === true ? true : false}
              onChange={handleRtuCompanyChange}
              value={
                iotInfo.rtuEnte.rtuEnteCode
                  ? { rtuEnteCode: iotInfo.rtuEnte.rtuEnteCode, rtuEnteTerm: iotInfo.rtuEnte.rtuEnteTerm }
                  : { rtuEnteTerm: "전체", rtuEnteCode: "" }
              }
            />
          </div>
          <div>
            <p>·IMEI</p>
            <Input name="imei" className={styles.rightFormInputs} onChange={handleInputsChange} value={iotInfo.imei ? iotInfo.imei : ""} />
          </div>
          <div>
            <p>·HashKey</p>
            <Input name="hashKey" className={styles.rightFormInputs} onChange={handleInputsChange} value={iotInfo.hashKey ? iotInfo.hashKey : ""} />
          </div>
          <div>
            <div>
              <Button onClick={handleSubmitBtn} icon="check">
                등록
              </Button>
              <Button onClick={handleExcelFormControl}>
                <img src="/images/icon_Up.png" alt="" />
                &nbsp; 엑셀파일 가져오기
              </Button>
              <Button>
                <img src="/images/icon_down.png" alt="" />
                &nbsp;
                <a className="managerExcelTxt" href={iotktExcelSample} download="sample_iotmakers.xlsx">
                  엑셀샘플 다운로드
                </a>
              </Button>
            </div>
            <p>※ Hash Key는 저장되지 않습니다.</p>
            <p>※ 정상코드 : 200(등록), 341(기등록) / 에러코드 : 기타</p>
          </div>
        </div>
      </div>
      {uploadState.visible && (
        <Dialog title={"엑셀파일 가져오기"} onClose={handleExcelFormControl} height={250} width={600}>
          <div>
            <LocalizationProvider language="ko-KR">
              <IntlProvider locale="ko">
                <Upload
                  className={styles.iotktUpload}
                  onBeforeUpload={handleUploadHeaders}
                  batch={false}
                  multiple={false}
                  files={uploadState.files}
                  restrictions={{ allowedExtensions: [".xlsx"] }}
                  withCredentials={true}
                  saveUrl={"/api/v2.2/iot/kt/xlsx"}
                  onAdd={onAdd}
                  onStatusChange={onStatusChange}
                />
              </IntlProvider>
            </LocalizationProvider>
            <div style={{ float: "right" }}>
              <Button icon="save" style={{ margin: "5px" }} onClick={handleExcelUpload}>
                저장
              </Button>
              <Button icon="close" style={{ margin: "5px" }} onClick={closeUpload}>
                취소
              </Button>
            </div>
          </div>
        </Dialog>
      )}
      {excelLoading && (
        <p
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
        >
          <BeatLoader loading={true} size={24} color="#1e88e5" />
        </p>
      )}
    </>
  );
}

export default Iotkt;
