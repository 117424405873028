import React from "react";
import styles from "./areaEnergy.module.css";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import CountUp from "react-countup";
// 로딩 스피너
import { BeatLoader } from "react-spinners";

const AreaEnergyMobPrst = ({
  selected,
  handleSelect,
  areaGridLoading,
  setCityClickEvt,
  cityTerm,
  state,
  setRgnClickEvt,
  setColumnNumberFormat,
  setColumnSum,
  setOperClickEvt,
  setOperCntClickEvt,
  getEnsoTerm,
}) => {
  return (
    <>
      <div className={areaGridLoading ? styles.areaTable : ""}>
        <div className={styles.TitleHistory}>
          <a href="#" onClick={setCityClickEvt} className={styles.rgnTerm}>
            &nbsp;&nbsp;&nbsp;&nbsp;{cityTerm}
          </a>
          {state.rgnTerm == "" ? (
            ""
          ) : (
            <span id="dongTerm" className={styles.dongTerm}>
              &gt; {state.rgnTerm}
            </span>
          )}
        </div>
        <TabStrip selected={selected} onSelect={handleSelect}>
          <TabStripTab title="태양광">
            <div className="uk-grid">
              <div className="uk-width-medium-1-1">
                <div className="Table-scr">
                  <Grid filterable={false} sortable={false} pageable={false} data={state.areaEnergyList} className="areaEnergyGrid" onRowClick={setRgnClickEvt}>
                    <Column className={styles.areaTerm} field="areaTerm" title="지 역" footerCell={() => <td>총계</td>} />
                    <Column title="태 양 광">
                      <Column field="solaCnt" title="개소" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                      <Column field="solaCapa" title="설비용량(kW)" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                      <Column field="solaDayPower" title="발전량(kWh)" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                    </Column>
                  </Grid>
                </div>
              </div>
            </div>
          </TabStripTab>
          <TabStripTab title="태양열">
            <div className="uk-grid">
              <div className="uk-width-medium-1-1">
                <div className="Table-scr">
                  <Grid filterable={false} sortable={false} pageable={false} data={state.areaEnergyList} className="areaEnergyGrid" onRowClick={setRgnClickEvt}>
                    <Column className={styles.areaTerm} field="areaTerm" title="지 역" footerCell={() => <td>총계</td>} />

                    <Column title="태 양 열">
                      <Column field="heatCnt" title="개소" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                      <Column field="heatCapa" title="설비용량(kW)" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                      <Column field="heatDayUse" title="사용량(kWh)" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                    </Column>
                  </Grid>
                </div>
              </div>
            </div>
          </TabStripTab>
          <TabStripTab title="지열">
            <div className="uk-grid">
              <div className="uk-width-medium-1-1">
                <div className="Table-scr">
                  <Grid filterable={false} sortable={false} pageable={false} data={state.areaEnergyList} className="areaEnergyGrid" onRowClick={setRgnClickEvt}>
                    <Column className={styles.areaTerm} field="areaTerm" title="지 역" footerCell={() => <td>총계</td>} />

                    <Column title="지 열">
                      <Column field="heathCnt" title="개소" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                      <Column field="heathCapa" title="설비용량(kW)" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                      <Column field="heathDayUse" title="사용량(kWh)" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                    </Column>
                  </Grid>
                </div>
              </div>
            </div>
          </TabStripTab>
          <TabStripTab title="풍력">
            <div className="uk-grid">
              <div className="uk-width-medium-1-1">
                <div className="Table-scr">
                  <Grid filterable={false} sortable={false} pageable={false} data={state.areaEnergyList} className="areaEnergyGrid" onRowClick={setRgnClickEvt}>
                    <Column className={styles.areaTerm} field="areaTerm" title="지 역" footerCell={() => <td>총계</td>} />

                    <Column title="풍 력">
                      <Column field="veloCnt" title="개소" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                      <Column field="veloCapa" title="설비용량(kW)" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                      <Column field="veloDayPower" title="발전량(kWh)" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                    </Column>
                  </Grid>
                </div>
              </div>
            </div>
          </TabStripTab>
          <TabStripTab title="연료전지">
            <div className="uk-grid">
              <div className="uk-width-medium-1-1">
                <div className="Table-scr">
                  <Grid filterable={false} sortable={false} pageable={false} data={state.areaEnergyList} className="areaEnergyGrid" onRowClick={setRgnClickEvt}>
                    <Column className={styles.areaTerm} field="areaTerm" title="지 역" footerCell={() => <td>총계</td>} />

                    <Column title="연료전지">
                      <Column field="fuelcellCnt" title="개소" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                      <Column field="fuelcellCapa" title="설비용량(kW)" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                      <Column field="fuelcellDayPower" title="발전량(kWh)" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                    </Column>
                  </Grid>
                </div>
              </div>
            </div>
          </TabStripTab>
          <TabStripTab title="ESS">
            <div className="uk-grid">
              <div className="uk-width-medium-1-1">
                <div className="Table-scr">
                  <Grid filterable={false} sortable={false} pageable={false} data={state.areaEnergyList} className="areaEnergyGrid" onRowClick={setRgnClickEvt}>
                    <Column className={styles.areaTerm} field="areaTerm" title="지 역" footerCell={() => <td>총계</td>} />

                    <Column title="ESS">
                      <Column field="essCnt" title="개소" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                      <Column field="essCapa" title="설비용량(kW)" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                      <Column field="essCharge" title="충전량(kWh)" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                      <Column field="essDecahrge" title="방전량(kWh)" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                    </Column>
                  </Grid>
                </div>
              </div>
            </div>
          </TabStripTab>
        </TabStrip>
        <div className="uk-grid">
          <div className="uk-width-medium-1-6">
            <div className="md-card">
              <div className="md-card-content">
                <div className="md-card-toolbar">
                  <h3 className="md-card-toolbar-heading-text">
                    <span className={styles.rgnTerm}>{state.rgnTerm == "" ? cityTerm : state.rgnTerm}</span> 전기에너지
                  </h3>
                </div>
                <table className="Ch-TableB">
                  <tbody>
                    <tr>
                      <th>금일발전량</th>
                      <td className={styles.totPowerNum}>
                        <CountUp end={state.totalEnergy.dayPower} separator="," duration={1.5} />
                      </td>
                      <td>kWh</td>
                    </tr>
                    <tr>
                      <th>
                        금일CO<sub>2</sub> 저감량
                      </th>
                      <td className={styles.totPowerNum}>
                        <CountUp end={state.totalEnergy.dayPowerCo2} separator="," duration={1.5} />
                      </td>
                      <td>
                        tCO<sub>2</sub>
                      </td>
                    </tr>
                    <tr>
                      <th>설비용량</th>
                      <td className={styles.totPowerNum}>
                        <CountUp end={state.totalEnergy.powerCapa} separator="," duration={1.5} />
                      </td>
                      <td>kW</td>
                    </tr>
                    <tr>
                      <th>누적발전량</th>
                      <td className={styles.totPowerNum}>
                        <CountUp end={state.totalEnergy.accumPower / 1000} separator="," duration={1.5} />
                      </td>
                      <td>MWh</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="uk-width-medium-1-6">
            <div className="md-card">
              <div className="md-card-content">
                <div className="md-card-toolbar">
                  <h3 className="md-card-toolbar-heading-text">
                    <span className={styles.rgnTerm}>{state.rgnTerm == "" ? cityTerm : state.rgnTerm}</span> 열에너지
                  </h3>
                </div>
                <table className="Ch-TableC">
                  <tbody>
                    <tr>
                      <th>금일사용량</th>
                      <td className="textcoB">
                        <CountUp end={state.totalEnergy.dayUse} separator="," duration={1.5} />
                      </td>
                      <td>kWh</td>
                    </tr>
                    <tr>
                      <th>
                        금일CO<sub>2</sub> 저감량
                      </th>
                      <td className="textcoB">
                        <CountUp end={state.totalEnergy.dayUseCo2} separator="," duration={1.5} />
                      </td>
                      <td>
                        tCO<sub>2</sub>
                      </td>
                    </tr>
                    <tr>
                      <th>설비용량 태양열</th>
                      <td className="textcoB">
                        <CountUp end={state.totalEnergy.heatCapa} separator="," duration={1.5} />
                      </td>
                      <td>
                        km²<sup></sup>
                      </td>
                    </tr>
                    <tr>
                      <th>설비용량 지열</th>
                      <td className="textcoB">
                        <CountUp end={state.totalEnergy.heathCapa} separator="," duration={1.5} />
                      </td>
                      <td>kW</td>
                    </tr>
                    <tr>
                      <th>누적사용량</th>
                      <td className="textcoB">
                        <CountUp end={state.totalEnergy.accumUse / 1000} separator="," duration={1.5} />
                      </td>
                      <td>MWh</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="uk-width-medium-2-3">
            <div className="md-card">
              <div className="md-card-content">
                <div className="md-card-toolbar">
                  <h3 className="md-card-toolbar-heading-text">
                    {" "}
                    <a onClick={setOperClickEvt}>
                      {" "}
                      <span className={styles.rgnTerm}>{state.rgnTerm == "" ? cityTerm : state.rgnTerm}</span> 운영현황{" "}
                    </a>{" "}
                  </h3>
                </div>
                <div className={styles.meainStatCntBox} onClick={setOperCntClickEvt}>
                  {state.meatinStatCnt.map((ensoCnt, index) => {
                    const totalCnt = ensoCnt.normalCnt + ensoCnt.unOperCnt + ensoCnt.readyCnt + ensoCnt.waringCnt + ensoCnt.errorCnt;
                    return (
                      <table className={styles.meainStatCnt} key={index}>
                        <thead>
                          <tr className="textcoB">
                            <th colSpan="2" className={`${ensoCnt.ensoTypeCode} ${7000} ${styles.meainStatCntEnso}`}>
                              {getEnsoTerm(ensoCnt.ensoTypeCode)}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th className={`${ensoCnt.ensoTypeCode} ${7002} ${styles.meainStatCntType}`}>정&nbsp;&nbsp;&nbsp;&nbsp;상</th>
                            <td className={`${ensoCnt.ensoTypeCode} ${7002} ${styles.colorG} ${styles.meainStatCntVal}`}>
                              <CountUp className={`${ensoCnt.ensoTypeCode} ${7002}`} end={ensoCnt.normalCnt} separator="," duration={1.5} />
                              <b className={`${ensoCnt.ensoTypeCode} ${7002} ${styles.meainStatCntTerm}`}>개소</b>
                            </td>
                          </tr>
                          <tr>
                            <th className={`${ensoCnt.ensoTypeCode} ${7001} ${styles.meainStatCntType}`}>준비중</th>
                            <td className={` ${ensoCnt.ensoTypeCode} ${7001} ${styles.colorG} ${styles.meainStatCntVal}`}>
                              <CountUp className={`${ensoCnt.ensoTypeCode} ${7001}`} end={ensoCnt.readyCnt} separator="," duration={1.5} />
                              <b className={`${ensoCnt.ensoTypeCode} ${7001} ${styles.meainStatCntTerm}`}>개소</b>
                            </td>
                          </tr>
                          <tr>
                            <th className={`${ensoCnt.ensoTypeCode} ${7006} ${styles.meainStatCntType}`}>경&nbsp;&nbsp;&nbsp;&nbsp;고</th>
                            <td className={`${ensoCnt.ensoTypeCode} ${7006} ${styles.colorG} ${styles.meainStatCntVal}`}>
                              <CountUp className={`${ensoCnt.ensoTypeCode} ${7006}`} end={ensoCnt.waringCnt} separator="," duration={1.5} />
                              <b className={`${ensoCnt.ensoTypeCode} ${7006} ${styles.meainStatCntTerm}`}>개소</b>
                            </td>
                          </tr>
                          <tr>
                            <th className={`${ensoCnt.ensoTypeCode} ${7004} ${styles.meainStatCntType}`}>미작동</th>
                            <td className={`${ensoCnt.ensoTypeCode} ${7004} ${styles.colorG} ${styles.meainStatCntVal}`}>
                              <CountUp className={`${ensoCnt.ensoTypeCode} ${7004}`} end={ensoCnt.unOperCnt} separator="," duration={1.5} />
                              <b className={`${ensoCnt.ensoTypeCode} ${7004} ${styles.meainStatCntTerm}`}>개소</b>
                            </td>
                          </tr>
                          <tr>
                            <th className={` ${ensoCnt.ensoTypeCode} ${7007} ${styles.meainStatCntType}`}>고&nbsp;&nbsp;&nbsp;&nbsp;장</th>
                            <td className={`${ensoCnt.ensoTypeCode} ${7007} ${styles.colorG} ${styles.meainStatCntVal}`}>
                              <CountUp className={`${ensoCnt.ensoTypeCode} ${7007}`} end={ensoCnt.errorCnt} separator="," duration={1.5} />
                              <b className={`${ensoCnt.ensoTypeCode} ${7007} ${styles.meainStatCntTerm}`}>개소</b>
                            </td>
                          </tr>
                          <tr>
                            <th className={`${ensoCnt.ensoTypeCode} ${7000} ${styles.meainStatCntType}`}>총개소</th>
                            <td className={`${ensoCnt.ensoTypeCode} ${7000} ${styles.colorG} ${styles.meainStatCntVal}`}>
                              <CountUp className={`${ensoCnt.ensoTypeCode} ${7000}`} end={totalCnt} separator="," duration={1.5} />
                              <b className={`${ensoCnt.ensoTypeCode} ${7000} ${styles.meainStatCntTerm}`}>개소</b>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        {areaGridLoading && (
          <p className={styles.areaTableLoading}>
            <BeatLoader loading={true} size={24} color="#1e88e5" />
          </p>
        )}
      </div>
    </>
  );
};

export default AreaEnergyMobPrst;
