import { numFormat, roundNum } from "../../../com/dataUtil";

// ⋅ Top Browsers (pageview)
const MobileDeviceSessionChart = ({Highcharts, HighchartsReact ,mobileDeviceChartData }) => {

  // 차트 옵션
  const chartConfig = {
    chart: {
      backgroundColor: '#ffffff',
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
      marginTop: 0,
      spacingTop: 0,
      height: 370,
      width: 753
    },
    noData: {
      style:{
        color: '#444'
      }
    },
    title: {
      text: null
    },
    credits: {
      enabled: false
    },
    navigation: {
          buttonOptions: {
              enabled: false
          }
      },
    tooltip: {
        formatter: function () {
          return this.point.name + " : " + numFormat( roundNum( this.point.y , 2 ) ) + " %";
        }
    },
     legend: {
        x: 70,
        align: 'top',
        layout: 'vertical',
        verticalAlign: 'middle'
      },
    plotOptions: {
        pie: {
            allowPointSelect: true,
            size: 300,
            // cursor: 'pointer',
            dataLabels: {
                enabled: true,
                distance: -35,
                format: ' {point.y:,.0f}' + " %",
                style: {
                      color: "#ffffff",
                      fontSize: "16px",
                      textOutline:"0px",
                      fontWeight:"400"
                }
            }
        }
    },
    colors : ["#3366CC","#DC3912","#FF9900","#109618","#990099","#0099C6","#DD4477","#66AA00","#B82E2E","#316395"],
    series: [{
        name: 'Browsers',
        colorByPoint: true,
        innerSize: '55%',
        showInLegend: true,
        marker: {
            symbol: 'circle',
            radius: 12
        },
        data: mobileDeviceChartData
    }]
  };


  return(
    <>
      <HighchartsReact Highcharts = {Highcharts} options = {chartConfig} />
    </>
  )
}


export default MobileDeviceSessionChart;