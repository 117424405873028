import kendo from "@progress/kendo-ui";
import { downloadExcel } from "../../../com/downloadExcel";

// Dashboard
export const getConsmDashboard = async (conn, data) => {
  const response = await conn.get("stat/event/consm/dashboard", {
    params: {
      start: kendo.toString(data.start, "yyyyMMdd"),
      end: kendo.toString(data.end, "yyyyMMdd"),
      userId: data.userId,
    },
  });
  return response;
};

// List
export const getConsmList = async (conn, data, paging) => {
  const take = paging.take;
  const skip = paging.skip;
  const page = paging.skip / paging.take + 1;
  const pageSize = paging.take;

  const response = await conn.get("stat/event/consm/list", {
    params: {
      start: kendo.toString(data.start, "yyyyMMdd"),
      end: kendo.toString(data.end, "yyyyMMdd"),
      userId: data.userId,
      take: take,
      skip: skip,
      page: page,
      pageSize: pageSize,
    },
  });
  return response;
};

// Charts
export const getConsmCharts = async (conn, data) => {
  const response = await conn.get("stat/event/consm/charts", {
    params: {
      start: kendo.toString(data.start, "yyyyMMdd"),
      end: kendo.toString(data.end, "yyyyMMdd"),
      userId: data.userId,
    },
  });
  return response;
};

// 엑셀 다운로드
export const getExcelDownload = async (param, paging) => {
  const excelTitle = "경보_수용가별_" + kendo.toString(new Date(), "yyyyMMdd") + ".xlsx";
  const url = "/api/v2.2/stat/event/consm";

  // 조건 검색 객체
  const search = {
    start: kendo.toString(param.start, "yyyyMMdd"),
    end: kendo.toString(param.end, "yyyyMMdd"),
    userId: param.userId,
  };

  try {
    downloadExcel(search, excelTitle, url);
  } catch (error) {
    kendo.alert("엑셀 다운로드를 실패 하였습니다.");
    return;
  }
};
