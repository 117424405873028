import { useState, useEffect } from "react";

import { TimePicker } from "@progress/kendo-react-dateinputs";
import { IntlProvider, load, loadMessages, LocalizationProvider } from "@progress/kendo-react-intl";

import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";

import numbers from "cldr-numbers-full/main/ko/numbers.json";
import caGregorian from "cldr-dates-full/main/ko/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/ko/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/ko/timeZoneNames.json";
import esMessages from "./ko.json";

load(likelySubtags, currencyData, weekData, numbers, caGregorian, dateFields, timeZoneNames);
loadMessages(esMessages, "ko");

const TimePickerKo = ({ format, defaultValue, onChange, max, min, value, readonly, name }) => {
  const [locale, setLocale] = useState("ko");
  useEffect(() => {
    if (typeof readonly !== "undefined") {
      if (readonly === true) {
        document.getElementsByName(name)[0].setAttribute("readonly", readonly);
      }
    }
  }, []);
  return (
    <LocalizationProvider language={locale}>
      <IntlProvider locale={locale}>
        <TimePicker format={format} defaultValue={defaultValue} name={name} onChange={onChange} max={max} min={min} value={value} />
      </IntlProvider>
    </LocalizationProvider>
  );
};

export default TimePickerKo;
