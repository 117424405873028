import { ListView } from "@progress/kendo-react-listview";
import LocationDepth1Item from "./locationDepth1Item";

const LocationDepth1List = ({ rgnMapDataList }) => {
  return (
    <>
      <div className="scroolY chmapTable-BC mapLocation1">
        {rgnMapDataList.dataMapList.length !== 0 ? (
          <ListView
            // className={styles.installerMeainList}
            data={rgnMapDataList.dataMapList}
            item={LocationDepth1Item}
          />
        ) : (
          <span className="none-data">데이터가 존재하지 않습니다.</span>
        )}
      </div>
    </>
  );
};

export default LocationDepth1List;
