import React from "react";

const SilverMedal = () => {
  return (
    <>
      {" "}
      <img className="cmpAreaMedal" src="/images/silver_medal.png"></img>
    </>
  );
};

export default SilverMedal;
