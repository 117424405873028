export const pwdRegex = (value) => {
  const pwdRegex = new RegExp(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*()])[A-Za-z\d!@#$%^&*()]{8,}$/);

  return !pwdRegex.test(value);
};

export const phoneRegex = (value) => {
  const phoneRegex = new RegExp(/^\d{3}-\d{3,4}-\d{4}$/);

  return !phoneRegex.test(value);
};

export const emailRegex = (value) => {
  const emailRegex = new RegExp(/\S+@\S+\.\S+/);

  return !emailRegex.test(value);
};
