import React, { Component, Fragment, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { RefreshSharp } from "@material-ui/icons";
import { numFormat } from "../../../com/dataUtil";

function WeathCmpChart({ search, dataState }) {
  const [visible, setVisible] = useState({
    temper: false,
    wind: false,
    rainfall: false,
    humi: false,
    rainProp: false,
  });
  const pointUnit = (name) => {
    if (name === "온도") return "°C</b>";
    else if (name === "풍속") return "m/s</b>";
    else if (name === "강수량") return "mm</b>";
    else if (name === "습도") return "%</b>";
    else if (name === "강수확률") return "%</b>";
    else if (name === "일사량") return "MJ/m²</b>";
    else if (name === "발전량") return "kWh</b>";
    else return "";
  };

  const chartOption = {
    chart: {
      zoomType: "xy",
      events: {
        addSeries: function () {
          //chart.series[0].hide();
        },
      },
    },
    noData: {
      style: {
        color: "#FFFFFF",
        fontSize: "14px",
      },
    },
    title: {
      text: null,
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    xAxis: [
      {
        type: "category",
        crosshair: true,
      },
    ],
    yAxis: [
      {
        // 온도 yAxis
        showEmpty: false,
        labels: {
          format: "{value}",
          style: {
            color: "tomato",
          },
        },
        title: {
          text: "온도(°C)",
          style: {
            color: "tomato",
          },
        },
        opposite: false,
      },
      {
        // 풍속 yAxis
        showEmpty: false,
        labels: {
          format: "{value}",
          style: {
            color: "#7FDBFF",
          },
        },
        title: {
          text: "풍속(m/s)",
          style: {
            color: "#7FDBFF",
          },
        },
        opposite: false,
      },
      {
        // 강수량 yAxis
        showEmpty: false,
        labels: {
          format: "{value}",
          style: {
            color: "#0074D9",
          },
        },
        title: {
          text: "강수량(mm)",
          style: {
            color: "#0074D9",
          },
        },
        opposite: false,
      },
      {
        // 습도 yAxis
        showEmpty: false,
        labels: {
          format: "{value}",
          style: {
            color: "orange",
          },
        },
        title: {
          text: "습도(%)",
          style: {
            color: "orange",
          },
        },
        opposite: true,
      },
      {
        // 강수확률 yAxis
        showEmpty: false,
        labels: {
          format: "{value}",
          style: {
            color: "mediumPurple",
          },
        },
        max: 100,
        title: {
          text: "강수확률(%)",
          style: {
            color: "mediumPurple",
          },
        },
        opposite: true,
      },
      {
        // 일사량 yAxis
        showEmpty: false,
        labels: {
          format: "{value}",
          style: {
            color: "#9ACD32",
          },
        },
        title: {
          text: "일사량(MJ/m²)",
          style: {
            color: "#9ACD32",
          },
        },
        opposite: true,
      },
      {
        // 발전량 yAxis
        showEmpty: false,
        labels: {
          format: "{value}",
          style: {
            color: "#ffc81E",
          },
        },
        title: {
          text: search.ensoTypeCode === 15002 || search.ensoTypeCode === 15003 ? "생산량(kWh)" : "발전량(kWh)",
          style: {
            color: "#ffc81E",
          },
        },
        opposite: true,
      },
    ],
    tooltip: {
      shared: true,
      formatter: function () {
        let msg = "";
        for (let i = 0; i < this.points.length; i++) {
          if (this.points[i]) {
            msg +=
              '<span style="color:' +
              this.points[i].point.color +
              '">\u25CF</span> ' +
              this.points[i].series.name +
              " : " +
              "<b>" +
              numFormat(this.points[i].y) +
              pointUnit(this.points[i].series.name) +
              "<br>";
          }
        }
        return msg;
      },
    },
    legend: {
      align: "center",
      verticalAlign: "top",
      backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || "#FFFFFF",
    },
    series: [
      {
        events: {
          legendItemClick: () => {
            setTimeout(() => {
              setVisible({ ...visible, temper: !visible.temper });
            }, 50);
          },
        },
        visible: visible.temper,
        color: "tomato",
        name: "온도",
        type: "spline",
        yAxis: 0,
        data: dataState.temp,
        tooltip: {
          valueSuffix: " °C",
        },
        zIndex: 10,
      },
      {
        events: {
          legendItemClick: () => {
            setTimeout(() => {
              setVisible({ ...visible, wind: !visible.wind });
            }, 50);
          },
        },
        visible: visible.wind,
        color: "#7FDBFF",
        name: "풍속",
        type: "spline",
        yAxis: 1,
        data: dataState.wspd,
        dashStyle: "shortdot",
        tooltip: {
          valueSuffix: " m/s",
        },
        zIndex: 10,
      },
      {
        events: {
          legendItemClick: () => {
            setTimeout(() => {
              setVisible({ ...visible, rainfall: !visible.rainfall });
            }, 50);
          },
        },
        visible: visible.rainfall,
        color: "#0074D9",
        name: "강수량",
        type: "spline",
        yAxis: 2,
        data: dataState.precQty,
        tooltip: {
          valueSuffix: " mm",
        },
        zIndex: 10,
      },
      {
        events: {
          legendItemClick: () => {
            setTimeout(() => {
              setVisible({ ...visible, humi: !visible.humi });
            }, 50);
          },
        },
        visible: visible.humi,
        color: "orange",
        name: "습도",
        type: "spline",
        yAxis: 3,
        data: dataState.humi,
        tooltip: {
          valueSuffix: " %",
        },
        zIndex: 10,
      },
      {
        events: {
          legendItemClick: () => {
            setTimeout(() => {
              setVisible({ ...visible, rainProp: !visible.rainProp });
            }, 50);
          },
        },
        visible: visible.rainProp,
        color: "mediumPurple",
        name: "강수확률",
        type: "spline",
        data: dataState.precPrbbl,
        yAxis: 4,
        tooltip: {
          valueSuffix: " %",
        },
        zIndex: 10,
      },
      {
        color: "#9ACD32",
        name: "일사량",
        type: "spline",
        yAxis: 5,
        data: dataState.soraQty,
        tooltip: {
          valueSuffix: " MJ/m²",
        },
        zIndex: 10,
      },
      {
        color: "#ffc81E",
        name: search.ensoTypeCode === 15002 || search.ensoTypeCode === 15003 ? "생산량" : "발전량",
        type: "column",
        data: dataState.power,
        yAxis: 6,
        tooltip: {
          valueSuffix: " kWh",
        },
        zIndex: 1,
      },
    ],
  };

  return (
    <Fragment>
      <HighchartsReact highcharts={Highcharts} options={chartOption} />
    </Fragment>
  );
}

export default WeathCmpChart;
