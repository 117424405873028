import kendo from "@progress/kendo-ui";
import HighchartsReact from "highcharts-react-official";
import NewWindow from "react-new-window";
import ReportConsmGrid from "./reportConsmGrid";
import Highcharts from "highcharts";
import ReportConsmWarnChart from "./reportConsmWarnChart";

const ReportConsmPrintModal = ({ search, energyData, energyCount, chartWarn, selectMeain, chartMonthData, chartYearData }) => {
  return (
    <NewWindow features={{ width: 900, height: 1080 }} title="월간 수용가별 운영 보고서">
      <div className="page">
        <div className="uk-grid">
          <div className="uk-width-medium-1-1">
            <div className="reportDayBackground"> {search.userTerm} 월간 종합 운영 보고서 </div>
            <div className="reportDayBackground"> Monthly Operation Report </div>
            <div className="reportDayBackground">보고일 : &nbsp; {kendo.toString(search.dtm, "yyyy년 MM월")}</div>
          </div>
        </div>
        <div className="uk-grid">
          <div className="uk-width-medium-4-10">
            <table className="reportEnergyTable">
              <thead>
                <th colSpan="3" style={{ color: "#369" }}>
                  전기 에너지
                </th>
              </thead>
              <tbody>
                <table>
                  <tr>
                    <th> 월간 발전량 </th>
                    <th> 누적 발전량</th>
                  </tr>
                  <tr>
                    <td scope="row">{energyData[0].totalPower}&nbsp;kWh</td>
                    <td scope="row">{energyData[0].accumPower}&nbsp;kWh</td>
                  </tr>
                </table>
                <table>
                  <tr>
                    <th>발전시간</th>
                    <th>누적CO2저감량</th>
                    <th>발전효율</th>
                  </tr>
                  <tr>
                    <td scope="row">-&nbsp;분</td>
                    <td scope="row">{energyData[0].powerCo2}&nbsp;t</td>
                    <td scope="row">-&nbsp;%</td>
                  </tr>
                </table>
              </tbody>
            </table>
          </div>
          <div className="uk-width-medium-3-10">
            <table className="reportEnergyTable">
              <thead>
                <th colSpan="3" style={{ color: "#00420b" }}>
                  열 에너지
                </th>
              </thead>
              <tbody>
                <table>
                  <tr>
                    <th> 월일 생산열량 </th>
                  </tr>
                  <tr>
                    <td scope="row">{energyData[0].totalCal}&nbsp;kWh</td>
                  </tr>
                </table>
                <table>
                  <tr>
                    <th>누적생산열량</th>
                  </tr>
                  <tr>
                    <td scope="row">{energyData[0].accumCal}&nbsp;kWh</td>
                  </tr>
                </table>
              </tbody>
            </table>
          </div>
          <div className="uk-width-medium-3-10">
            <table className="reportEnergyTable">
              <thead>
                <th colSpan="3" style={{ color: "#a52f00" }}>
                  ESS
                </th>
              </thead>
              <tbody>
                <table>
                  <tr>
                    <th>충전시간</th>
                  </tr>
                  <tr>
                    <td scope="row"> - &nbsp;kWh</td>
                    <td scope="row"> - &nbsp;시간</td>
                  </tr>
                </table>
                <table>
                  <tr>
                    <th>누적충전량</th>
                    <th>충전효율</th>
                  </tr>
                  <tr>
                    <td scope="row"> - &nbsp;kWh</td>
                    <td scope="row"> - &nbsp;%</td>
                  </tr>
                </table>
              </tbody>
            </table>
          </div>
        </div>
        <div className="uk-grid">
          <div className="uk-width-medium-1">
            <table className="reportEnergyTable">
              <thead>
                <th colSpan="3" style={{ color: "#369" }}></th>
              </thead>
              <tbody>
                <table>
                  <tr>
                    <th>전체&nbsp;{energyCount[0].totalCnt}&nbsp;개소</th>
                    <th>태양광&nbsp;{energyCount[0].solaCnt}&nbsp;개소</th>
                    <th>태양열&nbsp;{energyCount[0].heatCnt}&nbsp;개소</th>
                    <th>지열&nbsp;{energyCount[0].heathCnt}&nbsp;개소</th>
                    <th>풍력&nbsp;{energyCount[0].veloCnt}&nbsp;개소</th>
                    <th>연료전지&nbsp;{energyCount[0].fuelcellCnt}&nbsp;개소</th>
                    <th>ESS&nbsp;{energyCount[0].essCnt}&nbsp;개소</th>
                  </tr>
                  <tr>
                    <td scope="row">설치용량&nbsp;&nbsp;{energyCount[0].totalCapa}&nbsp;kW</td>
                    <td scope="row">{energyCount[0].solaCapa}&nbsp;kW</td>
                    <td scope="row">{energyCount[0].heatCapa}&nbsp;㎡</td>
                    <td scope="row">{energyCount[0].heathCapa}&nbsp;kW</td>
                    <td scope="row">{energyCount[0].veloCapa}&nbsp;kW</td>
                    <td scope="row">{energyCount[0].fuelcellCapa}&nbsp;kW</td>
                    <td scope="row">{energyCount[0].essCapa}&nbsp;kW</td>
                  </tr>
                </table>
              </tbody>
            </table>
          </div>
        </div>
        {/* <div className="uk-grid">
        <div className="uk-width-medium-1-1">
          <div className="reportDayBackground"> · &nbsp;시간대별 경보 발생 현황 </div>
          <div className="reportDayBackground">
              
          </div>
        </div>
      </div> */}
        <ReportConsmWarnChart chartWarn={chartWarn} width={793} />
        <ReportConsmGrid selectMeain={selectMeain} chartMonthData={chartMonthData} chartYearData={chartYearData} width={793} />
      </div>
    </NewWindow>
  );
};

export default ReportConsmPrintModal;
