// sha512 암호화
import sha512 from "js-sha512";

// 유저 정보 불러오기
export const getUserInfoData = async (conn, callback, reject, userId) => {
  // 검색 파라미터 제작
  await conn
    .get("/user", {
      params: {
        userId: userId,
      },
    })
    .then((response) => {
      const result = response;
      // 이메일 이벤트 수신 여부 값 가공
      let arr = ["0", "0", "0", "0", "0", "0", "0"];
      if (result) {
        if (result.wkdd !== null && typeof result.wkdd !== "undefined") {
          for (let index = 0; index < arr.length; index++) {
            arr[index] = result.wkdd.substring(index, index + 1) !== "" ? result.wkdd.substring(index, index + 1) : "0";
          }
        }
      }

      const today = new Date();
      const year = today.getFullYear();
      const month = today.getMonth();
      const day = today.getDate();

      // 이메일 수신 시간이 NULL 일경우 처리
      result.notiRcpmsgTm = result.notiRcpmsgTm === null ? 15 : result.notiRcpmsgTm;

      //알람 수신 시간 설정
      result.notiRcpmsgStart = new Date(year, month, day, result.notiRcpmsgStart.substring(0, 2), result.notiRcpmsgStart.substring(2, 4));
      result.notiRcpmsgEnd = new Date(year, month, day, result.notiRcpmsgEnd.substring(0, 2), result.notiRcpmsgEnd.substring(2, 4));
      // return 객체에 설정
      const calResult = {
        ...result,
        sun: arr[0],
        mon: arr[1],
        tues: arr[2],
        wednes: arr[3],
        thurs: arr[4],
        fri: arr[5],
        satur: arr[6],
      };
      callback(calResult);
    })
    .catch((error) => {
      reject(error);
    });
};

// 유저 정보 저장하기
export const setUserInfoSaveData = async (conn, callback, reject, userInfo) => {
  await conn
    .post("/user", {
      userId: userInfo.userId,
      loginPwd: userInfo.loginPwd,
      userEmail: userInfo.userEmail,
      userCelphon: userInfo.userCelphon,
      smsNotiUseYn: userInfo.smsNotiUseYn,
      emailNotiUseYn: userInfo.emailNotiUseYn,
      notiRcpmsgTm: userInfo.notiRcpmsgTm,
      notiRcpmsgStart: userInfo.notiRcpmsgStart,
      notiRcpmsgEnd: userInfo.notiRcpmsgEnd,
      kakaoNotiUseYn: userInfo.kakaoNotiUseYn,
      lisenNotiUseYn: userInfo.lisenNotiUseYn,
      ausenNotiUseYn: userInfo.ausenNotiUseYn,
      wkdd: userInfo.sun + userInfo.mon + userInfo.tues + userInfo.wednes + userInfo.thurs + userInfo.fri + userInfo.satur,
    })
    .then((response) => {
      const result = response;
      callback(result);
    })
    .catch((error) => {
      reject(error);
    });
};
