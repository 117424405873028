import { parseDate } from "../../../com/dateUtil";

const statsByConsmUrl = "/stat/byconsm";
let energyTypeUrl = "";

export const getStatsByConsm = async (conn, dataState) => {
  const { take, skip, filter } = dataState;
  const config = {
    params: {
      page: (take + skip) / take,
      size: take,
    },
  };

  if (filter.ensoType.codeNo === 15001) energyTypeUrl = "/sola/page";
  else if (filter.ensoType.codeNo === 15002) energyTypeUrl = "/heat/page";
  else if (filter.ensoType.codeNo === 15003) energyTypeUrl = "/heath/page";
  else if (filter.ensoType.codeNo === 15004) energyTypeUrl = "/velo/page";
  else if (filter.ensoType.codeNo === 15006) energyTypeUrl = "/fuelcell/page";
  else if (filter.ensoType.codeNo === 15007) energyTypeUrl = "/ess/page";

  if (filter !== undefined) {
    if (filter.startDtm !== "") config.params.startDtm = parseDate("yyyy-mm-dd", filter.startDtm);
    if (filter.endDtm !== "") config.params.endDtm = parseDate("yyyy-mm-dd", filter.endDtm);
    if (filter.userTerm !== undefined) config.params.userTerm = filter.userTerm;
    if (filter.cid !== undefined) config.params.cid = filter.cid;
    if (filter.rgnCode !== undefined) config.params.rgnCode = filter.rgnCode;
    if (filter.fixbylawBundCode !== undefined) config.params.fixbylawBundCode = filter.fixbylawBundCode;
    if (filter.alias !== undefined) config.params.alias = filter.alias;
    if (filter.meainModel !== undefined) {
      if (filter.meainModel.meainTypeSeriNo) {
        config.params.meainModel = filter.meainModel.meainTypeSeriNo;
      }
    }
  }

  const response = await conn.get(statsByConsmUrl + energyTypeUrl, config);

  return response;
};

export const getStatsByConsmExcel = async (conn, dataState) => {
  const { take, skip, filter } = dataState;
  const config = {
    params: {
      page: (take + skip) / take,
      size: take,
    },
  };

  if (filter !== undefined) {
    if (filter.startDtm !== "") config.params.startDtm = parseDate("yyyy-mm-dd", filter.startDtm);
    if (filter.endDtm !== "") config.params.endDtm = parseDate("yyyy-mm-dd", filter.endDtm);
    if (filter.userTerm !== undefined) config.params.userTerm = filter.userTerm;
    if (filter.cid !== undefined) config.params.cid = filter.cid;
    if (filter.rgnCode !== undefined) config.params.rgnCode = filter.rgnCode;
    if (filter.fixbylawBundCode !== undefined) config.params.fixbylawBundCode = filter.fixbylawBundCode;
    if (filter.alias !== undefined) config.params.alias = filter.alias;
  }

  const response = await conn.get(statsByConsmUrl + "/excel", config);

  return response;
};

export const getDbInfo = async (conn, dataState) => {
  const { filter } = dataState;
  let tbName = "";

  if (filter.ensoType.codeNo === 15001) tbName = "tb_sola_meain";
  else if (filter.ensoType.codeNo === 15002) tbName = "tb_sola_meain";
  else if (filter.ensoType.codeNo === 15003) tbName = "/heath/page";
  else if (filter.ensoType.codeNo === 15004) tbName = "/velo/page";
  else if (filter.ensoType.codeNo === 15006) tbName = "/fuelcell/page";
  else if (filter.ensoType.codeNo === 15007) tbName = "/ess/page";

  const response = await conn.get(statsByConsmUrl + "/db/" + tbName);

  return response;
};
