import { React, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Menu, MenuItem } from "@progress/kendo-react-layout";
import {
  DesktopWindows,
  Person,
  BarChart,
  Settings,
  AssignmentInd,
  Room,
  Groups,
  ContentPaste,
  InsertDriveFile,
  FindInPage,
  Alarm,
  NotificationImportant,
  AccountCircle,
  Report,
} from "@material-ui/icons";
import ManageAccountsIcon from "@material-ui/icons/ManageAccounts";
import { getMenuTree, getMenuRole } from "../service/menu/menuService";
import { useLocation } from "react-router";
import { getUserTypeCode } from "../service/authService";

import "./menuNavigator.css";

/**
 * 레프트 메뉴
 * 이용자의 권한별로 메뉴 표시 및 이동
 * 게스트는 이용자 정보 없음
 * @param {conn, codeList, user} param0
 * @returns
 */
const MenuNavigator = (props) => {
  const { roleMenu, getRoleMenu, setSelectMenu } = props;

  // console.log('roleMenu', roleMenu);
  let location = useLocation();

  useEffect(() => {
    if (location.pathname !== "/") getRoleMenu(location.pathname);
  }, []);

  const get1DepthMenuHtml = (menu) => {
    let menuHtml;
    let iconClass = "material-icons";
    let iconSpanClass = "menu_icon";
    let iconTxt = "menuIconTxt";
    switch (menu.id) {
      /**
       * 사이드 메뉴바 1
       */
      case "010000":
        if (location.pathname.includes("/monitor")) {
          iconClass += " material-icons-active";
          iconSpanClass += " iconActive";
          iconTxt = "menuIconTxtActive";
        }
        menuHtml = (
          <span className={iconSpanClass}>
            <DesktopWindows className={iconClass} />
            <br></br>
            <span className={iconTxt}>대시보드</span>
          </span>
        );
        break;
      /**
       * 사이드 메뉴바 2
       */
      case "020000":
        if (location.pathname.includes("/location")) {
          iconClass += " material-icons-active";
          iconSpanClass += " iconActive";
          iconTxt = "menuIconTxtActive";
        }
        menuHtml = (
          <span className={iconSpanClass}>
            <Room className={iconClass} />
            <br></br>
            <span className={iconTxt}>설치현황</span>
          </span>
        );
        break;
      /**
       * 사이드 메뉴바 3
       */
      case "030000":
        if (location.pathname.includes("/compare")) {
          iconClass += " material-icons-active";
          iconSpanClass += " iconActive";
          iconTxt = "menuIconTxtActive";
        }
        menuHtml = (
          <span className={iconSpanClass}>
            <BarChart className={iconClass} />
            <br></br>
            <span className={iconTxt}>비교</span>
          </span>
        );
        break;
      /**
       * 사이드 메뉴바 4
       */
      case "040000":
        if (location.pathname.includes("/expct")) {
          iconClass += " material-icons-active";
          iconSpanClass += " iconActive";
          iconTxt = "menuIconTxtActive";
        }
        menuHtml = (
          <span className={iconSpanClass}>
            <FindInPage className={iconClass} />
            <br></br>
            <span className={iconTxt}>예측</span>
          </span>
        );
        break;
      /**
       * 사이드 메뉴바 5
       */
      case "050000":
        if (location.pathname.includes("/logs")) {
          iconClass += " material-icons-active";
          iconSpanClass += " iconActive";
          iconTxt = "menuIconTxtActive";
        }
        menuHtml = (
          <span className={iconSpanClass}>
            <NotificationImportant className={iconClass} />
            <br></br>
            <span className={iconTxt}>이력</span>
          </span>
        );
        break;

      /**
       * 사이드 메뉴바 6
       */
      case "060000":
        if (location.pathname.includes("/report")) {
          iconClass += " material-icons-active";
          iconSpanClass += " iconActive";
          iconTxt = "menuIconTxtActive";
        }
        menuHtml = (
          <span className={iconSpanClass}>
            <InsertDriveFile className={iconClass} />
            <br></br>
            <span className={iconTxt}>보고서</span>
          </span>
        );
        break;

      /**
       * 사이드 메뉴바 7
       */
      case "070000":
        if (location.pathname.includes("/user")) {
          iconClass += " material-icons-active";
          iconSpanClass += " iconActive";
          iconTxt = "menuIconTxtActive";
        }
        menuHtml = (
          <span className={iconSpanClass}>
            <AccountCircle className={iconClass} />
            <br></br>
            <span className={iconTxt}>계정관리</span>
          </span>
        );
        break;

      /**
       * 사이드 메뉴바 8
       */
      case "080000":
        if (location.pathname.includes("/meain")) {
          iconClass += " material-icons-active";
          iconSpanClass += " iconActive";
          iconTxt = "menuIconTxtActive";
        }
        menuHtml = (
          <span className={iconSpanClass}>
            <Settings className={iconClass} />
            <br></br>
            <span className={iconTxt}>설비관리</span>
          </span>
        );
        break;
      /**
       * 사이드 메뉴바 - 관리자
       */
      case "100000":
        // if (location.pathname.includes('/mngt', 0)) {
        //   iconClass += ' material-icons-active';
        //   iconSpanClass += ' iconActive';
        //   iconTxt = 'menuIconTxtActive';
        // }
        menuHtml = (
          <span className={iconSpanClass}>
            <AssignmentInd className={iconClass} />
            <br></br>
            <span className={iconTxt}>개발자</span>
          </span>
        );
        break;

      default:
        break;
    }
    return menuHtml;
  };

  /**
   * 메뉴 네비게이터 클릭 시 동적으로 권한 있는 최하위 url로 접근
   * @param {}} menu click event
   * @returns 최하위 menu route
   */
  const conditionalMenuSelect = (menu) => {
    let url = "";
    if (menu.items) {
      url = conditionalMenuSelect(menu.items[0]);
    } else {
      return menu.data.route;
    }
    return url;
  };

  const setMenuSelectEvt = (event) => {
    if (event.item.data.authority) {
      const url = conditionalMenuSelect(event.item);
      setSelectMenu(url, roleMenu);
      props.history.push(url);
    } else alert("권한이 없습니다.");
  };

  return (
    <>
      <aside id="sidebar_main">
        <div className="menu_section">
          <Menu vertical={true} style={{}} onSelect={setMenuSelectEvt} className="sideNaviMenu">
            {roleMenu.map((md1) =>
              md1.checked ? (
                <MenuItem
                  text={get1DepthMenuHtml(md1)}
                  data={{ route: md1.menuUrl, id: md1.id, authority: md1.checked }}
                  itemId={md1.id}
                  id={md1.id}
                  key={md1.id}
                  cssStyle={{ background: md1.menuUrl.includes(location.pathname.substring(0, 4)) ? "#1C4E80" : null, borderBottom: "1px solid gainsboro" }}
                >
                  {md1.items.map(
                    (md2) =>
                      !md2.checked || (
                        <MenuItem text={md2.term} data={{ route: md2.menuUrl, id: md2.id, authority: md2.checked }} itemId={md1.id} id={md2.id} key={md2.id}>
                          {md2.items.map(
                            (md3) =>
                              !md3.checked || (
                                <MenuItem
                                  text={md3.term}
                                  data={{ route: md3.menuUrl, id: md3.id, authority: md3.checked }}
                                  itemId={md1.id}
                                  id={md3.id}
                                  key={md3.id}
                                >
                                  {}
                                </MenuItem>
                              ),
                          )}
                        </MenuItem>
                      ),
                  )}
                </MenuItem>
              ) : null,
            )}
          </Menu>
        </div>
      </aside>
      <div id="page_content">
        <div id="page_content_inner">{props.children}</div>
        <div className="copyrightDiv">Copyright 2020. Chungnam Techno park. All rights reserved.</div>
      </div>
    </>
  );
};
export default withRouter(MenuNavigator);
