import { useState, useEffect } from "react";
import { getMenuTree, getMenuRoleTree } from "../../../service/menu/menuService";
import { TreeView, processTreeViewItems, handleTreeViewCheckChange } from "@progress/kendo-react-treeview";

import { getMessage } from "../../../com/messageUtil";

const MenuRoleDev = ({ conn, setCheckData }) => {
  const settings = { checkChildren: true, checkParents: true };

  // 개발자 데이터
  let [dataSource, setDataSource] = useState({});

  // 메뉴 트리 데이터
  let [treeData, setTreeData] = useState({});

  const getTreeData = (treeData) => {
    getMenuTree(conn, treeData)
      .then((treeData) => {
        setTreeData({
          data: treeData,
        });

        getCheckData(treeData);
      })
      .catch((res) => {
        // console.error(res);
        alert(getMessage(res.errCode));
      });
  };

  function getCheckData(obj) {
    getMenuRoleTree(conn, obj, 2005)
      .then((dataSource) => {
        setDataSource({
          data: dataSource,
          checkChildren: true,
          checkParents: true,
          check: { idField: "id", ids: [], applyCheckIndeterminate: true },
        });
      })
      .catch((res) => {
        // console.error(res);
        alert(getMessage(res.errCode));
      });
  }
  // 메뉴 트리 데이터 불러오기
  useEffect(() => {
    getTreeData();
  }, []);

  const onCheckChange = (event) => {
    setDataSource({
      ...dataSource,
      check: handleTreeViewCheckChange(event, dataSource.check, dataSource.data, settings),
    });

    event.item.checked = !event.item.checked;

    const checkList = handleTreeViewCheckChange(event, dataSource.check, dataSource.data, settings);
    setCheckData(2005, checkList.ids, dataSource);
  };

  const onExpandChange = (event) => {
    event.item.expanded = !event.item.expanded;
  };

  return (
    <div className="uk-width-medium-1-6">
      <div className="md-card">
        <div className="md-card-content">
          <div className="md-card-toolbar">
            <h3 className="md-card-toolbar-heading-text">개발자</h3>
            <TreeView
              className="menuTree"
              data={processTreeViewItems(dataSource.data, { check: dataSource.check })}
              expandIcons={true}
              checkboxes={true}
              onCheckChange={onCheckChange}
              onExpandChange={onExpandChange}
              selectField={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuRoleDev;
