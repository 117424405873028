// React
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { getSys } from '../../../service/sys/sysService';
import log from '../../../com/log';
import { parseDate } from '../../../com/dateUtil';

// Kendo
import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import kendo from '@progress/kendo-ui';

// Highcharts
import { SysGause } from './sysGuage';

// Css
import './sys.css';
import styles from './sys.module.css';

// 로딩 스피너
import { BeatLoader } from 'react-spinners';
// 언어 변경
import koMessage from '../../../com/ko-language.json';
loadMessages(koMessage, 'ko-KR');

function Sys({ conn, codeList }) {
  const [loading, setLoading] = useState(false);

  // 리소스 모니터 드롭다운 데이터
  const resourceMonitor = useMemo(() => codeList.filter((item) => item.grpCodeNo === 11 && item.codeNo !== 11001), [codeList]);

  // 시스템 이벤트 이력 데이터 리스트
  const [dataSource, setDataSource] = useState([]);
  // 데이터 조회 상태 조건
  const [dataState, setDataState] = useState({
    take: 100,
    skip: 0,
    total: 0,
    sysCodeNo: 11002,
    startDate: parseDate('LocalDateTime'),
    endDate: parseDate('LocalDateTime'),
  });
  // CPU, 메모리, 디스크 데이터 상태
  const [tabInfo, setTabInfo] = useState({
    selected: 'DISK/',
    cpuGuage: [0],
    memGuage: [0],
    rootGuage: [0],
    rootHomeGuage: [0],
    jeusGuage: [0],
    jeusLogsGuage: [0],
  });

  useEffect(() => {
    setLoading(true);
    getSys(conn, dataState)
      .then((response) => {
        const { results, total } = response;
        setDataSource(results);
        setDataState({ ...dataState, total });

        if (Object.keys(results).length !== 0) {
          const cpuRate = new Array();
          const memRate = new Array();
          const diskRootRate = new Array();
          const diskRootHomeRate = new Array();
          const diskJeusRate = new Array();
          const diskJeusLogRate = new Array();

          cpuRate.push(results[0].cpuLoadRate);
          memRate.push(results[0].memoryUseRate);
          diskRootRate.push(results[0].prtnTermOne);
          diskRootHomeRate.push(results[0].prtnTermTwo);
          diskJeusRate.push(results[0].prtnTermThree);
          diskJeusLogRate.push(results[0].prtnTermFour);

          setTabInfo({
            ...tabInfo,
            cpuGuage: cpuRate,
            memGuage: memRate,
            rootGuage: diskRootRate,
            rootHomeGuage: diskRootHomeRate,
            jeusGuage: diskJeusRate,
            jeusLogsGuage: diskJeusLogRate,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        log(`sys.useEffect.getSys error : ${err}`);
      });
  }, []);

  // 리소스모니터 드롭다운 이벤트
  const handleResourceChange = useCallback(
    (e) => {
      setLoading(true);
      const { value } = e.target;

      const stateObj = new Object();
      stateObj.take = dataState.take;
      stateObj.skip = dataState.skip;
      stateObj.sysCodeNo = String(resourceMonitor.filter((item) => item.codeVal === value).map((item) => item.codeNo));
      stateObj.startDate = dataState.startDate;
      stateObj.endDate = dataState.endDate;

      getSys(conn, stateObj)
        .then((response) => {
          const { results, total } = response;
          setDataSource(results);
          setDataState({ ...dataState, sysCodeNo: stateObj.sysCodeNo, total });

          if (Object.keys(results).length !== 0) {
            const cpuRate = new Array();
            const memRate = new Array();
            const diskRootRate = new Array();
            const diskRootHomeRate = new Array();
            const diskJeusRate = new Array();
            const diskJeusLogRate = new Array();

            cpuRate.push(results[0].cpuLoadRate);
            memRate.push(results[0].memoryUseRate);
            diskRootRate.push(results[0].prtnTermOne);
            diskRootHomeRate.push(results[0].prtnTermTwo);
            diskJeusRate.push(results[0].prtnTermThree);
            diskJeusLogRate.push(results[0].prtnTermFour);

            setTabInfo({
              ...tabInfo,
              cpuGuage: cpuRate,
              memGuage: memRate,
              rootGuage: diskRootRate,
              rootHomeGuage: diskRootHomeRate,
              jeusGuage: diskJeusRate,
              jeusLogsGuage: diskJeusLogRate,
            });
          }
          setLoading(false);
        })
        .catch((err) => {
          log(`sys.onChange.getSys error : ${err}`);
        });
    },
    [dataSource, dataState, tabInfo],
  );

  // 디스크 드롭다운 이벤트
  const handleDiskChange = useCallback(
    (e) => {
      const { value } = e.target;
      setTabInfo({ ...tabInfo, selected: value });
    },
    [tabInfo],
  );

  // 그리드 페이징 처리
  const handlePageChange = useCallback(
    (e) => {
      const { take, skip } = e.page;

      const stateObj = new Object();
      stateObj.take = take;
      stateObj.skip = skip;
      stateObj.sysCodeNo = dataState.sysCodeNo;
      stateObj.startDate = dataState.startDate;
      stateObj.endDate = dataState.endDate;

      setLoading(true);
      getSys(conn, stateObj)
        .then((response) => {
          const { results, total } = response;
          setDataSource(results);
          setDataState({ ...dataState, take, skip, total });

          if (Object.keys(results).length !== 0) {
            const cpuRate = new Array();
            const memRate = new Array();
            const diskRootRate = new Array();
            const diskRootHomeRate = new Array();
            const diskJeusRate = new Array();
            const diskJeusLogRate = new Array();

            cpuRate.push(results[0].cpuLoadRate);
            memRate.push(results[0].memoryUseRate);
            diskRootRate.push(results[0].prtnTermOne);
            diskRootHomeRate.push(results[0].prtnTermTwo);
            diskJeusRate.push(results[0].prtnTermThree);
            diskJeusLogRate.push(results[0].prtnTermFour);

            setTabInfo({
              ...tabInfo,
              cpuGuage: cpuRate,
              memGuage: memRate,
              rootGuage: diskRootRate,
              rootHomeGuage: diskRootHomeRate,
              jeusGuage: diskJeusRate,
              jeusLogsGuage: diskJeusLogRate,
            });
          }
          setLoading(false);
        })
        .catch((err) => {
          log(`sys.onPageChange.getSys err : ${err}`);
        });
    },
    [dataSource, dataState, tabInfo],
  );

  // 시작 날짜 이벤트 처리
  const handleStartDateChange = useCallback(
    (e) => {
      const { value } = e.target;

      const stateObj = new Object();
      stateObj.take = dataState.take;
      stateObj.skip = dataState.skip;
      stateObj.sysCodeNo = dataState.sysCodeNo;
      stateObj.startDate = value;
      stateObj.endDate = dataState.endDate;

      setLoading(true);
      getSys(conn, stateObj)
        .then((response) => {
          const { results, total } = response;
          setDataSource(results);
          setDataState({ ...dataState, startDate: value, total });

          if (Object.keys(results).length !== 0) {
            const cpuRate = new Array();
            const memRate = new Array();
            const diskRootRate = new Array();
            const diskRootHomeRate = new Array();
            const diskJeusRate = new Array();
            const diskJeusLogRate = new Array();

            cpuRate.push(results[0].cpuLoadRate);
            memRate.push(results[0].memoryUseRate);
            diskRootRate.push(results[0].prtnTermOne);
            diskRootHomeRate.push(results[0].prtnTermTwo);
            diskJeusRate.push(results[0].prtnTermThree);
            diskJeusLogRate.push(results[0].prtnTermFour);

            setTabInfo({
              ...tabInfo,
              cpuGuage: cpuRate,
              memGuage: memRate,
              rootGuage: diskRootRate,
              rootHomeGuage: diskRootHomeRate,
              jeusGuage: diskJeusRate,
              jeusLogsGuage: diskJeusLogRate,
            });
          }
          setLoading(false);
        })
        .catch((err) => {
          log(`sys.datepicker.startDate.onChange error : ${err}`);
        });
    },
    [dataSource, dataState, tabInfo],
  );

  // 종료 날짜 이벤트 처리
  const handleEndDateChange = useCallback(
    (e) => {
      const { value } = e.target;

      const stateObj = new Object();
      stateObj.take = dataState.take;
      stateObj.skip = dataState.skip;
      stateObj.sysCodeNo = dataState.sysCodeNo;
      stateObj.startDate = dataState.startDate;
      stateObj.endDate = value;
      setLoading(true);

      getSys(conn, stateObj)
        .then((response) => {
          const { results, total } = response;
          setDataSource(results);
          setDataState({ ...dataState, endDate: value, total });

          if (Object.keys(results).length !== 0) {
            const cpuRate = new Array();
            const memRate = new Array();
            const diskRootRate = new Array();
            const diskRootHomeRate = new Array();
            const diskJeusRate = new Array();
            const diskJeusLogRate = new Array();

            cpuRate.push(results[0].cpuLoadRate);
            memRate.push(results[0].memoryUseRate);
            diskRootRate.push(results[0].prtnTermOne);
            diskRootHomeRate.push(results[0].prtnTermTwo);
            diskJeusRate.push(results[0].prtnTermThree);
            diskJeusLogRate.push(results[0].prtnTermFour);

            setTabInfo({
              ...tabInfo,
              cpuGuage: cpuRate,
              memGuage: memRate,
              rootGuage: diskRootRate,
              rootHomeGuage: diskRootHomeRate,
              jeusGuage: diskJeusRate,
              jeusLogsGuage: diskJeusLogRate,
            });
          }
          setLoading(false);
        })
        .catch((err) => {
          log(`sys.datepicker.endDate.onChange error : ${err}`);
        });
    },
    [dataSource, dataState, tabInfo],
  );

  return (
    <div
      className="sysBodyDiv"
      style={
        loading
          ? {
              opacity: 0.6,
              pointerEvents: 'none',
            }
          : null
      }
    >
      <div className="leftSysDiv">
        <div className="leftSysDivHeader">
          <span>리소스모니터</span>
          <DropDownList data={resourceMonitor.map((item) => item.codeVal)} defaultValue={resourceMonitor.map((item) => item.codeVal)[0]} onChange={handleResourceChange} />
        </div>
        <div className="leftSysDivBody">
          <div className="leftSysDivBodyChild">
            <TabStrip selected={0}>
              <TabStripTab title="CPU">
                <div>
                  <SysGause dataSource={tabInfo.cpuGuage} />
                </div>
              </TabStripTab>
            </TabStrip>

            <TabStrip selected={0}>
              <TabStripTab title="메모리">
                <div>
                  <SysGause dataSource={tabInfo.memGuage} />
                </div>
              </TabStripTab>
            </TabStrip>

            <div className="sysTabBottom">
              <DropDownList
                className={styles.sysKendoTab}
                data={['DISK/', 'DISK/HOME', 'DISK/KNREC/JEUS7', 'DISK/KNREC/JEUS7/LOGS']}
                defaultValue={'DISK/'}
                onChange={handleDiskChange}
              />
              {tabInfo.selected === 'DISK/' ? (
                <div>
                  <SysGause dataSource={tabInfo.rootGuage} />
                </div>
              ) : tabInfo.selected === 'DISK/HOME' ? (
                <div>
                  <SysGause dataSource={tabInfo.rootHomeGuage} />
                </div>
              ) : tabInfo.selected === 'DISK/KNREC/JEUS7' ? (
                <div>
                  <SysGause dataSource={tabInfo.jeusGuage} />
                </div>
              ) : (
                <div>
                  <SysGause dataSource={tabInfo.jeusLogsGuage} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="rightSysDiv">
        <LocalizationProvider language="ko-KR">
          <IntlProvider locale="ko">
            <Grid className={styles.sysGrid} data={dataSource} {...dataState} pageable={{ buttonCount: 5, pageSizes: [5, 10, 20, 50, 100] }} onPageChange={handlePageChange}>
              <GridToolbar>
                <span className="sysSpanTxt1">시스템 이벤트 이력</span>
                <span className="sysSpanTxt2">
                  날짜검색 &nbsp;
                  <DatePicker defaultValue={new Date()} format={'yyyy-MM-dd'} max={new Date(dataState.endDate)} onChange={handleStartDateChange} />
                  &nbsp; ~ &nbsp;
                  <DatePicker defaultValue={new Date()} format={'yyyy-MM-dd'} max={new Date()} onChange={handleEndDateChange} />
                </span>
              </GridToolbar>
              <GridColumn field="statCodeNo" title="등급" width="75px" />
              <GridColumn field="formatDate" title="일시" width="170px" />
              <GridColumn field="event" title="이벤트" width="325px" />
              <GridColumn field="detail" title="상세내역" />
            </Grid>
          </IntlProvider>
        </LocalizationProvider>
      </div>
      {loading && (
        <p
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
          }}
        >
          <BeatLoader loading={true} size={24} color="#1e88e5" />
        </p>
      )}
    </div>
  );
}

export default Sys;
