// 설비 버튼 리스트
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";

const CmpUserByUserMeainList = ({ meainList, search, setSearch, getDataList, allCidView }) => {
  // 버튼 클릭 이벤트
  const onClickEvt = (e) => {
    const clickCid = e.target.value;
    setSearch({ ...search, cid: clickCid });
    search.cid = clickCid;
    getDataList();
  };

  return (
    <div className="uk-grid">
      <div className="uk-width-medium-1-1">
        <div className="md-card TBgA">
          <div className="md-card-content small-padding cmpBtnGrp">
            <ButtonGroup>
              {allCidView && (
                <Button className="meainItemBtn" onClick={onClickEvt} value={""} togglable={true} selected={search.cid === ""}>
                  전체
                </Button>
              )}
              {meainList.map((e) => {
                return (
                  <Button className="meainItemBtn" onClick={onClickEvt} value={e.cid} togglable={true} selected={Number(search.cid) === e.cid}>
                    {e.cid}
                  </Button>
                );
              })}
            </ButtonGroup>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CmpUserByUserMeainList;
