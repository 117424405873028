import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import kendo from "@progress/kendo-ui";
import { useState, useEffect } from "react";
import { getMessage } from "../../../com/messageUtil";
import { getOperPowerWeek } from "../../../service/monitor/operationService";
import { numFormat, roundNum } from "../../../com/dataUtil";

const OperationStatPower = ({ state }) => {
  const beforeDay = [];

  let today = new Date();
  let todayDate = kendo.toString(today, "yyyy-MM-dd"); //기준일

  let chartPower = [];

  // 일주일치 날짜 저장
  for (let dateIteration = 0; dateIteration < 8; dateIteration++) {
    chartPower.push({ name: getNextWeek(todayDate, dateIteration - 8 + 1), y: dateIteration });
  }

  // 날짜 포맷
  function getNextWeek(v, t) {
    //날짜스트링과, 일차를 파라메터로 받는다
    let str = new Array(); //배열
    let b = v.split("-"); //날짜를 - 구분자로 나누어 배열로 변환
    let c = new Date(b[0], b[1] - 1, b[2]); //데이트객체 생성
    let d = c.valueOf() + 1000 * 60 * 60 * 24 * t; //t일후, (음수면 전일)의 타임스탬프를 얻는다
    let e = new Date(d); //의뢰한날의 데이트객체 생성

    // 날짜를 포맷 시켜 가져온다.
    let date = kendo.toString(e, "MM.dd");

    return date; //배열을 - 구분자로 합쳐 스트링으로 변환후 반환
  }

  // 발전량추이
  let powerChart = {
    chart: { type: "line", zoomType: "xy", height: 235 },
    title: { text: null },
    xAxis: [{ type: "category", crosshair: true }],
    yAxis: [
      {
        // 발전량
        labels: {
          formatter: function () {
            return numFormat(this.value);
          },
          style: {
            color: "#9ACD32",
          },
        },
        title: {
          text: "발전량(kWh)",
          style: {
            color: "#9ACD32",
          },
        },
        opposite: true,
      },
      {
        // 설비 용량
        labels: {
          formatter: function () {
            return numFormat(this.value);
          },
          style: {
            color: "#87CEEB",
          },
        },
        title: {
          text: "설비용량(kW)",
          style: {
            color: "#87CEEB",
          },
        },
      },
      {
        // 설비수
        labels: {
          formatter: function () {
            return numFormat(this.value);
          },
          style: {
            color: "#F08080",
          },
        },
        title: {
          text: "설비수",
          style: {
            color: "#F08080",
          },
        },
      },
    ],
    credits: { enabled: false },
    tooltip: {
      shared: true,
      formatter: function () {
        let s = "";

        if (this.points[0]) {
          s +=
            '<span style="color:' +
            this.points[0].point.color +
            '">\u25CF</span> ' +
            // "<span>" +
            this.points[0].series.name +
            // "</span>" +
            " : " +
            "<b>" +
            numFormat(this.points[0].y) +
            " kW" +
            "</b>";
        }

        if (this.points[1]) {
          s +=
            "<br/>" +
            '<span style="color:' +
            this.points[1].point.color +
            '">\u25CF</span> ' +
            this.points[1].series.name +
            " : " +
            "<b>" +
            numFormat(this.points[1].y) +
            " 개수" +
            "</b>";
        }

        if (this.points[2]) {
          s +=
            "<br/>" +
            '<span style="color:' +
            this.points[2].point.color +
            '">\u25CF</span> ' +
            // "<span>" +
            this.points[2].series.name +
            // "</span>" +
            " : " +
            "<b>" +
            numFormat(roundNum(this.points[2].y, 2)) +
            " kWh" +
            "</b>";
        }
        return s;
      },
    },
    legend: { layout: "horizontal", align: "center", verticalAlign: "top", floating: false },
    series: [
      {
        name: "설비용량",
        tooltip: {
          valueSuffix: " kW",
        },
        color: "#87CEEB",
        yAxis: 1,
        data: state.sumCapa,
      },
      {
        name: "설비수",
        tooltip: {
          valueSuffix: " 개수",
        },
        color: "#F08080",
        yAxis: 2,
        data: state.sumCnt,
      },
      {
        name: "발전량",
        tooltip: {
          valueSuffix: " kWh",
        },
        color: "#9ACD32",
        data: state.sumDayPower,
      },
    ],
  };

  return (
    <div className="uk-width-medium-1-3">
      <div className="md-card">
        <div className="md-card-content , operationErrorPage">
          <div className="md-card-toolbar">
            <h3 className="md-card-toolbar-heading-text">발전량 추이</h3>
          </div>
          <div
          // style="height: 280px;"
          >
            {" "}
            <HighchartsReact options={powerChart}></HighchartsReact>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};
export default OperationStatPower;
