import React from "react";
// const GeoGradient = (props) => {
//   return (
//     <svg xmlns="http://www.w3.org/2000/svg" style={{ position: "fixed" }}>
//       <linearGradient id="overGra" x1="0%" y1="0%" x2="0%" y2="100%" spreadMethod="pad">
//         <stop offset="0%" stop-color="#FF9A57" stop-opacity="1" />
//         <stop offset="100%" stop-color="#E8613B" stop-opacity="1" />
//       </linearGradient>
//       <linearGradient id="top20" x1="0%" y1="0%" x2="0%" y2="100%" spreadMethod="pad">
//         <stop offset="0%" stop-color="#1e6431" stop-opacity="1" />
//         <stop offset="100%" stop-color="#40ac48" stop-opacity="1" />
//       </linearGradient>
//       <linearGradient id="top40" x1="0%" y1="0%" x2="0%" y2="100%" spreadMethod="pad">
//         <stop offset="0%" stop-color="#40ac48" stop-opacity="1" />
//         <stop offset="100%" stop-color="#8bc64e" stop-opacity="1" />
//       </linearGradient>
//       <linearGradient id="top60" x1="0%" y1="0%" x2="0%" y2="100%" spreadMethod="pad">
//         <stop offset="0%" stop-color="#8bc64e" stop-opacity="1" />
//         <stop offset="100%" stop-color="#c5e0a0" stop-opacity="1" />
//       </linearGradient>
//       <linearGradient id="top80" x1="0%" y1="0%" x2="0%" y2="100%" spreadMethod="pad">
//         <stop offset="0%" stop-color="#c5e0a0" stop-opacity="1" />
//         <stop offset="100%" stop-color="#d6f3ae" stop-opacity="1" />
//       </linearGradient>
//     </svg>
//   );
// };

const GeoGradient = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" style={{ position: "fixed" }}>
      <linearGradient id="overGra" x1="0%" y1="0%" x2="0%" y2="100%" spreadMethod="pad">
        <stop offset="0%" stop-color="#FF9A57" stop-opacity="1" />
        <stop offset="100%" stop-color="#E8613B" stop-opacity="1" />
      </linearGradient>
      <linearGradient id="top20" x1="0%" y1="0%" x2="0%" y2="100%" spreadMethod="pad">
        <stop offset="0%" stop-color="#3174A8" stop-opacity="1" />
        <stop offset="100%" stop-color="#014F8D" stop-opacity="1" />
      </linearGradient>
      <linearGradient id="top40" x1="0%" y1="0%" x2="0%" y2="100%" spreadMethod="pad">
        <stop offset="0%" stop-color="#639AC5" stop-opacity="1" />
        <stop offset="100%" stop-color="#3476AA" stop-opacity="1" />
      </linearGradient>
      <linearGradient id="top60" x1="0%" y1="0%" x2="0%" y2="100%" spreadMethod="pad">
        <stop offset="0%" stop-color="#94BFE1" stop-opacity="1" />
        <stop offset="100%" stop-color="#639AC5" stop-opacity="1" />
      </linearGradient>
      <linearGradient id="top80" x1="0%" y1="0%" x2="0%" y2="100%" spreadMethod="pad">
        <stop offset="0%" stop-color="#C6E5FE" stop-opacity="1" />
        <stop offset="100%" stop-color="#96C1E2" stop-opacity="1" />
      </linearGradient>
    </svg>
  );
};

export default GeoGradient;
