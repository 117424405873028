import { useCallback, useEffect } from "react";
import queryString from "query-string";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
/*global kakao*/
const { kakao } = window;

// 카카오 맵
const Map = ({ rgnList, setMaps, meainList, setMapMarkUp }) => {
  let location = useLocation();
  let { rgnCode } = queryString.parse(location.search);
  // 처음 설비 마크업 설정
  useEffect(() => {
    // rgnCode 선택 시 선택된 지역의 lati , longi 적용
    const selRgnInfo = rgnList.filter((c) => c.rgnCode === rgnCode)[0];
    const lati = selRgnInfo.lati ? selRgnInfo.lati : 36.81508;
    const longi = selRgnInfo.longi ? selRgnInfo.longi : 127.11392;
    const container = document.getElementById("Mymap");
    const options = {
      //지도를 생성할 때 필요한 기본 옵션
      center: new kakao.maps.LatLng(lati, longi), //지도의 중심좌표.
      level: 8, //지도의 레벨(확대, 축소 정도)
      mapTypeId: 3, //지도 타입 변경
    };
    const map = new kakao.maps.Map(container, options);

    // 클러스터 제작
    var clusterer = new kakao.maps.MarkerClusterer({
      map: map, // 마커들을 클러스터로 관리하고 표시할 지도 객체
      averageCenter: true, // 클러스터에 포함된 마커들의 평균 위치를 클러스터 마커 위치로 설정
      minLevel: 1, // 클러스터 할 최소 지도 레벨
      disableClickZoom: true,
      minClusterSize: 1,
      gridSize: 1,
      // 각 클러스터 이미지 적용하려면 마커 갯수의 기준을 매겨야하는데 어려움
      // styles: [
      //   {width : '53px', height : '52px',background: 'url(../images/cluster/cluster_1.png) no-repeat'}
      // ]
    });

    setMaps(map, clusterer);
    // setMapMarkUp(meainList ,map )
  }, [rgnCode]);

  return <div id="Mymap"></div>;
};

export default Map;
