import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";

import kendo from "@progress/kendo-ui";

import { roundNum } from "../../../com/dataUtil";
import { setDeleteBpmRps } from "../../../service/meain/bpmRps/bpmRpsService";

const BpmRpsGrid = ({ conn, search, setSearch, paging, getBpmRpsData, modalItem, setModalItem }) => {

    // 페이징 이벤트
    const onPageChange = (e) => {
        let { take, skip } = e.page;
        paging.take = take;
        paging.skip = skip;
        getBpmRpsData(search, paging);
    };

    // 주소에서 괄호 제거를 할까 했는데 의미가 없어 취소
    // const addrCell = (e) => {
    //     const { dataItem } = e;
    //     const result = dataItem.address.split('(')[0];
    //     const result2 = dataItem.address.split(')');
    //     const result3 = result2[result2.length - 1];

    //     return <td>{result + " " + result3}</td>;
    // };

    const replaceBlankHyphen = (e) => {
        const { dataItem, field } = e;

        if (field === "ensoTypeVal") {
            if (dataItem.ensoTypeVal !== '' && dataItem.ensoTypeVal !== null) {
                return <td>{dataItem.ensoTypeVal}</td>;
            }
            else {
                return <td>-</td>;
            }
        }
        else if (field === "bizSct") {
            if (dataItem.bizSct !== '' && dataItem.bizSct !== null) {
                return <td>{dataItem.bizSct}</td>;
            }
            else {
                return <td>-</td>;
            }
        }
        else if (field === "bizYy") {
            if (dataItem.bizYy !== '' && dataItem.bizYy !== null) {
                return <td>{dataItem.bizYy}</td>;
            }
            else {
                return <td>-</td>;
            }
        }
        else if (field === "rgnTerm") {
            if (dataItem.rgnTerm !== '' && dataItem.rgnTerm !== null) {
                return <td>{dataItem.rgnTerm}</td>;
            }
            else {
                return <td>-</td>;
            }
        }
        else if (field === "address") {
            if (dataItem.address !== '' && dataItem.address !== null) {
                return <td>{dataItem.address}</td>;
            }
            else {
                return <td>-</td>;
            }
        }
        else if (field === "instCapa") {
            const result = roundNum(dataItem.instCapa, 3);
            if (isNaN(result)) {
                return <td>-</td>;
            }
            else {
                if (dataItem.ensoTypeCode == 15002) {
                    return <td>{result} ㎡</td>;
                }
                else {
                    return <td>{result} kW</td>;
                }
            }
        }
        else if (field === "consEnteName") {
            if (dataItem.consEnteName !== '' && dataItem.consEnteName !== null) {
                return <td>{dataItem.consEnteName}</td>;
            }
            else {
                return <td>-</td>;
            }
        }
        else if (field === "remarks") {
            if (dataItem.remarks !== '' && dataItem.remarks !== null) {
                return <td>{dataItem.remarks}</td>;
            }
            else {
                return <td>-</td>;
            }
        }
    };



    // 정렬 이벤트
    // const onSortChange = (e) => {
    //     const { sort } = e;
    //     paging.sort = sort;
    //     // setOperHistData(search, paging);
    // };

    return (
        <div className="uk-grid">
            <div className="uk-width-medium-1-1">
                <LocalizationProvider language="ko-KR">
                    <IntlProvider locale="ko">
                        <Grid
                            id="bpmRpsGrid"
                            className="bpmRpsGrid"
                            data={paging.list}
                            pageable={{ buttonCount: 5, pageSizes: true }}
                            total={paging.count}
                            skip={paging.skip}
                            take={paging.take}
                            onPageChange={onPageChange}
                        >
                            <GridNoRecords>{"  "}</GridNoRecords>
                            <GridColumn title="에너지원" field="ensoTypeVal" width={130} cell={replaceBlankHyphen}></GridColumn>
                            <GridColumn title="사업구분" field="bizSct" width={170} cell={replaceBlankHyphen}></GridColumn>
                            <GridColumn title="사업연도" field="bizYy" width={130} cell={replaceBlankHyphen}></GridColumn>
                            <GridColumn title="지역" field="rgnTerm" width={200} cell={replaceBlankHyphen}></GridColumn>
                            <GridColumn title="주소" field="address" cell={replaceBlankHyphen}></GridColumn>
                            <GridColumn title="설비용량" field="instCapa" width={130} cell={replaceBlankHyphen}></GridColumn>
                            <GridColumn title="시공기업" field="consEnteName" width={170} cell={replaceBlankHyphen}></GridColumn>
                            <GridColumn title="비고" field="remarks" width={200} cell={replaceBlankHyphen}></GridColumn>
                            <GridColumn
                                title="수정"
                                filterable={false}
                                sortable={false}
                                width={60}
                                cell={(props) => (
                                    <td>
                                        <a
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setModalItem({
                                                    ...modalItem,
                                                    visible: !modalItem.visible,
                                                    title: "수정",
                                                    createUpdateYn: false,
                                                    item: props.dataItem,
                                                });
                                            }}
                                        >
                                            <img src="/images/icon_su.png" alt="" />
                                        </a>
                                    </td>
                                )}
                            />
                            <GridColumn
                                title="삭제"
                                filterable={false}
                                sortable={false}
                                width={60}
                                cell={(props) => (
                                    <td>
                                        <a
                                            onClick={(e) => {
                                                e.preventDefault();
                                                const { cid } = props.dataItem;
                                                // 삭제 여부 확인
                                                kendo
                                                    .confirm("해당 국비지원/개인발전소 설비 정보를 삭제 하시겠습니까?")
                                                    .then(
                                                        (e) => {
                                                            setDeleteBpmRps(conn, cid)
                                                                .then((response) => {
                                                                    kendo.alert("삭제가 완료 되었습니다.");
                                                                    getBpmRpsData(search, paging);
                                                                })
                                                                .catch((err) => {
                                                                    kendo.alert("삭제를 실패 하였습니다.");
                                                                    console.log(`bpmRpsGrid.setDeleteBpmRps error: ${err}`);
                                                                });
                                                        })
                                                    .then((e) => { });
                                            }}
                                        >
                                            <img src="/images/icon_del.png" alt="" />
                                        </a>
                                    </td>
                                )}
                            />
                        </Grid>
                    </IntlProvider>
                </LocalizationProvider>
            </div>
        </div>
    );
};

export default BpmRpsGrid;
