import styles from "./nonOperHistGrid.module.css";
import DatePickerKo from "../../com/dateInputs/datePickerKo";
import { DropDownList } from "@progress/kendo-react-dropdowns";

import { isInstaller } from "../../../service/authService";

import NonOperHistGrid from "./nonOperHistGrid";
import OperationStatArea from "./operationStat_area";
import OperationStatMeainModl from "./operationStat_meainModl";
import { Button } from "@progress/kendo-react-buttons";
import { Input } from "@progress/kendo-react-inputs";
import RgnDropdown from "../../com/dropdown/rgnDropdown";

const NonOperHistSearch = (props) => {
  const {
    conn,
    ensoList,
    searchData,
    search,
    rgnList,
    rgns,
    defaultValRgn,
    cidChange,
    cidKeyPress,
    installerChange,
    installerKeyPress,
    defaultCommunicationModel,
    today,
    day,
    setSearch,
    makrList,
    optionLabel,
    makrMeainModelList,
    dataSource,
    setDataSource,
    paging,
    sort,
    onPageChange,
    onSortChange,
    setSearchClickEvt,
    communicationMthdList,
  } = props;
  return (
    <>
      <div className="md-card TBgA">
        <div className="md-card-content small-padding">
          <span className="searchSubTitleA">에너지원</span>
          <DropDownList
            data={ensoList.filter((e) => e.codeNo !== 15007)}
            defaultItem={{ codeVal: "전체", codeNo: "" }}
            name="ensoTypeCodeNo"
            textField="codeVal"
            dataItemKey="codeNo"
            onChange={searchData}
            className="searchArea"
            value={search.defaultEnsoCode}
            // disabled = { isInstaller() === true ? true : false }
          ></DropDownList>
          <span className="searchSubTitleA">지역검색</span>
          <RgnDropdown name="rgn" rgnList={rgnList} search={search} objKey={"rgn"} setSearch={setSearch} onChange={searchData} />
          {/* <DropDownList
            data={rgns}
            textField="rgnTermSimple"
            name="rgn"
            dataItemKey="rgnCode"
            defaultItem={defaultValRgn}
            onChange={searchData}
            disabled={isInstaller() === true ? true : false}
          /> */}
          &nbsp;
          <Input placeholder="CID" onChange={cidChange} onKeyPress={cidKeyPress}></Input>&nbsp;
          <Input placeholder="수용가명" onChange={installerChange} onKeyPress={installerKeyPress}></Input>
          <span className="searchSubTitleB">통신방식</span>
          <DropDownList
            data={communicationMthdList}
            textField="communicationMthd"
            name="communicationMthd"
            dataItemKey="cm"
            defaultItem={defaultCommunicationModel}
            onChange={searchData}
          ></DropDownList>
          {/* <span className="searchSubTitleB">조회일시</span>
          <DatePickerKo
            id="start"
            name="start"
            className="datePicker"
            format={"yyyy-MM-dd"}
            defaultValue={today}
            setDtm={(v) => {
              day.current = v;
              setSearch((state) => {
                const item = { ...state };
                item.date = v;
                return item;
              });
            }}
            max={today}
          ></DatePickerKo> */}
          &nbsp;&nbsp;&nbsp;
          <span className="searchSubTitleA">설비 제조사 / 모델</span>
          <DropDownList
            data={makrList}
            defaultItem={{ makrTerm: "제조사", makrSeriNo: "" }}
            name="makrSeriNo"
            textField="makrTerm"
            dataItemKey="makrSeriNo"
            onChange={searchData}
            className="searchArea"
            popupSettings={{ className: "autoFitDropDown" }}
          ></DropDownList>
          <DropDownList
            disabled={search.makrSeriNo === ""}
            value={optionLabel.meainModl}
            data={makrMeainModelList.meainModelList.filter((e) => {
              if (search.ensoTypeCode) {
                return e.ensoTypeCodeNo === search.ensoTypeCode && e.makrSeriNo === search.makrSeriNo;
              } else {
                return e.makrSeriNo === search.makrSeriNo;
              }
            })}
            defaultItem={{ meainTypeTerm: "설비 모델", meainTypeSeriNo: "" }}
            name="meainTypeSeriNo"
            textField="meainTypeTerm"
            dataItemKey="meainTypeSeriNo"
            popupSettings={{ className: "autoFitDropDown" }}
            onChange={searchData}
            className="searchArea"
          ></DropDownList>
          <span className="searchSubTitleC">
            <Button icon="search" onClick={setSearchClickEvt} style={{ paddingLeft: "20px", paddingRight: "20px" }}></Button>
            {/* <button className="ch-btnA" onClick={setSearchClickEvt} >
              <Search className="material-icons"  />
            </button> */}
          </span>
        </div>
      </div>
      <br></br>
      <div>
        <div className="uk-grid">
          <div className="uk-width-medium-1-1">
            <div className="md-card">
              <NonOperHistGrid
                conn={conn}
                styles={styles}
                dataSource={dataSource}
                setDataSource={setDataSource}
                paging={paging}
                sort={sort}
                onPageChange={onPageChange}
                onSortChange={onSortChange}
                search={search}
                setSearchClickEvt={setSearchClickEvt}
              />
            </div>
          </div>
        </div>
        <div className="uk-grid">
          <div className="uk-width-medium-1-2">
            <div className="md-card">
              <OperationStatArea
                styles={styles}
                dataSource={dataSource.rgnNonOperHistData}
                setSearchClickEvt={setSearchClickEvt}
                search={search}
                setSearch={setSearch}
                setDataSource={setDataSource}
              />
            </div>
          </div>
          <div className="uk-width-medium-1-2">
            <div className="md-card">
              <OperationStatMeainModl styles={styles} dataSource={dataSource.invNonOperHistData} setSearchClickEvt={setSearchClickEvt} setSearch={setSearch} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default NonOperHistSearch;
