import { numFormat ,roundNum } from "../../com/dataUtil";
import kendo from "@progress/kendo-ui";

/**
 * 종합보고서 에너지 리스트 API
 * @param 1. 검색 날짜
 * @param 2. 날짜 형식
 * @return 보고서 에너지 리스트
 */
export const getEnergyData = async(conn , callback , reject , search) => {
  await conn.get("/report/comprehensive/list", {
    params: {
      dtm : kendo.toString(search.dtm , 'yyyy-MM-dd'),
      dateType : search.dateType
    }
  }).then(response => {
    const result = response;
    const chartTime_category = [];
    const chartTimeData = { power : [] , cal : [] , ess : [] };
    // 그리드에 보여줘야할 값은 주의 점
    // 일별 시 시간별로 데이터를 보여줘야하기에 hour 이다.
    // 월별 시 일별로 데이터를 보여줘야하기에 day 이다.
    // 연간 시 월별로 데이터를 보여줘야하기에 month 이다.
    result.forEach(element => {
      if( search.dateType === 'DD' ){
        element.dtm = element.dtm + " 시";
        element.power = element.hourPower !== null ? numFormat(roundNum( element.hourPower, 2 )) : 0 ;
        element.cal = element.hourCal !== null ? numFormat(roundNum( element.hourCal, 2 )) : 0;
        element.ess = 0;
        // 차트 데이터
        chartTimeData.power.push({name : element.dtm , y : roundNum( element.hourPower, 2 )})
        chartTimeData.cal.push({name : element.dtm , y : roundNum( element.hourCal, 2 )})
        chartTimeData.ess.push({name : element.dtm , y : 0})
      } else if( search.dateType === 'MM' ){
        element.power = element.dayPower !== null ? numFormat(roundNum( element.dayPower , 2 )) : 0 ;
        element.cal = element.dayCal !== null ? numFormat(roundNum( element.dayCal , 2 )) : 0;
        element.ess = 0;
        // 차트 데이터
        chartTimeData.power.push({name : element.dtm , y : roundNum( element.dayPower, 2 )})
        chartTimeData.cal.push({name : element.dtm , y : roundNum( element.dayCal, 2 )})
        chartTimeData.ess.push({name : element.dtm , y : 0})
      } else {
        element.dtm = element.dtm + " 월";
        element.power = element.monthPower !== null ? numFormat(roundNum(element.monthPower, 2 )) : 0;
        element.cal = element.monthCal !== null ? numFormat(roundNum(element.monthCal, 2 )) : 0;
        element.ess = 0;
        // 차트 데이터
        chartTimeData.power.push({name : element.dtm , y : roundNum( element.monthPower, 2 )})
        chartTimeData.cal.push({name : element.dtm , y : roundNum( element.monthCal, 2 )})
        chartTimeData.ess.push({name : element.dtm , y : 0})
      }
      chartTime_category.push(element.dtm);
    });
    const gridData = result;
    // 그리드 데이터에 단위 넣기
    gridData.forEach(element => {
      element.power = element.power + " kWh";
      element.cal = element.cal + " kWh";
      element.ess = element.ess + " kWh";
    });

    const timeChart = { chartTime_category , chartTimeData }
  callback( gridData, timeChart );
  }).catch(error => {
    reject( "에너지" , error);
  });
}

/**
 * 종합보고서 지역 리스트 API
 * @param 1. 검색 날짜
 * @param 2. 날짜 형식
 * @return 보고서 지역 리스트
 */
 export const getAreaData = async(conn , callback , reject , search , rgnList) => {
  await conn.get("/report/comprehensive/area/list", {
    params: {
      dtm : kendo.toString(search.dtm , 'yyyy-MM-dd'),
      dateType : search.dateType
    }
  }).then(response => {
    const result = response;
    result.forEach(element => {
      // 지역명 입력
      rgnList.forEach(rgn => {
        if(element.rgnCode === rgn.rgnCode){
          element.rgnTerm = rgn.rgnTermSimple;
        }
      });
      // 그리드 데이터 가공
      if( search.dateType === 'DD' ){
        element.power = element.dayPower !== null ? numFormat(roundNum( element.dayPower, 2 )) + " kWh" : 0 + " kWh" ;
        element.cal = element.dayCal !== null ? numFormat(roundNum( element.dayCal, 2 )) + " kWh" : 0 + " kWh";
        element.ess = element.dayCharge !== null ? numFormat(roundNum( element.dayCharge, 2 )) + " kWh" : 0 + " kWh";
      } else if( search.dateType === 'MM' ){
        element.power = element.monthPower !== null ? numFormat(roundNum( element.monthPower, 2 )) + " kWh" : 0 + " kWh" ;
        element.cal = element.monthCal !== null ? numFormat(roundNum( element.monthCal, 2 )) + " kWh" : 0 + " kWh";
        element.ess = element.monthCharge !== null ? numFormat(roundNum( element.monthCharge, 2 )) + " kWh" : 0 + " kWh";
      } else if( search.dateType === 'YYYY' ){
        element.power = element.yearPower !== null ? numFormat(roundNum( element.yearPower, 2 )) + " kWh" : 0 + " kWh" ;
        element.cal = element.yearCal !== null ? numFormat(roundNum( element.yearCal, 2 )) + " kWh" : 0 + " kWh";
        element.ess = element.yearCharge !== null ? numFormat(roundNum( element.yearCharge, 2 )) + " kWh" : 0 + " kWh";
      }
    }); 
  callback(result);
  }).catch(error => {
    reject( "지역" , error);
  });
}

/**
 * 종합보고서 비교 리스트 API
 * @param 1. 검색 날짜
 * @param 2. 날짜 형식
 * @return 보고서 비교 리스트
 */
 export const getCompareData = async(conn , callback , reject , search , codeList) => {
  await conn.get("/report/comprehensive/compare", {
    params: {
      dtm : kendo.toString(search.dtm , 'yyyy-MM-dd'),
      dateType : search.dateType
    }
  }).then(response => {
    const result = response;
    const ensoTypeList = codeList.filter( v => v.grpCodeNo === 15 );
    const ensoEnergyChart = [];
    const compareEnergyData = [];

    // 에너지원별 데이터 초기화
    ensoTypeList.forEach(element => {
      // 발전량 , 생산열량 차트 데이터 틀 제작
      ensoEnergyChart.push({
        ensoTypeCode : element.codeNo ,
        name : element.codeVal,
        y : 0
      });

      // 전일 ,금일 데이터 테이블 제작
      compareEnergyData.push({
        ensoTypeCode : element.codeNo ,
        name : element.codeVal,
        energy : 0,
        preEnergy : 0,
        accumPower : 0,
        accumCal : 0
      });

    });

    // 발전량 , 생산열량 그래프 데이터 만들기
    ensoEnergyChart.forEach(element => {
      result.forEach(data => {
        if( element.ensoTypeCode === data.ensoTypeCode ){
          element.y = roundNum(data.energy , 2);
        }
      });
    });

    // 발전량 생산열량 ESS 충전량 총량 데이터
    let preTotalPower = 0;
    let totalPower = 0;
    let preTotalCal = 0;
    let totalCal = 0;

    // 발전량 생산열량 누적 값 구하기
    let accumPower = 0;
    let accumCal = 0;

    // 발전량 생산열량 ESS 충전량 테이블 데이터 만들기
    compareEnergyData.forEach(element => {
      result.forEach(data => {
        if( element.ensoTypeCode === data.ensoTypeCode ){
          element.preEnergy = data.preEnergy !== null ? data.preEnergy : 0;
          element.energy = data.energy !== null ? data.energy : 0;
          // 누적 발전량 , 생산량 값 입력
          element.accumPower = data.accumEnergy !== null ? data.accumEnergy : 0;
          element.accumCal = data.accumEnergy !== null ? data.accumEnergy : 0;
        }
      });
      if( element.ensoTypeCode === 15001 || element.ensoTypeCode === 15004 || element.ensoTypeCode === 15006 ){
        preTotalPower += element.preEnergy !== null ? element.preEnergy : 0;
        totalPower += element.energy !== null ? element.energy : 0;
        accumPower += element.accumPower !== null ? element.accumPower : 0;
      }
      if( element.ensoTypeCode === 15002 || element.ensoTypeCode === 15003 ){
        preTotalCal += element.preEnergy !== null ? element.preEnergy : 0;
        totalCal += element.energy !== null ? element.energy : 0;
        accumCal += element.accumCal !== null ? element.accumCal : 0;
      }
    });

    // 최종 비교 데이터 객체 생성
  const compareDashboard = { 
      // 총 발전량
      totalPower : numFormat( roundNum(totalPower,2) ) 
      , totalPrePower : numFormat( roundNum(preTotalPower,2) ) 
      , totalRate : numFormat( roundNum(((totalPower - preTotalPower) / preTotalPower) * 100, 2) ) !== "NaN" ? 
      numFormat( roundNum(((totalPower - preTotalPower) / preTotalPower) * 100, 2) ) : 0
      // 태양광
      , solaPrePower : numFormat( roundNum(compareEnergyData[0].preEnergy,2) ) 
      , solaPower : numFormat( roundNum(compareEnergyData[0].energy,2) ) 
      , solaRate : numFormat( roundNum( ((compareEnergyData[0].energy - compareEnergyData[0].preEnergy) / compareEnergyData[0].preEnergy) * 100, 2) ) !== "NaN" ? 
      numFormat( roundNum( ((compareEnergyData[0].energy - compareEnergyData[0].preEnergy) / compareEnergyData[0].preEnergy) * 100, 2) ) : 0  
      // 풍력
      , veloPrePower : numFormat( roundNum(compareEnergyData[3].preEnergy,2) ) 
      , veloPower : numFormat( roundNum(compareEnergyData[3].energy,2) ) 
      , veloRate : numFormat( roundNum( ((compareEnergyData[3].energy - compareEnergyData[3].preEnergy) / compareEnergyData[3].preEnergy) * 100, 2) ) !== "NaN" ? 
      numFormat( roundNum( ((compareEnergyData[3].energy - compareEnergyData[3].preEnergy) / compareEnergyData[3].preEnergy) * 100, 2) ) : 0 
      // 연료전지
      , fuelcellPrePower : numFormat( roundNum(compareEnergyData[4].preEnergy,2) ) 
      , fuelcellPower : numFormat( roundNum(compareEnergyData[4].energy,2) ) 
      , fuelcellRate : numFormat( roundNum( ((compareEnergyData[4].energy - compareEnergyData[4].preEnergy) / compareEnergyData[4].preEnergy) * 100, 2) ) !== "NaN" ? 
      numFormat( roundNum( ((compareEnergyData[4].energy - compareEnergyData[4].preEnergy) / compareEnergyData[4].preEnergy) * 100, 2) ) : 0 
      // 총 생산열량
      , totalCal : numFormat( roundNum(totalCal,2) ) 
      , totalPreCal : numFormat( roundNum(preTotalCal,2) ) 
      , totalCalRate : numFormat( roundNum(((totalCal - preTotalCal) / preTotalCal) * 100, 2) ) !== "NaN" ? 
      numFormat( roundNum(((totalCal - preTotalCal) / preTotalCal) * 100, 2) )  : 0
      // 태양열
      , heatPreCal : numFormat( roundNum(compareEnergyData[1].preEnergy, 2) )  
      , heatCal : numFormat( roundNum(compareEnergyData[1].energy,2) )  
      , heatRate : numFormat( roundNum( ((compareEnergyData[1].energy - compareEnergyData[1].preEnergy) / compareEnergyData[1].preEnergy) * 100 ,2) ) !== "NaN" ? 
      numFormat( roundNum( ((compareEnergyData[1].energy - compareEnergyData[1].preEnergy) / compareEnergyData[1].preEnergy) * 100, 2) ) : 0 
      // 지열
      , heathPreCal : numFormat( roundNum(compareEnergyData[2].preEnergy, 2) )  
      , heathCal : numFormat( roundNum(compareEnergyData[2].energy,2) )  
      , heathRate : numFormat( roundNum( ((compareEnergyData[2].energy - compareEnergyData[2].preEnergy) / compareEnergyData[2].preEnergy) * 100 ,2) ) !== "NaN" ? 
      numFormat( roundNum( ((compareEnergyData[2].energy - compareEnergyData[2].preEnergy) / compareEnergyData[2].preEnergy) * 100, 2) ) : 0
      , referDate : result.length !== 0 ? result[0].dtm : ' - '
    }

  // 통합 대시보드 데이터
  const dashboard = {
     power : numFormat( roundNum(totalPower,2) ) , accumPower : numFormat( roundNum(accumPower,2) ) , accumCo2 : numFormat( roundNum(accumPower * 0.424,2) ) 
    , cal : numFormat( roundNum(totalCal,2) ) , accumCal : numFormat( roundNum(accumCal,2) )
  };

  callback(result , ensoEnergyChart , dashboard , compareDashboard );
  }).catch(error => {
    reject( "비교" , error);
  });
}

/**
 * 종합보고서 이벤트(경보) 리스트 API
 * @param 1. 검색 날짜
 * @param 2. 날짜 형식
 * @return 보고서 이벤트(경보) 리스트
 */
 export const getEventData = async(conn , callback , reject , search , codeList) => {
  await conn.get("/report/comprehensive/event", {
    params: {
      dtm : kendo.toString(search.dtm , 'yyyy-MM-dd'),
      dateType : search.dateType
    }
  }).then(response => {
    const result = response;
    const ensoTypeList = codeList.filter( v => v.grpCodeNo === 15 )
    const eventCntData = [];
    // 초기화 데이터 생성
    ensoTypeList.forEach(element => {
      eventCntData.push({
        ensoTypeCode : element.codeNo ,
        ensoTypeTerm : element.codeVal,
        dtm : "" , 
        totalCnt : 0 ,
        errorCnt : 0 ,
        warningCnt : 0 ,
        unnormalCnt : 0 
      });
    });

    // 전체 데이터
    eventCntData.push({
      ensoTypeCode : 15000 ,
      ensoTypeTerm : "전체",
      dtm : "" , 
      totalCnt : 0 ,
      errorCnt : 0 ,
      warningCnt : 0 ,
      unnormalCnt : 0 
    });

    // 총 갯수 초기화
    let totalTCnt = 0;
    let errorTCnt = 0;
    let warnTCnt = 0;
    let unnormalTCnt = 0;

    // 데이터 세팅
    eventCntData.forEach(element => {
      result.forEach(data => {
        if( element.ensoTypeCode === data.ensoTypeCode ){
          element.totalCnt = data.totalCnt !== null ? data.totalCnt : 0 ;
          element.errorCnt = data.errorCnt !== null ? data.errorCnt : 0 ;
          element.warningCnt = data.warningCnt !== null ? data.warningCnt : 0 ;
          element.unnormalCnt = data.unnormalCnt !== null ? data.unnormalCnt : 0 ;
           /// 전체
          totalTCnt += data.totalCnt !== null ? data.totalCnt : 0;
          errorTCnt += data.errorCnt !== null ? data.errorCnt : 0;
          warnTCnt += data.warningCnt !== null ? data.warningCnt : 0;
          unnormalTCnt += data.unnormalCnt !== null ? data.unnormalCnt : 0;
        }
      });
    });

    // 전체 데이터 세팅
    eventCntData.forEach(element => {
      if( element.ensoTypeCode === 15000 ){
        element.totalCnt = totalTCnt; 
        element.errorCnt = errorTCnt; 
        element.warningCnt = warnTCnt; 
        element.unnormalCnt = unnormalTCnt; 
      }
    })
  callback(eventCntData);
  }).catch(error => {
    reject( "경보" , error);
  });
}