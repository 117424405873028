// 유저 선택 리스트
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { Window } from "@progress/kendo-react-dialogs";
import { getInstaller } from "../../../service/mngt/installer/installerService";
import GridColumnInputFilter from "../../com/gridFilter/gridColumnInputFilter";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import kendo from "@progress/kendo-ui";
import styles from "./consm.module.css";
import { Input } from "@progress/kendo-react-inputs";

function ConsmList({ conn, setUserInfoVisible, selectUserId, rgnList, dongList }) {
  const [searchConsm, setSearchConsm] = useState({
    userTerm: "",
    rgnCode: "",
    fixbylawBundCode: "",
    // rgnTerm : "" ,
    // dongTerm : "" ,
    // rgn : { rgnCode : "", rgnTerm : "시군구" },
    // dong : { fixbylawBundCode : "", fixbylawBundTerm : "읍면동" }
  });

  const defaultValRgn = { rgnTermSimple: "시/군/구", rgnCode: "" };
  const defaultValDong = { fixbylawBundTermSimple: "읍/면/동", fixbylawBundCode: "" };

  const [userList, setUserList] = useState({
    skip: 0,
    take: 10,
    filter: {
      userTerm: "",
      rgnCode: "",
      fixbylawBundCode: "",
    },
    userDataSource: [],
    total: 0,
    userId: "",
  });
  // 지역검색 읍/면/동
  const dongDs = dongList.filter((e) => e.rgnCode !== "" && e.rgnCode === userList.filter.rgnCode);

  const [userListBackup, setUserListBackup] = useState([]);

  // 유저 다이어로그 닫기 (윈도우 창)
  const toggleDialog = () => {
    setUserInfoVisible(false);
  };

  // 윈도우창 top , left 값 조정
  // const controlWindowPosition = (params) =>{
  //   // 공통 테이블 설정

  //   const table = document.getElementsByClassName("Ch-TableN")[0];
  //   return table * 0.11;
  // }

  const getUserList = () => {
    getInstaller(conn, userList)
      .then((response) => {
        setUserList((userList) => {
          const item = { ...userList };
          item.userDataSource = response.results;
          item.total = response.total;
          return item;
        });

        setUserListBackup((userList) => {
          const item = { ...userList };
          item.userDataSource = response.results;
          item.total = response.total;
          return item;
        });
      })
      .catch((error) => {
        // 현재 Installer에서 catch 코드 구현 안되어서 일단 임시로 작성
        // 추후에 코드 추가하면 Validaion 적용
        kendo.alert("수용가 목록을 불러오지 못하였습니다.");
        return;
      });
  };

  // 조회 시 이벤트
  const searchUser = () => {
    getUserList();
  };

  // 페이징 이벤트
  const onPageChange = (e) => {
    let { take, skip } = e.page;
    userList.take = take;
    userList.skip = skip;
    getUserList();
  };

  useEffect(() => {
    getUserList();
  }, []);

  // 엑셀 다운로드 이벤트
  // const onClickEvt = (e) => {
  // };

  // 드롭다운 이벤트
  // const onChangeEvt = (e) =>{
  //     setSearchConsm(searchConsm => {
  //       const item = {...searchConsm};
  //       item.rgnCode = e.target.value.rgnCode;
  //       return item;
  //     });

  //   // useCallback((e) =>
  //     setUserList((state) => {
  //       const item = { ...state };
  //         item.filter.rgnCode = e.target.value.rgnCode;
  //         return item;
  //     })
  //   // )
  // }

  const filterChange = (event) => {
    setUserList((userList) => {
      const item = { ...userList };
      const filter = event.filter.filters[0];
      if (filter.field === "userAddr") {
        item.filter.address = filter.value ? filter.value : "";
      } else if (filter.field === "userTerm") {
        item.filter.userTerm = filter.value ? filter.value : "";
      } else {
        item.filter.address = "";
        item.filter.userTerm = "";
      }
      return item;
    });
    getUserList();
  };

  // const consmNoCell = (props) => {
  //   var record = 0;
  //   record = (userList.total - props.dataIndex)

  //   return <td>{record}</td>
  // }

  const srchInptChngEvt = (e) => {
    const { value } = e;
    setUserList((userList) => {
      const item = { ...userList };
      item.filter.userTerm = value;
      return item;
    });
  };

  const keyPressEvt = (e) => {
    if ((e.key = "Enter")) {
      searchUser();
    }
  };

  return (
    <div>
      <Window
        title={"유저 목록 리스트"}
        onClose={toggleDialog}
        initialHeight={550}
        initialWidth={550}
        initialLeft={600}
        initialTop={200}
        modal={true}
        resizable={false}
        maximizeButton={"none"}
        minimizeButton={"none"}
        className="userListWindow"
      >
        <div className={styles.searchUserArea}>
          <span>지역검색</span>
          <DropDownList
            id="rgn"
            name="rgn"
            data={rgnList}
            className="searchConsm"
            value={searchConsm.rgn}
            defaultItem={defaultValRgn}
            dataItemKey="rgnCode"
            textField="rgnTermSimple"
            onChange={useCallback((e) =>
              setUserList((state) => {
                const item = { ...state };
                item.filter.rgnCode = e.target.value.rgnCode;
                return item;
              }),
            )}
            // onChange = {onChangeEvt}
          />
          <DropDownList
            id="dong"
            name="dong"
            data={dongDs}
            className="searchConsm"
            defaultItem={defaultValDong}
            dataItemKey="fixbylawBundCode"
            textField="fixbylawBundTermSimple"
            onChange={useCallback((e) =>
              setUserList((state) => {
                const item = { ...state };
                item.filter.rgnCode = e.target.value.rgnCode;
                item.filter.fixbylawBundCode = e.target.value.fixbylawBundCode;
                return item;
              }),
            )}
          />
          <Input type="text" name="userId" className="searchConsm" onChange={srchInptChngEvt} onKeyPress={keyPressEvt}></Input>
          <Button className="consmSeachBtn" onClick={searchUser}>
            검색
          </Button>
        </div>
        <br></br>
        <Grid
          style={{ height: "400px" }}
          className="installerList"
          data={userList.userDataSource}
          onRowClick={selectUserId}
          total={userList.total}
          skip={userList.skip}
          take={userList.take}
          pageSize={userList.take}
          onPageChange={onPageChange}
          pageable={{
            type: "numeric",
            pageSizes: [5, 10],
            previousNext: true,
            buttonCount: 5,
            refresh: true,
          }}
          // filterable={true}
          onFilterChange={filterChange}
        >
          <GridNoRecords>{"조회 결과가 없습니다."}</GridNoRecords>
          <GridColumn field="userAddr" name="address" title="주소" filterCell={GridColumnInputFilter} width="250px"></GridColumn>
          <GridColumn field="userTerm" name="userTerm" title="수용가" filterCell={GridColumnInputFilter}></GridColumn>
        </Grid>
      </Window>
    </div>
  );
}

export default ConsmList;
