// 유저 검색 윈도우 창
import { Window } from "@progress/kendo-react-dialogs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { useEffect, useState } from "react";
import { BeatLoader } from "react-spinners";
import { getInstaller } from "../../../../service/mngt/installer/installerService";
import GridColumnInputFilter from "../../../com/gridFilter/gridColumnInputFilter";
import styles from "./cmpUserByUser.module.css";

const UserInfoWindow = ({ conn, setSearch, kendo, rgnList, dongList, height, width }) => {
  // 검색 상태
  const [searchConsm, setSearchConsm] = useState({
    userTerm: "",
    rgnCode: "",
    fixbylawBundCode: "",
    rgn: { rgnTermSimple: "시/군/구", rgnCode: "" },
    dong: { fixbylawBundTermSimple: "읍/면/동", fixbylawBundCode: "" },
  });
  const [loading, setLoading] = useState(false);

  // 유저 리스트
  const [userList, setUserList] = useState({
    skip: 0,
    take: 10,
    filter: { userTerm: "", address: "" },
    userDataSource: [],
    total: 0,
  });

  // 유저리스트 조회
  const getUserList = () => {
    setLoading(true);
    getInstaller(conn, userList)
      .then((response) => {
        setLoading(false);
        setUserList((userList) => {
          const item = { ...userList };
          item.userDataSource = response.results;
          item.total = response.total;
          return item;
        });
      })
      .catch((error) => {
        setLoading(false);
        // 현재 Installer에서 catch 코드 구현 안되어서 일단 임시로 작성
        // 추후에 코드 추가하면 Validaion 적용
        kendo.alert("수용가 목록을 불러오지 못하였습니다.");
        return;
      });
  };

  // 조회 시 이벤트
  const searchUser = () => {
    getUserList();
  };

  // 유저 다이어로그 닫기 (윈도우 창)
  const toggleDialog = () => {
    setSearch((search) => {
      const item = { ...search };
      item.userInfoVisible = false;
      return item;
    });
  };

  // 페이징 이벤트
  const onPageChange = (e) => {
    let { take, skip } = e.page;
    userList.take = take;
    userList.skip = skip;
    getUserList();
  };

  // UserId 선택
  const selectUserId = (e) => {
    setSearch((search) => {
      const item = { ...search };
      item.userId = e.dataItem.userId;
      item.userTerm = e.dataItem.userTerm;
      item.rgnCode = e.dataItem.rgnCode;
      item.dongCode = e.dataItem.fixbylawBundCode;
      item.userInfoVisible = false;
      return item;
    });
  };

  // 필터 이벤트
  const filterChange = (event) => {
    setUserList((userList) => {
      const item = { ...userList };
      const filter = event.filter.filters[0];
      if (filter.field === "userAddr") {
        item.filter.address = filter.value ? filter.value : "";
      } else if (filter.field === "userTerm") {
        item.filter.userTerm = filter.value ? filter.value : "";
      } else {
        item.filter.address = "";
        item.filter.userTerm = "";
      }
      return item;
    });
    getUserList();
  };

  useEffect(() => {
    getUserList();
  }, []);

  return (
    <div className="uk-grid">
      <div className="uk-width-medium-1-1">
        <Window
          style={loading ? { pointerEvents: "none" } : null}
          title={"유저 목록 리스트"}
          onClose={toggleDialog}
          initialHeight={height ? height : 550}
          initialWidth={width ? width : 550}
          modal={true}
          resizable={false}
          minimizeButton={"none"}
          maximizeButton={"none"}
          className="userListWindow"
        >
          {/* <div className = {styles.searchUserArea}>
          <span>지역검색</span>
          <DropDownList id = "rgn" name = "rgn" data = {rgnList} className = "searchConsm" value = {searchConsm.rgn}
                defaultItem = {{ rgnTermSimple: "시/군/구", rgnCode: "" }} dataItemKey="rgnCode" textField="rgnTermSimple" 
                onChange={(e) =>
                  setUserList((state) => {
                    const item = { ...state };
                    searchConsm.rgn = e.target.value;
                    searchConsm.dong = { fixbylawBundTermSimple: "읍/면/동", fixbylawBundCode: "" };
                    item.filter.fixbylawBundCode = "";
                    searchConsm.rgnCode = e.target.value.rgnCode;
                    item.filter.rgnCode = e.target.value.rgnCode;
                    return item;
                  })
                } 
                />
          <DropDownList id = "dong" name = "dong" data = {dongList.filter(e => e.rgnCode === searchConsm.rgnCode)} className = "searchConsm" value = {searchConsm.dong}
                defaultItem = {{ fixbylawBundTermSimple: "읍/면/동", fixbylawBundCode: "" }} dataItemKey="fixbylawBundCode" 
                disabled = {searchConsm.rgnCode !== "" ? false : true}
                textField = "fixbylawBundTermSimple" 
                onChange={(e) =>
                  setUserList((state) => {
                    const item = { ...state };
                    searchConsm.dong = e.target.value;                    
                    item.filter.fixbylawBundCode = e.target.value.fixbylawBundCode;
                    return item;
                  })
                } 
                />
          <span className = {styles.userInputTermArea}>
            <input type = "text" name = "userId" className={styles.searchUserTermInput} onChange = { e => {
              setUserList(userList => {
                const item = {...userList};
                item.filter.userTerm = e.target.value;
                return item;
              });
            } } ></input>
            <a id = "inqBtn" icon = "search" className = {styles.searchBtnUser} name = "inqBtn" onClick={searchUser} ><img className = {styles.searchImg} src = {"/images/icon_d.png"} /></a>
          </span>
        </div> */}

          <Grid
            style={{ height: "100%" }}
            className="userInfoWindowGrid"
            data={userList.userDataSource}
            onRowClick={selectUserId}
            total={userList.total}
            skip={userList.skip}
            take={userList.take}
            pageSize={userList.take}
            onPageChange={onPageChange}
            pageable={{
              type: "numeric",
              pageSizes: [5, 10, 15],
              previousNext: true,
              buttonCount: 5,
              refresh: true,
            }}
            filterable={true}
            onFilterChange={filterChange}
          >
            <GridNoRecords>{"조회 결과가 없습니다."}</GridNoRecords>
            <GridColumn field="userTerm" name="userTerm" title="수용가" filterCell={GridColumnInputFilter} width={width ? width * 0.45 : "250px"}></GridColumn>
            <GridColumn field="userAddr" name="address" title="주소" filterCell={GridColumnInputFilter} width={width ? width * 0.45 : "250px"}></GridColumn>
          </Grid>
        </Window>
      </div>
      {loading && (
        <p className="loadingSpinner">
          <BeatLoader loading={true} size={24} color="#1e88e5" />
        </p>
      )}
    </div>
  );
};

export default UserInfoWindow;
