import kendo from "@progress/kendo-ui";
import { roundNum } from "../../../com/dataUtil";

// 대시보드
export const getDashboard = async (conn, callback, reject, param) => {
  await conn
    .get("stat/enso/dashboard", {
      params: {
        ensoTypeCodeNo: param.ensoTypeCodeNo,
        bizSct: param.bizSct,
        bizYy: param.bizYy,
        bldSrvCodeNo: param.bldSrvCodeNo,
        unit: param.unit,
        startGathDtm: kendo.toString(param.start, "yyyy-MM-dd"),
        endGathDtm: kendo.toString(param.end, "yyyy-MM-dd"),
      },
    })
    .then((response) => {
      callback(response);
    })
    .catch((error) => {
      reject(error);
    });
};

// 시간대별 그래프
export const getTimeDataList = async (conn, callback, reject, param) => {
  await conn
    .get("stat/enso/time", {
      params: {
        ensoTypeCodeNo: param.ensoTypeCodeNo,
        bizSct: param.bizSct,
        bizYy: param.bizYy,
        bldSrvCodeNo: param.bldSrvCodeNo,
        unit: param.unit,
        startGathDtm: kendo.toString(param.start, "yyyy-MM-dd"),
        endGathDtm: kendo.toString(param.end, "yyyy-MM-dd"),
        dateFormat: param.unit,
      },
    })
    .then((response) => {
      const result = response;
      let seriesDs = [];
      // 그래프 데이터 가공
      result.forEach((element) => {
        seriesDs.push({ name: element.gathDtm, y: element.dayPower });
      });
      callback(result, seriesDs);
    })
    .catch((error) => {
      reject(error);
    });
};

// 지역별 그래프
export const getAreaDataList = async (conn, callback, reject, param) => {
  await conn
    .get("stat/enso/area", {
      params: {
        ensoTypeCodeNo: param.ensoTypeCodeNo,
        bizSct: param.bizSct,
        bizYy: param.bizYy,
        bldSrvCodeNo: param.bldSrvCodeNo,
        unit: param.unit,
        startGathDtm: kendo.toString(param.start, "yyyy-MM-dd"),
        endGathDtm: kendo.toString(param.end, "yyyy-MM-dd"),
        dateFormat: param.unit,
      },
    })
    .then((response) => {
      const result = response;

      // 설비 용량 전체 합
      let sumInstCapa = 0;
      let no = 1;
      result.forEach((element) => {
        sumInstCapa += element.instCapa;
      });
      // (순위 , 설비용량비율 구하기) , 설비용량, 생산량, 용량대비 생산량 , 소수점2자리 붙이기
      result.forEach((element) => {
        element.ratio = element.instCapa / sumInstCapa ? roundNum((element.instCapa / sumInstCapa) * 100, 2) : 0;
        element.no = no++;
        if (element.instVsPower !== null) {
          element.instVsPower = roundNum(element.instVsPower, 2);
        }
        if (element.instVsUse !== null) {
          element.instVsUse = roundNum(element.instVsUse, 2);
        }
        if (element.dayPower !== null) {
          element.dayUse = roundNum(element.dayPower, 2);
        }
        if (element.dayUse !== null) {
          element.dayUse = roundNum(element.dayUse, 2);
        }
        if (element.efic !== null) {
          element.efic = roundNum(element.efic, 2);
        }
        if (element.maxEfic !== null) {
          element.maxEfic = roundNum(element.maxEfic, 2);
        }
        if (element.minEfic !== null) {
          element.minEfic = roundNum(element.minEfic, 2);
        }
        element.instCapa = roundNum(element.instCapa, 2);
      });

      // 그래프 데이터 가공
      let meainTypeData = [];
      let meainTypeInstCapaData = [];
      result.forEach((element) => {
        meainTypeData.push({ name: element.rgnTerm, y: element.efic, rgnCode: element.rgnCode });
        meainTypeInstCapaData.push({ name: element.rgnTerm, y: element.ratio, instCapa: element.instCapa, rgnCode: element.rgnCode });
      });
      const chart = { meainTypeData: meainTypeData, meainTypeInstCapaData: meainTypeInstCapaData };
      callback(result, chart, param.ensoTypeCodeNo);
    })
    .catch((error) => {
      reject(error);
    });
};

// 지역별 그래프 - 해당 지역의 읍/면/동
export const getAreaDataListByRgnCode = async (conn, callback, reject, param, rgnCode) => {
  await conn
    .get("stat/enso/area", {
      params: {
        ensoTypeCodeNo: param.ensoTypeCodeNo,
        bizSct: param.bizSct,
        bizYy: param.bizYy,
        bldSrvCodeNo: param.bldSrvCodeNo,
        unit: param.unit,
        startGathDtm: kendo.toString(param.start, "yyyy-MM-dd"),
        endGathDtm: kendo.toString(param.end, "yyyy-MM-dd"),
        dateFormat: param.unit,
        rgnCode: rgnCode,
      },
    })
    .then((response) => {
      const result = response;
      // 설비 용량 전체 합
      let sumInstCapa = 0;
      let no = 1;
      result.forEach((element) => {
        sumInstCapa += element.instCapa;
      });
      // (순위 , 설비용량비율 구하기) , 설비용량, 생산량, 용량대비 생산량 , 소수점2자리 붙이기
      result.forEach((element) => {
        element.ratio = element.instCapa / sumInstCapa ? roundNum((element.instCapa / sumInstCapa) * 100, 2) : 0;
        element.no = no++;
        if (element.instVsPower !== null) {
          element.instVsPower = roundNum(element.instVsPower, 2);
        }
        if (element.instVsUse !== null) {
          element.instVsUse = roundNum(element.instVsUse, 2);
        }
        if (element.dayPower !== null) {
          element.dayUse = roundNum(element.dayPower, 2);
        }
        if (element.dayUse !== null) {
          element.dayUse = roundNum(element.dayUse, 2);
        }
        if (element.efic !== null) {
          element.efic = roundNum(element.efic, 2);
        }
        if (element.maxEfic !== null) {
          element.maxEfic = roundNum(element.maxEfic, 2);
        }
        if (element.minEfic !== null) {
          element.minEfic = roundNum(element.minEfic, 2);
        }
        element.instCapa = roundNum(element.instCapa, 2);
        element.rgnTerm = element.fixbylawBundTerm;
      });

      // 그래프 데이터 가공
      let meainTypeData = [];
      let meainTypeInstCapaData = [];
      result.forEach((element) => {
        meainTypeData.push({ name: element.fixbylawBundTerm, y: element.efic, rgnCode: null });
        meainTypeInstCapaData.push({ name: element.fixbylawBundTerm, y: element.ratio, instCapa: element.instCapa, rgnCode: null });
      });
      const chart = { meainTypeData: meainTypeData, meainTypeInstCapaData: meainTypeInstCapaData };
      callback(result, chart, param.ensoTypeCodeNo);
    })
    .catch((error) => {
      reject(error);
    });
};

// 인버터 발전효율
export const getInverterDataList = async (conn, callback, reject, param) => {
  const response = await conn
    .get("stat/enso/sola/inverter", {
      params: {
        ensoTypeCodeNo: param.ensoTypeCodeNo,
        bizSct: param.bizSct,
        bizYy: param.bizYy,
        bldSrvCodeNo: param.bldSrvCodeNo,
        unit: param.unit,
        startGathDtm: kendo.toString(param.start, "yyyy-MM-dd"),
        endGathDtm: kendo.toString(param.end, "yyyy-MM-dd"),
        dateFormat: param.unit,
      },
    })
    .then((response) => {
      const result = response;

      // 설비 용량 전체 합
      let sumInstCapa = 0;
      let no = 1;
      result.forEach((element) => {
        sumInstCapa += element.instCapa;
      });
      // (순위 , 설비용량비율 구하기) , 설비용량, 생산량, 용량대비 생산량 , 소수점2자리 붙이기
      result.forEach((element) => {
        element.ratio = element.instCapa / sumInstCapa ? roundNum((element.instCapa / sumInstCapa) * 100, 2) : 0;
        element.no = no++;
        if (element.dayPower !== null) {
          element.dayPower = roundNum(element.dayPower, 2);
        }
        if (element.dayUse !== null) {
          element.dayUse = roundNum(element.dayUse, 2);
        }
        element.instCapa = roundNum(element.instCapa, 2);
      });

      // 그래프 가공
      const efic = [];
      const ratio = [];
      result.forEach((element) => {
        efic.push({ name: element.makrTerm, y: element.efic, makrSeriNo: element.makrSeriNo });
        ratio.push({ name: element.makrTerm, y: element.ratio, makrSeriNo: element.makrSeriNo, color: "#c1ce01", instCapa: element.instCapa });
      });
      // 드릴다운 플래그
      const drilldown = false;
      callback({ result, efic, ratio, drilldown });
    })
    .catch((error) => {
      reject(error);
    });
};

// 인버터 효율 - 제조사 모델별
export const getInverterDataListByMakr = async (conn, callback, reject, param, makrSeriNo, makrTerm) => {
  const response = await conn
    .get("stat/enso/sola/inverter", {
      params: {
        ensoTypeCodeNo: param.ensoTypeCodeNo,
        bizSct: param.bizSct,
        bizYy: param.bizYy,
        bldSrvCodeNo: param.bldSrvCodeNo,
        unit: param.unit,
        startGathDtm: kendo.toString(param.start, "yyyy-MM-dd"),
        endGathDtm: kendo.toString(param.end, "yyyy-MM-dd"),
        dateFormat: param.unit,
        makrSeriNo: makrSeriNo,
      },
    })
    .then((response) => {
      const result = response;

      // 설비 용량 전체 합
      let sumInstCapa = 0;
      let no = 1;
      result.forEach((element) => {
        sumInstCapa += element.instCapa;
      });
      // (순위 , 설비용량비율 구하기) , 설비용량, 생산량, 용량대비 생산량 , 소수점2자리 붙이기
      result.forEach((element) => {
        element.ratio = element.instCapa / sumInstCapa ? roundNum((element.instCapa / sumInstCapa) * 100, 2) : 0;
        element.no = no++;
        if (element.dayPower !== null) {
          element.dayPower = roundNum(element.dayPower, 2);
        }
        if (element.dayUse !== null) {
          element.dayUse = roundNum(element.dayUse, 2);
        }
        element.instCapa = roundNum(element.instCapa, 2);
      });

      // 그래프 가공
      const efic = [];
      const ratio = [];
      result.forEach((element) => {
        efic.push({ name: element.meainTypeTerm, y: element.efic, meainTypeSeriNo: element.meainTypeSeriNo, makrSeriNo: null });
        ratio.push({
          name: element.meainTypeTerm,
          y: element.ratio,
          meainTypeSeriNo: element.meainTypeSeriNo,
          color: "#c1ce01",
          instCapa: element.instCapa,
          makrSeriNo: null,
        });
      });
      // 드릴다운 플래그
      const drilldown = true;
      callback({ result, efic, ratio, drilldown, makrTerm });
    })
    .catch((error) => {
      reject(error);
    });
};

// 모듈 효율 - 제조사 별
export const getModuleDataList = async (conn, callback, reject, param) => {
  const response = await conn
    .get("stat/enso/sola/module", {
      params: {
        ensoTypeCodeNo: param.ensoTypeCodeNo,
        bizSct: param.bizSct,
        bizYy: param.bizYy,
        bldSrvCodeNo: param.bldSrvCodeNo,
        unit: param.unit,
        startGathDtm: kendo.toString(param.start, "yyyy-MM-dd"),
        endGathDtm: kendo.toString(param.end, "yyyy-MM-dd"),
        dateFormat: param.unit,
      },
    })
    .then((response) => {
      const result = response;

      // 설비 용량 전체 합
      let sumInstCapa = 0;
      let no = 1;
      result.forEach((element) => {
        sumInstCapa += element.instCapa;
      });
      // (순위 , 설비용량비율 구하기) , 설비용량, 생산량, 용량대비 생산량 , 소수점2자리 붙이기
      result.forEach((element) => {
        element.ratio = element.instCapa / sumInstCapa ? roundNum((element.instCapa / sumInstCapa) * 100, 2) : 0;
        element.no = no++;
        if (element.dayPower !== null) {
          element.dayPower = roundNum(element.dayPower, 2);
        }
        if (element.dayUse !== null) {
          element.dayUse = roundNum(element.dayUse, 2);
        }
        element.instCapa = roundNum(element.instCapa, 2);
      });

      // 그래프 가공
      const efic = [];
      const ratio = [];
      result.forEach((element) => {
        efic.push({ name: element.makrTerm, y: element.efic, makrSeriNo: element.makrSeriNo });
        ratio.push({ name: element.makrTerm, y: element.ratio, makrSeriNo: element.makrSeriNo, color: "#c1ce01", instCapa: element.instCapa });
      });

      // 드릴다운 플래그
      const drilldown = false;
      callback({ result, efic, ratio, drilldown });
    })
    .catch((error) => {
      reject(error);
    });
};

// 모듈효율 - 제조사 모델별
export const getModuleDataListByMakr = async (conn, callback, reject, param, makrSeriNo, makrTerm) => {
  const response = await conn
    .get("stat/enso/sola/module", {
      params: {
        ensoTypeCodeNo: param.ensoTypeCodeNo,
        bizSct: param.bizSct,
        bizYy: param.bizYy,
        bldSrvCodeNo: param.bldSrvCodeNo,
        unit: param.unit,
        startGathDtm: kendo.toString(param.start, "yyyy-MM-dd"),
        endGathDtm: kendo.toString(param.end, "yyyy-MM-dd"),
        dateFormat: param.unit,
        makrSeriNo: makrSeriNo,
      },
    })
    .then((response) => {
      const result = response;

      // 설비 용량 전체 합
      let sumInstCapa = 0;
      let no = 1;
      result.forEach((element) => {
        sumInstCapa += element.instCapa;
      });
      // (순위 , 설비용량비율 구하기) , 설비용량, 생산량, 용량대비 생산량 , 소수점2자리 붙이기
      result.forEach((element) => {
        element.ratio = element.instCapa / sumInstCapa ? roundNum((element.instCapa / sumInstCapa) * 100, 2) : 0;
        element.no = no++;
        if (element.dayPower !== null) {
          element.dayPower = roundNum(element.dayPower, 2);
        }
        if (element.dayUse !== null) {
          element.dayUse = roundNum(element.dayUse, 2);
        }
        element.instCapa = roundNum(element.instCapa, 2);
      });

      // 그래프 가공
      const efic = [];
      const ratio = [];
      result.forEach((element) => {
        efic.push({ name: element.meainTypeTerm, y: element.efic, meainTypeSeriNo: element.meainTypeSeriNo, makrSeriNo: null });
        ratio.push({
          name: element.meainTypeTerm,
          y: element.ratio,
          meainTypeSeriNo: element.meainTypeSeriNo,
          color: "#c1ce01",
          instCapa: element.instCapa,
          makrSeriNo: null,
        });
      });

      // 드릴다운 플래그
      const drilldown = true;
      callback({ result, efic, ratio, drilldown, makrTerm });
    })
    .catch((error) => {
      reject(error);
    });
};

// 결정 분류
export const getModuleCrystalDataList = async (conn, callback, reject, param) => {
  await conn
    .get("stat/enso/sola/crystal", {
      params: {
        ensoTypeCodeNo: param.ensoTypeCodeNo,
        bizSct: param.bizSct,
        bizYy: param.bizYy,
        bldSrvCodeNo: param.bldSrvCodeNo,
        unit: param.unit,
        startGathDtm: kendo.toString(param.start, "yyyy-MM-dd"),
        endGathDtm: kendo.toString(param.end, "yyyy-MM-dd"),
        dateFormat: param.unit,
      },
    })
    .then((response) => {
      const result = response;

      // 타입별
      var nullEfic = [];
      var singleEfic = [];
      var polyEfic = [];
      var data = result.solaModuleCrystalDataDtoList;
      data.forEach((element) => {
        if (element.modulCrystalClsf === "미분류") nullEfic = [element.minEfic, element.efic, element.maxEfic];
        if (element.modulCrystalClsf === "단결정") singleEfic = [element.minEfic, element.efic, element.maxEfic];
        if (element.modulCrystalClsf === "다결정") polyEfic = [element.minEfic, element.efic, element.maxEfic];
      });
      result.solaModuleCrystalDataDtoList = { nullEfic: nullEfic, singleEfic: singleEfic, polyEfic: polyEfic };

      // 시간별
      var anlyData = result.solaModuleCrystalTimeDataDtoList;
      var gathDtm = [],
        nullEficAnly = [],
        singleEficAnly = [],
        polyEficAnly = [];
      anlyData.forEach((element) => {
        gathDtm.push(element.gathDtm ? element.gathDtm : "");
        nullEficAnly.push(element.nullEfic ? element.nullEfic : 0);
        singleEficAnly.push(element.singleEfic ? element.singleEfic : 0);
        polyEficAnly.push(element.polyEfic ? element.polyEfic : 0);
      });
      result.solaModuleCrystalTimeDataDtoList = {
        gathDtm: gathDtm,
        nullEficAnly: nullEficAnly,
        singleEficAnly: singleEficAnly,
        polyEficAnly: polyEficAnly,
      };
      callback(result);
    })
    .catch((error) => {
      reject(error);
    });
};

// 설치유형
export const getInstTypeDataList = async (conn, callback, reject, param) => {
  const response = await conn
    .get("stat/enso/sola/insttype", {
      params: {
        ensoTypeCodeNo: param.ensoTypeCodeNo,
        bizSct: param.bizSct,
        bizYy: param.bizYy,
        bldSrvCodeNo: param.bldSrvCodeNo,
        unit: param.unit,
        startGathDtm: kendo.toString(param.start, "yyyy-MM-dd"),
        endGathDtm: kendo.toString(param.end, "yyyy-MM-dd"),
        dateFormat: param.unit,
      },
    })
    .then((response) => {
      const result = response;

      // 설치 유형별 종류의 효율 데이터 가공
      const solaInstType = result.solaInstTypeDtoList;
      result.solaInstTypeDtoList = [];
      var nullEfic = [];
      var curvedEfic = [];
      var slabEfic = [];
      var pitchedEfic = [];
      var groundEfic = [];
      var biEfic = [];
      for (let idx in solaInstType) {
        if (solaInstType[idx].instType === "미분류") nullEfic = [solaInstType[idx].minEfic, solaInstType[idx].efic, solaInstType[idx].maxEfic];
        if (solaInstType[idx].instType === "지상형") groundEfic = [solaInstType[idx].minEfic, solaInstType[idx].efic, solaInstType[idx].maxEfic];
        if (solaInstType[idx].instType === "평슬라브형") slabEfic = [solaInstType[idx].minEfic, solaInstType[idx].efic, solaInstType[idx].maxEfic];
        if (solaInstType[idx].instType === "곡면형") curvedEfic = [solaInstType[idx].minEfic, solaInstType[idx].efic, solaInstType[idx].maxEfic];
        if (solaInstType[idx].instType === "경사지붕형") pitchedEfic = [solaInstType[idx].minEfic, solaInstType[idx].efic, solaInstType[idx].maxEfic];
        if (solaInstType[idx].instType === "BIPV") biEfic = [solaInstType[idx].minEfic, solaInstType[idx].efic, solaInstType[idx].maxEfic];
      }
      result.solaInstTypeDtoList = {
        nullEfic: nullEfic,
        curvedEfic: curvedEfic,
        slabEfic: slabEfic,
        pitchedEfic: pitchedEfic,
        groundEfic: groundEfic,
        biEfic: biEfic,
      };

      // 설치 유형별 시간의 효율 데이터 가공
      var anlyData = result.solaInstTypeTimeDataDtoList;
      var gathDtm = [],
        nullEficAnly = [],
        outEficAnly = [],
        roofEficAnly = [],
        pitchedEficAnly = [],
        groundEficAnly = [],
        biEficAnly = [];
      anlyData.forEach((element) => {
        gathDtm.push(element.gathDtm ? element.gathDtm : "");
        nullEficAnly.push(element.nullEfic ? element.nullEfic : 0);
        outEficAnly.push(element.outEfic ? element.outEfic : 0);
        roofEficAnly.push(element.roofEfic ? element.roofEfic : 0);
        pitchedEficAnly.push(element.pitchedEfic ? element.pitchedEfic : 0);
        groundEficAnly.push(element.groundEfic ? element.groundEfic : 0);
        biEficAnly.push(element.biEfic ? element.biEfic : 0);
      });
      result.solaInstTypeTimeDataDtoList = [];
      result.solaInstTypeTimeDataDtoList = {
        gathDtm: gathDtm,
        nullEficAnly: nullEficAnly,
        outEficAnly: outEficAnly,
        roofEficAnly: roofEficAnly,
        pitchedEficAnly: pitchedEficAnly,
        groundEficAnly: groundEficAnly,
        biEficAnly: biEficAnly,
      };
      callback(result);
    })
    .catch((error) => {
      reject(error);
    });
};

// 방위각/경사각
export const getAngleData = async (conn, callback, reject, param) => {
  const response = await conn
    .get("stat/enso/sola/angle", {
      params: {
        ensoTypeCodeNo: param.ensoTypeCodeNo,
        bizSct: param.bizSct,
        bizYy: param.bizYy,
        bldSrvCodeNo: param.bldSrvCodeNo,
        unit: param.unit,
        startGathDtm: kendo.toString(param.start, "yyyy-MM-dd"),
        endGathDtm: kendo.toString(param.end, "yyyy-MM-dd"),
        dateFormat: param.unit,
      },
    })
    .then((response) => {
      const result = response;

      // 그래프 값 가공
      let chart_data = [];
      let col = 0;
      let row = 0;
      const x_categories = [];
      const y_categories = [];
      for (var i = 270; i >= 90; i -= 5) {
        x_categories.push(i);
      }
      for (let i = 0; i < 37 * 19; i++) {
        chart_data.push({
          x: col,
          y: row,
          value: null,
          count: 0,
        });
        col += 1;
        if (col >= 37) {
          col = 0;
          row += 1;
        }
      }
      for (let i = 90; i >= 0; i -= 5) y_categories.push(i);
      for (let index = 0; index < result.length; index++) {
        let azi = result[index].azimuth,
          inc = result[index].incliAngle,
          efic = result[index].efic,
          count = result[index].count;
        if (azi === null || inc === null || efic === null || count === null) continue;
        const calazi = Math.round(azi / 5) * 5;
        const calinc = Math.round(inc / 5) * 5;
        const chartIdx = Math.abs(36 - (calazi + 90) / 5) + (18 - calinc / 5) * 37;
        chart_data[chartIdx].value = efic;
        chart_data[chartIdx].count = count;
      }
      const chart = { chart: chart_data, y_categories: y_categories, x_categories: x_categories };
      callback(chart);
    })
    .catch((error) => {
      reject(error);
    });
};

// 태양열 집열기 (SOCO)
export const getSocoDataList = async (conn, callback, reject, param) => {
  const response = await conn
    .get("stat/enso/heat/soco", {
      params: {
        ensoTypeCodeNo: param.ensoTypeCodeNo,
        bizSct: param.bizSct,
        bizYy: param.bizYy,
        bldSrvCodeNo: param.bldSrvCodeNo,
        unit: param.unit,
        startGathDtm: kendo.toString(param.start, "yyyy-MM-dd"),
        endGathDtm: kendo.toString(param.end, "yyyy-MM-dd"),
        dateFormat: param.unit,
      },
    })
    .then((response) => {
      const result = response;

      // 설비 용량 전체 합
      let sumInstCapa = 0;
      let no = 1;
      result.forEach((element) => {
        sumInstCapa += element.instCapa;
      });
      // (순위 , 설비용량비율 구하기) , 설비용량, 생산량, 용량대비 생산량 , 소수점2자리 붙이기
      result.forEach((element) => {
        element.ratio = element.instCapa / sumInstCapa ? roundNum((element.instCapa / sumInstCapa) * 100, 2) : 0;
        element.no = no++;
        if (element.dayPower !== null) {
          element.dayPower = roundNum(element.dayPower, 2);
        }
        if (element.dayUse !== null) {
          element.dayUse = roundNum(element.dayUse, 2);
        }
        element.instCapa = roundNum(element.instCapa, 2);
      });

      // 그래프 가공
      const efic = [];
      const ratio = [];
      result.forEach((element) => {
        efic.push({ name: element.makrTerm, y: element.efic, makrSeriNo: element.makrSeriNo });
        ratio.push({ name: element.makrTerm, y: element.ratio, makrSeriNo: element.makrSeriNo, color: "#c1ce01", instCapa: element.instCapa });
      });

      // 드릴다운 플래그
      const drilldown = false;
      callback({ result, efic, ratio, drilldown });
    })
    .catch((error) => {
      reject(error);
    });
};

// 태양열 집열기 (SOCO) = 모델별 리스트
export const getSocoDataListByMakr = async (conn, callback, reject, param, makrSeriNo, makrTerm) => {
  const response = await conn
    .get("stat/enso/heat/soco", {
      params: {
        ensoTypeCodeNo: param.ensoTypeCodeNo,
        bizSct: param.bizSct,
        bizYy: param.bizYy,
        bldSrvCodeNo: param.bldSrvCodeNo,
        unit: param.unit,
        startGathDtm: kendo.toString(param.start, "yyyy-MM-dd"),
        endGathDtm: kendo.toString(param.end, "yyyy-MM-dd"),
        dateFormat: param.unit,
        makrSeriNo: makrSeriNo,
      },
    })
    .then((response) => {
      const result = response;

      // 설비 용량 전체 합
      let sumInstCapa = 0;
      let no = 1;
      result.forEach((element) => {
        sumInstCapa += element.instCapa;
      });
      // (순위 , 설비용량비율 구하기) , 설비용량, 생산량, 용량대비 생산량 , 소수점2자리 붙이기
      result.forEach((element) => {
        element.ratio = element.instCapa / sumInstCapa ? roundNum((element.instCapa / sumInstCapa) * 100, 2) : 0;
        element.no = no++;
        if (element.dayPower !== null) {
          element.dayPower = roundNum(element.dayPower, 2);
        }
        if (element.dayUse !== null) {
          element.dayUse = roundNum(element.dayUse, 2);
        }
        element.instCapa = roundNum(element.instCapa, 2);
      });

      // 그래프 가공
      const efic = [];
      const ratio = [];
      result.forEach((element) => {
        efic.push({ name: element.meainTypeTerm, y: element.efic, meainTypeSeriNo: element.meainTypeSeriNo, makrSeriNo: null });
        ratio.push({
          name: element.meainTypeTerm,
          y: element.ratio,
          meainTypeSeriNo: element.meainTypeSeriNo,
          color: "#c1ce01",
          instCapa: element.instCapa,
          makrSeriNo: null,
        });
      });

      // 드릴다운 플래그
      const drilldown = true;
      callback({ result, efic, ratio, drilldown, makrTerm });
    })
    .catch((error) => {
      reject(error);
    });
};
