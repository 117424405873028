import { useState, useEffect } from "react";
import styles from "./installerMeain.module.css";

import { Checkbox } from "@progress/kendo-react-inputs";

const InstallerMeainCount = ({ search, statCnt, setSearch }) => {
  const isMobile = localStorage.getItem("isMobile");
  const setMeainStatCheckEvt = (e, type) => {
    if (type === 7001) setSearch({ ...search, statReady: e.value });
    else if (type === 7002) setSearch({ ...search, statNormal: e.value });
    else if (type === 7004) setSearch({ ...search, statUnOper: e.value });
    else if (type === 7006) setSearch({ ...search, statWarn: e.value });
    else if (type === 7007) setSearch({ ...search, statError: e.value });
  };
  return (
    <>
      {isMobile === "Y" ? (
        <div>
          <table>
            <tr>
              <td>
                <Checkbox
                  defaultChecked={search.statNormal}
                  className={styles.meainStatCount}
                  label={<b className="textGre">정상 ({statCnt.normalCnt})</b>}
                  onChange={(e) => setMeainStatCheckEvt(e, 7002)}
                />
              </td>
              <td>
                <Checkbox
                  defaultChecked={search.statUnOper}
                  className={styles.meainStatCount}
                  label={<b className="textGry">미작동 ({statCnt.unOperCnt})</b>}
                  onChange={(e) => setMeainStatCheckEvt(e, 7004)}
                />
              </td>
              <td>
                <Checkbox
                  defaultChecked={search.statReady}
                  className={styles.meainStatCount}
                  label={<b className="textBl">준비 ({statCnt.readyCnt})</b>}
                  onChange={(e) => setMeainStatCheckEvt(e, 7001)}
                />
              </td>
            </tr>
            <tr>
              <td>
                <Checkbox
                  defaultChecked={search.statWarn}
                  className={styles.meainStatCount}
                  label={<b className="textYl">경고 ({statCnt.warnCnt})</b>}
                  onChange={(e) => setMeainStatCheckEvt(e, 7006)}
                />
              </td>
              <td>
                <Checkbox
                  defaultChecked={search.statError}
                  className={styles.meainStatCount}
                  label={<b className="textRd">고장 ({statCnt.errorCnt})</b>}
                  onChange={(e) => setMeainStatCheckEvt(e, 7007)}
                />
              </td>
            </tr>
          </table>
        </div>
      ) : (
        <div className="checkboxBox">
          <Checkbox
            defaultChecked={search.statNormal}
            className={styles.meainStatCount}
            label={<b className="textGre">정상 ({statCnt.normalCnt})</b>}
            onChange={(e) => setMeainStatCheckEvt(e, 7002)}
          />
          <Checkbox
            defaultChecked={search.statUnOper}
            className={styles.meainStatCount}
            label={<b className="textGry">미작동 ({statCnt.unOperCnt})</b>}
            onChange={(e) => setMeainStatCheckEvt(e, 7004)}
          />
          <Checkbox
            defaultChecked={search.statReady}
            className={styles.meainStatCount}
            label={<b className="textBl">운영준비중 ({statCnt.readyCnt})</b>}
            onChange={(e) => setMeainStatCheckEvt(e, 7001)}
          />
          <Checkbox
            defaultChecked={search.statWarn}
            className={styles.meainStatCount}
            label={<b className="textYl">경고 ({statCnt.warnCnt})</b>}
            onChange={(e) => setMeainStatCheckEvt(e, 7006)}
          />
          <Checkbox
            defaultChecked={search.statError}
            className={styles.meainStatCount}
            label={<b className="textRd">고장 ({statCnt.errorCnt})</b>}
            onChange={(e) => setMeainStatCheckEvt(e, 7007)}
          />
          <span className={["k-state-default", styles.meainStatCount, styles.statusViewHelp].join(" ")}>
            상태표시 도움말
            <img src="/images/info.png" alt="" />
            <div className={styles.statusHelpImg}>
              <span className="helpTxtLine">
                <b style={{ color: "#05c270" }}>정상</b> : 데이터가 3시간내로 수신되며, 데이터 상태가 정상일 경우
              </span>
              <span className="helpTxtLine">
                <b style={{ color: "#9090a7" }}>미작동</b> : 데이터가 3시간동안 미수신 될 경우
              </span>
              <span className="helpTxtLine">
                <b style={{ color: "#0e65ec" }}>운영준비중</b> : 데이터가 수신된 적이 없는 경우
              </span>
              <span className="helpTxtLine">
                <b style={{ color: "#f9cc13" }}>경고</b> : 데이터가 3시간내로 수신되며, 데이터 상태가 정상이 아니고, 발전량이 0보다 큰 경우
              </span>
              <span className="helpTxtLine">
                <b style={{ color: "#ff3b3b" }}>고장</b> : 데이터가 3시간내로 수신되며, 데이터 상태가 정상이 아니고, 발전량이 없는 경우
              </span>
            </div>
          </span>
        </div>
      )}
    </>
  );
};

export default InstallerMeainCount;
