import { roundNum } from "../../com/dataUtil";

export const getAreaEnergyList = async (conn, selectedArea) => {
  const result = await conn.get('monitor/area/list', {
    params: {
      areaType: selectedArea.areaType,
      rgnCode: selectedArea.rgnCode
    }
  });

  const totalEnergy = {
    dayPower: 0,
    dayPowerCo2: 0,
    powerCapa: 0,
    accumPower: 0,
    dayUse: 0,
    dayUseCo2: 0,
    heatCapa: 0,
    heathCapa: 0,
    accumUse: 0
  };
  if (result)
    result.map(item => {
      totalEnergy.powerCapa += item.solaCapa + item.veloCapa + item.fuelcellCapa;
      totalEnergy.dayPower += item.solaDayPower + item.veloDayPower + item.fuelcellDayPower;
      totalEnergy.accumPower += item.solaAccumPower + item.veloAccumPower + item.fuelcellAccumPower;
      totalEnergy.dayUse += item.heatDayUse + item.heathDayUse;
      totalEnergy.accumUse += item.heatAccumUse + item.heathAccumUse;
      totalEnergy.heatCapa += item.heatCapa;
      totalEnergy.heathCapa += item.heathCapa;
      if (selectedArea.areaType == 'RGN') item.areaTerm = item.rgnTerm;
      else item.areaTerm = item.dongTerm;
      return item;
    });

  totalEnergy.dayPowerCo2 = roundNum((totalEnergy.dayPower / 1000) * 0.46553, 2);
  totalEnergy.dayUseCo2 = roundNum((totalEnergy.dayUse / 1000) * 0.4653, 3);

  return { areaEnergyList: result, areaEnergyTotal: totalEnergy };

};



export const getMeainStatCntList = async (conn, selectedArea) => {
  const result = await conn.get('monitor/area/meainStatus', {
    params: {
      areaType: selectedArea.areaType,
      rgnCode: selectedArea.rgnCode
    }
  });

  const totalCnt = {
    ensoTypeCode: 15000,
    readyCnt: 0,
    normalCnt: 0,
    unOperCnt: 0,
    waringCnt: 0,
    errorCnt: 0
  };

  if (!result)
    return [];

  result.map(item => {
    totalCnt.readyCnt += item.readyCnt;
    totalCnt.normalCnt += item.normalCnt;
    totalCnt.unOperCnt += item.unOperCnt;
    totalCnt.waringCnt += item.waringCnt;
    totalCnt.errorCnt += item.errorCnt;
    return item;
  });
  result.splice(0, 0, totalCnt);

  return result;

};

export const getRemsAreaEnergyList = async (conn, selectedArea) => {
  const result = await conn.get('monitor/area/remsAreaList', {
    params: {
      areaType: selectedArea.areaType,
      rgnCode: selectedArea.rgnCode
    }
  });

  const totalEnergy = {
    dayPower: 0,
    dayPowerCo2: 0,
    powerCapa: 0,
    accumPower: 0,
    dayUse: 0,
    dayUseCo2: 0,
    heatCapa: 0,
    heathCapa: 0,
    accumUse: 0,
  };

  if (result) {
    result.forEach(item => {
      // 전기에너지 설비용량
      totalEnergy.powerCapa += item.solaCapa + item.veloCapa + item.fuelcellCapa;
      // 금일발전량
      totalEnergy.dayPower += item.solaDayPower + item.veloDayPower + item.fuelcellDayPower;

      // 누적값은 클롤링 안함 
      // totalEnergy.accumPower +=             
      // totalEnergy.accumUse += 

      // 금일사용량
      totalEnergy.dayUse += item.heatDayUse + item.heathDayUse;
      // 태양열 설비용량
      totalEnergy.heatCapa += item.heatCapa;
      // 지열 설비용량 
      totalEnergy.heathCapa += item.heathCapa;
    });
  }

  totalEnergy.dayPowerCo2 = roundNum((totalEnergy.dayPower / 1000) * 0.46553, 2);
  totalEnergy.dayUseCo2 = roundNum((totalEnergy.dayUse / 1000) * 0.4653, 3);

  return { areaEnergyList: result, areaEnergyTotal: totalEnergy };
}

export const getTotalAreaEnergyList = async (conn, selectedArea) => {
  const result = await conn.get('monitor/area/totalAreaList', {
    params: {
      areaType: selectedArea.areaType,
      rgnCode: selectedArea.rgnCode
    }
  });

  const totalEnergy = {
    dayPower: 0,
    dayPowerCo2: 0,
    powerCapa: 0,
    accumPower: 0,
    dayUse: 0,
    dayUseCo2: 0,
    heatCapa: 0,
    heathCapa: 0,
    accumUse: 0,
  };

  if (result) {
    result.forEach(item => {
      console.log("item = ", item);
      // 전기에너지 설비용량
      totalEnergy.powerCapa += item.solaCapa + item.veloCapa + item.fuelcellCapa;
      // 금일발전량
      totalEnergy.dayPower += item.solaDayPower + item.veloDayPower + item.fuelcellDayPower;

      // 충남 REMS 크롤링 합계
      totalEnergy.accumPower += item.solaAccumPower + item.veloAccumPower + item.fuelcellAccumPower;
      totalEnergy.accumUse += item.heatAccumUse + item.heathAccumUse;

      // 금일사용량
      totalEnergy.dayUse += item.heatDayUse + item.heathDayUse;
      // 태양열 설비용량
      totalEnergy.heatCapa += item.heatCapa;
      // 지열 설비용량 
      totalEnergy.heathCapa += item.heathCapa;
    });
  }

  totalEnergy.dayPowerCo2 = roundNum((totalEnergy.dayPower / 1000) * 0.46553, 2);
  totalEnergy.dayUseCo2 = roundNum((totalEnergy.dayUse / 1000) * 0.4653, 3);

  console.log("totalEnergy = ", totalEnergy);

  return { areaEnergyList: result, areaEnergyTotal: totalEnergy };
}

export const getRemsMeainStatCntList = async (conn, selectedArea) => {
  const result = await conn.get('monitor/area/remsMeainStatus', {
    params: {
      areaType: selectedArea.areaType,
      rgnCode: selectedArea.rgnCode
    }
  });

  const totalCnt = {
    ensoTypeCode: 15000,
    readyCnt: 0,
    normalCnt: 0,
    unOperCnt: 0,
    waringCnt: 0,
    errorCnt: 0
  };

  const essCnt = {
    ensoTypeCode: 15007,
    readyCnt: 0,
    normalCnt: 0,
    unOperCnt: 0,
    waringCnt: 0,
    errorCnt: 0
  }

  if (result) {
    result.map(item => {
      totalCnt.readyCnt += item.readyCnt;
      totalCnt.normalCnt += item.normalCnt;
      totalCnt.unOperCnt += item.unOperCnt;
      totalCnt.waringCnt += item.waringCnt;
      totalCnt.errorCnt += item.errorCnt;
    });
    result.splice(0, 0, totalCnt);
  }

  result.splice(6, 0, essCnt);

  return result;
}

export const getTotalMeainStatCntList = async (conn, selectedArea) => {
  const result = await conn.get('monitor/area/totalMeainStatus', {
    params: {
      areaType: selectedArea.areaType,
      rgnCode: selectedArea.rgnCode
    }
  });

  const totalCnt = {
    ensoTypeCode: 15000,
    readyCnt: 0,
    normalCnt: 0,
    unOperCnt: 0,
    waringCnt: 0,
    errorCnt: 0
  };

  const essCnt = {
    ensoTypeCode: 15007,
    readyCnt: 0,
    normalCnt: 0,
    unOperCnt: 0,
    waringCnt: 0,
    errorCnt: 0
  }

  if (result) {
    result.map(item => {
      totalCnt.readyCnt += item.readyCnt;
      totalCnt.normalCnt += item.normalCnt;
      totalCnt.unOperCnt += item.unOperCnt;
      totalCnt.waringCnt += item.waringCnt;
      totalCnt.errorCnt += item.errorCnt;
    });
    result.splice(0, 0, totalCnt);
  }

  result.splice(6, 0, essCnt);

  return result;
}