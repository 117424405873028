import { ListView } from "@progress/kendo-react-listview";
import { Pager } from "@progress/kendo-react-data-tools";
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";
import InstallerItem from "./installerItem";
import { useState, useEffect } from "react";
import styles from "./installerItem.module.css";
// import { loadMessages } from "@progress/kendo-react-intl";
// import koMessage from "../../../components/bbs/ko-language.json";
// import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";
// loadMessages(koMessage, "ko-KR");
// 좌측 수용가별 리스트뷰 부분
const InstallerList = ({ installer }) => {
  // 페이징 변수
  const [page, setPage] = useState({
    skip: 0,
    take: 10,
  });

  // 페이징 이벤트
  const changePage = (e) => {
    setPage((page) => {
      const item = { ...page };
      item.skip = e.skip;
      item.take = e.take;
      return item;
    });
  };
  useEffect(() => {
    setPage({ skip: 0, take: 10 });
  }, [installer]);

  return (
    <>
      {/* <div className="scroolY chmapTable-BC listViewText" > */}
      <div className={["scroolY", "chmapTable-BC", styles.listVeiwText].join(" ")}>
        {installer.length !== 0 ? (
          <ListView
            // className={styles.installerMeainList}
            data={installer.slice(page.skip, page.take + page.skip)}
            item={InstallerItem}
          />
        ) : (
          <span className="none-data">데이터가 존재하지 않습니다.</span>
        )}
      </div>

      <div className="uk-grid">
        <div className="uk-width-medium-1-1">
          {page.count !== 0 ? (
            <LocalizationProvider language="ko-KR">
              <IntlProvider locale="ko">
                {/* <Pager skip={page.skip} take={page.take}
                  info={true}
                  type="Numeric"
                  onPageChange={changePage}
                  pageSizes={[10, 15, 20]}
                  previousNext={true} total={installer.length}
                  className="installerPager"
                />
                <Pager skip={page.skip} take={page.take}
                  onPageChange={changePage} previousNext={true} total={installer.length} /> */}
                <Pager
                  skip={page.skip}
                  take={page.take}
                  total={installer.length}
                  onPageChange={changePage}
                  buttonCount={5}
                  info={true}
                  previousNext={true}
                  type="numeric"
                  pageSizes={[10, 15, 20]}
                  className="installerPager"
                />
              </IntlProvider>
            </LocalizationProvider>
          ) : (
            <span></span>
          )}
        </div>
      </div>
    </>
  );
};

export default InstallerList;
