import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

function ReportConsmWarnChart({ chartWarn, width }) {
  // 차트 옵션
  Highcharts.setOptions({
    lang: {
      resetZoom: "초기화",
    },
  });
  let chartDayWarn = {
    chart: {
      type: "line",
      zoomType: "xy",
      height: 350,
      backgroundColor: "#f7f9fa",
      width: width !== null ? width : "100%",
    },
    title: { text: null },
    xAxis: [{ type: "category", crosshair: true }],
    yAxis: [
      {
        labels: {
          format: "{value:,.0f}",
        },
        title: {
          text: "(단위)건",
        },
      },
    ],
    credits: { enabled: false },
    tooltip: { shared: true },
    legend: { layout: "horizontal", align: "center", verticalAlign: "top", floating: false },
    series: [
      {
        name: "미작동",
        tooltip: {
          valueSuffix: " 건",
        },
        color: "#87CEEB",
        data: chartWarn.unOperCnt,
      },
      {
        name: "경고",
        tooltip: {
          valueSuffix: " 건",
        },
        color: "#F08080",
        data: chartWarn.waringCnt,
      },
      {
        name: "고장",
        tooltip: {
          valueSuffix: " 건",
        },
        color: "#9ACD32",
        data: chartWarn.errorCnt,
      },
    ],
  };

  return (
    <div className="uk-grid">
      <div className="uk-width-medium-1-1">
        <div className="reportDayBackground">
          <b> · &nbsp;일별 경보 발생 현황 </b>
        </div>
        <div className="reportDayBackground">
          <HighchartsReact Highcharts={Highcharts} options={chartDayWarn} />
        </div>
      </div>
    </div>
  );
}

export default ReportConsmWarnChart;
