
import { DropDownList } from '@progress/kendo-react-dropdowns';
import React , {useState , useEffect} from "react";
import './custom_filter.css';

// 객체 드롭다운
/**
 *  
 * @param 1. 드롭다운에 사용할 데이터 리스트 
 * @param 2. 드롭다운 불러왔을 시 처음에 설정된 Item 값 ex : { value : "" , text : "전체" } , 
 * @param 3. 선택시 해당 드롭다운의 키 값 ex : 지역 리스트라면 rgnCode , 유저라면 userId , 스트링 배열 형식이라면 'none' 
 * @param 4. 선택 시 해당 드롭다운의 보여줄 텍스트 값 : 지역 리스트라면 rgnTerm , 유저라면 userTerm , 스트링 배열 형식이라면 'none'
 * @param 5. 해당 드롭다운 사용시 데이터가 객체형식인지 스트링 배열 형식인지 true, false 값 결정 (스트링 배열 형식이면 false 으로 플래그 값을 주어야한다.), 그리고 입력을 안하면 자동으로 false 처리 한다.
 * @return 설정한 데이터 리스트의 필터 드롭다운
 */
const CustomFilter = ( data, defaultItem , key , text , isYn ) =>{
  // isYn 이 undefined 라면 true 로 처리 
  if( typeof isYn === "undefined" ){
    isYn = true;
  }
  // 상태 값에 대한 변수
  const [filterValue , setFilterValue] = useState(defaultItem);
  return class extends React.Component {
      render() {
        return (
            <div className="k-filtercell">
              {isYn === true ? 
                <DropDownList
                 data={data}
                 onChange={this.onChange}
                 defaultItem={defaultItem}
                 className = "CustomFilterDropDown"
                 textField = {text}
                 dataItemKey= {key}
                 value= {filterValue}
                /> :
                <DropDownList
                 data={data}
                 onChange={this.onChange}
                 defaultItem={defaultItem}
                 className = "CustomFilterDropDown"
                 value = {filterValue || defaultItem}
                /> 
              }
            </div>
        );
      }
      onChange = event => {
        if( isYn === true ){
          this.props.onChange({
            value: event.target.value,
            operator: event.target.value ? "eq" : "",
            syntheticEvent: event.syntheticEvent
          });
        }else{
          this.props.onChange({
            value: event.target.value !== defaultItem && event.target.value ? event.target.value : '' ,
            operator: event.target.value !== defaultItem && event.target.value ? "eq" : '',
            syntheticEvent: event.syntheticEvent
          });
        }
        setFilterValue(event.target.value)     
      }
  };
}

export default CustomFilter;