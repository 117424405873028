import WeathCmpChart from "./weathCmp_chart";
import WeathCmpSearch from "./weathCmp_search";

import styles from "./weathCmp.module.css";
import "./weathCmp.css";
import { getWeathCmpList } from "../../../service/analy/weathCmpService";
import { getUserMngRgnList, isManager } from "../../../service/authService";
// 컴마표시
import { numFormat } from "../../../com/dataUtil";

import React, { useState, useEffect, memo, useCallback, useRef } from "react";
import kendo from "@progress/kendo-ui";

import { BeatLoader } from "react-spinners";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { RadioButton } from "@progress/kendo-react-inputs";

// import { loadMessages } from "@progress/kendo-react-intl";
import koMessage from "../../../com/ko-language.json";
import { orderBy } from "@progress/kendo-data-query";

import { Grid, GridColumn as Column, GridNoRecords } from "@progress/kendo-react-grid";

import { IntlProvider, LocalizationProvider, loadMessages } from "@progress/kendo-react-intl";
import { getMessage } from "../../../com/messageUtil";
import { Button } from "@progress/kendo-react-buttons";
import { isOneManager } from "./../../../service/authService";
loadMessages(koMessage, "ko-KR");

const WeathCmp = memo(({ conn, codeList, rgnList }) => {
  // 로딩 스피너
  const [loading, setLoading] = useState(false);

  // 페이징 이벤트
  const onPageChange = (e) => {
    const { page } = e;
    setSearch((search) => {
      const item = { ...search };
      item.take = page.take;
      item.skip = page.skip;
      return item;
    });
  };

  // 정렬 이벤트
  const onSortChange = (e) => {
    const { sort } = e;
    setSearch((search) => {
      const item = { ...search };
      item.sort = sort;
      return item;
    });
  };

  const ensoList = codeList.filter((c) => c.grpCodeNo === 15);
  const dateList = codeList.filter((c) => c.grpCodeNo === 23 && c.codeNo !== 23001);

  // 어제 날짜 정의
  const today = new Date();
  const yesterday = new Date();
  const lastWeek = new Date();
  const lastMonth = new Date();
  const lastYear = new Date();
  yesterday.setDate(today.getDate() - 1);
  lastWeek.setDate(yesterday.getDate() - 6);
  lastMonth.setDate(yesterday.getDate() - 30);
  lastYear.setDate(yesterday.getDate() - 365);

  // 페이징 take 값 설정
  const takeValue = 10;

  // 검색
  const [search, setSearch] = useState({
    ensoTypeCode: 15001, // 에너지원 코드
    rgnCode: isOneManager() ? getUserMngRgnList()[0] : "", // 지역 코드 ,
    rgn: { rgnTermSimple: "전체", rgnCode: "" },
    startGathDtm: yesterday,
    endGathDtm: yesterday,
    startMax: yesterday,
    endMin: yesterday,
    dateVal: { codeNo: 23002, codeVal: "어제" },
    take: takeValue,
    skip: 0,
    sort: [],
    selectedValue: "HH",
    page: { take: takeValue, skip: 0 },
  });

  const [ensoSearch, setEnsoSearch] = useState({
    ensoTypeCode: 15001,
    ensoType: { codeNo: 15001, codeVal: "태양광" },
  });
  // 데이터 리스트
  const [dataState, setDataState] = useState({
    data: [],
    power: [],
    temp: [],
    wspd: [],
    precQty: [],
    humi: [],
    soraQty: [],
    precPrbbl: [],
  });

  const setSearchClickEvt = (e) => {
    e.preventDefault();

    // 페이지 초기화
    search.take = takeValue;
    search.skip = 0;

    // 정렬 초기화
    search.sort = [{ field: "", dir: "" }];

    // 에너지원 코드 설정
    search.ensoTypeCode = ensoSearch.ensoTypeCode;

    // 검색 시작
    setWeathCmpList();
  };

  const setWeathCmpList = () => {
    setLoading(true);
    getWeathCmpList(conn, getWeathCmpDataList, getRejection, search);
  };

  const getWeathCmpDataList = (result) => {
    // console.log("getWeathCmpDataList result = ", result);
    setDataState((dataState) => {
      const item = { ...dataState };
      item.data = result.data;
      item.power = result.power;
      item.temp = result.temp;
      item.wspd = result.wspd;
      item.precQty = result.precQty;
      item.humi = result.humi;
      item.soraQty = result.soraQty;
      item.precPrbbl = result.precPrbbl;
      return item;
    });
    setLoading(false);
  };

  const getRejection = (error) => {
    console.error("getWeathCmpList error = ", error);
    alert(getMessage(error.errCode));
  };

  const searchData = (e) => {
    if (e.target.name == "ensoTypeCodeNo") {
      setEnsoSearch((ensoSearch) => {
        const item = { ...ensoSearch };
        item.ensoType = e.target.value;
        item.ensoTypeCode = e.target.value.codeNo;
        return item;
      });
    } else if (e.target.name == "rgn") {
      setSearch((search) => {
        const item = { ...search };
        item.rgn = e.target.value;
        item.rgnCode = e.target.value.rgnCode;
        return item;
      });
    } else if (e.target.name === "date") {
      setSearch((search) => {
        const item = { ...search };
        item.dateVal = e.target.value;
        if (e.target.value.codeNo === 23002) {
          item.startGathDtm = yesterday;
        } else if (e.target.value.codeNo === 23003) {
          item.startGathDtm = lastWeek;
        } else if (e.target.value.codeNo === 23004) {
          item.startGathDtm = lastMonth;
        } else if (e.target.value.codeNo === 23005) {
          item.startGathDtm = lastYear;
        } else if (e.target.value.codeNo === 23006) {
          item.startGathDtm = yesterday;
        }
        return item;
      });
    }
  };

  // 시간별, 일별, 월별 라디오 버튼
  const radioChange = (e) => {
    setSearch((search) => {
      const item = { ...search };
      item.selectedValue = e.value;
      return item;
    });
  };

  // 엑셀 파일 이름
  let [fileName, setFileNameExcel] = useState("");

  const setFileName = () => {
    const template = "기상비교_" + kendo.toString(new Date(), "yyyyMMdd") + ".xlsx";
    return template;
  };

  // 엑셀 다운로드
  let _export;

  const downloadExcel = () => {
    setLoading(true);
    setFileNameExcel(setFileName());
    _export.save();
  };

  const completeExcel = () => {
    setLoading(false);
  };

  useEffect(() => {
    setWeathCmpList();
  }, []);

  const dataNum = (props) => {
    let dataFormat = numFormat(parseFloat(props.dataItem.power.toFixed(2)));
    return <td>{dataFormat}</td>;
  };

  return (
    <>
      <div className={loading ? styles.weathCmp : ""}>
        <div className="uk-grid">
          <div className="uk-width-medium-1-1">
            <div className="md-card TBgA">
              <div className="md-card-content small-padding">
                <WeathCmpSearch
                  ensoList={ensoList}
                  search={search}
                  ensoSearch={ensoSearch}
                  setSearch={setSearch}
                  rgnList={rgnList}
                  dateList={dateList}
                  styles={styles}
                  RadioButton={RadioButton}
                  radioChange={radioChange}
                  yesterday={yesterday}
                  setSearchClickEvt={setSearchClickEvt}
                  searchData={searchData}
                ></WeathCmpSearch>
              </div>
            </div>
            <br></br>
            <div>
              <div className="uk-grid">
                <div className="uk-width-medium-1-1">
                  <div className="md-card">
                    <div className="md-card-content">
                      <div className="md-card-toolbar">
                        <h3 className="md-card-toolbar-heading-text">기상요소 상관관계 추이</h3>
                        <div className="md-card-toolbar-actions">
                          {/* <a className="IconBtn">
                        <img src="../../../images/icon_ExelSDownB.svg"></img>
                        전체 시도별 저장
                      </a> */}
                          <Button className="weathCmpExcelBtn" onClick={downloadExcel} icon="excel">
                            엑셀 저장
                          </Button>
                        </div>
                      </div>
                      <div className={styles.weathChartDiv}>
                        <WeathCmpChart search={search} dataState={dataState}></WeathCmpChart>
                      </div>
                      <ExcelExport data={dataState.data} ref={(exporter) => (_export = exporter)} fileName={fileName} onExportComplete={completeExcel}>
                        <LocalizationProvider language="ko-KR">
                          <IntlProvider locale="ko">
                            <Grid
                              className="weathCmp"
                              style={{ height: "30rem" }}
                              data={orderBy(dataState.data, search.sort).slice(search.skip, search.take + search.skip)}
                              page={search.page}
                              total={dataState.data.length}
                              skip={search.skip}
                              take={search.take}
                              onPageChange={onPageChange}
                              onSortChange={onSortChange}
                              sort={search.sort}
                              sortable
                              pageable
                            >
                              <Column headerClassName="gridHeader" field="gathDtm" title="시간" />
                              <Column headerClassName="gridHeader" field="temp" title="온도(°C)" width="230px" />
                              <Column headerClassName="gridHeader" field="wspd" title="풍속(m/s)" width="230px" />
                              <Column headerClassName="gridHeader" field="precQty" title="강수량(mm)" width="230px" />
                              <Column headerClassName="gridHeader" field="humi" title="습도(%)" width="230px" />
                              <Column headerClassName="gridHeader" field="precPrbbl" title="강수확률(%)" width="230px" />
                              <Column headerClassName="gridHeader" field="soraQty" title="일사량(MJ/m²)" width="230px" />
                              {search.ensoTypeCode !== 15002 && search.ensoTypeCode !== 15003 ? (
                                <Column headerClassName="gridHeader" field="power" title="발전량(kWh)" cell={dataNum} width="230px" />
                              ) : (
                                <Column headerClassName="gridHeader" field="power" title="생산량(kWh)" cell={dataNum} width="230px" />
                              )}
                            </Grid>
                          </IntlProvider>
                        </LocalizationProvider>
                      </ExcelExport>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && (
        <p className={styles.weathCmpLoading}>
          <BeatLoader loading={true} size={24} color="#1e88e5" />
        </p>
      )}
    </>
  );
});
export default WeathCmp;
