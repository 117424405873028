import log from "../../com/log";
import { parseDate } from "../../com/dateUtil";
import kendo from "@progress/kendo-ui";
import { getMessage } from "../../com/messageUtil";

const eventComUrl = "event/log";

export const getEventLog = async (conn, state, data) => {
  const { take, skip, sort, filter } = state;

  const config = {
    params: {
      page: (take + skip) / take,
      size: take,
      sort: state.sortStatus,
      ensoTypeCode: data.ensoTypeCode,
      buildingUseCode: data.buildingUseCode,
      facilityStateCode: data.facilityStateCode,
      rgnCode: data.rgnCode,
      dongCode: data.dongCode,
      startDate: parseDate("LocalDateTime", data.startDate),
      endDate: parseDate("LocalDateTime", data.endDate),
      installer: data.installer,
      cid: data.cid,
      // , facilityInfo: data.facilityInfo
    },
  };

  if (filter !== null) {
    if (filter.eventTrmeCtnt !== "") config.params.eventTrmeCtnt = state.eventTrmeCtnt; // 조치내용
    if (filter.eventTrmeMan !== "") config.params.eventTrmeMan = state.eventTrmeMan; // 조치자
    if (filter.eventTrmeStatCodeNo !== "") config.params.eventTrmeStatCodeNo = state.eventTrmeStatCodeNo; // 처리상태
  }

  const result = await conn.get(eventComUrl + "/page", config);
  for (const idx of result.results) {
    idx.ocurDtm = parseDate("yyyy-mm-dd hh:mm:ss", idx.ocurDtm);
    // idx.eventTrmeDtm = parseDate('yyyy-mm-dd hh:mm:ss', idx.eventTrmeDtm);
  }

  log(result, "eventLogService.getEventLog");
  return result;
};

export const setEventLog = async (conn, data) => {
  try {
    const result = await conn.post(eventComUrl, JSON.stringify(data), {
      headers: { "Content-Type": "application/json" },
    });
    log(result, "eventLogService.setEventLog");
  } catch (e) {
    const { errCode } = e;
    kendo.alert(getMessage(errCode));
  }
};
