
/**
 * 시, 군, 구, 읍, 면, 동등
 * 지역 관련한 비동기 통신 처리
 */

export const getDongs = async (conn, rgnCode) => {
    const response = await conn.get('area/dong/list', {
        params: {
            rgnCode : rgnCode
        }
    });
    return response;
};

export const getRgns = async (conn, cityProvCode) => {
    const response = await conn.get('area/rgn/list', {
        params: {
            cityProvCode : cityProvCode
        }
    });
    return response;
};

