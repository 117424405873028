// React
import React, { useCallback, useMemo } from "react";
import log from "../../../com/log";

// Kendo
import { Dialog } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";
import { Error } from "@progress/kendo-react-labels";

// Css
import "./managerModal.css";
import { pwdRegex } from "./../../../com/regexUtil";

const ManagerModal = ({ modalState, setModalState, rgnList, handleModalSaveSubmit, handleModalUpdateSubmit }) => {
  const smsUseYn = ["N", "Y"];
  const kakaoUseYn = ["N", "Y"];
  const idRegex = new RegExp(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/);
  const idValidator = (value) => (!idRegex.test(value) ? "" : "영어만 입력해 주시기 바랍니다.");

  // 지역 Converter
  const rgnInfo = rgnList.filter((e) => e.rgnCode === modalState.formItem.rgnCode);
  rgnInfo.length !== 0 ? (modalState.formItem.rgnInfo = rgnInfo[0]) : (modalState.formItem.rgnInfo = { rgnCode: "", rgnTermSimple: "주소검색" });
  // servrRgnInfo Converter
  if (typeof modalState.formItem.servrRgnInfo !== "undefined") {
    if (modalState.formItem.servrRgnInfo.length !== 0) {
      const servrRgnInfoArray = [];
      const servrRgnInfo = modalState.formItem.servrRgnInfo;
      servrRgnInfo.forEach((element) => {
        const rgnData = rgnList.filter((e) => e.rgnTermSimple === element)[0];
        servrRgnInfoArray.push({ rgnCode: rgnData.rgnCode, rgnTermSimple: rgnData.rgnTermSimple, rgnTerm: rgnData.rgnTermSimple });
      });
      modalState.formItem.servrRgnList = servrRgnInfoArray;
    }
  }

  // password Validation
  const PwdInput = useCallback((fieldRenderProps) => {
    const { validationMessage, visited, ...others } = fieldRenderProps;
    return (
      <div>
        <Input {...others} />
        {visited && validationMessage && <Error>{validationMessage}</Error>}
      </div>
    );
  });

  const PwdChkInput = useCallback((fieldRenderProps) => {
    const { validationMessage, visited, ...others } = fieldRenderProps;
    return (
      <div>
        <Input {...others} />
        {visited && validationMessage && <Error>{validationMessage}</Error>}
      </div>
    );
  });

  // phon Validation
  const PhoneInput = useCallback((fieldRenderProps) => {
    const { validationMessage, visited, ...others } = fieldRenderProps;
    return (
      <div>
        <Input {...others} />
        {visited && validationMessage && <Error>{validationMessage}</Error>}
      </div>
    );
  });

  // Email Validation
  const EmailInput = useCallback((fieldRenderProps) => {
    const { validationMessage, visited, ...others } = fieldRenderProps;
    return (
      <div>
        <Input {...others} />
        {visited && validationMessage && <Error>{validationMessage}</Error>}
      </div>
    );
  });

  // 닫기, 취소 이벤트
  const handleClose = useCallback(() => {
    setModalState({ ...modalState, visible: !modalState.visible, title: "", formItem: [] });
  }, [modalState]);

  // 저장 버튼
  const handleSubmit = useCallback((e) => {
    modalState.title === "추가" ? handleModalSaveSubmit(e) : handleModalUpdateSubmit(e);
  });

  return (
    <>
      <Dialog title={modalState.title} onClose={handleClose} width={"22%"}>
        <Form
          onSubmit={handleSubmit}
          initialValues={modalState.formItem}
          render={(formRenderProps) => (
            <FormElement>
              <table className="mngModalTable">
                <tbody>
                  <tr>
                    <th>ID</th>
                    <td>
                      <Field
                        name="userId"
                        component={Input}
                        placeholder="아이디 입력"
                        readOnly={modalState.formItem.userId ? true : false}
                        required
                        validator={idValidator}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>담당자</th>
                    <td>
                      <Field name="userTerm" component={Input} placeholder="담당자 이름 입력" />
                    </td>
                  </tr>
                  <tr>
                    <th>시/군</th>
                    <td>
                      <Field
                        name="rgnInfo"
                        component={DropDownList}
                        data={rgnList}
                        textField="rgnTermSimple"
                        dataItemKey="rgnCode"
                        defaultValue={{ rgnCode: "", rgnTermSimple: "주소검색" }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>비밀번호</th>
                    <td>
                      <Field
                        name="loginPwd"
                        type="password"
                        component={PwdInput}
                        validator={modalState.title === "수정" ? null : pwdRegex}
                        placeholder={modalState.title === "수정" ? "수정 시에만 입력" : "8자 이상 영문,숫자,특수문자 입력"}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>비밀번호 확인</th>
                    <td>
                      <Field name="loginPwdChk" type="password" component={PwdChkInput} />
                    </td>
                  </tr>
                  <tr>
                    <th>연락처</th>
                    <td>
                      <Field name="userCelphon" type="tel" component={PhoneInput} placeholder="010-1234-5678" />
                    </td>
                  </tr>
                  <tr>
                    <th>이메일</th>
                    <td>
                      <Field name="userEmail" type="email" component={EmailInput} placeholder="myname@example.com" />
                    </td>
                  </tr>
                  <tr>
                    <th>서버지역정보</th>
                    <td>
                      <Field
                        className="servrRgnListSelect"
                        name="servrRgnList"
                        component={MultiSelect}
                        data={rgnList}
                        textField="rgnTermSimple"
                        dataItemKey="rgnCode"
                        // defaultValue={rgn[0]}
                      />
                    </td>
                  </tr>
                  {/* <tr>
                    <th>SMS 수신여부</th>
                    <td>
                      <Field name="smsNotiUseYn" component={DropDownList} data={smsUseYn} defaultValue={smsUseYn[0]} />
                    </td>
                  </tr> */}
                  <tr>
                    <th>알림톡 수신여부</th>
                    <td>
                      <Field name="kakaoNotiUseYn" component={DropDownList} data={kakaoUseYn} defaultValue={kakaoUseYn[0]} />
                    </td>
                  </tr>
                </tbody>
              </table>
              <span className="mngModalSubmitBtnArea">
                <Button onClick={handleClose}>취소</Button>
                <Button type="submit" disabled={!formRenderProps.allowSubmit}>
                  저장
                </Button>
              </span>
            </FormElement>
          )}
        />
      </Dialog>
    </>
  );
};

export default ManagerModal;
