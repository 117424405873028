// React
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { getIotKtLog, getLogUserIdList } from "../../../service/meain/iotMakers/iotKtService";
import log from "../../../com/log";
import { parseDate } from "../../../com/dateUtil";
import { downloadExcel } from "../../../com/downloadExcel";
import { BeatLoader } from "react-spinners";

// Kendo
import kendo from "@progress/kendo-ui";
import { IntlProvider, LocalizationProvider, loadMessages } from "@progress/kendo-react-intl";
import { Button } from "@progress/kendo-react-buttons";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { DropDownList } from "@progress/kendo-react-dropdowns";

// Css
import "./iotktLog.css";
import styles from "./iotktLog.module.css";

function IotktLog({ conn }) {
  // 로딩 스피너
  const [loading, setLoading] = useState(true);
  // 엑셀 로딩 스피너
  const [excelLoading, setExcelLoading] = useState(false);

  const [dataSource, setDataSource] = useState({
    dataList: [],
    total: 0,
  });
  const [dataState, setDataState] = useState({
    take: 20,
    skip: 0,
  });
  const [userIdList, setUserIdList] = useState({
    userIdArr: [],
    filterUserIdArr: [],
    selectUserId: "전체",
  });

  // 등록 ID 목록 리스트
  const getLogUserIdDropList = () => {
    getLogUserIdList(conn)
      .then((response) => {
        setUserIdList({ ...userIdList, userIdArr: response, filterUserIdArr: response });
      })
      .catch((err) => {
        log(`IotktLog.useEffect.getLogUserIdList error : ${err}`);
      });
  };

  // IotMakers 로그 리스트
  const getIotKtLogList = () => {
    getIotKtLog(conn, setLoading, dataState)
      .then((response) => {
        setLoading(false);
        const { results, total } = response;
        setDataSource((dataSource) => {
          const item = { ...dataSource };
          item.dataList = results;
          item.total = total;
          return item;
        });
      })
      .catch((err) => {
        setLoading(false);
        log(`IotktLog.useEffect.getIotKtLog error : ${err}`);
      });
  };

  useEffect(() => {
    getLogUserIdDropList();
    getIotKtLogList();
  }, []);

  // 그리드 페이징 처리
  const handlePageChange = useCallback(
    (e) => {
      const { take, skip } = e.page;
      dataState.take = take;
      dataState.skip = skip;
      getIotKtLogList();
    },
    [dataSource, dataState],
  );

  // 엑셀다운
  const handleExcelDown = useCallback((e) => {
    e.preventDefault();
    const url = "/api/v2.2/iot/kt/log";
    const data = [];
    const fileName = "IoTMakers등록로그_" + parseDate("yyyy-mm-dd") + ".xlsx";
    downloadExcel(data, fileName, url, setExcelLoading);
  }, []);

  // 등록 ID 드롭다운리스트 변경 이벤트
  const handleDropDownChange = useCallback(
    (e) => {
      const { value } = e.target;

      const stateObj = new Object();
      stateObj.take = 20;
      stateObj.skip = 0;
      stateObj.sort = [];
      userIdList.selectUserId = value;

      getIotKtLog(conn, setLoading, stateObj, value)
        .then((response) => {
          const { results, total } = response;
          setDataSource((dataSource) => {
            const item = { ...dataSource };
            item.dataList = results;
            item.total = total;
            return item;
          });
          setDataState({ take: 20, skip: 0, total, sort: [] });
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          log(`IotktLog.handleDropDownChange error : ${err}`);
        });
    },
    [dataSource, dataState],
  );

  // 등록 ID 드롭다운리스트 필터 이벤트
  const handleDropDownFilterChange = useCallback(
    (e) => {
      const { value } = e.filter;

      const filterArr = new Array();
      userIdList.userIdArr.forEach((userIdItem) => {
        if (userIdItem.startsWith(value)) filterArr.push(userIdItem);
      });

      setUserIdList({ ...userIdList, filterUserIdArr: filterArr });
    },
    [userIdList],
  );

  return (
    <>
      <div className={["iotktLogBody", excelLoading ? styles.iotKtLogG : ""].join(" ")}>
        <div className={loading ? styles.iotKtLogG : ""}>
          <h3></h3>
          <div className="iotktLogCate">
            <span>등록 ID : </span>
            <DropDownList
              data={userIdList.filterUserIdArr}
              defaultItem="전체"
              defaultValue="전체"
              filterable
              onChange={handleDropDownChange}
              onFilterChange={handleDropDownFilterChange}
            />
            <Button onClick={handleExcelDown}>
              <img className="exelSDown" src="/images/Icon_ExelSDown.svg" alt="" />
              &nbsp; 엑셀저장
            </Button>
          </div>
          <LocalizationProvider language="ko-KR">
            <IntlProvider locale="ko">
              <Grid
                className={styles.iotktLogGrid}
                data={dataSource.dataList}
                total={dataSource.total}
                skip={dataState.skip}
                take={dataState.take}
                pageable={{ buttonCount: 5, pageSizes: [5, 10, 20, 50, 100] }}
                onPageChange={handlePageChange}
              >
                <GridColumn title="등록일시" field="regiDtm" />
                <GridColumn title="IMEI" field="nbDevid" />
                <GridColumn title="등록상태" field="stateCode" />
                <GridColumn title="응답코드" field="responseCode" />
                <GridColumn title="메시지" field="message" />
                <GridColumn title="등록ID" field="regiId" />
                <GridColumn title="등록유저명" field="userTerm" />
                <GridNoRecords>데이터가 존재하지 않습니다.</GridNoRecords>
              </Grid>
            </IntlProvider>
          </LocalizationProvider>
        </div>
        {loading && (
          <p
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
            }}
          >
            <BeatLoader loading={true} size={24} color="#1e88e5" />
          </p>
        )}
        {excelLoading && (
          <p
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
            }}
          >
            <BeatLoader loading={true} size={24} color="#1e88e5" />
          </p>
        )}
      </div>
    </>
  );
}

export default IotktLog;
