import React from "react";
import "./alarmLog.css";

export const alarmLogDetail = (props) => {
  const { msg } = props.dataItem;
  const msgArr = msg.split("\n");
  return (
    <div>
      <div className="alLogDetailHeader">알림톡 메시지</div>
      <div className="alLogDetailContent">
        {msgArr.map((item) => {
          return item ? <div>{item}</div> : <br></br>;
        })}
      </div>
    </div>
  );
};
