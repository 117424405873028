//React
import React from "react";
import "./reportDay.css";

// 상단 대시보드 테이블
export const ReportDayEnergyTable = ({ reportData, dashBoard }) => {
  return (
    <div className="uk-grid">
      <div className="uk-width-medium-4-10">
        <table className="reportEnergyTable">
          <thead>
            <th colSpan="3" style={{ color: "#87CEEB" }}>
              전기 에너지
            </th>
          </thead>
          <tbody>
            <tr>
              {reportData.dateType === "DD" && <th> 일간 발전량 </th>}
              {reportData.dateType === "MM" && <th> 월간 발전량 </th>}
              {reportData.dateType === "YYYY" && <th> 년간 발전량 </th>}
              <th>누적발전량</th>
            </tr>
            <tr>
              <td scope="row">{dashBoard.power}&nbsp;kWh</td>
              <td scope="row">{dashBoard.accumPower}&nbsp;kWh</td>
            </tr>
            <tr>
              <th>누적CO2저감량</th>
              <th>이용률</th>
            </tr>
            <tr>
              <td scope="row">{dashBoard.accumCo2}&nbsp;t</td>
              <td scope="row"> - &nbsp;%</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="uk-width-medium-3-10">
        <table className="reportEnergyTable">
          <thead>
            <th colSpan="3" style={{ color: "#9ACD32" }}>
              열 에너지
            </th>
          </thead>
          <tbody>
            <tr>
              {reportData.dateType === "DD" && <th> 일간 생산열량 </th>}
              {reportData.dateType === "MM" && <th> 월간 생산열량 </th>}
              {reportData.dateType === "YYYY" && <th> 년간 생산열량 </th>}
            </tr>
            <tr>
              <td scope="row">{dashBoard.cal}&nbsp;kWh</td>
            </tr>
            <tr>
              <th>누적생산열량</th>
            </tr>
            <tr>
              <td scope="row">{dashBoard.accumCal}&nbsp;kWh</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="uk-width-medium-3-10">
        <table className="reportEnergyTable">
          <thead>
            <th colSpan="3" style={{ color: "#F08080" }}>
              ESS
            </th>
          </thead>
          <tbody>
            <tr>
              {reportData.dateType === "DD" && <th> 일간 충전량 </th>}
              {reportData.dateType === "MM" && <th> 월간 충전량 </th>}
              {reportData.dateType === "YYYY" && <th> 년간 충전량 </th>}
              <th>충전시간</th>
            </tr>
            <tr>
              <td scope="row"> - &nbsp;kWh</td>
              <td scope="row"> - &nbsp;시간</td>
            </tr>
            <tr>
              <th>누적충전량</th>
              <th>충전효율</th>
            </tr>
            <tr>
              <td scope="row"> - &nbsp;kWh</td>
              <td scope="row"> - &nbsp;%</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ReportDayEnergyTable;
