import { useCallback, useEffect, useState } from "react";
import { getDbInfo, getStatsByConsm, getStatsByConsmExcel } from "../../../../service/stats/type/statsByConsmService";
import { useHistory } from "react-router";

import log from "../../../../com/log";
//kendo
import { Grid, GridColumn as Column, GridNoRecords } from "@progress/kendo-react-grid";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { IntlProvider, LocalizationProvider, loadMessages } from "@progress/kendo-react-intl";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { ExcelExport, ExcelExportColumn, ExcelExportColumnGroup } from "@progress/kendo-react-excel-export";
import kendo from "@progress/kendo-ui";
//css
import styles from "./statsByConsm.module.css";
import { getMeainTypeTermList } from "../../../../service/meainModelService";
const StatsByConsm = ({ conn, codeList, rgnList, dongList }) => {
  const solaMultiSelData = [
    "태양광",
    "인버터모델",
    "위도",
    "경도",
    "RTU업체",
    "최종접속일",
    "최종수신일",
    "설비설치일시",
    "계통접속",
    "설치확인일",
    "AS만료일",
    "모듈용량",
    "모듈제조사",
    "모듈모델",
    "방위각",
    "경사각",
    "모듈총용량",
    "인버터용량",
    "인버터제조사",
    "설치유형",
    "모듈결정분류",
    "모듈직렬개수",
    "모듈병렬개수",
    "추적시스템",
    "건축물용도",
    "모니터링일시",
    "위상종류",
    "시공업체",
    "주소",
    "사업연도",
    "사업구분",
    "OTC_CID",
    "포트",
    "LoRa ID",
    "통신방식",
    "비고",
    "토큰값",
  ];
  const [dbInfo, setDbInfo] = useState([]);
  //엑셀 익스포트
  let _export;
  const history = useHistory();
  //엑셀 데이터
  const [excelData, setExcelData] = useState([]);
  //그리드 데이터
  const [dataSource, setDataSource] = useState({
    total: 0,
    dataList: [],
  });
  //설비모델 드롭다운 리스트 데이터
  const [meainTypeTermList, setMeainTypeTermList] = useState([]);
  //에너지원 DDL data
  const [ensoTypeCode, setEnsoTypeCode] = useState([]);
  //읍면동 DDL data <- 시군구 선택 시 선택필터링 해야함
  const [dongData, setDongData] = useState([]);
  //읍면동 DDL value <- 시군구 선택 시 필터해제 위해서 만듬
  const [dongDdl, setDongDdl] = useState({ fixbylawBundTermSimple: "읍/면/동", fixbylawBundCode: "" });
  //필터링
  const [dataState, setDataState] = useState({
    take: 20,
    skip: 0,
    sort: [],
    sortStatus: [],
    filter: {
      ensoType: { codeNo: 15001, codeVal: "태양광" }, //에너지원
      userTerm: "", //수용가명
      cid: "", //cid
      startDtm: new Date(), //시작일자
      endDtm: new Date(), //종료일자
      rgnCode: "", //시군구
      fixbylawBundCode: "", //읍면동
      alias: "", //설비명
      meainModel: "", //설비모델
    },
  });
  //DatePicker 관련 데이터
  const [calendarState, setCalendarState] = useState({
    min: new Date(),
    max: new Date(),
  });
  log(dataState, "dataState");
  log(dataSource, "dataSource");
  log(excelData, "ExcelData");
  log(dbInfo, "DBINFO");
  // 파일 이름
  const fileName = () => {
    let name = "전체";
    if (dataState.filter.ensoType === "15001") {
      name = "태양광";
    } else if (dataState.filter.ensoType === "15002") {
      name = "태양열";
    } else if (dataState.filter.ensoType === "15003") {
      name = "지열";
    } else if (dataState.filter.ensoType === "15004") {
      name = "풍력";
    } else if (dataState.filter.ensoType === "15006") {
      name = "연료전지";
    } else if (dataState.filter.ensoType === "15007") {
      name = "ESS";
    }
    const template = name + "_수용가별통계_" + kendo.toString(new Date(), "yyyyMMdd") + ".xlsx";
    return template;
  };
  useEffect(() => {
    getGridData();
    getMeainTypeTermList(conn)
      .then((response) => setMeainTypeTermList(response))
      .catch((error) => log(error, "meainTypeTermListError"));
    getDbInfo(conn, dataState)
      .then((response) => setDbInfo(response))
      .catch((error) => log(error, "setDbInfo Error!"));
  }, []);

  useEffect(() => {
    //grpCodeNo:15 에너지원만 뽑아냄
    setEnsoTypeCode(codeList.filter((c) => c.grpCodeNo === 15));
  }, [codeList]);

  // 그리드 페이징 처리
  const pageChangeEvt = useCallback(
    (e) => {
      const { take, skip } = e.page;
      dataState.skip = skip;
      dataState.take = take;
      getGridData();
    },
    [dataState],
  );

  const sortChangeEvt = (e) => {
    const { sort } = e;
    dataState.sort = sort;
    dataState.sortStatus = sort;
  };

  // 단순 그리드 데이터 조회
  const getGridData = useCallback(() => {
    getStatsByConsm(conn, dataState)
      .then((response) => {
        setDataSource((dataSource) => {
          const item = { ...dataSource };
          item.total = response.total;
          item.dataList = response.results;
          return item;
        });
      })
      .catch((error) => {
        if (error.errCode === "L007") {
          alert("로그인 한 ID는 해당 메뉴에 접근할 수 없습니다.");
          history.goBack();
          return;
        }
      });
  }, [dataState, dataSource]);

  //발전량-시간(kWh)셀
  const timeColumns = () => {
    let arr = [];
    for (let i = 0; i < 24; i++) {
      if (i < 10) arr.push(<Column field={"t0" + i} title={i + "시"} width="50px" sortable={false}></Column>);
      else arr.push(<Column field={"t" + i} title={i + "시"} width="50px" sortable={false}></Column>);
    }
    return arr;
  };

  /* 에너지원 DDL 선택 */
  const ensoTypeChngEvt = (e) => {
    const { value } = e;
    setDataState({ ...dataState, filter: { ...dataState.filter, ensoType: value } });
  };

  /* 시군구 DDL 선택 */
  const rgnDdlChngEvt = (e) => {
    const { value } = e;
    setDongDdl({ fixbylawBundTermSimple: "읍/면/동", fixbylawBundCode: "" });
    //시군구 선택 시 읍면동 선택하게 함
    setDataState({ ...dataState, filter: { ...dataState.filter, rgnCode: value.rgnCode, fixbylawBundCode: "" } });
    //읍면동 데이터 세팅
    setDongData(
      dongList
        .filter((c) => c.rgnCode === value.rgnCode)
        .sort(function (a, b) {
          return a.fixbylawBundTermSimple < b.fixbylawBundTermSimple ? -1 : a.fixbylawBundTermSimple > b.fixbylawBundTermSimple ? 1 : 0; //오름차순으로 정렬
        }),
    );
  };

  /* 읍면동 DDL 선택 */
  const dongDdlChngEvt = (e) => {
    const { value } = e;
    setDongDdl(value);
    setDataState({ ...dataState, filter: { ...dataState.filter, fixbylawBundCode: value.fixbylawBundCode } });
  };

  /* 설비 모델 DDL 선택 */
  const meainTypeTermChngEvt = (e) => {
    const { value } = e;
    setDataState({ ...dataState, filter: { ...dataState.filter, meainModel: value } });
  };

  /* 시작일 변경 이벤트 */
  const startDtmChngEvt = (e) => {
    const { value } = e;
    const year = new Date(value).getFullYear();
    const month = new Date(value).getMonth();
    const day = new Date(value).getDate();

    setCalendarState({ ...calendarState, min: value });
    setDataState({ ...dataState, filter: { ...dataState.filter, startDtm: new Date(year, month, day) } });
  };

  /* 종료일 변경 이벤트 */
  const endDtmChngEvt = (e) => {
    const { value } = e;
    const year = new Date(value).getFullYear();
    const month = new Date(value).getMonth();
    const day = new Date(value).getDate();

    setCalendarState({ ...calendarState, max: value });
    setDataState({ ...dataState, filter: { ...dataState.filter, endDtm: new Date(year, month, day, 23, 59, 59) } });
  };

  /* 수용가명 인풋 변경 이벤트 */
  const userTermInputChngEvt = (e) => {
    const { value } = e;
    setDataState({ ...dataState, filter: { ...dataState.filter, userTerm: value } });
  };

  /* CID 인풋 변경 이벤트 */
  const cIdInputChngEvt = (e) => {
    const { value } = e;
    setDataState({ ...dataState, filter: { ...dataState.filter, cid: value } });
  };

  /* 설비명 인풋 변경 이벤트 */
  const aliasInputChngEvt = (e) => {
    const { value } = e;
    setDataState({ ...dataState, filter: { ...dataState.filter, alias: value } });
  };

  //조회 버튼 클릭이벤트
  const searchStatsByConsmBtnEvt = (e) => {
    dataState.skip = 0;
    getGridData();
  };

  //그리드 넘버 셀
  const gridNumCell = (e) => {
    return <td>{e.dataIndex + 1}</td>;
  };
  //그리드 설비상태 셀
  const meainStatCell = (e) => {
    //설비상태 codeList에서 codeVal 추출
    const meainStatCodeVal = codeList.filter((c) => c.codeNo === e.dataItem.meainStatCodeNo)[0].codeVal;
    return <td>{meainStatCodeVal}</td>;
  };
  //발전량-일 셀
  const dayPowerCell = (e) => {
    const result = e.dataItem.dayPower.toFixed(2); //소수점 두번째자리 고정
    return <td>{result}</td>;
  };
  //누적발전량 셀
  const accumPowerCell = (e) => {
    const result = e.dataItem.accumPower.toFixed(2); //소수점 두번째자리 고정
    return <td>{result}</td>;
  };
  //효율-일 셀
  const dayAccumEficCell = (e) => {
    const result = e.dataItem.dayAccumEfic.toFixed(2); //소수점 두번째자리 고정
    return <td>{result}</td>;
  };

  //엑셀저장 버튼 클릭이벤트
  const excelExport = (e) => {
    getStatsByConsmExcel(conn, dataState)
      .then((response) => {
        setExcelData(response);
      })
      .catch((error) => {
        if (error.errCode === "L007") {
          alert("로그인 한 ID는 해당 메뉴에 접근할 수 없습니다.");
          history.goBack();
          return;
        }
      });
  };
  useEffect(() => {
    if (excelData.length) _export.save();
  }, [excelData]);
  return (
    <div>
      <div className={styles.filterBox}>
        <table className={styles.filterTable}>
          <tr>
            <td>
              <DropDownList data={ensoTypeCode} textField="codeVal" key="codeNo" value={dataState.filter.ensoType} onChange={ensoTypeChngEvt} />
            </td>
            <td>
              <Input placeholder="수용가명" onChange={userTermInputChngEvt}></Input>
            </td>
            <td>
              <Input placeholder="CID" onChange={cIdInputChngEvt}></Input>
            </td>
            <td colSpan={2}>
              <LocalizationProvider language="ko-KR">
                <IntlProvider locale="ko">
                  <DatePicker value={dataState.filter.startDtm} onChange={startDtmChngEvt} max={calendarState.max}></DatePicker>
                </IntlProvider>
              </LocalizationProvider>
              <span>&nbsp;~&nbsp;</span>
              <LocalizationProvider language="ko-KR">
                <IntlProvider locale="ko">
                  <DatePicker value={dataState.filter.endDtm} onChange={endDtmChngEvt} min={calendarState.min} max={new Date()}></DatePicker>
                </IntlProvider>
              </LocalizationProvider>
            </td>
          </tr>
          <tr>
            <td>
              <DropDownList
                data={rgnList}
                textField="rgnTermSimple"
                key="rgnCode"
                onChange={rgnDdlChngEvt}
                defaultItem={{ rgnTermSimple: "시/군/구", rgnCode: "" }}
              />
            </td>
            <td>
              <DropDownList
                data={dongData}
                value={dongDdl}
                textField="fixbylawBundTermSimple"
                key="fixbylawBundCode"
                disabled={!dataState.filter.rgnCode}
                onChange={dongDdlChngEvt}
                defaultItem={{ fixbylawBundTermSimple: "읍/면/동", fixbylawBundCode: "" }}
              />
            </td>
            <td>
              <DropDownList defaultItem={"설비업체 전체"} />
            </td>
            <td>
              <DropDownList
                data={meainTypeTermList}
                defaultItem={{ meaiNTypeSeriNo: 0, meainTypeTerm: "설비 모델" }}
                onChange={meainTypeTermChngEvt}
                key={"meainTypeSeriNo"}
                textField={"meainTypeTerm"}
              />
            </td>
            <td>
              <Input placeholder="설비명" onChange={aliasInputChngEvt} />
            </td>
            <td>
              <Button style={{ backgroundColor: "white" }} icon="search" onClick={searchStatsByConsmBtnEvt}>
                조회
              </Button>
            </td>
          </tr>
          <tr>
            <td colSpan={6}>
              <Input style={{ width: "100%" }}></Input>
            </td>
          </tr>
        </table>
      </div>
      <div>
        <Button className={styles.excelBtn} icon="excel" onClick={excelExport}>
          엑셀저장
        </Button>
      </div>
      <div className={styles.statConsmGridDiv}>
        <LocalizationProvider language="ko-KR">
          <IntlProvider locale="ko">
            <ExcelExport data={excelData} ref={(exporter) => (_export = exporter)} fileName={fileName()}>
              <Grid
                className={styles.statConsmGrid}
                style={{ height: "70vh" }}
                data={dataSource.dataList}
                skip={dataState.skip}
                take={dataState.take}
                total={dataSource.total}
                sort={dataState.sortStatus}
                sortable
                pageable={{ buttonCount: 5, info: true, pageSizes: true, pageSizes: [5, 10, 20, 50] }}
                onPageChange={pageChangeEvt}
                onSortChange={sortChangeEvt}
                scrollable={true}
                resizable={true}
              >
                <Column field="" title="No" width="50px" cell={gridNumCell} sortable={false}></Column>
                <Column field="cid" title="CID" width="110px"></Column>
                <Column field="userTerm" title="수용가" width="180px"></Column>
                <Column field="alias" title="설비명" width="180px" sortable={false}></Column>
                <Column field="instCapa" title="설비용량(kW)" width="105px" sortable={false}></Column>
                <Column field="meainStatCodeNo" title="설비상태" width="80px" cell={meainStatCell} sortable={false}></Column>
                <Column field="gathDtm" title="수집일자" width="90px"></Column>
                <Column field="dayPower" title="발전량-일(kWh)" width="100px" cell={dayPowerCell}></Column>
                <Column field="accumPower" title="누적발전량(kWh)" width="110px" cell={accumPowerCell}></Column>
                <Column field="dayAccumEfic" title="효율-일(%)" width="80px" cell={dayAccumEficCell}></Column>
                <Column title="발전량-시간(kWh)" width="30px" sortable={false}>
                  {timeColumns()}
                </Column>

                <GridNoRecords>{"조회 결과 데이터가 없습니다."}</GridNoRecords>
              </Grid>
            </ExcelExport>
          </IntlProvider>
        </LocalizationProvider>
      </div>
    </div>
  );
};

export default StatsByConsm;
