// 그리드
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-data-query";
import React, { useEffect, useState } from "react";
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";

const UserBetweenUserListGrid = ({ userDataList, kendo, setUserExcelData, search }) => {
  // 발전량 , 생산량 조건부 렌더링
  const isCal = search.ensoTypeCode === 15002 || search.ensoTypeCode === 15003 ? true : false;

  // 엑셀 레퍼런스
  let _excelData;

  // 페이징, 정렬 상태
  const [paging, setPaging] = useState({
    take: 10,
    skip: 0,
    sort: [{ field: "", dir: "" }],
  });

  // 페이징 이벤트
  const onPageChange = (e) => {
    const { page } = e;
    setPaging((paging) => {
      const item = { ...paging };
      item.take = page.take;
      item.skip = page.skip;
      return item;
    });
  };

  // 정렬 이벤트
  const onSortChange = (e) => {
    const { sort } = e;
    setPaging((paging) => {
      const item = { ...paging };
      item.sort = sort;
      return item;
    });
  };

  // 파일 이름
  const fileName = () => {
    let template = "";
    template += "수용가별_데이터_" + +kendo.toString(new Date(), "yyyyMMdd") + ".xlsx";
    return template;
  };

  useEffect(() => {
    // 엑셀 다운로드 데이터 설정
    setUserExcelData(_excelData);
  }, []);

  return (
    <div className="Table-scr" style={{ height: "400px" }}>
      <ExcelExport data={orderBy(userDataList, paging.sort)} ref={(exporter) => (_excelData = exporter)} fileName={fileName()}>
        <ExcelExportColumn title="수용가명" field="userTerm" />
        <ExcelExportColumn title={isCal === false ? "발전량(kWh)" : "생산량(kWh)"} field="dayEnergy" />
        <ExcelExportColumn title="설비용량(kW)" field="instCapa" />
        <ExcelExportColumn title="설비용량비율(%)" field="ratio" />
        <ExcelExportColumn title={isCal === false ? "용량대비발전량(kWh/kW.day)" : "용량대비생산량(kWh/kW.day)"} field="instCapaPerEnergy" />
        <ExcelExportColumn title="운영개소" field="count" />
      </ExcelExport>
      <Grid
        style={{ height: "400px" }}
        className="userBetweenUserRankGrid"
        data={orderBy(userDataList, paging.sort).slice(paging.skip, paging.take + paging.skip)}
        total={userDataList.length}
        skip={paging.skip}
        take={paging.take}
        onPageChange={onPageChange}
        onSortChange={onSortChange}
        sort={paging.sort}
        page={paging}
        sortable
        pageable
      >
        <GridNoRecords>{"조회 결과 데이터가 없습니다."}</GridNoRecords>
        <GridColumn title="순위" field="no" width="80px" />
        <GridColumn title="수용가명" field="userTerm" width="250px" />
        <GridColumn title={isCal === false ? "발전량(kWh)" : "생산량(kWh)"} field="dayEnergy" />
        <GridColumn title="설비용량(kW)" field="instCapa" />
        <GridColumn title="설비용량비율(%)" field="ratio" />
        <GridColumn
          title={
            isCal === false ? (
              <div>
                용량대비발전량<br></br>(kWh/kW.day)
              </div>
            ) : (
              <div>
                용량대비생산량<br></br>(kWh/kW.day)
              </div>
            )
          }
          field="instCapaPerEnergy"
        />
        <GridColumn title="운영개소" field="count" width="80px" />
      </Grid>
    </div>
  );
};

export default UserBetweenUserListGrid;
