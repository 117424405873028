// 설비 정보 태양광
import React, { useState, useEffect } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import DateTimePickerKo from "../../com/dateInputs/dateTimePickerKo";
import DatePickerKo from "../../com/dateInputs/datePickerKo";
import kendo from "@progress/kendo-ui";
import styles from "./meain.module.css";
import { Input } from "@progress/kendo-react-inputs";
import { filterBy } from "@progress/kendo-data-query";

// 태양광 설비 정보
const Meain_info_sola = ({ selectMeain, setMeainInfo, codeList, meainModlList, inputValidMsg, itemRender }) => {
  const [modulMakr, setModulMakr] = useState(
    selectMeain.modulMakrNo ? { makrSeriNo: selectMeain.modulMakrNo, makrTerm: "" } : { makrSeriNo: "", makrTerm: "" },
  );
  const [modulType, setModulType] = useState(
    selectMeain.modulTypeNo ? { meainTypeSeriNo: selectMeain.modulTypeNo, meainTypeTerm: "" } : { meainTypeSeriNo: "", meainTypeTerm: "" },
  );
  const [invtMakr, setInvtMakr] = useState(selectMeain.invtMakrNo ? { makrSeriNo: selectMeain.invtMakrNo, makrTerm: "" } : { makrSeriNo: "", makrTerm: "" });
  const [invtType, setInvtType] = useState(
    selectMeain.invtTypeNo ? { meainTypeSeriNo: selectMeain.meainTypeSeriNo, meainTypeTerm: "" } : { meainTypeSeriNo: "", meainTypeTerm: "" },
  );
  const [bldSrvCodeNo, setBldSrvCodeNo] = useState(selectMeain.bldSrvCodeNo ? { codeNo: selectMeain.bldSrvCodeNo, codeVal: "" } : { codeNo: "", codeVal: "" });
  const bldSrvDs = codeList.filter((c) => c.grpCodeNo === 31);

  // 모듈 제조사 목록
  const modulMakrDs = meainModlList.length !== 0 ? meainModlList.makrDtoList.filter((c) => c.makrClsfNo == 30001) : [];
  // 모듈 타입 목록
  const modulTypeDs =
    meainModlList.length !== 0 ? meainModlList.meainModelDtoList.filter((c) => c.ensoTypeCodeNo === 15001 && c.makrSeriNo === selectMeain.modulMakrNo) : [];
  // 인버터 제조사 목록
  const invtMakrDs = meainModlList.length !== 0 ? meainModlList.makrDtoList.filter((c) => c.makrClsfNo == 30002) : [];
  // 인버터 타입 목록
  const invtTypeDs =
    meainModlList.length !== 0 ? meainModlList.meainModelDtoList.filter((c) => c.ensoTypeCodeNo === 15001 && c.makrSeriNo === selectMeain.invtMakrNo) : [];

  const filterChange = (e) => {
    const { filter } = e;
    const ddlId = e.target.props.id;
    switch (ddlId) {
      case "modulMakr":
        setDdlData({ ...ddlData, modulMakr: filterBy(modulMakrDs, filter) });
        break;
      case "modulType":
        setDdlData({ ...ddlData, modulType: filterBy(modulTypeDs, filter) });
        break;
      case "invtMakr":
        setDdlData({ ...ddlData, invtMakr: filterBy(invtMakrDs, filter) });
        break;
      case "invtType":
        setDdlData({ ...ddlData, invtType: filterBy(invtTypeDs, filter) });
        break;
      default:
        break;
    }
  };
  const [ddlData, setDdlData] = useState({
    modulMakr: modulMakrDs.slice(),
    modulType: modulTypeDs.slice(),
    invtMakr: invtMakrDs.slice(),
    invtType: invtTypeDs.slice(),
  });
  /*
   ** 태양광 드롭다운 DS
   */
  // 태양광 설치유형
  const instTypeDs = ["지상형", "경사지붕형", "평슬라브형", "옥상(평슬라브 곡선형)", "건물일체형(BIPV)"];
  // 모듈결정분류
  const modulCrystalClsfDs = ["단결정", "다결정"];
  // 추적 시스템
  const trckSystDs = ["고정형", "추적형"];
  // 계통접속
  const sysAcceDs = ["저압연계", "고압연계"];
  // 위상종류
  const phaseTypeDs = ["단상", "삼상"];

  // 드롭다운 값 변경 이벤트 (Single 데이터 버전)
  const onchageDropdown = (e) => {
    // 값변경한 부분에 대해서 추적하여 객체 이름 기입하기 위해 name 값 가져오기
    const objName = e.target.name;
    const meainInfo = {
      ...selectMeain,
      [objName]: e.value,
    };
    setMeainInfo(meainInfo);
  };

  // Input 데이터 값 변경 이벤트
  const onchageInput = (e) => {
    let meainInfo = {};
    const objName = e.target.name;
    // 모듈 총용량은 태양광 설비 용량 과 같다.
    if (e.target.name === "modulAlCapa") {
      meainInfo = {
        ...selectMeain,
        [objName]: e.target.value,
        instCapa: e.target.value,
      };
    } else {
      // 값변경한 부분에 대해서 추적하여 객체 이름 기입하기 위해 name 값 가져오기
      meainInfo = {
        ...selectMeain,
        [objName]: e.target.value,
      };
    }
    setMeainInfo(meainInfo);
  };

  // Date 데이터 값 변경 이벤트
  const onchageDate = (v, name) => {
    // 값변경한 부분에 대해서 추적하여 객체 이름 기입하기 위해 name 값 가져오기
    const meainInfo = {
      ...selectMeain,
      [name]: kendo.toString(v, "yyyy-MM-dd"),
    };
    setMeainInfo(meainInfo);
  };

  // DateTimeOnChangeDate
  const onChangeDateTiem = (v, name) => {
    const meainInfo = {
      ...selectMeain,
      [name]: v,
      // ,  [name] : kendo.toString(v , 'yyyy-MM-dd HH:mm:ss')
    };
    setMeainInfo(meainInfo);
  };

  // 날짜 포맷 지정
  const dateFormat = (date) => {
    if (typeof date !== "undefined") {
      if (date !== "") {
        date = new Date(date);
      } else {
        date = "";
      }
    } else {
      date = "";
    }
    return date;
  };

  // 제조사 Converter
  const makrConverter = () => {
    if (Object.keys(selectMeain).length !== 0) {
      if (selectMeain.modulMakrNo) {
        let makrSeriNo = "";
        let makrTerm = "";
        modulMakrDs.forEach((element) => {
          if (element.makrSeriNo == selectMeain.modulMakrNo) {
            makrSeriNo = element.makrSeriNo;
            makrTerm = element.makrTerm;
          }
        });
        setModulMakr({ makrSeriNo: makrSeriNo, makrTerm: makrTerm });
      }
      if (selectMeain.invtMakrNo) {
        let makrSeriNo = "";
        let makrTerm = "";
        invtMakrDs.forEach((element) => {
          if (element.makrSeriNo == selectMeain.invtMakrNo) {
            makrSeriNo = element.makrSeriNo;
            makrTerm = element.makrTerm;
          }
        });
        setInvtMakr({ makrSeriNo: makrSeriNo, makrTerm: makrTerm });
      }
    } else {
      setModulMakr({ makrSeriNo: "", makrTerm: "" });
      setInvtMakr({ makrSeriNo: "", makrTerm: "" });
    }
  };

  // 모델 Converter
  const modlConverter = () => {
    if (Object.keys(selectMeain).length !== 0) {
      if (selectMeain.modulTypeNo) {
        let meainTypeSeriNo = "";
        let meainTypeTerm = "";
        modulTypeDs.forEach((element) => {
          if (element.meainTypeSeriNo == selectMeain.modulTypeNo) {
            meainTypeSeriNo = element.meainTypeSeriNo;
            meainTypeTerm = element.meainTypeTerm;
          }
        });
        setModulType({ meainTypeSeriNo: meainTypeSeriNo, meainTypeTerm: meainTypeTerm });
      } else {
        setModulType({ meainTypeSeriNo: "", meainTypeTerm: "" });
      }
      if (selectMeain.meainTypeSeriNo) {
        let meainTypeSeriNo = "";
        let meainTypeTerm = "";
        invtTypeDs.forEach((element) => {
          if (element.meainTypeSeriNo == selectMeain.meainTypeSeriNo) {
            meainTypeSeriNo = element.meainTypeSeriNo;
            meainTypeTerm = element.meainTypeTerm;
          }
        });
        setInvtType({ modulTypeNo: meainTypeSeriNo, meainTypeTerm: meainTypeTerm });
      } else {
        setInvtType({ modulTypeNo: "", meainTypeTerm: "" });
      }
    } else {
      setModulType({ meainTypeSeriNo: "", meainTypeTerm: "" });
      setInvtType({ modulTypeNo: "", meainTypeTerm: "" });
    }
  };

  // Converter 건물용도별
  const bldSrvConverter = () => {
    if (Object.keys(selectMeain).length !== 0) {
      let codeNo = "";
      let codeVal = "";
      bldSrvDs.forEach((element) => {
        if (element.codeNo == selectMeain.bldSrvCodeNo) {
          codeNo = element.codeNo;
          codeVal = element.codeVal;
        }
      });
      setBldSrvCodeNo({ codeNo: codeNo, codeVal: codeVal });
    } else {
      setBldSrvCodeNo({ codeNo: "", codeVal: "-" });
    }
  };

  useEffect(() => {
    // props의 데이터를 각각 데이터 소스 객체에 맞게 Convert 작업 해준다.
    makrConverter();
    modlConverter();
    bldSrvConverter();
    setDdlData({
      modulMakr: modulMakrDs.slice(),
      modulType: modulTypeDs.slice(),
      invtMakr: invtMakrDs.slice(),
      invtType: invtTypeDs.slice(),
    });
  }, [selectMeain]); // <- 이걸 해줘야 다른 컴포넌트에 값을 입력해준다.

  //처음 초기화
  useEffect(() => {
    setDdlData({
      modulMakr: modulMakrDs.slice(),
      modulType: modulTypeDs.slice(),
      invtMakr: invtMakrDs.slice(),
      invtType: invtTypeDs.slice(),
    });
  }, [meainModlList]);
  return (
    <table className="Ch-TableN">
      <tr>
        <th>설치유형</th>
        <td>
          <DropDownList
            className="meainInfoDropDown"
            id="instType"
            name="instType"
            data={instTypeDs}
            value={selectMeain.instType ? selectMeain.instType : ""}
            onChange={onchageDropdown}
            required={true}
            validationMessage={inputValidMsg.normalText}
            popupSettings={{ className: "autoFitDropDown" }}
            itemRender={itemRender}
          ></DropDownList>
        </td>
        <th></th>
        <td></td>
        <th></th>
        <td></td>
      </tr>
      <tr>
        <th colSpan="6">
          <div></div>
        </th>
      </tr>
      <tr>
        <th>모듈결정분류</th>
        <td>
          <DropDownList
            className="meainInfoDropDown"
            id="modulCrystalClsf"
            name="modulCrystalClsf"
            data={modulCrystalClsfDs}
            value={selectMeain.modulCrystalClsf ? selectMeain.modulCrystalClsf : ""}
            itemRender={itemRender}
            onChange={onchageDropdown}
            required={true}
            popupSettings={{ className: "autoFitDropDown" }}
            validationMessage={inputValidMsg.normalText}
          ></DropDownList>
        </td>
        <th>모듈당 용량(W)</th>
        <td>
          <Input
            id="modulCapa"
            name="modulCapa"
            type="number"
            className={styles.searchTermTBC}
            value={selectMeain.modulCapa ? selectMeain.modulCapa : ""}
            step="0.01"
            min={0}
            onChange={onchageInput}
            required={true}
            validationMessage={inputValidMsg.number2Fix}
          />
        </td>
        <th>모듈 총 용량(kW)</th>
        <td>
          <Input
            id="modulAlCapa"
            name="modulAlCapa"
            type="number"
            className={styles.searchTermTBC}
            value={selectMeain.modulAlCapa ? selectMeain.modulAlCapa : ""}
            step="0.01"
            min={0}
            onChange={onchageInput}
            required={true}
            validationMessage={inputValidMsg.number2Fix}
          />
        </td>
      </tr>
      <tr>
        <th colSpan="6">
          <div></div>
        </th>
      </tr>
      <tr>
        <th>모듈 제조사</th>
        <td>
          <DropDownList
            className="meainInfoDropDown"
            id="modulMakr"
            name="modulMakrNo"
            data={ddlData.modulMakr}
            popupSettings={{ className: "autoFitDropDown" }}
            value={modulMakr}
            required={true}
            validationMessage={inputValidMsg.normalText}
            itemRender={itemRender}
            dataItemKey="makrSeriNo"
            textField="makrTerm"
            filterable={true}
            onFilterChange={filterChange}
            onChange={(e) => {
              const obj = { ...selectMeain, [e.target.name]: e.value.makrSeriNo };
              setModulType(e.target.value);
              setMeainInfo(obj);
            }}
            valid={selectMeain.modulMakrNo !== "" && selectMeain.modulMakrNo !== null && typeof selectMeain.modulMakrNo !== "undefined"}
          />
        </td>
        <th>모듈 모델명</th>
        <td>
          <DropDownList
            className="meainInfoDropDown"
            id="modulType"
            name="modulTypeNo"
            data={ddlData.modulType}
            disabled={selectMeain.modulMakrNo ? false : true}
            value={modulType}
            required={true}
            validationMessage={inputValidMsg.normalText}
            itemRender={itemRender}
            dataItemKey="meainTypeSeriNo"
            popupSettings={{ className: "autoFitDropDown" }}
            textField="meainTypeTerm"
            filterable={true}
            onFilterChange={filterChange}
            onChange={(e) => {
              const obj = { ...selectMeain, [e.target.name]: e.value.meainTypeSeriNo };
              setModulType(e.target.value);
              setMeainInfo(obj);
            }}
            valid={selectMeain.modulTypeNo !== "" && selectMeain.modulTypeNo !== null && typeof selectMeain.modulTypeNo !== "undefined"}
          />
        </td>
        <th>모듈 방위각</th>
        <td>
          <Input
            id="azimuth"
            name="azimuth"
            type="number"
            className={styles.searchTermTBC}
            step="0.01"
            min={0}
            value={selectMeain.azimuth ? selectMeain.azimuth : ""}
            required={true}
            validationMessage={inputValidMsg.number2Fix}
            onChange={onchageInput}
          />
        </td>
      </tr>
      <tr>
        <th colSpan="6">
          <div></div>
        </th>
      </tr>
      <tr>
        <th>모듈 경사각</th>
        <td>
          <Input
            id="incliAngle"
            name="incliAngle"
            type="number"
            className={styles.searchTermTBC}
            step="0.01"
            min={0}
            value={selectMeain.incliAngle ? selectMeain.incliAngle : ""}
            required={true}
            validationMessage={inputValidMsg.number2Fix}
            onChange={onchageInput}
          />
        </td>
        <th>모듈 직렬개수</th>
        <td>
          <Input
            id="modulSeriCnt"
            name="modulSeriCnt"
            type="number"
            className={styles.searchTermTBC}
            min={0}
            value={selectMeain.modulSeriCnt ? selectMeain.modulSeriCnt : ""}
            required={true}
            validationMessage={inputValidMsg.number0Fix}
            onChange={onchageInput}
          />
        </td>
        <th>모듈 병렬개수</th>
        <td>
          <Input
            id="modulArowCnt"
            name="modulArowCnt"
            type="number"
            className={styles.searchTermTBC}
            min={0}
            value={selectMeain.modulArowCnt ? selectMeain.modulArowCnt : ""}
            required={true}
            validationMessage={inputValidMsg.number0Fix}
            onChange={onchageInput}
          />
        </td>
      </tr>
      <tr>
        <th colSpan="6">
          <div></div>
        </th>
      </tr>
      <tr>
        <th>인버터 제조사</th>
        <td>
          <DropDownList
            className="meainInfoDropDown"
            id="invtMakr"
            name="invtMakrNo"
            data={ddlData.invtMakr}
            value={invtMakr}
            required={true}
            validationMessage={inputValidMsg.normalText}
            itemRender={itemRender}
            popupSettings={{ className: "autoFitDropDown" }}
            dataItemKey="makrSeriNo"
            textField="makrTerm"
            filterable={true}
            onFilterChange={filterChange}
            onChange={(e) => {
              const obj = { ...selectMeain, [e.target.name]: e.value.makrSeriNo };
              setInvtMakr(e.target.value);
              setMeainInfo(obj);
            }}
            valid={selectMeain.invtMakrNo !== "" && selectMeain.invtMakrNo !== null && typeof selectMeain.invtMakrNo !== "undefined"}
          />
        </td>
        <th>인버터 모델명</th>
        <td>
          <DropDownList
            className="meainInfoDropDown"
            id="invtType"
            name="meainTypeSeriNo"
            data={ddlData.invtType}
            disabled={selectMeain.invtMakrNo ? false : true}
            value={invtType}
            required={true}
            popupSettings={{ className: "autoFitDropDown" }}
            validationMessage={inputValidMsg.normalText}
            itemRender={itemRender}
            dataItemKey="meainTypeSeriNo"
            textField="meainTypeTerm"
            filterable={true}
            onFilterChange={filterChange}
            onChange={(e) => {
              const obj = { ...selectMeain, [e.target.name]: e.value.meainTypeSeriNo };
              setInvtType(e.target.value);
              setMeainInfo(obj);
            }}
            valid={selectMeain.meainTypeSeriNo !== "" && selectMeain.meainTypeSeriNo !== null && typeof selectMeain.meainTypeSeriNo !== "undefined"}
          />
        </td>
        <th>인버터 용량(kW)</th>
        <td>
          <Input
            id="invtCapa"
            name="invtCapa"
            type="number"
            className={styles.searchTermTBC}
            step="0.01"
            min={0}
            value={selectMeain.invtCapa ? selectMeain.invtCapa : ""}
            required={true}
            validationMessage={inputValidMsg.number2Fix}
            onChange={onchageInput}
          />
        </td>
      </tr>
      <tr>
        <th colSpan="6">
          <div></div>
        </th>
      </tr>
      <tr>
        <th>위상종류</th>
        <td>
          <DropDownList
            className="meainInfoDropDown"
            id="phaseType"
            name="phaseType"
            data={phaseTypeDs}
            itemRender={itemRender}
            value={selectMeain.phaseType ? selectMeain.phaseType : ""}
            required={true}
            popupSettings={{ className: "autoFitDropDown" }}
            validationMessage={inputValidMsg.normalText}
            onChange={onchageDropdown}
          ></DropDownList>
        </td>
        <th>추적 시스템</th>
        <td>
          <DropDownList
            className="meainInfoDropDown"
            id="trckSyst"
            name="trckSyst"
            data={trckSystDs}
            itemRender={itemRender}
            value={selectMeain.trckSyst ? selectMeain.trckSyst : ""}
            required={true}
            popupSettings={{ className: "autoFitDropDown" }}
            validationMessage={inputValidMsg.normalText}
            onChange={onchageDropdown}
          ></DropDownList>
        </td>
        <th>
          시공업체
          <br />
          핸드폰 연락처
        </th>
        <td>
          <Input
            id="consPhoneCntct"
            name="consPhoneCntct"
            type="text"
            className={styles.searchTermTBC}
            value={selectMeain.consPhoneCntct ? selectMeain.consPhoneCntct : ""}
            required={true}
            validationMessage={inputValidMsg.normalText}
            placeholder="하이픈(-) 없이 입력해주세요."
            onChange={onchageInput}
          />
        </td>
      </tr>
      <tr>
        <th colSpan="6">
          <div></div>
        </th>
      </tr>
      <tr>
        <th>시공업체</th>
        <td>
          <Input
            id="consEnte"
            name="consEnte"
            type="text"
            className={styles.searchTermTBC}
            value={selectMeain.consEnte ? selectMeain.consEnte : ""}
            required={true}
            validationMessage={inputValidMsg.normalText}
            onChange={onchageInput}
          />
        </td>
        <th>시공업체 연락처</th>
        <td>
          <Input
            id="consEnteCntct"
            name="consEnteCntct"
            type="text"
            className={styles.searchTermTBC}
            value={selectMeain.consEnteCntct ? selectMeain.consEnteCntct : ""}
            required={true}
            validationMessage={inputValidMsg.normalText}
            placeholder="하이픈(-) 없이 입력해주세요."
            onChange={onchageInput}
          />
        </td>
        <th>시공일시</th>
        <td>
          <DateTimePickerKo
            id="meainInstDtm"
            name="meainInstDtm"
            className="meainDatePicker"
            value={dateFormat(selectMeain.meainInstDtm)}
            format={"yyyy-MM-dd HH:mm"}
            formatPlaceholder={{ year: "년", month: "월", day: "일", hour: "시간", minute: "분" }}
            setDtm={(v) => {
              onChangeDateTiem(v, "meainInstDtm");
            }}
            required={true}
            validationMessage={inputValidMsg.normalText}
          ></DateTimePickerKo>
        </td>
      </tr>
      <tr>
        <th colSpan="6">
          <div></div>
        </th>
      </tr>
      <tr>
        <th>모니터링일시</th>
        <td>
          <DateTimePickerKo
            id="mntrStrtDtm"
            name="mntrStrtDtm"
            format={"yyyy-MM-dd HH:mm"}
            className="meainDatePicker"
            formatPlaceholder={{ year: "년", month: "월", day: "일", hour: "시간", minute: "분" }}
            value={dateFormat(selectMeain.mntrStrtDtm)}
            setDtm={(v) => {
              onChangeDateTiem(v, "mntrStrtDtm");
            }}
            required={true}
            validationMessage={inputValidMsg.normalText}
          ></DateTimePickerKo>
        </td>
        <th>AS만료일</th>
        <td>
          <DatePickerKo
            id="asExpiDtm"
            name="asExpiDtm"
            className="meainDatePicker"
            format="yyyy-MM-dd"
            value={dateFormat(selectMeain.asExpiDtm)}
            formatPlaceholder={{ year: "년", month: "월", day: "일" }}
            setDtm={(v) => {
              onchageDate(v, "asExpiDtm");
            }}
            required={true}
            validationMessage={inputValidMsg.normalText}
          ></DatePickerKo>
        </td>
        <th>계통접속</th>
        <td>
          <DropDownList
            className="meainInfoDropDown"
            id="sysAcce"
            name="sysAcce"
            data={sysAcceDs}
            value={selectMeain.sysAcce ? selectMeain.sysAcce : ""}
            popupSettings={{ className: "autoFitDropDown" }}
            itemRender={itemRender}
            onChange={onchageDropdown}
          ></DropDownList>
        </td>
      </tr>
      <tr>
        <th colSpan="6">
          <div></div>
        </th>
      </tr>
      <tr>
        <th>건물용도</th>
        <td>
          <DropDownList
            className="bldSrvCodeNo"
            id="bldSrvCodeNo"
            name="bldSrvCodeNo"
            data={bldSrvDs}
            itemRender={itemRender}
            value={bldSrvCodeNo}
            required={true}
            popupSettings={{ className: "autoFitDropDown" }}
            validationMessage={inputValidMsg.normalText}
            dataItemKey="codeNo"
            textField="codeVal"
            onChange={(e) => {
              const obj = { ...selectMeain, [e.target.name]: e.value.codeNo };
              setBldSrvCodeNo(e.target.value);
              setMeainInfo(obj);
            }}
            valid={selectMeain.bldSrvCodeNo !== "" && selectMeain.bldSrvCodeNo !== null && typeof selectMeain.bldSrvCodeNo !== "undefined"}
          />
        </td>
        <th>특이사항</th>
        <td colSpan="3">
          <input id="etc" name="etc" type="text" className={styles.searchTermTBC} value={selectMeain.etc ? selectMeain.etc : ""} onChange={onchageInput} />
        </td>
      </tr>
      <tr>
        <th colSpan="6">
          <div></div>
        </th>
      </tr>
    </table>
  );
};

export default Meain_info_sola;
