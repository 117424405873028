import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import kendo from "@progress/kendo-ui";
import { IntlProvider, LocalizationProvider, loadMessages } from "@progress/kendo-react-intl";

const GathRawDataLogGridFuelcell = ({ paging, setPage, data, fetchData }) => {
  // Cell 의 각 데이터 값에 대한 포맷
  const cellFormat = (data) => {
    const field = data.field;
    let value = "-";
    // 동적으로 객체 존재를 확인 하고 있다면 포맷 적용 (undefined 와 null 체크 )
    if (data.dataItem.hasOwnProperty(field) && data.dataItem[field] != null) {
      if (field === "errCode") {
        value = data.dataItem[field];
      } else {
        value = kendo.format("{0:n0}", data.dataItem[field]);
      }
    }
    return <td style={{ textAlign: "right", paddingRight: "6px" }}>{value}</td>;
  };

  // CID , 수용가 , 수집일자 , 데이터상태 값의 포맷
  const cellKeyFormat = (data) => {
    const field = data.field;
    let value = "-";
    if (field === "gathDtm") {
      value = data.dataItem[field] ? data.dataItem[field].replace("T", " ") : "-";
    } else {
      value = data.dataItem[field] ? data.dataItem[field] : "-";
    }
    return <td style={{ textAlign: "center" }}>{value}</td>;
  };

  // 페이징 이벤트
  const onPageChange = (e) => {
    let { take, skip } = e.page;
    paging.take = take;
    paging.skip = skip;
    setPage(paging);
    fetchData();
  };

  return (
    <div className="uk-grid">
      <div className="uk-width-medium-1-1">
        <LocalizationProvider language="ko-KR">
          <IntlProvider locale="ko">
            <Grid
              id="fuelCellGrid"
              key={15006}
              className="operHistGrid"
              data={data.list}
              resizable={true}
              pageable={{
                type: "numeric",
                pageSizes: [5, 10, 20, 50, 100],
                previousNext: true,
                buttonCount: 5,
                refresh: true,
              }}
              total={data.count}
              skip={paging.skip}
              take={paging.take}
              pageSize={paging.take}
              onPageChange={onPageChange}
            >
              <GridNoRecords>{"  "}</GridNoRecords>
              <GridColumn title="CID" field="cid" width="130px" cell={cellKeyFormat}></GridColumn>
              <GridColumn title="수용가" field="userTerm" width="200px" cell={cellKeyFormat}></GridColumn>
              <GridColumn title="수집일자" field="gathDtm" width="160px" cell={cellKeyFormat}></GridColumn>
              <GridColumn field="dataStatSctCodeTerm" title="데이터상태" width="160px" cell={cellKeyFormat}></GridColumn>
              <GridColumn field="accumGelecQty" title="누적발전량(Wh)" width="110px" cell={cellFormat} sortable={false}></GridColumn>
              <GridColumn field="accumPrdctHeatQty" title="누적생산열량(kWh)" width="125px" cell={cellFormat} sortable={false}></GridColumn>
              <GridColumn field="prdctHeatQty" title="생산열량(W)" width="90px" cell={cellFormat} sortable={false}></GridColumn>
              <GridColumn field="accumUseHeatQty" title="누적사용열량(kWh)" width="130px" cell={cellFormat} sortable={false}></GridColumn>
              <GridColumn field="ystrAccumUseHeatQty" title="전일누적사용열량(kWh)" width="150px" cell={cellFormat} sortable={false}></GridColumn>
              <GridColumn field="inptVotgFigr" title="(전)전압(V)" width="90px" cell={cellFormat} sortable={false}></GridColumn>
              <GridColumn field="inptEcurFigr" title="(전)전류(A)" width="90px" cell={cellFormat} sortable={false}></GridColumn>
              <GridColumn field="inptElcpFigr" title="(전)출력(W)" width="90px" cell={cellFormat} sortable={false}></GridColumn>
              <GridColumn field="outpVotgFigr" title="(후)전압(V)" width="90px" cell={cellFormat} sortable={false}></GridColumn>
              <GridColumn field="outpEcurFigr" title="(후)전류(A)" width="90px" cell={cellFormat} sortable={false}></GridColumn>
              <GridColumn field="outpElcpFigr" title="(후)출력(W)" width="90px" cell={cellFormat} sortable={false}></GridColumn>
              <GridColumn field="wasupTemp" title="급수온도(℃)" width="90px" cell={cellFormat} sortable={false}></GridColumn>
              <GridColumn field="olutwtrTemp" title="출수온도(℃)" width="90px" cell={cellFormat} sortable={false}></GridColumn>
              <GridColumn field="pwrftRate" title="역률(%)" width="90px" cell={cellFormat} sortable={false}></GridColumn>
              <GridColumn field="freqFigr" title="주파수(Hz)" width="90px" cell={cellFormat} sortable={false}></GridColumn>
              {/* <GridColumn field="errCode" title="에러코드" width="150px" cell={cellFormat} sortable={false}></GridColumn> */}
            </Grid>
          </IntlProvider>
        </LocalizationProvider>
      </div>
    </div>
  );
};

export default GathRawDataLogGridFuelcell;
