// React
import React from 'react';

// HighCharts
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';

HighchartsMore(Highcharts);

export const ConsmCharts = ({ xCategories, warnData, errData }) => {

  const options = {
    chart: { type: 'line', height: '460' }
    , title: null
    , credits: false
    , tooltip: {
      pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
    }
    , xAxis: {
      categories: xCategories
    }
    , yAxis: { title: null }
    , tooltip: {
        shared: true
        , crosshairs: true
        , headerFormat: '<b>{point.x}시</b><br />'
    }
    , plotOptions: {
        line: {
          dataLabels: {
              enabled: true
          }
        },
        series: {
          marker: {
            fillColor: '#FFFFFF',
            lineWidth: 2,
            lineColor: null
          }
        }
    }
    , legend: {
        verticalAlign: 'top'
    }
    , series: [{
        name: '경고'
        , data: warnData
        , color: '#F9CC13'
    }, {
        name: '고장'
        , data: errData
        , color: '#FF3B3B'
    }]
  }
  return (
    <>
      <HighchartsReact
        Highcharts={Highcharts}
        options={options}
      />
    </>
  );
};
