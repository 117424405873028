import { memo, useEffect, useState } from 'react';
import { getRealTimeUserCount } from '../../../service/sys/visitorStatisticsSevice';
import styles from './visitorStatistics.module.css';

const RealTimeUserCount = memo(({conn , realTimeUserCount , getTotalData}) => {

  // 실시간 유저 카운트
  const [realTimeCount , setRealTimeCount] =  useState(realTimeUserCount);

  // 시간 값 TimeDelay 설정 (주기적으로 실시간 방문자를 체크 한다. 시간 : 10~12초 사이)
  const setRealTimeUserCount = () => {
    setInterval(() => {
      getRealTimeUserCountStart();
    }, 12000);
  }

  // 실시간 유저 수 값 가져오기
  const getRealTimeUserCountStart = () => {
    getRealTimeUserCount(conn , getRealTimeUserCountResult , getTotalData);
  }

  const getRealTimeUserCountResult = (result) => {
    setRealTimeCount(result.realTimeUserCount);
  }

  // 시간 타이머 
  useEffect(() => {
    setRealTimeUserCount();
  },[]);

  return(
    <>
      <b className={styles.ActiveUsersValue} id="users-container">&nbsp;&nbsp;&nbsp;{realTimeCount}</b>
    </>
  );
});

export default RealTimeUserCount;