import React from "react";
import styles from "./renewStat.module.css";

const RenewMapTb = (props) => {
  const { depthBoxRef } = props;
  return (
    <div className={styles.municDepthTbl} ref={depthBoxRef} style={{ display: "none" }}>
      클릭하여 해당 지역의 <br />
      상세정보를 확인해 보세요.
    </div>
  );
};

export default RenewMapTb;
