//React
import React, { useState, useEffect } from "react";
import { getRtu, setDeleteRtu, setUpdateRtu } from "../../../service/mngt/rtuEnte/rtuService";
import GridColumnInputFilter from "../../com/gridFilter/gridColumnInputFilter";
import RtuModal from "./rtuModal";
import RtuRprsnModal from "./rtuRprsnModal";
import { parseDate } from "../../../com/dateUtil";

//kendo
import kendo from "@progress/kendo-ui";
import { Grid, GridColumn as Column, GridToolbar, GridNoRecords } from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-data-query";
import { filterBy } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import { useHistory } from "react-router";
import { IntlProvider, LocalizationProvider, loadMessages } from "@progress/kendo-react-intl";

import "./rtuMngt.css";
import CustomFilter from "../../com/gridFilter/custom_filter";
// 로딩 스피너
import { BeatLoader } from "react-spinners";

//컴포넌트 가져간 사항 rtuService
//codeList 사용
const RtuMngt = ({ conn, codeList, dongList }) => {
  const [loading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const [backupDataSource, setBackupDataSource] = useState([]);

  // 히스토리 사용
  const history = useHistory();

  // 그리드 상태(페이징, 정렬, 필터)
  const [dataState, setDataState] = useState({
    take: 10,
    skip: 0,
    total: 0,
    sort: [],
    filter: {},
  });

  /* 추가, 수정용 팝업창 State */
  const [modalItem, setModalItem] = useState({
    title: "",
    item: [],
    visible: false,
  });

  /* A/S담당자 관리용 팝업창 State */
  const [rprsnModalItem, setRprsnModalItem] = useState({
    title: "",
    selectedId: null,
    item: [],
    visible: false,
  });

  useEffect(() => {
    getRtu(conn)
      .then((result) => {
        //testState의 CodeNo=>CodeVal로 바꾸는 코드
        result.forEach((element) => {
          element.testState = codeList.filter((it) => it.codeNo === element.testState).map((item) => item.codeVal)[0];
        });
        setDataSource(result);
        setBackupDataSource(result);
        setLoading(false);
      })
      .catch((error) => {
        if (error.errCode === "L007") {
          alert("로그인 한 ID는 해당 메뉴에 접근할 수 없습니다.");
          history.goBack();
          return;
        } else {
          setLoading(false);
          kendo.alert("조회를 실패하였습니다.", error);
        }
      });
  }, [codeList]); //[codeList]넣어준 이유는 useEffect함수가 codeList 가져오는거보다 먼저 실행되기 때문에 넣어줌

  /* 테스트상태리스트 */
  const testStateList = codeList.filter((c) => c.grpCodeNo === 34);
  /* 테스트상태 필터 */
  const testFilterDropDownList = CustomFilter(
    codeList.filter((c) => c.grpCodeNo === 34).map((item) => item.codeVal),
    "테스트 상태",
    "none",
    "none",
    false,
  );
  /* 사용여부 필터 */
  const useYnDropDownList = CustomFilter(["Y", "N"], "사용여부", "none", "none", false);

  /* 페이징 이벤트 */
  const pageChange = (event) => {
    setDataState({
      ...dataState,
      skip: event.page.skip,
      take: event.page.take,
    });
  };

  /* 정렬 이벤트 */
  const sortChange = (event) => {
    if (event.sort.length === 0) {
      setDataState({
        ...dataState,
        sort: [
          {
            field: "",
            dir: "",
          },
        ],
      });
    } else {
      setDataState({
        ...dataState,
        sort: [
          {
            field: event.sort[0].field,
            dir: event.sort[0].dir,
          },
        ],
      });
    }
  };

  /* 필터 이벤트 */
  const filterChange = (event) => {
    setDataSource(filterBy(backupDataSource, event.filter));
    setDataState({
      ...dataState,
      filter: event.filter,
    });
  };

  /* Row 클릭 이벤트 */
  const onRowClick = (e) => {
    if (rprsnModalItem.selectedId === e.dataItem.rtuEnteCode) {
      setRprsnModalItem({ ...rprsnModalItem, selectedId: null, title: "" });
    } else {
      setRprsnModalItem({
        ...rprsnModalItem,
        selectedId: e.dataItem.rtuEnteCode,
        title: e.dataItem.rtuEnteTerm,
      });
    }
  };

  // RTU 업체 저장 시 Validation
  const rtuValidation = (dataItem, isNew) => {
    let isValid = true;
    let message = "";
    if (!dataItem.dongInfo) {
      message = "읍/면/동 을 선택 해주세요.";
      isValid = false;
    }
    if (!dataItem.rtuEnteEmail) {
      message = "이메일을 작성 해주세요.";
      isValid = false;
    } else {
      const rtuEnteEmail = /([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
      if (!rtuEnteEmail.test(dataItem.rtuEnteEmail)) {
        message = "유효하지 않은 RTU업체 이메일입니다!";
        isValid = false;
      }
    }

    if (isNew === false) {
      if (dataItem.loginPwd) {
        const loginPwd = /^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
        if (!loginPwd.test(dataItem.loginPwd)) {
          message = "유효하지 않은 비밀번호입니다! 영어 ,숫자,특수문자 포함한 8자리 이상 작성해주세요.";
          isValid = false;
        }
      }
    } else {
      if (!dataItem.loginPwd) {
        message = "비밀번호를 입력 해주세요.";
        isValid = false;
      } else {
        const loginPwd = /^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
        if (!loginPwd.test(dataItem.loginPwd)) {
          message = "유효하지 않은 비밀번호입니다! 영어 ,숫자,특수문자 포함한 8자리 이상 작성해주세요.";
          isValid = false;
        }
      }
    }

    if (!dataItem.rtuEnteId) {
      message = "업체 ID를 입력 해주세요.";
      isValid = false;
    }

    if (!dataItem.rtuEntePhon) {
      message = "담당자 연락처를 입력 해주세요.";
      isValid = false;
    } else {
      const rtuEntePhon = /[01](0|1|6|7|8|9)-(\d{4}|\d{3})-\d{4}$/;
      if (!rtuEntePhon.test(dataItem.rtuEntePhon)) {
        message = "유효하지 않은 담당자 연락처 번호입니다!";
        isValid = false;
      }
    }

    if (!dataItem.rtuEnteCntct) {
      message = "업체 연락처를 입력 해주세요.";
      isValid = false;
    } else {
      // const rtuEnteCntct = /[01](0|1|6|7|8|9)-(\d{4}|\d{3})-\d{4}$/;
      const rtuEnteCntct = /^(02|01[016789]{1}|0[3-9]{1}[0-9]{1})([0-9]{3,4})([0-9]{4})$/;
      if (!rtuEnteCntct.test(dataItem.rtuEnteCntct)) {
        message = "유효하지 않은 업체 연락처 입니다!";
        isValid = false;
      }
    }

    return { isValid: isValid, message: message };
  };

  /* 등록일시 parse */
  const setDateUtil = (e) => {
    return <td className="k-command-cell">{parseDate("yyyy-mm-dd hh:mm:ss", e.dataItem.regiDtm)}</td>;
  };
  return (
    <div>
      <div className="uk-grid">
        <div className="uk-width-medium-1-1">
          <h3 className="md-card-toolbar-heading-text">RTU업체관리</h3>
          <LocalizationProvider language="ko-KR">
            <IntlProvider locale="ko">
              <Grid
                style={
                  loading
                    ? {
                        opacity: 0.6,
                        pointerEvents: "none",
                      }
                    : null
                }
                className="rtuMngtGrid"
                data={orderBy(dataSource, dataState.sort)
                  .slice(dataState.skip, dataState.take + dataState.skip)
                  .map((item) => ({
                    ...item,
                    selected: item.rtuEnteCode === rprsnModalItem.selectedId,
                  }))}
                skip={dataState.skip}
                take={dataState.take}
                total={dataSource.length}
                pageable={{ buttonCount: 5, pageSizes: true }}
                onPageChange={pageChange}
                // sortable
                // sort={dataState.sort}
                // onSortChange={sortChange}
                filterable={true}
                filter={dataState.filter}
                onFilterChange={filterChange}
                onRowClick={onRowClick}
                selectedField="selected"
              >
                <GridToolbar>
                  <Button
                    style={{ backgroundColor: "white" }}
                    icon="add"
                    onClick={() => {
                      const newRecord = { rtuEnteCode: "" };
                      setDataSource([newRecord, ...dataSource]);
                      setModalItem({
                        title: "추가",
                        item: { rtuEnteCode: newRecord.rtuEnteCode },
                        visible: true,
                      });
                    }}
                  >
                    추가
                  </Button>
                  <Button
                    style={{ backgroundColor: "white" }}
                    icon="user"
                    onClick={() => {
                      if (rprsnModalItem.selectedId != undefined) setRprsnModalItem({ ...rprsnModalItem, visible: true });
                      else kendo.alert("업체를 선택해 주시기 바랍니다.");
                    }}
                  >
                    A/S 담당자 관리
                  </Button>
                </GridToolbar>
                {/* <Column
              field="rtuEnteCode"
              title="업체코드"
              width="100px"
              editable={false}
              filterable={false}
            /> */}
                <Column field="rtuEnteId" title="업체 ID" width="130px" filterable={false} />
                <Column field="rtuEnteTerm" title="업체명" width="195px" filterCell={GridColumnInputFilter} />
                <Column
                  field="rtuEnteAddr"
                  title="업체 주소"
                  // width="195px"
                  filterCell={GridColumnInputFilter}
                />
                <Column field="rtuEnteCntct" title="업체 연락처" width="130px" filterable={false} />
                <Column field="rtuEntePhon" title="담당자 연락처" width="130px" filterable={false} />
                <Column field="rtuEnteExpl" title="업체 설명" width="200px" filterCell={GridColumnInputFilter} />
                <Column field="rtuEnteEmail" title="이메일" width="200px" filterable={false} />
                <Column field="testState" title="테스트 상태" width="120px" filterCell={testFilterDropDownList} />
                <Column field="useYn" title="사용여부" width="100px" filterCell={useYnDropDownList} />
                <Column
                  field="regiDtm"
                  title="등록일시"
                  editor="date"
                  format="{0:s}"
                  // width="140px"
                  filterable={false}
                  cell={setDateUtil}
                />
                <Column
                  title="수정"
                  filterable={false}
                  width="64"
                  cell={(props) => (
                    <td>
                      <a
                        onClick={() => {
                          setModalItem({
                            title: "수정",
                            item: props.dataItem,
                            visible: !modalItem.visible,
                          });
                        }}
                      >
                        <img src="/images/icon_su.png" alt="" />
                      </a>
                    </td>
                  )}
                />
                <Column
                  title="삭제"
                  filterable={false}
                  width="64"
                  cell={(props) => (
                    <td>
                      <a
                        onClick={() => {
                          kendo.confirm(props.dataItem.rtuEnteTerm + "의 데이터를 정말 삭제하시겠습니까?").then(() => {
                            setDeleteRtu(conn, props.dataItem)
                              .then(() => {
                                kendo.alert("삭제 되었습니다.");
                                setDataSource(dataSource.filter((item) => item.rtuEnteCode !== props.dataItem.rtuEnteCode));
                              })
                              .catch((error) => console.log("Delete Rtu Error!", error));
                          });
                        }}
                      >
                        <img src="/images/icon_del.png" alt="" />
                      </a>
                    </td>
                  )}
                />
                <GridNoRecords>데이터가 존재하지 않습니다.</GridNoRecords>
              </Grid>
            </IntlProvider>
          </LocalizationProvider>
          {loading && (
            <p
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
              }}
            >
              <BeatLoader loading={true} size={24} color="#1e88e5" />
            </p>
          )}
          {modalItem.visible && (
            <RtuModal
              title={modalItem.title}
              item={modalItem.item}
              dongList={dongList}
              codeList={codeList}
              cancelEdit={() => {
                if (modalItem.title === "추가") {
                  setDataSource(dataSource.filter((item) => item.rtuEnteCode !== modalItem.item.rtuEnteCode));
                }
                setModalItem({
                  title: "",
                  item: [],
                  visible: !modalItem.visible,
                });
              }}
              onSubmit={(dataItem) => {
                if (modalItem.title === "추가") {
                  dataItem.useYn = dataItem.useYn ? dataItem.useYn : "Y";
                  dataItem.testState = dataItem.testState ? dataItem.testState : "미실시";
                  //rtuEnteCode 생성 = 전체 EnteCode의 최대값 +1
                  const rtuEnteCodeList = dataSource.map((item) => (item.rtuEnteCode !== undefined ? item.rtuEnteCode : 0));
                  dataItem.rtuEnteCode = Math.max.apply(null, rtuEnteCodeList) + 1;
                  dataItem.regiDtm = new Date();

                  // Validation 설정
                  const isValid = rtuValidation(dataItem, true);
                  if (isValid.isValid === false) {
                    kendo.alert(isValid.message);
                    return;
                  }

                  // cityprovCode , rgnCode , dongCode 설정
                  dataItem.cityProvCode = dataItem.dongInfo.provinceCode;
                  dataItem.rgnCode = dataItem.dongInfo.rgnCode;
                  dataItem.dongCode = dataItem.dongInfo.fixbylawBundCode;
                  // dataItem.fixbylawBundCode = dataItem.dongInfo.fixbylawBundCode;

                  // regiDtm 설정
                  dataItem.regiDtm = kendo.toString(dataItem.regiDtm, "yyyy-MM-dd HH:mm:ss");

                  setUpdateRtu(conn, { ...dataItem, testStateList })
                    .then(() => {
                      kendo.alert("저장되었습니다.");
                      setDataSource(dataSource.map((u) => (u.rtuEnteCode === "" ? { ...u, ...dataItem } : u)));
                    })
                    .catch((e) => {
                      setDataSource(dataSource.filter((item) => item.rtuEnteCode !== ""));
                      kendo.alert("저장을 실패 하였습니다.");
                    });
                } else if (modalItem.title === "수정") {
                  // Validation 설정
                  const isValid = rtuValidation(dataItem, false);
                  if (isValid.isValid === false) {
                    kendo.alert(isValid.message);
                    return;
                  }

                  // cityprovCode , rgnCode , dongCode 설정
                  dataItem.cityProvCode = dataItem.dongInfo.provinceCode;
                  dataItem.rgnCode = dataItem.dongInfo.rgnCode;
                  dataItem.dongCode = dataItem.dongInfo.fixbylawBundCode;
                  // dataItem.fixbylawBundCode = dataItem.dongInfo.fixbylawBundCode;

                  setUpdateRtu(conn, { ...dataItem, testStateList })
                    .then(() => {
                      kendo.alert("저장되었습니다.");
                      const result = dataSource.map((item) => (item.rtuEnteCode === dataItem.rtuEnteCode ? dataItem : item));
                      setDataSource(result);
                    })
                    .catch((e) => {
                      kendo.alert("저장을 실패 하였습니다.");
                    });
                }
                setModalItem({
                  title: "",
                  item: [],
                  visible: !modalItem.visible,
                });
              }}
            />
          )}
          {rprsnModalItem.visible && (
            <RtuRprsnModal
              conn={conn}
              title={rprsnModalItem.title}
              rtuEnteCode={rprsnModalItem.selectedId}
              cancelEdit={() => setRprsnModalItem({ ...rprsnModalItem, visible: false })}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default RtuMngt;
