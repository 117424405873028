import latestGathColumn from "./latestGathColumn.json";

export const getLatestGathEnergy = async (conn, installerId, ensoTypeCode, cid) => {
  const response = await conn.get("monitor/installer/gath/latest", {
    params: { installerId, ensoTypeCode, cid },
  });
  const data = response;
  const gath = [...latestGathColumn[ensoTypeCode]];

  for (let i in gath) {
    for (let k in data) {
      if (k == gath[i].name) {
        // 15001 태양광일때, 출력 값은 W단위로 1000을 나눠서 kW로 변경해줘야함
        if (ensoTypeCode == 15001 && gath[i].name === "outpElcpFigr") {
          gath[i].value = data[k] ? data[k] / 1000 : data[k];
        } else if (ensoTypeCode == 15001 && gath[i].name === "inptEcurFigr") {
          gath[i].value = data[k] ? data[k] : 0;
        } else {
          gath[i].value = data[k];
        }
      }
    }
  }
  return { gath: gath, latestGathDtm: data.latestGathDtm };
};

export const getHourlyEnergyList = async (conn, installerId, ensoTypeCode, cid) => {
  const response = await conn.get("monitor/installer/gath/hourly/list", {
    params: { installerId, ensoTypeCode, cid },
  });
  return response;
};

export const getAreaEnergyList = async (conn, installerId, ensoTypeCode, cid) => {
  const response = await conn.get("monitor/installer/gath/area/list", {
    params: { installerId, ensoTypeCode, cid },
  });

  const list = response;

  const colors = ["#767E8D", "#767E8D", "#5A8DEE", "#1E88E5"];
  // list.map((v,i) => (v.color));
  // list[0].color = '#767E8D';
  // list[1].color = '#6DB6EE';
  // list[2].color = '#5A8DEE';
  // list[3].color = '#1E88E5';
  return list;
};

export const getLatestEventList = async (conn, installerId, ensoTypeCode, cid) => {
  const response = await conn.get("monitor/installer/gath/event/list", {
    params: { installerId, ensoTypeCode, cid },
  });
  return response;
};

export const getWeekEnergyList = async (conn, installerId, ensoTypeCode, cid) => {
  const response = await conn.get("monitor/installer/gath/week/list", {
    params: { installerId, ensoTypeCode, cid },
  });
  let list = response;
  return list.map((v) => ({ term: v.gathDtm, gathDtm: v.gathDtm, energy: v.energy }));
};

export const getInstaller = async (conn, installerId) => {
  const response = await conn.get("user/installer/" + installerId, {
    params: {},
  });
  return {
    userTerm: response.userTerm,
    rgnCode: response.rgnCode,
    dongCode: response.fixbylawBundTermSimple,
  };
};

export const getInstallerMeainList = async (conn, installerId, ensoTypeCode) => {
  const response = await conn.get("monitor/installer/gath/meain/list", {
    params: { ensoTypeCode, installerId },
  });
  let list = response;
  return list.map((v) => ({
    cid: v.cid,
    term: v.cid + "",
  }));
};

export const getInstallerEnsoList = async (conn, installerId) => {
  const response = await conn.get("monitor/installer/gath/enso/list", {
    params: { installerId },
  });
  return response;
};

export const getInstallerEnergy = async (conn, installerId, ensoTypeCode, cid) => {
  const response = await conn.get("monitor/installer/gath/energy", {
    params: { installerId, ensoTypeCode, cid },
  });
  return response;
};
