import { Input } from "@progress/kendo-react-inputs";
import React, { useState, useEffect } from "react";
import styles from "./gridColumnInputFilter.module.css";

// 사용법
// 그리드의 컬럼의 filterCell 에 해당
// 1. 해당 컴포넌트를 임포트 한다. import GridColumnInputFilter from '../com/filter/GridColumnInputFilter';
// 2. 그리드의 컬럼에 설정한다. /* <Column field="userTerm" title="수용가" filterCell = {GridColumnInputFilter} /> */

function GridColumnInputFilter(props) {
  let [input, setInput] = useState("");
  const [event, setEvent] = useState({});
  // Input 입력 시 이벤트
  const onChangeInput = (e) => {
    setEvent(e);
    setInput(e.value);
  };
  const onKeyPressHandle = (e) => {
    if (e.key == "Enter") {
      const value = event.value;
      const operator = "contains";
      props.onChange({
        value,
        operator,
        syntheticEvent: event,
      });
    }
  };

  return (
    <label for="input">
      <Input onChange={onChangeInput} value={input} className={styles.gridColumnInputFilter} onKeyPress={onKeyPressHandle}></Input>
    </label>
  );
}

export default GridColumnInputFilter;
