//react
import { useState, useEffect } from "react";
import useHistoryState from "../../../com/useHistoryState";
// Kendo
import kendo from "@progress/kendo-ui";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input, RadioButton } from "@progress/kendo-react-inputs";
import DatePickerKo from "../../com/dateInputs/datePickerKo";
//component
import { getThermalPowerList } from "./../../../service/analy/thermalService";
import ThermalCmpChart from "./thermalCmpChart";
// 로딩 스피너
import { BeatLoader } from "react-spinners";
import { numFormat, roundNum } from "./../../../com/dataUtil";
//css
import "./thermalCmp.css";
import RgnDropdown from "../../com/dropdown/rgnDropdown";
import { isOneManager, getUserMngRgnList } from "./../../../service/authService";
const ThermalCmp = (props) => {
  const { rgnList, dongList, conn, codeList } = props;
  const [loading, setLoading] = useState(false);
  //그리드 데이터소스
  const [gridData, setGridData] = useState([]);
  //차트 데이터소스
  const [chartData, setChartData] = useState({ thPwList: [], solaPwList: [] });

  // 날짜 검색 데이터 소스
  const today = new Date();
  const yesterday = new Date();
  const lastWeek = new Date();
  const lastMonth = new Date();
  const lastYear = new Date();
  yesterday.setDate(today.getDate() - 1);
  lastWeek.setDate(yesterday.getDate() - 7);
  lastMonth.setDate(yesterday.getDate() - 30);
  lastYear.setDate(yesterday.getDate() - 365);
  const dateList = codeList.filter((c) => c.grpCodeNo === 23 && c.codeNo !== 23001);
  const [dateVal, setDateVal] = useState();

  const [search, setSearch] = useState({
    skip: 0,
    take: 15,
    dateType: "DD",
    startDate: lastWeek,
    endDate: yesterday,
    rgnCode: isOneManager() ? getUserMngRgnList()[0] : "", // 지역 코드 ,
    rgnTermSimple: "전체",
  });
  const pageChngEvt = (e) => {
    const { take, skip } = e.page;
    setSearch({ ...search, skip: skip, take: take });
  };
  const startDateChngEvt = (item) => {
    setSearch({ ...search, startDate: item });
  };
  const endDateChngEvt = (item) => {
    setSearch({ ...search, endDate: item });
  };
  const radioClickEvt = (e) => {
    const radioName = e.target.name;
    setSearch({ ...search, dateType: radioName });
  };
  const dateDdlChngEvt = (e) => {
    setDateVal(e.target.value);
    if (e.target.value.codeNo === 23002) {
      setSearch({ ...search, startDate: yesterday, endDate: yesterday });
    } else if (e.target.value.codeNo === 23003) {
      setSearch({ ...search, startDate: lastWeek, endDate: yesterday });
    } else if (e.target.value.codeNo === 23004) {
      setSearch({ ...search, startDate: lastMonth, endDate: yesterday });
    } else if (e.target.value.codeNo === 23005) {
      setSearch({ ...search, startDate: lastYear, endDate: yesterday });
    } else if (e.target.value.codeNo === 23006) {
      setSearch({ ...search, startDate: yesterday, endDate: yesterday });
    }
  };

  useEffect(() => {
    getGridData();
  }, []);

  const [hdVisbl, setHdVisbl] = useState(false);
  const GWHeader = (props) => {
    const mouseOverEvt = () => {
      setHdVisbl(true);
    };
    const mouseOutEvt = () => {
      setHdVisbl(false);
    };
    return (
      <>
        {hdVisbl && (
          <div className="thCmpHdInfo">
            누적 발전량 계산 기준 <br></br>2018-01-01 ~
          </div>
        )}
        <span>
          {props.title}
          <br></br>(단위:GWh)
          {props.children}
          <img className="thCmpHdInfoImg" onMouseOver={mouseOverEvt} onMouseOut={mouseOutEvt} src="/images/info.png" alt="수집항목"></img>
        </span>
      </>
    );
  };

  const MWHeader = (props) => {
    return (
      <span>
        {props.title}
        <br></br>(단위:MWh)
        {props.children}
      </span>
    );
  };

  const kWHeader = (props) => {
    return (
      <span>
        {props.title}
        <br></br>(단위:kWh)
        {props.children}
      </span>
    );
  };

  const solaThHeader = (props) => {
    return (
      <span>
        {props.title}
        <br></br>(단위:%)
        {props.children}
      </span>
    );
  };
  const hdCell = (props) => {
    return <span>{props.title}</span>;
  };
  const srchBtnClkEvt = () => {
    getGridData();
  };

  const getGridData = () => {
    setLoading(true);
    search.skip = 0;
    setSearch({ ...search, skip: 0 });
    getThermalPowerList(conn, search, setChartData)
      .then((response) => {
        setGridData(response);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const rgnDdlChngEvt = (e) => {
    const { value } = e;
    setSearch({ ...search, rgnCode: value.rgnCode, rgnTermSimple: value.rgnTermSimple });
  };

  const rgnCell = (e) => {
    const { dataItem } = e;
    const result = dataItem.rgnTermSimple ? dataItem.rgnTermSimple : "전체";
    return <td>{result}</td>;
  };
  const solaPowerCell = (e) => {
    const { dataItem } = e;
    const result = numFormat(roundNum(dataItem.solaPower, 2));
    return <td>{result}</td>;
  };
  const solaAcPowerCell = (e) => {
    const { dataItem } = e;
    const result = numFormat(roundNum(dataItem.solaAcPower / 1000, 2));
    return <td>{result}</td>;
  };

  const thermalPowerCell = (e) => {
    const { dataItem } = e;
    let result = numFormat(roundNum(dataItem.thermalPower, 2));
    return <td>{result}</td>;
  };
  const thermalAcPowerCell = (e) => {
    const { dataItem } = e;
    let result = numFormat(roundNum(dataItem.thermalAcPower / 1000, 2));
    return <td>{result}</td>;
  };
  const ratioCell = (e) => {
    const { dataItem } = e;
    const { solaPower, thermalPower } = dataItem;
    const result = solaPower / (thermalPower * 10);

    return <td>{result}</td>;
  };

  return (
    <div className="thCmpDiv" style={loading ? { opacity: "0.6", pointerEvents: "none" } : null}>
      <div className="thCmpSrchDiv">
        <span className="thCmpSrchLeft">
          <span>지역검색</span>&nbsp;&nbsp;&nbsp;
          <RgnDropdown rgnList={rgnList} search={search} objKey={"rgn"} setSearch={setSearch} onChange={rgnDdlChngEvt} />
          {/* <DropDownList
            defaultItem={{ rgnCode: "", rgnTermSimple: "전체" }}
            data={rgnList}
            dataItemKey={"rgnCode"}
            textField={"rgnTermSimple"}
            onChange={rgnDdlChngEvt}
          ></DropDownList> */}
        </span>
        <span className="thCmpSrchRight">
          <span className="thCmpRadGrp">
            <label style={{ margin: "3px", display: "inline-flex" }}>조회 기간</label>
            <DropDownList
              className="ensoDropDown yearDropDown"
              id="date"
              name="date"
              data={dateList}
              dataItemKey="codeNo"
              textField="codeVal"
              defaultValue={{ codeNo: 23006, codeVal: "직접조회" }}
              onChange={dateDdlChngEvt}
              value={dateVal}
            />
            <RadioButton name="DD" label="일별" checked={search.dateType === "DD"} onClick={radioClickEvt}></RadioButton>
            <RadioButton name="MM" label="월별" checked={search.dateType === "MM"} onClick={radioClickEvt}></RadioButton>
          </span>
          <DatePickerKo
            className="thCmpDtStrt"
            value={search.startDate}
            setDtm={startDateChngEvt}
            max={search.endDate}
            format={search.dateType === "MM" ? "yyyy-MM" : "yyyy-MM-dd"}
            view={search.dateType === "MM" ? "year" : null}
          ></DatePickerKo>
          {"~"}
          <DatePickerKo
            className="thCmpDtEnd"
            value={search.endDate}
            setDtm={endDateChngEvt}
            min={search.startDate}
            max={yesterday}
            format={search.dateType === "MM" ? "yyyy-MM" : "yyyy-MM-dd"}
            view={search.dateType === "MM" ? "year" : null}
          ></DatePickerKo>
          <Button icon="search" style={{ backgroundColor: "white", paddingLeft: "20px", paddingRight: "20px" }} onClick={srchBtnClkEvt}></Button>
        </span>
      </div>
      <div className="thCmpContent">
        <span className="thCmpCtntHdr">발전량 그래프</span>
        <span className="thCmpCtntHdr">상세정보</span>

        <span className="thCmpCtntBody">
          <div className="thCmpChartContent">
            <ThermalCmpChart chartData={chartData}></ThermalCmpChart>
          </div>
        </span>
        <span className="thCmpCtntBody">
          <LocalizationProvider language="ko-KR">
            <IntlProvider locale="ko">
              <Grid
                className="thCmpGrid"
                data={gridData.slice(search.skip, search.take + search.skip)}
                skip={search.skip}
                take={search.take}
                total={gridData.length}
                onPageChange={pageChngEvt}
                pageable={{
                  type: "numeric",
                  previousNext: true,
                  buttonCount: 5,
                }}
              >
                <GridColumn field="gathDtm" headerCell={hdCell} title="날짜"></GridColumn>
                <GridColumn field="rgnTermSimple" headerCell={hdCell} title="지역" cell={rgnCell}></GridColumn>
                <GridColumn title="석탄화력 발전량">
                  <GridColumn
                    field="thermalPower"
                    headerCell={MWHeader}
                    title={search.dateType === "DD" ? "일별 발전량" : "월별 발전량"}
                    cell={thermalPowerCell}
                  ></GridColumn>
                  <GridColumn field="thermalAcPower" headerCell={GWHeader} title="누적 발전량" cell={thermalAcPowerCell}></GridColumn>
                </GridColumn>
                <GridColumn field="solaPower" title="신재생에너지 발전량">
                  <GridColumn
                    field="solaPower"
                    title={search.dateType === "DD" ? "일별 발전량" : "월별 발전량"}
                    headerCell={kWHeader}
                    cell={solaPowerCell}
                  ></GridColumn>
                  <GridColumn field="solaPower" title="누적 발전량" headerCell={MWHeader} cell={solaAcPowerCell}></GridColumn>
                </GridColumn>
                {/* <GridColumn field="" title="석탄에너지 대비 태양광에너지 발전비율" headerCell={solaThHeader} cell={ratioCell}></GridColumn> */}
              </Grid>
            </IntlProvider>
          </LocalizationProvider>
        </span>
      </div>
      {loading && (
        <p className="loadingSpinner">
          <BeatLoader loading={true} size={24} color="#1e88e5" />
        </p>
      )}
    </div>
  );
};

export default ThermalCmp;
