import { parseDate } from "./../../com/dateUtil";

// 화력발전, 태양광발전량 조회
export const getThermalPowerList = async (conn, search, setChartData) => {
  const { rgnCode, startDate, endDate, dateType, rgnTermSimple } = search;

  const config = {
    params: {
      rgnCode: rgnCode,
      startDate: parseDate("yyyy-mm-dd", startDate),
      endDate: parseDate("yyyy-mm-dd", endDate),
      dateType: dateType,
    },
  };

  const result = await conn.get("/thermal/list/" + dateType, config);
  result.forEach((e) => {
    e.rgnTermSimple = rgnTermSimple;
  });

  //차트 데이터 가공
  let thPwList = [];
  let solaPwList = [];
  result.forEach((item) => {
    const name = item.gathDtm;
    const sola = item.solaPower;
    const thermal = item.thermalPower;
    thPwList.push({ name: name, y: sola });
    solaPwList.push({ name: name, y: thermal });
  });

  setChartData({ pwrQtyList: thPwList, dayPowerList: solaPwList });
  return result;
};
