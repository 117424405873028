import styles from "./installStatDepth1Item.module.css";
import { useHistory } from "react-router-dom";

const InstallStatDepth1Item = (props) => {
  const history = useHistory();
  let item = props.dataItem;

  const dayPower = item.solaDayPower + item.veloDayPower + item.fuelcellDayPower;
  const dayUse = item.heatDayUse + item.heathDayUse;
  const powerCapa = item.solaCapa + item.veloCapa + item.fuelcellCapa + item.essCapa;
  const totalCnt = item.solaCnt + item.heatCnt + item.heathCnt + item.veloCnt + item.fuelcellCnt + item.essCnt;

  const onClickEvt = () => {
    history.push("/location/thridDepth?rgnCode=" + item.rgnCode);
  };

  return (
    <>
      <table className="chmapTable-B mapLocation1D">
        <thead>
          <tr>
            <th colSpan="2" onClick={onClickEvt} style={{ cursor: "pointer" }}>
              {item.rgnTerm}
            </th>
            <td colSpan="3">
              {/* <span className="titleTbarWimg">
                    <img src="/images/weather/weather_icon01.png" alt="구름"/>
                  </span>
                    23&#730;C&nbsp;&nbsp;&#124;&nbsp;&nbsp;맑음 */}
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>금일발전량</th>
            <td className={styles.energyTxtRight}>
              <label className="textcoR">{dayPower}</label> <span>kWh</span>
            </td>
            <th>금일생산량</th>
            <td className={styles.energyTxtRight} colSpan="2">
              <label className="textcoR">{dayUse}</label> <span>kWh</span>
            </td>
          </tr>
          <tr>
            <th>
              설비용량&nbsp;
              {/* <i className="material-icons">info</i>*/}
            </th>
            <td colSpan="4">
              <img src="/images/iconS_A.png" alt="" />
              <label className="textcoB">{powerCapa}</label> <span>kWh&nbsp;&nbsp;</span>
              <span>|&nbsp;&nbsp;</span>
              <img src="/images/iconS_B.png" alt="" />
              <label className="textcoB">{item.heatCapa}</label> <span>kWh&nbsp;&nbsp;</span>
              <span>|&nbsp;&nbsp;</span>
              <img src="/images/iconS_C.png" alt="" />
              <label className="textcoB">{item.heathCapa}</label> <span>kWh&nbsp;&nbsp;</span>
            </td>
          </tr>
        </tbody>
      </table>
      <table className="chmapTable-C mapLocation1D">
        <tr>
          <th rowSpan="4" className="linetitle">
            운영개소
            <br />
            (개소)
          </th>
          <th>전체</th>
          <td>{totalCnt}</td>
        </tr>
        <tr>
          <th>태양광</th>
          <td>{item.solaCnt}</td>
          <th>풍력</th>
          <td>{item.veloCnt}</td>
        </tr>
        <tr>
          <th>태양열</th>
          <td>{item.heatCnt}</td>
          <th>연료전지</th>
          <td>{item.fuelcellCnt}</td>
        </tr>
        <tr>
          <th>지열</th>
          <td>{item.heathCnt}</td>
          <th>ESS</th>
          <td>{item.essCnt}</td>
        </tr>
      </table>
    </>
  );
};

export default InstallStatDepth1Item;
