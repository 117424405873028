import { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import { orderBy } from "@progress/kendo-data-query";
import { Grid, GridColumn as Column, GridNoRecords } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import kendo from "@progress/kendo-ui";

import styles from "./expctBuildBsman.module.css";
import { numFormat, roundNum } from "./../../../../com/dataUtil";

const ExpctBuildBsmanGrid = ({ dataSourceGrid, dataSourceChart, dataSourceExcel, setDataSource, userInfo }) => {
  // 엑셀 다운로드 변수 (레퍼런스 사용)
  let _excelData;

  let [sort, setSort] = useState([{ field: "", dir: "" }]);

  // 엑셀 다운로드 이벤트
  const onClickEvt = (e) => {
    dataSourceExcel.save();
  };

  // 정렬 이벤트
  const onSortChange = (e) => {
    const { sort } = e;
    setSort(sort);
  };

  // 차트 옵션
  let chartExpctConsm = {
    chart: {
      type: "column",
      zoomType: "xy",
      backgroundColor: "#f7f9fa",
    },
    title: { text: null },
    xAxis: {
      categories: ["5일전", "4일전", "3일전", "2일전", "1일전", "오늘(수집중)", "내일"],
      crosshair: true,
      labels: {
        style: {
          fontSize: "18px",
        },
      },
    },
    yAxis: [
      {
        labels: {
          format: "{value:,.0f}",
        },
        title: {
          text: "발전량(KWh)",
        },
      },
    ],
    credits: { enabled: false },
    legend: { layout: "horizontal", align: "center", verticalAlign: "top", floating: false },
    colors: ["#b77d08", "#6f8835", "#1f624d", "#542871", "#85275c", "#32768e", "#33427a", "#818385"],
    tooltip: {
      shared: true,
      formatter: function () {
        let s = "<b>" + this.points[0].key + "</b>";
        for (let i = 0; i < this.points.length; i++) {
          s +=
            "<br/>" +
            '<span style="color:' +
            this.points[i].point.color +
            '">\u25CF</span> ' +
            this.points[i].series.name +
            ": " +
            numFormat(roundNum(this.points[i].y, 2)) +
            " kWh";
        }
        return s;
      },
    },
    series: dataSourceChart,
  };

  // 엑셀 변수 상태 설정
  const setExcelData = (_excelData) => {
    setDataSource((dataSource) => {
      const item = { ...dataSource };
      item.excelData = _excelData;
      return item;
    });
  };

  // 파일 이름
  const fileName = () => {
    let name = userInfo.userId;
    const template = name + "_구축사업자_예측_통계" + kendo.toString(new Date(), "yyyyMMdd") + ".xlsx";
    return template;
  };

  useEffect(() => {
    // 엑셀 다운로드 데이터 설정
    setExcelData(_excelData);
  }, []);
  Highcharts.setOptions({
    lang: {
      thousandsSep: ",",
    },
  });

  return (
    <>
      <div className="expctConsmBackground">
        <b>· &nbsp;예측 그래프 </b>
      </div>
      <div className="expctConsmContent">
        <HighchartsReact Highcharts={Highcharts} options={chartExpctConsm} containerProps={{ style: { height: "100%" } }} />
      </div>
      <div className="expctConsmBackground">
        <b>· &nbsp;예측 통계 </b>
        <Button className="expctConsmExcelBtn" style={{ cursor: "pointer", position: "absolute", right: 55 }} onClick={onClickEvt} icon="excel">
          엑셀저장
        </Button>
      </div>
      <div className="expctConsmContent">
        <ExcelExport className="expctBuildGrid" data={orderBy(dataSourceGrid, sort)} ref={(exporter) => (_excelData = exporter)} fileName={fileName()}>
          <ExcelExportColumn field="ensoTypeTerm" title="에너지원" width={100} />
          <ExcelExportColumn field="dayAgo5" title="5일전(kWh)" width={100} />
          <ExcelExportColumn field="dayAgo4" title="4일전(kWh)" width={100} />
          <ExcelExportColumn field="dayAgo3" title="3일전(kWh)" width={100} />
          <ExcelExportColumn field="dayAgo2" title="2일전(kWh)" width={100} />
          <ExcelExportColumn field="dayAgo1" title="1일전(kWh)" width={100} />
          <ExcelExportColumn field="dayAgo0" title="오늘(kWh)" width={100} />
          <ExcelExportColumn field="tomorrow" title="내일(kWh)" width={100} />
        </ExcelExport>
        <Grid
          className="expctBuildGrid"
          data={dataSourceGrid}
          // onSortChange={onSortChange}
          // sort = {sort}
          // sortable
        >
          <GridNoRecords>{"조회 결과 데이터가 없습니다."}</GridNoRecords>
          <Column field="ensoTypeTerm" title="에너지원" />
          <Column field="dayAgo5" title="5일전(kWh)" />
          <Column field="dayAgo4" title="4일전(kWh)" />
          <Column field="dayAgo3" title="3일전(kWh)" />
          <Column field="dayAgo2" title="2일전(kWh)" />
          <Column field="dayAgo1" title="1일전(kWh)" />
          <Column field="dayAgo0" title="오늘(kWh)" />
          <Column field="tomorrow" title="내일(kWh)" />
        </Grid>
      </div>
    </>
  );
};

export default ExpctBuildBsmanGrid;
