import { useEffect, useState } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { getUserMngRgnList, isManager, isInstaller, isOneManager } from "./../../../service/authService";

/**
 * 지역(시군구) 드롭다운 공용 컴포넌트 (2022-03-23 권영서)
 * @param {*} props
 * rgnList : rgnList
 * search : useState search 객체
 * objKey : 객체 키
 * setSearch : useState setSearch 함수
 * onChange : onChange 이벤트
 * name : name
 * id : id
 * className : className
 * @returns
 */
const RgnDropdown = ({ rgnList, search, objKey, setSearch, onChange, name, id, className }) => {
  const userMngRgnList = getUserMngRgnList();
  const defualtValRgn = { rgnTermSimple: "전체", rgnCode: "" };
  const [rgns, setRgns] = useState([]);

  useEffect(() => {
    // 지역 리스트
    let rgnListfilter = [];
    if (isManager() === true) {
      rgnList.forEach((element) => {
        if (userMngRgnList.length != 0) {
          userMngRgnList.forEach((rgnCode) => {
            if (rgnCode === element.rgnCode) {
              rgnListfilter.push(element);
            }
          });
        } else {
          rgnListfilter.push(element);
        }
      });
    } else {
      rgnListfilter = rgnList;
    }
    setRgns(rgnListfilter);
  }, [rgnList]);

  useEffect(() => {
    //지역 하나만 관리하는 관리자라면 해당 지역으로 선택되게 함
    if (isOneManager() && rgns.length) setSearch({ ...search, [objKey]: rgns[0], rgnCode: rgns[0].rgnCode, rgnTermSimple: rgns[0].rgnTermSimple });
  }, [rgns]);

  return (
    <DropDownList
      id={id}
      name={name}
      className={className}
      data={rgns}
      disabled={isInstaller() || isOneManager() === true ? true : false}
      value={search[objKey]}
      textField="rgnTermSimple"
      dataItemKey="rgnCode"
      defaultItem={defualtValRgn}
      onChange={onChange}
    ></DropDownList>
  );
};

export default RgnDropdown;
