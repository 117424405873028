import LocationDepth1List from "./locationDepth1_list";
import { DropDownList } from "@progress/kendo-react-dropdowns";

import { getLocationEnergyList } from "../../../service/location/locationDepth1Service";
import { getInstallMeainStatData } from "../../../service/location/locationService";
import { getUserMngRgnList, isInstaller, isManager } from "../../../service/authService";

const LocationDepth1Search = ({ conn, search, setSearch, codeList, rgnList, dongList, rgnMapDataList, setRgnMapDataList, setMapDataList, setStatCnt }) => {
  // 담당자 일 떄 시군구 리스트 설정
  if (isManager() === true) {
    const userMngRgnList = getUserMngRgnList();
    const rgnListfilter = [];
    rgnList.forEach((element) => {
      if (userMngRgnList.length != 0) {
        userMngRgnList.forEach((rgnCode) => {
          if (rgnCode === element.rgnCode) {
            rgnListfilter.push(element);
          }
        });
      } else {
        rgnListfilter.push(element);
      }
    });
    rgnList = rgnListfilter;
  }

  if (rgnList.length !== 0) {
    rgnList.forEach((element) => {
      element.rgnTerm = element.rgnTermSimple;
    });
  }

  // 드롭다운 체인지 이벤트
  const onchange = (e) => {
    setSearch((search) => {
      const item = { ...search };
      item.rgnCode = e.target.value.rgnCode;

      return item;
    });

    getLocationEnergyList(conn, { rgnCode: e.target.value.rgnCode }).then((result) => {
      setRgnMapDataList((rgnMapDataList) => {
        const item = { ...rgnMapDataList };
        item.dataMapList = result.locationEnergyList;

        return item;
      });

      meainCntStat({ rgnCode: e.target.value.rgnCode });
    });
  };

  const meainCntStat = (search) => {
    getInstallMeainStatData(conn, getResultMeainStat, getRejection, search);
  };

  const getResultMeainStat = (result) => {
    setStatCnt((statCnt) => {
      const item = { ...statCnt };
      item.totalCnt = result.totalCnt;
      item.readyCnt = result.readyCnt;
      item.normalCnt = result.normalCnt;
      item.unOperCnt = result.unOperCnt;
      item.warnCnt = result.warnCnt;
      item.errorCnt = result.errorCnt;
      return item;
    });
  };

  // reject 함수
  const getRejection = (error) => {};

  return (
    <div className="uk-width-medium-3-10">
      <div className="Depth1InstallArea">
        <div className="location1ChmapTBox">
          <div className="chmapTT">충남 설치지역검색</div>
          <div className="MapdropdownRgn">
            <DropDownList
              className="locationDropDownRgn"
              id="rgn"
              name="rgn"
              data={rgnList}
              defaultItem={{ rgnCode: "", rgnTerm: "시군구" }}
              disabled={isInstaller() === true ? true : false}
              dataItemKey="rgnCode"
              textField="rgnTerm"
              onChange={onchange}
            />
          </div>
          <LocationDepth1List setMapDataList={setMapDataList} rgnMapDataList={rgnMapDataList} search={search} />
        </div>
      </div>
    </div>
  );
};

export default LocationDepth1Search;
