// React
import React, { useState, useEffect } from "react";
import log from "../../../com/log";

// Kendo
import { Dialog } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";

function RtuModal({ title, item, cancelEdit, onSubmit, codeList, dongList }) {
  const useYnDDL = ["Y", "N"];
  const testStateDDL = codeList.filter((c) => c.grpCodeNo === 34).map((item) => item.codeVal);

  dongList.forEach((element) => {
    element.dongTerm = element.fixbylawBundTerm.substring(5);
  });
  // 읍/면/동 드롭다운 리스트 값 설정
  item.dongInfo = item.dongInfo ? dongList.filter((v) => v.fixbylawBundCode === item.dongCode)[0] : { fixbylawBundCode: "", fixbylawBundTermFix: "읍/면/동" };

  return (
    <Dialog title={title} onClose={cancelEdit} width={500}>
      <Form
        onSubmit={onSubmit}
        initialValues={item}
        render={(formRenderProps) => (
          <FormElement>
            <fieldset className={"k-form-fieldset"}>
              <div className="mb-3">
                {/* <Field
                  style={{ width: '100%' }}
                  name={'rtuEnteCode'}
                  label={'업체코드'}
                  component={Input}
                  placeholder={'자동생성됩니다.'}
                  readOnly={true}
                /> */}
              </div>
              <div className="mb-3">
                {title === "추가" ? (
                  <Field style={{ width: "100%" }} name="rtuEnteId" label="업체ID" component={Input} placeholder="아이디" required />
                ) : (
                  <Field style={{ width: "100%" }} name="rtuEnteId" label="업체ID" component={Input} placeholder="아이디" readOnly={true} />
                )}
              </div>
              <div className="mb-3">
                <Field
                  style={{ width: "100%" }}
                  name="loginPwd"
                  label="패스워드"
                  component={Input}
                  type="password"
                  placeholder="비밀번호를 입력 해주세요."
                  // required
                />
              </div>
              <div className="mb-3">
                <Field style={{ width: "100%" }} name="rtuEnteTerm" label="업체명" component={Input} placeholder="업체명" required />
              </div>
              <div className="mb-3">
                <Field
                  style={{ width: "100%" }}
                  name="dongInfo"
                  label="읍/면/동"
                  component={DropDownList}
                  placeholder="읍/면/동"
                  data={dongList}
                  textField="dongTerm"
                  dataItemKey="fixbylawBundCode"
                  defaultValue={{ fixbylawBundCode: "", fixbylawBundTermFix: "읍/면/동" }}
                  required
                />
              </div>
              <div className="mb-3">
                <Field style={{ width: "100%" }} name="rtuEnteAddr" label="업체 주소" component={Input} placeholder="업체 주소" required />
              </div>
              <div className="mb-3">
                <Field style={{ width: "100%" }} name="rtuEnteCntct" label="업체 연락처" component={Input} placeholder="업체 연락처" required />
              </div>
              <div className="mb-3">
                <Field
                  style={{ width: "100%" }}
                  name="rtuEntePhon"
                  label="담당자 연락처"
                  component={Input}
                  type="tel"
                  pattern="[0-9]{3}-[0-9]{4}-[0-9]{4}"
                  placeholder="010-1234-5678"
                  required
                />
              </div>
              <div className="mb-3">
                <Field style={{ width: "100%" }} name="rtuEnteExpl" label="업체 설명" component={Input} placeholder="업체 설명" />
              </div>
              <div className="mb-3">
                <Field style={{ width: "100%" }} name="rtuEnteEmail" label="이메일" component={Input} type="email" placeholder="myname@example.com" required />
              </div>
              <div className="mb-3">
                <Field style={{ width: "100%" }} name="smsNotiUseYn" label="SMS 수신여부" component={DropDownList} data={useYnDDL} defaultValue={useYnDDL[0]} />
              </div>
              <div className="mb-3">
                <Field
                  style={{ width: "100%" }}
                  name="emailNotiUseYn"
                  label="EMAIL 수신여부"
                  component={DropDownList}
                  data={useYnDDL}
                  defaultValue={useYnDDL[0]}
                />
              </div>
              <div className="mb-3">
                <Field
                  style={{ width: "100%" }}
                  name="testState"
                  label="테스트 상태"
                  component={DropDownList}
                  data={testStateDDL}
                  defaultValue={testStateDDL[0]}
                />
              </div>
              <div className="mb-3">
                <Field style={{ width: "100%" }} name="useYn" label="사용여부" component={DropDownList} data={useYnDDL} defaultValue={useYnDDL[0]} />
              </div>
            </fieldset>
            <div className="k-form-buttons">
              <Button type={"submit"} disabled={!formRenderProps.allowSubmit}>
                {title}
              </Button>
              <Button type={"submit"} onClick={cancelEdit}>
                취소
              </Button>
            </div>
          </FormElement>
        )}
      />
    </Dialog>
  );
}

export default RtuModal;
