// 설비 정보 지열
import React, { useState, useEffect } from "react";
import kendo from "@progress/kendo-ui";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import DateTimePickerKo from "../../com/dateInputs/dateTimePickerKo";
import styles from "./meain.module.css";
import DatePickerKo from "../../com/dateInputs/datePickerKo";
import { Input } from "@progress/kendo-react-inputs";

// 지열 설비 정보
function Meain_info_heath({ selectMeain, setMeainInfo, codeList, meainModlList, inputValidMsg, itemRender }) {
  const [heatPumpMakr, setHeatPumpMakr] = useState(
    selectMeain.heatPumpMakrNo ? { makrSeriNo: selectMeain.heatPumpMakrNo, makrTerm: "" } : { makrSeriNo: "", makrTerm: "" },
  );
  const [meainTypeSeri, setMeainTypeSeri] = useState(
    selectMeain.meainTypeSeriNo ? { meainTypeSeriNo: selectMeain.meainTypeSeriNo, meainTypeTerm: "" } : { meainTypeSeriNo: "", meainTypeTerm: "" },
  );
  const [hwClrmtMakr, setHwClrmtMakr] = useState(
    selectMeain.hwClrmtMakrNo ? { makrSeriNo: selectMeain.hwClrmtMakrNo, makrTerm: "" } : { makrSeriNo: "", makrTerm: "" },
  );
  const [hwClrmtModl, setHwClrmtModl] = useState(
    selectMeain.hwClrmtModlNo ? { modulTypeNo: selectMeain.hwClrmtModlNo, meainTypeTerm: "" } : { modulTypeNo: "", meainTypeTerm: "" },
  );
  const [hcClrmtMakr, setHcClrmtMakr] = useState(
    selectMeain.hcClrmtMakrNo ? { makrSeriNo: selectMeain.hcClrmtMakrNo, makrTerm: "" } : { makrSeriNo: "", makrTerm: "" },
  );
  const [hcClrmtModl, setHcClrmtModl] = useState(
    selectMeain.hcClrmtModlNo ? { meainTypeSeriNo: selectMeain.hcClrmtModlNo, meainTypeTerm: "" } : { meainTypeSeriNo: "", meainTypeTerm: "" },
  );
  const [elctrMeterMakr, setElctrMeterMakr] = useState(
    selectMeain.elctrMeterMakrNo ? { makrSeriNo: selectMeain.elctrMeterMakrNo, makrTerm: "" } : { makrSeriNo: "", makrTerm: "" },
  );
  const [elctrMeterModl, setElctrMeterModl] = useState(
    selectMeain.elctrMeterModlNo ? { modulTypeNo: selectMeain.elctrMeterModlNo, meainTypeTerm: "" } : { modulTypeNo: "", meainTypeTerm: "" },
  );
  const [bntnMakr, setBntnMakr] = useState(selectMeain.bntnMakrNo ? { makrSeriNo: selectMeain.bntnMakrNo, makrTerm: "" } : { makrSeriNo: "", makrTerm: "" });
  const [bntnType, setBntnType] = useState(
    selectMeain.bntnTypeNo ? { meainTypeSeriNo: selectMeain.bntnTypeNo, meainTypeTerm: "" } : { meainTypeSeriNo: "", meainTypeTerm: "" },
  );

  const [bldSrvCodeNo, setBldSrvCodeNo] = useState(selectMeain.bldSrvCodeNo ? { codeNo: selectMeain.bldSrvCodeNo, codeVal: "" } : { codeNo: "", codeVal: "" });
  const bldSrvDs = codeList.filter((c) => c.grpCodeNo === 31);

  // 히트펌프 제조사 목록
  const heatPumpMakrList = meainModlList.length !== 0 ? meainModlList.makrDtoList.filter((c) => c.makrClsfNo == 30005) : [];
  // 히트펌프 모델 목록
  const meainTypeSeriList =
    meainModlList.length !== 0 ? meainModlList.meainModelDtoList.filter((c) => c.ensoTypeCodeNo === 15003 && c.makrSeriNo === selectMeain.heatPumpMakrNo) : [];
  // 급탕측 / 냉난방측 열량계 제조사
  const clrmtMakrList = meainModlList.length !== 0 ? meainModlList.makrDtoList.filter((c) => c.makrClsfNo == 30014) : [];
  // 급탕측 열량계 모델
  const hwClrmtModlList =
    meainModlList.length !== 0 ? meainModlList.meainModelDtoList.filter((c) => c.ensoTypeCodeNo === 15003 && c.makrSeriNo === selectMeain.hwClrmtMakrNo) : [];
  // 냉난방측 열량계 모델
  const hcClrmtModlList =
    meainModlList.length !== 0 ? meainModlList.meainModelDtoList.filter((c) => c.ensoTypeCodeNo === 15003 && c.makrSeriNo === selectMeain.hcClrmtMakrNo) : [];
  // 전력량계 제조사
  const elctrMeterMakrList = meainModlList.length !== 0 ? meainModlList.makrDtoList.filter((c) => c.makrClsfNo == 30015) : [];
  // 전력량계 모델
  const elctrMeterModlList =
    meainModlList.length !== 0
      ? meainModlList.meainModelDtoList.filter((c) => c.ensoTypeCodeNo === 15003 && c.makrSeriNo === selectMeain.elctrMeterMakrNo)
      : [];
  // 벤토나이트 제조사
  const bntnMakrList = meainModlList.length !== 0 ? meainModlList.makrDtoList.filter((c) => c.makrClsfNo == 30006) : [];
  // 벤토나이트 모델
  const bntnTypeList =
    meainModlList.length !== 0 ? meainModlList.meainModelDtoList.filter((c) => c.ensoTypeCodeNo === 15003 && c.makrSeriNo === selectMeain.bntnMakrNo) : [];

  // 드롭다운 값 변경 이벤트 (Single 데이터 버전)
  const onchageDropdown = (e) => {
    // 값변경한 부분에 대해서 추적하여 객체 이름 기입하기 위해 name 값 가져오기
    const objName = e.target.name;
    const meainInfo = {
      ...selectMeain,
      [objName]: e.value,
    };
    setMeainInfo(meainInfo);
  };

  // Input 데이터 값 변경 이벤트
  const onchageInput = (e) => {
    let meainInfo = {};
    const objName = e.target.name;
    // 지열은  히트펌프 용량이 설비 용량이다.
    if (e.target.name === "heatPumpCapa") {
      meainInfo = {
        ...selectMeain,
        [objName]: e.target.value,
        instCapa: e.target.value,
      };
    } else {
      // 값변경한 부분에 대해서 추적하여 객체 이름 기입하기 위해 name 값 가져오기
      meainInfo = {
        ...selectMeain,
        [objName]: e.target.value,
      };
    }
    setMeainInfo(meainInfo);
  };

  // Date 데이터 값 변경 이벤트
  const onchageDate = (v, name) => {
    // 값변경한 부분에 대해서 추적하여 객체 이름 기입하기 위해 name 값 가져오기
    const meainInfo = {
      ...selectMeain,
      [name]: kendo.toString(v, "yyyy-MM-dd"),
    };
    setMeainInfo(meainInfo);
  };

  // DateTimeOnChangeDate
  const onChangeDateTiem = (v, name) => {
    const meainInfo = {
      ...selectMeain,
      [name]: v,
      // ,  [name] : kendo.toString(v , 'yyyy-MM-dd HH:mm:00')
    };
    setMeainInfo(meainInfo);
  };

  // 날짜 포맷 지정
  const dateFormat = (date) => {
    if (typeof date !== "undefined") {
      if (date !== "") {
        date = new Date(date);
      } else {
        date = "";
      }
    } else {
      date = "";
    }
    return date;
  };

  // 제조사 converter
  const makrConverter = () => {
    if (Object.keys(selectMeain).length !== 0) {
      if (selectMeain.heatPumpMakrNo) {
        let makrSeriNo = "";
        let makrTerm = "";
        heatPumpMakrList.forEach((element) => {
          if (element.makrSeriNo == selectMeain.heatPumpMakrNo) {
            makrSeriNo = element.makrSeriNo;
            makrTerm = element.makrTerm;
          }
        });
        setHeatPumpMakr({ makrSeriNo: makrSeriNo, makrTerm: makrTerm });
      }
      if (selectMeain.hwClrmtMakrNo) {
        let makrSeriNo = "";
        let makrTerm = "";
        clrmtMakrList.forEach((element) => {
          if (element.makrSeriNo == selectMeain.hwClrmtMakrNo) {
            makrSeriNo = element.makrSeriNo;
            makrTerm = element.makrTerm;
          }
        });
        setHwClrmtMakr({ makrSeriNo: makrSeriNo, makrTerm: makrTerm });
      }
      if (selectMeain.hcClrmtMakrNo) {
        let makrSeriNo = "";
        let makrTerm = "";
        clrmtMakrList.forEach((element) => {
          if (element.makrSeriNo == selectMeain.hcClrmtMakrNo) {
            makrSeriNo = element.makrSeriNo;
            makrTerm = element.makrTerm;
          }
        });
        setHcClrmtMakr({ makrSeriNo: makrSeriNo, makrTerm: makrTerm });
      }
      if (selectMeain.elctrMeterMakrNo) {
        let makrSeriNo = "";
        let makrTerm = "";
        elctrMeterMakrList.forEach((element) => {
          if (element.makrSeriNo == selectMeain.elctrMeterMakrNo) {
            makrSeriNo = element.makrSeriNo;
            makrTerm = element.makrTerm;
          }
        });
        setElctrMeterMakr({ makrSeriNo: makrSeriNo, makrTerm: makrTerm });
      }
      if (selectMeain.bntnMakrNo) {
        let makrSeriNo = "";
        let makrTerm = "";
        bntnMakrList.forEach((element) => {
          if (element.makrSeriNo == selectMeain.bntnMakrNo) {
            makrSeriNo = element.makrSeriNo;
            makrTerm = element.makrTerm;
          }
        });
        setBntnMakr({ makrSeriNo: makrSeriNo, makrTerm: makrTerm });
      }
    } else {
      setHeatPumpMakr({ makrSeriNo: "", makrTerm: "" });
      setHwClrmtMakr({ makrSeriNo: "", makrTerm: "" });
      setHcClrmtMakr({ makrSeriNo: "", makrTerm: "" });
      setElctrMeterMakr({ makrSeriNo: "", makrTerm: "" });
      setBntnMakr({ makrSeriNo: "", makrTerm: "" });
    }
  };
  // 모델 Converter
  const modlConverter = () => {
    if (Object.keys(selectMeain).length !== 0) {
      if (selectMeain.meainTypeSeriNo) {
        let meainTypeSeriNo = "";
        let meainTypeTerm = "";
        meainTypeSeriList.forEach((element) => {
          if (element.meainTypeSeriNo == selectMeain.meainTypeSeriNo) {
            meainTypeSeriNo = element.meainTypeSeriNo;
            meainTypeTerm = element.meainTypeTerm;
          }
        });
        setMeainTypeSeri({ meainTypeSeriNo: meainTypeSeriNo, meainTypeTerm: meainTypeTerm });
      } else {
        setMeainTypeSeri({ meainTypeSeriNo: "", meainTypeTerm: "" });
      }
      if (selectMeain.hwClrmtModlNo) {
        let meainTypeSeriNo = "";
        let meainTypeTerm = "";
        hwClrmtModlList.forEach((element) => {
          if (element.meainTypeSeriNo == selectMeain.hwClrmtModlNo) {
            meainTypeSeriNo = element.meainTypeSeriNo;
            meainTypeTerm = element.meainTypeTerm;
          }
        });
        setHwClrmtModl({ meainTypeSeriNo: meainTypeSeriNo, meainTypeTerm: meainTypeTerm });
      } else {
        setHwClrmtModl({ meainTypeSeriNo: "", meainTypeTerm: "" });
      }
      if (selectMeain.hcClrmtModlNo) {
        let meainTypeSeriNo = "";
        let meainTypeTerm = "";
        hcClrmtModlList.forEach((element) => {
          if (element.meainTypeSeriNo == selectMeain.hcClrmtModlNo) {
            meainTypeSeriNo = element.meainTypeSeriNo;
            meainTypeTerm = element.meainTypeTerm;
          }
        });
        setHcClrmtModl({ meainTypeSeriNo: meainTypeSeriNo, meainTypeTerm: meainTypeTerm });
      } else {
        setHcClrmtModl({ meainTypeSeriNo: "", meainTypeTerm: "" });
      }
      if (selectMeain.elctrMeterModlNo) {
        let meainTypeSeriNo = "";
        let meainTypeTerm = "";
        elctrMeterModlList.forEach((element) => {
          if (element.meainTypeSeriNo == selectMeain.elctrMeterModlNo) {
            meainTypeSeriNo = element.meainTypeSeriNo;
            meainTypeTerm = element.meainTypeTerm;
          }
        });
        setElctrMeterModl({ meainTypeSeriNo: meainTypeSeriNo, meainTypeTerm: meainTypeTerm });
      } else {
        setElctrMeterModl({ meainTypeSeriNo: "", meainTypeTerm: "" });
      }
      if (selectMeain.bntnTypeNo) {
        let meainTypeSeriNo = "";
        let meainTypeTerm = "";
        bntnTypeList.forEach((element) => {
          if (element.meainTypeSeriNo == selectMeain.bntnTypeNo) {
            meainTypeSeriNo = element.meainTypeSeriNo;
            meainTypeTerm = element.meainTypeTerm;
          }
        });
        setBntnType({ meainTypeSeriNo: meainTypeSeriNo, meainTypeTerm: meainTypeTerm });
      } else {
        setBntnType({ meainTypeSeriNo: "", meainTypeTerm: "" });
      }
    } else {
      setMeainTypeSeri({ meainTypeSeriNo: "", meainTypeTerm: "" });
      setHwClrmtModl({ meainTypeSeriNo: "", meainTypeTerm: "" });
      setHcClrmtModl({ meainTypeSeriNo: "", meainTypeTerm: "" });
      setElctrMeterModl({ meainTypeSeriNo: "", meainTypeTerm: "" });
      setBntnType({ meainTypeSeriNo: "", meainTypeTerm: "" });
    }
  };

  // Converter 건물용도별
  const bldSrvConverter = () => {
    if (Object.keys(selectMeain).length !== 0) {
      let codeNo = "";
      let codeVal = "";
      bldSrvDs.forEach((element) => {
        if (element.codeNo == selectMeain.bldSrvCodeNo) {
          codeNo = element.codeNo;
          codeVal = element.codeVal;
        }
      });
      setBldSrvCodeNo({ codeNo: codeNo, codeVal: codeVal });
    } else {
      setBldSrvCodeNo({ codeNo: "", codeVal: "-" });
    }
  };

  useEffect(() => {
    // props의 데이터를 각각 데이터 소스 객체에 맞게 Convert 작업 해준다.
    makrConverter();
    modlConverter();
    bldSrvConverter();
  }, [selectMeain]); // <- 이걸 해줘야 다른 컴포넌트에 값을 입력해준다.

  return (
    <table className="Ch-TableN">
      <tr>
        <th>시스템방식</th>
        <td>
          <DropDownList
            id="sysMthd"
            name="sysMthd"
            className="meainInfoDropDown"
            data={["개방형", "수직밀폐형"]}
            value={selectMeain.sysMthd ? selectMeain.sysMthd : ""}
            onChange={onchageDropdown}
            required={true}
            validationMessage={inputValidMsg.normalText}
            itemRender={itemRender}
          />
        </td>
        <th>시스템구분</th>
        <td>
          <DropDownList
            id="sysSct"
            name="sysSct"
            className="meainInfoDropDown"
            data={["물-물", "물-공기", "물-공기멀티"]}
            value={selectMeain.sysSct ? selectMeain.sysSct : ""}
            onChange={onchageDropdown}
            required={true}
            validationMessage={inputValidMsg.normalText}
            itemRender={itemRender}
          />
        </td>
        <th>히트펌프용량(kW)</th>
        <td>
          <Input
            id="heatPumpCapa"
            name="heatPumpCapa"
            type="number"
            className={styles.searchTermTBC}
            value={selectMeain.heatPumpCapa ? selectMeain.heatPumpCapa : ""}
            onChange={onchageInput}
            required={true}
            validationMessage={inputValidMsg.number2Fix}
            step="0.01"
            min={0}
          />
        </td>
      </tr>
      <tr>
        <th colSpan="6">
          <div></div>
        </th>
      </tr>
      <tr>
        <th>히트펌프 제조사</th>
        <td>
          <DropDownList
            id="heatPumpMakrNo"
            name="heatPumpMakrNo"
            className="meainInfoDropDown"
            dataItemKey="makrSeriNo"
            textField="makrTerm"
            data={heatPumpMakrList}
            value={heatPumpMakr}
            itemRender={itemRender}
            onChange={(e) => {
              const obj = { ...selectMeain, [e.target.name]: e.value.makrSeriNo };
              setHeatPumpMakr({ makrSeriNo: e.target.value.makrSeriNo, makrTerm: e.target.value.makrTerm });
              setMeainInfo(obj);
            }}
            required={true}
            validationMessage={inputValidMsg.normalText}
            valid={selectMeain.heatPumpMakrNo !== "" && selectMeain.heatPumpMakrNo !== null && typeof selectMeain.heatPumpMakrNo !== "undefined"}
          ></DropDownList>
        </td>
        <th>히트펌프 모델명</th>
        <td>
          <DropDownList
            id="meainTypeSeriNo"
            name="meainTypeSeriNo"
            className="meainInfoDropDown"
            dataItemKey="meainTypeSeriNo"
            textField="meainTypeTerm"
            data={meainTypeSeriList}
            itemRender={itemRender}
            value={meainTypeSeri}
            disabled={selectMeain.heatPumpMakrNo ? false : true}
            onChange={(e) => {
              const obj = { ...selectMeain, [e.target.name]: e.value.meainTypeSeriNo };
              setMeainTypeSeri({ meainTypeSeriNo: e.target.value.meainTypeSeriNo, meainTypeTerm: e.target.value.meainTypeTerm });
              setMeainInfo(obj);
            }}
            required={true}
            validationMessage={inputValidMsg.normalText}
            valid={selectMeain.meainTypeSeriNo !== "" && selectMeain.meainTypeSeriNo !== null && typeof selectMeain.meainTypeSeriNo !== "undefined"}
          ></DropDownList>
        </td>
        <th></th>
        <td></td>
      </tr>
      <tr>
        <th colSpan="6">
          <div></div>
        </th>
      </tr>
      <tr>
        <th>급탕측 열량계 제조사</th>
        <td>
          <DropDownList
            id="hwClrmtMakrNo"
            name="hwClrmtMakrNo"
            type="text"
            className="meainInfoDropDown"
            dataItemKey="makrSeriNo"
            textField="makrTerm"
            data={clrmtMakrList}
            value={hwClrmtMakr}
            itemRender={itemRender}
            onChange={(e) => {
              const obj = { ...selectMeain, [e.target.name]: e.value.makrSeriNo };
              setHwClrmtMakr({ makrSeriNo: e.target.value.makrSeriNo, makrTerm: e.target.value.makrTerm });
              setMeainInfo(obj);
            }}
            required={true}
            validationMessage={inputValidMsg.normalText}
            valid={selectMeain.hwClrmtMakrNo !== "" && selectMeain.hwClrmtMakrNo !== null && typeof selectMeain.hwClrmtMakrNo !== "undefined"}
          ></DropDownList>
        </td>
        <th>급탕측 열량계 모델명</th>
        <td>
          <DropDownList
            id="hwClrmtModlNo"
            name="hwClrmtModlNo"
            type="text"
            className="meainInfoDropDown"
            dataItemKey="meainTypeSeriNo"
            textField="meainTypeTerm"
            data={hwClrmtModlList}
            itemRender={itemRender}
            value={hwClrmtModl}
            disabled={selectMeain.hwClrmtMakrNo ? false : true}
            onChange={(e) => {
              const obj = { ...selectMeain, [e.target.name]: e.value.meainTypeSeriNo };
              setHwClrmtModl({ meainTypeSeriNo: e.target.value.meainTypeSeriNo, meainTypeTerm: e.target.value.meainTypeTerm });
              setMeainInfo(obj);
            }}
            required={true}
            validationMessage={inputValidMsg.normalText}
            valid={selectMeain.hwClrmtModlNo !== "" && selectMeain.hwClrmtModlNo !== null && typeof selectMeain.hwClrmtModlNo !== "undefined"}
          ></DropDownList>
        </td>
        <th></th>
        <td></td>
      </tr>
      <tr>
        <th colSpan="6">
          <div></div>
        </th>
      </tr>
      <tr>
        <th>냉난방측 열량계 제조사</th>
        <td>
          <DropDownList
            id="hcClrmtMakrNo"
            name="hcClrmtMakrNo"
            className="meainInfoDropDown"
            dataItemKey="makrSeriNo"
            textField="makrTerm"
            data={clrmtMakrList}
            value={hcClrmtMakr}
            itemRender={itemRender}
            onChange={(e) => {
              const obj = { ...selectMeain, [e.target.name]: e.value.makrSeriNo };
              setHcClrmtMakr({ makrSeriNo: e.target.value.makrSeriNo, makrTerm: e.target.value.makrTerm });
              setMeainInfo(obj);
            }}
            required={true}
            validationMessage={inputValidMsg.normalText}
            valid={selectMeain.hcClrmtMakrNo !== "" && selectMeain.hcClrmtMakrNo !== null && typeof selectMeain.hcClrmtMakrNo !== "undefined"}
          ></DropDownList>
        </td>
        <th>냉난방측 열량계 모델명</th>
        <td>
          <DropDownList
            id="hcClrmtModlNo"
            name="hcClrmtModlNo"
            className="meainInfoDropDown"
            dataItemKey="meainTypeSeriNo"
            textField="meainTypeTerm"
            data={hcClrmtModlList}
            itemRender={itemRender}
            value={hcClrmtModl}
            disabled={selectMeain.hcClrmtMakrNo ? false : true}
            onChange={(e) => {
              const obj = { ...selectMeain, [e.target.name]: e.value.meainTypeSeriNo };
              setHcClrmtModl({ meainTypeSeriNo: e.target.value.meainTypeSeriNo, meainTypeTerm: e.target.value.meainTypeTerm });
              setMeainInfo(obj);
            }}
            required={true}
            validationMessage={inputValidMsg.normalText}
            valid={selectMeain.hcClrmtModlNo !== "" && selectMeain.hcClrmtModlNo !== null && typeof selectMeain.hcClrmtModlNo !== "undefined"}
          ></DropDownList>
        </td>
        <th></th>
        <td></td>
      </tr>
      <tr>
        <th colSpan="6">
          <div></div>
        </th>
      </tr>
      <tr>
        <th>전력량계 제조사</th>
        <td>
          <DropDownList
            id="elctrMeterMakrNo"
            name="elctrMeterMakrNo"
            className="meainInfoDropDown"
            dataItemKey="makrSeriNo"
            textField="makrTerm"
            data={elctrMeterMakrList}
            value={elctrMeterMakr}
            itemRender={itemRender}
            onChange={(e) => {
              const obj = { ...selectMeain, [e.target.name]: e.value.makrSeriNo };
              setElctrMeterMakr({ makrSeriNo: e.target.value.makrSeriNo, makrTerm: e.target.value.makrTerm });
              setMeainInfo(obj);
            }}
            required={true}
            validationMessage={inputValidMsg.normalText}
            valid={selectMeain.elctrMeterMakrNo !== "" && selectMeain.elctrMeterMakrNo !== null && typeof selectMeain.elctrMeterMakrNo !== "undefined"}
          ></DropDownList>
        </td>
        <th>전력량계 모델명</th>
        <td>
          <DropDownList
            id="elctrMeterModlNo"
            name="elctrMeterModlNo"
            className="meainInfoDropDown"
            dataItemKey="meainTypeSeriNo"
            textField="meainTypeTerm"
            data={elctrMeterModlList}
            itemRender={itemRender}
            value={elctrMeterModl}
            disabled={selectMeain.elctrMeterMakrNo ? false : true}
            onChange={(e) => {
              const obj = { ...selectMeain, [e.target.name]: e.value.meainTypeSeriNo };
              setElctrMeterModl({ meainTypeSeriNo: e.target.value.meainTypeSeriNo, meainTypeTerm: e.target.value.meainTypeTerm });
              setMeainInfo(obj);
            }}
            required={true}
            validationMessage={inputValidMsg.normalText}
            valid={selectMeain.elctrMeterModlNo !== "" && selectMeain.elctrMeterModlNo !== null && typeof selectMeain.elctrMeterModlNo !== "undefined"}
          ></DropDownList>
        </td>
        <th></th>
        <td></td>
      </tr>
      <tr>
        <th colSpan="6">
          <div></div>
        </th>
      </tr>
      <tr>
        <th>냉온수 유량(LPM)</th>
        <td>
          <Input
            id="hotchOilQty"
            name="hotchOilQty"
            type="number"
            className={styles.searchTermTBC}
            value={selectMeain.hotchOilQty ? selectMeain.hotchOilQty : ""}
            onChange={onchageInput}
            required={true}
            validationMessage={inputValidMsg.number2Fix}
            step="0.01"
            min={0}
          />
        </td>
        <th>지열수 유량(LPM)</th>
        <td>
          <Input
            id="heathwtrOilQty"
            name="heathwtrOilQty"
            type="number"
            className={styles.searchTermTBC}
            value={selectMeain.heathwtrOilQty ? selectMeain.heathwtrOilQty : ""}
            onChange={onchageInput}
            required={true}
            validationMessage={inputValidMsg.number2Fix}
            step="0.01"
            min={0}
          />
        </td>
        <th></th>
        <td></td>
      </tr>
      <tr>
        <th colSpan="6">
          <div></div>
        </th>
      </tr>

      <tr>
        <th>벤토나이트 제조사</th>
        <td>
          <DropDownList
            id="bntnMakrNo"
            name="bntnMakrNo"
            className="meainInfoDropDown"
            dataItemKey="makrSeriNo"
            textField="makrTerm"
            data={bntnMakrList}
            value={bntnMakr}
            itemRender={itemRender}
            onChange={(e) => {
              const obj = { ...selectMeain, [e.target.name]: e.value.makrSeriNo };
              setBntnMakr({ makrSeriNo: e.target.value.makrSeriNo, makrTerm: e.target.value.makrTerm });
              setMeainInfo(obj);
            }}
            required={true}
            validationMessage={inputValidMsg.normalText}
            valid={selectMeain.bntnMakrNo !== "" && selectMeain.bntnMakrNo !== null && typeof selectMeain.bntnMakrNo !== "undefined"}
          ></DropDownList>
        </td>
        <th>벤토나이트 모델명</th>
        <td>
          <DropDownList
            id="bntnTypeNo"
            name="bntnTypeNo"
            className="meainInfoDropDown"
            dataItemKey="meainTypeSeriNo"
            textField="meainTypeTerm"
            data={bntnTypeList}
            itemRender={itemRender}
            value={bntnType}
            disabled={selectMeain.bntnMakrNo ? false : true}
            onChange={(e) => {
              const obj = { ...selectMeain, [e.target.name]: e.value.meainTypeSeriNo };
              setBntnType({ meainTypeSeriNo: e.target.value.meainTypeSeriNo, meainTypeTerm: e.target.value.meainTypeTerm });
              setMeainInfo(obj);
            }}
            required={true}
            validationMessage={inputValidMsg.normalText}
            valid={selectMeain.bntnTypeNo !== "" && selectMeain.bntnTypeNo !== null && typeof selectMeain.bntnTypeNo !== "undefined"}
          ></DropDownList>
        </td>
        <th></th>
        <td></td>
      </tr>
      <tr>
        <th colSpan="6">
          <div></div>
        </th>
      </tr>
      <tr>
        <th>천공깊이(m)</th>
        <td>
          <Input
            id="boringDeep"
            name="boringDeep"
            type="number"
            className={styles.searchTermTBC}
            value={selectMeain.boringDeep ? selectMeain.boringDeep : ""}
            onChange={onchageInput}
            required={true}
            validationMessage={inputValidMsg.number2Fix}
            step="0.01"
            min={0}
          />
        </td>
        <th>천공개수</th>
        <td>
          <Input
            id="boringCnt"
            name="boringCnt"
            type="number"
            className={styles.searchTermTBC}
            value={selectMeain.boringCnt ? selectMeain.boringCnt : ""}
            onChange={onchageInput}
            required={true}
            validationMessage={inputValidMsg.number0Fix}
            min={0}
          />
        </td>
        <th>건물용도</th>
        <td>
          <DropDownList
            className="bldSrvCodeNo"
            id="bldSrvCodeNo"
            name="bldSrvCodeNo"
            data={bldSrvDs}
            value={bldSrvCodeNo}
            itemRender={itemRender}
            dataItemKey="codeNo"
            textField="codeVal"
            onChange={(e) => {
              const obj = { ...selectMeain, [e.target.name]: e.value.codeNo };
              setBldSrvCodeNo({ codeNo: e.target.value.codeNo, codeVal: e.target.value.codeVal });
              setMeainInfo(obj);
            }}
            required={true}
            validationMessage={inputValidMsg.normalText}
            valid={selectMeain.bldSrvCodeNo !== "" && selectMeain.bldSrvCodeNo !== null && typeof selectMeain.bldSrvCodeNo !== "undefined"}
          />
        </td>
      </tr>
      <tr>
        <th colSpan="6">
          <div></div>
        </th>
      </tr>
      <tr>
        <th>시공업체</th>
        <td>
          <Input
            id="consEnte"
            name="consEnte"
            type="text"
            className={styles.searchTermTBC}
            value={selectMeain.consEnte ? selectMeain.consEnte : ""}
            onChange={onchageInput}
            required={true}
            validationMessage={inputValidMsg.normalText}
          />
        </td>
        <th>시공업체 연락처</th>
        <td>
          <Input
            id="consEnteCntct"
            name="consEnteCntct"
            type="text"
            className={styles.searchTermTBC}
            value={selectMeain.consEnteCntct ? selectMeain.consEnteCntct : ""}
            onChange={onchageInput}
            required={true}
            validationMessage={inputValidMsg.normalText}
            placeholder="하이픈(-) 없이 입력해주세요."
          />
        </td>
        <th>
          시공업체
          <br />
          핸드폰 연락처
        </th>
        <td>
          <Input
            id="consPhoneCntct"
            name="consPhoneCntct"
            type="text"
            className={styles.searchTermTBC}
            value={selectMeain.consPhoneCntct ? selectMeain.consPhoneCntct : ""}
            onChange={onchageInput}
            required={true}
            validationMessage={inputValidMsg.normalText}
            placeholder="하이픈(-) 없이 입력해주세요."
          />
        </td>
      </tr>
      <tr>
        <th colSpan="6">
          <div></div>
        </th>
      </tr>
      <tr>
        <th>시공일시</th>
        <td>
          <DateTimePickerKo
            id="meainInstDtm"
            name="meainInstDtm"
            className="meainDatePicker"
            formatPlaceholder={{ year: "년", month: "월", day: "일", hour: "시간", minute: "분" }}
            value={dateFormat(selectMeain.meainInstDtm)}
            format={"yyyy-MM-dd HH:mm"}
            setDtm={(v) => {
              onChangeDateTiem(v, "meainInstDtm");
            }}
            required={true}
            validationMessage={inputValidMsg.normalText}
          ></DateTimePickerKo>
        </td>
        <th>AS만료일</th>
        <td>
          <DatePickerKo
            id="asExpiDtm"
            name="asExpiDtm"
            className="meainDatePicker"
            value={dateFormat(selectMeain.asExpiDtm)}
            format="yyyy-MM-dd"
            formatPlaceholder={{ year: "년", month: "월", day: "일" }}
            setDtm={(v) => {
              onchageDate(v, "asExpiDtm");
            }}
            required={true}
            validationMessage={inputValidMsg.normalText}
          ></DatePickerKo>
        </td>
        <th>모니터링일시</th>
        <td>
          <DateTimePickerKo
            id="mntrStrtDtm"
            name="mntrStrtDtm"
            className="meainDatePicker"
            value={dateFormat(selectMeain.mntrStrtDtm)}
            format={"yyyy-MM-dd HH:mm"}
            formatPlaceholder={{ year: "년", month: "월", day: "일", hour: "시간", minute: "분" }}
            setDtm={(v) => {
              onChangeDateTiem(v, "mntrStrtDtm");
            }}
            required={true}
            validationMessage={inputValidMsg.normalText}
          ></DateTimePickerKo>
        </td>
      </tr>
      <tr>
        <th colSpan="6">
          <div></div>
        </th>
      </tr>
      <tr>
        <th>특이사항</th>
        <td colSpan="4">
          <Input
            // className = "meainInfoDropDown"
            id="etc"
            name="etc"
            type="text"
            className={styles.searchTermTBC}
            value={selectMeain.etc ? selectMeain.etc : ""}
            onChange={onchageInput}
          />
        </td>
      </tr>
      <tr>
        <th colSpan="6">
          <div></div>
        </th>
      </tr>
    </table>
  );
}

export default Meain_info_heath;
