import React, { useState, useEffect } from "react";

import styles from "./menuApi.module.css";

import { getMessage } from "../../../com/messageUtil";

import { Button } from "@progress/kendo-react-buttons";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import kendo from "@progress/kendo-ui";
import { TreeView } from "@progress/kendo-react-treeview";

import { getMenuTree, getMenuApi, setUpdateMenuApi, setDeleteMenuApi } from "../../../service/menu/menuService";

const MenuApi = ({ conn }) => {
  // 메뉴 트리 데이터
  const [treeData, setTreeData] = useState({
    data: [],
  });
  // 테이블 데이터
  const [dataSource, setDataSource] = useState({
    data: [],
    changes: [],
    editField: [],
  });

  // 메뉴트리에서 클릭한 ID 데이터
  const [checkData, setCheckData] = useState({
    data: null,
  });

  // 메뉴트리에서 클릭한 ID 데이터
  const [selectData, setSelectData] = useState({
    data: [],
  });

  //메뉴 트리 데이터 axios
  const getTreeData = (treeData) => {
    getMenuTree(conn, treeData)
      .then((treeData) => {
        setTreeData({
          data: treeData,
        });
      })
      .catch((res) => {
        // console.error(res);
        alert(getMessage(res.errCode));
      });
  };

  // 메뉴 트리 데이터 불러오기
  useEffect(() => {
    getTreeData();
  }, []);

  // 테이블 데이터 axios
  const getDataSource = (dataSource) => {
    getMenuApi(conn, dataSource, checkData.data)
      .then((dataSource) => {
        setDataSource({
          data: dataSource,
        });
      })
      .catch((res) => {
        alert(getMessage(res.errCode));
        // console.error(res);
      });
  };

  // 테이블 저장 하기
  const saveDataSource = (dataItem) => {
    // // editFlag 삭제
    // dataItem.forEach(element => {
    //     delete element.editFlag;
    // });

    // // axios 구성
    // axios({
    //     method : "POST"
    //     , url : `${process.env.REACT_APP_REST_API_VERSION}` + "/menu/api"
    //     , withCredentials : true
    //     , data : dataItem
    //     , config: {
    //         headers: {
    //             'Content-Type': 'application/json'
    //         }
    //     }
    // })
    // .then ( res => {
    //     getDataSource();

    // })
    // .catch ( res => {
    //     kendo.alert("저장을 실패 하였습니다.");
    // });

    setUpdateMenuApi(conn, dataItem)
      .then((res) => {
        getDataSource();
      })
      .catch((res) => {
        // kendo.alert("저장을 실패 하였습니다.");
        alert(getMessage(res.errCode));
      });
  };

  /* 추가 */
  const addNew = () => {
    const newDataItem = { inEdit: true, menuId: checkData.data };
    setDataSource({
      data: [newDataItem, ...dataSource.data],
    });
  };

  // 저장
  const saveEvent = () => {
    let dataItem = [];
    dataSource.data.forEach((element) => {
      if (element.editFlag == true) {
        dataItem.push(element);
      }
    });

    saveDataSource(dataItem);
  };

  /* 취소버튼 */
  const cancelChanges = () => {
    getDataSource();
  };

  /* 삭제버튼 시작 */
  const CommandCell = (props) => {
    const { dataItem } = props;

    return (
      <td className="k-command-cell">
        <Button className="k-button" onClick={() => remove(dataItem)} icon="delete" />
      </td>
    );
  };

  const remove = (dataItem) => {
    setDeleteMenuApi(conn, dataItem)
      .then((data) => {
        kendo.alert("삭제되었습니다.");
      })
      .catch((e) => {
        // API 호출이 실패한 경우
        // kendo.alert("삭제를 실패 하였습니다.");
        // console.error(e);  // 에러표시
        alert(getMessage(e.errCode));
      });

    getDataSource();
  };

  /* 삭제버튼 끝 */

  // 메뉴트리 펼치기
  const onExpandChange = (event) => {
    event.item.expanded = !event.item.expanded;
  };

  // 메뉴 트리 클릭
  const onItemClick = (event) => {
    if (selectData.data) {
      selectData.data.selected = false;
    }
    event.item.selected = true;
    const selectedItem = event.item;

    setSelectData({
      data: selectedItem,
    });

    setCheckData({
      data: selectedItem.id,
    });

    getDataSource();
  };

  // 이벤트
  const itemChange = (event) => {
    event.dataItem.editFlag = true;
    event.dataItem[event.field] = event.value;

    setDataSource({
      data: dataSource.data,
      changes: true,
      editField: event.field,
    });
  };

  // 셀 EDIT 진입 시
  const enterEdit = (dataItem, field) => {
    const data = dataSource.data.map((item) => ({
      ...item,
      inEdit: item.codeNo == dataItem.codeNo ? field : undefined,
    }));

    setDataSource({
      data: data,
      editField: field,
    });
  };

  // Cell 렌더링
  let cellRender = (tdElement, cellProps) => {
    const dataItem = cellProps.dataItem;
    const cellField = cellProps.field;
    const inEditField = dataItem["inEdit"];
    const additionalProps =
      cellField && cellField === inEditField
        ? {
            ref: (td) => {
              const input = td && td.querySelector("input");
              const activeElement = document.activeElement;
              if (!input || !activeElement || input === activeElement || !activeElement.contains(input)) {
                return;
              }

              if (input.type === "checkbox") {
                input.focus();
              } else {
                input.select();
              }
            },
          }
        : {
            onClick: () => {
              // Edit 진입
              enterEdit(dataItem, cellField);
            },
          };

    return React.cloneElement(tdElement, { ...tdElement.props, ...additionalProps }, tdElement.props.children);
  };

  return (
    <div>
      <div className="uk-grid">
        <div className="uk-width-medium-1-5">
          <div className="md-card">
            <div className="md-card-content">
              <div className="md-card-toolbar">
                <h3 className="md-card-toolbar-heading-text">메뉴</h3>
                <TreeView data={treeData.data} expandIcons={true} onExpandChange={onExpandChange} onItemClick={onItemClick} />
              </div>
            </div>
          </div>
        </div>
        <div className="uk-width-medium-4-5">
          <div className="md-card">
            <div className="md-card-content">
              <div className="md-card-toolbar">
                <h3 className="md-card-toolbar-heading-text">
                  {/* 메인모니터링  */}
                  <Button id="addBtn" className="kendoBtn buttonClass" onClick={addNew} icon="add">
                    추가
                  </Button>
                  <Button id="saveBtn" className="kendoBtn buttonClass" onClick={saveEvent} icon="save">
                    저장
                  </Button>
                  <Button id="cancelBtn" className="kendoBtn buttonClass" onClick={cancelChanges} icon="cancel">
                    취소
                  </Button>
                </h3>
              </div>
            </div>
          </div>
          <Grid className={[styles.menuApiGrid, styles.menuApiGridSize]} data={dataSource} onItemChange={itemChange} cellRender={cellRender} editField="inEdit">
            <Column field="menuId" editable={false} title="메뉴ID" width="350px" />
            <Column field="apiUrl" title="API URL" width="1000px" />
            <Column title="삭제" cell={CommandCell} width="80px" />
          </Grid>
        </div>
      </div>
    </div>
  );
};
export default MenuApi;
