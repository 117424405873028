import React, { useState, useEffect } from "react";
//Kendo Tab TabStrip , TabStripTab
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
// 탭
import MeainTab from "./meainTab";
//CSS
import "./meain.css";
// 서비스사용
import { getRtu } from "../../../service/mngt/rtuEnte/rtuService";
import { getMeainModlMakr } from "../../../service/meain/meain/meainService";
import { getUserMngRgnList, isManager } from "../../../service/authService";
import CustomFilter from "../../com/gridFilter/custom_filter";
import { useLocation } from 'react-router-dom'

// 문제 사항
// 현재 DateTimePicker 와 DropDownList 아이콘이 a 태그가 아닌 span 태그로 붙여서 화면에 이벤트 아이콘을 보여주지 않아서 좀 더 확인이 필요함

const Meain = ({ conn, codeList, rgnList, dongList }) => {

  const location = useLocation();
  
  const monitorASInfo = {
    installerTerm : '' , 
    sysMode : '' , 
    ensoTypeCode : 15001,
    monitorYn: "N",
  }
  if( location.meainTransData ){
    const meainTransData = location.meainTransData;
    monitorASInfo.installerTerm = meainTransData.installerTerm;
    monitorASInfo.sysMode = meainTransData.sysMode;
    monitorASInfo.ensoTypeCode = meainTransData.ensoTypeCode;
    monitorASInfo.monitorYn = meainTransData.monitorYn;
  }

  // 담당자 일 떄 시군구 리스트 설정
  if (isManager() === true) {
    const userMngRgnList = getUserMngRgnList();
    const rgnListfilter = [];
    rgnList.forEach((element) => {
      if (userMngRgnList.length != 0) {
        userMngRgnList.forEach((rgnCode) => {
          if (rgnCode === element.rgnCode) {
            rgnListfilter.push(element);
          }
        });
      } else {
        rgnListfilter.push(element);
      }
    });
    rgnList = rgnListfilter;
  }

  // 시/군/구 그리드 필터 드롭다운
  const rgnFilterDropDown = CustomFilter(rgnList, { rgnCode: "", rgnTermSimple: "시/군/구" }, "rgnCode", "rgnTermSimple");

  // RTU 업체 리스트
  const [meainDs, setMeainDs] = useState({
    rtuEnteList: [],
    meainModleMakrList: [],
  });

  // 탭 상태
  const [tabStat, setTabStat] = useState({});

  // 탭 선택 시 발생하는 이벤트
  const selectTabItem = (e) => {
    let ensoTypeCode = 15001;
    if (e.selected === 0) {
      ensoTypeCode = 15001;
    } else if (e.selected === 1) {
      ensoTypeCode = 15002;
    } else if (e.selected === 2) {
      ensoTypeCode = 1500229002;
    } else if (e.selected === 3) {
      ensoTypeCode = 15003;
    } else if (e.selected === 4) {
      ensoTypeCode = 15004;
    } else if (e.selected === 5) {
      ensoTypeCode = 15006;
    } else if (e.selected === 6) {
      ensoTypeCode = 15007;
    }

    // 탭 선택에 대한 탭 값 설정
    setTabStat({
      state: e.selected,
      ensoTypeCode: ensoTypeCode,
    });
  };

  // 설비 모델 & 제조사 목록
  const getMeainModlMakrList = () => {
    getMeainModlMakr(conn, getMeainModleMakrResult, getRejection);
  };

  const getMeainModleMakrResult = (result) => {
    setMeainDs((meainDs) => {
      const item = { ...meainDs };
      item.meainModleMakrList = result;
      return item;
    });
  };

  // RTU 업체 목록 서비스
  const getRtuEnteList = () => {
    getRtu(conn)
      .then((response) => {
        response.forEach((item) => {
          item.rtuEnteTermAndCode = item.rtuEnteTerm + " ( 코드 : " + item.rtuEnteCode + " )";
        });
        setMeainDs((meainDs) => {
          const item = { ...meainDs };
          item.rtuEnteList = response;
          return item;
        });
      })
      .catch((error) => {
        getRejection(error);
      });
  };

  // 리젝션 함수
  const getRejection = (error) => {};

  useEffect(() => {
    if(monitorASInfo.monitorYn === 'Y'){
      let tabNumber = 0;
      let ensoTypeCode = monitorASInfo.ensoTypeCode;
      const sysMode = monitorASInfo.sysMode;
      if( ensoTypeCode === 15001 ){ tabNumber = 0 }
      else if( ensoTypeCode === 15002 ){
        tabNumber = 1
        if( sysMode === 29002 ){ tabNumber = 2; ensoTypeCode = 15002; }
      }
      else if( ensoTypeCode === 15003 ){ tabNumber = 3 }
      else if( ensoTypeCode === 15004 ){ tabNumber = 4 }
      else if( ensoTypeCode === 15006 ){ tabNumber = 5 }
      else if( ensoTypeCode === 15007 ){ tabNumber = 6 }
      setTabStat({
        state : tabNumber
        , ensoTypeCode : monitorASInfo.ensoTypeCode
      });
    }else{
      setTabStat({
        state : 0
        , ensoTypeCode : 15001
      });
    }
    getRtuEnteList();
    getMeainModlMakrList();
  }, []);

  return (
    <div>
      <TabStrip selected={tabStat.state} onSelect={selectTabItem}>
        <TabStripTab title="태양광" key={"tabSola"}>
          <dd style={{ padding: "20px" }}>
            <MeainTab
              conn={conn}
              codeList={codeList}
              rgnList={rgnList}
              dongList={dongList}
              meainDs={meainDs}
              ensoTypeCode={15001}
              rgnFilterDropDown={rgnFilterDropDown}
              monitorASInfo={monitorASInfo}
            />
          </dd>
        </TabStripTab>
        <TabStripTab title="태양열" key={"tabHeat"}>
          <dd style={{ padding: "20px" }}>
            <MeainTab
              conn={conn}
              codeList={codeList}
              rgnList={rgnList}
              dongList={dongList}
              meainDs={meainDs}
              ensoTypeCode={15002}
              sysMode={29001}
              rgnFilterDropDown={rgnFilterDropDown}
              monitorASInfo={monitorASInfo}
            />
          </dd>
        </TabStripTab>
        <TabStripTab title="태양열(자연)" key={"tabHeatN"}>
          <dd style={{ padding: "20px" }}>
            <MeainTab
              conn={conn}
              codeList={codeList}
              rgnList={rgnList}
              dongList={dongList}
              meainDs={meainDs}
              ensoTypeCode={15002}
              sysMode={29002}
              rgnFilterDropDown={rgnFilterDropDown}
              monitorASInfo={monitorASInfo}
            />
          </dd>
        </TabStripTab>
        <TabStripTab title="지열" key={"tabHeath"}>
          <dd style={{ padding: "20px" }}>
            <MeainTab
              conn={conn}
              codeList={codeList}
              rgnList={rgnList}
              dongList={dongList}
              meainDs={meainDs}
              ensoTypeCode={15003}
              rgnFilterDropDown={rgnFilterDropDown}
              monitorASInfo={monitorASInfo}
            />
          </dd>
        </TabStripTab>
        <TabStripTab title="풍력" key={"tabVelo"}>
          <dd style={{ padding: "20px" }}>
            <MeainTab
              conn={conn}
              codeList={codeList}
              rgnList={rgnList}
              dongList={dongList}
              meainDs={meainDs}
              ensoTypeCode={15004}
              rgnFilterDropDown={rgnFilterDropDown}
              monitorASInfo={monitorASInfo}
            />
          </dd>
        </TabStripTab>
        <TabStripTab title="연료전지" key={"tabFuelCell"}>
          <dd style={{ padding: "20px" }}>
            <MeainTab
              conn={conn}
              codeList={codeList}
              rgnList={rgnList}
              dongList={dongList}
              meainDs={meainDs}
              ensoTypeCode={15006}
              rgnFilterDropDown={rgnFilterDropDown}
              monitorASInfo={monitorASInfo}
            />
          </dd>
        </TabStripTab>
        <TabStripTab title="ESS" key={"tabEss"}>
          <dd style={{ padding: "20px" }}>
            <MeainTab
              conn={conn}
              codeList={codeList}
              rgnList={rgnList}
              dongList={dongList}
              meainDs={meainDs}
              ensoTypeCode={15007}
              rgnFilterDropDown={rgnFilterDropDown}
              monitorASInfo={monitorASInfo}
            />
          </dd>
        </TabStripTab>
      </TabStrip>
    </div>
  );
};

export default Meain;
