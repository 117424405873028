import kendo from "@progress/kendo-ui";
import { roundNum } from "../../com/dataUtil";

export const getOperEnergyList = async (conn, selectedArea) => {
  const response = await conn.get("monitor/area/list", {
    params: {
      areaType: selectedArea.areaType,
      rgnCode: selectedArea.rgnCode,
    },
  });
  // const result = response;
  const totalEnergy = {
    dayPower: 0,
    dayPowerCo2: 0,
    dayUse: 0,
    dayUseCo2: 0,
    heatDayUse: 0,
    heathDayUse: 0,
    dayCo2: 0,
  };
  if (response)
    response.map((item) => {
      totalEnergy.dayPower += item.solaDayPower + item.veloDayPower + item.fuelcellDayPower;
      totalEnergy.dayUse += item.heatDayUse + item.heathDayUse;
      totalEnergy.heatDayUse += item.heatDayUse;
      totalEnergy.heathDayUse += item.heathDayUse;
      totalEnergy.powerEfic += item.solaPowerEfic + item.heatPowerEfic + item.heatPowerEfic + item.heatPowerEfic + item.heatPowerEfic + item.heatPowerEfic;

      return item;
    });
  totalEnergy.dayPowerCo2 = totalEnergy.dayPower * 0.46553;
  totalEnergy.dayUseCo2 = totalEnergy.dayUse * 0.4653;

  totalEnergy.dayCo2 = totalEnergy.dayPowerCo2 + totalEnergy.dayUseCo2;

  let chartCount = [];
  let chartInstCapa = [];
  let chartPowerEfic = [];
  let chartCalEfic = [];
  response.forEach((element) => {
    let cnt = element.solaCnt + element.heatCnt + element.heathCnt + element.veloCnt + element.fuelcellCnt + element.essCnt;
    let capa =
      roundNum(element.solaCapa, 1) +
      roundNum(element.heatCapa, 1) +
      roundNum(element.heathCapa, 1) +
      roundNum(element.veloCapa, 1) +
      roundNum(element.fuelcellCapa, 1) +
      roundNum(element.essCapa, 1);

    let powerEficCnt = 0;
    if (element.solaPowerEfic > 0) {
      powerEficCnt = +1;
    }
    if (element.veloPowerEfic > 0) {
      powerEficCnt = +1;
    }
    if (element.fuelcellPowerEfic > 0) {
      powerEficCnt = +1;
    }

    let calEficCnt = 0;
    if (element.heatPowerEfic > 0) {
      calEficCnt = +1;
    }
    if (element.heathPowerEfic > 0) {
      calEficCnt = +1;
    }

    let powerEfic = powerEficCnt !== 0 ? (element.solaPowerEfic + element.veloPowerEfic + element.fuelcellPowerEfic) / powerEficCnt : 0;
    powerEficCnt = 0;
    let calEfic = calEficCnt !== 0 ? (element.heatPowerEfic + element.heathPowerEfic) / calEficCnt : 0;
    calEficCnt = 0;

    chartCount.push({ name: element.rgnTerm, y: cnt });
    chartInstCapa.push({ name: element.rgnTerm, y: capa });
    chartPowerEfic.push({ name: element.rgnTerm, y: powerEfic !== "NaN" ? roundNum(powerEfic, 3) : 0 });
    chartCalEfic.push({ name: element.rgnTerm, y: calEfic !== "NaN" ? roundNum(calEfic, 3) : 0 });
  });

  // 지역운영차트의 개소수에 따른 우선순위 작업
  chartCount.sort((after, before) => {
    if (before.y > after.y) {
      return 1;
    }
    if (before.y < after.y) {
      return -1;
    }
    return 0;
  });

  return {
    areaEnergyList: response,
    areaEnergyTotal: totalEnergy,
    chartCount: chartCount,
    chartInstCapa: chartInstCapa,
    chartPowerEfic: chartPowerEfic,
    chartCalEfic: chartCalEfic,
  };
};

export const getOperMeainStatCntList = async (conn, selectedArea) => {
  const response = await conn.get("monitor/area/meainStatus", {
    params: {
      areaType: selectedArea.areaType,
      rgnCode: selectedArea.rgnCode,
    },
  });
  // const result = response;
  const totalCnt = {
    ensoTypeCode: 15000,
    readyCnt: 0,
    normalCnt: 0,
    unOperCnt: 0,
    waringCnt: 0,
    errorCnt: 0,
    totalCnt: 0,
  };

  if (response)
    response.map((item) => {
      totalCnt.readyCnt += item.readyCnt;
      totalCnt.normalCnt += item.normalCnt;
      totalCnt.unOperCnt += item.unOperCnt;
      totalCnt.waringCnt += item.waringCnt;
      totalCnt.errorCnt += item.errorCnt;

      return item;
    });

  totalCnt.totalCnt += totalCnt.readyCnt + totalCnt.normalCnt + totalCnt.unOperCnt + totalCnt.waringCnt + totalCnt.errorCnt;
  response.splice(0, 0, totalCnt);

  return response;
};

export const getOperNewMeainList = async (conn, selectedArea) => {
  const response = await conn.get("monitor/oper/new", {
    params: {
      areaType: selectedArea.areaType,
      rgnCode: selectedArea.rgnCode,
    },
  });

  return response;
};

export const getOperWarnMeainList = async (conn, selectedArea) => {
  const response = await conn.get("monitor/oper/warn", {
    params: {
      areaType: selectedArea.areaType,
      rgnCode: selectedArea.rgnCode,
    },
  });

  return response;
};

export const getOperPowerWeek = async (conn) => {
  const response = await conn.get("monitor/oper/power");

  let sumCapa = [];
  let sumCnt = [];
  let sumDayPower = [];
  response.forEach((element) => {
    const capa = roundNum(element.sumCapa, 2);
    element.gathDtm = kendo.toString(new Date(element.gathDtm), "MM.dd");
    sumCapa.push({ name: element.gathDtm, y: capa });
    sumCnt.push({ name: element.gathDtm, y: element.sumCnt });
    sumDayPower.push({ name: element.gathDtm, y: element.sumDayPower });
  });

  return { sumCapa: sumCapa, sumCnt: sumCnt, sumDayPower: sumDayPower };
};
