import { Button } from "@progress/kendo-react-buttons";

const QnaSearch = ({
  styles,
  dropSearchKind,
  search,
  appChange,
  searchCondition,
  appKeyPress,
  searchHandler,
  formChange,
  DropDownList,
}) => {
  return (
    <>
      <span className={styles.searchSubTitleQna}>
        검색옵션&nbsp;&nbsp;
        <DropDownList
          data={dropSearchKind}
          defaultValue="제목"
          className="CustomFilterDropDown1"
          onChange={searchCondition}
        />
        {!formChange.searchBool ? (
          <input
            type="text"
            id="qestnSj"
            name="qestnSj"
            value={search.qestnSj}
            className={styles.searchTerm}
            placeholder="제목을 입력해주세요"
            onChange={appChange}
            onKeyPress={appKeyPress}
          ></input>
        ) : (
          <input
            type="text"
            id="companyTerm"
            name="companyTerm"
            value={search.companyTerm}
            className={styles.searchTerm}
            placeholder="업체명을 입력해주세요"
            onChange={appChange}
            onKeyPress={appKeyPress}
          ></input>
        )}
        <span>
          <Button
            className={styles.qnaBtn}
            onClick={searchHandler}
          >
            <img src='/images/icon_d.png' alt='' />
          </Button>
        </span>
      </span>
    </>
  );
};
export default QnaSearch;
