import { numFormat } from '../../com/dataUtil';
/**
 * 설치현황 Depth3 운영현황 설비 카운트
 * 
 * @param 검색 조건
 * @return 설비 운영현황에 따른 설비 갯수
 */
 export const getInstallMeainStatData = async(conn, callback, reject , param) => {
  await conn.get('location/meainstat', {
    params: {
      userTerm : param.userTerm
      , bizSct : param.bizSct
      , bldSrvCodeNo : param.bldSrvCodeNo
      , bizYy : param.bizYy
      , rgnCode : param.rgnCode
      , fixbylawBundCode : param.fixbylawBundCode
    }
  }).then(response => {
    const result = response;
    // null로 들어올 경우 0으로 변경
    if( result.errorCnt === null ){ result.errorCnt = 0; }
    if( result.normalCnt === null ){ result.normalCnt = 0; }
    if( result.readyCnt === null ){ result.readyCnt = 0; }
    if( result.unOperCnt === null ){ result.unOperCnt = 0; }
    if( result.warnCnt === null ){ result.warnCnt = 0; }
    // 토탈 갯수 구하기
    result.totalCnt = result.errorCnt + result.normalCnt + result.readyCnt + result.unOperCnt + result.warnCnt;
    // 1000 넘으면 콤마 표시
    result.errorCnt = numFormat(result.errorCnt);
    result.readyCnt = numFormat(result.readyCnt);
    result.unOperCnt = numFormat(result.unOperCnt);
    result.warnCnt = numFormat(result.warnCnt);
    result.normalCnt = numFormat(result.normalCnt);
    result.totalCnt = numFormat(result.totalCnt);
    callback(result);
  }).catch(error => {
    reject(error);
  });
  
}