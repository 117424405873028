import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const TimePowerChart = ({ dataSource, ensoTypeCodeNo }) => {
  // 에너지원별 생산량 , 발전량 분리
  const isUse = ensoTypeCodeNo === 15002 || ensoTypeCodeNo === 15003 ? true : false;

  // 차트 옵션
  const chartConfig = {
    chart: { zoomType: "x" },
    title: { text: null },
    xAxis: [{ type: "category", crosshair: true }],
    noData: {
      style: { color: "#FFFFFF", fontSize: "14px" },
    },
    yAxis: [
      {
        // 발전량 축
        labels: {
          format: "{value:,.0f}",
          style: { color: Highcharts.getOptions().colors[1] },
        },
        title: {
          text: isUse === true ? "생산량(kWh)" : "발전량(kWh)",
          style: { color: Highcharts.getOptions().colors[1] },
          rotation: 0,
          y: -10,
          offset: 0,
          align: "high",
        },
      },
    ],
    credits: { enabled: false },
    tooltip: {
      shared: true,
    },
    legend: {
      layout: "vertical",
      align: "center",
      verticalAlign: "top",
      floating: false,
      backgroundColor: Highcharts.theme && Highcharts.theme.legendBackgroundColor,
    },
    series: [
      {
        name: isUse === true ? "생산량(kWh)" : "발전량(kWh)",
        type: "column",
        tooltip: { valueSuffix: " kWh" },
        data: dataSource,
      },
    ],
  };

  return (
    <div>
      <HighchartsReact Highcharts={Highcharts} options={chartConfig} containerProps={{ style: { height: "50vh" } }}></HighchartsReact>
    </div>
  );
};

export default TimePowerChart;
