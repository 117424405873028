import { useState, useCallback } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";

import { setLoraData } from "../../../service/mngt/loraService";

import { getMessage } from "../../../com/messageUtil";

import { useLocation } from "react-router";

import { getUserTypeCode } from "../../../service/authService";

// kendo
import kendo from "@progress/kendo-ui";
import { Window } from "@progress/kendo-react-dialogs";
import { Upload } from "@progress/kendo-react-upload";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Form, FormElement } from "@progress/kendo-react-form";
import { isRtuUser } from "../../../service/authService";
import { Input } from "@progress/kendo-react-inputs";
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";

const RegiLoraRight = ({ conn, rtuList, inputs, setInputs, getLoraList, qs, setPageState, selectValue, setSelectValue, styles }) => {
  const loginUserTypeCode = getUserTypeCode();
  // let location = useLocation();
  // let {id} = qs.parse(location.search);

  // 파일 업로드 윈도우 창 제어
  let [windowVisible, setWindowVisible] = useState(false);

  // 파일 업로드 결과 윈도우 창 제어
  let [windowSaveVisible, setWindowSaveVisible] = useState(false);

  // 파일 업로드
  let [uploadState, setUploadState] = useState({
    files: [],
    events: [],
    filePreviews: {},
    afterStateChange: [],
  });

  // 파일 업로드 후 문제 없다면 리스트 변수
  let [uploadDataList, setUploadDataList] = useState([]);

  // 파일 업로드 결과 리스트 변수
  let [uploadResult, setUploadResult] = useState({});

  // 엑셀 파일 이름
  let [fileName, setFileNameExcel] = useState("");

  // // 로라 정보 값
  // const [inputs, setInputs] = useState({
  //   rtuEnteCode: '',
  //   loraDevid: '',
  //   appEUI: '',
  //   uKey: ''
  // })

  function setFileName() {
    let template = "LoRa등록목록_" + kendo.toString(new Date(), "yyyyMMdd") + ".xlsx";
    return template;
  }

  // 데이터 저장 하기
  function saveDataSource(dataItem) {
    setLoraData(conn, dataItem)
      .then((res) => {
        let respCode = res.obj[0].loraStatus;
        if (respCode == 201) kendo.alert("정상적으로 등록되었습니다!");
        else if (respCode == 404) kendo.alert("미개통 또는 조인을 미실시한 LoRa ID입니다!");
        else if (respCode == 409) kendo.alert("이미 등록된 ID입니다!");
        else kendo.alert("code : " + respCode + " 해당 통신사에 문의바랍니다.");

        //  값 초기화
        inputs.rtuEnteCode = "";
        inputs.loraDevid = "";
        inputs.appEUI = "";
        inputs.uKey = "";
        getLoraList();
        //       getDataSource();

        // window.location.replace("/");
      })
      .catch((res) => {
        kendo.alert("저장을 실패 하였습니다.");
        alert(getMessage(res.errCode));
      });
  }

  // 등록
  const saveChanges = (e) => {
    if (inputs.rtuEnteCode != null && inputs.loraDevid != null && inputs.appEUI != null && inputs.uKey != null) {
      if (!inputs.loraDevid) {
        kendo.alert("LoRaID를 입력이 안되었습니다. LoRaID를 입력 해주세요.");
        return;
      }
      if (!inputs.rtuEnteCode) {
        kendo.alert("RTU업체가 선택이 안되었습니다. RTU업체를 선택 해주세요.");
        return;
      }
      if (!inputs.appEUI) {
        kendo.alert("appEUI를 입력이 안되었습니다. appEUI를 입력 해주세요.");
        return;
      }
      if (!inputs.uKey) {
        kendo.alert("uKey를 입력이 안되었습니다. uKey를 입력 해주세요.");
        return;
      }

      if (inputs.appEUI.length < 8) {
        kendo.alert("appEUI 값은 8자리 이상 입력 해주세요.");
        return;
      } else {
        saveDataSource([inputs]);
        setPageState((prevState) => ({
          ...prevState,
          skip: 0,
          take: 10,
          filter: { loraDevid: "", loraStatus: "", rtuEnteCode: "" },
          total: 0,
        }));
      }
      setSelectValue({
        ...selectValue,
        rtuEnte: {
          rtuEnteCode: "",
          rtuEnteTerm: "전체",
        },
      });
    } else {
      kendo.alert("LoRa 정보를 입력해주세요!");
    }
  };

  // 엑셀 다운로드
  let _export;
  const downloadExcel = () => {
    setFileNameExcel(setFileName());
    _export.save();
  };

  // 엑셀 샘플 다운로드
  const downloadSample = () => {
    const link = document.createElement("a");
    link.href = "/template/meain/sample_lora.xlsx";
    document.body.appendChild(link);
    link.click();
  };

  // 액셀 업로드 윈도우 창 제어
  const uploadExcel = () => {
    initialExcelFile();
    setWindowVisible(!windowVisible);
  };

  // 액셀 업로드 결과 윈도우 창 제어
  const uploadSaveExcel = (event) => {
    setWindowSaveVisible(!windowSaveVisible);

    let result = [];
    const obj = event.obj;
    obj.forEach((element) => {
      result.push({ loraDevid: element.loraDevid, loraStatus: element.loraStatus });
    });

    document.getElementById("resultMessage").innerText = event.resultMessage;

    setUploadResult({
      data: result,
    });
  };

  const uploadClose = () => {
    setWindowSaveVisible(!windowSaveVisible);
  };

  // 엑셀 업로드 헤더 설정
  const handleUploadHeaders = useCallback((e) => (e.headers["X-AUTH-TOKEN"] = localStorage.getItem("atk")));

  // 엑셀 업로드 관련 함수 모음
  // files: [],
  // events: [],
  // filePreviews: {}
  const onAdd = (event) => {
    // 이걸 안해주면 파일 업로드 결과 목록을 안보여준다.
    const afterStateChange = () => {
      event.affectedFiles
        .filter((file) => !file.validationErrors)
        .forEach((file) => {
          const reader = new FileReader();

          reader.onloadend = (ev) => {
            setUploadState({
              files: uploadDataList.files,
              events: uploadDataList.events,
              filePreviews: {
                [file.uid]: ev.target.result,
              },
            });
          };

          reader.readAsDataURL(file.getRawFile());
        });
    };

    // 이걸 안해주면 파일 업로드 결과 목록을 안보여준다.
    setUploadState({
      // files: uploadDataList.files,
      files: event.newState,
      events: [uploadDataList.events],
      filePreviews: uploadDataList.filePreviews,
      afterStateChange: afterStateChange,
    });
  };

  const onRemove = (event) => {};

  const onProgress = (event) => {};

  const onStatusChange = (event) => {
    // 이걸 안해주면 파일 업로드 로딩이 안끝난다.
    setUploadState({
      files: event.newState,
    });

    setUploadDataList(event.response.response);
  };

  // 업로드 Save
  const uploadSave = () => {
    setLoraData(conn, uploadDataList.data)
      .then((res) => {
        uploadSaveExcel(res);
        //         getDataSource();
        uploadExcel();
      })
      .catch((res) => {
        // kendo.alert("저장을 실패 하였습니다.");
        // alert(getMessage(res.errCode));
      });
  };

  // 엑셀 업로드 취소 , 닫기 누를 시 초기화
  const initialExcelFile = () => {
    setUploadState({
      files: [],
    });
    setUploadDataList([]);
  };

  const onChange = (e) => {
    //input에 name을 가진 요소의 value에 이벤트를 걸었다
    const { name, value } = e.target;

    // 변수를 만들어 이벤트가 발생했을때의 value를 넣어줬다
    const nextInputs = {
      //스프레드 문법으로 기존의 객체를 복사한다.
      ...inputs,
      [name]: value,
    };

    //만든 변수를 seInput으로 변경해준다.
    setInputs(nextInputs);
  };

  const handleChange = (event) => {
    setInputs({
      ...inputs,
      rtuEnteCode: event.target.value.rtuEnteCode,
    });

    if (loginUserTypeCode != 2002) {
      setSelectValue({
        ...selectValue,
        rtuEnte: {
          rtuEnteCode: event.target.value.rtuEnteCode,
          rtuEnteTerm: event.target.value.rtuEnteTerm,
        },
      });
    }
  };

  const cellFormat = (data) => {
    const field = data.field;
    let value = "-";
    if (field === "loraStatus") {
      if (data.dataItem.loraStatus == "201") {
        value = "등록완료";
      } else if (data.dataItem.loraStatus == "400") {
        value = "실패";
      } else if (data.dataItem.loraStatus == "404") {
        value = "미개통 또는 조인 미실시";
      } else if (data.dataItem.loraStatus == "409") {
        value = "기등록";
      }
    }
    return <td style={{ textAlign: "center" }}>{value}</td>;
  };

  return (
    <div className="uk-width-medium-1-2">
      {/* <div className="loraRight"> */}
      <h3 className="md-card-toolbar-heading-text">LoRa 정보입력</h3>
      <Form
        render={(formRenderProps) => (
          <FormElement style={{ maxWidth: 650 }}>
            <label className="text-label"> &middot; RTU 업체 </label> <br />
            <DropDownList
              data={rtuList}
              defaultItem={{ rtuEnteTerm: "전체", rtuEnteCode: "" }}
              textField="rtuEnteTerm"
              dataItemKey="rtuEnteCode"
              onChange={handleChange}
              disabled={isRtuUser() === true ? true : false}
              className="regiLoraColumnGrid"
              value={
                selectValue.rtuEnte.rtuEnteCode
                  ? { rtuEnteCode: selectValue.rtuEnte.rtuEnteCode, rtuEnteTerm: selectValue.rtuEnte.rtuEnteTerm }
                  : { rtuEnteTerm: "전체", rtuEnteCode: "" }
              }
            />
            <br />
            <br />
            <label className="text-label"> &middot; LoRa ID </label> <br />
            <Input
              id="loraDevid"
              name="loraDevid"
              onChange={onChange}
              className="regiLoraColumnGrid"
              value={inputs.loraDevid ? inputs.loraDevid : ""}
              //  value={inputs.data}
            />{" "}
            <br />
            <br />
            <label className="text-label"> &middot; appEUI </label>
            <br />
            <Input id="appEUI" name="appEUI" onChange={onChange} className="regiLoraColumnGrid" value={inputs.appEUI ? inputs.appEUI : ""} /> <br />
            <br />
            <label className="text-label"> &middot; uKey </label> <br />
            <Input id="uKey" name="uKey" onChange={onChange} className="regiLoraColumnGrid" value={inputs.uKey ? inputs.uKey : ""} /> <br />
            <br />
            <br />
            <Button title="excelSave" className="k-button regiLoraButton" onClick={saveChanges} icon="check">
              등록
            </Button>
            <Button title="excelUpload" className="k-button regiLoraButton" onClick={uploadExcel} icon="upload">
              엑셀파일 가져오기
            </Button>
            <Button title="sampleDownload" className="k-button regiLoraButton" icon="download" onClick={downloadSample}>
              엑셀 샘플 다운로드
            </Button>
          </FormElement>
        )}
      />
      <br />
      <br />
      <div>※ appEUI와 uKey는 저장되지 않습니다.</div>
      <div>※ 정상코드 : 409, 201 / 에러코드 : 404, 400, 기타</div>

      {windowVisible && (
        <Window title={"엑셀 업로드"} onClose={uploadExcel} initialHeight={350} initialWidth={600} modal={true}>
          <div>
            윈도우 창
            <LocalizationProvider language="ko-KR">
              <IntlProvider locale="ko">
                <Upload
                  batch={false}
                  multiple={false}
                  withCredentials={true}
                  restrictions={{
                    allowedExtensions: [".xlsx"],
                  }}
                  onBeforeUpload={handleUploadHeaders}
                  onAdd={onAdd}
                  onRemove={onRemove}
                  onProgress={onProgress}
                  onStatusChange={onStatusChange}
                  files={uploadState.files}
                  saveUrl={`${process.env.REACT_APP_REST_API_VERSION}` + "/iot/sk/xlsx"}
                  removeUrl={`${process.env.REACT_APP_REST_API_VERSION}` + "/iot/sk/removeFile"}
                ></Upload>
              </IntlProvider>
            </LocalizationProvider>
            <div style={{ float: "right" }}>
              <Button icon="save" style={{ margin: "5px" }} onClick={uploadSave}>
                저장
              </Button>
              <Button icon="close" style={{ margin: "5px" }} onClick={uploadExcel}>
                취소
              </Button>
            </div>
          </div>
        </Window>
      )}

      {windowSaveVisible && (
        <Window title={"엑셀 업로드 결과"} onClose={uploadClose} initialHeight={350} initialWidth={600} modal={true}>
          <div>
            <div id="resultMessage" />
            <Grid data={uploadResult.data}>
              <Column field="loraDevid" title="LoRa ID" width="300px" />
              <Column field="loraStatus" title="LoRa 상태" width="250px" cell={cellFormat} />
            </Grid>
          </div>
        </Window>
      )}
      {/* </div> */}
    </div>
  );
};

export default RegiLoraRight;
