import React, { useState, useEffect, useCallback } from "react";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import kendo from "@progress/kendo-ui";

import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";
import { Input } from "@progress/kendo-react-inputs";
import styles from "./consm.module.css";

import { CustomCalendar } from "./customCalender";
import ConsmList from "./consmList";
import ReportConsmPrintModal from "./ReportConsmPrintModal";
import { getPowerCompare, getEnergyMonth, getEnergyCount, getEnergyWarn } from "../../../service/report/reportConsmService";

function ConsmSearch({
  conn,
  rgnList,
  dongList,
  search,
  setSearch,
  selectMeain,
  setSelectMeain,
  energyData,
  setEnergyData,
  energyCount,
  setEnergyCount,
  chartMonthData,
  chartWarn,
  setChartWarn,
  setChartMonthData,
  chartYearData,
  setChartYearData,
}) {
  const [locale, setLocale] = useState("ko");
  // 윈도우 창 Visible
  let [userInfoVisible, setUserInfoVisible] = useState(false);

  const [modalItem, setModalItem] = useState({
    visible: false,
  });

  let today = new Date();
  let yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);

  // 그리드에서 유저 선택 시 이벤트
  const selectCalendar = (e) => {
    setSearch({
      ...search,
      dtm: e.target.value,
    });

    getEnergyList({
      ...search,
      dtm: e.target.value,
    });
    getEnergyCountList({
      ...search,
      dtm: e.target.value,
    });
    getGridList({
      ...search,
      dtm: e.target.value,
    });
    getEnergyWarnList({
      ...search,
      dtm: e.target.value,
    });
  };

  const print = () => {
    // window.open('','보고서 프린트', width=430, height=500, location=no, status=no, scrollbars=yes');
    // window.print();
    setModalItem({ ...modalItem, visible: true });
  };

  // 수용가 검색 버튼 클릭 이벤트
  const searchUserId = () => {
    setUserInfoVisible(!userInfoVisible);
  };

  // 그리드에서 유저 선택 시 이벤트
  const selectUserId = (e) => {
    setSearch({
      ...search,
      consmId: e.dataItem.userId,
      userTerm: e.dataItem.userTerm,
    });
    setUserInfoVisible(false);
    getEnergyList({
      ...search,
      consmId: e.dataItem.userId,
      userTerm: e.dataItem.userTerm,
    });
    getEnergyCountList({
      ...search,
      consmId: e.dataItem.userId,
    });
    getGridList({
      ...search,
      consmId: e.dataItem.userId,
      userTerm: e.dataItem.userTerm,
    });
    getEnergyWarnList({
      ...search,
      consmId: e.dataItem.userId,
    });
  };

  const getEnergyList = (search) => {
    getEnergyMonth(conn, getEnergyCallback, getRejection, search);
  };

  // 에너지원 callback
  const getEnergyCallback = (dataList) => {
    setEnergyData(dataList);
  };

  const getEnergyCountList = (search) => {
    getEnergyCount(conn, getEnergyCountCallback, getRejection, search);
  };

  // 에너지원 callback
  const getEnergyCountCallback = (powerCount) => {
    setEnergyCount(powerCount);
  };

  const getEnergyWarnList = (search) => {
    getEnergyWarn(conn, getEnergyWarnCallback, getRejection, search);
  };

  // 에너지원 callback
  const getEnergyWarnCallback = (powerCount) => {
    setChartWarn(powerCount);
  };

  const getGridList = (search) => {
    getPowerCompare(conn, getCallback, getRejection, search);
  };

  // 에너지원 callback
  const getCallback = (gridList, chartMonth, chartYear) => {
    setSelectMeain(gridList);
    setChartMonthData(chartMonth);
    setChartYearData(chartYear);
  };

  // rejection
  const getRejection = (name, error) => {
    if (error.errCode === "F011") {
      kendo.alert("필요한 검색 값이 없습니다. '일간', '월간', '연간' 버튼을 선택 또는 날짜를 선택하였는지 확인해주세요.");
      return;
    }
  };

  return (
    <>
      <div className="uk-grid">
        <div className="uk-width-medium-1-1">
          <div className="reportDayBackground"> 수용가별 운영 보고서 </div>
          <div className="reportDayBackground"> Installer Operation Report </div>
          <div className="reportDayBackground">
            보고일 : &nbsp;
            <LocalizationProvider language={locale}>
              <IntlProvider locale={locale}>
                <DatePicker
                  defaultValue={today}
                  // onChange={(e)=>setSearch({...search, dtm: kendo.toString(e.target.value , 'yyyy-MM')}, [search])}
                  onChange={selectCalendar}
                  format={"yyyy-MM"}
                  calendar={(props) => <CustomCalendar {...props} topView={"year"} bottomView={"year"} max={yesterday} />}
                />
              </IntlProvider>
            </LocalizationProvider>
            {search.consmId && <img onClick={print} src="/images/printer.png" alt=" " style={{ cursor: "pointer", position: "absolute", right: 50 }} />}
          </div>
        </div>
      </div>
      <div className="uk-gridConsmSeach">
        {/* <div className="uk-grid"> */}
        <div className="uk-width-medium-1-2">
          <b>수용가 검색 &nbsp; &nbsp;</b>
          <Input
            id="userTerm"
            type="text"
            className={styles.SetSearchTerm}
            onClick={searchUserId}
            // className = "SetSearchTerm"
            placeholder="수용가"
            readOnly
            value={search.userTerm ? search.userTerm : ""}
          />
          <a icon="search" className={styles.searchBtn} onClick={searchUserId}>
            <img className={styles.searchImg} src={"/images/icon_d.png"} />
          </a>
          {userInfoVisible && (
            <ConsmList conn={conn} setUserInfoVisible={setUserInfoVisible} rgnList={rgnList} dongList={dongList} selectUserId={selectUserId} />
          )}
        </div>
        {modalItem.visible && (
          <ReportConsmPrintModal
            search={search}
            energyData={energyData}
            energyCount={energyCount}
            selectMeain={selectMeain}
            chartWarn={chartWarn}
            chartMonthData={chartMonthData}
            chartYearData={chartYearData}
          />
        )}
      </div>
    </>
  );
}

export default ConsmSearch;
