import kendo from "@progress/kendo-ui";
import { downloadExcel } from "../../../com/downloadExcel";

export const getNonOperHistList = async (conn, paging, data) => {
  const take = paging.take;
  const skip = paging.skip;
  const page = paging.skip / paging.take + 1;
  const pageSize = paging.take;

  // const formatDate = kendo.toString(data.date, "yyyyMMdd")
  const response = await conn.get("stat/nonOperHist/page", {
    params: {
      // date: formatDate,
      take: take,
      skip: skip,
      page: page,
      pageSize: pageSize,
      date: data.date,
      ensoTypeCode: data.ensoTypeCode,
      rgnCode: data.rgnCode,
      cid: data.cid,
      installerTerm: data.installerTerm,
      communicationMthd: data.communicationMthd,
      makrSeriNo: data.makrSeriNo,
      meainTypeSeriNo: data.meainTypeSeriNo,
    },
  });

  return response;
};

export const getRgnNonOperHistList = async (conn, data) => {
  const response = await conn.get("stat/nonOperHist/rgn", {
    params: {
      date: data.date,
      ensoTypeCode: data.ensoTypeCode,
      rgnCode: data.rgnCode,
      cid: data.cid,
      installerTerm: data.installerTerm,
      communicationMthd: data.communicationMthd,
      makrSeriNo: data.makrSeriNo,
      meainTypeSeriNo: data.meainTypeSeriNo,
    },
  });

  return response;
};

export const getInvNonOperHistList = async (conn, data) => {
  const response = await conn.get("stat/nonOperHist/inv", {
    params: {
      date: data.date,
      ensoTypeCode: data.ensoTypeCode,
      rgnCode: data.rgnCode,
      cid: data.cid,
      installerTerm: data.installerTerm,
      communicationMthd: data.communicationMthd,
      makrSeriNo: data.makrSeriNo,
      meainTypeSeriNo: data.meainTypeSeriNo,
    },
  });
  return response;
};

export const updateEtc = async (conn, data) => {
  const response = await conn.post("stat/nonOperHist", data, {
    config: {
      headers: {
        "Content-Type": "application/json",
      },
    },
  });
  return response;
};

// 엑셀 다운로드
export const getExcelDownload = async (param, setLoading) => {
  const excelTitle = "미작동이력_" + kendo.toString(new Date(), "yyyyMMdd") + ".xlsx";
  const url = "/api/v2.2/stat/nonOperHist";

  // 정렬 필드 정의

  // 조건 검색 객체
  const search = {
    date: param.date,
    ensoTypeCode: param.ensoTypeCode,
    rgnCode: param.rgnCode,
    cid: param.cid,
    installerTerm: param.installerTerm,
    communicationMthd: param.communicationMthd,
    makrSeriNo: param.makrSeriNo,
    meainTypeSeriNo: param.meainTypeSeriNo,
  };

  try {
    downloadExcel(search, excelTitle, url, setLoading);
  } catch (error) {
    kendo.alert("엑셀 다운로드를 실패 하였습니다.");
    return;
  }
};
