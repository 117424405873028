//React
import React from 'react';
import './reportDay.css';

// 비교 테이블
export const ReportDayCompareEnergyTable = ({ compareDashboard, dateType, print }) => {
  return (
    <>
      <table className={print ? 'printCompareEnergyTable' : 'compareEnergyTable'}>
        <thead>
          <tr>
            <th></th>
            {dateType === 'DD' && <th>전일 기준값</th>}
            {dateType === 'MM' && <th>전월 기준값</th>}
            {dateType === 'YYYY' && <th>전년 기준값</th>}
            {dateType === 'DD' && <th>{compareDashboard.referDate}</th>}
            {dateType === 'MM' && <th>{compareDashboard.referDate + ' 월'}</th>}
            {dateType === 'YYYY' && <th>{compareDashboard.referDate + ' 년'}</th>}
            <th>증감률</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>총 발전량</td>
            <td>{compareDashboard.totalPrePower}&nbsp;kWh</td>
            <td>{compareDashboard.totalPower}&nbsp;kWh</td>
            <td>{compareDashboard.totalRate}&nbsp;%</td>
          </tr>
          <tr>
            <td>태양광</td>
            <td>{compareDashboard.solaPrePower}&nbsp;kWh</td>
            <td>{compareDashboard.solaPower}&nbsp;kWh</td>
            <td>{compareDashboard.solaRate}&nbsp;%</td>
          </tr>
          <tr>
            <td>풍력</td>
            <td>{compareDashboard.veloPrePower}&nbsp;kWh</td>
            <td>{compareDashboard.veloPower}&nbsp;kWh</td>
            <td>{compareDashboard.veloRate}&nbsp;%</td>
          </tr>
          <tr>
            <td>연료전지</td>
            <td>{compareDashboard.fuelcellPrePower}&nbsp;kWh</td>
            <td>{compareDashboard.fuelcellPower}&nbsp;kWh</td>
            <td>{compareDashboard.fuelcellRate}&nbsp;%</td>
          </tr>
        </tbody>
      </table>
      <table className={print ? 'printCompareEnergyTable' : 'compareEnergyTable'}>
        <thead>
          <tr>
            <th></th>
            {dateType === 'DD' && <th>전일 기준값</th>}
            {dateType === 'MM' && <th>전월 기준값</th>}
            {dateType === 'YYYY' && <th>전년 기준값</th>}
            {dateType === 'DD' && <th>{compareDashboard.referDate}</th>}
            {dateType === 'MM' && <th>{compareDashboard.referDate + ' 월'}</th>}
            {dateType === 'YYYY' && <th>{compareDashboard.referDate + ' 년'}</th>}
            <th>증감률</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>총 생산열량</td>
            <td>{compareDashboard.totalPreCal}&nbsp;kWh</td>
            <td>{compareDashboard.totalCal}&nbsp;kWh</td>
            <td>{compareDashboard.totalCalRate}&nbsp;%</td>
          </tr>
          <tr>
            <td>태양열</td>
            <td>{compareDashboard.heatPreCal}&nbsp;kWh</td>
            <td>{compareDashboard.heatCal}&nbsp;kWh</td>
            <td>{compareDashboard.heatRate}&nbsp;%</td>
          </tr>
          <tr>
            <td>지열</td>
            <td>{compareDashboard.heathPreCal}&nbsp;kWh</td>
            <td>{compareDashboard.heathCal}&nbsp;kWh</td>
            <td>{compareDashboard.heathRate}&nbsp;%</td>
          </tr>
        </tbody>
      </table>
      <table className={print ? 'printCompareEnergyTable' : 'compareEnergyTable'}>
        <thead>
          <tr>
            <th></th>
            {dateType === 'DD' && <th>전일 기준값</th>}
            {dateType === 'MM' && <th>전월 기준값</th>}
            {dateType === 'YYYY' && <th>전년 기준값</th>}
            {dateType === 'DD' && <th>{compareDashboard.referDate}</th>}
            {dateType === 'MM' && <th>{compareDashboard.referDate + ' 월'}</th>}
            {dateType === 'YYYY' && <th>{compareDashboard.referDate + ' 년'}</th>}
            <th>증감률</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>ESS 충전량</td>
            <td> - &nbsp;kWh</td>
            <td> - &nbsp;kWh</td>
            <td> - &nbsp;%</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default ReportDayCompareEnergyTable;
