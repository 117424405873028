import { getToken } from "../service/authService";

/**
 * - data : 조건 데이터
 * - fileName : '파일명_' + parseDate('yyyymmddhhmmss') + '.xlsx';
 * - url : 요청 url (/excelDownload 제외)
 */
export const downloadExcel = (data, fileName, url, loading) => {

  const request = new XMLHttpRequest();
  request.open("POST", url + "/excelDownload", true);
  request.setRequestHeader("Content-Type", "application/json; charset=UTF-8");
  request.setRequestHeader('X-AUTH-TOKEN', getToken());
  request.setRequestHeader(
    "Accept",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  );
  request.responseType = "blob";
  request.onload = function (e) {
    if (this.status === 200) {
      const blob = this.response;
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, fileName);
      } else {
        const downloadLink = window.document.createElement("a");
        const contentTypeHeader = request.getResponseHeader("Content-Type");
        downloadLink.href = window.URL.createObjectURL(
          new Blob([blob], { type: contentTypeHeader })
        );
        downloadLink.download = fileName;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
      // clearInterval(progress);
      // excelUploadProgressClear(url+"/excelDownloadProgressClear");
    } else {
      // clearInterval(progress);
      // excelUploadProgressClear(url+"/excelDownloadProgressClear");
    }

    if( loading ){
      loading(false);
    }
  };
  request.send(JSON.stringify(data));
  return request;
};
