//React
import React, { useState } from "react";

import ReportDayIntegratedChart from "./chart/reportDayIntegratedChart";
import ReportDayProductionChart from "./chart/reportDayProductionChart";
import ReportDayGenerationChart from "./chart/reportDayGenerationChart";
import ReportDayEnergyTable from "./reportDayEnergyTable";
import ReportDayAlertChart from "./chart/reportDayAlertChart";

//kendo
import kendo from "@progress/kendo-ui";
import { Grid, GridColumn as Column, GridNoRecords } from "@progress/kendo-react-grid";

//css
import "./reportDay.css";
import ReportDayCompareEnergyTable from "./reportDayCompareEnergyTable";
import NewWindow from "react-new-window";
import { orderBy } from "@progress/kendo-data-query";
import { BeatLoader } from "react-spinners";

const ReportDayPrintModal = ({ reportData, sortState, onUnload, search }) => {
  const [loading, setLoading] = useState(false);
  return (
    <NewWindow
      features={{ width: 900, height: 1080 }}
      title="종합 운영 보고서"
      onUnload={onUnload}
      onOpen={(win) => {
        setLoading(true);
        setTimeout(() => {
          setLoading(false);
        }, 2000);
        setTimeout(() => {
          win.print();
          win.close();
        }, 3000);
      }}
    >
      {loading && (
        <p
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
        >
          <BeatLoader loading={true} size={24} color="#1e88e5" />
        </p>
      )}
      <div
        style={
          loading
            ? {
                opacity: 0.6,
                pointerEvents: "none",
                boxShadow: "none",
                width: "210mm",
                height: "297mm",
                margin: "0mm 30mm",
              }
            : {
                pointerEvents: "none",
                width: "210mm",
                height: "297mm",
                margin: "0mm 30mm",
              }
        }
      >
        <div className="uk-grid">
          <div className="uk-width-medium-1-1">
            {reportData.dateType === "DD" && <div className="reportDayPrintHeading"> 일간 종합 운영 보고서 </div>}
            {reportData.dateType === "MM" && <div className="reportDayPrintHeading"> 월간 종합 운영 보고서 </div>}
            {reportData.dateType === "YYYY" && <div className="reportDayPrintHeading"> 연간 종합 운영 보고서 </div>}
            {reportData.dateType === "DD" && <div className="reportDayPrintSubheading"> Daily Operation Report </div>}
            {reportData.dateType === "MM" && <div className="reportDayPrintSubheading"> Monthly Operation Report </div>}
            {reportData.dateType === "YYYY" && <div className="reportDayPrintSubheading"> Yearly Operation Report </div>}
            <div className="reportDayBackground">
              보고일 : &nbsp; {kendo.toString(search.dtm, search.dateformat)}
              {reportData.dateType === "MM" && <span>월</span>}
              {reportData.dateType === "YYYY" && <span>년</span>}
            </div>
          </div>
          {/**
           * 전기에너지, 열에너지, ESS 테이블
           */}

          <div className="reportDayPrintTableDiv">
            <table>
              <thead>
                <th colSpan="2">전기 에너지</th>
              </thead>
              <tbody>
                <tr>
                  {search.dateType === "DD" && <th> 일간 발전량 </th>}
                  {search.dateType === "MM" && <th> 월일 발전량 </th>}
                  {search.dateType === "YYYY" && <th> 년도 발전량 </th>}
                  <th>누적발전량</th>
                </tr>
                <tr>
                  <td>{reportData.dashBoard.power}&nbsp;kWh</td>
                  <td>{reportData.dashBoard.accumPower}&nbsp;kWh</td>
                </tr>
                <tr>
                  <th>누적CO2저감량</th>
                  <th>이용률</th>
                </tr>
                <tr>
                  <td>{reportData.dashBoard.accumCo2}&nbsp;t</td>
                  <td> - &nbsp;%</td>
                </tr>
              </tbody>
            </table>
            <table>
              <thead>
                <th colSpan="2">열 에너지</th>
              </thead>
              <tbody>
                <tr>
                  {search.dateType === "DD" && <th> 일간 생산열량 </th>}
                  {search.dateType === "MM" && <th> 월일 생산열량 </th>}
                  {search.dateType === "YYYY" && <th> 년도 생산열량 </th>}
                </tr>
                <tr>
                  <td>{reportData.dashBoard.cal}&nbsp;kWh</td>
                </tr>
                <tr>
                  <th>누적생산열량</th>
                </tr>
                <tr>
                  <td>{reportData.dashBoard.accumCal}&nbsp;kWh</td>
                </tr>
              </tbody>
            </table>
            <table>
              <thead>
                <th colSpan="2">ESS</th>
              </thead>
              <tbody>
                <tr>
                  {search.dateType === "DD" && <th> 일간 충전량 </th>}
                  {search.dateType === "MM" && <th> 월일 충전량 </th>}
                  {search.dateType === "YYYY" && <th> 년도 충전량 </th>}
                  <th>충전시간</th>
                </tr>
                <tr>
                  <td> - &nbsp;kWh</td>
                  <td> - &nbsp;시간</td>
                </tr>
                <tr>
                  <th>누적충전량</th>
                  <th>충전효율</th>
                </tr>
                <tr>
                  <td> - &nbsp;kWh</td>
                  <td> - &nbsp;%</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="uk-width-medium-1-1">
            {reportData.dateType === "DD" && <div className="reportDayBackground"> ·&nbsp;일간 시간별 생산량 </div>}
            {reportData.dateType === "MM" && <div className="reportDayBackground"> ·&nbsp;월일 시간별 생산량 </div>}
            {reportData.dateType === "YYYY" && <div className="reportDayBackground"> ·&nbsp;년도 시간별 생산량 </div>}
            {/**
             * 일간시간별 생산량 차트
             */}
            <ReportDayIntegratedChart timeChart={reportData.timeChart} width={777} height={300} clsName={"printChart"}></ReportDayIntegratedChart>
          </div>

          <div style={{ width: 380 }}>
            {/**
             * 전일기준값과 에너지 비교 테이블
             */}
            <ReportDayCompareEnergyTable compareDashboard={reportData.compareDashboard} dateType={reportData.dateType} print={true} />
            {/**
             * 일간 경보 발생 현황 차트
             */}
            {reportData.dateType === "DD" && <div className="reportDayBackground"> ·&nbsp;일간 경보 발생 현황 </div>}
            {reportData.dateType === "MM" && <div className="reportDayBackground"> ·&nbsp;월간 경보 발생 현황 </div>}
            {reportData.dateType === "YYYY" && <div className="reportDayBackground"> ·&nbsp;년간 경보 발생 현황 </div>}
            <ReportDayAlertChart eventList={reportData.eventList} ensoTypeCode={15000} width={380} height={240} clsName={"printChart"}></ReportDayAlertChart>
          </div>
          <div style={{ float: "left", width: 380 }}>
            <Grid className="reportPrintGrid" data={orderBy(reportData.energyList, sortState)}>
              <GridNoRecords>{"조회 결과 데이터가 없습니다."}</GridNoRecords>
              <Column field="dtm" title="시간대" />
              <Column field="power" title="발전량" />
              <Column field="cal" title="생산열량" />
              <Column field="ess" title="ESS 충전량" />
            </Grid>
          </div>
          <div className="uk-width-medium-1-1">
            <div className="reportDayBackground"> &nbsp;</div>
          </div>
          <div>
            {reportData.dateType === "DD" && <div className="reportDayBackground"> ·&nbsp;에너지원별 일간 발전량 </div>}
            {reportData.dateType === "MM" && <div className="reportDayBackground"> ·&nbsp;에너지원별 월간 발전량 </div>}
            {reportData.dateType === "YYYY" && <div className="reportDayBackground"> ·&nbsp;에너지원별 년간 발전량 </div>}
            <ReportDayGenerationChart compareChart={reportData.compareChart} width={380} height={240} clsName={"printChart"}></ReportDayGenerationChart>
          </div>
          <div>
            {reportData.dateType === "DD" && <div className="reportDayBackground"> ·&nbsp;에너지원별 일간 생산열량 </div>}
            {reportData.dateType === "MM" && <div className="reportDayBackground"> ·&nbsp;에너지원별 월간 생산열량 </div>}
            {reportData.dateType === "YYYY" && <div className="reportDayBackground"> ·&nbsp;에너지원별 년간 생산열량 </div>}
            <ReportDayProductionChart compareChart={reportData.compareChart} width={380} height={240} clsName={"printChart"}></ReportDayProductionChart>
          </div>
          <div className="uk-width-medium-1-1">
            {reportData.dateType === "DD" && <div className="reportDayBackground"> -&nbsp;에너지원별 경보 발생 현황(주간) </div>}
            {reportData.dateType === "MM" && <div className="reportDayBackground"> -&nbsp;에너지원별 경보 발생 현황(월간) </div>}
            {reportData.dateType === "YYYY" && <div className="reportDayBackground"> -&nbsp;에너지원별 경보 발생 현황(연간) </div>}
          </div>
          <div>
            <div className="reportDayBackground">·&nbsp;태양광</div>
            <ReportDayAlertChart eventList={reportData.eventList} ensoTypeCode={15001} width={380} height={240} clsName={"printChart"}></ReportDayAlertChart>
          </div>
          <div>
            <div className="reportDayBackground">·&nbsp;태양열</div>
            <ReportDayAlertChart eventList={reportData.eventList} ensoTypeCode={15002} width={380} height={240} clsName={"printChart"}>
              {" "}
              clsName={"printChart"}
            </ReportDayAlertChart>
          </div>
          <div>
            <div className="reportDayBackground">·&nbsp;지열</div>
            <ReportDayAlertChart eventList={reportData.eventList} ensoTypeCode={15003} width={380} height={240} clsName={"printChart"}></ReportDayAlertChart>
          </div>
          <div>
            <div className="reportDayBackground">·&nbsp;풍력</div>
            <ReportDayAlertChart eventList={reportData.eventList} ensoTypeCode={15004} width={380} height={240} clsName={"printChart"}></ReportDayAlertChart>
          </div>
          <div>
            <div className="reportDayBackground">·&nbsp;연료전지</div>
            <ReportDayAlertChart eventList={reportData.eventList} ensoTypeCode={15006} width={380} height={240} clsName={"printChart"}></ReportDayAlertChart>
          </div>
          <div>
            <div className="reportDayBackground">·&nbsp;ESS</div>
            <ReportDayAlertChart eventList={reportData.eventList} ensoTypeCode={15007} width={380} height={240} clsName={"printChart"}></ReportDayAlertChart>
          </div>
          <div className="uk-width-medium-1-1">
            <div className="reportDayBackground">&nbsp;</div>
            <div className="reportDayBackground">·&nbsp;시군구별 운영 현황</div>
            <Grid className="reportPrintSecondGrid" data={reportData.areaList} scrollable={false}>
              <GridNoRecords>{"조회 결과 데이터가 없습니다."}</GridNoRecords>
              <Column field="rgnTerm" title="지역" />
              <Column field="power" title="발전량" />
              <Column field="cal" title="생산열량" />
              <Column field="ess" title="ESS 충전량" />
            </Grid>
            <div className="reportDayBackground">&nbsp;</div>
          </div>
        </div>
      </div>
    </NewWindow>
  );
};

export default ReportDayPrintModal;
