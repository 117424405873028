import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";
import { useEffect, useRef, useState } from "react";
import { yearList } from "../../../com/dropDownDs";
import { isInstaller } from "../../../service/authService";
import { isOneManager } from "./../../../service/authService";

// 좌측 검색 목록
const LocationSearch = ({ codeList, rgnList, dongList, rgnCode, fixbylawBundCode, search, setSearchInst, setSearch, map, cluster }) => {
  // 기존 위도 경도 설정
  const basicLati = 36.81508;
  const basicLongi = 127.11392;

  // 사업구분 데이터 소스
  const bizSctList = codeList.filter((c) => c.grpCodeNo === 33);
  //건물용도별 데이터 소스
  const bldSrvList = codeList.filter((c) => c.grpCodeNo === 31);
  // 읍면동
  const dongDs = dongList.filter((e) => e.rgnCode !== "" && e.rgnCode === search.rgnCode);

  // 각 지역 이름을 simpleTerm 으로 변경
  // rgnList.forEach((element) => {
  //   element.rgnTerm = element.rgnTermSimple;
  // });
  // dongDs.forEach((element) => {
  //   element.fixbylawBundTerm = element.fixbylawBundTermSimple;
  // });

  // 수용가명 입력 이벤트
  const textInputEvt = (e) => {
    // userTerm.current = e.target.value;
    setSearch((search) => {
      const item = { ...search };
      item.userTerm = e.target.value;
      return item;
    });
  };

  // 드롭다운 이벤트
  const onChangeEvt = (e) => {
    if (e.target.name === "rgn") {
      setSearch((search) => {
        const item = { ...search };
        item.rgnCode = e.target.value.rgnCode;
        item.rgn = e.target.value;
        item.fixbylawBundCode = "";
        item.dongTerm = "";
        item.dong = { fixbylawBundCode: "", fixbylawBundTermSimple: "읍면동" };
        item.rgnTermSimple = e.target.value.rgnTermSimple;
        item.lati = e.target.value.rgnCode !== "" ? e.target.value.lati : basicLati;
        item.longi = e.target.value.rgnCode !== "" ? e.target.value.longi : basicLongi;
        item.rgnLati = e.target.value.rgnCode !== "" ? e.target.value.lati : basicLati;
        item.rgnLongi = e.target.value.rgnCode !== "" ? e.target.value.longi : basicLongi;
        return item;
      });
    } else if (e.target.name === "dong") {
      setSearch((search) => {
        const item = { ...search };
        item.fixbylawBundCode = e.target.value.fixbylawBundCode;
        item.dong = e.target.value;
        item.dongTerm = e.target.value.fixbylawBundTermSimple;
        item.lati = e.target.value.fixbylawBundCode !== "" ? e.target.value.lati : item.rgnLati;
        item.longi = e.target.value.fixbylawBundCode !== "" ? e.target.value.longi : item.rgnLongi;
        return item;
      });
    } else {
      if (e.target.name === "bizYy") {
        if (e.target.value === "사업연도") {
          setSearch((search) => {
            const item = { ...search };
            item[e.target.name] = "";
            return item;
          });
        } else {
          setSearch((search) => {
            const item = { ...search };
            item[e.target.name] = e.target.value;
            return item;
          });
        }
      } else {
        setSearch((search) => {
          const item = { ...search };
          item[e.target.name] = e.target.value.codeNo;
          return item;
        });
      }
    }
  };

  // 조회 버튼
  const onClickEvt = (e) => {
    setSearchInst(map, cluster);
  };

  // 시군구 값 설정
  const changeValueRgn = () => {
    if (rgnCode !== null) {
      setSearch((search) => {
        const item = { ...search };
        item.rgn = rgnList.filter((e) => e.rgnCode === search.rgnCode)[0];
        return item;
      });
    }
  };

  // 음면동 값 설정
  const changeValueDong = () => {
    if (fixbylawBundCode !== null) {
      setSearch((search) => {
        const item = { ...search };
        item.dong = dongList.filter((e) => e.fixbylawBundCode === search.fixbylawBundCode)[0];
        return item;
      });
    }
  };

  useEffect(() => {
    changeValueRgn();
    changeValueDong();
  }, []);

  return (
    <div>
      <table className="chmapTable-A">
        <tbody>
          <tr>
            <td>
              <div className="MapSearch">
                <input
                  type="text"
                  name="userTerm"
                  className="MapSearchTerm"
                  placeholder="수용가명"
                  onChange={textInputEvt}
                  value={search.userTerm}
                  disabled={isInstaller() === true ? true : false}
                />
              </div>
            </td>
            <td>
              <div className="Mapdropdown">
                <DropDownList
                  id="rgn"
                  name="rgn"
                  data={rgnList}
                  className="searchDropDown"
                  value={search.rgn}
                  disabled={isInstaller() || isOneManager() === true ? true : false}
                  defaultItem={{ rgnCode: "", rgnTermSimple: "시군구" }}
                  dataItemKey="rgnCode"
                  textField="rgnTermSimple"
                  onChange={onChangeEvt}
                />
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div className="Mapdropdown">
                <DropDownList
                  id="dong"
                  name="dong"
                  data={dongDs}
                  className="searchDropDown"
                  disabled={isInstaller() === true ? true : false}
                  defaultItem={{ fixbylawBundCode: "", fixbylawBundTermSimple: "읍면동" }}
                  dataItemKey="fixbylawBundCode"
                  textField="fixbylawBundTermSimple"
                  onChange={onChangeEvt}
                  value={search.dong}
                />
              </div>
            </td>
            <td>
              <div className="Mapdropdown">
                <DropDownList
                  id="bldSrvCodeNo"
                  name="bldSrvCodeNo"
                  data={bldSrvList}
                  className="searchDropDown"
                  disabled={isInstaller() === true ? true : false}
                  defaultItem={{ codeNo: "", codeVal: "건물용도" }}
                  dataItemKey="codeNo"
                  textField="codeVal"
                  onChange={onChangeEvt}
                />
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div className="Mapdropdown">
                <DropDownList
                  id="bizSct"
                  name="bizSct"
                  data={bizSctList}
                  className="searchDropDown"
                  disabled={isInstaller() === true ? true : false}
                  defaultItem={{ codeNo: "", codeVal: "사업구분" }}
                  dataItemKey="codeNo"
                  textField="codeVal"
                  onChange={onChangeEvt}
                />
              </div>
            </td>
            <td>
              <div className="Mapdropdown">
                <DropDownList
                  id="bizYy"
                  name="bizYy"
                  data={yearList()}
                  className="searchDropDown"
                  disabled={isInstaller() === true ? true : false}
                  defaultItem="사업연도"
                  onChange={onChangeEvt}
                />
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan="2">
              <Button id="inqBtn" className="MapSearchBtn" name="inqBtn" onClick={onClickEvt}>
                검 색
              </Button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default LocationSearch;
