//react
import React from "react";
//components
import RenewMap from "./renewMap";
import RenewStatDetail from "./renewStatDetail";
//css
import styles from "./renewStat.module.css";
import RenewStatTb from "./renewStatTb";
import RenewMapTb from "./renewMapTb";
import "./renewStatScss.scss";
import RenewStatLeft from "./renewStatLeft";
import GeoGradient from "./geoGradient";

const RenewStatPrzt = (props) => {
  const {
    currMap,
    mapOverEvt,
    mapOutEvt,
    mapClickEvt,
    depthBoxRef,
    dashData,
    operCntData,
    ensoStatData,
    bizStatData,
    realPowerBoxRef,
    estPowerBoxRef,
    realUseBoxRef,
    carbDecBoxRef,
    prevTabBtnClickEvt,
    nextTabBtnClickEvt,
    currTab,
    top3EnsoStatData,
    monthStatData,
    setLoading
  } = props;

  return (
    <div style={{ backgroundImage: `url("/images/mapBg_44130_5.png"`, backgroundRepeat: "no-repeat" }}>
      <GeoGradient />
      {dashData.map((item) => (
        <RenewStatTb rgnData={item} mapOutEvt={mapOutEvt} mapOverEvt={mapOverEvt} mapClickEvt={mapClickEvt} />
      ))}
      <section className={styles.content}>
        <div className={styles.conLeft}>
          <RenewStatLeft
            currMap={currMap}
            dashData={dashData}
            realPowerBoxRef={realPowerBoxRef}
            estPowerBoxRef={estPowerBoxRef}
            realUseBoxRef={realUseBoxRef}
            carbDecBoxRef={carbDecBoxRef}
          />
        </div>
        <div className={styles.conCenter}>
          <RenewMap mapOverEvt={mapOverEvt} mapOutEvt={mapOutEvt} mapClickEvt={mapClickEvt} />
        </div>
        <div className={styles.conRight}>
          <RenewStatDetail
            operCntData={operCntData}
            ensoStatData={ensoStatData}
            bizStatData={bizStatData}
            currMap={currMap}
            prevTabBtnClickEvt = {prevTabBtnClickEvt}
            nextTabBtnClickEvt = {nextTabBtnClickEvt}
            currTab={currTab}
            top3EnsoStatData={top3EnsoStatData}
            monthStatData={monthStatData}
            setLoading = {setLoading}
          />
        </div>
          <RenewMapTb depthBoxRef={depthBoxRef} />
      </section>
    </div>
  );
};

export default RenewStatPrzt;
