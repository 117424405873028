import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { numFormat } from '../../../../com/dataUtil';

// 제조사 / 설비모델 그래프
const UserBetweenUserMakerChart = ({search , makrDataEfic , makrDataRatio , getMakrMeainModelDataList}) => {
  // 에너지원 코드 번호
  const ensoTypeCodeNo = search.ensoTypeCode; 
  // 발전량 , 생산량 조건부 렌더링
  const isCal = ensoTypeCodeNo === 15002 || ensoTypeCodeNo === 15003 ? true : false;

  // 제조사  / 모델 차트 옵션
  const chartConfig = {
    chart: { zoomType: 'xy', },
    noData: { style:{ color: '#FFFFFF', fontSize: '14px' } },
    title: { text: null },
    legend: { enabled: false },
    tooltip: {
        shared: true,
        positioner: function (labelWidth, labelHeight, point) {
          // 툴팁 위치 조정 
          const tooltipX = point.plotX;
          const tooltipY = 150;
          return {
              x: tooltipX,
              y: tooltipY
          };
        },
        formatter: function () {
            var s = '<b>' + this.points[0].point.name + '</b>';
            s += '<br/>'+ '<span style="color:' + this.points[0].point.color + '">\u25CF</span> '  + this.points[0].series.name + ': ' + Math.round(this.y*100)/100 + ' kWh/'+(search.ensoTypeCodeNo==15002?'㎡':'kW')+'.day';
            s += '<br/>' + '<span style="color:' + this.points[1].point.color + '">\u25CF</span> '  + this.points[1].series.name + ': ' + Math.round(this.points[1].y) +' % (' + numFormat(this.points[1].point.instCapa)  + (search.ensoTypeCodeNo==15002?'㎡':'kW')+')';
            return s;
        }
    },
    xAxis: { type: 'category', crosshair: true },
    yAxis: [{ // Primary yAxis
        labels: {
            format: '{value:,.2f}',
            style: {
                color: "#69bcc8"
            }
        },
        title: {
            text: isCal === false ? '용량대비생산량(kWh/' : '용량대비생산량(kWh/' +(search.ensoTypeCodeNo==15002?'㎡':'kW')+'.day)',
            style: {
                color: "#69bcc8"
            }
        }
    }, { // Secondary yAxis
        min: 0,
        title: {
            text: '설비용량비율(%)',
            style: {
                color: "#c1ce01"
            }
        },
        labels: {
            format: '{value:,.0f}',
            style: {
                color: "#c1ce01"
            }
        },
        opposite: true
    }],
    credits: { enabled: false },
    plotOptions: {
        series: { colorByPoint: true },
        column : {
            cursor: 'pointer',
            point: {
                events: {
                    click: function(e){
                      // 제조사 코드 확인 
                      if( e.point.makrSeriNo !== null ){
                        search.makrSeriNo = e.point.makrSeriNo;
                      }else{
                        search.makrSeriNo = "";
                      }
                      getMakrMeainModelDataList();
                    }
                }
            }
        }
    },
    series: [{
        name: '용량대비생산량',
        type: 'column',
        color: '#69bcc8',
        data: makrDataEfic,
        dataLabels: {
            enabled: true,
            format: '{point.y:,.2f}'
        },
        tooltip: {
            pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:,.2f} kWh/'+(search.ensoTypeCodeNo==15002?'㎡':'kW')+'.day</b><br/>'
        }
    },
    {
        name: '설비용량비율',
        type: 'column',
        yAxis: 1,
        data: makrDataRatio,
        color: '#c1ce01',
        dataLabels: {
            enabled: true,
            format: '{point.y:,.2f}%'
        },
        tooltip: {
            pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:,.2f} %</b><br/>'
        }
    }]
  }

  return(
    <div style = {{height : "370px"}}>
        <HighchartsReact Highcharts = {Highcharts} options = {chartConfig}/>
    </div>
  );
}

export default UserBetweenUserMakerChart;