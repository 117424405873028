import UserInfoData from "./userInfoData";
import kendo from "@progress/kendo-ui";
import { getUserInfoData, setUserInfoSaveData } from "../../../service/mngt/userInfo/userInfoService";

import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { getUser } from "../../../service/authService";

const UserInfo = ({ conn }) => {
  // 히스토리 사용
  const history = useHistory();
  // 유저 정보 불러오기
  const userData = getUser();
  // 유저 정보가 존재하지 않으면 로그인으로 이동
  // if( userData === null ){
  //   history.push('/login');
  // }

  // 유저 정보 상태
  const [userInfo, setUserInfo] = useState({
    userId: userData ? userData.userId : "",
    loginPwd: "",
    loginPwdCheck: "",
    userEmail: "",
    userCelphon: "",
    smsNotiUseYn: "",
    emailNotiUseYn: "",
    wkdd: "",
    notiRcpmsgTm: "",
    lisenNotiUseYn: "",
    ausenNotiUseYn: "",
    sun: "0",
    mon: "0",
    tues: "0",
    wednes: "0",
    thurs: "0",
    fri: "0",
    satur: "0",
  });

  // 유저 정보 불러오는 부분
  const getUserInfo = (userId) => {
    if (userId === "" || typeof userId === "undefined" || userId === null) {
      kendo.alert("ID가 존재하지 않아 정보를 읽어 올 수 없습니다.");
      history.push("/login");
      return;
    }
    getUserInfoData(conn, setUserInfoObj, getRejection, userId);
  };

  // 유저 정보 설정
  const setUserInfoObj = (result) => {
    setUserInfo(result);
  };

  // 이벤트에 따른 유저 정보 설정
  const setUserInfoData = (value, name, form) => {
    if (form === "checkbox") {
      setUserInfo((userInfo) => {
        const item = { ...userInfo };
        item[name] = value === true ? "Y" : "N";
        return item;
      });
    } else if (form === "input") {
      setUserInfo((userInfo) => {
        const item = { ...userInfo };
        item[name] = value;
        return item;
      });
    } else if (form === "weekends") {
      setUserInfo((userInfo) => {
        const item = { ...userInfo };
        item[name] = value === true ? "1" : "0";
        return item;
      });
    }
  };

  // 유저 정보 저장
  const saveUserInfo = () => {
    // 여기서 Validaion 처리
    // 1-1 PASSWORD 확인 및 이전 PASSWORD 처리 있는가?
    if (userInfo.loginPwd) {
      if (userInfo.loginPwd !== userInfo.loginPwdCheck) {
        kendo.alert("입력하신 변경된 비밀번호와 확인 비밀번호와 맞지 않습니다. 다시 확인하시고 똑같이 입력 해주세요.");
        return;
      } else {
        // 비밀번호 정규식 (영어 대문자, 소문자 포함 숫자 포함 특수문자 포함 8자리 이상)
        const regPassword = /^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
        // const regPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^*+=-]).{9,15}$/;
        if (!regPassword.test(userInfo.loginPwd)) {
          kendo.alert("유효하지 않은 비밀번호입니다! 영어 ,숫자,특수문자 포함한 8자리 이상 작성해주세요.");
          return;
        }
      }
    }

    //2-1 정규식 - 이메일 유효성 검사
    const regEmail = /([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
    if (userInfo.userEmail && !regEmail.test(userInfo.userEmail)) {
      kendo.alert("유효하지 않은 이메일 주소입니다!");
      return;
    }
    //2-2 정규식 - 이메일 알람은 Y 인데 이메일 입력 값이 없다면
    if (!userInfo.userEmail && userInfo.emailNotiUseYn === "Y") {
      kendo.alert("이메일 주소를 입력하지 않으면 알림을 수신할 수 없습니다!");
      return;
    }
    // 3-1 휴대폰 번호 처리
    const regPhone = /[01](0|1|6|7|8|9)-(\d{4}|\d{3})-\d{4}$/;
    if (userInfo.userCelphon && !regPhone.test(userInfo.userCelphon)) {
      kendo.alert("핸드폰 번호 형식이 맞지 않습니다. 010-1234-5678의 형태로 입력해 주시기 바랍니다.");
      return;
    }
    // 3-2.휴대폰 알람은 Y 인데 휴대폰 입력 값이 없다면
    if (!userInfo.userCelphon && (userInfo.smsNotiUseYn === "Y" || userInfo.kakaoNotiUseYn === "Y")) {
      kendo.alert("휴대폰 번호를 입력하지 않으면 알림을 수신할 수 없습니다!");
      return;
    }

    //rcpmsg 가공
    if (userInfo.notiRcpmsgStart) {
      const value = userInfo.notiRcpmsgStart;
      const resultHour = value.getHours() < 10 ? "0" + value.getHours() : value.getHours() + "";
      const resultMinute = value.getMinutes() < 10 ? "0" + value.getMinutes() : value.getMinutes() + "";
      const result = resultHour + resultMinute;
      userInfo.notiRcpmsgStart = result;
    }
    if (userInfo.notiRcpmsgEnd) {
      const value = userInfo.notiRcpmsgEnd;
      const resultHour = value.getHours() < 10 ? "0" + value.getHours() : value.getHours() + "";
      const resultMinute = value.getMinutes() < 10 ? "0" + value.getMinutes() : value.getMinutes() + "";
      const result = resultHour + resultMinute;
      userInfo.notiRcpmsgEnd = result;
    }

    // 4. 이메일 수신시간 0 ~ 23시 사이 작성 하였는가?

    kendo.confirm("저장하시겠습니까?").then(
      () => {
        setUserInfoSaveData(conn, saveResult, getRejection, userInfo);
      },
      () => {
        kendo.alert("취소되었습니다.");
        return;
      },
    );
  };

  const saveResult = (result) => {
    kendo.alert("저장이 완료 되었습니다.");
    // 유저 정보 다시 불러와서 설정
    getUserInfo(userInfo.userId);
  };

  // 유저 정보 설정 rejection
  const getRejection = (error) => {
    if (error.errCode === "L001") {
      kendo.alert("로그인한 유저 ID가 확인되지 않습니다. 다시 로그인 해주세요.");
      history.push("/login");
      return;
    } else {
      kendo.alert("시스템 오류로 관리자에 문의하십시오.");
    }
  };

  //대상자별 알림 수신 시간 변경이벤트
  const timeChngEvt = (e) => {
    const { target, value } = e;

    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const day = today.getDate();
    const hour = value.getHours();
    const minute = value.getMinutes();

    if (target.name === "notiRcpmsgStart") {
      setUserInfo({ ...userInfo, notiRcpmsgStart: new Date(year, month, day, hour, minute) });
    } else if (target.name === "notiRcpmsgEnd") {
      setUserInfo({ ...userInfo, notiRcpmsgEnd: new Date(year, month, day, hour, minute) });
    }
  };

  useEffect(() => {
    getUserInfo(userInfo.userId);
  }, []);

  return (
    <div className="userInfoDiv">
      <UserInfoData userInfo={userInfo} setUserInfoData={setUserInfoData} saveUserInfo={saveUserInfo} setUserInfo={setUserInfo} timeChngEvt={timeChngEvt} />
    </div>
  );
};

export default UserInfo;
