import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { numFormat } from "../../../../com/dataUtil";

const RenewMonthCht = ({monthStatData}) => {
  let dataSource = {
    month: [],
    monthPower: [],
  };
  monthStatData.forEach((item) => {
    dataSource.month.push(item.month);
    dataSource.monthPower.push(item.monthPower);
  });
  const chartOpt = {
    chart:{
      height: '230px'
    },
    //제목
    title: {text: null},

    //출처
    credits: {enabled: false},
    
    //계열
    series: [
      {
        type: 'column',
        data: dataSource.monthPower,
        showInNavigator: true,
        color: {
          linearGradient: {
            x1: 0, // 시작 위치
            x2: 1, // 끝 위치
            y1: 0,
            y2: 0
          },
          stops: [
            [0, "#87CEEB"],
            [1, "#1E90FF"]
          ]
        },
      }
    ],

    tooltip: {
        shared: true,
        formatter: function () {
          let msg = "";
          if (this.points[0]) {
            msg +=
              '<span style="color:' +
              this.points[0].point.color +
              '">\u25CF</span> ' +
              "<span>" +
              " 발전량" +
              "</span>" +
              " : " +
              "<b>" +
              numFormat(this.points[0].y) +
              "MWh</b>" +
              "<br>";
          }
  
          return msg;
        },
    },

    //범례
    legend: {
      enabled: false,
    },
  
    xAxis: {
      categories: dataSource.month,
      labels: {format: `{value} 월`}
    },

    yAxis:{
      title: null,
      labels: {format: `{value} MWh`}
    },

  };
  
  return <HighchartsReact highcharts={Highcharts} options={chartOpt}/>;
};

export default RenewMonthCht;
