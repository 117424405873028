import React from "react";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import HeatPumpIcon from "@mui/icons-material/HeatPump";
import { SvgIcon } from "@material-ui/core";
import styles from "./renewStat.module.css";
import { roundNum } from "../../../com/dataUtil";
import { numFormat } from "./../../../com/dataUtil";

const RenewStatTb = (props) => {
  const { rgnData, mapOverEvt, mapOutEvt, mapClickEvt } = props;
  /* 화면에서 지역별 테이블 위치 */
  const locPerRgn = [
    { rgnCode: "44250", rgnTerm: "계룡시", top: "610px", left: "1220px" },
    { rgnCode: "44710", rgnTerm: "금산군", top: "760px", left: "1270px" },
    { rgnCode: "44150", rgnTerm: "공주시", top: "450px", left: "1170px" },
    { rgnCode: "44230", rgnTerm: "논산시", top: "900px", left: "1120px" },
    { rgnCode: "44270", rgnTerm: "당진시", top: "130px", left: "700px" },
    { rgnCode: "44180", rgnTerm: "보령시", top: "700px", left: "570px" },
    { rgnCode: "44760", rgnTerm: "부여군", top: "830px", left: "900px" },
    { rgnCode: "44210", rgnTerm: "서산시", top: "300px", left: "600px" },
    { rgnCode: "44770", rgnTerm: "서천군", top: "900px", left: "630px" },
    { rgnCode: "44200", rgnTerm: "아산시", top: "150px", left: "930px" },
    { rgnCode: "44810", rgnTerm: "예산군", top: "400px", left: "800px" },
    { rgnCode: "44130", rgnTerm: "천안시", top: "170px", left: "1200px" },
    { rgnCode: "44790", rgnTerm: "청양군", top: "580px", left: "850px" },
    { rgnCode: "44825", rgnTerm: "태안군", top: "210px", left: "400px" },
    { rgnCode: "44800", rgnTerm: "홍성군", top: "500px", left: "570px" },
  ];
  const currRgn = locPerRgn.find((item) => item.rgnCode === rgnData.rgnCode);

  /* 지역 로고 이미지 파일 경로 */
  const getLogo = (rgnCode) => {
    return "/images/logos/" + rgnCode + "_logo.png";
  };
  return (
    <div
      id={"rgnTabl_" + currRgn.rgnCode}
      onMouseMove={mapOverEvt}
      onMouseLeave={mapOutEvt}
      onClick={mapClickEvt}
      className={styles.renewTable}
      style={{ top: currRgn.top, left: currRgn.left }}
    >
      <table>
        <thead>
          <tr>
            <th colspan="2" id={"rgnHead_" + currRgn.rgnCode}>
              <img style={{ position: "absolute", left: "22px", width: "20px" }} src={getLogo(currRgn.rgnCode)} alt="로고" />
              {currRgn.rgnTerm}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ fontSize: "13px" }}>설비용량</td>
            <td style={{ fontSize: "13px" }}>발전/사용량</td>
          </tr>
          <tr>
            <td>
              <SvgIcon component={ElectricBoltIcon} style={{ color: "#FFAD01" }} />
              &nbsp;
              {numFormat(roundNum(rgnData.gelecInstCapa + rgnData.realSolaInstCapa, 1))} MW
            </td>
            <td>{numFormat(roundNum(rgnData.dayPower + rgnData.dayAddPower, 1))} MWh</td>
          </tr>
          <tr>
            <td>
              <div style={{ paddingTop: "7px", float: "left" }}>
                <SvgIcon component={LocalFireDepartmentIcon} style={{ color: "#FE4A59" }} />
                &nbsp;
              </div>
              <div style={{ float: "right" }}>
                {numFormat(roundNum(rgnData.heathInstCapa + rgnData.realHeathInstCapa, 1))} MW
                <br />
                {numFormat(roundNum(rgnData.heatInstCapa + rgnData.realHeatInstCapa, 1))} km²
              </div>
            </td>
            <td>{numFormat(roundNum(rgnData.dayUse + rgnData.dayAddUse, 1))} kWh</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default RenewStatTb;
