import styles from "./cmpUserByUser.module.css";
import { orderBy } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { useState } from "react";

// 시간별 데이터 그리드
const CmpUserByUserTimeGrid = ({ timeDataList, search, kendo, setLoading, ensoTypeCode }) => {
  const isMobile = localStorage.getItem("isMobile");
  // 태양열, 지열인지 판별
  const isHeat = ensoTypeCode === 15002 || ensoTypeCode === 15003 ? true : false;

  // 엑셀 다운로드 변수 (레퍼런스 사용)
  let _excelData;

  // 정렬
  const [sort, setSort] = useState([{ field: "", dir: "" }]);
  // 정렬 함수
  const onSortChange = (e) => {
    const { sort } = e;
    setSort(sort);
  };

  // 엑셀 다운로드
  const downloadExcel = (e) => {
    setLoading(true);
    _excelData.save();
  };

  const completeExcel = () => {
    setLoading(false);
  };
  return (
    <div className="uk-width-medium-1-2">
      <div className="md-card TBgA">
        <div className={styles.titleName}> ⋅ 시간대별 상세정보</div>
        <div className="md-card-content small-padding">
          <div className="md-card-toolbar">
            <h3 className="md-card-toolbar-heading-text">
              <Button icon="excel" className="ensoCmpExcelDownBtn" onClick={downloadExcel}>
                엑셀저장
              </Button>
            </h3>
          </div>
          <ExcelExport
            className={isMobile === "Y" ? "mobileUserByUserTimeDataListGrid" : "userByUserMeainListGrid"}
            data={orderBy(timeDataList, sort)}
            ref={(exporter) => (_excelData = exporter)}
            fileName={search.userTerm + "_시간대별 발전량_" + kendo.toString(new Date(), "yyyy-MM-dd")}
            onExportComplete={completeExcel}
          >
            <ExcelExportColumn title="시간" field="gathDtm" />
            <ExcelExportColumn title="발전량(kWh)" field="dayEnergy" />
            <ExcelExportColumn title="평균효율(%)" field="efic" />
            <ExcelExportColumn title="용량대비발전량(kWh/kW.day)" field="instCapaPerPower" />
            {isHeat === false && <ExcelExportColumn title="CO₂저감량(kgCO₂)" field="co2" />}
          </ExcelExport>
          <Grid
            id="userByUserTimeDataListGrid"
            className={isMobile === "Y" ? "mobileUserByUserTimeDataListGrid" : "userByUserMeainListGrid"}
            data={orderBy(timeDataList, sort)}
            style={{ height: "360px" }}
            onSortChange={onSortChange}
            sort={sort}
            sortable
          >
            <GridNoRecords>{"조회 결과 데이터가 없습니다."}</GridNoRecords>

            <GridColumn title="시간" field="gathDtm" />
            <GridColumn title="발전량(kWh)" field="dayEnergy" />
            <GridColumn title="평균효율(%)" field="efic" />
            <GridColumn title="용량대비발전량(kWh/kW.day)" width={isMobile === "Y" ? 0 : 210} field="instCapaPerPower" />
            {isHeat === false && <GridColumn title="CO₂저감량(kgCO₂)" field="co2" />}
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default CmpUserByUserTimeGrid;
