// 설비 정보 지열
import React, { useState, useEffect } from "react";
import kendo from "@progress/kendo-ui";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import styles from "./meain.module.css";
import DateTimePickerKo from "../../com/dateInputs/dateTimePickerKo";
import DatePickerKo from "../../com/dateInputs/datePickerKo";
import { Input } from "@progress/kendo-react-inputs";

// 태양열(자연순환) 설비 정보
function Meain_info_heatNa({ selectMeain, setMeainInfo, codeList, meainModlList, inputValidMsg, itemRender }) {
  // 자연 순환식은 clrmtModlNo(열량계 모델이 설비 번호이다.);
  const [wtrHtrMakr, setWtrHtrMakr] = useState(
    selectMeain.wtrHtrMakrNo ? { makrSeriNo: selectMeain.wtrHtrMakrNo, makrTerm: "" } : { makrSeriNo: "", makrTerm: "" },
  );
  const [wtrHtrModl, setWtrHtrModl] = useState(
    selectMeain.wtrHtrModlNo ? { meainTypeSeriNo: selectMeain.wtrHtrModlNo, meainTypeTerm: "" } : { meainTypeSeriNo: "", meainTypeTerm: "" },
  );
  const [clrmtMakr, setClrmtMakr] = useState(
    selectMeain.clrmtMakrNo ? { makrSeriNo: selectMeain.clrmtMakrNo, makrTerm: "" } : { makrSeriNo: "", makrTerm: "" },
  );
  const [clrmtModl, setClrmtModl] = useState(
    selectMeain.clrmtModlNo ? { meainTypeSeriNo: selectMeain.clrmtModlNo, meainTypeTerm: "" } : { meainTypeSeriNo: "", meainTypeTerm: "" },
  );
  const [bldSrvCodeNo, setBldSrvCodeNo] = useState(selectMeain.bldSrvCodeNo ? { codeNo: selectMeain.bldSrvCodeNo, codeVal: "" } : { codeNo: "", codeVal: "" });
  const bldSrvDs = codeList.filter((c) => c.grpCodeNo === 31);

  // 온수기 제조사 목록
  const wtrHtrMakrList = meainModlList.length !== 0 ? meainModlList.makrDtoList.filter((c) => c.makrClsfNo == 30016) : [];
  // 온수기 모델 목록
  const wtrHtrModlList =
    meainModlList.length !== 0 ? meainModlList.meainModelDtoList.filter((c) => c.ensoTypeCodeNo === 15002 && c.makrSeriNo === selectMeain.wtrHtrMakrNo) : [];
  // 열량계 제조사
  const clrmtMakrList = meainModlList.length !== 0 ? meainModlList.makrDtoList.filter((c) => c.makrClsfNo == 30013) : [];
  // 열량계 모델
  const clrmtModlList =
    meainModlList.length !== 0 ? meainModlList.meainModelDtoList.filter((c) => c.ensoTypeCodeNo === 15002 && c.makrSeriNo === selectMeain.clrmtMakrNo) : [];

  // 드롭다운 값 변경 이벤트 (Single 데이터 버전)
  const onchageDropdown = (e) => {
    // 값변경한 부분에 대해서 추적하여 객체 이름 기입하기 위해 name 값 가져오기
    const objName = e.target.name;
    const meainInfo = {
      ...selectMeain,
      [objName]: e.value,
    };
    setMeainInfo(meainInfo);
  };

  // Input 데이터 값 변경 이벤트
  const onchageInput = (e) => {
    let meainInfo = {};
    const objName = e.target.name;
    // 태양열은 집열기 면적이 설비 용량이다.
    if (e.target.name === "socoArea") {
      meainInfo = {
        ...selectMeain,
        [objName]: e.target.value,
        instCapa: e.target.value,
      };
    } else {
      // 값변경한 부분에 대해서 추적하여 객체 이름 기입하기 위해 name 값 가져오기
      meainInfo = {
        ...selectMeain,
        [objName]: e.target.value,
      };
    }
    setMeainInfo(meainInfo);
  };

  // Date 데이터 값 변경 이벤트
  const onchageDate = (v, name) => {
    // 값변경한 부분에 대해서 추적하여 객체 이름 기입하기 위해 name 값 가져오기
    const meainInfo = {
      ...selectMeain,
      [name]: kendo.toString(v, "yyyy-MM-dd"),
    };
    setMeainInfo(meainInfo);
  };

  // DateTimeOnChangeDate
  const onChangeDateTiem = (v, name) => {
    const meainInfo = {
      ...selectMeain,
      [name]: v,
      // ,  [name] : kendo.toString(v , 'yyyy-MM-dd HH:mm:00')
    };
    setMeainInfo(meainInfo);
  };

  // 날짜 포맷 지정
  const dateFormat = (date) => {
    if (typeof date !== "undefined") {
      if (date !== "") {
        date = new Date(date);
      } else {
        date = "";
      }
    } else {
      date = "";
    }
    return date;
  };

  // 제조사 Converter
  const makrConverter = () => {
    if (Object.keys(selectMeain).length !== 0) {
      if (selectMeain.wtrHtrMakrNo) {
        let makrSeriNo = "";
        let makrTerm = "";
        wtrHtrMakrList.forEach((element) => {
          if (element.makrSeriNo == selectMeain.wtrHtrMakrNo) {
            makrSeriNo = element.makrSeriNo;
            makrTerm = element.makrTerm;
          }
        });
        setWtrHtrMakr({ makrSeriNo: makrSeriNo, makrTerm: makrTerm });
      }
      if (selectMeain.clrmtMakrNo) {
        let makrSeriNo = "";
        let makrTerm = "";
        clrmtMakrList.forEach((element) => {
          if (element.makrSeriNo == selectMeain.clrmtMakrNo) {
            makrSeriNo = element.makrSeriNo;
            makrTerm = element.makrTerm;
          }
        });
        setClrmtMakr({ makrSeriNo: makrSeriNo, makrTerm: makrTerm });
      }
    } else {
      setWtrHtrMakr({ makrSeriNo: "", makrTerm: "" });
      setClrmtMakr({ makrSeriNo: "", makrTerm: "" });
    }
  };
  // 모델 Converter
  const modlConverter = () => {
    if (Object.keys(selectMeain).length !== 0) {
      if (selectMeain.wtrHtrModlNo) {
        let meainTypeSeriNo = "";
        let meainTypeTerm = "";
        wtrHtrModlList.forEach((element) => {
          if (element.meainTypeSeriNo == selectMeain.wtrHtrModlNo) {
            meainTypeSeriNo = element.meainTypeSeriNo;
            meainTypeTerm = element.meainTypeTerm;
          }
        });
        setWtrHtrModl({ meainTypeSeriNo: meainTypeSeriNo, meainTypeTerm: meainTypeTerm });
      } else {
        setWtrHtrModl({ meainTypeSeriNo: "", meainTypeTerm: "" });
      }
      if (selectMeain.clrmtModlNo) {
        let meainTypeSeriNo = "";
        let meainTypeTerm = "";
        clrmtModlList.forEach((element) => {
          if (element.meainTypeSeriNo == selectMeain.clrmtModlNo) {
            meainTypeSeriNo = element.meainTypeSeriNo;
            meainTypeTerm = element.meainTypeTerm;
          }
        });
        setClrmtModl({ meainTypeSeriNo: meainTypeSeriNo, meainTypeTerm: meainTypeTerm });
      } else {
        setClrmtModl({ meainTypeSeriNo: "", meainTypeTerm: "" });
      }
    } else {
      setWtrHtrModl({ meainTypeSeriNo: "", meainTypeTerm: "" });
      setClrmtModl({ meainTypeSeriNo: "", meainTypeTerm: "" });
    }
  };

  // Converter 건물용도별
  const bldSrvConverter = () => {
    if (Object.keys(selectMeain).length !== 0) {
      let codeNo = "";
      let codeVal = "";
      bldSrvDs.forEach((element) => {
        if (element.codeNo == selectMeain.bldSrvCodeNo) {
          codeNo = element.codeNo;
          codeVal = element.codeVal;
        }
      });
      setBldSrvCodeNo({ codeNo: codeNo, codeVal: codeVal });
    } else {
      setBldSrvCodeNo({ codeNo: "", codeVal: "-" });
    }
  };

  useEffect(() => {
    // props의 데이터를 각각 데이터 소스 객체에 맞게 Convert 작업 해준다.
    makrConverter();
    modlConverter();
    bldSrvConverter();
  }, [selectMeain]); // <- 이걸 해줘야 다른 컴포넌트에 값을 입력해준다.

  return (
    <table className="Ch-TableN">
      <tr>
        <th>현재사용열원</th>
        <td>
          <Input
            id="presUseHeatSrc"
            name="presUseHeatSrc"
            type="text"
            className={styles.searchTermTBC}
            value={selectMeain.presUseHeatSrc ? selectMeain.presUseHeatSrc : ""}
            onChange={onchageInput}
            required={true}
            validationMessage={inputValidMsg.normalText}
          />
        </td>
        <th>설치유형</th>
        <td>
          <DropDownList
            id="instType"
            name="instType"
            type="text"
            className="meainInfoDropDown"
            data={["지상형", "경사지붕형", "평슬라브형", "곡면형", "건물일체형(BIST)"]}
            value={selectMeain.instType ? selectMeain.instType : ""}
            onChange={onchageDropdown}
            required={true}
            validationMessage={inputValidMsg.normalText}
            itemRender={itemRender}
          />
        </td>
        <th>건물용도</th>
        <td>
          <DropDownList
            className="bldSrvCodeNo"
            id="bldSrvCodeNo"
            name="bldSrvCodeNo"
            data={bldSrvDs}
            required={true}
            validationMessage={inputValidMsg.normalText}
            value={bldSrvCodeNo}
            itemRender={itemRender}
            dataItemKey="codeNo"
            textField="codeVal"
            onChange={(e) => {
              const obj = { ...selectMeain, [e.target.name]: e.value.codeNo };
              setBldSrvCodeNo({ codeNo: e.target.value.codeNo, codeVal: e.target.value.codeVal });
              setMeainInfo(obj);
            }}
          />
        </td>
      </tr>
      <tr>
        <th colSpan="6">
          <div></div>
        </th>
      </tr>
      <tr>
        <th>집열기 매수</th>
        <td>
          <Input
            id="socoShtno"
            name="socoShtno"
            type="number"
            className={styles.searchTermTBC}
            value={selectMeain.socoShtno ? selectMeain.socoShtno : ""}
            onChange={onchageInput}
            required={true}
            validationMessage={inputValidMsg.number2Fix}
            step="0.01"
            min={0}
          />
        </td>
        <th>집열기 면적(㎡)</th>
        <td>
          <Input
            id="socoArea"
            name="socoArea"
            type="number"
            className={styles.searchTermTBC}
            value={selectMeain.socoArea ? selectMeain.socoArea : ""}
            onChange={onchageInput}
            required={true}
            validationMessage={inputValidMsg.number2Fix}
            step="0.01"
            min={0}
          />
        </td>
        <th></th>
        <td></td>
      </tr>
      <tr>
        <th colSpan="6">
          <div></div>
        </th>
      </tr>
      <tr>
        <th>온수기 제조사</th>
        <td>
          <DropDownList
            id="wtrHtrMakrNo"
            name="wtrHtrMakrNo"
            className="meainInfoDropDown"
            value={wtrHtrMakr}
            data={wtrHtrMakrList}
            itemRender={itemRender}
            required={true}
            validationMessage={inputValidMsg.normalText}
            valid={selectMeain.wtrHtrMakrNo !== "" && selectMeain.wtrHtrMakrNo !== null && typeof selectMeain.wtrHtrMakrNo !== "undefined"}
            dataItemKey="makrSeriNo"
            textField="makrTerm"
            onChange={(e) => {
              const obj = { ...selectMeain, [e.target.name]: e.value.makrSeriNo };
              setWtrHtrMakr({ makrSeriNo: e.target.value.makrSeriNo, makrTerm: e.target.value.makrTerm });
              setMeainInfo(obj);
            }}
          ></DropDownList>
        </td>
        <th>온수기 모델명</th>
        <td>
          <DropDownList
            id="wtrHtrModlNo"
            name="wtrHtrModlNo"
            className="meainInfoDropDown"
            value={wtrHtrModl}
            data={wtrHtrModlList}
            disabled={selectMeain.wtrHtrMakrNo ? false : true}
            itemRender={itemRender}
            required={true}
            validationMessage={inputValidMsg.normalText}
            valid={selectMeain.wtrHtrModlNo !== "" && selectMeain.wtrHtrModlNo !== null && typeof selectMeain.wtrHtrModlNo !== "undefined"}
            dataItemKey="meainTypeSeriNo"
            textField="meainTypeTerm"
            onChange={(e) => {
              const obj = { ...selectMeain, [e.target.name]: e.value.meainTypeSeriNo };
              setWtrHtrModl({ meainTypeSeriNo: e.target.value.meainTypeSeriNo, meainTypeTerm: e.target.value.meainTypeTerm });
              setMeainInfo(obj);
            }}
          ></DropDownList>
        </td>
        <th></th>
        <td></td>
      </tr>
      <tr>
        <th colSpan="6">
          <div></div>
        </th>
      </tr>
      <tr>
        <th>열량계 제조사</th>
        <td>
          <DropDownList
            id="clrmtMakrNo"
            name="clrmtMakrNo"
            className="meainInfoDropDown"
            value={clrmtMakr}
            data={clrmtMakrList}
            itemRender={itemRender}
            required={true}
            validationMessage={inputValidMsg.normalText}
            dataItemKey="makrSeriNo"
            textField="makrTerm"
            valid={selectMeain.clrmtMakrNo !== "" && selectMeain.clrmtMakrNo !== null && typeof selectMeain.clrmtMakrNo !== "undefined"}
            onChange={(e) => {
              const obj = { ...selectMeain, [e.target.name]: e.value.makrSeriNo };
              setClrmtMakr({ makrSeriNo: e.target.value.makrSeriNo, makrTerm: e.target.value.makrTerm });
              setMeainInfo(obj);
            }}
          ></DropDownList>
        </td>
        <th>열량계 모델</th>
        <td>
          <DropDownList
            id="clrmtModlNo"
            name="clrmtModlNo"
            className="meainInfoDropDown"
            value={clrmtModl}
            data={clrmtModlList}
            disabled={selectMeain.clrmtMakrNo ? false : true}
            itemRender={itemRender}
            required={true}
            validationMessage={inputValidMsg.normalText}
            valid={selectMeain.clrmtModlNo !== "" && selectMeain.clrmtModlNo !== null && typeof selectMeain.clrmtModlNo !== "undefined"}
            dataItemKey="meainTypeSeriNo"
            textField="meainTypeTerm"
            onChange={(e) => {
              const obj = { ...selectMeain, [e.target.name]: e.value.meainTypeSeriNo, meainTypeSeriNo: e.value.meainTypeSeriNo };
              setClrmtModl({ meainTypeSeriNo: e.target.value.meainTypeSeriNo, meainTypeTerm: e.target.value.meainTypeTerm });
              setMeainInfo(obj);
            }}
          ></DropDownList>
        </td>
        <th></th>
        <td></td>
      </tr>
      <tr>
        <th colSpan="6">
          <div></div>
        </th>
      </tr>
      <tr>
        <th>방위각</th>
        <td>
          <Input
            id="azimuth"
            name="azimuth"
            type="number"
            className={styles.searchTermTBC}
            value={selectMeain.azimuth ? selectMeain.azimuth : ""}
            onChange={onchageInput}
            required={true}
            validationMessage={inputValidMsg.number2Fix}
            step="0.01"
            min={0}
          />
        </td>
        <th>경사각</th>
        <td>
          <Input
            id="incliAngle"
            name="incliAngle"
            type="number"
            className={styles.searchTermTBC}
            value={selectMeain.incliAngle ? selectMeain.incliAngle : ""}
            onChange={onchageInput}
            required={true}
            validationMessage={inputValidMsg.number2Fix}
            step="0.01"
            min={0}
          />
        </td>
        <th></th>
        <td></td>
      </tr>
      <tr>
        <th colSpan="6">
          <div></div>
        </th>
      </tr>
      <tr>
        <th>축열탱크용량(L)</th>
        <td>
          <Input
            id="rthrmTankCapa"
            name="rthrmTankCapa"
            type="number"
            className={styles.searchTermTBC}
            value={selectMeain.rthrmTankCapa ? selectMeain.rthrmTankCapa : ""}
            onChange={onchageInput}
            required={true}
            validationMessage={inputValidMsg.number2Fix}
            step="0.01"
            min={0}
          />
        </td>
        <th>축열탱크재질</th>
        <td>
          <Input
            id="rthrmTankQual"
            name="rthrmTankQual"
            type="text"
            className={styles.searchTermTBC}
            value={selectMeain.rthrmTankQual ? selectMeain.rthrmTankQual : ""}
            onChange={onchageInput}
            required={true}
            validationMessage={inputValidMsg.normalText}
          />
        </td>
        <th>시공업체</th>
        <td>
          <Input
            id="consEnte"
            name="consEnte"
            type="text"
            className={styles.searchTermTBC}
            value={selectMeain.consEnte ? selectMeain.consEnte : ""}
            onChange={onchageInput}
            required={true}
            validationMessage={inputValidMsg.normalText}
          />
        </td>
      </tr>
      <tr>
        <th colSpan="6">
          <div></div>
        </th>
      </tr>
      <tr>
        <th>시공업체 연락처</th>
        <td>
          <Input
            id="consEnteCntct"
            name="consEnteCntct"
            type="text"
            className={styles.searchTermTBC}
            value={selectMeain.consEnteCntct ? selectMeain.consEnteCntct : ""}
            onChange={onchageInput}
            required={true}
            validationMessage={inputValidMsg.normalText}
            placeholder="하이픈(-) 없이 입력해주세요."
          />
        </td>
        <th>
          시공업체
          <br />
          핸드폰 연락처
        </th>
        <td>
          <Input
            id="consPhoneCntct"
            name="consPhoneCntct"
            type="text"
            className={styles.searchTermTBC}
            value={selectMeain.consPhoneCntct ? selectMeain.consPhoneCntct : ""}
            onChange={onchageInput}
            required={true}
            validationMessage={inputValidMsg.normalText}
            placeholder="하이픈(-) 없이 입력해주세요."
          />
        </td>
        <th>시공일시</th>
        <td>
          <DateTimePickerKo
            id="meainInstDtm"
            name="meainInstDtm"
            className="meainDatePicker"
            value={dateFormat(selectMeain.meainInstDtm)}
            format={"yyyy-MM-dd HH:mm"}
            formatPlaceholder={{ year: "년", month: "월", day: "일", hour: "시간", minute: "분" }}
            setDtm={(v) => {
              onChangeDateTiem(v, "meainInstDtm");
            }}
            required={true}
            validationMessage={inputValidMsg.normalText}
          ></DateTimePickerKo>
        </td>
      </tr>
      <tr>
        <th colSpan="6">
          <div></div>
        </th>
      </tr>
      <tr>
        <th>AS만료일</th>
        <td>
          <DatePickerKo
            id="asExpiDtm"
            name="asExpiDtm"
            className="meainDatePicker"
            format="yyyy-MM-dd"
            value={dateFormat(selectMeain.asExpiDtm)}
            formatPlaceholder={{ year: "년", month: "월", day: "일" }}
            setDtm={(v) => {
              onchageDate(v, "asExpiDtm");
            }}
            required={true}
            validationMessage={inputValidMsg.normalText}
          ></DatePickerKo>
        </td>
        <th>보증기간</th>
        <td>
          <Input
            id="wrntyPrid"
            name="wrntyPrid"
            type="text"
            className={styles.searchTermTBC}
            value={selectMeain.wrntyPrid ? selectMeain.wrntyPrid : ""}
            onChange={onchageInput}
          />
        </td>
        <th>모니터링일시</th>
        <td>
          <DateTimePickerKo
            id="mntrStrtDtm"
            name="mntrStrtDtm"
            className="meainDatePicker"
            value={dateFormat(selectMeain.mntrStrtDtm)}
            format={"yyyy-MM-dd HH:mm"}
            formatPlaceholder={{ year: "년", month: "월", day: "일", hour: "시간", minute: "분" }}
            setDtm={(v) => {
              onChangeDateTiem(v, "mntrStrtDtm");
            }}
            required={true}
            validationMessage={inputValidMsg.normalText}
          ></DateTimePickerKo>
        </td>
      </tr>
      <tr>
        <th colSpan="6">
          <div></div>
        </th>
      </tr>
      <tr>
        <th>특이사항</th>
        <td colSpan="4">
          <Input id="etc" name="etc" type="text" className={styles.searchTermTBC} value={selectMeain.etc ? selectMeain.etc : ""} onChange={onchageInput} />
        </td>
      </tr>
      <tr>
        <th colSpan="6">
          <div></div>
        </th>
      </tr>
    </table>
  );
}

export default Meain_info_heatNa;
